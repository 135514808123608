import React from "react";
import { Route, Switch } from "react-router-dom";
import routes from "../routes";
import Page from "../components/page/Page";

const Auth = (props: any) => {
  
  document.body.classList.remove('with-sidebar');
  
  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.layout === "/auth") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={props => (
              <Page {...props}
                component={prop.component}
                title={'34 Elements | ' + prop.name}
                name={prop.name}
              // onSetBrandText={onSetBrandText}
              />
            )} />
        );
      } else {
        return null;
      }
    });
  };

  return (
    <React.Fragment>
      <Switch>{getRoutes(routes)}</Switch>
    </React.Fragment>
  );
}

export default Auth;
