import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Svg
import { ReactSVG } from "react-svg";
// Helper
import StartupHelper from '../../helpers/startup/startup.helper';
// Moment
import Moment from 'react-moment';
import ChallengeItemCollapse from './ChallengeItemCollapse';
import ChallengeHelper from '../../helpers/challenge/challenge.helper';
// Redux
import { useDispatch } from 'react-redux';
import { challengeToUpdate, challengeToView } from '../../redux/ducks/challenge-duck';
// Show More 
import ShowMoreText from 'react-show-more-text';

// Props
interface ChallengeItemProps {
    challenge: any,
    type: string,
}

const ChallengeItem = (props: ChallengeItemProps) => {
    // Dispatch 
    const dispatch = useDispatch();
    // Helper
    const startupHelper = new StartupHelper()
    const challengeHelper = new ChallengeHelper();
    // Props
    const { challenge, type } = props;
    // States
    const [collapsed, setCollapsed] = useState(true)
    // Icons
    const arrow = require("../../assets/img/challenges/arrow-right.svg").default;
    const calendar = require("../../assets/img/challenges/calendar.svg").default;
    // Percentage
    const percentageStyle = startupHelper.resolvePercentageScoringStyle(challengeHelper.resolvePercentage(challenge));
    //  Status
    const status = startupHelper.resolveChallengeStatus(challenge.status_sku)
    // Icon Status
    const iconStatussrc = require(`../../assets/img/challenges/icon_${status.sku}.svg`).default
    const toggleCollapsed = (e) => {
        e.preventDefault();
        setCollapsed(!collapsed)
    }
    // On View
    const onClickViewChallenge = () => {
        dispatch(challengeToView(challenge));
    }
    // On Update
    const onClickUpdateChallenge = () => {
        dispatch(challengeToUpdate(challenge));
    }
    const onClickView = () => {
        if (challengeHelper.isCompleted(challenge)) {
            onClickViewChallenge();
        } else {
            onClickUpdateChallenge();
        }
    }
    return (
        <>
            <div className={`challenge-item ${!collapsed ? 'collapse-show' : ''}`}>
                <span className="collapse-btn" onClick={toggleCollapsed}>
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right" />
                </span>
                <div className="challenge-item-content">
                    <Row>
                        {/* Main Data */}
                        <Col md={7} className="d-flex align-items-center">
                            <div className="w-100">
                                <span className="challenge-title">
                                    <span onClick={onClickView}>{challenge.title}</span>
                                </span>
                                <span className="challenge-description">
                                    <ShowMoreText
                                        /* Default options */
                                        lines={1}
                                        more='Show more'
                                        less='Show less'
                                        className='content-css'
                                        anchorClass='challlenge-description-show-more'
                                        // onClick={executeOnClick}
                                        expanded={false}
                                        width={0} >
                                        <div dangerouslySetInnerHTML={{ __html: challenge.description }} />
                                    </ShowMoreText>
                                </span>
                            </div>
                        </Col>
                        {/* Due Date Data */}
                        <Col md={5} className="d-flex align-items-center justify-content-md-end">
                            <div>
                                <div className="challenge-level">
                                    <span className={`badge-challenge ${percentageStyle?.key}`}>
                                        {type === 'type' && challenge.type.title}
                                        {type === 'category' && challenge.type.category.title}
                                    </span>
                                    <span className={`badge-challenge badge-challenge-status  ${status?.sku}`}>
                                        <ReactSVG src={iconStatussrc} wrapper="span" className="icon-status " />
                                        {status.title}
                                    </span>
                                </div>
                                <div className="challenge-due-date">
                                    <ReactSVG src={calendar} wrapper="span" className="calendar-icon svg-baseline" />
                                    <span className="due-date">Due on <Moment format="MMMM D[,] YYYY">{challenge.due_date}</Moment>
                                    </span>
                                </div>
                            </div>
                        </Col>
                    </Row>
                </div>
            </div>
            <ChallengeItemCollapse challenge={challenge} collapsed={collapsed} />

        </>
    );
};
export default ChallengeItem;