// Sweet Alter
const Swal = require('sweetalert2');
const swalWithBootstrapButtons = Swal.mixin({
  customClass: {
    confirmButton: 'btn btn-violet',
    cancelButton: 'btn btn-light'
  },
  buttonsStyling: false
})

export default swalWithBootstrapButtons;