
// Auth
import CategoryEditView from "./views/admin/categories/CategoryEditView";
import CategoryIndexView from "./views/admin/categories/CategoryIndexView";
import CategoryWeightView from "./views/admin/categories/CategoryWeightView";
import QuestionTypeIndexView from "./views/admin/question-types/QuestionTypeIndexView";
import QuestionTypeEditView from "./views/admin/question-types/QuestionTypeEditView";
import QuestionEditView from "./views/admin/questions/QuestionEditView";
import QuestionIndexView from "./views/admin/questions/QuestionIndexView";
import QuestionPriorizationView from "./views/admin/questions/QuestionPriorization";
import SettingIndexView from "./views/admin/settings/SettingIndexView";
import AdminStartupIndexView from "./views/admin/startups/StartupIndexView";
import AdminStartupView from "./views/admin/startups/StartupView";
import BestPracticeIndexView from "./views/admin/best-practice/BestPracticeIndexView"
import BestPracticeAddView from "./views/admin/best-practice/BestPracticeAddView";
import BestPracticeEditView from "./views/admin/best-practice/BestPracticeEditView";
import ChallengeTemplateIndexView from "./views/admin/challenge-templates/ChallengeTemplateIndexView";
import ChallengeTemplateAddView from "./views/admin/challenge-templates/ChallengeTemplateAddView";
import ChallengeTemplateEditView from "./views/admin/challenge-templates/ChallengeTemplateEditView";
import AdviceIndexView from "./views/admin/advices/AdviceIndexView";
import AdviceAddView from "./views/admin/advices/AdviceAddView";
import AdviceEditView from "./views/admin/advices/AdviceEditView";
import TrackIndexView from "./views/admin/tracks/TrackIndexView";
import TrackAddView from "./views/admin/tracks/TrackAddView";
import TrackEditView from "./views/admin/tracks/TrackEditView";
import UserIndexView from "./views/admin/users/UserIndexView";
import UserEditView from "./views/admin/users/UserEditView";
// import UserElementsAvailablesView from "./views/admin/users/UserElementsAvailablesView";
// External Invitation
import CreateExternalInvitationView from "./views/admin/external-invitation/CreateExternalInvitationView";
import ExternalInvitationView from "./views/admin/external-invitation/ExternalInvitationView";
import ListExternalInvitationView from "./views/admin/external-invitation/ListExternalInvitationView";
import GroupIndexView from "./views/admin/groups/GroupIndexView";
import GroupAddView from "./views/admin/groups/GroupAddView";
import GroupEditView from "./views/admin/groups/GroupEditview";
import GroupManageUsersView from "./views/admin/groups/GroupManageUsersView";
import MasterSessionIndexView from "./views/admin/master-session/MasterSessionIndexView";
import MasterSessionAddView from "./views/admin/master-session/MasterSessionAddView";
import MasterSessionEditView from "./views/admin/master-session/MasterSessionEditView";

// Groups
const routesAdmin = [
  // Groups
  {
    path: "/groups/manage-users/:id",
    name: "Manage Users",
    icon: "ni ni-key-25",
    component: GroupManageUsersView,
    layout: "/admin",
    key: 'groups-manage'
  },
  {
    path: "/groups/edit/:id",
    name: "Edit Group",
    icon: "ni ni-key-25",
    component: GroupEditView,
    layout: "/admin",
    key: 'groups-edit'
  },
  {
    path: "/groups/add",
    name: "Groups",
    icon: "ni ni-key-25",
    component: GroupAddView,
    layout: "/admin",
    key: 'groups'
  },
  {
    path: "/groups",
    name: "Groups",
    icon: "ni ni-key-25",
    component: GroupIndexView,
    layout: "/admin",
    key: 'groups'
  },
  // Users
  // {
  //   path: "/users/elements/:id",
  //   name: "Edit Elements Availables",
  //   icon: "ni ni-key-25",
  //   component: UserElementsAvailablesView,
  //   layout: "/admin",
  //   key: 'users-edit-elements'
  // }, 
  {
    path: "/users/edit/:id",
    name: "Edit User",
    icon: "ni ni-key-25",
    component: UserEditView,
    layout: "/admin",
    key: 'users-edit-user'
  }, 
  {
    path: "/users",
    name: "Users",
    icon: "ni ni-key-25",
    component: UserIndexView,
    layout: "/admin",
    key: 'users'
  },
  // Tracks
  {
    path: "/tracks/edit/:id",
    name: "Track Edit",
    icon: "ni ni-key-25",
    component: TrackEditView,
    layout: "/admin",
    key: 'tracks-edit'
  },  
  {
    path: "/tracks/add",
    name: "Track Add",
    icon: "ni ni-key-25",
    component: TrackAddView,
    layout: "/admin",
    key: 'tracks-add'
  },
  {
    path: "/tracks",
    name: "Tracks",
    icon: "ni ni-key-25",
    component: TrackIndexView,
    layout: "/admin",
    key: 'tracks'
  },
  // Advices
  {
    path: "/advices/edit/:id",
    name: "Advice Edit",
    icon: "ni ni-key-25",
    component: AdviceEditView,
    layout: "/admin",
    key: 'advice-edit'
  },  
  {
    path: "/advices/add",
    name: "Advice Add",
    icon: "ni ni-key-25",
    component: AdviceAddView,
    layout: "/admin",
    key: 'advice-add'
  },
  {
    path: "/advices",
    name: "Advices",
    icon: "ni ni-key-25",
    component: AdviceIndexView,
    layout: "/admin",
    key: 'advices'
  },
  // Challenge Templates
  {
    path: "/challenge-templates/edit/:id",
    name: "Challenge Template Edit",
    icon: "ni ni-key-25",
    component: ChallengeTemplateEditView,
    layout: "/admin",
    key: 'challenge-templates-add'
  },  
  {
    path: "/challenge-templates/add",
    name: "Challenge Template Add",
    icon: "ni ni-key-25",
    component: ChallengeTemplateAddView,
    layout: "/admin",
    key: 'challenge-templates-add'
  },
  {
    path: "/challenge-templates",
    name: "Challenge Templates",
    icon: "ni ni-key-25",
    component: ChallengeTemplateIndexView,
    layout: "/admin",
    key: 'challenge-templates'
  },
  // Best Practice Documents
  {
    path: "/best-practice/edit/:id",
    name: "Best Practice Add",
    icon: "ni ni-key-25",
    component: BestPracticeEditView,
    layout: "/admin",
    key: 'best-practice-add'
  },  
  {
    path: "/best-practice/add",
    name: "Best Practice Add",
    icon: "ni ni-key-25",
    component: BestPracticeAddView,
    layout: "/admin",
    key: 'best-practice-add'
  },
  {
    path: "/best-practice",
    name: "Best Practice Documents",
    icon: "ni ni-key-25",
    component: BestPracticeIndexView,
    layout: "/admin",
    key: 'best-practice'
  },
  // Main Funding Metrics
  {
    path: "/main-funding-metrics",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: CategoryWeightView,
    layout: "/admin",
    key: 'category-weights-view'
  },
  // Sub Elements
  {
    path: "/sub-elements/edit/:question_type_id",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: QuestionTypeEditView,
    layout: "/admin",
    key: 'type-edit-view'
  },
  {
    path: "/sub-elements",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: QuestionTypeIndexView,
    layout: "/admin",
    key: 'types-index'
  },
  // Main Elements
  {
    path: "/main-elements/edit/:category_id",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: CategoryEditView,
    layout: "/admin",
    key: 'category-edit-view'
  },
  {
    path: "/main-elements",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: CategoryIndexView,
    layout: "/admin",
    key: 'category-index'
  },
  // Settings
  {
    path: "/main-funding",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: SettingIndexView,
    layout: "/admin",
    key: 'setting-index'
  },
  // Priorization  
  {
    path: "/priorization",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: QuestionPriorizationView,
    layout: "/admin",
    key: 'question-priorization-view'
  },
  // Questions
  {
    path: "/questions/edit/:question_id",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: QuestionEditView,
    layout: "/admin",
    key: 'question-edit-view'
  },
  {
    path: "/questions",
    name: "Questions List",
    icon: "ni ni-key-25",
    component: QuestionIndexView,
    layout: "/admin",
    key: 'questions-index'
  },
  // External Invitataion
  {
    path: "/startups/external-invitation/list/:startup_id",
    name: "List External Invitation",
    icon: "ni ni-key-25",
    component: ListExternalInvitationView,
    layout: "/admin",
    key: 'startup-external-invitation-list'
  },
  {
    path: "/startups/external-invitation/create/:startup_id",
    name: "Create Startup External Invitation",
    icon: "ni ni-key-25",
    component: CreateExternalInvitationView,
    layout: "/admin",
    key: 'startup-external-invitation'
  },
  {
    path: "/startups/external-invitation/:group_id",
    name: "Startup External Invitation View",
    icon: "ni ni-key-25",
    component: ExternalInvitationView,
    layout: "/admin",
    key: 'startup-external-invitation-view'
  },
  // Startup
  {
    path: "/startups/view/:startup_id",
    name: "Startups View",
    icon: "ni ni-key-25",
    component: AdminStartupView,
    layout: "/admin",
    key: 'startup-view'
  },
  {
    path: "/startups",
    name: "Startups List",
    icon: "ni ni-key-25",
    component: AdminStartupIndexView,
    layout: "/admin",
    key: 'startup-index'
  },
  // Master Session
  {
    path: "/master-sessions/edit/:id",
    name: "Master Session Edit",
    icon: "ni ni-key-25",
    component: MasterSessionEditView,
    layout: "/admin",
    key: 'master-sessions-edit'
  },  
  {
    path: "/master-sessions/add",
    name: "Master Session Add",
    icon: "ni ni-key-25",
    component: MasterSessionAddView,
    layout: "/admin",
    key: 'master-sessions-add'
  },
  {
    path: "/master-sessions",
    name: "Master Sessions",
    icon: "ni ni-key-25",
    component: MasterSessionIndexView,
    layout: "/admin",
    key: 'master-sessions-index'
  },

];
export default routesAdmin;
