/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Router
// Boxs
import BoxFounders from "../../components/dashboard/BoxFounders";
import BoxPriorization from "../../components/dashboard/BoxPriorization";
import BoxFundabilityFounders from "../../components/dashboard/BoxFundabilityFounders";
import BoxMostOptimist from "../../components/dashboard/BoxMostOptimist";
// Apollo
import { useApolloClient } from "react-apollo";
// Helper
import StartupHelper from "../../helpers/startup/startup.helper";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getDashboardData,
  getDashboardDataInternal,
  setDashboardData,
  setDashboardDataInternal,
} from "../../redux/ducks/dashboard-duck";
// Toast
import { toast } from "react-toastify";
// Constants
import {
  CO_FOUNDERS_CATEGORIES,
  CO_FOUNDERS_SUMMARY_EMPTY,
  DEFAULT_MAIN_ELEMENT_FUNDING,
} from "../../constants/startup/startup.constants";
// Modal
import ModalListGuests from "../../components/guests/ModalListGuests";
import { LIST_GUEST_BY_TYPE } from "../../queries/guests/guests.query";
import { GET_STARTUP_DASHBOARD_INTERNAL } from "../../queries/startup/startups.query";
import { getUser } from "../../redux/ducks/user-duck";

// Founders Alignment
const FoundersAlignment = (props) => {
  // Auth User
  const user = useSelector(getUser);
  // Selectors
  const dashboardDataInternal = useSelector(getDashboardDataInternal);
  const dashboardData = useSelector(getDashboardData);
  // Can Invite
  const [canInvite, setCanInvite] = useState(false);
  // dispatch
  const dispatch = useDispatch();
  // Client
  const client = useApolloClient();
  // Categories
  const [categories, setCategories] = useState<any>(CO_FOUNDERS_CATEGORIES);
  // Summary
  const [summary, setSummary] = useState<any>(null);
  // Fundability
  const [fundability, setFundability] = useState<any>(
    DEFAULT_MAIN_ELEMENT_FUNDING
  );
  // Most Optimist
  const [mostOptimist, setMostOptimist] = useState<any>(null);
  // Priorizarion
  const [priorization, setPriorization] = useState({
    now: null,
    next: null,
    future: null,
  });
  // Guests
  const [guests, setGuests] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  // Helper
  const startupHelper = new StartupHelper();
  // Fetch Dashboard Data
  const fetchDashboardData = useCallback(async () => {
    const response = await client.query({
      query: GET_STARTUP_DASHBOARD_INTERNAL,
      fetchPolicy: "no-cache",
    });
    // Startup
    const startup = response.data.getStartupDashboard || {};
    dispatch(setDashboardDataInternal(startup));
  }, [client, dispatch]);
  // Fectch Fundability
  const fetchFundability = useCallback(
    async (scoring) => {
      // console.log('scoring',scoring)
      const cloned = startupHelper.resolveFundabilityStatusElement(
        scoring,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      // console.log('cloned',cloned)
      setFundability([...cloned]);
    },
    [setFundability]
  );
  // Fetch Priorization
  const fetchPriorization = useCallback(
    (priorization) => {
      const now = priorization.filter((p) => p.now);
      const next = priorization.filter((p) => p.next);
      const future = priorization.filter((p) => p.future);
      setPriorization({ now: now, next: next, future: future });
    },
    [setPriorization]
  );
  // Fetch Categories
  const fetchCategories = useCallback(
    (total_categories, summary, has_founders) => {
      setCategories(total_categories);
      if (has_founders) {
        setSummary(summary);
      } else {
        setSummary(CO_FOUNDERS_SUMMARY_EMPTY);
      }
    },
    [setCategories, setSummary]
  );
  // Fetch Most Optimist
  const fetchMostOptimist = useCallback(
    (totals_user) => {
      const users = totals_user.filter((t) => t.most_optimist.length > 0);
      if (users.length > 0) {
        const mapOptimist = users.map((t) => {
          return {
            name: t.user.first_name,
            items: t.most_optimist,
          };
        });
        setMostOptimist(mapOptimist);
      } else {
        setMostOptimist([]);
      }
    },
    [setMostOptimist]
  );
  // Fetch Guests
  const fetchGuests = useCallback(async () => {
    setLoading(true);
    try {
      // Guests
      const guestResponse = await client.query({
        query: LIST_GUEST_BY_TYPE,
        variables: { type: "guest_internal" },
        fetchPolicy: "no-cache",
      });
      const guestsList = guestResponse.data.listGuestByType || [];
      // Files
      setGuests(guestsList);
    } catch (e) {
      console.log("error fetch guests", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      setLoading(false);
    }
  }, [client, setGuests]);
  // Fetch can Invite
  const fetchCantInvite = useCallback(() => {
    if (user.startup_type === 'limited') {
      // Startup
      const startup = dashboardData;
      // Totals
      const totals = startup.totals;
      
      if (parseInt(totals.answer_percentage) === 100) {
        setCanInvite(true);
      } else {
        setCanInvite(false);
      }
    } else {
      setCanInvite(true);
    }

  }, [user, setCanInvite, dashboardData])
  // Fecth Data
  const fetchData = useCallback(async () => {
    try {
      // Startup
      const startup = dashboardDataInternal;
      // Totals
      const totals_internal = startup.totals_internal;
      // Scoring
      const scoring = totals_internal.scoring;
      // If has Founders
      const has_founders = totals_internal.has_founders;
      // Fetch Fundability
      fetchFundability(scoring);
      // Types
      const total_categories = totals_internal.total_categories;
      const summary = totals_internal.summary;
      fetchCategories(total_categories, summary, has_founders);
      // Most Optimist
      const totals_user = totals_internal.totals_user;
      fetchMostOptimist(totals_user);
      // Priorization
      if (has_founders) {
        const priorization = totals_internal.priorization;
        fetchPriorization(priorization);
      } else {
        fetchPriorization([]);
      }
      fetchCantInvite();
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      setLoading(false);
    }
  }, [
    dashboardDataInternal,
    fetchCantInvite,
    fetchCategories,
    fetchFundability,
    fetchPriorization,
    fetchMostOptimist,
  ]);
  // Init load one time totals
  useEffect(() => {
    if (dashboardDataInternal && dashboardDataInternal.id) {
      fetchData();
    }
  }, [dashboardDataInternal]);
  // Fetch All data what dont need dashboard data
  useEffect(() => {
    // Fetch Guests
    fetchGuests();
  }, []);

  const onChangeGuests = () => {
    fetchDashboardData();
    fetchGuests();
  }

  // Render
  return (
    <>
      {/* Guests */}
      <ModalListGuests
        guests={guests}
        loading={loading}
        loadGuests={onChangeGuests}
      />
      {/* First Row */}
      <Row>
        <Col className={"mb-16"} id="column-founders-1">
          <BoxFounders
            categories={categories}
            summary={summary}
            loadGuests={onChangeGuests}
            canInvite={canInvite}
          />
        </Col>
        <Col className={"mb-16"} id="column-founders-2">
          <BoxFundabilityFounders
            title={"Founder’s Alignment"}
            chartDescription={"Founders"}
            description={
              "Founders not agreeing and fighting is the third reason for startup failure. Invite co-founders to go though the entire questionnaire and visualize alignment."
            }
            fundability={fundability}
          />
        </Col>
      </Row>
      {/* Second Row */}
      <Row>
        <Col md={12} className={"col-lg-55 col-xl-57 mb-16"}>
          <BoxMostOptimist mostOptimist={mostOptimist} />
        </Col>
        <Col md={12} className={"col-lg-45 col-xl-43 mb-16"}>
          <BoxPriorization
            title={"Co-Founders Prioritization"}
            {...priorization}
          />
        </Col>
      </Row>
    </>
  );
};

export default FoundersAlignment;
