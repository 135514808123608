import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";

// PrettyFileSize
import PrettyFileSize from '../files/PrettyFileSize';
// Props
interface UploaderItemProps {
    file: any,
    index: number,
    onRemove: Function,
    enableRemoveItem: boolean,
    enableViewItem: boolean
}
const UploaderItem = (props: UploaderItemProps) => {
    // Helpers
    const { file, onRemove, index, enableRemoveItem, enableViewItem } = props;
    // Remove
    const onClickRemove = (e) => {
        e.preventDefault();
        onRemove(file, index)
    }
    return (
        <div className={'uploader-item'}>
            <Row>
                <Col lg={8}>
                    <span className="uploader-item-name">{file.name}</span>
                    <span className="uploader-item-size">
                        <PrettyFileSize>{file.size}</PrettyFileSize>
                    </span>
                </Col>
                {(enableRemoveItem || enableViewItem) &&
                    <Col lg={4}>
                        <div className="uploader-item-actions">
                            {/* Remove Item */}
                            {enableViewItem && file.id &&
                                <a href={file.url} className="btn btn- btn-sm btn-outline-violet" target="_blank" rel="noopener noreferrer">
                                    View
                                </a>
                            }
                            {enableRemoveItem &&
                                <span className="btn btn- btn-sm btn-outline-danger" onClick={onClickRemove}>
                                    Delete
                            </span>}
                        </div>

                    </Col>}
            </Row>
        </div>
    );
};

export default UploaderItem;