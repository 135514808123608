import React, { useEffect, useCallback, useState } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// React hook Form
import { useFormContext } from "react-hook-form";
// Helpers
// import StartupHelper from "../../../helpers/startup/startup.helper";

interface QuestionBlockProps {
    question: any,
    answerItem: any,
    firstScoring: any,
    onChangeQuestion?: Function
}
const QuestionBlockFirstScoring = (props: QuestionBlockProps) => {
    // Props
    const { question, onChangeQuestion, firstScoring, answerItem } = props;
    // State
    const [namePrefix, setNamePrefix] = useState<any>();
    const [chosenFirstScoring, setChosenFirstScoring] = useState<any>(null);

    // Get Error
    const getError = (errors, question) => {
        console.log(errors, question)
        if (errors['firstScoring']) {
            if (errors['firstScoring'][question.type.category_sku]) {
                    if(errors['firstScoring'][question.type.category_sku][question.type.sku]){
                        const keys = Object.keys(errors['firstScoring'][question.type.category_sku][question.type.sku]);
                        if(keys.length > 0){
                            return true;
                        }
                    }
            }
        }
        return false;
    }

    // Form
    const { register, setValue, errors } = useFormContext();

    // Validation and set values
    const validation = useCallback((question) => {
        // Name Prefix
        const namePrefixFormated = `firstScoring.${question.type.category_sku}.${question.type_sku}`;
        // console.log('validation', namePrefixFormated)
        setNamePrefix(namePrefixFormated)
        // Max First Scoring
        // const maxFirstScoring = startupHelper.getMaxWeight(firstScoring)
        // Option Validation
        register({ name: `${namePrefixFormated}.questionId` });
        // register({ name: `${namePrefixFormated}.maxWeightFirstScoring` });
        register({ name: `${namePrefixFormated}.firstScoringChosen` }, { required: 'The field is required' });
        // register({ name: `${namePrefixFormated}.totalFirstScoring` });
        // register({ name: `${namePrefixFormated}.weightType` });
        // register({ name: `${namePrefixFormated}.total_refined_pre_seed` });
        // register({ name: `${namePrefixFormated}.total_refined_seed` });
        // register({ name: `${namePrefixFormated}.total_refined_series_a` });
        // // Question Id
        setValue(`${namePrefixFormated}.questionId`, question.id)
        // // Set Values
        // setValue(`${namePrefixFormated}.maxWeightFirstScoring`, maxFirstScoring.weight)
        // setMaxFirstScoring(maxFirstScoring.weight)
        // // Set Weight Type 
        // setValue(`${namePrefixFormated}.weightType`, question.weight_type_sku)
        // //  Totals
        // setValue(`${namePrefixFormated}.totalFirstScoring`, 0)
        // // Totals Seed
        // setValue(`${namePrefixFormated}.total_refined_pre_seed`, question.total_refined_pre_seed);
        // setValue(`${namePrefixFormated}.total_refined_seed`, question.total_refined_seed);
        // setValue(`${namePrefixFormated}.total_refined_series_a`, question.total_refined_series_a);
        // First Change
        if (onChangeQuestion) {
            onChangeQuestion();
        }

    }, [
        register,
        setNamePrefix,
        setValue,
        // setMaxFirstScoring, 
        // startupHelper, 
        onChangeQuestion
    ])
    // Total Change
    // const handleTotalChange = useCallback((choosenFirstScoring) => {
    //     // Calculate total on change
    //     const totalFirstScoring = startupHelper.calculateTotalFirstScoring(choosenFirstScoring, maxFirstScoring);
    //     setValue(`${namePrefix}.totalFirstScoring`, totalFirstScoring);
    //     setTotalFirstScoring(totalFirstScoring)
    //     // Change
    //     if (onChangeQuestion) {
    //         onChangeQuestion();
    //     }
    // }, [setValue, setTotalFirstScoring, onChangeQuestion])
    // Handle Change First Scoring
    const handleChangeFirstScoring = useCallback((chosen) => {
        // console.log('first scoring change', chosen, namePrefix)
        setValue(`${namePrefix}.firstScoringChosen`, chosen, { shouldValidate: true });
        setChosenFirstScoring(chosen);
        // Trigger totals
        // handleTotalChange(chosen);
    }, [setValue, setChosenFirstScoring, namePrefix])
    // Fetch Data
    const fetchData = useCallback(() => {
        if (answerItem.first_scoring_id) {
            const chosen = firstScoring.find(i => i.id === answerItem.first_scoring_id);
            handleChangeFirstScoring(chosen);
        }
    }, [answerItem, setChosenFirstScoring, handleChangeFirstScoring, firstScoring]);
    // Create Form First
    useEffect(() => {
        if (question.id) {
            validation(question);
        }
    }, [question])
    // Fetch Data If Have
    useEffect(() => {
        if (answerItem) {
            fetchData();
        }
    }, [answerItem])

    return (
        <>
            { question &&
                <div className={`first-scoring-question-container ${getError(errors, question) ? 'has-error' : ''}`}>
                    <Row className="mb-3">
                        <Col md="12">
                            <h2 className='header mb-3 text-uppercase p-2 text-white'>{question.type.title}</h2>
                        </Col>
 
                        <Col md="12">
                            <h3 className='mb-3'>{question.title}</h3>
                        </Col>
                        {/* First Scoring */}
                        <Col md={12}>
                            {firstScoring.map((q, index) => (
                                <div key={`custom-${q.id}`} className="mb-3">
                                    <Form.Check
                                        custom
                                        name={`${question.type.category_sku}.${question.type_sku}`}
                                        type={'radio'}
                                        id={`custom-${q.id}`}
                                        label={q.title}
                                        checked={chosenFirstScoring ? chosenFirstScoring.id === q.id : false}
                                        onChange={() => handleChangeFirstScoring(q)}
                                    />
                                </div>
                            ))}
                        </Col>
                        <Col md="12">
                            <div className="invalid-feedback"> You must chose a option</div>
                        </Col>
                        <Col md={12}>
                            <hr />
                        </Col>
                    </Row>
                </div>
            }
        </>
    )
}

export default QuestionBlockFirstScoring;