import React from "react";
// Questions
import QuestionBlockFirstScoring from "./QuestionBlockFirstScoring";
// Forms
interface CategoryBlockProps {
    questions: any,
    category: any,
    name: string,
    lastAnswer: any,
}
const CategoryBlockFirstScoring = (props: CategoryBlockProps) => {    
    // Props
    const { questions, category, name, lastAnswer } = props;

    return (
        <>
            {questions.map((question, index) => {
                const firstScoring = question.items.filter(i => i.type_sku === 'first-scoring');
                let answerItem = null;
                if(lastAnswer && lastAnswer.answer_items.length > 0){
                    answerItem = lastAnswer.answer_items.find(_answerItem => _answerItem.question_type_sku === question.type_sku);
                }
                return (<QuestionBlockFirstScoring key={index} question={question} firstScoring={firstScoring} answerItem={answerItem}  />)
            })}            
        </>
    )
}

export default CategoryBlockFirstScoring;