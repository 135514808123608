import gql from 'graphql-tag'

export const STARTUP_FIELDS = gql`
fragment StartupFields on Startup {
    id
    name
    stage_sku
    situation_sku
    stage_text
    situation_text
    created_at
    updated_at
    business_category_text
    business_type_1_text
    business_type_2_text       
    primary_account{
        id
        email
        first_name
        last_name
    }
    type
    elements_configuration{
        sku
        title
        available
    }
    group_id
}`