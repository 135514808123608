
import React from "react";
// Bootstrap
import FormControl from 'react-bootstrap/FormControl'
import { Hint } from 'react-bootstrap-typeahead'
// import { uuid } from 'uuidv4';

export interface ExtraPropsInterface {
    isValid: boolean, name: string, rbtType: string
}

export const HintedFormControl = (props) => {
    // const randomId = props.name + '_input_' + Math.round(Math.random())
    // Props
    const { selected, onClear, isInvalid, rbtType, inputRef, referenceElementRef } = props;
    // Focus
    const onFocus = event => {
        event.target.setAttribute('autocomplete', 'wathever');
        props.onFocus(event)
    };

    // React-Bootstrap < 1.0.0 takes an `inputRef` rather than forwarding the ref.
    return (
        <Hint>
            <div className={`rbt-input-container ${props.className} ${props.value ? 'with-value' : ''} ${isInvalid ? 'is-invalid' : ''}`}>
                <FormControl
                    // {...props}
                    // id={ randomId}
                    // name={randomId}
                    autoComplete={props.autoComplete}
                    className={props.className}
                    isInvalid={isInvalid}
                    onBlur={props.onBlur}
                    onChange={props.onChange}
                    onClick={props.onClick}
                    onFocus={onFocus}
                    onKeyDown={props.onKeyDown}
                    placeholder={props.placeholder}
                    role={props.role}
                    type={props.type}
                    value={props.value}
                    disabled={props.disabled}

                    ref={(node) => {
                        inputRef(node);
                        referenceElementRef(node);
                    }}
                />
                {(onClear && selected && !props.disabled) && selected.length > 0 &&
                    <div className="rbt-aux rbt-aux-remove" onClick={onClear}>
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
                        </span>
                    </div>
                }
                {rbtType && rbtType === 'select' &&
                    <div className="rbt-aux rbt-aux-caret">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z" /></svg>
                        </span>
                    </div>
                }
                {rbtType && rbtType === 'search' &&
                    <div className="rbt-aux rbt-aux-search">
                        <span className="svg-icon">
                            <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M15.5 14h-.79l-.28-.27c1.2-1.4 1.82-3.31 1.48-5.34-.47-2.78-2.79-5-5.59-5.34-4.23-.52-7.79 3.04-7.27 7.27.34 2.8 2.56 5.12 5.34 5.59 2.03.34 3.94-.28 5.34-1.48l.27.28v.79l4.25 4.25c.41.41 1.08.41 1.49 0 .41-.41.41-1.08 0-1.49L15.5 14zm-6 0C7.01 14 5 11.99 5 9.5S7.01 5 9.5 5 14 7.01 14 9.5 11.99 14 9.5 14z" /></svg>
                        </span>
                    </div>
                }
            </div>
        </Hint>
    )
}

export const renderInputTypehead = (
    inputRef,
    referenceElementRef,
    inputProps,
    extraProps: ExtraPropsInterface) => {
    // const { onClear, selected } = typeaheadManagerProps;
    const { rbtType, isValid } = extraProps;
    return <>
        <HintedFormControl
            {...inputProps}
            referenceElementRef={referenceElementRef}
            inputRef={inputRef}
            rbtType={rbtType ? rbtType : 'select'}
            // name={uuid() + name}
            isInvalid={!isValid}
        />
    </>
}