
/*eslint-disable*/
import React from "react";
// Bootstrap
import Container from "react-bootstrap/Container";
// Router
import { Route, Switch, useRouteMatch } from "react-router-dom";
// Nav
import MultipleStepNavigation from "../../components/multiple-step/MultipleStepNavigation";
// Page
import Page from "../page/Page";

interface MultipleStepProps {
    steps: Array<any>,
    children?: any
}

const MultipleStep = (props: MultipleStepProps) => {
    // Route
    const match = useRouteMatch();
    // console.log('match', match)
    // Props
    const { steps, children } = props;
    // Routes
    const getRoutes = (steps: Array<any>) => {

        return steps.map((prop: any, key: any) => {

            return (
                <Route
                    key={key}
                    exact
                    path={match.path + prop.route}
                    render={props => (
                        <Page {...props}
                            component={prop.component}
                            title={'Startup | ' + prop.name}
                            name={prop.name}
                        // onSetBrandText={onSetBrandText}
                        />
                    )}
                />)
        });
    }
    // Render
    return (
        <div className="multiple-step-wrapper">
            <MultipleStepNavigation steps={steps} />
            {children ? children : null}
            <Container>
                <div className="multiple-step-main-content">
                    <Switch >
                        {getRoutes(steps)}
                    </Switch>
                </div>
            </Container>
        </div>
    )
}

export default MultipleStep;
