import { CHART_COLOR } from "../charts/chart.constants";
// Status
export const CHALLENGES_STATUS = [
    {
        sku: 'passed',
        title: 'Passed',
        color: CHART_COLOR.good
    },
    {
        sku: 'failed',
        title: 'Failed',
        color: CHART_COLOR.poor
    },
    {
        sku: 'in_progress',
        title: 'In Progress',
        color: CHART_COLOR.fair
    },
]
// Priority
export const CHALLENGES_PRIORITY_LEVEL = [
    {
        sku: 'critical',
        title: 'Critical',
    },
    {
        sku: 'medium',
        title: 'Medium',
    },
    {
        sku: 'high',
        title: 'High',
    },
]
// Testability
export const CHALLENGES_PRIORITY_TESTABILITY = [
    {
        sku: 'easy',
        title: 'Easy',
    },
    {
        sku: 'hard',
        title: 'Hard',
    },
]
// Conviction Level
export const CHALLENGES_CONVICTION_LEVEL = [
    {
        sku: 'low',
        title: 'Low',
    },
    {
        sku: 'medium',
        title: 'Medium',
    },
    {
        sku: 'high',
        title: 'High',
    },
]
export const CHALLENGES = [
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 80,
        category: 'Team',
        due_date: new Date(),
        status_sku: 'passed',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 70,
        category: 'Traction',
        due_date: new Date(),
        status_sku: 'failed',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 70,
        category: 'Team',
        due_date: new Date(),
        status_sku: 'in_progress',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 40,
        category: 'Scale',
        due_date: new Date(),
        status_sku: 'in_progress',

    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 70,
        category: 'Traction',
        due_date: new Date(),
        status_sku: 'passed',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 80,
        category: 'Team',
        due_date: new Date(),
        status_sku: 'in_progress',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 80,
        category: 'Scale',
        due_date: new Date(),
        status_sku: 'in_progress',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 70,
        category: 'Traction',
        due_date: new Date(),
        status_sku: 'passed',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 80,
        category: 'Team',
        due_date: new Date(),
        status_sku: 'in_progress',
    },
    {
        title: 'Lorem ipsum dolor',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        progress: 80,
        category: 'Scale',
        due_date: new Date(),
        status_sku: 'in_progress',
    },
]
export const CHALLENGES_SUGGESTIONS = [
    {
        title: 'Challenge #1',
        description: 'Test how the market is growing',
        test_title: 'Call 10 potential clients and ask about how they see the dynamic with current suppliers in your field'
    },
    {
        title: 'Challenge #2',
        description: 'Inventore veritatis et quasi architecto beatae vitae dicta sunt',
        test_title: 'Call 10 potential clients and ask about how they see the dynamic with current suppliers in your field'
    },
    {
        title: 'Challenge #3',
        description: 'Lorem ipsum dolor sit amet, consectetur adipiscing elit.',
        test_title: 'Call 10 potential clients and ask about how they see the dynamic with current suppliers in your field'
    },    
]
export const MY_CHALLENGES = [
    {
        title: 'Sed ut perspiciatis unde',
        description: 'Inventore veritatis et quasi architecto beatae vitae dicta sunt',
        test_title: 'Contact a recommended Comcast contact that could confirm',
        status_sku: 'failed',
        critical: true,
        blocking: true,
        priority_level_sku: 'medium',
        testability_sku: 'easy',
        conviction_level_sku: 'low',        
        metric_type_sku: 'progress',
        progress: 16,
        due_date: new Date(),
        
        
    },
    {
        title: '5G',
        description: 'Available in the US in Q1 2021',
        test_title: 'Contact a recommended Comcast contact that could confirm',
        status_sku: 'in_progress',
        critical: true,
        blocking: true,
        priority_level_sku: 'critical',
        testability_sku: 'hard',
        conviction_level_sku: 'medium',
        metric_type_sku: 'progress' ,
        progress: 72,
        due_date: new Date(),
        
    },
    {
        title: 'At vero eos et accusamus et iusto',
        description: 'Quis autem vel eum iure reprehenderit qui in ea voluptate',
        test_title: 'Contact a recommended Comcast contact that could confirm',
        status_sku: 'passed',
        critical: true,
        blocking: true,
        priority_level_sku: 'high',
        testability_sku: 'easy',
        conviction_level_sku: 'high',
        metric_type_sku: 'kpi',
        due_date: new Date(),
        kpi_metric: 'Number of 5G antenna deployed',
        kpi_metric_target: 50,
        kpi_metric_current: 21
        
    },
    {
        title: 'Ut aut reiciendis voluptatibus',
        description: 'Vel illum qui dolorem eum fugiat quo voluptas nulla pariatur',
        test_title: 'Contact a recommended Comcast contact that could confirm',
        status_sku: 'failed',
        critical: true,
        blocking: true,
        priority_level_sku: 'medium',
        testability_sku: 'hard',
        conviction_level_sku: 'low',
        metric_type_sku: 'kpi',
        due_date: new Date(),
        kpi_metric: 'Number of 5G antenna deployed',
        kpi_metric_target: 50,
        kpi_metric_current: 21
        
    },
    {
        title: '5G',
        description: 'Available in the US in Q1 2021',
        test_title: 'Contact a recommended Comcast contact that could confirm',
        status_sku: 'in_progress',
        critical: true,
        blocking: true,
        priority_level_sku: 'critical',
        testability_sku: 'easy',
        conviction_level_sku: 'medium',
        metric_type_sku: 'kpi',
        due_date: new Date(),
        kpi_metric: 'Number of 5G antenna deployed',
        kpi_metric_target: 50,
        kpi_metric_current: 21
        
    },


]

export const CHALLENGE_NUMBER_FIELDS = ['kpi_metric_target','kpi_metric_current','order']

export  const CHALLENGE_STATUS_COMPLETED = ['failed', 'passed']