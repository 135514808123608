import React from "react";


const PrettyFileSize = (props) => {
    let bytes = props.children;
    const precision = props.precision ? props.precision : 1;
    const units = ['bytes', 'Kb', 'Mb', 'Gb', 'Tb', 'Pb'];
    let unitIndex = 0;

    while (bytes >= 1024) {
        bytes /= 1024;
        unitIndex++;
    }

    const unit = units[unitIndex];
    const hasDecimals =  (bytes + "").split(".").length > 1

    return (
        <>
            {hasDecimals ? bytes.toFixed(precision) + ' ' + unit : bytes.toFixed(0) + ' ' + unit}
        </>
    )
}
export default PrettyFileSize;