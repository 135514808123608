import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
// import { setAnswers } from "../../redux/ducks/questionnaire-duck";
import { getQuestionnaireGuestAnswersFirstScoring, getQuestionnaireGuestAnswersRefinedScoring, getQuestionnaireGuestQuestions, getQuestionnaireGuestUser } from "../../redux/ducks/questionnaire-guest-duck";

// Hook
const useGuestAnswer = () => {
    // Get Necesary Data
    const answersFirstScoring = useSelector(getQuestionnaireGuestAnswersFirstScoring);
    const answersRefinedScoring = useSelector(getQuestionnaireGuestAnswersRefinedScoring);
    const guest = useSelector(getQuestionnaireGuestUser);
    const questions = useSelector(getQuestionnaireGuestQuestions)
    // Initialize state with undefined width/height so server and client renders match
    // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
    const [answer, setAnswer] = useState<any>(null);

    const mapData = useCallback(() => {
        const answer = {
            guest_id: guest.id,
            startup_id: guest.startup_id,
            items: [] as any
        }
        // First Scoring
        const answerKeys = Object.keys(answersFirstScoring);
        for (let aKey of answerKeys) {
            const answerItem = answersFirstScoring[aKey];
            const question = questions.find(q => q.type_sku === aKey);

            const item: any = {
                question_id: question.id,
                question_type_sku: question.type_sku,
                first_scoring_id: answerItem,
                refined_scoring_ids: null
            }

            answer.items.push(item)
        }
        // Refined Scoring
        for (let item of answer.items) {
            if (answersRefinedScoring[item.question_type_sku]) {
                item.refined_scoring_ids = answersRefinedScoring[item.question_type_sku]
            }
        }
        setAnswer(answer);
    }, [setAnswer, answersFirstScoring, answersRefinedScoring, questions, guest])

    useEffect(() => {
        if(guest && answersFirstScoring && questions){
            mapData()
        }
    }, [answersFirstScoring, answersRefinedScoring, questions, guest, mapData]); 

    return answer;
}

export default useGuestAnswer;