export const QUESTIONS_EXTERNAL_OPTIONS = [
    {
        title: '1',
        value: 1,
        color: '#CC3E32',
        text: 'Sucks',
    },
    {
        title: '2',
        value: 2,
        color: '#D35534',
        text: 'Really\nBad',
    },
    {
        title: '3',
        value: 3,
        color: '#DA6A36',
        text: 'Poor\nat Best',
    },
    {
        title: '4',
        value: 4,
        color: '#E07F38',
        text: 'Below\nAverage',
    },
    {
        title: '5',
        value: 5,
        color: '#E6933A',
        text: 'Average',
    },
    {
        title: '6',
        value: 6,
        color: '#DDA03F',
        text: 'Above\nAverage',
    },
    {
        title: '7',
        value: 7,
        color: '#B99D4A',
        text: 'Pretty\nGood',
    },
    {
        title: '8',
        value: 8,
        color: '#969A54',
        text: 'Excellent',
    },
    {
        title: '9',
        value: 9,
        color: '#71975F',
        text: 'Exceptional',
    },
    {
        title: '10',
        value: 10,
        color: '#42936D',
        text: 'Blown Away',
    }
]
