
import React, { useState, useEffect, useCallback } from "react";
// Bootstrap
import Form from 'react-bootstrap/Form'
import { useFormContext } from "react-hook-form";
// Redux
import { useSelector } from 'react-redux';
import {
    getQuestionnaireGuestAnswersFirstScoring
} from '../../../redux/ducks/questionnaire-guest-duck';
interface QuestionPaginationItemProps {
    question: any,
    onSelectOption: Function
}
const QuestionPaginationItem = (props: QuestionPaginationItemProps) => {
    // Answers
    const answersStore = useSelector(getQuestionnaireGuestAnswersFirstScoring);
    // Form
    const { register, setValue } = useFormContext();
    // Props
    const { question, onSelectOption } = props;
    // Item
    const [selected, setSelected] = useState(null)

    const onChangeRadio = (event) => {
        // Target
        const { target } = event;
        // Values
        const { name, value } = target;
        // Set Data
        setSelected(value);
        setValue(name, value, { shouldValidate: true })
        // setTimeout(() => {
        //     onSelectOption()
        // }, 250)

    }
    const onClick = (event) => {
        // Target
        const { target } = event;
        // Values
        const { name, value, checked } = target;

        if (checked) {
            if (value !== selected) {
                setTimeout(() => {
                    onSelectOption()
                }, 250)
            }else{
                onSelectOption()
            }
            

        }

    }
    // Callback
    const fetchData = useCallback(() => {
        const item = answersStore[question.type.sku];
        setSelected(item);
        setValue(`firstScoring.${question.type.sku}`, item, { shouldValidate: true });
    }, [setSelected, question, answersStore])

    // Init
    useEffect(() => {
        if (question && answersStore[question.type.sku]) {
            fetchData()
        }
    }, [fetchData, question])


    return (
        <>
            <div className="radio-group-questions">
                <h4 className="radio-group-title">{question.title}</h4>

                <div className="radio-group-questions-options">
                    {question.firstScoring &&
                        question.firstScoring.length > 0 &&
                        question.firstScoring.map((item, index) => (
                            <div className="radio-group-item" key={`custom-iten-${item.id}`} >
                                <Form.Check
                                    custom
                                    name={`firstScoring.${question.type.sku}`}
                                    ref={register({
                                        required: 'Must choice a option'
                                    })}
                                    type={'radio'}
                                    id={`q-item-${item.id}`}
                                    label={item.title}
                                    value={item.id}
                                    onChange={onChangeRadio}
                                    checked={selected === item.id}
                                    onClick={onClick}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default QuestionPaginationItem;