
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { Controller, FormProvider, useForm } from "react-hook-form";
// Form
import ReactSelect from "../select/ReactSelect";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ADVICE } from "../../queries/advices/advice.query";
// Button Fixed
import ButtonFixed from "../buttons/ButtonFixed";
// Helper
import ObjectHelper from "../../helpers/objects/object.helper";
import HtmlEditor from "../forms/HtmlEditor";
// Props
interface AdviceAddFormProps {
    questionTypes: any,
    categories: any
}
const AdviceAddForm = (props: AdviceAddFormProps) => {
    // Helper
    const objectHelper = new ObjectHelper();
    // Client
    const client = useApolloClient();
    // Form
    const methods = useForm({
        mode: 'onChange'
    });
    // Advice
    const [categoryQuestion, setCategoryQuestion] = useState(null);
    const [typeQuestion, setTypeQuestion] = useState(null);
    const [locked, setLocked] = useState(false)
    const [descriptionContent, setDescriptionContent] = useState('')
    // Loading
    const [loading, setLoading] = useState(false);
    // Options
    const [optionsCategories, setOptionCategories] = useState<any>([]);
    const [optionsType, setOptionsType] = useState<any>([]);
    const [optionsTypeFilter, setOptionsTypeFilter] = useState<any>([]);
    // Props
    const {
        questionTypes,
        categories
    } = props;
    // Reset Form
    const resetForm = () => {
        // States        
        setTypeQuestion(null)
        setCategoryQuestion(null)
        setLocked(false)
        setDescriptionContent(()=> '')
        // Reset
        methods.setValue('description', '')
        methods.setValue('order', '')
        methods.setValue('locked', false)
        methods.setValue('category_sku', null)
        methods.setValue('type_sku', null)
        methods.setValue('title', '')
    }
    // Type Change
    const onChangeTypeQuestion = useCallback((option) => {
        setTypeQuestion(option);
        if (option) {
            methods.setValue('type_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('type_sku', null, { shouldValidate: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTypeQuestion, methods.setValue])
    // Category Change, change filter question types
    const onChangeCategoryQuestion = useCallback((option, event) => {
        setCategoryQuestion(option);
        if (option) {
            methods.setValue('category_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('category_sku', null, { shouldValidate: true })
        }
        if (event.action === 'select-option') {
            let filter = [];
            if (option) {
                filter = optionsType.filter(filter => filter.category_sku === option.value);
            }
            setOptionsTypeFilter(filter);
            onChangeTypeQuestion(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCategoryQuestion, methods.setValue, optionsType, setOptionsTypeFilter, onChangeTypeQuestion])


    // Fetch Data
    const fetchData = useCallback(() => {
        // Map options Categories
        const categoriesMap = categories.map(cat => {
            return {
                label: cat.title,
                value: cat.sku
            }
        })
        setOptionCategories(categoriesMap);
        // Map options Question Types
        const typesMap = questionTypes.map(type => {
            return {
                category_sku: type.category_sku,
                label: type.title,
                value: type.sku
            }
        })
        setOptionsType(typesMap);
        // Default Metric Type
        methods.setValue('metric_type_sku', 'kpi')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // Options type
        setOptionsType,
        questionTypes,
        // Categories
        setOptionCategories,
        categories,
        methods.setValue
    ])
    // Init
    useEffect(() => {
        if (categories && questionTypes) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, questionTypes])

    // Create
    const onClickCreate = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.formState.errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            createAdvice()
        }
    }
    const createAdvice = async () => {
        try {
            setLoading(true)
            const values = methods.getValues()
            const toCreate: any = {
                ...values,
            }
            delete toCreate.category_sku;
            // Parse
            objectHelper.parseFieldToInt(toCreate, ['order'])

            const createAdviceResp = await client.mutate({
                mutation: CREATE_ADVICE,
                variables: { data: toCreate }
            })
            const advice = createAdviceResp.data.createAdvice || {}
            if (advice.id) {
                toast.success(`Advice created succefully!`);
                resetForm()
            } else {
                throw new Error('Cannot create')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }
    return (
        <LoadingOverlay active={loading}
            text={'Creating Advice...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods}>


                <Form noValidate name={'create-advice'}>
                    {/* Relation */}
                    <Row>
                        {/* Challenge*/}
                        <Col lg="9">
                            <Row>
                                {/* Main element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.category_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Main element</Form.Label>
                                        <Controller
                                            name="category_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Main element is required' }}
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a main-element"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.category_sku}
                                                    options={optionsCategories}
                                                    onChange={onChangeCategoryQuestion}
                                                    value={categoryQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {methods.errors.category_sku && <div className="invalid-feedback"> {methods.errors.category_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                                {/* Sub element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.type_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Sub element</Form.Label>
                                        <Controller
                                            name="type_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Sub element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a sub-element"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.type_sku}
                                                    options={optionsTypeFilter}
                                                    onChange={onChangeTypeQuestion}
                                                    value={typeQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />
                                        {methods.errors.type_sku && <div className="invalid-feedback"> {methods.errors.type_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Order */}
                    <Row>
                        <Col lg="3">
                            <Form.Group >
                                <Form.Label>Order</Form.Label>
                                <Form.Control
                                    name="order"
                                    type="number"
                                    placeholder="Enter a Order"
                                    isInvalid={methods.errors.kpi_metric}
                                    ref={methods.register({
                                        required: 'Order is required',
                                    })}
                                />
                                {/* Error */}
                                {methods.errors.order && <div className="invalid-feedback"> {methods.errors.order.message} </div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Locked */}
                    <Row>
                        <Col lg="3">
                            <Controller
                                name='locked'
                                control={methods.control}
                                render={(props) => (
                                    <Form.Check
                                        custom
                                        type={'checkbox'}
                                        id={`create-modal-locked`}
                                        label={`Locked`}
                                        name="locked"
                                        value={'locked'}
                                        checked={locked}
                                        onChange={(event: any) => {
                                            const { checked } = event.target;
                                            props.onChange(checked)
                                            setLocked(checked)
                                        }}
                                    />
                                )} />
                        </Col>
                    </Row>
                    {/* Challenge */}
                    <Row>
                        <Col lg="9">
                            <hr />
                        </Col>
                    </Row>
                    {/* Title */}
                    <Row>
                        <Col lg="9">
                            <Form.Group >
                                <Form.Label>Title</Form.Label>
                                <Form.Control
                                    name="title"
                                    type="text"
                                    placeholder="Enter a Title"
                                    isInvalid={methods.errors.title}
                                    ref={methods.register({ required: 'The title is required' })}
                                />
                                {methods.errors.title && <div className="invalid-feedback"> {methods.errors.title.message} </div>}

                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Description */}
                    <Row>
                        <Col lg="9">
                            <Form.Group className={methods.errors.description ? 'is-invalid' : ''}>
                                <Form.Label>Description</Form.Label>
                                <Controller
                                    render={(props) => (
                                        <HtmlEditor
                                            value={descriptionContent}
                                            onChange={(value) => {
                                                props.onChange(value)
                                                setDescriptionContent(value)
                                            }}
                                        />
                                    )}
                                    name="description"
                                    rules={{ required: 'Description is required' }}
                                    control={methods.control}

                                />
                                {/* Error */}
                                {methods.errors.description && <div className="invalid-feedback"> {methods.errors.description.message} </div>}

                            </Form.Group>
                        </Col>
                    </Row>

                    {/* Button */}
                    <ButtonFixed onClick={onClickCreate} loading={loading} />

                </Form>
            </FormProvider>
        </LoadingOverlay>
    )
}

export default AdviceAddForm;