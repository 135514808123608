
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
// Validation
import { useForm } from "react-hook-form";
// Loader
import { MoonLoader } from "react-spinners";
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_CATEGORY } from "../../queries/categories/categories.query";
// import { UPDATE_QUESTION_PRIORIZATION } from '../../../queries/questions/questions.query';

interface CategoryEditWeightsProps {
    categories: any,
}
const CategoryEditWeights = (props: CategoryEditWeightsProps) => {
    // Client
    const client = useApolloClient();
    // Keys numbers 
    const keysNumbers = [
        'weight_excitement_pre_seed',
        'weight_confidence_pre_seed',
        'weight_excitement_seed',
        'weight_confidence_seed',
        'weight_excitement_series_a',
        'weight_confidence_series_a',

    ]
    // States 
    const [loading, setLoading] = useState(false);
    // Validation
    const { register, errors, getValues, trigger } = useForm({
        mode: 'onChange'
    });
    // Props
    const {
        categories
    } = props;
    // Get Error
    const getError = (prefix, index, key) => {
        if (errors) {
            if (errors[prefix] && errors[prefix][index]) {
                return errors[prefix][index][key]
            }
        }
        return null;
    }

    const onClickUpdate = async () => {
        await trigger()
        const errorsKeys = Object.keys(errors);
        if (errorsKeys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updatePriorization()
        }
    }
    const updatePriorization = async () => {
        let hasError = false;
        setLoading(true)
        try {
            const categories = getValues().categories;
            console.log(categories);
            for (let i = 0; i < categories.length; i++) {
                const category = categories[i];
                for(let key of keysNumbers){
                    category[key] = parseInt(category[key]);
                }
                const resp = await client.mutate({
                    mutation: UPDATE_CATEGORY,
                    variables: { data: category }
                })
                const updated = resp.data.updateCategory || {}
                if (!updated.id) {
                    throw new Error('Error saving');
                }
            }
        } catch (e) {
            hasError = true;
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }
        if (!hasError) {
            toast.success(`Priorization successfully updated`);
        }
    }
    return (
        <>
            <div className="table-startup-wrapper table-responsive">
                <Form noValidate name={'create-startup'}>
                    <table className='table table-weights-edit' >
                        <thead>
                            <tr>
                                <th colSpan={7} className={'text-center'}>Weights</th>
                            </tr>
                            <tr>
                                <th></th>
                                <th colSpan={2} className={'text-center'}>Pre Seed</th>
                                <th colSpan={2} className={'text-center'}>Seed</th>
                                <th colSpan={2} className={'text-center'}>Series A</th>
                            </tr>
                            <tr>
                                <th className={'thead-title'}>Main Element</th>
                                {/* Pre Seed */}
                                <th className={'text-center'}>Excitement</th>
                                <th className={'text-center'}>Confidence</th>
                                {/* Seed */}
                                <th className={'text-center'}>Excitement</th>
                                <th className={'text-center'}>Confidence</th>
                                {/* Series A */}
                                <th className={'text-center'}>Excitement</th>
                                <th className={'text-center'}>Confidence</th>

                            </tr>
                        </thead>
                        {categories && categories.length > 0
                            &&
                            <tbody>
                                {categories.map((category, index) => {
                                    return (
                                        <tr key={category.id}>
                                            {/* Title & Default */}
                                            <td>
                                                <Form.Control
                                                    type="hidden"
                                                    name={`categories[${index}].id`}
                                                    defaultValue={category.id}
                                                    ref={register}
                                                />
                                                <Form.Control
                                                    type="hidden"
                                                    name={`categories[${index}].sku`}
                                                    defaultValue={category.sku}
                                                    ref={register}
                                                />
                                                {category.title}
                                            </td>
                                            {/* Pree Seed */}
                                            <td className={'input-td'}>

                                                <Form.Control
                                                    name={`categories[${index}].weight_excitement_pre_seed`}
                                                    type="number"
                                                    isInvalid={getError('categories', index, 'weight_excitement_pre_seed')}
                                                    placeholder="Enter a number"
                                                    defaultValue={category.weight_excitement_pre_seed}
                                                    ref={register({
                                                        required: 'The weight is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    name={`categories[${index}].weight_confidence_pre_seed`}
                                                    type="number"
                                                    placeholder="Enter a number"
                                                    isInvalid={getError('categories', index, 'weight_confidence_pre_seed')}
                                                    defaultValue={category.weight_confidence_pre_seed}
                                                    ref={register({
                                                        required: 'The weight is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                            {/* Seed */}
                                            <td className={'input-td'}>

                                                <Form.Control
                                                    name={`categories[${index}].weight_excitement_seed`}
                                                    type="number"
                                                    isInvalid={getError('categories', index, 'weight_excitement_seed')}
                                                    placeholder="Enter a number"
                                                    defaultValue={category.weight_excitement_seed}
                                                    ref={register({
                                                        required: 'The weight is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    name={`categories[${index}].weight_confidence_seed`}
                                                    type="number"
                                                    placeholder="Enter a number"
                                                    isInvalid={getError('categories', index, 'weight_confidence_seed')}
                                                    defaultValue={category.weight_confidence_seed}
                                                    ref={register({
                                                        required: 'The weight is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                            {/* Series A */}
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    name={`categories[${index}].weight_excitement_series_a`}
                                                    type="number"
                                                    isInvalid={getError('categories', index, 'weight_excitement_series_a')}
                                                    placeholder="Enter a number"
                                                    defaultValue={category.weight_excitement_series_a}
                                                    ref={register({
                                                        required: 'The weight is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    name={`categories[${index}].weight_confidence_series_a`}
                                                    type="number"
                                                    placeholder="Enter a number"
                                                    isInvalid={getError('categories', index, 'weight_confidence_series_a')}
                                                    defaultValue={category.weight_confidence_series_a}
                                                    ref={register({
                                                        required: 'The weight is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>
                </Form>
            </div>
            <Button variant="success"
                id="btn-finish-question"
                onClick={onClickUpdate}
                disabled={loading}
                className="btn-fixed fixed-right shadow">
                {!loading && <i className="fas fa-save icon"></i>}
                {loading && <MoonLoader color="#8e8e93" size={40} />}
            </Button>

        </>
    )
}

export default CategoryEditWeights;