
import React, { useState, useEffect, useCallback } from "react";
// Boostrap
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Validation
import { useForm } from "react-hook-form";
import Button from "../buttons/Button";
// Apollo
import { useApolloClient } from "react-apollo";
// Loader
import Spinner from "react-bootstrap/Spinner";
import { CREATE_OR_UPDATE_SETTING, GET_PRIORIZATION_FACTOR } from "../../queries/settings/settings.query";
// Toastify
import { toast } from 'react-toastify';
import { color } from "d3";

const SettingFactorPriorization = (props) => {
    // Client
    const client = useApolloClient();
    // Setting
    const [setting, setSetting] = useState<any>(null);
     // Loading
     const [loading, setLoading] = useState(false);
    // Validation
    const { register, errors, handleSubmit } = useForm({
        mode: 'onChange'
    })
    // Save
    const save = async(data) => {
        try {
            setLoading(true);          
            const setting = {};
            setting[`sku`] = 'settings';
            setting[`priorization_factor`] = parseFloat(data.priorization_factor);
            
            const resp = await client.mutate({
                mutation: CREATE_OR_UPDATE_SETTING,
                variables: { data: setting }
            })
            const settingUpdate = resp.data.createOrUpdateSetting || {};
            if (settingUpdate.sku) {
                toast.success(`Priorization Factor updated succefully`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }

        } catch (e) {
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false);
        }
    }
    // Fetch Data
    const fetchData = useCallback(async () => {
        const settingResp = await client.query({
            query: GET_PRIORIZATION_FACTOR,
        })
        const setting = settingResp.data.getSetting || {};
        setSetting(setting)
    }, [setSetting, client])
    // Init
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    return (
        <>
            { setting && <Form name="update-setting-factor-priorization" noValidate={true} onSubmit={handleSubmit(save)}>
                <Row>
                    <Col md="12">
                        <Form.Group >
                            <Form.Label className={'d-md-flex d-block align-items-md-center'}>Priorization Factor</Form.Label>
                            <Form.Control
                                name={`priorization_factor`}
                                type="number"
                                placeholder="Enter a value"
                                isInvalid={errors.priorization_factor}
                                ref={register({
                                    required: 'Priorization Factor is required',
                                    min: {
                                        value: 0,
                                        message: 'The value must be greater than or equal to 0'
                                    }
                                })}
                                defaultValue={setting.priorization_factor}

                            />
                            {errors.priorization_factor &&
                                <div className="invalid-feedback"> {errors.priorization_factor.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row>
                    <Col md="12">
                        <Button type="submit" disabled={loading} loading={loading}>Update</Button>
                    </Col>
                </Row>
            </Form>}
            { !setting &&
                <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
        </>
    )
}

export default SettingFactorPriorization