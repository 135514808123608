
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_QUESTION_TYPE_BY_ID } from "../../../queries/question-types/question-types.query";
// Routerc
import { useParams } from "react-router-dom";
// Form
import QuestionTypeEditForm from "../../../components/question-types/QuestionTypeEditForm";
// Spinner
import Spinner from "react-bootstrap/Spinner";


const QuestionTypeEditView = (props) => {
    // Question Id
    const { question_type_id } = useParams<any>();

    // Question
    const [questionType, setQuestionType] = useState<any>(null);    
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        console.log('question_type_id', question_type_id)
        const response = await client.query({
            query: GET_QUESTION_TYPE_BY_ID,
            variables: { id: question_type_id },
            fetchPolicy: 'no-cache'
        })
        console.log(response)
        // Type
        const type = response.data.getQuestionTypeById || {};        
        setQuestionType(type)
        // Loading
        setLoading(false);
    }, [
        client,
        setQuestionType,        
        setLoading,
        question_type_id]);
    // Call Fetch Data
    useEffect(() => {
        if (question_type_id) {
            fetchData();
        }
    }, [question_type_id, fetchData])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/sub-elements">Sub Elements</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Sub Element</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Sub Element</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <QuestionTypeEditForm questionType={questionType}  />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default QuestionTypeEditView;