
import React, { useState, useEffect, useCallback } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
import Form from "react-bootstrap/Form";
// Box
import BoxStartup from "../box/BoxStartup";
// Form
import { useForm, Controller } from "react-hook-form";
import Button from "../buttons/Button";
// Apollo
import { useApolloClient } from "react-apollo";
import {
    CREATE_USER_AND_STARTUP,
    UPDATE_USER_AND_STARTUP,
    FIND_USER_BY_ID
} from "../../queries/users/user.query";
// Toastify
import { toast } from 'react-toastify';
// Select
import ReactSelect from "../select/ReactSelect";
// Options
import { STARTUP_SITUATION, STARTUP_STAGES } from "../../constants/startup/startup.constants";

interface CreateStartupAndUserProps {
    onChangeUser?: Function,
    onCreate?: Function,
    userId?: any
}
const CreateStartupAndUser = (props: CreateStartupAndUserProps) => {
    // Props
    const { userId, onChangeUser } = props;
    // Client
    const client = useApolloClient();
    // Loading
    const [loading, setLoading] = useState(false);
    // Stages
    const [startupStage, setStartupStage] = useState<any>(null);
    const [startupStagesOptions] = useState(STARTUP_STAGES);
    // Situation
    const [startupSituation, setStartupSituation] = useState<any>(null);
    const [startupSituationOptions] = useState(STARTUP_SITUATION);

    // Form
    const { register, errors, handleSubmit, setError, setValue, control } = useForm({
        mode: 'onBlur'
    });

    // Change
    const onChangeStartupStage = useCallback((option) => {
        setStartupStage(option);
        if (option) {
            setValue('startup_stage', option.value, { shouldValidate: true })
        } else {
            setValue('startup_stage', null, { shouldValidate: true })
        }
    }, [setStartupStage, setValue])

    const onChangeStartupSituation = useCallback((option) => {
        setStartupSituation(option);
        if (option) {
            setValue('startup_situation', option.value, { shouldValidate: true })
        } else {
            setValue('startup_situation', null, { shouldValidate: true })
        }
    }, [setStartupSituation, setValue])

    const fetchData = useCallback(async () => {
        setLoading(true);
        try {
            console.log('find user by id');
            const response = await client.query({
                query: FIND_USER_BY_ID,
                variables: { id: userId }
            })
            console.log(response)
            const user = response.data.findUserById;
            setValue('id', user.id);
            setValue('email', user.email);
            setValue('startup_name', user.startup.name);
            setValue('first_name', user.first_name);
            setValue('last_name', user.last_name);
            setValue('startup_id', user.startup.id);
            if (user.phone) {
                setValue('phone', user.phone);
            }
            if (user.startup.stage_sku) {
                const value = startupStagesOptions.find(v => v.value === user.startup.stage_sku)
                onChangeStartupStage(value)
            }
            if (user.startup.situation_sku) {
                const value = startupSituationOptions.find(v => v.value === user.startup.situation_sku)
                onChangeStartupSituation(value)
            }
            if (onChangeUser) {
                onChangeUser(user);
            }
        } catch (e) {
            console.log('User not exist', e);
            if (onChangeUser) {
                onChangeUser(null);
            }
        }
        finally {
            setLoading(false);
        }


    }, [client, setLoading, userId, setValue, onChangeStartupStage, startupStagesOptions, onChangeUser]);

    // Fetch Data
    useEffect(() => {
        if (userId) {
            fetchData()
        }
    }, [userId])
    // Save
    const save = async (data) => {
        if (userId) {
            handleUpdated(data)
        } else {
            handleCreated(data);
        }
    }
    const handleCreated = async (data) => {
        setLoading(true);
        try {
            // Make company
            const startup = {
                name: data.startup_name,
                stage_sku: data.startup_stage,
                situation_sku: data.startup_situation
            }
            const user = {
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone ? data.phone : null,
                type: 'user',
                active: false,
                primary_account: true
            }
            const response = await client.mutate({
                mutation: CREATE_USER_AND_STARTUP,
                variables: { user: user, startup: startup }
            })
            console.log(response);
            console.log(user, startup)
            const userCreated = response.data.createUserAndStartup;
            if (!userCreated.id) {
                toast.error(`Oops...An error occurred. Try again later`);
            } else {
                toast.success(`Startup successfully created`);
                if (props.onCreate) {
                    props.onCreate(userCreated)
                }
            }


        } catch (e) {
            console.log(e)
            const error = (e.graphQLErrors) ? e.graphQLErrors[0].message : undefined;
            if (error && error.includes('already exist')) {
                setError('email', {
                    type: 'exist',
                    message: 'Email address alredy exist ',
                    shouldFocus: true,
                });
                toast.error(`Email address alredy exist`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
        } finally {
            setLoading(false);
        }
    }
    const handleUpdated = async (data) => {
        setLoading(true);
        try {
            // Make company
            const startup = {
                id: data.startup_id,
                name: data.startup_name,
                stage_sku: data.startup_stage,
                situation_sku: data.startup_situation
            }
            const user = {
                id: data.id,
                email: data.email,
                first_name: data.first_name,
                last_name: data.last_name,
                phone: data.phone ? data.phone : null,
                active: false,
                primary_account: true
            }
            console.log(user, startup)

            const response = await client.mutate({
                mutation: UPDATE_USER_AND_STARTUP,
                variables: { user: user, startup: startup }
            })

            const userCreated = response.data.updateUserAndStartup;
            if (!userCreated.id) {
                toast.error(`Oops...An error occurred. Try again later`);
            } else {
                toast.success(`Startup successfully updated`);
                if (onChangeUser) {
                    onChangeUser(userCreated);
                }
            }


        } catch (e) {
            console.log(e)
            const error = (e.graphQLErrors) ? e.graphQLErrors[0].message : undefined;
            if (error && error.includes('already exist')) {
                setError('email', {
                    type: 'exist',
                    message: 'Email address alredy exist ',
                    shouldFocus: true,
                });
                toast.error(`Email address alredy exist`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
        } finally {
            setLoading(false);
        }
    }
    return (
        <Container>
            < BoxStartup >
                <BoxStartup.Header>
                    <span className="title">{userId ? 'Update Information' : 'First fill in the company data'}</span>
                </BoxStartup.Header>
                <BoxStartup.Body>
                    <Form noValidate name={'create-startup'}>                        
                        {userId && <Form.Control name="id" type="hidden" placeholder="Enter a Email" ref={register} />}
                        {userId && <Form.Control name="startup_id" type="hidden" placeholder="Enter a Email" ref={register} />}
                        {/* Email */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="userEmail">
                                    <Form.Label>Email</Form.Label>
                                    <Form.Control
                                        name="email"
                                        type="text"
                                        placeholder="Enter a Email"
                                        isInvalid={errors.email}
                                        readOnly={userId}
                                        ref={register({
                                            required: 'Email is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                    {errors.email &&
                                        <div className="invalid-feedback"> {errors.email.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* Company name */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="companyName">
                                    <Form.Label>Company Name</Form.Label>
                                    <Form.Control
                                        name="startup_name"
                                        type="text"
                                        placeholder="Enter a Company name"
                                        isInvalid={errors.startup_name}
                                        ref={register({
                                            required: 'Company name is required'
                                        })}
                                    />
                                    {errors.startup_name &&
                                        <div className="invalid-feedback"> {errors.startup_name.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* Company stage */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="formGroupKeyProperty" className={`${errors.init_date ? 'is-invalid' : ''}`}>
                                    <Form.Label>Company Stage</Form.Label>

                                    <Controller
                                        name="startup_stage"
                                        control={control}
                                        defaultValue={false}
                                        rules={{ required: 'Company stage is required' }}
                                        default
                                        render={props =>
                                            <ReactSelect
                                                placeholder="Select a stage"
                                                isClearable={true}
                                                isInvalid={errors.startup_stage}
                                                options={startupStagesOptions}
                                                onChange={onChangeStartupStage}
                                                value={startupStage}
                                            />
                                        } // props contains: onChange, onBlur and value
                                    />

                                    {errors.startup_stage && <div className="invalid-feedback"> {errors.startup_stage.message} </div>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {/* Situation */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="formGroupKeyProperty" className={`${errors.init_date ? 'is-invalid' : ''}`}>
                                    <Form.Label>Company Situation</Form.Label>

                                    <Controller
                                        name="startup_situation"
                                        control={control}
                                        defaultValue={false}
                                        rules={{ required: 'Company situation is required' }}
                                        default
                                        render={props =>
                                            <ReactSelect
                                                placeholder="Select a situation"
                                                isClearable={true}
                                                isInvalid={errors.startup_situation}
                                                options={startupSituationOptions}
                                                onChange={onChangeStartupSituation}
                                                value={startupSituation}
                                            />
                                        } // props contains: onChange, onBlur and value
                                    />

                                    {errors.startup_situation && <div className="invalid-feedback"> {errors.startup_situation.message} </div>}

                                </Form.Group>
                            </Col>
                        </Row>
                        {/* First Name */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="firstName">
                                    <Form.Label>First Name</Form.Label>
                                    <Form.Control
                                        name="first_name"
                                        type="text"
                                        placeholder="Enter a First Name"
                                        isInvalid={errors.first_name}
                                        ref={register({
                                            required: 'First Name is required'
                                        })}
                                    />
                                    {errors.first_name &&
                                        <div className="invalid-feedback"> {errors.first_name.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* Last Name */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="lastName">
                                    <Form.Label>Last Name</Form.Label>
                                    <Form.Control
                                        name="last_name"
                                        type="text"
                                        placeholder="Enter a Last Name"
                                        isInvalid={errors.last_name}
                                        ref={register({
                                            required: 'Lasr Name is required'
                                        })}
                                    />
                                    {errors.last_name &&
                                        <div className="invalid-feedback"> {errors.last_name.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* Phone number */}
                        <Row>
                            <Col lg="8">
                                <Form.Group controlId="phoneNumber">
                                    <Form.Label>Phone Number</Form.Label>
                                    <Form.Control
                                        name="phone"
                                        type="text"
                                        placeholder="Enter a Phone Number"
                                        ref={register}
                                        maxLength={15}
                                    />
                                </Form.Group>
                            </Col>
                        </Row>
                        {/* Buttons */}
                        <Row>
                            <Col md="12">
                                <div className="form-inside-footer">
                                    <Button
                                        type={'submit'}
                                        disabled={loading}
                                        loading={loading}
                                        className="font-weight-normal"
                                        variant="primary"
                                        onClick={handleSubmit(save)}>
                                        {userId ? 'Update' : 'Create'}
                                    </Button>
                                </div>
                            </Col>
                        </Row>
                    </Form>
                </BoxStartup.Body>
            </BoxStartup >
        </Container>
    )
}

export default CreateStartupAndUser;