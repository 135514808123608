
import React, { useState } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import CategoryBlockRefinedScoringAdmin from "./refined-scoring/CategoryBlockRefinedScoringAdmin";


interface RefinedScoringProps {
    questions: any,
    categories: any,
    lastAnswer: any

}
const RefinedScoringAdmin = (props: RefinedScoringProps) => {
    // Tabs
    const [key, setKey] = useState('team');
    // Props
    const {
        questions,
        categories,
        lastAnswer
    } = props;
    return (
        <>
            {/* Tabs */}
            { categories && questions && <div className="tabs-answers-container">
                <Tab.Container id="left-tabs-example"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                >
                    <Row>
                        <Col lg={3} className="d-flex">
                            <Nav variant="pills" className="flex-row flex-lg-column w-100 nav-pills-primary">
                                {categories.map(category => (
                                    <Nav.Item key={category.id}>
                                        <Nav.Link eventKey={category.sku}>{category.title}</Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col lg={9} >
                            <Tab.Content className="tab-content-primary tab-content">
                                {categories.map(category => {
                                    const categoryQuestions = questions.filter(q => q.type.category_sku.includes(category.sku))
                                    return (
                                        <Tab.Pane eventKey={category.sku} key={category.id}>
                                            <CategoryBlockRefinedScoringAdmin
                                                name={category.sku}
                                                questions={categoryQuestions}
                                                category={category}
                                                lastAnswer={lastAnswer}
                                            />
                                        </Tab.Pane>
                                    )
                                })}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>}
        </>
    )
}

export default RefinedScoringAdmin;