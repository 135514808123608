
/*eslint-disable*/
import React from "react";

const ThanksGuest = (props) => {

    // Render
    return (
        <>
            <h4 className="text-center">
                Thank you for answering the questionnaire. An email has been sent to inform the person who invited you. <br /><br />
            </h4>

        </>
    )
}

export default ThanksGuest;
