import React, { useState, useEffect, useCallback } from 'react'
// Core
import Button from '../buttons/Button';
// Bootstrap
import Modal from 'react-bootstrap/Modal'
// Toastify
import { toast } from 'react-toastify';
// Apollo
import { useApolloClient } from 'react-apollo';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    getChallengeToDelete,
    challengeToDelete as challengeToDeleteFn,
    challengeCreate
} from '../../redux/ducks/challenge-duck';
import { DELETE_CHALLENGE } from '../../queries/challenges/challenge.query';



// Props
interface ModalAddChallengeProps {
    onClose?: Function,
    onSave?: Function,
}

const ModalDeleteChallenge = (props: ModalAddChallengeProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Selector
    const challengeToDelete = useSelector(getChallengeToDelete);
    // Client
    const client = useApolloClient();
    // Loading 
    const [loading, setLoading] = useState(false);
    // Show
    const [show, setShow] = useState(false);
    // Fetch Show
    const fetchShow = useCallback(() => {
        if (challengeToDelete && challengeToDelete.id) {
            setShow(true);
        } else {
            setShow(false);
        }

    }, [setShow, challengeToDelete])
    // Close
    const handleClose = () => {
        dispatch(challengeToDeleteFn(null))
    }

    // Save
    const handleDelete = async () => {
        try {
            setLoading(true)
            const responseDelete = await client.mutate({
                mutation: DELETE_CHALLENGE,
                variables: { id: challengeToDelete.id }
            })
            const challengeDeleted = responseDelete.data.deleteChallenge || {}
            if (challengeDeleted.id) {
                // Dispatch
                dispatch(challengeCreate(challengeDeleted))
                // Close
                dispatch(challengeToDeleteFn(null))
                // Message Success
                toast.success('Challenge deleted succefully!')

            } else {
                throw new Error('Cannot Delete')
            }
        } catch (e) {
            console.log(e)
            toast.error('Opps...Error ocurred. Try later...')
        } finally {
            setLoading(false)
        }
    }

    // Show
    useEffect(() => {
        fetchShow()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [challengeToDelete])

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                enforceFocus={true}
                backdrop={'static'}>
                <Modal.Header>
                    <Modal.Title>Delete Challenge</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <h5 className="text-dark mb-4">Arew your sure to delete challenge <span className='font-weight-medium'>{challengeToDelete ? challengeToDelete.title : ''}</span>?</h5>
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={loading}
                        variant="light"
                        onClick={handleClose}>
                        Cancel
                        </Button>
                    <Button
                        variant="danger"
                        disabled={loading}
                        loading={loading}
                        loadingText={'Deleting'}
                        onClick={handleDelete}
                    >
                        Delete
                        </Button>
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalDeleteChallenge;