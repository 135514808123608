

import gql from 'graphql-tag';
import { GUEST_INVITATION_FIELDS } from '../guest-invitation/guest-invitation.fragment';

import { GUEST_FIELDS } from './guest.fragment';
// Query
export const LIST_GUEST_BY_TYPE = gql`  
        ${GUEST_FIELDS}              
        ${GUEST_INVITATION_FIELDS}              
        query listGuestByType($type: String!){                     
            listGuestByType(type:$type){
                ...GuestFields
                invitations{
                    ...GuestInvitationFields
                }
            }
        }
      
`
export const GET_GUEST_BY_EMAIL_AND_TYPE = gql`  
        ${GUEST_FIELDS}              
        query getGuestByEmailAndType($type: String!, $email: String!){                     
            getGuestByEmailAndType(type:$type, email: $email){
                ...GuestFields
            }
        }
      
`


export const RESOLVE_TOKEN_GUEST = gql`  
        ${GUEST_FIELDS}              
        query resolveTokenGuest($token: String!){                     
            resolveTokenGuest(token:$token){
                ...GuestFields
            }
        }
      
`

// Mutation
export const CREATE_GUEST = gql`  
        ${GUEST_FIELDS}              
        mutation createGuest($data: GuestInput!){                     
            createGuest(data:$data){
                ...GuestFields
            }
        }
      
`

export const CREATE_OR_UPDATE_GUESTS_EXTERNAL = gql`  
        ${GUEST_FIELDS}              
        mutation createOrUpdateGuestsExternal($data: GuestExternalInput!){                     
            createOrUpdateGuestsExternal(data:$data){
                ...GuestFields
            }
        }
      
`
export const SEND_INVITATIONS_GUESTS_EXTERNAL = gql`  
        ${GUEST_FIELDS}              
        mutation sendInvitationsGuestsExternal($data: GuestExternalInput!){                     
            sendInvitationsGuestsExternal(data:$data){
                ...GuestFields
            }
        }
      
`



export const UPDATE_GUEST = gql`  
        ${GUEST_FIELDS}              
        mutation updateGuest($data: GuestInput!){                     
            updateGuest(data:$data){
                ...GuestFields
            }
        }
      
`
export const GUEST_FINISH_ANSWERED = gql`  
        ${GUEST_FIELDS}              
        mutation guestFinishAnswered($data: GuestInput!){                     
            guestFinishAnswered(data:$data){
                ...GuestFields
            }
        }
      
`


export const DELETE_ALL_GUEST_PER_TYPE = gql`             
        mutation deleteAllGuestPerType($type: String!){                     
            deleteAllGuestPerType(type:$type)
        }
      
`
export const DELETE_GUEST = gql`    
        ${GUEST_FIELDS}          
        mutation deleteGuest($id: String!){                     
            deleteGuest(id:$id){
                ...GuestFields
            }
        }
      
`
export const DELETE_GUEST_EXTERNAL = gql`    
        ${GUEST_FIELDS}          
        mutation deleteGuestExternal($id: String!, $group_id: String!){                     
            deleteGuestExternal(id:$id, group_id:$group_id){
                ...GuestFields
            }
        }
      
`





