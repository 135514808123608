import gql from 'graphql-tag';
import { SETTING_FIELDS } from './setting.fragment';

// MUTATION
export const CREATE_OR_UPDATE_SETTING = gql`        
        ${SETTING_FIELDS}
        mutation createOrUpdateSetting($data: SettingInput!){
            createOrUpdateSetting(data: $data){
                ...SettingFields
             }            
           
        }`


export const GET_SETTING = gql`        
        ${SETTING_FIELDS}
        query getSetting{
            getSetting{
                ...SettingFields
             }            
        }`

export const GET_PRIORIZATION_FACTOR = gql`        
        query getSetting{
            getSetting{
                priorization_factor
             }            
        }`