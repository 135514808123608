import gql from 'graphql-tag'

export const QUESTION_ITEM_FIELDS = gql`
fragment QuestionItemFields on QuestionItem {
    id
    question_id 
    title
    type_sku
    weight    
    order
    active
    checked
    situations_sku
}`



