
import gql from 'graphql-tag'
import { FILE_MODEL_FIELDS } from '../file-model/file-model.fragment'

export const MASTER_SESSION_FIELDS = gql`
${FILE_MODEL_FIELDS}
fragment MasterSessionFields on MasterSession {    
    id
    group_id
    topic
    topic_sku
    description
    number_of_slides
    reading_time
    document{
        ...FileModelFields
    }
    active
    created_at
    updated_at
}`
