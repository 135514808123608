import React, { useState, useEffect, useCallback } from "react";
// Core
import Button from "../buttons/Button";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// Toastify
import { toast } from "react-toastify";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getSubElement } from "../../redux/ducks/dashboard-duck";
import { getUser } from "../../redux/ducks/user-duck";
import {
  answerUpdateRefinedScoring,
  getAnswerLastAnswer,
  getAnswerQuestion,
} from "../../redux/ducks/answer-duck";
import {
  getQuestionnaireAnswers,
  setAnswers,
} from "../../redux/ducks/questionnaire-duck";
import BoxStartup from "../box/BoxStartup";

// Props
interface UpdateRefinedScoringProps {
  onClose?: Function;
  onSave?: Function;
}

const UpdateRefinedScoring = (props: UpdateRefinedScoringProps) => {
  // Dispatch
  const dispatch = useDispatch();
  // Selector
  const user = useSelector(getUser);
  const question = useSelector(getAnswerQuestion);
  const lastAnswer = useSelector(getAnswerLastAnswer);
  const subElement = useSelector(getSubElement);
  const answersQuestionnaire = useSelector(getQuestionnaireAnswers);
  // Client
  const client = useApolloClient();
  // Loading
  const [loading, setLoading] = useState(false);
  // Chosen
  const [selected, setSelected] = useState<any>([]);
  // Fetch Answer
  const fetchAnswer = useCallback(() => {
    if (lastAnswer && subElement) {
      const item = lastAnswer.answer_items.find(
        (item) => item.question_type_sku === subElement
      );
      setSelected(item.refined_scoring_ids ? item.refined_scoring_ids : []);
    }
  }, [subElement, lastAnswer, setSelected]);
  // On Click Radio
  const onClickCheckbox = (event) => {
    const { target } = event;
    const { value } = target;
    const copySelected = [...selected];

    if (copySelected.indexOf(value) < 0) {
      copySelected.push(value);
    } else {
      copySelected.splice(copySelected.indexOf(value), 1);
    }

    setSelected(copySelected);
  };
  
  // Save Fn
  const saveFn = async () => {
    let answerCreate: any = null;
    setLoading(true);
    try {
      const copyLastAnswer = {
        user_id: user.id,
        startup_id: lastAnswer.startup_id,
        items: [] as any,
      };
      for (let item of lastAnswer.answer_items) {
        let refined_scoring_ids = item.refined_scoring_ids;
        if (item.question_type_sku === subElement) {
          refined_scoring_ids = selected;
        }
        const newItem: any = {
          question_id: item.question_id,
          question_type_sku: item.question_type_sku,
          first_scoring_id: item.first_scoring_id,
          refined_scoring_ids: refined_scoring_ids,
        };
        copyLastAnswer.items.push(newItem);
      }
      // Mutate
      const response = await client.mutate({
        mutation: CREATE_ANSWER,
        variables: { data: copyLastAnswer },
      });
      console.log("response", response);
      answerCreate = response.data.createAnswer || null;
      //console.log(copyLastAnswer);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    return answerCreate;
  };

  // Save
  const handleSave = async () => {
    const answerCreate = await saveFn();
    console.log("answerCreate created", answerCreate);
    if (answerCreate && answerCreate.id) {
      if (props.onSave) {
        props.onSave(answerCreate);
      }
      if (answersQuestionnaire) {
        console.log("questionnaire is started");
        if (answersQuestionnaire[question.type.sku]) {
          answersQuestionnaire[question.type.sku] = selected;
          dispatch(setAnswers({ answersQuestionnaire }));
        }
      }
      // Message Success
      toast.success("Scoring Refining saved succefully!");
      // Close Modal
      dispatch(answerUpdateRefinedScoring(false));
    } else {
      toast.error("Opps...Error ocurred. Try later...");
    }
  };

  // Show
  useEffect(() => {
    fetchAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxStartup >
      <BoxStartup.Header>
        <span className="title">
          {`Score Refining`}
        </span>
      </BoxStartup.Header>
      <BoxStartup.Body>
        <h5 className="text-dark mb-4">{question ? question.title : ""}</h5>
        {/* Items */}
        {question &&
          question.refinedScoring.map((item, index) => (
            <Row key={`answer-first-scoring-${item.id}`}>
              <Col lg="12">
                <Form.Group>
                  <Form.Check
                    custom
                    name={`${question.type.sku}`}
                    type={"checkbox"}
                    id={`q-item-${item.id}`}
                    label={item.title}
                    value={item.id}
                    checked={selected.filter((i) => i === item.id).length > 0}
                    onChange={onClickCheckbox}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
        <Row>
          <Col lg="12">
            <Button
              disabled={loading}
              loading={loading}
              loadingText={"Saving"}
              onClick={handleSave}
              className="btn-violet"
            >
              Save
            </Button>
          </Col>
        </Row>
      </BoxStartup.Body>
    </BoxStartup>
  );
};

export default UpdateRefinedScoring;
