
/*eslint-disable*/
import React from "react";
// Props
interface TypeItemFoundersProps {
    type: any
}


const TypeItemFounders = (props: TypeItemFoundersProps) => {
    const { type } = props
    // Render
    return (
        <div className="type-item-founders">
            <span className="type-item-founders-title">{type.type_title}</span>
            <span className={`status-founders bg-${type.spread_status}`}></span>
        </div>

    )
}

export default TypeItemFounders;
