// questionnaire-guest-duck.js
// Actions
const RESET = 'startup/questionnaire-guest-external/RESET';
const RESET_ANSWERS = 'startup/questionnaire-guest-external/RESET_ANSWERS';
const UPDATE_ADVANCE = 'startup/questionnaire-guest-external/UPDATE_ADVANCE';
// Question Page
const UPDATE_QUESTIONS_PAGE= 'startup/questionnaire-guest-external/UPDATE_QUESTIONS_PAGE';
// Questions
const SET_QUESTIONS = 'startup/questionnaire-guest-external/SET_QUESTIONS';
const SET_INVITATION = 'startup/questionnaire-guest-external/SET_INVITATION';
// Answers
const SET_ANSWERS = 'startup/questionnaire-guest-external/SET_ANSWERS';
// Questionanire Page
const SET_QUESTIONNAIRE_PAGE = 'startup/questionnaire-guest-external/SET_QUESTIONNAIRE_PAGE';
// Action Creators
export function resetQuestionnaireGuest() {
    return {
        type: RESET
    };
}
export function resetAnswersGuest() {
    return {
        type: RESET_ANSWERS
    };
}

export function updateAdvanceGuest(payload: any) {
    return {
        type: UPDATE_ADVANCE,
        payload: payload
    };
}

export function updateQuestionsPage(payload: any) {
    return {
        type: UPDATE_QUESTIONS_PAGE,
        payload: payload
    };
}
export function setQuestionsGuest(state: []) {
    return {
        type: SET_QUESTIONS,
        payload: state
    };
}
// ANSWERS
export function setAnswersGuest(state) {
    return {
        type: SET_ANSWERS,
        payload: state
    };
}

export function setQuestionnaireGuestPage(state) {
    return {
        type: SET_QUESTIONNAIRE_PAGE,
        payload: state
    };
}
export function setQuestionnaireInvitation(state) {
    return {
        type: SET_INVITATION,
        payload: state
    };
}
// Interface
interface QuestionnaireState {
    questionnaire_page: any,
    questions: any,
    questions_page: number,
    answers: any,
    invitation: any,
    advance: number,
}
// Percentage total 11 pages
// 1 = 9 | 4 pages 36%
// 7 = 64% | questions
// Initial State
const initialState: QuestionnaireState = {
    questionnaire_page: null,
    questions: [],
    questions_page: 1,
    answers: {},
    invitation: null,
    advance: 0,
};
// Reducer
export default function reducer(state: QuestionnaireState = initialState, action: any = {}) {
    // console.log('Questionnaire', action)
    switch (action.type) {
        // Update Startup Data
        case RESET: {
            return Object.assign(
                {},
                state,
                {
                    ...initialState
                }
            );
        }
        case RESET_ANSWERS: {
            return Object.assign(
                {},
                state,
                {
                    answers: {}
                }
            );
        }
        // Update Advance
        case UPDATE_ADVANCE: {
            return Object.assign(
                {},
                state,
                {
                    advance: action.payload
                }
            );
        }
        // Update Questions Page
        case UPDATE_QUESTIONS_PAGE: {
            return Object.assign(
                {},
                state,
                {
                    questions_page: action.payload
                }
            );
        }
        // Set Questions (type)
        case SET_QUESTIONS:
            return Object.assign(
                {},
                state,
                {
                    questions: action.payload || []
                }
            );
        // Set Answers 
        case SET_ANSWERS:
            const answers = { ...state.answers, ...action.payload };
            return Object.assign(
                {},
                state,
                {
                    answers: answers
                }
            );
        // Set Questionnaire Page
        case SET_QUESTIONNAIRE_PAGE:
            return Object.assign(
                {},
                state,
                {
                    questionnaire_page: action.payload
                }
            );
        // Set User
        case SET_INVITATION:
            return Object.assign(
                {},
                state,
                {
                    invitation: action.payload
                }
            );

        default:
            return state;
    }
}



// SELECTORS
export const getQuestionnaireGuestAdvance = (state) => state.questionnaireGuestExternal.advance;
export const getQuestionnaireGuestQuestions = (state) => state.questionnaireGuestExternal.questions;
// Questions Page
export const getQuestionnaireGuestQuestionsPage = (state) => state.questionnaireGuestExternal.questions_page;
// Answers
export const getQuestionnaireGuestAnswers = (state) => state.questionnaireGuestExternal.answers;
// Questionnaire Page
export const getQuestionnaireGuestPage = (state) => state.questionnaireGuestExternal.questionnaire_page;
// User
export const getQuestionnaireInvitation = (state) => state.questionnaireGuestExternal.invitation;