
import React from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
// Box
import BoxStartup from "../box/BoxStartup";
// Helper
import DateHelper from "../../helpers/dates/date.helper";
import NumberPretty from "../number/NumberPretty";


interface StartupFundingGlobalProps {
    startup: any,
    categories: any
}
const StartupFundingGlobal = (props: StartupFundingGlobalProps) => {
    // Props
    const { startup, categories } = props;

    return (
        <Container>
            { startup &&
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">
                            Funding Global&nbsp;<b>({startup.stage_text})</b>
                        </span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {categories && categories.length > 0 &&
                            <Row>
                                <Col md="1"></Col>
                                <Col md="10">
                                    <div className="table-startup-wrapper table-responsive table-totals-wrapper">

                                        <table className='table table-totals' >
                                            <thead>
                                                <tr>
                                                    <th className={'thead-question'}></th>
                                                    <th></th>
                                                    <th colSpan={3}>First Scoring</th>
                                                    <th colSpan={3}>Refined Scoring</th>
                                                </tr>
                                            </thead>
                                            <thead>
                                                <tr>
                                                    <th colSpan={2}></th>
                                                    {/* FIRST SCORING */}
                                                    <th className={'thead-value'}>S</th>
                                                    <th className={'thead-value'}>E</th>
                                                    <th className={'thead-value'}>C</th>
                                                    {/* REFINED SCORING */}
                                                    <th className={'thead-value'}>S</th>
                                                    <th className={'thead-value'}>E</th>
                                                    <th className={'thead-value'}>C</th>
                                                </tr>
                                            </thead>
                                            <tbody>
                                                {categories.map((category, index) => {
                                                    const categoryTotal = startup.totals.categories.find(c => c.category_sku === category.sku);

                                                    return (
                                                        <tr key={index}>
                                                            <td>{category.title}</td>
                                                            <td>{index + 1}</td>
                                                            {/* First Scoring */}
                                                            <td><NumberPretty hideZero={true}>{categoryTotal.firstScoring}</NumberPretty></td>
                                                            <td><NumberPretty hideZero={true}>{categoryTotal.excitementFirstScoring}</NumberPretty></td>
                                                            <td><NumberPretty hideZero={true}>{categoryTotal.confidenceFirstScoring}</NumberPretty></td>
                                                            {/* Refined Scoring */}
                                                            <td><NumberPretty hideZero={true}>{categoryTotal.refinedScoring}</NumberPretty></td>
                                                            <td><NumberPretty hideZero={true}>{categoryTotal.excitementRefinedScoring}</NumberPretty></td>
                                                            <td><NumberPretty hideZero={true}>{categoryTotal.confidenceRefinedScoring}</NumberPretty></td>
                                                        </tr>
                                                    )
                                                })}

                                                <tr className="totals">
                                                    <td colSpan={2} className={'bg-secondary text-right text-white font-weight-bold'}>
                                                        Total
                                                </td>
                                                    <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty hideZero={true}>{startup.totals.totalGeneral.firstScoring}</NumberPretty></td>
                                                    <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty hideZero={true}>{startup.totals.totalGeneral.firstScoringExcitement}</NumberPretty></td>
                                                    <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty hideZero={true}>{startup.totals.totalGeneral.firstScoringConfidence}</NumberPretty></td>

                                                    <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty hideZero={true}>{startup.totals.totalGeneral.refinedScoring}</NumberPretty></td>
                                                    <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty hideZero={true}>{startup.totals.totalGeneral.refinedScoringExcitement}</NumberPretty></td>
                                                    <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty hideZero={true}>{startup.totals.totalGeneral.refinedScoringConfidence}</NumberPretty></td>
                                                </tr>
                                            </tbody>
                                        </table>
                                    </div>
                                </Col >
                            </Row >
                        }
                        <Row>
                            <Col md="1"></Col>
                            <Col md={10}>
                                <Row>
                                    <Col md={6} className="align-items-center">
                                        <h3>First Scoring</h3>
                                        <div className={`box-fundable-admin ${startup.totals.funding_global.firstScoring.funding}`}>
                                            <span className="d-block">{startup.totals.funding_global.firstScoring.funding_text}</span>
                                            <span className="d-block">{startup.totals.funding_global.firstScoring.value}</span>
                                        </div>
                                    </Col>
                                    <Col md={6} className="align-items-center">
                                        <h3>RefinedScoring</h3>
                                        <div className={`box-fundable-admin ${startup.totals.funding_global.refinedScoring.funding}`}>
                                            <span className="d-block">{startup.totals.funding_global.refinedScoring.funding_text}</span>
                                            <span className="d-block">{startup.totals.funding_global.refinedScoring.value}</span>
                                        </div>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </BoxStartup.Body>
                </BoxStartup >}
        </Container >
    )
}

export default StartupFundingGlobal;