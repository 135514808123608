import gql from 'graphql-tag'
import { GUEST_FIELDS } from '../guests/guest.fragment'
import { GUEST_INVITATION_FIELDS } from './guest-invitation.fragment'

export const RESOLVE_TOKEN_GUEST_INVITATION = gql`  
        ${GUEST_INVITATION_FIELDS}      
        ${GUEST_FIELDS}                      
        query resolveTokenGuestInvitation($token: String!){                     
            resolveTokenGuestInvitation(token:$token){
                ...GuestInvitationFields
                guest{
                    ...GuestFields
                }   
            }
        }
      
`

export const CREATE_GUEST_INVITATION = gql`  
        ${GUEST_INVITATION_FIELDS}              
        mutation createGuestInvitation($data: GuestInvitationInput!){                     
            createGuestInvitation(data:$data){
                ...GuestInvitationFields
                group{
                    startup_id
                }
            }
        }
      
`


export const UPDATE_GUEST_INVITATION = gql`  
        ${GUEST_INVITATION_FIELDS}              
        mutation updateGuestInvitation($data: GuestInvitationInput!){                     
            updateGuestInvitation(data:$data){
                ...GuestInvitationFields
            }
        }
      
`

export const DELETE_GUEST_INVITATION = gql`    
        ${GUEST_INVITATION_FIELDS}          
        mutation deleteGuestInvitation($id: String!){                     
            deleteGuestInvitation(id:$id){
                ...GuestInvitationFields
            }
        }
`


