import React, { useEffect, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
import { UPDATE_GUEST_INVITATION } from "../../queries/guest-invitation/guest-invitation.query";
// Button
import Button from "../buttons/Button";
// History
import { useHistory } from "react-router-dom";
// SVG
import { ReactSVG } from "react-svg";
// Redux
import { useDispatch } from "react-redux";
import {
  resetQuestionnaireGuest,
  setQuestionnaireGuestPage,
  updateAdvanceGuest,
} from "../../redux/ducks/questionnaire-guest-external-duck";
// Toast
import { toast } from "react-toastify";
// Hooks
import useGuestExternalAnswer from "../../hooks/guest/useGuestExternalAnswer";
import useGuestInvitationUpdate from "../../hooks/guest/useGuestInvitationUpdate";


const QuestionnaireGuestExternalDone = (props) => {
  // Loading
  const [loading, setLoading] = useState(false);
  // Client
  const client = useApolloClient();
  // dispatch
  const dispatch = useDispatch();
  // Answer
  const answerInput = useGuestExternalAnswer();
  // Guest
  const invitationUpdate = useGuestInvitationUpdate();
  // History
  const history = useHistory();
  // Arrow
  const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
  const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
  // Back
  const onClickBack = () => {
    history.push(`/questionnaire-guest/intro-external/questions`);
  };
  // Finish
  const onClickFinish = async () => {
    setLoading(true);

    try {
      // Update Guest
      const invitationInput = {
        ...invitationUpdate,
        answered: true,
      };
      const responseGuestInvitation = await client.mutate({
        mutation: UPDATE_GUEST_INVITATION,
        variables: { data: invitationInput },
        fetchPolicy: "no-cache",
      });
      const guestInvitationUpdated = responseGuestInvitation.data.updateGuestInvitation || {};
      if (!guestInvitationUpdated.id) {
        throw new Error("Cannot update guest");
      }
      // Create Answer
      const responseAnswer = await client.mutate({
        mutation: CREATE_ANSWER,
        variables: { data: answerInput },
      });
      const newAnswer = responseAnswer.data.createAnswer || {};
      if (!newAnswer.id) {
        throw new Error("Cannot save answer");
      }

      toast.success(`Questionnaire successfully finished!`);
      if(invitationUpdate.anonymous){
        history.push("/questionnaire-guest/thanks-anonymous");
      }else{
        history.push("/questionnaire-guest/thanks");
      }
      
      dispatch(resetQuestionnaireGuest());
    } catch (e) {
      console.log(e);
      toast.error(`Oops...An error occurred. Try again later`);
    } finally {
      setLoading(false);
    }
  };
  // Progress
  useEffect(() => {
    dispatch(setQuestionnaireGuestPage("done"));
    // Update Redux
    dispatch(updateAdvanceGuest(100));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      {/* Title */}
      <h4 className="multiple-step-title">
        Awesome you are done with the ratings! Thank you!
      </h4>
      <div className="multiple-step-btn-container">
        <Button className="btn-violet" onClick={onClickBack} disabled={loading}>
          <ReactSVG
            src={arrowLeft}
            wrapper="span"
            className="arrow-right icon-left"
          />
          Back
        </Button>
        <Button
          className="btn-violet"
          type="submit"
          onClick={onClickFinish}
          disabled={loading}
        >
          Finish
          <ReactSVG
            src={arrow}
            wrapper="span"
            className="arrow-right icon-right"
          />
        </Button>
      </div>
    </>
  );
};

export default QuestionnaireGuestExternalDone;
