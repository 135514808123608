
/*eslint-disable*/
import React, { useState, createRef } from "react";
// Core
import Button from "../../components/buttons/Button";
// Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// React form
import { useForm } from 'react-hook-form';
// Apollo
import { useApolloClient } from "react-apollo";
// Queries
import { RECOVER_PASSWORD } from "../../queries/users/user.query";
interface RecoverUser {
    email: string;
}
const RecoverPassword = (props) => {
    // Form
    const { register, handleSubmit, errors, setValue } = useForm<RecoverUser>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {}
    });
    // Client
    const client = useApolloClient();
    // Alert
    const [alert, setAlert] = useState<any>({
        show: false,
        variant: 'danger',
        message: ''
    });
    // Show Alert
    const showAlert = (show = false, variant = 'danger', message = '') => {
        setAlert({
            show: show,
            variant: variant,
            message: message
        });
    }
    // Loading
    const [loading, setLoading] = useState(false);
    const recoverPassword = data => {
        setLoading(true);
        showAlert();
        const variables = {
            ...data
        }
        client.mutate({
            mutation: RECOVER_PASSWORD,
            variables: variables
        })
            .then(resp => {
                // console.log("login success: Barer " + queryUserResult.data.login);
                if (resp.data.recoverPassword) {
                    showAlert(true, 'success', 'Mail sent succefully!');
                    setValue("email", "", { shouldValidate: false });
                } else {
                    showAlert(true, 'danger', "The email doesn't exist");
                }
                setLoading(false);
            })
            .catch(e => {
                showAlert(true, 'danger', "The email doesn't exist");
                setLoading(false);
            })

    }
    return (
        <>
            <div id="login-container" >
                <div className="form-box-login restore-password">
                    <Row>
                        <Col md={12} className="help p-0">
                            <h3 className="text-capitalize text-center text-white">Restore Password</h3>
                            <p className="help-body text-center text-white">
                                Enter the email from which you want to reset the password.<br />And you will be sent an email with the steps
                        </p>
                        </Col>
                        {/* Alert */}
                        <Col xs={12} >
                            <Row className="justify-content-md-start justify-content-center">
                                <Col>
                                    {alert.show && <Alert
                                        key='alert-login'
                                        className="my-2 my-md-2"
                                        variant={alert.variant}
                                        dismissible
                                        show={alert.show}
                                        onClose={() => showAlert()}
                                    >
                                        <p className="m-0" style={{ whiteSpace: 'pre-line' }}> {alert.message}</p>
                                    </Alert>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Form */}
                    <form noValidate className="form-login" onSubmit={handleSubmit(recoverPassword)} >
                        <Row>
                            <Col md={12}>
                                <Form.Group>
                                    <div className="form-group-icon">
                                        <Form.Control
                                            name="email"
                                            type="email"
                                            placeholder="E-Mail Address"
                                            isInvalid={errors.email ? true : false}
                                            ref={register({
                                                required: 'The Email is required',
                                                pattern: {
                                                    value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                    message: "Invalid email address"
                                                }
                                            })} />
                                        {errors.email && <div className="invalid-feedback"> {errors.email.message} </div>}
                                        <span className="material-icons">
                                            person_outline
                                    </span>
                                    </div>
                                </Form.Group>
                            </Col>
                            <Col md={12}>
                                {/* Restore  */}
                                <Row>
                                    <Col xs={12} className="my-4 my-md-4">
                                        <Button disabled={loading} loading={loading} variant="success" type="submit" className="w-100">
                                            Send Email
                                    </Button>
                                    </Col>
                                </Row>
                                {/* Forgot Password */}
                                <Row>
                                    <Col xs={12} className="text-center mt-4">
                                        <a href="/auth/login"
                                            className="d-block mt-4 mt-lg-0 recover-password">Back to login</a>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </form>
                </div>
            </div>
        </>
    )
}

export default RecoverPassword;
