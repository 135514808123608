/*eslint-disable*/
import React from "react";


const NumberPretty = (props) => {
    const number = props.children;
    let fixed = 2;
    if(props.fixed){
        fixed = props.fixed;
    }

    return (
        <>  
            {props.hideZero &&  number <= 0 ? '' : number.toFixed(fixed)}
        </>
    )
}
export default NumberPretty;