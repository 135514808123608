/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
// Core
import Button from "../../components/buttons/Button";
// Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// React form
import { useForm } from 'react-hook-form';
// Apollo
import { useApolloClient } from "react-apollo";
// Queries
import { REGISTER } from "../../queries/users/user.query";
// Interface Error
export interface RegisterUser {
    email: string;
    first_name: string;
    last_name: string;
    startup_name: string;
    password: string;
    password_repeat: string;

}
interface RegisterFormProps {
    title?: string;
    type: 'limited' | 'not_limited' | 'lead_generation'
}
// Register
const RegisterForm = (props: RegisterFormProps) => {
    // Title
    const [titleForm] = useState(props.title ? props.title : 'Register');
    const { type } = props;
    // Form
    const { register, handleSubmit, errors, watch, setError, reset } = useForm<RegisterUser>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {}
    });

    const password = useRef({});
    password.current = watch("password", "");
    // Client
    const client = useApolloClient();
    // Alert
    const [alert, setAlert] = useState<any>({
        show: false,
        variant: "danger",
        message: ""
    });
    // Show Alert
    const showAlert = (show = false, variant = 'danger', message = '') => {
        setAlert({
            show: show,
            variant: variant,
            message: message
        });
    }
    // Loading
    const [loading, setLoading] = useState(false);
    // Register submit
    const registerFn = async (data) => {
        showAlert();
        setLoading(true);
        try {
            delete data.password_repeat
            // Primary Accoiunt
            const registerInput = {
                ...data,
                startup_type: type,
                primary_account: true
            }
            const userResponse = await client.mutate({
                mutation: REGISTER,
                variables: { data: registerInput },
                fetchPolicy: 'no-cache'
            })
            const user = userResponse.data.register || {}
            if (user.id) {
                showAlert(true, 'success', 'User created succefully.\nCheck your mail to activate your account.')
                window.scroll({ top: 0, left: 0, behavior: 'smooth' })
                reset();
            } else {
                showAlert(true, 'danger', 'Oops...An error occurred. Try again later')
            }
        } catch (e: any) {
            console.log('error', e);
            const error = (e.graphQLErrors) ? e.graphQLErrors[0].message : undefined;
            if (error && error.includes('already exist')) {
                setError('email', {
                    type: 'exist',
                    message: 'Email address alredy exist ',
                    shouldFocus: true,
                });

                showAlert(true, 'danger', 'Email address alredy exist')
            } else {
                showAlert(true, 'danger', 'Oops...An error occurred. Try again later')
            }
        } finally {
            setLoading(false);
        }

    }
    return (
        <div id="login-container" >
            <div className="form-box-login">
                <Row>
                    <Col md={12} className="help p-0">
                        <h3 className="text-capitalize text-center text-white">{titleForm}</h3>
                        <p className="help-body text-center text-white"></p>
                    </Col>
                    {/* Alert */}
                    <Col xs={12} >
                        <Row className="justify-content-md-start justify-content-center">
                            <Col>
                                {alert.show && <Alert
                                    key='alert-login'
                                    className="my-2 my-md-2"
                                    variant={alert.variant}
                                    dismissible
                                    show={alert.show}
                                    onClose={() => showAlert()}
                                >
                                    <p className="m-0" style={{ whiteSpace: 'pre-line' }}> {alert.message}</p>
                                </Alert>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Form */}
                <form noValidate className="p-0 form-login" onSubmit={handleSubmit(registerFn)} >
                    <Row>
                        {/* Email */}
                        <Col md={12}>
                            <Form.Group >
                                <Form.Label className="text-white">Email</Form.Label>
                                <Form.Control
                                    name="email"
                                    type="email"
                                    placeholder="Enter a e-mail address"
                                    isInvalid={errors.email ? true : false}
                                    ref={register({
                                        required: 'E-mail is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    })}
                                />
                                {errors.email && <div className="invalid-feedback"> {errors.email.message} </div>}

                            </Form.Group>
                        </Col>
                        {/* First Name */}
                        <Col lg="12">
                            <Form.Group >
                                <Form.Label className="text-white">First Name</Form.Label>
                                <Form.Control
                                    name="first_name"
                                    type="text"
                                    placeholder="Enter a First Name"
                                    isInvalid={errors.first_name ? true : false}
                                    ref={register({
                                        required: 'First Name is required'
                                    })}
                                />
                                {errors.first_name &&
                                    <div className="invalid-feedback"> {errors.first_name.message} </div>}
                            </Form.Group>
                        </Col>
                        {/* Last Name */}
                        <Col md="12">
                            <Form.Group >
                                <Form.Label className="text-white">Last Name</Form.Label>
                                <Form.Control
                                    name="last_name"
                                    type="text"
                                    placeholder="Enter a Last Name"
                                    isInvalid={errors.last_name ? true : false}
                                    ref={register({
                                        required: 'Last Name is required'
                                    })}
                                />
                                {errors.last_name &&
                                    <div className="invalid-feedback"> {errors.last_name.message} </div>}
                            </Form.Group>
                        </Col>
                        {/* Company Name */}
                        <Col md="12">
                            <Form.Group >
                                <Form.Label className="text-white">Company Name</Form.Label>
                                <Form.Control
                                    name="startup_name"
                                    type="text"
                                    placeholder="Enter a Company name"
                                    isInvalid={errors.startup_name ? true : false}
                                    ref={register({
                                        required: 'Company name is required'
                                    })}
                                />
                                {errors.startup_name &&
                                    <div className="invalid-feedback"> {errors.startup_name.message} </div>}
                            </Form.Group>
                        </Col>
                        {/* Password */}
                        <Col md={12}>
                            <Form.Group >
                                <Form.Label className="text-white">Password</Form.Label>
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder="Enter a Password"
                                    isInvalid={errors.password ? true : false}
                                    ref={register({
                                        required: 'Password is required',
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters"
                                        }
                                    })}
                                />

                                {errors.password && <div className="invalid-feedback"> {errors.password.message} </div>}
                            </Form.Group>
                        </Col>
                        {/* Password Confirm*/}
                        <Col md={12}>
                            <Form.Group >
                                <Form.Label className="text-white">Repeat password</Form.Label>
                                <Form.Control
                                    name="password_repeat"
                                    type="password"
                                    placeholder="Repeat a Password"
                                    isInvalid={errors.password_repeat ? true : false}
                                    ref={register({
                                        required: 'Password confirm is required',
                                        validate: value =>
                                            value === password.current || "The passwords do not match"
                                    })}
                                />

                                {errors.password_repeat && <div className="invalid-feedback"> {errors.password_repeat.message} </div>}
                            </Form.Group>
                        </Col>
                        <Col md={12}>

                            {/* Sign In  */}
                            <Row>
                                <Col xs={12} className="my-4 my-md-4">
                                    <Button disabled={loading} loading={loading} variant="success" type="submit" className="w-100">
                                        Submit
                                    </Button>
                                </Col>
                            </Row>
                            {/* Forgot Password */}
                            <Row>
                                <Col xs={12} className="text-center mb-4">
                                    <a href="/auth/login"
                                        className="d-block mt-4 mt-lg-0 recover-password">Back to Sign In</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default RegisterForm;
