import React, { useEffect, useCallback, useState, useRef } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { Controller, FormProvider, useForm } from "react-hook-form";
// Form
import ReactSelect from "../select/ReactSelect";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Range
import StartupRangePicker from '../forms/RangePicker';
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_CHALLENGE_TEMPLATE } from "../../queries/challenge-template/challenge-template.query";
// Button Fixed
import ButtonFixed from "../buttons/ButtonFixed";
// Constants
import {
    CHALLENGES_PRIORITY_LEVEL,
    CHALLENGES_PRIORITY_TESTABILITY,
    CHALLENGE_NUMBER_FIELDS
} from '../../constants/challenges/challenges.constants'
import ObjectHelper from "../../helpers/objects/object.helper";
import HtmlEditor from "../forms/HtmlEditor";


// Props
interface ChallengeTemplateUpdateFormProps {
    tracks: any,
    template: any
}
const ChallengeTemplateUpdateForm = (props: ChallengeTemplateUpdateFormProps) => {
    // Helper
    const objectHelper = new ObjectHelper();
    // Client
    const client = useApolloClient();

    const methods = useForm({
        mode: 'onChange'
    });
    const testTarget = useRef({});
    testTarget.current = methods.watch("kpi_metric_target", "");
    // Challenge
    const [trackTemplate, setTrackTemplate] = useState(null);
    const [prioriyLevel, setPriorityLevel] = useState<any>(null);
    const [testability, setTestability] = useState<any>(null);
    const [metricType, setMetricType] = useState('kpi');
    const [blocking, setBlocking] = useState(false);
    const [critical, setCritical] = useState(false);
    const [progress, setProgress] = useState(0)
    const [descriptionContent, setDescriptionContent] = useState('')
    // Loading
    const [loading, setLoading] = useState(false);
    // Options
    const [optionsTracks, setOptionsTrack] = useState<any>([]);
    // Props
    const {
        tracks,
        template
    } = props;
    // Track Change
    const onChangeTrackTemplate = useCallback((option) => {
        setTrackTemplate(option);
        if (option) {
            methods.setValue('track_id', option.value, { shouldValidate: true })
        } else {
            methods.setValue('track_id', null, { shouldValidate: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTrackTemplate, methods.setValue])

    // On Change Priority Level
    const onChangePriorityLevel = useCallback((option, event) => {
        setPriorityLevel(option);
        if (option) {
            methods.setValue('priority_level_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('priority_level_sku', null, { shouldValidate: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps 
    }, [setPriorityLevel, methods.setValue])
    // On Change Testability
    const onChangeTestability = useCallback((option, event) => {
        setTestability(option);
        if (option) {
            methods.setValue('testability_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('testability_sku', null, { shouldValidate: true })
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTestability, methods.setValue])
    // On Change Metric Type
    const onChangeMetricType = (event) => {
        const { target } = event;
        const { value } = target;
        setMetricType(value);
    }

    // Fetch Data
    const fetchData = useCallback(() => {
        // Track Options
        const trackMap = tracks.map(track => {
            return {
                label: `${track.title}`,
                value: track.id
            }
        })
        setOptionsTrack(trackMap);
        // Default Metric Type
        methods.setValue('metric_type_sku', 'kpi')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [

        // Categories
        setOptionsTrack,
        tracks,
        methods.setValue
    ])
    // Fetch Form Initial Data
    useEffect(() => {
        if (tracks && template) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tracks, template])
    // Fetch Edit Form
    // Fetch Form
    const fetchForm = useCallback(() => {
        methods.setValue('order', template.order);        
        // Set Track
        if (template.track_id) {
            const track = optionsTracks.find(o => o.value === template.track_id)
            setTrackTemplate(track);
            methods.setValue('track_id', track.value);
        }
        // Challenge
        methods.setValue('title', template.title);
        methods.setValue('description', template.description);
        setDescriptionContent(template.description)
        methods.setValue('priority_level_sku', template.priority_level_sku)
        const priorityOption = CHALLENGES_PRIORITY_LEVEL.find(v => v.sku === template.priority_level_sku);
        setPriorityLevel({ value: priorityOption?.sku, label: priorityOption?.title })
        methods.setValue('testability_sku', template.testability_sku)
        const testabilityOption = CHALLENGES_PRIORITY_TESTABILITY.find(v => v.sku === template.testability_sku);
        setTestability({ value: testabilityOption?.sku, label: testabilityOption?.title })
        methods.setValue('blocking', template.blocking)
        setBlocking(template.blocking)
        methods.setValue('critical', template.critical)
        setCritical(template.critical)
        // Test        
        methods.setValue('metric_type_sku', template.metric_type_sku)
        setMetricType(template.metric_type_sku)
        if (template.metric_type_sku === 'kpi') {
            // Kpi
            methods.setValue('kpi_metric', template.kpi_metric)
            methods.setValue('kpi_metric_target', template.kpi_metric_target)
            methods.setValue('kpi_metric_current', template.kpi_metric_current)
        } else {
            // Progress
            methods.setValue('progress', template.progress)
            setProgress(template.progress)
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        template,
        setTrackTemplate,
        optionsTracks,
        methods.setValue,
        setCritical,
        setBlocking,
        setPriorityLevel,
        setProgress,
        setTestability,
        setDescriptionContent        
    ])
    // fetchForm
    useEffect(() => {
        if (optionsTracks && optionsTracks.length > 0) {
            fetchForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsTracks])

    // Create
    const onClickUpdate = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateChallengeTemplate()
        }
    }
    const updateChallengeTemplate = async () => {
        try {
            setLoading(true)
            const values = methods.getValues()
            const toCreate: any = {
                id: template.id,
                ...values,
            }
            delete toCreate.category_sku;
            // Parse
            objectHelper.parseFieldToInt(toCreate, CHALLENGE_NUMBER_FIELDS)

            const createChallengeTemplateResp = await client.mutate({
                mutation: UPDATE_CHALLENGE_TEMPLATE,
                variables: { data: toCreate }
            })
            const challengeTemplate = createChallengeTemplateResp.data.updateChallengeTemplate || {}
            if (challengeTemplate.id) {
                toast.success(`Challenge template updated succefully!`);
            } else {
                throw new Error('Cannot update')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }
    return (
        <LoadingOverlay active={loading}
            text={'Updating Template...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods}>


                <Form noValidate name={'create-best-practice'}>
                    {/* Relation */}
                    <Row>
                        {/* Challenge*/}
                        <Col lg="9">
                            <Row>
                                {/* Track */}
                                <Col lg="8">
                                    <Form.Group className={`${methods.errors.track_id ? 'is-invalid' : ''}`}>
                                        <Form.Label>Track</Form.Label>
                                        <Controller
                                            name="track_id"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Track is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select or search track"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.category_sku}
                                                    options={optionsTracks}
                                                    onChange={onChangeTrackTemplate}
                                                    value={trackTemplate}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {methods.errors.track_id && <div className="invalid-feedback"> {methods.errors.track_id.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Order */}
                    <Row>
                        <Col lg="3">
                            <Form.Group >
                                <Form.Label>Order</Form.Label>
                                <Form.Control
                                    name="order"
                                    type="number"
                                    placeholder="Enter a Order"
                                    isInvalid={methods.errors.kpi_metric}
                                    ref={methods.register({
                                        required: 'Order is required',
                                    })}
                                />
                                {/* Error */}
                                {methods.errors.order && <div className="invalid-feedback"> {methods.errors.order.message} </div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Challenge */}
                    <Row>
                        <Col lg="9">
                            <hr />
                            <h3>Challenge</h3>
                        </Col>
                    </Row>
                    {/* Title */}
                    <Row>
                        <Col lg="9">
                            <Form.Group >
                                <Form.Label>Challenge Title</Form.Label>
                                <Form.Control
                                    name="title"
                                    type="text"
                                    placeholder="Enter a Title"
                                    isInvalid={methods.errors.title}
                                    ref={methods.register({ required: 'The title is required' })}
                                />
                                {methods.errors.title && <div className="invalid-feedback"> {methods.errors.title.message} </div>}

                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Description */}
                    <Row>
                        <Col lg="9">


                            <Form.Group className={methods.errors.description ? 'is-invalid' : ''}>
                                <Form.Label>Challenge Description</Form.Label>
                                <Controller
                                    render={(props) => (
                                        <HtmlEditor
                                            value={descriptionContent}
                                            onChange={(value) => {
                                                props.onChange(value)
                                                // setTestDescriptionContent(value)
                                            }}
                                        />
                                    )}
                                    name="description"
                                    rules={{ required: 'Description is required' }}
                                    control={methods.control}

                                />
                                {/* Error */}
                                {methods.errors.description && <div className="invalid-feedback"> {methods.errors.description.message} </div>}

                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Selects */}
                    <Row>
                        <Col lg="9">
                            <Row>
                                {/* Priority Level */}
                                <Col lg="6">
                                    <Form.Group className={`${methods.errors.priority_level_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Priority Level</Form.Label>
                                        <Controller
                                            name="priority_level_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Priority Level is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a option"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.priority_level_sku}
                                                    options={CHALLENGES_PRIORITY_LEVEL.map(i => { return { label: i.title, value: i.sku } })}
                                                    onChange={onChangePriorityLevel}
                                                    value={prioriyLevel}
                                                />
                                            }
                                        />
                                        {methods.errors.priority_level_sku && <div className="invalid-feedback"> {methods.errors.priority_level_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                                {/*  Testability  */}
                                <Col lg="6">
                                    <Form.Group className={`${methods.errors.testability_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Testability</Form.Label>
                                        <Controller
                                            name="testability_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Testability is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a option"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.testability_sku}
                                                    options={CHALLENGES_PRIORITY_TESTABILITY.map(i => { return { label: i.title, value: i.sku } })}
                                                    onChange={onChangeTestability}
                                                    value={testability}
                                                />
                                            }
                                        />
                                        {methods.errors.testability_sku && <div className="invalid-feedback"> {methods.errors.testability_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    <Row >
                        <Col lg="9">
                            <Row>
                                {/* Blocking */}
                                <Col lg="6">

                                    <Form.Group >
                                        <Controller
                                            name='blocking'
                                            control={methods.control}
                                            render={(props) => (
                                                <Form.Check
                                                    custom
                                                    type={'checkbox'}
                                                    id={`create-modal-blocking`}
                                                    label={`Blocking`}
                                                    name="blocking"
                                                    value={'blocking'}
                                                    checked={blocking}
                                                    onChange={(event: any) => {
                                                        const { checked } = event.target;
                                                        props.onChange(checked)
                                                        setBlocking(checked)
                                                    }}
                                                />
                                            )}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* Blocking */}
                                <Col lg="6">
                                    <Form.Group >
                                        <Controller
                                            name='critical'
                                            control={methods.control}
                                            render={(props) => (
                                                <Form.Check
                                                    custom
                                                    type={'checkbox'}
                                                    id={`create-modal-critical`}
                                                    label={`Critical for Next Round`}
                                                    name="critical"
                                                    value={'critical'}
                                                    checked={critical}
                                                    onChange={(event: any) => {
                                                        const { checked } = event.target;
                                                        props.onChange(checked)
                                                        setCritical(checked)
                                                    }}
                                                />)}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Test */}
                    <Row>
                        <Col lg="9">
                            <hr />
                            <h3>Test</h3>
                        </Col>
                    </Row>
                    {/* Metric Type */}
                    <Row >
                        <Col lg="9">
                            <Row>
                                {/* Progress */}
                                <Col lg="6">
                                    <Form.Group >
                                        <Form.Check
                                            custom
                                            id="custom-radio-metric-percent"
                                            type={'radio'}
                                            label={`Percent Completed`}
                                            name="metric_type_sku"
                                            value={'progress'}
                                            onChange={onChangeMetricType}
                                            checked={metricType === 'progress'}
                                            ref={methods.register}
                                        />
                                    </Form.Group>
                                </Col>
                                {/* KPI */}
                                <Col lg="6">
                                    <Form.Group >
                                        <Form.Check
                                            custom
                                            type={'radio'}
                                            id="custom-radio-metric-kpi"
                                            label={`KPI (success metric)`}
                                            name="metric_type_sku"
                                            value={'kpi'}
                                            onChange={onChangeMetricType}
                                            checked={metricType === 'kpi'}
                                            ref={methods.register}
                                        />
                                    </Form.Group>
                                </Col>

                            </Row>
                        </Col>
                    </Row>
                    {/* Progress */}
                    {metricType === 'progress' &&
                        <Row>
                            <Col lg="12">


                                <Controller
                                    render={(props) => (
                                        <StartupRangePicker
                                            value={progress}
                                            onChange={props.onChange}
                                        />
                                    )}
                                    name="progress"
                                    control={methods.control}
                                />
                            </Col>
                        </Row>
                    }
                    {/* Kpi */}
                    {metricType === 'kpi' &&
                        <Row>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Metric</Form.Label>
                                    <Form.Control
                                        name="kpi_metric"
                                        type="text"
                                        placeholder="Enter a Metric"
                                        isInvalid={methods.errors.kpi_metric}
                                        ref={methods.register({
                                            required: 'Metric is required',
                                        })}
                                    />
                                    {/* Error */}
                                    {methods.errors.kpi_metric && <div className="invalid-feedback"> {methods.errors.kpi_metric.message} </div>}
                                </Form.Group>
                            </Col>
                            <Col lg="3">
                                <Form.Group >
                                    <Form.Label>Target</Form.Label>
                                    <Form.Control
                                        name="kpi_metric_target"
                                        type="number"
                                        placeholder="Enter a value"
                                        isInvalid={methods.errors.kpi_metric_target}
                                        ref={methods.register({
                                            required: 'Target is required',
                                        })}
                                    />
                                    {/* Error */}
                                    {methods.errors.kpi_metric_target && <div className="invalid-feedback"> {methods.errors.kpi_metric_target.message} </div>}
                                </Form.Group>
                            </Col>
                            <Col lg="3">
                                <Form.Group >
                                    <Form.Label>Starting</Form.Label>
                                    <Form.Control
                                        name="kpi_metric_current"
                                        type="number"
                                        placeholder="Enter a value"
                                        isInvalid={methods.errors.kpi_metric_current}

                                        ref={methods.register({
                                            required: 'Starting is required',
                                            validate: (value) => {
                                                const currentValue = parseInt(value);
                                                const targetValue = parseInt(testTarget.current as any);
                                                return currentValue <= targetValue || "Starting must be equal or lower"
                                            }

                                        })}
                                    />
                                    {/* Error */}
                                    {methods.errors.kpi_metric_current && <div className="invalid-feedback"> {methods.errors.kpi_metric_current.message} </div>}

                                </Form.Group>
                            </Col>
                        </Row>
                    }
                    {/* Button */}
                    <ButtonFixed onClick={onClickUpdate} loading={loading} />

                </Form>
            </FormProvider>
        </LoadingOverlay>
    )
}

export default ChallengeTemplateUpdateForm;