
/*eslint-disable*/
import React  from "react";
import TypeItemFunders from "./TypeItemFounders";
// Props
interface CategoryItemFoundersProps {
    category: any
}


const CategoryItemFounders = (props: CategoryItemFoundersProps) => {
    const { category } = props
    const length = category.types.length
    // Render
    return (
        <div className="category-item-founders">
            <div className="category-title">
                <span>{category.title}</span>
            </div>
            <div className={`list-type-founders ${length >=6 ? 'list-m-4' : 'list-m-8'}`}>
                { category.types && category.types.map((type,index)=>(
                    <TypeItemFunders type={type} key={index}/>
                ))}
            </div>
        </div>

    )
}

export default CategoryItemFounders;
