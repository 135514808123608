import React, { useState, useEffect, useCallback } from "react";
// Core
import Button from "../buttons/Button";
// Bootstrap
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// Toastify
import { toast } from "react-toastify";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getSubElement } from "../../redux/ducks/dashboard-duck";
import { getUser } from "../../redux/ducks/user-duck";
import {
  answerUpdateRefinedScoring,
  getAnswerRefinedScoring,
  getAnswerLastAnswer,
  getAnswerQuestion,
} from "../../redux/ducks/answer-duck";
import {
  getQuestionnaireAnswers,
  setAnswers,
} from "../../redux/ducks/questionnaire-duck";

// Props
interface ModalUpdateRefinedScoringProps {
  onClose?: Function;
  onSave?: Function;
}

const ModalUpdateRefinedScoring = (props: ModalUpdateRefinedScoringProps) => {
  // Dispatch
  const dispatch = useDispatch();
  // Selector
  const user = useSelector(getUser);
  const question = useSelector(getAnswerQuestion);
  const lastAnswer = useSelector(getAnswerLastAnswer);
  const updateRefinedScoring = useSelector(getAnswerRefinedScoring);
  const subElement = useSelector(getSubElement);
  const answersQuestionnaire = useSelector(getQuestionnaireAnswers);
  // Client
  const client = useApolloClient();
  // Loading
  const [loading, setLoading] = useState(false);
  // Show
  const [show, setShow] = useState(false);
  // Chosen
  const [selected, setSelected] = useState<any>([]);
  // Fetch Answer
  const fetchAnswer = useCallback(() => {
    if (lastAnswer && subElement) {
      const item = lastAnswer.answer_items.find(
        (item) => item.question_type_sku === subElement
      );
      setSelected(item.refined_scoring_ids ? item.refined_scoring_ids : []);
    }
  }, [subElement, lastAnswer, setSelected]);
  // Fetch Show
  const fetchShow = useCallback(() => {
    if (updateRefinedScoring) {
      fetchAnswer();
    }
    setShow(updateRefinedScoring);
  }, [updateRefinedScoring, fetchAnswer]);
  // On Click Radio
  const onClickCheckbox = (event) => {
    const { target } = event;
    const { value } = target;
    const copySelected = [...selected];

    if (copySelected.indexOf(value) < 0) {
      copySelected.push(value);
    } else {
      copySelected.splice(copySelected.indexOf(value), 1);
    }

    setSelected(copySelected);
  };
  // Close
  const handleClose = () => {
    dispatch(answerUpdateRefinedScoring(false));
  };
  // Save Fn
  const saveFn = async () => {
    let answerCreate: any = null;
    setLoading(true);
    try {
      const copyLastAnswer = {
        user_id: user.id,
        startup_id: lastAnswer.startup_id,
        items: [] as any,
      };
      for (let item of lastAnswer.answer_items) {
        let refined_scoring_ids = item.refined_scoring_ids;
        if (item.question_type_sku === subElement) {
          refined_scoring_ids = selected;
        }
        const newItem: any = {
          question_id: item.question_id,
          question_type_sku: item.question_type_sku,
          first_scoring_id: item.first_scoring_id,
          refined_scoring_ids: refined_scoring_ids,
        };
        copyLastAnswer.items.push(newItem);
      }
      // Mutate
      const response = await client.mutate({
        mutation: CREATE_ANSWER,
        variables: { data: copyLastAnswer },
      });
      console.log("response", response);
      answerCreate = response.data.createAnswer || null;
      //console.log(copyLastAnswer);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    return answerCreate;
  };

  // Save
  const handleSave = async () => {
    const answerCreate = await saveFn();
    console.log("answerCreate created", answerCreate);
    if (answerCreate && answerCreate.id) {
      if (props.onSave) {
        props.onSave(answerCreate);
      }
      if (answersQuestionnaire) {
        console.log("questionnaire is started");
        if (answersQuestionnaire[question.type.sku]) {
          answersQuestionnaire[question.type.sku] = selected;
          dispatch(setAnswers({ answersQuestionnaire }));
        }
      }
      // Message Success
      toast.success("Scoring Refining saved succefully!");
      // Close Modal
      dispatch(answerUpdateRefinedScoring(false));
    } else {
      toast.error("Opps...Error ocurred. Try later...");
    }
  };

  // Show
  useEffect(() => {
    fetchShow();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateRefinedScoring]);

  return (
    <>
      <Modal
        id="modal-edit-answer"
        show={show}
        onHide={handleClose}
        enforceFocus={true}
        backdrop={"static"}
      >
        <Modal.Header>
          <Modal.Title>Score Refining</Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <h5 className="text-dark mb-4">{question ? question.title : ""}</h5>
          {/* Items */}
          {question &&
            question.refinedScoring.map((item, index) => (
              <Row key={`answer-first-scoring-${item.id}`}>
                <Col lg="12">
                  <Form.Group>
                    <Form.Check
                      custom
                      name={`${question.type.sku}`}
                      type={"checkbox"}
                      id={`q-item-${item.id}`}
                      label={item.title}
                      value={item.id}
                      checked={selected.filter((i) => i === item.id).length > 0}
                      onChange={onClickCheckbox}
                    />
                  </Form.Group>
                </Col>
              </Row>
            ))}
        </Modal.Body>
        <Modal.Footer>
          <Button disabled={loading} variant="light" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={loading}
            loading={loading}
            loadingText={"Saving"}
            onClick={handleSave}
            className="btn-violet"
          >
            Save
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
};

export default ModalUpdateRefinedScoring;
