
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_ADMIN } from "../../../queries/questions/questions.query";
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import QuestionPriorizationEdit from "../../../components/questions/admin/QuestionPriorizationEdit";
import SettingFactorPriorization from "../../../components/settings/SettingFactorPriorization";






const QuestionPriorizationView = (props) => {


    // Questions
    const [questions, setQuestions] = useState([]);

    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_QUESTIONS_ADMIN,
            fetchPolicy: 'no-cache'
        })
        const questions = response.data.listQuestions || [];
        setQuestions(questions)
        setLoading(false);
    }, [client, setQuestions]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])



    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                        <Breadcrumb.Item active>Priorization</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Priorization</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <Row>
                            <Col md="3" className="mb-4">
                                <SettingFactorPriorization />
                            </Col>
                        </Row>
                        <Row>
                            <Col md="12">
                                <QuestionPriorizationEdit
                                    questions={questions}
                                />
                            </Col>
                        </Row>
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default QuestionPriorizationView;
