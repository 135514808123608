
import React, { useState, useEffect, useCallback } from "react";
// Boostrap
import Form from "react-bootstrap/Form";
import Dropdown from "react-bootstrap/Dropdown";
// Constants
import { STARTUP_FUNDING_OPTIONS } from "../../constants/startup/startup.constants";
// Form
import { useForm } from "react-hook-form";
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_OR_UPDATE_SETTING } from "../../queries/settings/settings.query";
// Loader
import { MoonLoader } from "react-spinners";

interface MatrixFundingCalculation {
    stageSku: string,
    setting: any
}

const MatrixFundingCalculation = (props: MatrixFundingCalculation) => {
    // Client
    const client = useApolloClient();
    // Matrix
    const arrayAxis = ['1', '1_5', '2', '2_5', '3', '3_5', '4', '4_5', '5', '5_5', '6', '6_5', '7', '7_5', '8', '8_5', '9', '9_5', '10'];
    const [axisX] = useState([...arrayAxis]);
    const [axisY] = useState(Object.assign(arrayAxis, []).reverse());
    const [fundingOptions] = useState(STARTUP_FUNDING_OPTIONS);
    const [matrix, setMatrix] = useState<any>(null);
    // Loading
    const [loading, setLoading] = useState(false);
    // Props
    const { stageSku, setting } = props;
    // Validation
    const { register, setValue, getValues } = useForm();

    // buildMatrix
    const buildMatrix = useCallback(() => {
        const matrix = {};
        for (let y of axisY) {
            for (let x of axisX) {
                const defaultValue = {
                    value: 0,
                    funding: 'empty'
                };
                if (!setting[`funding_matrix_${stageSku}`]) {
                    matrix[`${y}-${x}`] = defaultValue;
                    // Set default empty
                    setValue(`${y}-${x}.funding`, 'empty')
                    setValue(`${y}-${x}.value`, 0)
                } else {
                    const value = setting[`funding_matrix_${stageSku}`][`${y}-${x}`];
                    matrix[`${y}-${x}`] = value;
                    // Set default empty
                    setValue(`${y}-${x}.funding`, value.funding)
                    setValue(`${y}-${x}.value`, value.value)
                }
            }
        }
        setMatrix(matrix);

    }, [axisX, axisY, setMatrix, setValue, setting])
    // Init
    useEffect(() => {
        if (setting.sku) {
            buildMatrix();
        }
    }, [setting, buildMatrix])
    // onClickFunding
    const onClickFunding = (key, value) => {
        matrix[key].funding = value;
        setValue(`${key}.funding`, value);
        setMatrix({ ...matrix })
    }
    const onClickUpdate = async () => {
        try {
            setLoading(true);
            const values = getValues();
            const data = {};
            data[`sku`] = 'settings';
            data[`funding_matrix_${stageSku}`] = values;
            console.log(data);
            const resp = await client.mutate({
                mutation: CREATE_OR_UPDATE_SETTING,
                variables: { data: data }
            })
            const setting = resp.data.createOrUpdateSetting || {};
            if (setting.sku) {
                toast.success(`Funding calculation updated succefully`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }

        } catch (e) {
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false);
        }
    }
    return (
        <>
            <LoadingOverlay
                active={loading}
                text={'Saving Settings...'}
                spinner={
                    <div className="_loading_overlay_spinner">
                        <BounceLoader color={"#2662f0"} />
                    </div>}>


                <Form noValidate name={'create-settings'}>
                    <div className="table-responsive table-matrix-funding-calculation-responsive">
                        <table className='table table-matrix-funding-calculation' >
                            <tbody>
                                <tr>
                                    <td className="matrix-axis-x"></td>
                                    {axisX.map((val, index) => (
                                        <td className="matrix-axis-x" key={index}>
                                            { val.replace('_', '.')}
                                        </td>
                                    ))}
                                </tr>
                                {axisY.map((valY, index) => (
                                    <tr key={index}>
                                        <td className="matrix-axis-y">{valY.replace('_', '.')}</td>
                                        {axisX.map((valX, index) => (
                                            // ${matrix[`${valY}-${valX}`].funding}
                                            <td className={`matrix-element ${matrix && matrix[`${valY}-${valX}`].funding ? matrix[`${valY}-${valX}`].funding : ''}`} key={index}>
                                                <div className="matrix-element-container">
                                                    <Form.Control
                                                        name={`${valY}-${valX}.value`}
                                                        type="number"
                                                        defaultValue={matrix && matrix[`${valY}-${valX}`].value}
                                                        min={0}
                                                        ref={register({ 
                                                            required: true,
                                                        })}
                                                    />
                                                    <Form.Control
                                                        name={`${valY}-${valX}.funding`}
                                                        type="hidden"
                                                        ref={register({ required: true })}
                                                    />
                                                    {matrix && matrix[`${valY}-${valX}`] &&
                                                        <Dropdown>
                                                            <Dropdown.Toggle variant="primary" id={`dropdown-${valY}-${valX}`}>
                                                                <i className="fas fa-ellipsis-v"></i>
                                                            </Dropdown.Toggle>

                                                            <Dropdown.Menu className="dropdown-menu-funding">
                                                                {fundingOptions.map((item, index) => (
                                                                    <Dropdown.Item
                                                                        key={index}
                                                                        active={matrix && matrix[`${valY}-${valX}`].funding === item.value}
                                                                        onClick={() => onClickFunding(`${valY}-${valX}`, item.value)}>
                                                                        <span className={`indicator ${item.value}`}></span>
                                                                        <span className="">{item.label}</span>
                                                                    </Dropdown.Item>
                                                                ))}


                                                            </Dropdown.Menu>
                                                        </Dropdown>
                                                    }
                                                </div>
                                            </td>
                                        ))}
                                    </tr>
                                ))}

                            </tbody>
                        </table>
                    </div>
                </Form>
                <>

                    <Button variant="success"
                        id="btn-finish-question"
                        onClick={onClickUpdate}
                        disabled={loading}
                        className="btn-fixed fixed-right shadow">
                        {!loading && <i className="fas fa-save icon"></i>}
                        {loading && <MoonLoader color="#8e8e93" size={40} />}
                    </Button>

                </>
            </LoadingOverlay>
        </>
    )
}

export default MatrixFundingCalculation;