import gql from 'graphql-tag'
import { CHALLENGE_FIELDS } from './challenge.fragment'

// List
export const LIST_CHALLENGES = gql`
${CHALLENGE_FIELDS}
query listChallenges{            
    listChallenges{      
        ...ChallengeFields    
        type{
            title
            category_sku
            category{
                title
            }
        }
    }
}`
// Get by ID
export const GET_CHALLENGE_BY_ID = gql`
${CHALLENGE_FIELDS}
query getChallengeById($id: String!){            
    getChallengeById(id: $id){      
        ...ChallengeFields    
    }
}`
// Create
export const CREATE_CHALLENGE = gql`
${CHALLENGE_FIELDS}
mutation createChallenge($data: ChallengeInput!){            
    createChallenge(data: $data){
        ...ChallengeFields             
    }
}`
// Update
export const UPDATE_CHALLENGE = gql`
${CHALLENGE_FIELDS}
mutation updateChallenge($data: ChallengeInput!){            
    updateChallenge(data: $data){
        ...ChallengeFields             
    }
}`
// Delete
export const DELETE_CHALLENGE = gql`
${CHALLENGE_FIELDS}
mutation deleteChallenge($id: String!){            
    deleteChallenge(id: $id){
        ...ChallengeFields             
    }
}`
// Delete Documet
export const REMOVE_CHALLENGE_DOCUMENT = gql`
${CHALLENGE_FIELDS}
mutation removeChallengeDocument($id: String!, $document: FileModelInput!){            
    removeChallengeDocument(id: $id, document: $document){
        ...ChallengeFields        
    }
}`
