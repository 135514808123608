
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_CHALLENGES_TEMPLATES, DELETE_CHALLENGE_TEMPLATE } from "../../../queries/challenge-template/challenge-template.query";
// Router
import { useHistory } from "react-router-dom";
// Swaler
import Swal from '../../../components/sweet-altert/sweet-alert';
// Toastify
// Button
import Button from "../../../components/buttons/Button";
// Table
import BestPracticeTable from "../../../components/best-practice/BestPracticeTable";
import { toast } from "react-toastify";
import ChallengeTemplateTable from "../../../components/challenges-template/ChallengeTemplateTable";





const ChallengeTemplateIndexView = (props) => {
    // Router
    const history = useHistory();
    // Startups
    const [templates, setTemplates] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_CHALLENGES_TEMPLATES,
            fetchPolicy: 'no-cache'
        })
        const templates = response.data.listChallengeTemplates || [];
        setTemplates(templates)
        setLoading(false);

    }, [client, setTemplates]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickViewItem = (challengeTemplate) => {
        history.push(`/admin/challenge-templates/edit/${challengeTemplate.id}`)
    }
    const onClickDeleteItem = (challengeTemplate) => {
        Swal.fire({
            title: `<span>Are you sure to delete?</span>`,
            html: `<span>You are trying to delete a challenge template </span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_CHALLENGE_TEMPLATE,
                    variables: { id: challengeTemplate.id }
                }).then(resp => {
                    toast.success('Challenge Template deleted succefully');
                    fetchData();
                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted a Challenge Template. Try later');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }
    const onClickAdd = (bestPractice) => {
        history.push(`/admin/challenge-templates/add`)
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>Challenges Templates</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <Row >
                            <Col md="6" className="d-flex">
                                <span className="title"> Challenges Templates</span>
                            </Col>
                            <Col md="6" className="d-flex justify-content-lg-end">
                                <Button
                                    variant={'primary'}
                                    size="sm"
                                    className={'ml-auto'} onClick={onClickAdd}>
                                    <i className="fas fa-add mr-2"></i>
                                    Add
                            </Button>
                            </Col>
                        </Row>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <ChallengeTemplateTable 
                            templates={templates} 
                            loading={loading} 
                            onClickDeleteItem={onClickDeleteItem}
                            onClickViewItem={onClickViewItem}
                            />
                    </BoxStartup.Body>
                </BoxStartup>

            </Container>
        </>
    )
}

export default ChallengeTemplateIndexView;
