
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_CATEGORY_BY_ID } from "../../../queries/categories/categories.query";
// Router
import { useParams } from "react-router-dom";
// Form
import Spinner from "react-bootstrap/Spinner";
import CategoryEditForm from "../../../components/categories/CategoryEditForm";



const CategoryEditView = (props) => {
    // Question Id
    const { category_id } = useParams<any>();

    // Question
    const [category, setCategory] = useState<any>(null);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        try {
            setLoading(true);
            const response = await client.query({
                query: GET_CATEGORY_BY_ID,
                variables: { id: category_id },
                fetchPolicy: 'no-cache'
            })
            // Category
            const category = response.data.getCategoryById || {};
            setCategory(category)
        } catch (e) {
            console.log(e);
        } finally {
            // Loading
            setLoading(false);
        }

    }, [
        client,
        setCategory,
        setLoading,
        category_id]);
    // Call Fetch Data
    useEffect(() => {
        if (category_id) {
            fetchData();
        }
    }, [category_id, fetchData])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href="/admin/main-elements">Main Elements</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Main Element</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Main Element</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <CategoryEditForm category={category} />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                        {!loading && !category && <h3>Main Element Not Found</h3>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default CategoryEditView;