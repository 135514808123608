import gql from 'graphql-tag'

export const ADVICE_FIELDS = gql`
fragment AdviceFields on Advice {        
    id    
    type_sku    
    title
    description
    active    
    order
    locked

    created_at
    updated_at
}`