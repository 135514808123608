
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Button from "../buttons/Button";
// Redux
import { useDispatch } from 'react-redux';
import { updateAdvance } from '../../redux/ducks/questionnaire-duck';

const QuestionnaireIntroGuest = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const nextRoute = `/questionnaire/intro-guest/startup` ; 

    useEffect(() => {
        // Update Redux
        dispatch(updateAdvance(
            50
        ))
    }, [])
    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Welcome to 34 Elements! 👋</h4>
            {/* Description */}
            <p className="multiple-step-description">
                First you need configure the type of Startup<br />
            </p>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={() => { history.push(nextRoute) }}>
                    Go to Startup Type
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </>
    )
}

export default QuestionnaireIntroGuest;