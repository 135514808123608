import React from "react";
import Select, { IndicatorProps } from 'react-select';
import styles from './styles';
import classNames from 'classnames';
const ControlComponent = (props) => {
    const {
        children,
        isFocused,
        innerRef,
        innerProps,
        menuIsOpen,
        isInvalid,
    } = props;
    const value = props.getValue();
    const data = value.length > 0 ? value[0] : {};

    return (
        <div
            ref={innerRef}
            className={classNames({
                'form-control': true,
                'react-select': true,
                'focus': isFocused || menuIsOpen,
                'is-invalid': isInvalid
            })}
            {...innerProps}>
            {data && data.saved && <span className="material-icons icon-saved">done</span>}
            {children}
        </div>
    )
};

export const DropdownIndicator = (props: IndicatorProps) => {
    // const { children, className, cx, getStyles, innerProps } = props;
    return (
        <div className="rs-indicator dropdown-indicator">
            <span className="svg-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M8.12 9.29L12 13.17l3.88-3.88c.39-.39 1.02-.39 1.41 0 .39.39.39 1.02 0 1.41l-4.59 4.59c-.39.39-1.02.39-1.41 0L6.7 10.7c-.39-.39-.39-1.02 0-1.41.39-.38 1.03-.39 1.42 0z" /></svg>
            </span>
        </div>
    );
};

export const ClearIndicator = (props: IndicatorProps) => {
    const { innerProps } = props;
    return (
        <div
            {...innerProps}
            className="rs-indicator  clear-indicator">
            <span className="svg-icon">
                <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" fill="black" width="18px" height="18px"><path d="M0 0h24v24H0V0z" fill="none" /><path d="M18.3 5.71c-.39-.39-1.02-.39-1.41 0L12 10.59 7.11 5.7c-.39-.39-1.02-.39-1.41 0-.39.39-.39 1.02 0 1.41L10.59 12 5.7 16.89c-.39.39-.39 1.02 0 1.41.39.39 1.02.39 1.41 0L12 13.41l4.89 4.89c.39.39 1.02.39 1.41 0 .39-.39.39-1.02 0-1.41L13.41 12l4.89-4.89c.38-.38.38-1.02 0-1.4z" /></svg>
            </span>
        </div>
    );
};

const Placeholder = (props) => {
    const { children } = props;
    return (
        <div className="rs-placeholder">
            {children}
        </div>
    );
};
export const ValueContainer = (props) => {
    const { children } = props;
    return (
        <div
            // style={getStyles('valueContainer', props)}
            className={classNames(
                {
                    'rs-value-container': true,
                }
            )}
        >
            {children}
        </div>
    );
};
export const IndicatorsContainer = (props) => {
    const { children } = props;

    return (
        <div className={'rs-indicator-container'}>
            {children}
        </div>
    );
};
const IndicatorSeparator = ({ innerProps }) => {
    return <span className={'rs-indicator-separator'} {...innerProps} />;
};

const Menu = (props) => {
    const { children, innerProps, getStyles,innerRef } = props;
    // console.log(props);
    return (
        <ul 
            // css={getStyles('menu', props)}
            className='rs-menu dropdown-menu show' 
            {...innerProps}
            ref={innerRef}
            >
            {children}
        </ul>
    );
};
export const MenuList = (props) => {
    const { children, innerRef, getStyles } = props;
    return (
        <div
            style={getStyles('menuList', props)}
            // className='rs-menu dropdown-menu show'
            ref={innerRef}>
            {children}
        </div>
    );
};

const Option = (props) => {
    const {
        children,
        isFocused,
        isSelected,
        innerRef,
        innerProps,
        data,
    } = props;

    return (
        <div className={`dropdown-item ${isFocused ? 'active' : ''} ${isSelected ? 'selected' : ''}`} ref={innerRef}
            {...innerProps}>
            {data && data.saved && <span className="material-icons icon-saved">done</span>}
            {children}
        </div>
    );
};

const ReactSelect = (props) => {
    const { isDisabled,isInvalid, isValid, placeholder, value, onChange, isClearable, options, isMulti, defaultValue } = props;
    return (
        <>
            <Select
                maxHeight={350}
                className={`rs-container ${isInvalid ? 'is-invalid' : ''}  ${isValid ? 'is-valid' : ''}`}
                styles={styles}
                isMulti={isMulti}
                isDisabled={isDisabled}
                components={{
                    Control: ControlComponent,
                    IndicatorsContainer: IndicatorsContainer,
                    IndicatorSeparator: IndicatorSeparator,
                    DropdownIndicator: DropdownIndicator,
                    ClearIndicator: ClearIndicator,
                    Placeholder: Placeholder,
                    ValueContainer: ValueContainer,
                     Menu: Menu,
                    MenuList: MenuList,
                    Option: Option
                }}
                placeholder={placeholder ? placeholder : 'Select a option'}
                isClearable={isClearable}
                options={options}
                value={value ? value : null}
                defaultValue={defaultValue ? defaultValue : null}
                onChange={onChange ? onChange : () => { }}
            />
        </>
    )
}

export default ReactSelect;