import React, { useState, useEffect, useCallback } from "react";
// Core
import Button from "../buttons/Button";
// Bootstrap
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// Toastify
import { toast } from "react-toastify";
// Client
import { useApolloClient } from "react-apollo";
import { CONTACT_USER } from '../../queries/users/user.query';
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getShowModalLetsTalk, hideModalLetsTalk } from "../../redux/ducks/lest-talks-duck";
// Validations
import { useForm } from "react-hook-form";


// Props
interface ModalLetsTalkProps {
    onClose?: Function;
    onSave?: Function;
}
interface MessageForm {
    message: string;
}
const ModalLetsTalk = (props: ModalLetsTalkProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Validation
    const { register, errors, setValue, trigger, getValues } = useForm<MessageForm>({
        mode: 'onChange'
    })
    // Selector
    const isShow = useSelector(getShowModalLetsTalk);
    // Client
    const client = useApolloClient();
    // Loading
    const [loading, setLoading] = useState(false);
    // Show
    const [show, setShow] = useState(false);
    // Reset Form Data
    const resetFormData = () => {
        setValue('message', '', { shouldValidate: false })
    }
    // Close
    const handleClose = () => {
        dispatch(hideModalLetsTalk());
        resetFormData()
    }

    //   Fetch Modal data
    const setIsShow = useCallback(() => {
        setShow(isShow);
    }, [isShow, setShow])
    // Change Modal Data
    useEffect(() => {
        setIsShow()
    }, [isShow, setIsShow])

    const letsTalk = async () => {
        setLoading(true);
        try {
            const message = getValues().message;
            const messageResp = await client.mutate({
                mutation: CONTACT_USER,
                variables: { message }
            })
            if(!messageResp.data.contactUser){
                throw new Error('Cannot send message');
            }
            toast.success(`The message was sent correctly!`);
            handleClose()
        } catch (e) {
            console.log(e);
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false);
        }
    }
    // Save
    const onClickSave = async () => {
        await trigger();
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);
        } else {
            letsTalk();
        }
    }
    return (
        <>
            <Modal
                id="modal-lets-talk"
                show={show}
                onHide={handleClose}
                enforceFocus={true}
                backdrop={"static"}
            >
                <Modal.Header>
                    <Modal.Title>
                        Let’s talk
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <Form noValidate name={'send-contact'}>
                        {/* Message */}
                        <Row>
                            <Col lg="9">
                                <Form.Group controlId="questionTitle">
                                    <Form.Label>Tell us what you want to discuss</Form.Label>
                                    <Form.Control
                                        name="message"
                                        type="text"
                                        placeholder="Enter a message"
                                        as={'textarea'}
                                        isInvalid={errors.message ? true : false}
                                        maxLength={300}
                                        rows={4}
                                        ref={register({ required: 'The message is required' })}
                                    />
                                    <Form.Text className="text-muted">
                                        Max Length 300 Characters
                                    </Form.Text>
                                    {errors.message && <div className="invalid-feedback"> {errors.message.message} </div>}

                                </Form.Group>
                            </Col>
                        </Row>

                    </Form>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        loading={loading}
                        loadingText={"Sending"}
                        onClick={onClickSave}
                        className="btn-violet">
                        Send
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalLetsTalk;
