
/*eslint-disable*/
import React, { useState, useEffect, useRef } from "react";
// Core
import Button from "../../components/buttons/Button";
// Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// React form
import { useForm } from 'react-hook-form';
// Apollo
import { useApolloClient } from "react-apollo";
// Queries
import { RESTORE_PASSWORD_TOKEN, RESTORE_PASSWORD } from "../../queries/users/user.query";

export interface RestoreUser {
    password: string;
    password_confirm: string;

}
const RestorePassword = (props) => {
    const token = props.match.params.token;
    // Form
    const { register, handleSubmit, errors, watch } = useForm<RestoreUser>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {}

    });
    const password = useRef({});
    password.current = watch("password", "");
    // Client
    const client = useApolloClient();
    // Alert
    const [alert, setAlert] = useState<any>({
        show: false,
        variant: 'danger',
        message: ''
    });
    // Props
    const [user, setUser] = useState<any>(null);
    const [processToken, setProcessToken] = useState<any>(true);
    const [tokenError, setTokenError] = useState(false);
    // Show alert
    const showAlert = (show = false, variant = 'danger', message = '') => {
        setAlert({
            show: show,
            variant: variant,
            message: message
        });
    }
    // Loading
    const [loading, setLoading] = useState(false);
    const restorePassword = data => {
        if (user) {
            setLoading(true);
            // setToken(token);
            // props.history.push(`/admin/index`)
            showAlert();
            const dataInput = {
                email: user.email,
                password: data.password
            }
            client.mutate({
                mutation: RESTORE_PASSWORD,
                variables: { data: dataInput }
            })
                .then(resp => {
                    // console.log("login success: Barer " + queryUserResult.data.login);
                    if (resp.data.restorePassword) {
                        props.history.push(`/auth/login?password_changed=true`);
                    } else {
                        showAlert(true, 'danger', "Email doesn't exist");
                    }
                    setLoading(false);
                })
                .catch(e => {
                    showAlert(true, 'danger', "Email doesn't exist");
                    setLoading(false);
                })
        }

    }
    // Form
    const renderForm = () => {
        return (
            <form noValidate className="form-login" onSubmit={handleSubmit(restorePassword)} >
                <Row>
                    <Col md={12}>
                        <Form.Group controlId="formBasicEmail">
                            <div className="form-group-icon">
                                <Form.Control
                                    name="password"
                                    type="password"
                                    placeholder="New Password"
                                    isInvalid={errors.password ? true : false}
                                    ref={register({
                                        required: 'Password is required',
                                        minLength: {
                                            value: 8,
                                            message: "Password must have at least 8 characters"
                                        }
                                    })}
                                />
                                {errors.password && <div className="invalid-feedback"> {errors.password.message} </div>}
                                <span className="material-icons">
                                    lock_outline
                            </span>
                            </div>

                        </Form.Group>
                    </Col>
                    <Col md={12}>
                        <Form.Group >
                            <div className="form-group-icon">
                                <Form.Control
                                    name="password_confirm"
                                    type="password"
                                    placeholder="Repeat a New Password"
                                    isInvalid={errors.password_confirm ? true : false}
                                    ref={register({
                                        required: 'Password confirm is required',
                                        validate: value =>
                                            value === password.current || "The passwords do not match"
                                    })}
                                />
                                {errors.password_confirm && <div className="invalid-feedback"> {errors.password_confirm.message} </div>}
                                <span className="material-icons">
                                    lock_outline
                                </span>
                            </div>
                        </Form.Group>
                    </Col>

                    <Col md={12}>
                        <Row>
                            <Col xs={12} className="my-2 my-md-2">

                                <Button disabled={loading} loading={loading} variant="success" type="submit" className="w-100">
                                    Restore
                                </Button>

                            </Col>
                            <Col xs={12} className="text-center mt-4">
                                <a href="/auth/login"
                                    className="d-block mt-4 mt-lg-0 recover-password">Back to login</a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </form>)
    }
    // Process
    const renderProcesss = () => {
        return (
            <Row>
                <Col lg={12} >
                    <h4 className={'text-capitalize text-center text-white'}>Process Token...</h4>
                </Col>
            </Row>)
    }
    // Token invalid
    const renderTokenInvalid = () => {
        return (
            <Row>
                <Col lg={12} >
                    <h4 className={'text-capitalize text-center text-white'}>Token invalid...</h4>
                </Col>
                <Col xs={12} className="text-center mt-4">
                    <a href="/auth/login"
                        className="d-block mt-4 mt-lg-0 recover-password">Back to Sign In</a>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        client.query({
            query: RESTORE_PASSWORD_TOKEN,
            variables: { token }
        }).then((response) => {
            // console.log(response);
            if (response.data.restorePasswordToken) {
                const user = response.data.restorePasswordToken;
                setProcessToken(false);
                setTokenError(false);
                setUser(user);
            } else {
                setProcessToken(false);
                setTokenError(true);
            }

        }).catch((error) => {
            setProcessToken(false);
            setTokenError(true);
        });
    }, [token])

    return (
        <>
            <div id="login-container" >
                <div className="form-box-login">
                    <Row>
                        <Col md={12} className="help p-0">
                            <h3 className="text-capitalize text-center text-white">Restore Password</h3>
                            {/* <p className="help-body text-center text-white">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Nullam id libero euismod, vestibulum eros eget
                        </p> */}
                        </Col>
                        {/* Alert */}
                        <Col xs={12} >
                            <Row className="justify-content-md-start justify-content-center">
                                <Col>
                                    {alert.show && <Alert
                                        key='alert-login'
                                        className="my-2 my-md-2"
                                        variant={alert.variant}
                                        dismissible
                                        show={alert.show}
                                        onClose={() => showAlert()}
                                    >
                                        <p className="m-0" style={{ whiteSpace: 'pre-line' }}> {alert.message}</p>
                                    </Alert>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Process token */}
                    {processToken && renderProcesss()}
                    {/* Process token */}
                    {tokenError && renderTokenInvalid()}
                    {/* Valid */}
                    {(!processToken && !tokenError) && renderForm()}
                </div>
            </div>
        </>
    )
}

export default RestorePassword;
