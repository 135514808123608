
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Table
import QuestionTable from "../../../components/questions/admin/QuestionTable";
// Apollo
import { useApolloClient } from "react-apollo";
// Router
import { useHistory } from "react-router-dom";
// Toastify
import { LIST_QUESTIONS_ADMIN } from "../../../queries/questions/questions.query";
import Button from "../../../components/buttons/Button";



const QuestionIndexView = (props) => {
    // Router
    const history = useHistory();
    // Startups
    const [questions, setQuestions] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_QUESTIONS_ADMIN,
            fetchPolicy: 'no-cache'
        })
        const questions = response.data.listQuestions || [];
        setQuestions(questions)
        setLoading(false);
    }, [client, setQuestions]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickViewItem = (question) => {
        history.push(`/admin/questions/edit/${question.id}`)
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active> Questions </Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Questions</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <QuestionTable
                            questions={questions}
                            loading={loading}
                            onClickViewItem={onClickViewItem}

                        />
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default QuestionIndexView;
