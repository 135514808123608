
import React, { useEffect, useCallback, useState, useRef } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ListGroup from "react-bootstrap/ListGroup";
// Import
import Button from '../../components/buttons/Button'
// Toastify
import { toast } from 'react-toastify';
// Apollo
import { useApolloClient } from "react-apollo";
import { FIND_USERS_BY_QUERY } from "../../queries/users/user.query";
import { UPDATE_STARTUP_GROUP } from "../../queries/startup/startups.query";
// Typehead
import { AsyncTypeahead } from 'react-bootstrap-typeahead'
import { renderInputTypehead } from "../typehead/HintedForm";
import { renderMenuItemChildrenUser } from "../typehead/MenuItemChildren";
// Swaler
import Swal from '../sweet-altert/sweet-alert';

// Props
interface GroupFormProps {
    group?: any
}
const GroupUsersForm = (props: GroupFormProps) => {
    // Client
    const client = useApolloClient();
    // Form
    const [userSelected, setUserSelected] = useState<any>(null);
    const [isLoadingUser, setIsLoadingUser] = useState(false)
    const [optionsUser, setOptionsUser] = useState<any>([]);
    const [users, setUsers] = useState<any>([]);
    // Typehead Ref
    const _typeahead = useRef<any>(null)
    // Loading
    const [loading, setLoading] = useState(false);

    // Props
    const {
        group
    } = props;

    // Typehead
    const onChangeUserInput = (userSelected) => {
        setUserSelected(userSelected);
    }
    // Find Users By Query
    const findUsers = async (query) => {
        let userSearch: any = [];
        try {
            const resp = await client.query({
                query: FIND_USERS_BY_QUERY,
                variables: { query },
                fetchPolicy: 'network-only'
            });
            const userResponse = resp.data.findUserByQuery || [];
            if (userResponse.length > 0) {


                userSearch = userResponse.filter(u =>
                    u.startup.type === 'limited' &&
                    !u.startup.group_id
                )
            }

        } catch (e) {

        } finally {
            return userSearch;
        }

    }
    // Handle Search
    const handleSearchUser = async (query) => {
        setIsLoadingUser(true);
        let result = await findUsers(query);
        setOptionsUser(result);
        setIsLoadingUser(false);
    }
    // Remove User to group
    const fnRemoveUserToGroup = async (user) => {
        try {
            setLoading(true);
            // Uset To Add to Group
            const toUpdate: any = {
                id: user.startup.id,
                group_id: null
            }
            const updateStartupGroupResponse = await client.mutate({
                mutation: UPDATE_STARTUP_GROUP,
                variables: { data: toUpdate },
                // fetchPolicy: 'network-only'
            })
            const startup = updateStartupGroupResponse.data.updateStartupGroup || {}
            if (startup.id) {
                toast.success(`User remove succefully!`);
                const index = users.findIndex(u => u.id === user.id);
                users.splice(index, 1)
                setUsers([...users])
                resetSearchUser();
            } else {
                throw new Error('Cannot remove')
            }
        } catch (e) {
            console.log(e);
            toast.error(`Oops...An error occurred. Try again later`);
        }
        finally {
            setLoading(false);
        }
    }
    const handleRemoveUserToGroup = async (user) => {

        // Alert
        Swal.fire({
            title: `<span>Are you sure to remove user to group?</span>`,
            html: `<span>The user will be left without a group</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                fnRemoveUserToGroup(user)
            }
        })

    }
    // Render User List
    const renderUsers = () => {
        if (!users) {
            return null;
        }
        return (
            <Row>
                <Col lg="9">
                    <ListGroup>
                        {/* Each Users */}
                        {users.map(user => (
                            <ListGroup.Item key={user.id}>
                                <Row>
                                    <Col lg='9'>
                                        <span className="d-block font-weight-bold">{user.fullname}</span>
                                        <span className="d-block">{user.email}</span>
                                    </Col>
                                    <Col lg='3'>
                                        <Button variant="danger" block onClick={() => handleRemoveUserToGroup(user)}>Remove</Button>
                                    </Col>
                                </Row>
                            </ListGroup.Item>
                        ))}
                    </ListGroup>
                </Col>
            </Row>
        )
    }
    const resetSearchUser = () => {
        _typeahead.current.clear()
        _typeahead.current.focus()
        setUserSelected(null)
        setOptionsUser([])
    }
    // Add User To group
    const fnAddUserToGroup = async () => {
        try {
            setLoading(true);
            const user = userSelected[0];
            // Uset To Add to Group
            const toUpdate: any = {
                id: user.startup.id,
                group_id: group.id
            }
            const updateStartupGroupResponse = await client.mutate({
                mutation: UPDATE_STARTUP_GROUP,
                variables: { data: toUpdate },
                // fetchPolicy: 'network-only'
            })
            const startup = updateStartupGroupResponse.data.updateStartupGroup || {}
            if (startup.id) {
                toast.success(`User addded succefully!`);
                setUsers([userSelected[0], ...users])
                resetSearchUser();
            } else {
                throw new Error('Cannot add')
            }
        } catch (e) {
            console.log(e);
            toast.error(`Oops...An error occurred. Try again later`);
        }
        finally {
            setLoading(false);
        }
    }

    const handleOnClickAddToGroup = () => {
        if (userSelected && userSelected.length > 0) {
            fnAddUserToGroup();
        }
    }

    // Fetch User
    const fetchUsers = useCallback(() => {
        if (group) {
            setUsers([...group.users]);
        }
    }, [group])

    useEffect(() => {
        if (group) {
            fetchUsers();
        }
    }, [fetchUsers, group])
    return (
        <>
            <Form noValidate name={'form-group'}>
                {/* Name */}
                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="9">
                                <Form.Group >
                                    <Form.Label>Search user to add</Form.Label>
                                    <AsyncTypeahead
                                        className="typehead-address-container"
                                        id="typehead-users"
                                        labelKey="fullname"
                                        onChange={onChangeUserInput}
                                        placeholder="Search a user"
                                        renderMenuItemChildren={renderMenuItemChildrenUser}
                                        emptyLabel={'No matches found.'}
                                        // Async search
                                        isLoading={isLoadingUser}
                                        minLength={3}
                                        onSearch={handleSearchUser}
                                        options={optionsUser}
                                        useCache={false}
                                        // Render
                                        renderInput={({
                                            inputRef,
                                            referenceElementRef,
                                            ...inputProps
                                        }) => (
                                            renderInputTypehead(
                                                inputRef,
                                                referenceElementRef,
                                                inputProps,
                                                {
                                                    isValid: true,
                                                    name: 'user',
                                                    rbtType: 'search'
                                                }
                                            ))}
                                        filterBy={['email', 'fullname']}
                                        ref={_typeahead}

                                    />
                                    {/* {errors.address && <div className="invalid-feedback"> {errors.address.message} </div>} */}
                                </Form.Group>
                            </Col>
                            <Col lg='3' className="d-lg-flex align-items-lg-end">
                                <Form.Group >
                                    <Button
                                        variant="violet"
                                        block
                                        disabled={!userSelected || loading}
                                        onClick={handleOnClickAddToGroup}
                                    >
                                        Add to group
                                    </Button>
                                </Form.Group >
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </Form>
            {renderUsers()}
        </>

    )
}

export default GroupUsersForm;