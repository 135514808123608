import gql from 'graphql-tag'

export const QUESTION_FIELDS = gql`
fragment QuestionFields on Question {
    id
    title
    type_sku
    weight_type_sku
    weight_type_name    
    order
    active
    total_refined_pre_seed
    total_refined_seed
    total_refined_series_a
    
    priorization_pre_seed
    priorization_seed
    priorization_series_a

    created_at
    updated_at
}`



