import gql from 'graphql-tag'

export const CATEGORY_FIELDS = gql`
fragment CategoryFields on Category {    
    id
    order
    sku
    title
    description    
    active
    priorization_pre_seed
    priorization_seed
    priorization_series_a 
    # Weight
    weight_excitement_pre_seed
    weight_confidence_pre_seed
    weight_excitement_seed
    weight_confidence_seed    
    weight_excitement_series_a
    weight_confidence_series_a
    # Priority Level
    priority_level_pre_seed_sku
    priority_level_seed_sku
    priority_level_series_a_sku

}`



