import gql from 'graphql-tag'

export const USER_FIELDS = gql`
fragment UserFields on User {
    id
    first_name
    last_name
    fullname
    picture
    phone
    email
    type
    active
    startup_id
    continue_scoring
    startup{
        name
    }
    created_at
    updated_at

}`