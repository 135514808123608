import React, { useCallback, useEffect, useRef, useState } from 'react';
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// Validations
import { useFormContext, Controller } from 'react-hook-form';
// Date picker
import StartupRangePicker from '../forms/RangePicker';
// Interface
interface CreateTestFormProps {
    template?: any,
    readOnly: boolean
}
const CreateTestForm = (props: CreateTestFormProps) => {
    // Template
    const { template, readOnly } = props;
    // Validation
    const { register, errors, control, watch, setValue } = useFormContext();
    // Test Target
    const testTarget = useRef({});
    testTarget.current = watch("test.kpi_metric_target", "");
    // Draw error
    const drawError = (errors, name) => {
        if (getError(errors, name)) {
            return <div className="invalid-feedback"> {errors['test'][name].message} </div>
        }
        return null;
    }
    // Get Error
    const getError = (errors, name) => {
        if (errors['test']) {
            if (errors['test'][name]) {
                const keys = Object.keys(errors['test'][name]);
                if (keys.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    // Model
    const [metricType, setMetricType] = useState('kpi');
    const [progress, setProgress] = useState(0);
    // Change Radio
    const onChangeRadioMetric = (event) => {
        const { target } = event;
        const { name, value } = target;
        setMetricType(value)
        setValue(name, value)
    }
    const fetchTemplate = useCallback(() => {
        setMetricType(template.metric_type_sku)
        setValue('test.metric_type_sku', template.metric_type_sku)
        if (template.metric_type_sku === 'progress') {
            // setValue('test.progress', template.progress)
            setProgress(template.progress)
        }
    }, [template, setValue, setProgress])
    // On Change Template
    useEffect(() => {
        if (template && template.id) {
            fetchTemplate()
        }
    }, [fetchTemplate, template])
    return (
        <>
            {/* Progress Title */}
            <Row>
                <Col lg="12" className="mb-3">
                    <Form.Label>Progress&nbsp;<small>How would you like to mesure your progress?</small></Form.Label>
                </Col>
            </Row>
            <Row >
                {/* Progress */}
                <Col lg="6">
                    <Form.Group >
                        <Form.Check
                            disabled={readOnly}
                            custom
                            id="custom-radio-metric-percent"
                            type={'radio'}
                            label={`Percent Completed`}
                            name="test.metric_type_sku"
                            value={'progress'}
                            checked={metricType === 'progress'}
                            onChange={onChangeRadioMetric}
                            ref={register}
                        />
                    </Form.Group>
                </Col>
                {/* KPI */}
                <Col lg="6">
                    <Form.Group >
                        <Form.Check
                            disabled={readOnly}
                            custom
                            type={'radio'}
                            id="custom-radio-metric-kpi"
                            label={`KPI (success metric)`}
                            name="test.metric_type_sku"
                            value={'kpi'}
                            onChange={onChangeRadioMetric}
                            checked={metricType === 'kpi'}
                            ref={register}
                        />
                    </Form.Group>
                </Col>
            </Row>
            {/* Progress */}
            {metricType === 'progress' &&
                <Row>
                    <Col lg="12">


                        <Controller
                            render={(props) => (
                                <StartupRangePicker
                                    disabled={readOnly}
                                    value={progress}
                                    onChange={props.onChange}
                                />
                            )}
                            name="test.progress"
                            control={control}
                        />
                    </Col>
                </Row>
            }
            {/* Kpi */}
            {metricType === 'kpi' &&
                <Row>
                    <Col lg="6">
                        <Form.Group >
                            <Form.Label>Metric</Form.Label>
                            <Form.Control
                                disabled={readOnly}
                                name="test.kpi_metric"
                                type="text"
                                placeholder="Enter a Metric"
                                isInvalid={getError(errors, 'kpi_metric')}
                                ref={register({
                                    required: 'Metric is required',
                                })}
                            />
                            {/* Error */}
                            {drawError(errors, 'kpi_metric')}
                        </Form.Group>
                    </Col>
                    <Col lg="3">
                        <Form.Group >
                            <Form.Label>Target</Form.Label>
                            <Form.Control
                                name="test.kpi_metric_target"
                                type="number"
                                disabled={readOnly}
                                placeholder="Enter a value"
                                isInvalid={getError(errors, 'kpi_metric_target')}
                                ref={register({
                                    required: 'Target is required',
                                })}
                            />
                            {/* Error */}
                            {drawError(errors, 'kpi_metric_target')}
                        </Form.Group>
                    </Col>
                    <Col lg="3">
                        <Form.Group >
                            <Form.Label>Starting</Form.Label>
                            <Form.Control
                                disabled={readOnly}
                                name="test.kpi_metric_current"
                                type="number"
                                placeholder="Enter a value"
                                isInvalid={getError(errors, 'kpi_metric_current')}

                                ref={register({
                                    required: 'Starting is required',
                                    validate: (value) => {
                                        const currentValue = parseInt(value);
                                        const targetValue = parseInt(testTarget.current as any);
                                        return currentValue <= targetValue || "Starting must be equal or lower"
                                    }

                                })}
                            />
                            {/* Error */}
                            {drawError(errors, 'kpi_metric_current')}
                        </Form.Group>
                    </Col>
                </Row>
            }
        </>
    );
};
export default CreateTestForm;