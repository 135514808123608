/*eslint-disable*/
import React, { useState } from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import Button from "../buttons/Button";
// Pdf
import { Document, Page } from 'react-pdf';
import FileItem from "../files/FileItem";
import ModalMasterSessionHistory from "../master-session/ModalMasterSessionHistory";






// Props
interface BoxMasterSessionProps {
    title?: any,
    id?: any,
    className?: any,
    masterSession: any,
    loading: boolean
}
const BoxMasterSession = (props: BoxMasterSessionProps) => {
    // Modal
    const [showModal, setShowModal] = useState(false);

    // Props
    const { title, masterSession, id, className , loading} = props;
    // On Click Add
    const onClickHistory = () => {
        setShowModal(true)
    }
    const renderBoxContent = () => {
        return (
            <>
                <Row>
                    <Col lg="6" className="d-flex flex-column align-items-center">
                        <div className="master-session-title-content">
                            {/* Title */}
                            <span className="master-session-title">{masterSession.topic}</span>
                            {/* Badge */}
                            <div className="master-session-badge-content">
                                <span className="master-session-badge">
                                    {masterSession.number_of_slides} {masterSession.number_of_slides > 1 ? 'Slides' : 'Slide'}
                                </span>
                                <span className="master-session-badge">
                                    {masterSession.reading_time} min max
                                </span>
                            </div>
                        </div>
                    </Col>
                    <Col lg="6">
                        <div className="master-session-body-content">
                            <p className="master-session-description">{masterSession.description}</p>
                        </div>
                    </Col>
                </Row>
                <Row className="mt-3">
                    <Col lg="6" className="d-flex flex-column align-items-center">
                        <div className="master-session-title-content">
                            <span className="master-session-title center-file">Food for Thoughts</span>
                        </div>
                    </Col>
                    <Col lg="6">
                        <FileItem file={{
                            ...masterSession.document,
                            mime: masterSession.document.type,
                        }} type="master-session" />

                        <Document
                            file={masterSession.document.url.replace('http://localhost:4000', '')}
                        >
                            <Page height={100} pageNumber={1} />
                        </Document>


                    </Col>
                </Row>
            </>
        )
    }
    return (
        <>
            {/* Modal */}
            {masterSession && masterSession.id && <ModalMasterSessionHistory history={masterSession.history} show={showModal} onClose={() => setShowModal(false)} />}
            {/* Box */}
            <BoxStartup id={id ? id : 'box-master-session'} className={className ? className : ''}>
                <BoxStartup.Header>
                    <Row>
                        <Col lg="6">
                            <span className="title">
                                {title ? title : 'Master Session'}
                            </span>
                        </Col>
                        {masterSession && masterSession.history && masterSession.history.length > 0 && <Col lg="6" className={'d-flex justify-content-lg-end mt-2 mt-lg-0'}>
                            <Button size="sm" className="btn-violet" onClick={onClickHistory}>History</Button>
                        </Col>}
                    </Row>
                </BoxStartup.Header>
                {/* With Data */}
                {!loading && masterSession && masterSession.id &&
                    <BoxStartup.Body >
                        <div className="box-master-session-content">
                            {renderBoxContent()}
                        </div>
                    </BoxStartup.Body>}
                {/* Without Data */}
                {!loading && !masterSession &&
                    <BoxStartup.Body className={'box-body-100 d-flex  justify-content-center'}>
                        <div className="box-master-session-content-empty">
                            <span className="title-empty">Without Master Session</span>
                        </div>
                    </BoxStartup.Body>}
                    {loading  &&
                    <BoxStartup.Body className={'box-body-100 d-flex  justify-content-center'}>
                        <div className="box-master-session-content-empty">
                            <span className="title-empty">Loading...</span>
                        </div>
                    </BoxStartup.Body>}
            </BoxStartup >
        </>

    )
}

export default BoxMasterSession;
