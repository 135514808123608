import gql from 'graphql-tag';
import { QUESTION_EXTERNAL_FIELDS } from '../questions-external/question-external.fragment';
import { STARTUP_FIELDS } from './startup.fragment';

// Query
export const LIST_STARTUPS = gql`        
        ${STARTUP_FIELDS}
        query listStartups{
            listStartups{
                ...StartupFields
             }            
           
        }`
export const LIST_STARTUPS_BY_GROUP = gql`        
query listStartupsByGroup($group_id: String!){
    listStartupsByGroup(group_id:$group_id){
        id
        name
     }            
}`

export const GET_STARTUP_HASH_URL = gql`        
    query getStartupHashUrl($id: String!){
        getStartupHashUrl(id: $id)
       
    }`
export const RESOLVE_STARTUP_HASH_URL = gql`        
query resolverStartupHashUrl($token: String!){
    resolverStartupHashUrl(token: $token)
   
}`


export const DELETE_STARTUP = gql`        
        ${STARTUP_FIELDS}
        mutation deleteStartup($id: String!){
            deleteStartup(id: $id){
                ...StartupFields
             }            
           
        }`
export const UPDATE_STARTUP = gql`        
${STARTUP_FIELDS}
mutation updateStartup($data: StartupInput!){
    updateStartup(data: $data){
        ...StartupFields
     }            
   
}`

export const GET_STARTUP_BY_ID = gql`        
${STARTUP_FIELDS}
query getStartupById($id: String!){
    getStartupById(id: $id){
        ...StartupFields
     }            
   
}`



export const GET_STARTUP_DASHBOARD = gql`        
        ${STARTUP_FIELDS}
        ${QUESTION_EXTERNAL_FIELDS}
        query getStartupDashboard{
            getStartupDashboard{
                ...StartupFields
                totals
                # totals_internal
                # totals_external   
                primary_account{
                    id
                    email
                    first_name
                    last_name
                }
             }
             listCategories{
                 title
                 sku
                 question_types{
                    id
                    sku
                    title
                    description
                 }
                 questions_external{
                    ...QuestionExternalFields
                 }
            }
        }`

export const GET_STARTUP_DASHBOARD_LEAD = gql`        
        ${STARTUP_FIELDS}
        ${QUESTION_EXTERNAL_FIELDS}
        query getStartupDashboard{
            getStartupDashboard{
                ...StartupFields
                totals
                primary_account{
                    id
                    email
                    first_name
                    last_name
                }
             }
             listCategories{
                 title
                 sku
                 question_types{
                    id
                    sku
                    title
                    description
                 }
                 questions_external{
                    ...QuestionExternalFields
                 }
            }
        }`

export const GET_STARTUP_DASHBOARD_INTERNAL = gql`        
        query getStartupDashboard{
            getStartupDashboard{
                id
                totals_internal                
             }            
           
        }`

export const GET_STARTUP_DASHBOARD_EXTERNAL = gql`        
        query getStartupDashboard{
            getStartupDashboard{
                id
                totals_external                
             }            
           
        }`

export const UPDATE_STARTUP_ELEMENTS_CONFIGURATION = gql`
    ${STARTUP_FIELDS}
    mutation updateStartupElementsConfiguration($data: UpdateStartupElementsConfigurationInput!) {
        updateStartupElementsConfiguration(data: $data) {
          ...StartupFields         
        }
    }
`

export const UPDATE_STARTUP_GROUP = gql`
    mutation updateStartupGroup($data: UpdateStartupGroupInput!) {
        updateStartupGroup(data: $data) {
          id 
          name   
          stage_sku
        }
    }
`
