
import React, { useState } from "react";
import Form from "react-bootstrap/Form";
// Validation
import { useForm } from "react-hook-form";
// Loader
import { MoonLoader } from "react-spinners";
// Button
import Button from "../../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_QUESTION_PRIORIZATION } from '../../../queries/questions/questions.query';

interface QuestionPriorizationEditProps {
    questions: any

}
const QuestionPriorizationEdit = (props: QuestionPriorizationEditProps) => {
    // Client
    const client = useApolloClient();
    // States 
    const [loading, setLoading] = useState(false);
    // Validation
    const { register, errors, getValues,trigger } = useForm({
        mode: 'onChange'
    });
    // Props
    const {
        questions
    } = props;
    // Get Error
    const getError = (prefix, index, key) => {
        if (errors) {
            if (errors[prefix] && errors[prefix][index]) {
                return errors[prefix][index][key]
            }
        }
        return null;
    }

    const onClickUpdate = async () => {
        await trigger()
        const errorsKeys = Object.keys(errors);
        if (errorsKeys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updatePriorization()
        }
    }
    const updatePriorization = async () => {
        let hasError = false;
        setLoading(true)
        try {
            const questions = getValues().questions;
            for (let i = 0; i < questions.length; i++) {
                const question = questions[i];
                question.priorization_pre_seed = parseInt(question.priorization_pre_seed);
                question.priorization_seed = parseInt(question.priorization_seed);
                question.priorization_series_a = parseInt(question.priorization_series_a);
                const resp = await client.mutate({
                    mutation: UPDATE_QUESTION_PRIORIZATION,
                    variables: { data: question }
                })
                const updated = resp.data.updateQuestionPriorization || {}
                if (!updated.id) {
                    throw new Error('Error saving');
                }
            }
        } catch (e) {
            hasError = true;
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }
        if (!hasError) {
            toast.success(`Priorization successfully updated`);
        }
    }
    return (
        <>

            <div className="alert alert-primary" role="alert">
                Zero value is a item without priorization
            </div>
            <div className="table-startup-wrapper table-responsive">
                <Form noValidate name={'create-startup'}>
                    <table className='table table-priorization-edit' >
                        <thead>
                            <tr>
                                <th colSpan={5} className={'text-center'}>Priorization</th>
                            </tr>
                            <tr>
                                <th className={'thead-order'}>Order</th>
                                <th className={'thead-question'}>Question</th>
                                <th className={'text-center'}>Pre Seed</th>
                                <th className={'text-center'}>Seed</th>
                                <th className={'text-center'}>Series A</th>
                            </tr>
                        </thead>
                        {questions && questions.length > 0
                            &&
                            <tbody>
                                {questions.map((question, index) => {
                                    return (
                                        <tr key={question.id}>
                                            <td>{question.order}</td>
                                            <td>{question.type.title}</td>
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    type="hidden"
                                                    name={`questions[${index}].id`}
                                                    defaultValue={question.id}
                                                    ref={register}
                                                />
                                                <Form.Control
                                                    name={`questions[${index}].priorization_pre_seed`}
                                                    type="number"
                                                    isInvalid={getError('questions', index, 'priorization_pre_seed')}
                                                    placeholder="Enter a number"
                                                    defaultValue={question.priorization_pre_seed}
                                                    ref={register({
                                                        required: 'The priorization series a is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />

                                            </td>
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    name={`questions[${index}].priorization_seed`}
                                                    type="number"
                                                    placeholder="Enter a number"
                                                    isInvalid={getError('questions', index, 'priorization_seed')}
                                                    defaultValue={question.priorization_seed}
                                                    ref={register({
                                                        required: 'The priorization series a is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                            <td className={'input-td'}>
                                                <Form.Control
                                                    name={`questions[${index}].priorization_series_a`}
                                                    type="number"
                                                    placeholder="Enter a number"
                                                    isInvalid={getError('questions', index, 'priorization_series_a')}
                                                    defaultValue={question.priorization_series_a}
                                                    ref={register({
                                                        required: 'The priorization series a is required',
                                                        min: {
                                                            value: 0,
                                                            message: 'The value must be greater than or equal to 0'
                                                        }
                                                    })}
                                                />
                                            </td>
                                        </tr>
                                    )
                                })}
                            </tbody>}
                    </table>
                </Form>
            </div>
            <Button variant="success"
                id="btn-finish-question"
                onClick={onClickUpdate}
                disabled={loading}
                className="btn-fixed fixed-right shadow">
                {!loading && <i className="fas fa-save icon"></i>}
                {loading && <MoonLoader color="#8e8e93" size={40} />}
            </Button>

        </>
    )
}

export default QuestionPriorizationEdit;