
/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
// Redux
import {  useDispatch } from 'react-redux';
import { 
    setQuestionnaireGuestUser
 } from '../../redux/ducks/questionnaire-guest-duck';
// Client
import { useApolloClient } from "react-apollo";
import { RESOLVE_TOKEN_GUEST } from '../../queries/guests/guests.query'
import { useHistory, useParams } from "react-router-dom";
import { setTokenGuest } from "../../utils";

const ProcessTokenView = (props) => {
    // Dispatch
    const dispatch = useDispatch();
    // States
    const [error, setError] = useState(false)
    const [processToken, setProcessToken] = useState(true)
    // Params
    const { token } = useParams<any>();
    // History
    const history = useHistory();
    // Client
    const client = useApolloClient();
    // Fetch Data
    const processTokenFn = useCallback(async () => {
        try {
            const response = await client.query({
                query: RESOLVE_TOKEN_GUEST,
                variables: { token }
            })
            const guest = response.data.resolveTokenGuest || {}
            console.log('guest', guest)
            if (!guest.id) {
                throw new Error('Token invalid')
            }
            setTokenGuest(token)
            if(guest.type === 'guest_internal'){
                dispatch(setQuestionnaireGuestUser(guest))                
                history.push(`/questionnaire-guest/intro`)
            }else {
                throw new Error('Token invalid')
            }
            
        } catch (e) {
            console.log(e)
            setError(true)
        } finally {
            setProcessToken(false)
        }
    }, [client, token, setProcessToken, setError])
    // Init
    useEffect(() => {
        if (token) {
            processTokenFn()
        }
    }, [token])
    // Render
    return (
        <>
            <h4 className="text-center">
                {processToken && <span>Process Invitation...</span>}
                {!processToken && error && <span>Token Invalid</span>}
                {!processToken && !error && <span>Token Valid!</span>}
            </h4>
        </>
    )
}

export default ProcessTokenView;
