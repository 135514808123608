import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";

// Props
interface CategoryTableProps {
    categories: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
}
// Reports Table
const CategoryTable = (props: CategoryTableProps) => {
    // Props
    const { categories, loading, onClickViewItem, emptyString } = props;
    // On Click View
    const onClickView = (category) => {
        if (onClickViewItem) {
            onClickViewItem(category);
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Edit Main Element
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-edit"></i>
                    </button>

                </OverlayTrigger>
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {

            dataField: 'title',
            text: 'Title',
            sort: true,
        },        
        {

            dataField: 'order',
            text: 'Order',
            sort: true,
        },     

        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
            // headerStyle: { minWidth: 'px' }
        }
    ];


    return (
        <>
            <DefaultTable
                data={categories}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'order',
                        order: 'asc'
                    }
                }
            />
        </>
    )
}

export default CategoryTable;