import React from 'react';
import { ReactSVG } from 'react-svg';



const LockedOverlay = (props) => {
    // Icon
    const lockIconSrc = require("../../assets/img/lock.svg").default;
    return (
        <div className={'locked-overlay'}>
            <span  className={'btn btn-sm btn-outline-violet'}>
                <ReactSVG src={lockIconSrc} wrapper="span" className="icon lock-icon" />
                <span>Locked</span>
            </span>
        </div>
    )
}

export default LockedOverlay;