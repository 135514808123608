import gql from 'graphql-tag'
import { ADVICE_FIELDS } from './advice.fragment'

// List
export const LIST_ADVICES = gql`
${ADVICE_FIELDS}
query listAdvices{            
    listAdvices{      
        ...AdviceFields    
        type{
            title
            category_sku
            category{
                title
            }
        }
    }
}`
export const LIST_ADVICES_BY_TYPE_SKU = gql`
${ADVICE_FIELDS}
query listAdvicesByTypeSku($type_sku: String!){            
    listAdvicesByTypeSku(type_sku: $type_sku){      
        ...AdviceFields    
        type{
            title
            category_sku
            category{
                title
            }
        }
    }
}`


// Get by ID
export const GET_ADVICE_BY_ID = gql`
${ADVICE_FIELDS}
query getAdviceById($id: String!){            
    getAdviceById(id: $id){      
        ...AdviceFields    
        type{
            sku
            title
            category_sku
            category{
                sku
                title
            }
        }
    }
}`
// Create
export const CREATE_ADVICE = gql`
${ADVICE_FIELDS}
mutation createAdvice($data: AdviceInput!){            
    createAdvice(data: $data){
        ...AdviceFields             
    }
}`
// Update
export const UPDATE_ADVICE = gql`
${ADVICE_FIELDS}
mutation updateAdvice($data: AdviceInput!){            
    updateAdvice(data: $data){
        ...AdviceFields             
    }
}`
// Delete
export const DELETE_ADVICE = gql`
${ADVICE_FIELDS}
mutation deleteAdvice($id: String!){            
    deleteAdvice(id: $id){
        ...AdviceFields             
    }
}`
