
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { useForm } from "react-hook-form";
//
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_QUESTION_TYPE } from "../../queries/question-types/question-types.query";
// Loader
import { MoonLoader } from "react-spinners";

// Props
interface QuestionTypeEditFormProps {
    questionType: any
}
const QuestionTypeEditForm = (props: QuestionTypeEditFormProps) => {

    // Keys numbers 
    const keysNumbers = [
        'order',
    ]
    // Client
    const client = useApolloClient();
    // Form
    const { errors, register, trigger, setValue, getValues } = useForm({
        mode: 'onChange'
    });
    // Loading
    const [loading, setLoading] = useState(false);
    // Props
    const {
        questionType
    } = props;


    // Fetch Data
    const fetchData = useCallback(() => {
        setValue('id', questionType.id);
        setValue('title', questionType.title);
        setValue('sku', questionType.sku);
        setValue('order', questionType.order);
        setValue('description', questionType.description);
        setValue('priorization_description', questionType.priorization_description);
        setValue('strength_description', questionType.strength_description);        
    }, [
        questionType,
        setValue
    ])

    // Init
    useEffect(() => {
        if (questionType && questionType.id) {
            fetchData()
        }
    }, [questionType, fetchData])
    // Update Question
    const onClickUpdate = async () => {
        await trigger()
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateCategory()
        }
    }
    const updateCategory = async () => {
        try {
            setLoading(true)
            const type = getValues();

            // String to number question
            for (let key of keysNumbers) {
                type[key] = parseInt(type[key]);
            }
            console.log('formatted type', type)
            const resp = await client.mutate({
                mutation: UPDATE_QUESTION_TYPE,
                variables: { data: type }
            })
            const updated = resp.data.updateQuestionType || {};
            console.log(updated);
            if (updated.id) {
                toast.success(`Question type successfully updated`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }


    }

    return (
        <LoadingOverlay active={loading}
            text={'Update category...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>

            <Form noValidate name={'edit-category'}>
                {/* Sku */}
                <Row >
                    <Col lg="4">
                        <Form.Group >
                            <Form.Label>SKU</Form.Label>
                            <Form.Control
                                readOnly={true}
                                name="sku"
                                type="text"
                                placeholder="Enter a Sku"
                                isInvalid={errors.title}
                                ref={register({
                                    required: 'Sku is required',
                                })}
                            />
                            {errors.sku &&
                                <div className="invalid-feedback"> {errors.sku.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                {/* Title */}
                <Row >
                    <Form.Control name="id" type="hidden" ref={register({
                        required: 'Question id required',
                    })} />
                    <Col lg="9">
                        <Form.Group >
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                name="title"
                                type="text"
                                placeholder="Enter a Title"
                                isInvalid={errors.title}
                                ref={register({
                                    required: 'Title is required',
                                })}
                            />
                            {errors.title &&
                                <div className="invalid-feedback"> {errors.title.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>

                {/* Order  */}
                <Row >
                    <Col lg="4">
                        <Form.Group >
                            <Form.Label>Order</Form.Label>
                            <Form.Control
                                name="order"
                                type="number"
                                placeholder="Enter a number"
                                isInvalid={errors.order}
                                ref={register({
                                    required: 'Weight is required',
                                    min: {
                                        value: 0,
                                        message: 'The value must be greater than or equal to 0'
                                    }
                                })}
                            />
                            {errors.order &&
                                <div className="invalid-feedback"> {errors.order.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Col lg="9">
                        <Form.Group controlId="questionTitle">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                name="description"
                                type="text"
                                placeholder="Enter a description"
                                isInvalid={errors.description}
                                as={'textarea'}
                                rows={3}
                                ref={register({
                                    required: 'Description is required',
                                })}
                            />
                            {errors.description &&
                                <div className="invalid-feedback"> {errors.description.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    <Col lg="9">
                        <Form.Group controlId="questionTitle">
                            <Form.Label>Priorization description</Form.Label>
                            <Form.Control
                                name="priorization_description"
                                type="text"
                                placeholder="Enter a description"
                                isInvalid={errors.priorization_description}
                                as={'textarea'}
                                rows={3}
                                ref={register({
                                    required: 'Priorization description is required',
                                })}
                            />
                            {errors.priorization_description &&
                                <div className="invalid-feedback"> {errors.priorization_description.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                {/* strength_description */}
                <Row >
                    <Col lg="9">
                        <Form.Group controlId="questionTitle">
                            <Form.Label>Strength description</Form.Label>
                            <Form.Control
                                name="strength_description"
                                type="text"
                                placeholder="Enter a description"
                                isInvalid={errors.strength_description}
                                as={'textarea'}
                                rows={3}
                                ref={register({
                                    required: 'Strength description is required',
                                })}
                            />
                            {errors.strength_description &&
                                <div className="invalid-feedback"> {errors.strength_description.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>

                
                <Button variant="success"
                    id="btn-finish-question"
                    onClick={onClickUpdate}
                    disabled={loading}
                    className="btn-fixed fixed-right shadow">
                    {!loading && <i className="fas fa-save icon"></i>}
                    {loading && <MoonLoader color="#8e8e93" size={40} />}
                </Button>

            </Form>
        </LoadingOverlay>
    )
}

export default QuestionTypeEditForm;