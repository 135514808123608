
/*eslint-disable*/
import React from "react";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import FileList from "../files/FileList";
import NoteList from "../notes/NoteList";
// Props
interface BoxFilesProps {
    title?: any,
    note: any
}
const BoxBrainStormingNotes = (props: BoxFilesProps) => {
    // Props
    const { title, note } = props;

    return (
        <BoxStartup id="box-brain-storming">
            <BoxStartup.Header>
                <span className="title">{title ? title : 'Brainstorming Notes'}</span>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0'}>
                <NoteList note={note} />
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxBrainStormingNotes;
