import gql from 'graphql-tag'
import { FILE_MODEL_FIELDS } from '../file-model/file-model.fragment'

export const STARTUP_FILE_FIELDS = gql`
${FILE_MODEL_FIELDS}
fragment StartupFileFields on StartupFile {        
    id
    type_file_sku
    category_sku    
    category{
        title
        sku
    }
    name
    description    
    document{
        ...FileModelFields
    }
    active
}`