import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateHelper from "../../helpers/dates/date.helper";
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";

// Props
interface GroupTableProps {
    groups: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
    onClickDeleteItem?: Function
    onClickEditUsers?: Function
}
// Reports Table
const GroupTable = (props: GroupTableProps) => {
    const dateHelper = new DateHelper();
    // Props
    const { groups, loading, onClickViewItem, onClickDeleteItem, onClickEditUsers, emptyString } = props;
    // On Click View
    const onClickView = (group) => {
        if (onClickViewItem) {
            onClickViewItem(group);
        }
    }
    const onClickDelete = (group) => {
        if (onClickDeleteItem) {
            onClickDeleteItem(group);
        }
    }
    const onClickUsers = (group) => {
        if (onClickEditUsers) {
            onClickEditUsers(group);
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Edit Group
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-edit"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-users`}>
                            Edit Users
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-secondary btn-rounded-action action-table"
                        onClick={() => onClickUsers(row)}
                    >
                        <i className="fas fa-users-cog"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-delete'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-delete`}>
                            Delete Group
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-danger btn-rounded-action action-table"
                        onClick={() => onClickDelete(row)}
                    >
                        <i className="far fa-trash-alt"></i>
                    </button>

                </OverlayTrigger>
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {

            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {

            dataField: 'elements_configuration',
            text: 'Elements Availables',
            sort: false,
            formatter: cell => cell.filter(value => value.available).map(value => value.title).join(', '),
            headerStyle: { width: '400px' }
        },
        {

            dataField: 'created_at',
            text: 'Created',
            sort: true,
            formatter: cell => dateHelper.format(cell),
            sortValue: cell => dateHelper.sortValue(cell)
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
        }
    ];


    return (
        <>
            <DefaultTable
                data={groups}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'created_at',
                        order: 'desc'
                    }
                }
            />
        </>
    )
}

export default GroupTable;