
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_CATEGORIES_STARTUP } from "../../../queries/questions/questions.query";
// Route
import { useHistory, useParams } from "react-router-dom";
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
import StartupInfo from "../../../components/startup/StartupInfo";
// Questions
import FirstScoringAdmin from "../../../components/questions/admin/FirstScoringAdmin";
import RefinedScoringAdmin from "../../../components/questions/admin/RefinedScoring";
import StartupTotals from "../../../components/startup/StartupTotals";
import StartupFundingGlobal from "../../../components/startup/StartupFundingGlobal";
import StartupControlBoard from "../../../components/startup/StartupControlBoard";
import { toast } from "react-toastify";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";




const StartupView = (props) => {
    // Startup Id
    const { startup_id } = useParams<any>();
    // Set Key
    const [key, setKey] = useState('startup');
    // Startups
    const [startup, setStartup] = useState<any>(null);
    // Questions
    const [questions, setQuestions] = useState<any>([]);
    // Categories
    const [categories, setCategories] = useState<any>([]);
    // Last Answer
    const [answer, setAnswer] = useState<any>(null);
    // Loading
    const [loading, setLoading] = useState(true);
    // History
    const history = useHistory();
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/questions");
    // Fetch Data
    const fetchData = useCallback(async () => {
        try {
            const response = await client.query({
                query: LIST_QUESTIONS_CATEGORIES_STARTUP,
                variables: { startup_id: startup_id },
                fetchPolicy: 'no-cache',
            });
            console.log('response', response);
            // Startup
            const startup = response.data.getStartupById || {}
            setStartup(startup);
            console.log('startup', startup)
            // Categories
            const categories = response.data.listCategories || [];
            setCategories(categories)
            // Questions
            const allQuestions = response.data.listQuestions || [];
            // All questions
            setQuestions(allQuestions);
            // Last Answer
            const lastAnswer = response.data.getLastAnswer || null;
            setAnswer(lastAnswer);
            // Loading False
            setLoading(false);
        } catch (e) {
            toast.error("The Startup doesn't exist")
            history.push('/admin/startups')

        }
    }, [client, startup_id, setStartup, setCategories, setQuestions, setAnswer]);
    // Call Fetch Data
    useEffect(() => {
        if (startup_id) {
            fetchData();
        }
    }, [startup_id, fetchData])
    
    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/startups${search}`}>Startups</Breadcrumb.Item>
                    <Breadcrumb.Item active>
                        View&nbsp;<span className="text-dark">{startup ? `${startup.name} (${startup.stage_text})` : ''}</span>
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <Container fluid>
                {/* Box */}
                <LoadingOverlay active={loading}
                    text={'Loading Questions...'}
                    spinner={
                        <div className="_loading_overlay_spinner">
                            <BounceLoader color={"#2662f0"} />
                        </div>}>

                    <Tab.Container id="tab-question"
                        activeKey={key}
                        onSelect={(k: any) => setKey(k)}
                    >
                        <Row>
                            <Col lg="12">
                                <Container>
                                    <Nav variant="pills" className={'justify-content-center'} id="questions-pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="startup">Startup</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="first-scoring">First Scoring</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="refined-scoring" >Refined Scoring</Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="totals" >
                                                Totals
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="funding_global" >
                                                Funding Global
                                            </Nav.Link>
                                        </Nav.Item>
                                        <Nav.Item>
                                            <Nav.Link eventKey="priorization" >
                                                Priorization
                                            </Nav.Link>
                                        </Nav.Item>
                                    </Nav>
                                </Container>
                            </Col>
                            <Col lg="12">
                                <Tab.Content>
                                    <Tab.Pane eventKey="startup">
                                        <StartupInfo
                                            startup={startup}
                                        />
                                    </Tab.Pane>
                                    {/* First Scoring */}
                                    <Tab.Pane eventKey="first-scoring">
                                        <Container fluid>
                                            <FirstScoringAdmin
                                                categories={categories}
                                                questions={questions}
                                                lastAnswer={answer}
                                            />
                                        </Container>
                                    </Tab.Pane>
                                    {/* Refined Scoring */}
                                    <Tab.Pane eventKey="refined-scoring">
                                        <Container fluid>
                                            <RefinedScoringAdmin
                                                categories={categories}
                                                questions={questions}
                                                lastAnswer={answer}
                                            />
                                        </Container>
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="totals">
                                        <StartupTotals
                                            startup={startup}
                                            categories={categories}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="funding_global">
                                        <StartupFundingGlobal
                                            startup={startup}
                                            categories={categories}
                                        />
                                    </Tab.Pane>
                                    <Tab.Pane eventKey="priorization">
                                        <StartupControlBoard
                                            startup={startup}
                                            categories={categories}
                                        />
                                    </Tab.Pane>
                                </Tab.Content>

                            </Col>
                        </Row>
                    </Tab.Container >
                </LoadingOverlay >
            </Container>
        </>
    )
}

export default StartupView;
