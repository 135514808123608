import { useCallback, useEffect, useState } from "react";
// Redux
import { useSelector } from 'react-redux';
import { getQuestionnaireAnswers, getQuestionnaireLastAnswer, getQuestionnaireQuestions } from "../../redux/ducks/questionnaire-duck";
import { getUser } from "../../redux/ducks/user-duck";

// Hook
const useAnswerRefinedScoring = () => {
    // Get Necesary Data
    const answers = useSelector(getQuestionnaireAnswers);
    const questions = useSelector(getQuestionnaireQuestions);
    const user = useSelector(getUser);
    const lastAnswer = useSelector(getQuestionnaireLastAnswer);

    const [answerRefined, setAnswerRefined] = useState<any>(null);

    const mapData = useCallback(() => {
        const answerRefinedNew = {
            user_id: user.id,
            startup_id: user.startup_id,
            items: [] as any
        }
        //  Each las answer
        for (let lastAnswerItem of lastAnswer.answer_items) {
            const questionTypeSku = lastAnswerItem.question_type_sku;
            let answerItem = answers[questionTypeSku];
            const question = questions.find(q => q.type_sku === questionTypeSku);
            // Check if have last answer has refined scoring            
            if (!answerItem) {
                if (lastAnswerItem.refined_scoring_ids) {
                    answerItem = lastAnswerItem.refined_scoring_ids;
                } else {
                    answerItem = null;
                }
            }
            const item: any = {
                question_id: question.id,
                question_type_sku: question.type_sku,
                first_scoring_id: lastAnswerItem.first_scoring_id,
                refined_scoring_ids: answerItem
            }
            answerRefinedNew.items.push(item)
        }
        setAnswerRefined(answerRefinedNew)
    }, [setAnswerRefined, answers, questions, user,lastAnswer])

    useEffect(() => {
        if (user && questions && questions.length > 0) {
            // console.log(questions)
            mapData()
        }
    }, [answers, questions, user, lastAnswer, mapData]);

    return answerRefined;
}

export default useAnswerRefinedScoring;