import React, { useEffect, useCallback, useState } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";


interface QuestionBlockProps {
    question: any,
    answerItem: any,
    firstScoring: any,
}
const QuestionBlockFirstScoringAdmin = (props: QuestionBlockProps) => {
    // Props
    const { question, firstScoring, answerItem } = props;
    // State
    const [chosenFirstScoring, setChosenFirstScoring] = useState<any>(null);
    const fetchData = useCallback(() => {
        if(answerItem.first_scoring_id){
                const chosen = firstScoring.find(i => i.id === answerItem.first_scoring_id);
                setChosenFirstScoring(chosen);
        }
    },[answerItem, setChosenFirstScoring, firstScoring]);

 
    useEffect(() => {
        if (answerItem) {
            fetchData();
        }
    }, [question.id,answerItem])

    return (
        <>
            { question &&
                <Row className="mb-3">
                    <Col md="12">
                        <h2 className='mb-3 text-uppercase bg-primary p-2 text-white'>{question.type.title}</h2>
                    </Col>
                    <Col md="12">
                        <span className='mb-4 text-uppercase font-weight-bold d-block'>{question.weight_type_name}</span>
                    </Col>
                    <Col md="12">
                        <h3 className='mb-3'>{question.title}</h3>
                    </Col>
                    {/* First Scoring */}
                    <Col md={12}>
                        {firstScoring.map((q, index) => (
                            <div key={`custom-${q.id}`} className="mb-3">
                                <Form.Check
                                    readOnly={true}
                                    custom                                    
                                    name={`${question.category_sku}.${question.type_sku}`}
                                    type={'radio'}
                                    id={`custom-${q.id}`}
                                    label={q.title}
                                    checked={chosenFirstScoring ? chosenFirstScoring.id === q.id : false}
                                    onChange={() => {}}
                                />
                            </div>
                        ))}
                    </Col>
                    <Col md={12}>
                        <hr />
                    </Col>
                </Row>}
        </>
    )
}

export default QuestionBlockFirstScoringAdmin;