/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import { LIST_QUESTION_TYPES } from "../../../queries/question-types/question-types.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Form
import AdviceAddForm from "../../../components/advices/AdviceAddForm";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";


const AdviceAddView = (props) => {

    // Question
    const [questionTypes, setQuestionTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/advices");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
     
        // Question Types
        const responseQuestionTypes = await client.query({
            query: LIST_QUESTION_TYPES,
            fetchPolicy: 'no-cache'
        })
        const questionTypes = responseQuestionTypes.data.listQuestionTypes || [];        
        // Categories
        const responseCategories = await client.query({
            query: LIST_CATEGORIES,
            fetchPolicy: 'no-cache'
        })
        const categories = responseCategories.data.listCategories || [];
        // Set
        setCategories(categories)
        setQuestionTypes(questionTypes)
        // Loading
        setLoading(false);
    }, [
        client,
        setQuestionTypes,
        setCategories,
        setLoading,
    ]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/advices${search}`}>Advices</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">Add Advice</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <AdviceAddForm questionTypes={questionTypes} categories={categories} />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default AdviceAddView;