import React, { useState, useEffect, useCallback } from "react";
// toastify
import { toast } from 'react-toastify';
// classNames
import classNames from 'classnames';
// Helpers
import FileHelper from "../../helpers/files/file.helper";

// SweeAlert
import Swal from '../sweet-altert/sweet-alert';
// uuid
import Button from "../buttons/Button";
// PrettyFileSize
import UploaderItem from "./UploaderItem";
// Props
interface UploaderProps {

    text?: string,
    files?: any,
    onChange?: Function,
    onDeleteItem?: Function,
    validFileExtensions?: Array<string>,
    maxFileSize?: number,
    maxFileLimit?: number,
    multiple?: boolean,
    enableRemoveItem?: boolean,
    enableViewItem?: boolean,
    disabled?: boolean,

}
const Uploader = (props: UploaderProps) => {
    // Helpers
    const _fileHelper = new FileHelper();
    // Max file size
    const maxFileSizeDefault = 3000;
    const [maxFileSize] = useState(props.maxFileSize ? props.maxFileSize : maxFileSizeDefault);
    const maxFileLimitDefault = 10;
    const [maxFileLimit] = useState(props.maxFileLimit ? props.maxFileLimit : maxFileLimitDefault);
    // Valid extension
    const validExtensionsDefault = [
        '.png',
        '.jpg',
        '.jpeg',
        '.pdf',
        '.mp4',
        '.numbers',
        '.pptx',
        '.ppt',
        '.key',
        '.xls',
        '.xlsx',
        '.txt'
    ];
    const [validFileExtensions] = useState<Array<string>>(props.validFileExtensions ? props.validFileExtensions : validExtensionsDefault);
    // Accept
    const [acceptFiles, setAcceptFiles] = useState('image/*')
    // Multiple
    const [multiple] = useState(props.multiple ? props.multiple : false);
    const [enableRemoveItem] = useState(props.enableRemoveItem ? props.enableRemoveItem : false);
    const [enableViewItem] = useState(props.enableViewItem ? props.enableViewItem : false);
    // Disabled
    const [disabled] = useState(props.disabled ? props.disabled : false);
    // Text
    const [text] = useState(props.text ? props.text : 'Upload File or Drag File');
    // Files
    const [files, setFiles] = useState<any>([])
    // Drag
    const [onDragging, setOnDragging] = useState<boolean>(false);
    //
    // Ref
    const fileRef: any = React.createRef()
    // On change file
    const onChangeFile = (event) => {
        const files = Array.from(event.target.files) as Array<File>
        if (files.length > 0) {
            processFiles(files);
        }
    }
    const onDrop = (event) => {
        event.preventDefault()
        onDragLeave(event)
        const filesAdded = event.dataTransfer ? event.dataTransfer.files : event.target.files
        if (filesAdded.length > 0) {
            const files = [...filesAdded]
            processFiles(files);
        }
    }
    // Click upload
    const onClickUpload = () => {
        fileRef.current.value = null
        fileRef.current.click()
    }
    // Drag
    const onDragEnter = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setOnDragging(true);
    }
    const onDragOver = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setOnDragging(true);
    }
    const onDragLeave = (event) => {
        event.preventDefault()
        event.stopPropagation()
        setOnDragging(false);
    }
    // Process
    const processFiles = (filesArg: Array<File>) => {
        // console.log(files);
        const filesAccepted: Array<File> = []
        const filesNotAllowed: Array<File> = []
        const filesMaxSize: Array<File> = []
        const totalFiles = filesArg.length + files.length;
        if (totalFiles > maxFileLimit) {
            toast.error(`The limit of files are ${maxFileLimit}`);
            return false;

        }
        filesArg.forEach(file => {
            const extension = '.' + file.name.split('.').pop() || '';
            // Get length of file in bytes
            const fileSizeInBytes = file.size;
            // Convert the bytes to Kilobytes (1 KB = 1024 Bytes)
            const fileSizeInKB = fileSizeInBytes / 1024;
            // Convert the KB to MegaBytes (1 MB = 1024 KBytes)
            const fileSizeInMB = fileSizeInKB / 1024;
            if (fileSizeInMB > maxFileSize) {
                filesMaxSize.push(file)
                return false;
            }
            if (!validFileExtensions.includes(extension)) {
                filesNotAllowed.push(file);
                return false
            }


            filesAccepted.push(file);
        })
        if (filesNotAllowed.length > 0) {
            const extensionsAllowed = validFileExtensions.join(", ");
            toast.error(`One or more files not added. Allowed extensions ${extensionsAllowed}`);
        }
        if (filesMaxSize.length > 0) {
            toast.error(`One or more files not added. Max file size ${maxFileSize}mb`);
        }

        processFilesAccepted(filesAccepted);
    }
    const processFilesAccepted = async (filesAccepted: Array<File>) => {
        const newFiles: any = [];
        for (let i = 0; i < filesAccepted.length; i++) {
            const file = filesAccepted[i];
            // console.log(file);
            const base64: any = await _fileHelper.getBase64(file);
            const preview = URL.createObjectURL(file);
            // File base to add
            const fileBase = {
                file: {
                    name: file.name,
                    content: base64
                },
                name: file.name,
                size: file.size,
                type: file.type,
                url: preview
            };
            // Push files
            newFiles.push(fileBase);
        }
        if (multiple) {
            const mergedFiles = [...files, ...newFiles];
            setFiles(mergedFiles);
            if (props.onChange) {
                props.onChange(mergedFiles);
            }
        } else {
            setFiles(newFiles);
            if (props.onChange) {
                props.onChange(newFiles);
            }
        }


    }
    const onRemoveFile = (file, index) => {
        if (!file.id) {
            files.splice(index, 1);
            setFiles([...files])
            if (props.onChange) {
                props.onChange([...files]);
            }

        } else {
            onDeleteItem(file)
        }
    }
    // onDeleteItem
    const onDeleteItem = (file) => {
        Swal.fire({
            title: 'Are you sure to remove the file?',
            // text: 'You want remove the file',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then((result) => {
            if (result.value) {
                if (props.onDeleteItem) {
                    // Remove file upload
                    props.onDeleteItem(file);
                }
            }
        })
    }
    // Combined Extension
    useEffect(() => {
        setAcceptFiles(validFileExtensions.join(','));
    }, [validFileExtensions])
    // Fetch Files
    const fetchFiles = useCallback(() => {
        setFiles(props.files);
    }, [props.files])


    const renderAcceptExtenstions = () =>{
        if(validFileExtensions.length > 1){
            return `Only accept ${validFileExtensions.slice(0, validFileExtensions.length - 1).join(", ")} and ${validFileExtensions[validFileExtensions.length - 1]}`
        }
        if(validFileExtensions.length > 0){
            return `Only accept ${validFileExtensions[validFileExtensions.length - 1]}`;
        }
        return `No valid extensions`
        
    }
    useEffect(() => {
        if (props.files !== files) {
            fetchFiles();
        }
    }, [fetchFiles, files, props.files])



    return (
        <>
            <small className="d-block mb-2">{renderAcceptExtenstions()}</small>
            <div className={
                classNames('uploader',
                    {
                        'on-dragging': onDragging,
                    }
                )}
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragEnter={onDragEnter}
                onDragLeave={onDragLeave}>
                {/* Input */}
                <input type="file" name="uploader-files" onChange={onChangeFile} multiple={multiple} ref={fileRef} accept={acceptFiles} />

                {files && files.map((file, index) => (
                    <UploaderItem
                        key={index}
                        index={index}
                        file={file}
                        onRemove={onRemoveFile}
                        enableRemoveItem={enableRemoveItem}
                        enableViewItem={enableViewItem}
                    />
                ))}
                {/* Upload Buton */}
                {!disabled &&
                    <div>
                        <Button
                            disabled={disabled}
                            className="btn-violet" onClick={onClickUpload}>{text}</Button>
                    </div>}
            </div>
        </>
    );
};

export default Uploader;