import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { getQuestionnairePage, getQuestionnaireQuestionsPage } from "../../redux/ducks/questionnaire-duck";
import { getUser } from "../../redux/ducks/user-duck";

// Hook
const useContinueScoring = () => {
    // Get Necesary Data
    const user = useSelector(getUser);
    const page = useSelector(getQuestionnairePage);
    const questionsPage = useSelector(getQuestionnaireQuestionsPage);

    const [userUpdate, setUserUpdate] = useState<any>(null);

    const mapData = useCallback(() => {
        const userInput = {
            email: user.email,
            continue_scoring: {
                page: page,
                questionPage: questionsPage,
            },
        }
        setUserUpdate(userInput)
    }, [setUserUpdate, user, page, questionsPage])

    useEffect(() => {
        if (user) {
            mapData()
        }
    }, [user, page, questionsPage, mapData]);

    return userUpdate;
}

export default useContinueScoring;