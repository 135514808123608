
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_CHALLENGE_TEMPLATE_BY_ID } from "../../../queries/challenge-template/challenge-template.query";
import { LIST_TRACKS } from "../../../queries/track/track.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Form
import ChallengeTemplateUpdateForm from "../../../components/challenges-template/ChallengeTemplateUpdateForm";
// Router
import { useParams } from "react-router-dom";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";



const ChallengeTemplateEditView = (props) => {
    // Question Id
    const { id } = useParams<any>();
    // Challenge
    const [template, setTemplate] = useState([]);
    // Tracks
    const [tracks, setTracks] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/challenge-templates");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        // Challenge Template
        const challengeTemplateResp = await client.query({
            query: GET_CHALLENGE_TEMPLATE_BY_ID,
            variables: { id: id },
            fetchPolicy: 'no-cache'
        })
        const template = challengeTemplateResp.data.getChallengeTemplateById || {};
        setTemplate(template);
        // Get Tracks
        const responseTracks = await client.query({
            query: LIST_TRACKS,
            fetchPolicy: 'no-cache'
        })
        const tracks = responseTracks.data.listTracks || [];
        // Set Tracks
        setTracks(tracks)
        // Loading
        setLoading(false);
    }, [
        client,
        setTracks,
        setLoading,
    ]);
    // Call Fetch Data
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/challenge-templates${search}`}>Challenge Templates</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">Edit Challenge Template</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <ChallengeTemplateUpdateForm
                            template={template}
                            tracks={tracks}
                        />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default ChallengeTemplateEditView;