import React, { useState, useEffect, useCallback, useRef } from 'react';
// Apollo
import { useApolloClient } from 'react-apollo';
import { UPDATE_NOTE } from '../../queries/note/note.query';
// Toast
import { toast } from 'react-toastify';
// Debounce
import useDebounce from '../../hooks/useDebounce';




interface NoteItemProps {
    note: any
}

const NoteItem = (props: NoteItemProps) => {
    // Props
    const { note } = props;
    const [content, setContent] = useState(note.content)
    // Debounce
    const debouncedContent = useDebounce(content, 500);
    // Did Mount
    const didMountRef = useRef(false);
    // Client
    const client = useApolloClient();

    const onChange = (event) => {
        const { target } = event;
        const { value } = target;
        setContent(value)
    }
    // Save Callback
    const saveContent = useCallback(async () => {
        console.log('saving', debouncedContent)
        try {
            const data = {
                id: note.id,
                content: debouncedContent
            }
            const response = await client.mutate({
                mutation: UPDATE_NOTE,
                variables: { data }
            })
            console.log('response', response)
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);

        }
    }, [debouncedContent])

    // Here's where the API call happens
    // We use useEffect since this is an asynchronous action
    useEffect(
        () => {

            if (didMountRef.current) {
                // Make sure we have a value (user has entered something in input)
                saveContent()
            }
            else {
                didMountRef.current = true;
            }


        },
        // This is the useEffect input array
        // Our useEffect function will only execute if this value changes ...
        // ... and thanks to our hook it will only change if the original ...
        // value (searchTerm) hasn't changed for more than 500ms.
        [debouncedContent, saveContent]
    );

    return (
        <div className="note-item" >
            <textarea
                value={content}
                placeholder="Enter a note..."
                onChange={onChange}
            />
        </div>
    );
};
export default NoteItem;