import gql from 'graphql-tag'
import { MASTER_SESSION_HISTORY_FIELDS } from '../master-session-history/master-session-history.fragment'
import { MASTER_SESSION_FIELDS } from './master-session.fragment'

// Queries
export const LIST_MASTER_SESSIONS = gql`
${MASTER_SESSION_FIELDS}
${MASTER_SESSION_HISTORY_FIELDS}
query listMasterSessions{            
    listMasterSessions{      
        ...MasterSessionFields    
        group{
            name
        }
        history{
            ...MasterSessionHistoryFields
        }
    }
}`
export const GET_MASTER_SESSION_BY_ID = gql`
${MASTER_SESSION_FIELDS}
${MASTER_SESSION_HISTORY_FIELDS}
query getMasterSessionById($id: String!){            
    getMasterSessionById(id: $id){      
        ...MasterSessionFields    
        group{
            id
            name
        }
        history{
            ...MasterSessionHistoryFields
        }
    }
}`

export const GET_MASTER_SESSION_BY_CURRENT_USER = gql`
${MASTER_SESSION_FIELDS}
${MASTER_SESSION_HISTORY_FIELDS}
query getMasterSessionByCurrentUser{            
    getMasterSessionByCurrentUser{      
        ...MasterSessionFields    
        group{
            id
            name
        }
        history{
            ...MasterSessionHistoryFields
        }
    }
}`




// Mutation
export const CREATE_MASTER_SESSION = gql`
${MASTER_SESSION_FIELDS}
${MASTER_SESSION_HISTORY_FIELDS}
mutation createMasterSession($data: MasterSessionInput!){            
    createMasterSession(data: $data){      
        ...MasterSessionFields    
        history{
            ...MasterSessionHistoryFields
        }
    }
}`
export const UPDATE_MASTER_SESSION = gql`
${MASTER_SESSION_FIELDS}
${MASTER_SESSION_HISTORY_FIELDS}

mutation updateMasterSession($data: MasterSessionInput!){            
    updateMasterSession(data: $data){      
        ...MasterSessionFields    
        history{
            ...MasterSessionHistoryFields
        }
    }
}`
export const DELETE_MASTER_SESSION = gql`
${MASTER_SESSION_FIELDS}
mutation deleteMasterSession($id: String!){            
    deleteMasterSession(id: $id){      
        ...MasterSessionFields    
    }
}`
