
import Environment from "../constants/environment";
import { HttpLink, ApolloLink, ApolloClient, InMemoryCache, split } from "apollo-boost";
import { setContext } from "apollo-link-context";
import { onError } from 'apollo-link-error';
import { WebSocketLink } from 'apollo-link-ws';
import { getMainDefinition } from 'apollo-utilities';
import { getToken, removeToken } from "../utils";


const httpLink = new HttpLink({
    "uri": Environment.apiUrl
});


const authMiddleware = setContext(async (operation, { headers }) => {
    const token = getToken();
    // const ipAddress = await getPublicIp();
    return {
        headers: {
            ...headers,
            authorization: token ? `Bearer ${token}` : '',
            // 'x-forwarded-for': ipAddress
        },
    };
});

const errorLink = onError(({ graphQLErrors, networkError, operation, forward }) => {

    // console.log(operation);
    if (graphQLErrors) {
        console.log({ graphQLErrors })
        for (let err of graphQLErrors) {
            // handle errors differently based on its error code
            console.log('err', err)
            if (err.extensions) {
                switch (err.extensions.code) {
                    case 'UNAUTHENTICATED':
                        removeToken()
                        window.location.reload()
                        // old token has expired throwing AuthenticationError,
                        // one way to handle is to obtain a new token and 
                        // add it to the operation context
                        // const headers = operation.getContext().headers
                        //console.warn('TODO: UNAUTHENTICATED')
                        // Now, pass the modified operation to the next link
                        // in the chain. This effectively intercepts the old
                        // failed request, and retries it with a new token
                        
                        // return forward(operation);
                        // handle other errors
                        break
                    case 'ANOTHER_ERROR_CODE':
                        // ...
                        break
                }
            }
        }
    }
});
// Auth link
const authLink = ApolloLink.from([
    errorLink,
    authMiddleware,
    httpLink
]);
// Ws link
const wsLink = new WebSocketLink({
    uri: Environment.wsUrl,
    options: {
        reconnect: true,
        connectionParams: {
            // authToken: localStorage.getItem(AUTH_TOKEN),
        }
    }
})
/**
 * https://www.apollographql.com/docs/react/data/subscriptions/
 * 
 * Now create a new WebSocketLink that represents the WebSocket connection. 
 * Use split for proper “routing” of the requests and update the constructor call of ApolloClient 
 * like so:
 */
const link = split(
    ({ query }) => {
        const definition = getMainDefinition(query)
        return definition.kind === 'OperationDefinition' && definition.operation === 'subscription'
    },
    wsLink,
    authLink
)
// Cache
const appCache = new InMemoryCache()
const client = new ApolloClient({
    link: link,
    cache: appCache
});
export default client;