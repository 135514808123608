import gql from 'graphql-tag'
import { QUESTION_ITEM_FIELDS } from '../questions-items/question-item.fragment'

export const ANSWER_ITEMS_FIELDS = gql`
${QUESTION_ITEM_FIELDS}
fragment AnswerItemFields on AnswerItem {
    id
    question_id
    question_type_sku
    first_scoring_id
    refined_scoring_ids
    first_scoring_question{
        ...QuestionItemFields
    }
    refined_scoring_questions{
        ...QuestionItemFields
    }
}`



