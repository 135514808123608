import gql from 'graphql-tag';
import { QUESTION_FIELDS } from './question.fragment';
import { QUESTION_ITEM_FIELDS } from '../questions-items/question-item.fragment';
import { CATEGORY_FIELDS } from '../categories/category.fragment';
import { QUESTION_TYPE_FIELDS } from '../question-types/question-type.fragment';
import { ANSWER_FIELDS } from '../answer/answer.fragment';
import { USER_FIELDS } from '../users/user.fragment';
import { STARTUP_FIELDS } from '../startup/startup.fragment';
// Query
export const LIST_QUESTIONS_AND_CATEGORIES = gql`
        ${QUESTION_FIELDS}
        ${QUESTION_ITEM_FIELDS}
        ${CATEGORY_FIELDS}
        ${QUESTION_TYPE_FIELDS}
        ${ANSWER_FIELDS}
        ${USER_FIELDS}
        ${STARTUP_FIELDS}
        query listQuestions($startup_id: String!){
            listQuestions{
                ...QuestionFields
                items{
                    ...QuestionItemFields
                }                               
                type{
                    ...QuestionTypeFields
                    category{
                        ...CategoryFields    
                    }
                }
                
             }            
            listCategories{
                ...CategoryFields
            }
            getLastAnswer(startup_id: $startup_id){
                ...AnswerFields
                user{
                    ...UserFields
                }
                startup{
                   ...StartupFields
                }
            }
        }`

export const LIST_QUESTIONS_FIRST_SCORING = gql`
${QUESTION_FIELDS}
${QUESTION_ITEM_FIELDS}
${CATEGORY_FIELDS}
${QUESTION_TYPE_FIELDS}
query listQuestions{
    listQuestions{
        ...QuestionFields
        firstScoring{
            ...QuestionItemFields
        }                               
        type{
            ...QuestionTypeFields
            category{
                ...CategoryFields    
            }
        }
     }            
}`
export const LIST_QUESTIONS_REFINED_SCORING = gql`
${QUESTION_FIELDS}
${QUESTION_ITEM_FIELDS}
${CATEGORY_FIELDS}
${QUESTION_TYPE_FIELDS}
query listQuestions{
    listQuestions{
        ...QuestionFields
        refinedScoring{
            ...QuestionItemFields
        }                               
        type{
            ...QuestionTypeFields
            category{
                ...CategoryFields    
            }
        }
     }       
}`

export const LIST_QUESTIONS_GUEST = gql`
${QUESTION_FIELDS}
${QUESTION_ITEM_FIELDS}
${CATEGORY_FIELDS}
${QUESTION_TYPE_FIELDS}
${ANSWER_FIELDS}
query listQuestions($guest_id: String!, $startup_id: String!){
    listQuestions{
        ...QuestionFields
        firstScoring{
            ...QuestionItemFields
        }   
        refinedScoring{
            ...QuestionItemFields
        }                               
        type{
            ...QuestionTypeFields
            category{
                ...CategoryFields    
            }
        }
     }
     getLastAnswerGuest(guest_id: $guest_id, startup_id: $startup_id){
       ...AnswerFields
     }
                 
}`
// List Questions Lead
export const LIST_QUESTIONS_LEAD = gql`
${QUESTION_FIELDS}
${QUESTION_ITEM_FIELDS}
${CATEGORY_FIELDS}
${QUESTION_TYPE_FIELDS}
query listQuestions{
    listQuestions{
        ...QuestionFields
        firstScoring{
            ...QuestionItemFields
        }   
        refinedScoring{
            ...QuestionItemFields
        }                               
        type{
            ...QuestionTypeFields
            category{
                ...CategoryFields    
            }
        }
     }                 
}`
// Query
export const LIST_QUESTIONS_CATEGORIES_STARTUP = gql`
        ${QUESTION_FIELDS}
        ${QUESTION_ITEM_FIELDS}
        ${CATEGORY_FIELDS}
        ${QUESTION_TYPE_FIELDS}
        ${ANSWER_FIELDS}
        ${STARTUP_FIELDS}
        query listQuestions($startup_id: String!){
            getStartupById(id: $startup_id){
                ...StartupFields
                totals
            }
            listQuestions{
                ...QuestionFields
                items{
                    ...QuestionItemFields
                }                                
                type{
                    ...QuestionTypeFields
                    category{
                        ...CategoryFields    
                    }   
                }
             }            
            listCategories{
                ...CategoryFields
            }
            getLastAnswer(startup_id: $startup_id){
                ...AnswerFields              
            }
        }`


export const LIST_QUESTIONS_ADMIN = gql`
        ${QUESTION_FIELDS}
        ${QUESTION_TYPE_FIELDS}
        ${CATEGORY_FIELDS}
        query listQuestions{            
            listQuestions{
                ...QuestionFields  
                type{
                    ...QuestionTypeFields
                    category{
                        ...CategoryFields    
                    }   
                }            
            }                        
        }`
// Get question by id
export const GET_QUESTION_BY_ID = gql`
${QUESTION_FIELDS}
${QUESTION_TYPE_FIELDS}
${CATEGORY_FIELDS}
${QUESTION_ITEM_FIELDS}
query getQuestionById($id: String!){            
        getQuestionById(id: $id){
            ...QuestionFields  
            type{
                ...QuestionTypeFields
                category{
                    ...CategoryFields    
                 }   
           
            }                
            firstScoring{                
                ...QuestionItemFields
            }
            refinedScoring{                
                ...QuestionItemFields
            }
    }
    listQuestionTypes{
        ...QuestionTypeFields
    }
    listCategories{
        ...CategoryFields
    }
}`
export const GET_QUESTION_BY_TYPE_SKU = gql`
${QUESTION_FIELDS}
${QUESTION_TYPE_FIELDS}
${CATEGORY_FIELDS}
${QUESTION_ITEM_FIELDS}
query getQuestionByTypeSku($type_sku: String!){            
    getQuestionByTypeSku(type_sku: $type_sku){
            ...QuestionFields  
            type{
                ...QuestionTypeFields
                category{
                    ...CategoryFields    
                 }   
           
            }                
            firstScoring{                
                ...QuestionItemFields
            }
            refinedScoring{                
                ...QuestionItemFields
            }
    }   
}`

export const UPDATE_QUESTION = gql`
${QUESTION_FIELDS}
mutation updateQuestion($data: QuestionInput!){            
    updateQuestion(data: $data){
            ...QuestionFields             
    }
}`
export const UPDATE_QUESTION_PRIORIZATION = gql`
${QUESTION_FIELDS}
mutation updateQuestionPriorization($data: QuestionInputPriorization!){            
    updateQuestionPriorization(data: $data){
            ...QuestionFields             
    }
}`

