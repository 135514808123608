
import React, { useEffect, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_STARTUP } from '../../queries/startup/startups.query'
import { RESET_FIRST_LOGIN, UPDATE_PROFILE } from '../../queries/users/user.query'
// Button
import Button from "../buttons/Button";
// History
import { useHistory } from "react-router-dom";
// SVG
import { ReactSVG } from "react-svg";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionnaireAnswers, getQuestionnaireQuestions, getQuestionnaireStartup, resetQuestionnaire, updateAdvance } from '../../redux/ducks/questionnaire-duck';
import { getUser, userCreate } from "../../redux/ducks/user-duck";
import { toast } from "react-toastify";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
import TokenHelper from "../../helpers/security/token.helper";
import { setToken } from "../../utils";


const QuestionnaireResults = (props) => {
    // Loading
    const [loading, setLoading] = useState(false);
    // Client
    const client = useApolloClient();
    // dispatch
    const dispatch = useDispatch();
    // Startup
    const startup = useSelector(getQuestionnaireStartup);
    const answers = useSelector(getQuestionnaireAnswers);
    const questions = useSelector(getQuestionnaireQuestions);
    const user = useSelector(getUser);
    // History
    const history = useHistory();
    // Arrow
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Back
    const onClickBack = () => {
        history.push(`/questionnaire/intro/questions`)
    }
    // Finish
    const onClickFinish = async () => {
        setLoading(true)
        
        const startupVar = {
            ...startup,
            active: true,
            id: user.startup_id,
        }
        const answer = {
            user_id: user.id,
            startup_id: user.startup_id,
            items: [] as any
        }
        // Keys
        const answerKeys = Object.keys(answers);
        for (let aKey of answerKeys) {
            const answerItem = answers[aKey];
            const question = questions.find(q => q.type_sku === aKey);

            const item: any = {
                question_id: question.id,
                question_type_sku: question.type_sku,
                first_scoring_id: answerItem,
                refined_scoring_ids: null
            }

            answer.items.push(item)
        }
        try {

            // Update Startup
            const responseStartup = await client.mutate({
                mutation: UPDATE_STARTUP,
                variables: { data: startupVar }
            })
            const startup = responseStartup.data.updateStartup || {};
            if (!startup.id) {
                throw new Error('Cannot save startup');
            }
            // Update User
            const responseUser = await client.mutate({
                mutation: RESET_FIRST_LOGIN
            })
            const newToken = responseUser.data.resetFirstLogin || null;
            if (!newToken) {
                throw new Error('Cannot save user');
            }
            // Create Answer
            const responseAnswer = await client.mutate({
                mutation: CREATE_ANSWER,
                variables: { data: answer }
            })
            console.log('responseAnswer', responseAnswer)
            const newAnswer = responseAnswer.data.createAnswer || {}
            if (!newAnswer.id) {
                throw new Error('Cannot save answer');
            }
            // Set the new token
            const tokenHelper = new TokenHelper();
            const user = tokenHelper.decode(newToken);
            if (user) {
                console.log('user', user)
                setToken(newToken)
                delete user.password;
                // Set User
                dispatch(userCreate(user));
                // Reset Questionnaire
                dispatch(resetQuestionnaire())
                // Redirect
                history.push(`/dashboard/main`)

            }else{
                throw new Error('Token error');

            }
            toast.success(`Questionnaire successfully finished!`);
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }


    }
    // Progress
    useEffect(() => {
        // Update Redux
        dispatch(updateAdvance(
            100
        ))
    }, [])

    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Results</h4>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                    disabled={loading}
                >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    type="submit"
                    onClick={onClickFinish}
                    disabled={loading}
                >
                    Finish
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>
        </>
    )
}

export default QuestionnaireResults;