export default class ObjectHelper {


    parseFieldToInt(object, fields: Array<string>) {
            const keys = Object.keys(object);
            for(let key of keys){
                if(fields.includes(key)){
                    object[key] = parseInt(object[key]);
                }
            }
    }

  
}