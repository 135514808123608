import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";

import {
  getQuestionnaireGuestAnswers,
  getQuestionnaireInvitation,
  getQuestionnaireGuestQuestions,
} from "../../redux/ducks/questionnaire-guest-external-duck";

// Hook
const useGuestExternalAnswer = () => {
  // Get Necesary Data
  const answers = useSelector(getQuestionnaireGuestAnswers);
  const invitation = useSelector(getQuestionnaireInvitation);
  const questions = useSelector(getQuestionnaireGuestQuestions);
  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [answer, setAnswer] = useState<any>(null);

  const mapData = useCallback(() => {
    const answer = {
      guest_invitation_id: invitation.id,
      startup_id: invitation.anonymous ? invitation.group.startup_id : invitation.guest.guest_group.startup_id,
      items: [] as any,
    };
    // First Scoring
    const answerKeys = Object.keys(answers);
    for (let aKey of answerKeys) {
      const answerItem = answers[aKey];
      if (answerItem) {
        const question = questions.find((q) => q.sku === aKey);

        const item: any = {
          question_id: question.id,
          question_type_sku: question.sku,
          external_value: parseInt(answerItem),
        };

        answer.items.push(item);
      }
    }

    setAnswer(answer);
  }, [setAnswer, questions, invitation, answers]);

  useEffect(() => {
    if (invitation && answers && questions) {
      mapData();
    }
  }, [answers, questions, invitation, mapData]);

  return answer;
};

export default useGuestExternalAnswer;
