/*const Environment = {
    apiUrl: "https://myimove.herokuapp.com/graphql",
    wsUrl: "wss://myimove.herokuapp.com/graphql"
}*/
// Runtime configuration
import runtimeEnv from '@mars/heroku-js-runtime-env';

const env = runtimeEnv();

const Environment = {
    apiUrl: env.REACT_APP_API_URL || "http://localhost:4000/graphql",
    wsUrl: env.REACT_APP_API_WEBSOCKET || "ws://localhost:4000/graphql",
}
// console.log('Environment', { Environment })
// http://localhost:5000/graphql

export default Environment;