/*eslint-disable*/
import React, { useState, useEffect } from "react";
// React bootstrap
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Dropdown from 'react-bootstrap/Dropdown';
import NavDropdown from 'react-bootstrap/NavDropdown';
// Avatar
import Avatar from 'react-avatar';
// Router
import { withRouter } from "react-router";
import { NavLink } from "react-router-dom";
// Apollo
import { useQuery } from "react-apollo";
import { GET_USER_DATA } from "../../queries/users/user.query";
// Utils
import { removeToken } from "../../utils";
// Redux
import { useDispatch } from 'react-redux';
import { userDelete } from "../../redux/ducks/user-duck";



const AdminNavbar = (props: any) => {
  // Redux
  const dispatch = useDispatch();
  // Query
  const { loading, error, data } = useQuery(GET_USER_DATA, {
    fetchPolicy: 'no-cache'
  });
  // Props
  const [fullname, setFullname] = useState("")
  useEffect(() => {
    if (!loading) {
      if (data && data.userData) {
        setFullname(data.userData.fullname)
      } else {
        logout()
      }

    }
  }, [data, loading])

  const logout = () => {
    dispatch(userDelete())
    removeToken();
    props.history.push(`/auth/login`)
  }

  return (
    <Navbar bg="primary" expand="lg" id="navbar-admin" fixed={'top'}>
      <Container fluid>
        {/* Logo */}
        {/* <Navbar.Brand href="/admin/startups" className='text-white'>
            Startup
        </Navbar.Brand> */}
        {/* Toggle */}
        <Navbar.Toggle aria-controls="admin-nav order-1 " />
        {/* User Menu */}
        <Dropdown className="order-2 order-lg-3" alignRight={true}>
          <Dropdown.Toggle variant="outline-primary" id="dropdown-user">
            <Avatar name={fullname}
              className="mr-lg-2"
              src={''}
              round={true}
              color={'#00973c'}
              fgColor={'#fff'}
              maxInitials={2}
              size={'36px'} />
            {/* <span className="d-none d-lg-inline-block">
              {fullname}
            </span> */}
          </Dropdown.Toggle>
          <Dropdown.Menu className="w-100">
            {/*<Dropdown.Item href="#/action-1">
              Profile
            </Dropdown.Item>
            <Dropdown.Divider />*/}
            <Dropdown.Item onClick={logout}>
              Logout
            </Dropdown.Item>
          </Dropdown.Menu>
        </Dropdown>

        <Navbar.Collapse id="admin-nav" className="justify-content-center order-3 order-lg-2">
          <Nav >
            <NavLink className="nav-link" to="/admin/startups">Startups</NavLink>
            <NavLink className="nav-link" to="/admin/questions">Questions</NavLink>
            <NavLink className="nav-link" to="/admin/priorization">Priorization</NavLink>
            <NavLink className="nav-link" to="/admin/main-funding-metrics">Main funding Metrics</NavLink>
            <NavLink className="nav-link" to="/admin/main-funding">Main Funding</NavLink>
            <NavLink className="nav-link" to="/admin/best-practice">Best Practice</NavLink>
            <NavLink className="nav-link" to="/admin/tracks">Tracks</NavLink>
            <NavLink className="nav-link" to="/admin/challenge-templates">Challenges</NavLink>
            <NavLink className="nav-link" to="/admin/advices">Advices</NavLink>
            <NavLink className="nav-link" to="/admin/master-sessions">Master Sessions</NavLink>
            <NavDropdown title="Users" id="navItemUsers">
              <NavLink
                onClick={() => { document.body.click() }}
                className="dropdown-item" to="/admin/groups">Groups</NavLink>
              <NavLink
                onClick={() => { document.body.click() }}
                className="dropdown-item" to="/admin/users">Users</NavLink>

            </NavDropdown>
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>
  )
}


export default withRouter(AdminNavbar);
