
/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
// Questionnaire
import QuestionnaireRefinedIntro from "../../components/questionnaire-refined/QuestionnaireRefinedIntro";
import QuestionnaireRefinedResults from "../../components/questionnaire-refined/QuestionnaireRefinedResults";
import QuestionnaireRefinedQuestions from "../../components/questionnaire-refined/QuestionnaireRefinedQuestions";
import MultipleStep from "../../components/multiple-step/MultipleStep";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    getQuestionnaireAdvance,    
    setQuestions
} from '../../redux/ducks/questionnaire-duck';
// Client
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_REFINED_SCORING } from "../../queries/questions/questions.query";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
import { UPDATE_PROFILE } from "../../queries/users/user.query";
// Button
import Button from "../../components/buttons/Button";
// SVG
import { ReactSVG } from "react-svg";
// History
import { useHistory } from "react-router-dom";
// Toast
import { toast } from "react-toastify";

// Hooks
import useContinueScoring from "../../hooks/continue-scoring/useContinueScoring";

import useAnswerRefinedScoring from "../../hooks/continue-scoring/useAnswerRefinedScoring";
import { getUser } from "../../redux/ducks/user-duck";

const RefinedView = (props) => {
    // User
    const user = useSelector(getUser);
    // Arrow
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // History
    const history = useHistory();
    // Advance
    const advance = useSelector(getQuestionnaireAdvance);
    // Questions
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // Loading
    const [loading, setLoading] = useState(false);
    // User Update
    const userUpdate = useContinueScoring()
    // Answer Refined
    const answerRefinedInput = useAnswerRefinedScoring()
    // Save Current Advance
    const saveCurrentAdvance = async () => {
        const resp = await client.mutate({
            mutation: UPDATE_PROFILE,
            variables: { data: userUpdate }
        })
        const userUpdated = resp.data.updateProfile || {};
        if (!userUpdated.id) {
            throw new Error('Cannot Update User')
        }
    };
    // Save Current Answers
    const saveCurrentAnswers = async () => {
        // New Answer
        let newAnswer: any = null        
        // Create Answer
        const responseAnswer = await client.mutate({
            mutation: CREATE_ANSWER,
            variables: { data: answerRefinedInput }
        })
        // Response
        newAnswer = responseAnswer.data.createAnswer || {}
        if (!newAnswer.id) {
            throw new Error('Cannot save answer');
        }


    }
    // On Finish Later
    const onClickFinishLater = async () => {
        setLoading(true)
        try {
            await saveCurrentAdvance();
            await saveCurrentAnswers();
            if(user.startup_type === 'lead_generation'){
                history.push(`/dashboard-lead/main`)
            }else{
                history.push(`/dashboard/main`)
            }
            
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }
    }
    // Steps
    const steps = [
        {
            name: 'Intro',
            route: '/',
            component: QuestionnaireRefinedIntro
        },
        {
            name: 'Questions',
            route: '/questions',
            component: QuestionnaireRefinedQuestions
        },
        {
            name: 'Results',
            route: '/results',
            component: QuestionnaireRefinedResults
        },
    ];
    // Fetch Data
    const fetchData = useCallback(async () => {
        let questions;
        try {
            const resp = await client.query({
                query: LIST_QUESTIONS_REFINED_SCORING,
                fetchPolicy: 'no-cache'
            })
            questions = resp.data.listQuestions;
        } catch (e) {
            console.log(e);
        } finally {
            // dispatch(resetAnswers())
            dispatch(setQuestions(questions));
        }

    }, [client])
    // Init
    useEffect(() => {
        fetchData()
    }, [fetchData])
    // Render
    return (
        <>

            <MultipleStep steps={steps}>
                <Button
                    disabled={loading}
                    variant="" className="btn-violet btn-finish-later-questionnaire" onClick={onClickFinishLater}>
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Finish Later
                </Button>
                <div className="progress-multiple-step">
                    <div className="progress-multiple-step-bar" style={{ width: advance ? `${advance}%` : '0%' }}></div>
                </div>
            </MultipleStep>
        </>
    )
}

export default RefinedView;
