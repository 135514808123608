/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_TRACK_BY_ID } from "../../../queries/track/track.query";
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import { LIST_QUESTION_TYPES } from "../../../queries/question-types/question-types.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Form
import TrackUpdateForm from "../../../components/tracks/TrackUpdateForm";
// Router
import { useParams } from "react-router-dom";
// Hooks
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";




// Advice Edit View
const TrackEditView = (props) => {
    // Advice Id
    const { id } = useParams<any>();
    // Advice
    const [track, setTrack] = useState([]);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/tracks");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const responseTrack = await client.query({
            query: GET_TRACK_BY_ID,
            variables: { id: id },
            fetchPolicy: 'no-cache'
        })
        const track = responseTrack.data.getTrackById;
        setTrack(track);
        // Question Types
        const responseQuestionTypes = await client.query({
            query: LIST_QUESTION_TYPES,
            fetchPolicy: 'no-cache'
        })
        const questionTypes = responseQuestionTypes.data.listQuestionTypes || [];
        // Categories
        const responseCategories = await client.query({
            query: LIST_CATEGORIES,
            fetchPolicy: 'no-cache'
        })
        const categories = responseCategories.data.listCategories || [];
        // Set
        setCategories(categories)
        setQuestionTypes(questionTypes)
        // Loading
        setLoading(false);
    }, [
        client,
        setTrack,
        setQuestionTypes,
        setCategories,
        setLoading,
    ]);
    // Call Fetch Data
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])
    
    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/tracks${search}`}>Tracks</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">Edit Track</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <TrackUpdateForm
                            track={track}
                            questionTypes={questionTypes}
                            categories={categories}
                        />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default TrackEditView;