import React, { useRef } from 'react';
// Radar
import { Radar as RadarChartJs } from 'react-chartjs-2';
interface RadarChartProps {
    data: any,
    height?: number,
    width?: number,
    options?: any
}

const RadarChart = (props: RadarChartProps) => {
    const { data, width, height, options } = props;
    const referenceChart = useRef<RadarChartJs>(null);
    // Tooltip Issue When Is Big
    const tooltips = {
        enabled: false,
        bodyFontFamily: '"Source Sans Pro", sans-serif',
        bodyFontSize: 14,
        callbacks: {
            label: (tooltipItem, data) => {
                let label = data.labels[tooltipItem.index] || '';

                if (label) {
                    label += ': ';
                }
                label += Math.round(tooltipItem.yLabel * 100) / 100;
                return label;
            }
        },
        // Custom Tooltip
        custom: (tooltipModel) => {
            // Tooltip Element
            let tooltipEl = document.getElementById('chartjs-tooltip');

            // Create element on first render
            if (!tooltipEl) {
                tooltipEl = document.createElement('div');
                tooltipEl.id = 'chartjs-tooltip';
                tooltipEl.innerHTML = '<table></table>';
                document.body.appendChild(tooltipEl);
            }

            // Hide if no tooltip
            if (tooltipModel.opacity === 0) {
                tooltipEl.style.opacity = '0';
                tooltipEl.style.visibility = 'hidden';
                return;
            }

            // Set caret Position
            tooltipEl.classList.remove('above', 'below', 'no-transform');
            if (tooltipModel.yAlign) {
                tooltipEl.classList.add(tooltipModel.yAlign);
            } else {
                tooltipEl.classList.add('no-transform');
            }
            // Get Body
            const getBody = (bodyItem) => {
                return bodyItem.lines;
            }

            // Set Text
            if (tooltipModel.body) {
                const bodyLines = tooltipModel.body.map(getBody);
                let innerHtml = '<tbody>';

                // Body Lines
                bodyLines.forEach((body, i) => {
                    const colors = tooltipModel.labelColors[i];
                    const bodySpan = `<span>${body}</span>`
                    let keyColor = 'background:' + colors.backgroundColor;                  
                    const span = `<span class="chartjs-tooltip-key" style="${keyColor}"></span>`;
                    innerHtml += '<tr><td>' + span + bodySpan + '</td></tr>';
                });
                // End Body
                innerHtml += '</tbody>';
                // Root Table
                var tableRoot = tooltipEl.querySelector('table');
                if (tableRoot) {
                    tableRoot.innerHTML = innerHtml;
                }
            }

            // `this` will be the overall tooltip
            const position = referenceChart.current?.chartInstance.canvas.getBoundingClientRect();
            // Display, position, and set styles for font
            tooltipEl.style.opacity = '1';
            tooltipEl.style.visibility = 'visible';
            tooltipEl.style.position = 'absolute';
            tooltipEl.style.left = position.left + window.pageXOffset + tooltipModel.caretX + 'px';
            tooltipEl.style.top = tooltipModel.xPadding + position.top + window.pageYOffset + tooltipModel.caretY + 'px';
            tooltipEl.style.fontFamily = tooltipModel._bodyFontFamily;
            tooltipEl.style.fontSize = tooltipModel.bodyFontSize + 'px';
            tooltipEl.style.fontStyle = tooltipModel._bodyFontStyle;
            tooltipEl.style.padding = tooltipModel.yPadding + 'px ' + tooltipModel.xPadding + 'px';
            tooltipEl.style.pointerEvents = 'none';
        }
    }

    return (<>
        <RadarChartJs
            data={data}
            height={height}
            width={width}
            options={{ ...options, tooltips }}
            ref={referenceChart}
        />
    </>)
}

export default RadarChart;