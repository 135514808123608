import React from 'react';
// Redux
import { useDispatch } from 'react-redux';
import { adviceToShow } from '../../redux/ducks/advice-duck';


interface AdviceItemProps {
    advice: any,
    children?: any
}

const AdviceItem = (props: AdviceItemProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Props
    const { advice, children } = props;
    const onClick = (event) => {
        event.stopPropagation();
        event.preventDefault();
        dispatch(adviceToShow(advice))
    }
    return (
        <div className="advice-item" >
            {children && children}
            <a href='#advice-url' target="_blank" onClick={onClick}>{advice.title}</a>
        </div>
    );
};
export default AdviceItem;