
/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
// Navigation
import { useHistory } from "react-router-dom";
// Image
// import Image from 'react-bootstrap/Image';
// Charts
import BoxStartup from "../box/BoxStartup";
import Button from "../buttons/Button";
// import ProgressBarPercentageChart from "../charts/progress/ProgressBarPercentageChart";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionnairePage, getQuestionnaireQuestionsPage, setAnswers, setLastAnswer } from "../../redux/ducks/questionnaire-duck";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_LAST_ANSWER_DASHBOARD } from "../../queries/answer/answers.query";
// Helpers
import StartupHelper from "../../helpers/startup/startup.helper";
// Radar
import RadarChart from "../charts/radar/RadarChart";
// Props
interface BoxCompletionLeadProps {
    answerPercentage: any,
    radarCategories: any,
    loading: boolean
}
const BoxCompletionLead = (props: BoxCompletionLeadProps) => {
    // Client
    const client = useApolloClient();
    // Last Page Questionnaire
    const lastPage = useSelector(getQuestionnairePage);
    // History
    const history = useHistory()
    // Percentage
    const { answerPercentage, loading, radarCategories } = props;
    // Percentage 
    const [percentage, setPercentage] = useState({ percentage: -1 })
    // Helpers
    const startupHelper = new StartupHelper();
    // Dispatch
    const dispatch = useDispatch();
    // Question Page
    const currentPage = useSelector(getQuestionnaireQuestionsPage);
    // Ref
    // Radar
    const radarOptions = {
        maintainAspectRatio: false,
        layout: {
            padding: {
                left: 4,
                right: 4,
                top: 4,
                bottom: 4
            }
        },
        scale: {
            angleLines: {
                color: '#cbd5e0'
            },
            gridLines: {
                color: '#cbd5e0'
            },
            pointLabels: {
                fontColor: '#121024',
                fontSize: 14,
            },
            ticks: {
                fontColor: '#121024',
                fontSize: 12,
                suggestedMin: 0,
                suggestedMax: 10,
            }
        },
        legend: {
            display: false
        }
    }


    /**
     * On click Continue
     * Set the last Answer
     */
    const onClickContinue = async () => {
        // Push Last Answer (refined) In Redux Questionnaire
        const lastAnswerResponse = await client.mutate({
            mutation: GET_LAST_ANSWER_DASHBOARD,
            fetchPolicy: 'no-cache'
        })
        // Map Answer Items
        const lastAnswer = lastAnswerResponse.data.getLastAnswerDashboard;
        const answerItems = lastAnswer.answer_items;
        const idsRefined = startupHelper.mapRefinedScoringIds(answerItems);
        // Set Answers of continue scoring
        dispatch(setAnswers({
            currentPage,
            data: idsRefined
        }));
        dispatch(setLastAnswer(lastAnswer))
        if (lastPage) {
            if (lastPage !== 'intro') {
                history.push(`/questionnaire/refined-scoring/${lastPage}`)
            } else {
                history.push('/questionnaire/refined-scoring')
            }
        } else {
            history.push('/questionnaire/refined-scoring')
        }


    }
    const fetchPercentage = useCallback(() => {
        setPercentage({ percentage: parseInt(answerPercentage) });
    }, [answerPercentage, setPercentage])
    useEffect(() => {
        if (answerPercentage) {
            fetchPercentage();
        }
    }, [answerPercentage])


    return (
        <BoxStartup className={'box-completion'}>
            <BoxStartup.Header>
                <span className="title">
                    Completion
                </span>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0 h-100'}>

                {!loading && radarCategories &&
                    <div className="box-completion-content-big text-center">
                        {/* <p>Welcome to 34 elements</p> */}
                        <RadarChart
                            data={radarCategories}
                            height={264}
                            options={radarOptions}
                        />
                    </div>
                }

            </BoxStartup.Body>
            {/* <BoxStartup.Footer>
                
                <div className="box-completion-footer">
                    <Button className="btn-violet font-weight-medium" size="md" onClick={onClickContinue}>Continue Scoring</Button>
                </div>
            </BoxStartup.Footer> */}
        </BoxStartup >

    )
}

export default BoxCompletionLead;
