// questionnaire-guest-duck.js
// Actions
const RESET = 'startup/questionnaire-guest/RESET';
const RESET_ANSWERS = 'startup/questionnaire-guest/RESET_ANSWERS';
const UPDATE_ADVANCE = 'startup/questionnaire-guest/UPDATE_ADVANCE';
// Page
const UPDATE_QUESTIONS_PAGE_FIRST_SCORING = 'startup/questionnaire-guest/UPDATE_QUESTIONS_PAGE_FIRST_SCORING';
const UPDATE_QUESTIONS_PAGE_REFINED_SCORING = 'startup/questionnaire-guest/UPDATE_QUESTIONS_PAGE_REFINED_SCORING';
// Questions
const SET_QUESTIONS = 'startup/questionnaire-guest/SET_QUESTIONS';
const SET_USER = 'startup/questionnaire-guest/SET_USER';
// Answers
const SET_ANSWERS_FIRST_SCORING = 'startup/questionnaire-guest/SET_ANSWERS_FIRST_SCORING';
const SET_ANSWERS_REFINED_SCORING = 'startup/questionnaire-guest/SET_ANSWERS_REFINED_SCORING';
// Last Answer
const SET_LAST_ANSWER = 'startup/questionnaire-guest/SET_LAST_ANSWER';
// Questionanire Page
const SET_QUESTIONNAIRE_PAGE = 'startup/questionnaire-guest/SET_QUESTIONNAIRE_PAGE';
// Action Creators
export function resetQuestionnaireGuest() {
    return {
        type: RESET
    };
}
export function resetAnswersGuest() {
    return {
        type: RESET_ANSWERS
    };
}

export function updateAdvanceGuest(payload: any) {
    return {
        type: UPDATE_ADVANCE,
        payload: payload
    };
}

export function updateQuestionsPageFirstScoringGuest(payload: any) {
    return {
        type: UPDATE_QUESTIONS_PAGE_FIRST_SCORING,
        payload: payload
    };
}
export function updateQuestionsPageRefinedScoringGuest(payload: any) {
    return {
        type: UPDATE_QUESTIONS_PAGE_REFINED_SCORING,
        payload: payload
    };
}

export function setQuestionsGuest(state: []) {
    return {
        type: SET_QUESTIONS,
        payload: state
    };
}
// ANSWERS
export function setAnswersFirstScoringGuest(state) {
    return {
        type: SET_ANSWERS_FIRST_SCORING,
        payload: state
    };
}
export function setAnswersRefinedScoringGuest(state) {
    return {
        type: SET_ANSWERS_REFINED_SCORING,
        payload: state
    };
}
// LAST ANSWER
export function setLastAnswerGuest(state) {
    return {
        type: SET_LAST_ANSWER,
        payload: state
    };
}
export function setQuestionnaireGuestPage(state) {
    return {
        type: SET_QUESTIONNAIRE_PAGE,
        payload: state
    };
}
export function setQuestionnaireGuestUser(state) {
    return {
        type: SET_USER,
        payload: state
    };
}
// Interface
interface QuestionnaireState {
    questionnaire_page: any,
    questions: any,
    questions_page_first_scoring: number,
    questions_page_refined_scoring: number
    answers_first_scoring: any,
    answers_refined_scoring: any,
    user: any,
    advance: number,
    lastAnswer: any,
}
// Percentage total 11 pages
// 1 = 9 | 4 pages 36%
// 7 = 64% | questions
// Initial State
const initialState: QuestionnaireState = {
    questionnaire_page: null,
    questions: [],
    questions_page_first_scoring: 1,
    questions_page_refined_scoring: 1,
    answers_first_scoring: {},
    answers_refined_scoring: {},
    user: null,
    advance: 0,
    lastAnswer: null
};
// Reducer
export default function reducer(state: QuestionnaireState = initialState, action: any = {}) {
    // console.log('Questionnaire', action)
    switch (action.type) {
        // Update Startup Data
        case RESET: {
            return Object.assign(
                {},
                state,
                {
                    ...initialState
                }
            );
        }
        case RESET_ANSWERS: {
            return Object.assign(
                {},
                state,
                {
                    answers: {}
                }
            );
        }


        // Update Advance
        case UPDATE_ADVANCE: {
            return Object.assign(
                {},
                state,
                {
                    advance: action.payload
                }
            );
        }
        // Update Questions Page
        case UPDATE_QUESTIONS_PAGE_FIRST_SCORING: {
            return Object.assign(
                {},
                state,
                {
                    questions_page_first_scoring: action.payload
                }
            );
        }
        case UPDATE_QUESTIONS_PAGE_REFINED_SCORING: {
            return Object.assign(
                {},
                state,
                {
                    questions_page_refined_scoring: action.payload
                }
            );
        }
        // Set Questions
        case SET_QUESTIONS:
            return Object.assign(
                {},
                state,
                {
                    questions: action.payload || []
                }
            );
        // Set Answers First Scoring
        case SET_ANSWERS_FIRST_SCORING:
            const answers_first_scoring = { ...state.answers_first_scoring, ...action.payload };
            return Object.assign(
                {},
                state,
                {
                    answers_first_scoring: answers_first_scoring
                }
            );
        // Set Answer Refined Scoring
        case SET_ANSWERS_REFINED_SCORING:
            const answers_refined_scoring = { ...state.answers_refined_scoring, ...action.payload };
            return Object.assign(
                {},
                state,
                {
                    answers_refined_scoring: answers_refined_scoring
                }
            );
        // Set Last Answers
        case SET_LAST_ANSWER:
            return Object.assign(
                {},
                state,
                {
                    lastAnswer: action.payload
                }
            );
        // Set Questionnaire Page
        case SET_QUESTIONNAIRE_PAGE:
            return Object.assign(
                {},
                state,
                {
                    questionnaire_page: action.payload
                }
            );
        // Set User
        case SET_USER:
            return Object.assign(
                {},
                state,
                {
                    user: action.payload
                }
            );

        default:
            return state;
    }
}



// SELECTORS
export const getQuestionnaireGuestAdvance = (state) => state.questionnaireGuest.advance;
export const getQuestionnaireGuestQuestions = (state) => state.questionnaireGuest.questions;
// Questions Page
export const getQuestionnaireGuestQuestionsPageFirstScoring = (state) => state.questionnaireGuest.questions_page_first_scoring;
export const getQuestionnaireGuestQuestionsPageRefinedScoring = (state) => state.questionnaireGuest.questions_page_refined_scoring;
// Answers
export const getQuestionnaireGuestAnswersFirstScoring = (state) => state.questionnaireGuest.answers_first_scoring;
export const getQuestionnaireGuestAnswersRefinedScoring = (state) => state.questionnaireGuest.answers_refined_scoring;
// Last Answer
export const getQuestionnaireGuestLastAnswer = (state) => state.questionnaireGuest.lastAnswer;
export const getQuestionnaireGuestPage = (state) => state.questionnaireGuest.questionnaire_page;
export const getQuestionnaireGuestUser = (state) => state.questionnaireGuest.user;