
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Butonn
import Button from '../../../components/buttons/Button';

// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import { LIST_QUESTION_TYPES } from "../../../queries/question-types/question-types.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Last Location
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";
import GroupForm from "../../../components/groups/GroupForm";
// Router
import { useHistory, useParams } from "react-router-dom";
import { GET_GROUP_BY_ID } from "../../../queries/group/group.query";
import { toast } from "react-toastify";

const GroupEditView = (props) => {
    // Group Id
    const { id } = useParams<any>();
    // history
    const history = useHistory();
    // Question
    const [group, setGroup] = useState<any>(null);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/groups");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        // Group 
        const groupResponse = await client.query({
            query: GET_GROUP_BY_ID,
            variables: { id: id },
            fetchPolicy: 'no-cache'
        })
        const group = groupResponse.data.getGroupById || {};
        if (!group.id) {
            toast.error(`Group doesn't exist`);
            history.push('/admin/groups')
        }
        setGroup(group);
        // Question Types
        const responseQuestionTypes = await client.query({
            query: LIST_QUESTION_TYPES,
            fetchPolicy: 'no-cache'
        })
        const questionTypes = responseQuestionTypes.data.listQuestionTypes || [];
        // Categories
        const responseCategories = await client.query({
            query: LIST_CATEGORIES,
            fetchPolicy: 'no-cache'
        })
        const categories = responseCategories.data.listCategories || [];
        // Set
        setCategories(categories)
        setQuestionTypes(questionTypes)
        // Loading
        setLoading(false);
    }, [
        client,
        setGroup,
        setQuestionTypes,
        setCategories,
        setLoading,
    ]);

    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [])

    // Handle Click
    const handleClickManageUsers = () => {
        history.push(`/admin/groups/manage-users/${id}`)
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/groups${search}`}>Groups</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>

                        <Row >
                            <Col md="6" className="d-flex">
                                <span className="title">Edit Group</span>
                            </Col>
                            <Col md="6" className="d-flex justify-content-lg-end">
                                <Button
                                    variant={'primary'}
                                    size="sm"
                                    className={'ml-auto'} onClick={handleClickManageUsers}>
                                    <i className="fas fa-users-cog mr-2"></i>
                                    Manage Users
                                </Button>
                            </Col>
                        </Row>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading &&
                            <GroupForm
                                group={group}
                                categories={categories}
                                questionTypes={questionTypes} />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default GroupEditView;