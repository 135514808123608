import gql from 'graphql-tag'

export const QUESTION_TYPE_FIELDS = gql`
fragment QuestionTypeFields on QuestionType {    
    id
    sku
    title
    order 
    active
    category_sku
    description
    priorization_description
    strength_description

}`