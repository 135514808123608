
import React from "react";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import { useRouteMatch } from "react-router-dom";
// Router
import { NavLink } from "react-router-dom";
// Svg
import { ReactSVG } from "react-svg";
// Props
interface MultipleStepNavigationProps{
    steps: Array<any>,
}
const MultipleStepNavigation = (props: MultipleStepNavigationProps) => {
    const match = useRouteMatch();
    const arrow = require("../../assets/img/multiple-step/arrow-right.svg").default;
    const handleClick = (e) => {
         e.preventDefault()
    }
    
    return (
        <div className="multiple-step-navigation">
            <Container>
                <Nav className={'justify-content-center'} >
                    {props.steps.map((val, index)=>(
                        <NavLink        
                        onClick={handleClick} 
                 
                            exact
                            key={index} 
                            className="nav-link" 
                            to={`${match.path}${val.route}`}>
                                <span className="link-text">{val.name}</span>
                                <ReactSVG src={arrow} wrapper="span" className="arrow-right" />
                        </NavLink>
                    ))}                                    
                </Nav>
            </Container>
        </div>
    )
}

export default MultipleStepNavigation;