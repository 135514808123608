

export const SHOW_MODAL_LIST_GUESTS = 'startup/guests/SHOW_MODAL_LIST_GUESTS';



export function showModalListGuests(payload) {
    return {
        type: SHOW_MODAL_LIST_GUESTS,
        payload
    }
}


const initialState = {
    modalListGuestType: null,    
};
// Reducer
export default function reducer(state = initialState, action: any) {
    //  console.log('state', state)
    //  console.log('action', action)
    switch (action.type) {
      
        case SHOW_MODAL_LIST_GUESTS:
            return Object.assign(
                {},
                state,
                {
                    modalListGuestType: action.payload
                }
            );      
        default:
            return state;
    }
}
// Selectors
export const getModalListGuestType = (state) => state.guests.modalListGuestType;
