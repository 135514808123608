/*eslint-disable*/
import React, { useRef, useState } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import Button from "../buttons/Button";
// Forms
import { useForm, Controller } from "react-hook-form";
// Apollo
import { useApolloClient } from "react-apollo";
import {
  SEND_INVITATIONS_GUESTS_EXTERNAL,
  DELETE_ALL_GUEST_PER_TYPE,
  CREATE_OR_UPDATE_GUESTS_EXTERNAL,
  DELETE_GUEST_EXTERNAL
} from "../../queries/guests/guests.query";
// Toast
import { toast } from "react-toastify";
// Redux
import { useDispatch } from "react-redux";
// Summary
import { showModalListGuests } from "../../redux/ducks/guest-duck";
// SweeAlert
import Swal from "../sweet-altert/sweet-alert";
import CategoryBoxExternalRating from "../external-rating/CategoryBoxExternalRating";
import ListGuestExternal from "../guest-external/ListGuestExternal";
// Typehead
import { Typeahead } from 'react-bootstrap-typeahead'
// Props
interface BoxFoundersProps {
  categories: any;
  guestGroups: any;
  loadGuests: Function;
}

interface FormGuest {
  email: string;
  first_name: string;
  type: string;
}
interface FormGroupName {
  group: any;
}

const BoxFoundersExternal = (props: BoxFoundersProps) => {
  // Client
  const client = useApolloClient();
  // Dispatch
  const dispatch = useDispatch();
  // Props
  const { loadGuests, categories, guestGroups } = props;
  // List
  const [guests, setGuests] = useState<Array<any>>(new Array<any>())
  const [typesSelected, setTypesSelected] = useState<Array<string>>([]);
  // Loading
  const [loading, setLoading] = useState(false);
  const [loadingResetRatings, setLoadingResetRatings] = useState(false);
  // Group
  const [guestGroupSelected, setGuestGroupSelected] = useState<any>([])
  // Typeheade Ref
  const typeheadeRef = useRef<any>(null);
  // Form
  const {
    control: controlGroup,
    // handleSubmit: handleSumbitGroup,
    errors: errorsGroup,
    reset: resetGroup,
    getValues: getValuesGroup
  } = useForm<FormGroupName>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  const { register, handleSubmit, errors, setError, reset, control } = useForm<FormGuest>({
    mode: "onSubmit",
    reValidateMode: "onChange",
    defaultValues: {},
  });

  // Add Item To list
  const addToList = async (data: FormGuest) => {
    const exist = guests.findIndex(g => g.email === data.email);
    if (exist >= 0) {
      setError("email", {
        type: "exist",
        message: "This email has already exist in list",
        shouldFocus: true,
      });

      toast.error("This email has already exist in list");
    } else {
      data.type = 'guest_external';
      guests.push(data);
      setGuests([...guests]);
      reset();
      if (control.fieldsRef && control.fieldsRef.current.first_name) {
        const input: any = control.fieldsRef.current.first_name.ref;
        if (input) {
          input.focus({ preventScroll: true })
        }
      }
    }
  };
  // Remove Guest
  const removeGuest = async (index) => {
    const guest = guests[index];
    console.log(guest);
    // If have id, remove it
    if (guest.id) {
      const response = await client.mutate({
        mutation: DELETE_GUEST_EXTERNAL,
        variables: { id: guest.id, group_id: guest.guest_group_id },
        fetchPolicy: "no-cache",
      });
      const guestDeleted = response.data.deleteGuestExternal || {};
      console.log('guestDeleted', guestDeleted)
      if (guestDeleted.id) {
        toast.success("Guest deleted succefully!");
        guests.splice(index, 1);
        setGuests([...guests]);
        loadGuests()
      } else {
        toast.error("Oops...An error occurred. Try again later");
      }

    } else {
      // Set Array
      guests.splice(index, 1);
      setGuests([...guests]);
    }

  }
  // Remove Item
  const onRemoveGuest = (index) => {
    if (guestGroupSelected.length > 0) {
      const group = guestGroupSelected[0];
      if (group.customOption) {
        guests.splice(index, 1);
        setGuests([...guests]);
      } else {
        Swal.fire({
          title: `<span>Are you sure to remove guest?</span>`,
          html: `<span>All data will be remove</span>`,
          icon: "warning",
          showCancelButton: true,
          confirmButtonText: "Yes, remove!",
          cancelButtonText: "No, keep it",
        }).then(async (result) => {
          if (result.value) {
            removeGuest(index);
          }
        });
      }

    }

  }
  // Reset All
  const resetAll = () => {
    reset();
    resetGroup();
    setGuests([]);
    setTypesSelected([]);
    setGuestGroupSelected([]);
    typeheadeRef.current.clear()
  }
  // Selected Types
  const onSelectItem = (type) => {
    const indexOf = typesSelected.indexOf(type)
    if (indexOf === -1) {
      typesSelected.push(type);
    } else {
      typesSelected.splice(indexOf, 1)
    }
    setTypesSelected([...typesSelected]);
  }
  // Create or Update Group
  const createOrUpdateGroup = async () => {
    const groupSelected = getValuesGroup().group[0];
    setLoading(true);
    let group = {}
    if (groupSelected.customOption) {
      group = {
        name: groupSelected.name,
      }
    } else {
      group = {
        id: groupSelected.id,
        startup_id: groupSelected.startup_id,
        name: groupSelected.name,
      }
    }
    try {
      const mapGuests = guests.map((guest) => ({
        id: guest.id ? guest.id : null,
        email: guest.email,
        first_name: guest.first_name,
        type: guest.type
      }))
      const guestExternalInput = {
        guests: mapGuests,
        group
      }
      const respGuest = await client.mutate({
        mutation: CREATE_OR_UPDATE_GUESTS_EXTERNAL,
        variables: { data: guestExternalInput },
        fetchPolicy: 'no-cache'
      })
      const guestsInvitation = respGuest.data.createOrUpdateGuestsExternal || [];
      if (guestsInvitation.length > 0) {
        if (groupSelected.id) {
          toast.success("Group updated succefully");
        } else {
          toast.success("Group created succefully");
        }
        resetAll();
        loadGuests();
      } else {
        toast.error("Oops...An error occurred. Try again later");
      }
    } catch (e) {
      console.log(e);
      toast.error("Oops...An error occurred. Try again later");
    }
    finally {
      setLoading(false);
    }
  }
  // Send Invitations
  const sendInvitationsGroup = async () => {
    const groupSelected = getValuesGroup().group[0];
    setLoading(true);
    let group = {}
    if (groupSelected.customOption) {
      group = {
        name: groupSelected.name,
      }
    } else {
      group = {
        id: groupSelected.id,
        startup_id: groupSelected.startup_id,
        name: groupSelected.name,
      }
    }
    try {
      const mapGuests = guests.map((guest) => ({
        id: guest.id ? guest.id : null,
        email: guest.email,
        first_name: guest.first_name,
        type: guest.type
      }))
      const guestExternalInput = {
        guests: mapGuests,
        group,
        types: typesSelected
      }
      const respGuest = await client.mutate({
        mutation: SEND_INVITATIONS_GUESTS_EXTERNAL,
        variables: { data: guestExternalInput },
        fetchPolicy: 'no-cache'
      })
      const guestsInvitation = respGuest.data.sendInvitationsGuestsExternal || [];
      if (guestsInvitation.length > 0) {
        toast.success("Invitations sent succefully");
        resetAll();
        loadGuests();
      } else {
        toast.error("Oops...An error occurred. Try again later");
      }
    } catch (e) {
      console.log(e);
      toast.error("Oops...An error occurred. Try again later");
    }
    finally {
      setLoading(false);
    }


  }
  // List Participants
  const onClickListParticipants = () => {
    dispatch(showModalListGuests("guest-internal"));
  };
  // Reset Ratings
  const onClickResetRatings = async () => {
    Swal.fire({
      title: `<span>Are you sure to reset ratings?</span>`,
      html: `<span>All invitations will be deleted</span>`,
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, reset!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.value) {
        resetRatingsFn();
      }
    });
  };
  const resetRatingsFn = async () => {
    setLoadingResetRatings(true);
    try {
      const response = await client.mutate({
        mutation: DELETE_ALL_GUEST_PER_TYPE,
        variables: { type: "guest_external" },
        fetchPolicy: "no-cache",
      });
      const deleted = response.data.deleteAllGuestPerType || {};
      if (deleted) {
        toast.success("Reset ratings succefully");
        reset();
      } else {
        toast.error("Oops...An error occurred. Try again later");
      }
    } catch (e) {
      console.log("error", e);
      toast.error("Oops...An error occurred. Try again later");
    } finally {
      setLoadingResetRatings(false);
      resetAll();
      loadGuests();
    }
  };
  // On Change Group
  const onChangeGroup = (value) => {
    setGuestGroupSelected(value)
    if (value.length === 0) {
      setGuests([])
    } else {
      if (!value[0].customOption) {
        setGuests(value[0].guests)
      }
    }
  }
  // Render Button group
  const renderButtonGroup = () => {
    if (guestGroupSelected.length > 0) {
      if (guestGroupSelected[0].customOption) {
        return (
          <Col className="column-btn mb-4 mb-lg-0">
            <Button
              variant=""
              className={"btn-violet"}
              type="button"
              disabled={loading || errorsGroup.group ? true : false || guests.length === 0}
              onClick={createOrUpdateGroup}>
              Create
          </Button>
          </Col>
        )

      } else {
        return (
          <Col className="column-btn two-btn mb-4 mb-lg-0">
            <Button
              variant=""
              className={"btn-violet"}
              type="button"
              disabled={loading || errorsGroup.group ? true : false || guests.length === 0}
              onClick={createOrUpdateGroup}>
              Update
          </Button>
            <Button
              variant=""
              className={"btn-violet"}
              type="button"
              disabled={loading || typesSelected.length === 0 || errorsGroup.group ? true : false || guests.length === 0}
              onClick={sendInvitationsGroup}>
              Invite
          </Button>
          </Col>
        )
      }
    }
  }

  // Render
  return (
    <BoxStartup className={""} id="box-founders-external">
      <BoxStartup.Header>
        {/* Create Group Form */}
        <div className="box-founders-invite-header group-creation mb-lg-4">
          <Form noValidate name="form-send-invitation">
            <Form.Row>
              <Col className="mb-4 mb-lg-0 column-title align-items-lg-center d-lg-flex">
                <span className="title">Group Creation</span>
              </Col>
              <Col className="column-input mb-4 mb-lg-0">
                <div className={`${errorsGroup.group ? 'is-invalid' : ''}`}>
                  <Controller
                    name="group"
                    control={controlGroup}
                    defaultValue={false}
                    rules={{ required: 'Group Name is required' }}
                    default
                    render={props => (
                      <Typeahead
                        id="input-size-example"
                        labelKey="name"
                        options={guestGroups}
                        placeholder="Choose or Create group"
                        allowNew={true}
                        newSelectionPrefix={`Select to create: `}
                        onChange={(value) => {
                          onChangeGroup(value);
                          props.onChange(value);
                        }}
                        value={guestGroupSelected}
                        clearButton={true}
                        ref={typeheadeRef}
                      />
                    )} />
                  {errorsGroup.group && (
                    <div className="invalid-feedback">{errorsGroup.group.message}</div>
                  )}

                </div>
              </Col>
              {/* If Selected */}
              {renderButtonGroup()}
              {/* If Not selected */}
              {guestGroupSelected.length === 0 &&
                <Col className="column-btn mb-4 mb-lg-0">
                  <Button variant="" disabled={true} className={"btn-violet"}> Create</Button>
                </Col>
              }
            </Form.Row>

          </Form>
        </div>
        {/* Add To List */}
        <div className="box-founders-invite-header">
          <Form onSubmit={handleSubmit(addToList)} noValidate name="form-add-to-list">
            <Form.Row>
              <Col className="mb-4 mb-lg-0 column-title align-items-lg-center d-lg-flex">
                <span className="title">List Invitation</span>
              </Col>
              <Col className="column-input mb-4 mb-lg-0">
                <Form.Control
                  name="first_name"
                  placeholder="Enter name..."
                  isInvalid={errors.first_name ? true : false}
                  maxLength={150}
                  ref={register({
                    required: "First Name is required",
                  })}
                />
                {errors.first_name && (
                  <div className="invalid-feedback">{errors.first_name.message}</div>
                )}
              </Col>
              <Col className="column-input mb-4 mb-lg-0">
                <Form.Control
                  name="email"
                  type="email"
                  isInvalid={errors.email ? true : false}
                  ref={register({
                    required: "E-mail is required",
                    pattern: {
                      value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                      message: "Invalid email address",
                    },
                  })}
                  placeholder="Enter email..."
                />
                {errors.email && (
                  <div className="invalid-feedback">{errors.email.message}</div>
                )}
              </Col>
              <Col className="column-btn mb-4 mb-lg-0">
                <Button
                  variant=""
                  className={"btn-violet"}
                  type="submit"
                  disabled={loading}
                >
                  Add to list
                </Button>
              </Col>
            </Form.Row>
          </Form>
        </div>
      </BoxStartup.Header>
      <BoxStartup.Body className={`p-0`}>
        {/* List Guests*/}
        <div className="list-invitation-external-wrapper">
          <ListGuestExternal guests={guests} onRemoveItem={onRemoveGuest} />
        </div>

        {/* Actions */}
        <div className={`box-founders-actions ${guests.length > 0 ? 'with-line' : ''}`}>
          <Row>
            <Col className="column-description">
              <p className="text-description">
                Invite external individuals to get another perspective and minimize bias. Rating is from 0 to 10.<br />
                Visualize results by checking “External” below each speedometer.
              </p>
            </Col>
            <Col className="column-actions d-flex justify-content-lg-end">
              <div>
                <Button
                  variant=""
                  className={"btn-light-violet"}
                  onClick={onClickListParticipants}
                >
                  List of participants
                </Button>
                <Button
                  variant=""
                  className={"btn-poor"}
                  onClick={onClickResetRatings}
                  disabled={loadingResetRatings}
                >
                  Reset Ratings
                </Button>
              </div>
            </Col>
          </Row>
        </div>
        {/* Categories*/}
        <div className="category-list-external-rating">
          <Row className="m-0">
            {categories &&
              categories.length > 0 &&
              categories.map((category, index) => (
                <Col key={category.sku} className="p-0 category-box-external-wrapper">
                  <CategoryBoxExternalRating
                    category={category}
                    onSelectItem={onSelectItem}
                    typesSelected={typesSelected}
                    disabled={guestGroupSelected.length === 0 || (guestGroupSelected.length > 0 && guestGroupSelected[0].customOption)}
                  />
                </Col>
              ))}
          </Row>
        </div>
      </BoxStartup.Body>
    </BoxStartup>
  );
};

export default BoxFoundersExternal;