
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
// Validation
import { Controller, FormProvider, useForm } from "react-hook-form";
// Form
import ReactSelect from "../select/ReactSelect";
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { MoonLoader } from "react-spinners";
import Uploader from "../uploader/Uploader";
import { DELETE_BEST_PRACTICE_FILE, UPDATE_BEST_PRACTICE } from "../../queries/best-practice/best-practice.query";
import UrlInput from "../forms/UrlInput";
// Props
interface BestPracticeUpdateFormProps {
    bestPractice: any,
    questionTypes: any,
    categories: any,
    onUpdated: Function
}
const BestPracticeUpdateForm = (props: BestPracticeUpdateFormProps) => {
    // Client
    const client = useApolloClient();
    // Form
    const methods = useForm({
        mode: 'onChange'
    });
    // Best Practice
    const [link, setLink] = useState('');
    const [type, setType] = useState('');
    const [categoryQuestion, setCategoryQuestion] = useState(null);
    const [typeQuestion, setTypeQuestion] = useState(null);
    const [files, setFiles] = useState<any>([]);
    // Loading
    const [loading, setLoading] = useState(false);
    // Options
    const [optionsCategories, setOptionCategories] = useState<any>(null);
    const [optionsType, setOptionsType] = useState<any>(null);
    const [optionsTypeFilter, setOptionsTypeFilter] = useState<any>([]);
    // Props
    const {
        bestPractice,
        questionTypes,
        categories,
        onUpdated
    } = props;
    // Type Change
    const onChangeTypeQuestion = useCallback((option) => {
        setTypeQuestion(option);
        if (option) {
            methods.setValue('type_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('type_sku', null, { shouldValidate: true })
        }
        
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTypeQuestion, methods.setValue])
    // Category Change, change filter question types
    const onChangeCategoryQuestion = useCallback((option, event) => {
        setCategoryQuestion(option);
        if (option) {
            methods.setValue('category_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('category_sku', null, { shouldValidate: true })
        }
        if (event.action === 'select-option') {
            let filter = [];
            if (option) {
                filter = optionsType.filter(filter => filter.category_sku === option.value);
            }
            setOptionsTypeFilter(filter);
            onChangeTypeQuestion(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCategoryQuestion, methods.setValue, optionsType, setOptionsTypeFilter, onChangeTypeQuestion])
    // Fetch Data
    const fetchData = useCallback(() => {
        // Map options Categories
        const categoriesMap = categories.map(cat => {
            return {
                label: cat.title,
                value: cat.sku
            }
        })
        setOptionCategories(categoriesMap);
        // Map options Question Types
        const typesMap = questionTypes.map(type => {
            return {
                category_sku: type.category_sku,
                label: type.title,
                value: type.sku
            }
        })
        setOptionsType(typesMap);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        // Options type
        setOptionsType,
        questionTypes,
        // Categories
        setOptionCategories,
        categories,
        //Best Practice
        bestPractice
    ])

    // Fetch Form
    const fetchForm = useCallback(() => {
        // Type
        setType(bestPractice.type_file_sku);
        // Values
        methods.setValue('name', bestPractice.name);
        methods.setValue('description', bestPractice.description);
        // Get Category
        const category = optionsCategories.find(o => o.value === bestPractice.type.category.sku)
        setCategoryQuestion(category);
        methods.setValue('category_sku', category.value);
        // Get Type
        const type = optionsType.find(o => o.value === bestPractice.type.sku)
        setTypeQuestion(type);
        methods.setValue('type_sku', type.value);
        const filter = optionsType.filter(filter => filter.category_sku === type.category_sku);
        setOptionsTypeFilter(filter);
        if (bestPractice.type_file_sku === 'link') {
            setFiles([])
            methods.setValue('file_input', null)
            if (bestPractice.document) {
                setLink(bestPractice.document.url)
            }
        } else {
            setLink('')
            methods.setValue('link', '')
            // File Input
            if (bestPractice.document) {
                setFiles([bestPractice.document])
                // methods.setValue('file_input', [bestPractice.document])
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        bestPractice,
        setLink,
        setCategoryQuestion,
        setTypeQuestion,
        setFiles,
        optionsCategories,
        optionsType,
        methods.setValue,
        setOptionsTypeFilter
    ])
    // Init
    useEffect(() => {
        if (categories && questionTypes && bestPractice) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setType, bestPractice, categories, questionTypes])

    // fetchForm
    useEffect(() => {
        if (optionsCategories && optionsCategories) {
            fetchForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsCategories, optionsCategories])
    // On Change Type
    const onChangeType = (type) => {
        setType(type)
    }
    // Handle Files
    const onChangeUploader = async (files) => {
        methods.setValue('file_input', files, { shouldValidate: true })
        setFiles(files)
    }
    // Create
    const onClickUpdate = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.formState.errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateBestPractice()
        }
    }
    const updateBestPractice = async () => {
        try {
            setLoading(true)
            const values = methods.getValues()
            const toUpdate: any = {
                id: bestPractice.id,
                ...values,
                type_file_sku: type,
                file_input: null
            }
            delete toUpdate.category_sku;
            if (type === 'file') {
                if (values.file_input) {
                    const fileInput = values.file_input[0].id ? null : values.file_input[0];
                    toUpdate.file_input = fileInput
                }
            }
            const createBestPracticeResp = await client.mutate({
                mutation: UPDATE_BEST_PRACTICE,
                variables: { data: toUpdate }
            })
            const bestPracticeUpdated = createBestPracticeResp.data.updateBestPractice || {}
            if (bestPracticeUpdated.id) {
                toast.success(`Best Practice update succefully!`);
                onUpdated(bestPracticeUpdated)
            } else {
                throw new Error('Cannot create')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }

    const onDeleteFile = async () => {
        try {
            const deletBestPracticeFile = await client.mutate({
                mutation: DELETE_BEST_PRACTICE_FILE,
                variables: { id: bestPractice.id }
            })
            console.log('deletBestPracticeFile', deletBestPracticeFile)
            if (!deletBestPracticeFile.data.deleteBestPracticeFile) {
                throw new Error('Cannot delete file ')
            }
            toast.success('File delete succefuly');
            setFiles([])
            methods.setValue('file_input', null)

        } catch (e) {
            console.log(e)
            toast.error('Oops...An error occurred. Try again later');
        }
    }
    return (
        <LoadingOverlay active={loading}
            text={'Updating Best Practice...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods}>
                <Form noValidate name={'create-best-practice'}>
                    <Row>
                        <Col lg="6">
                            <Form.Group >
                                <Form.Label className="d-block">Best Practice Document</Form.Label>
                                <ButtonGroup>
                                    <Button variant="primary" onClick={() => onChangeType('link')} className={type === 'link' ? 'btn-violet' : 'btn-light'} >Link</Button>
                                    <Button variant="primary" onClick={() => onChangeType('file')} className={type === 'file' ? 'btn-violet' : 'btn-light'} >File</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* File */}
                    {type === 'file' && <Row>
                        <Col lg="6">
                            <Form.Group className={`${methods.errors.file_input ? 'is-invalid' : ''}`}>
                                <Controller
                                    name="file_input"
                                    control={methods.control}
                                    defaultValue={false}
                                    // rules={{ required: 'File is required' }}
                                    default
                                    render={props => (
                                        <Uploader
                                            onChange={onChangeUploader}
                                            files={files}
                                            onDeleteItem={onDeleteFile}
                                        />
                                    )} />
                                {methods.errors.file_input && <div className="invalid-feedback"> {methods.errors.file_input.message} </div>}
                            </Form.Group >
                        </Col>
                    </Row>}
                    {/* Or Link */}
                    {type === 'link' &&
                        <Row>
                            <Col lg="9">
                                <UrlInput
                                    name="link"
                                    value={link}
                                />
                            </Col>
                        </Row>
                    }
                    {/* Relation */}
                    <Row>
                        <Col lg="9">
                            <Row>
                                {/* Main element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.category_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Main element</Form.Label>
                                        <Controller
                                            name="category_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Main element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a main-element"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.category_sku}
                                                    options={optionsCategories}
                                                    onChange={onChangeCategoryQuestion}
                                                    value={categoryQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {methods.errors.category_sku && <div className="invalid-feedback"> {methods.errors.category_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                                {/* Sub element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.type_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Sub element</Form.Label>
                                        <Controller
                                            name="type_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Sub element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a sub-element"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.type_sku}
                                                    options={optionsTypeFilter}
                                                    onChange={onChangeTypeQuestion}
                                                    value={typeQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />
                                        {methods.errors.type_sku && <div className="invalid-feedback"> {methods.errors.type_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Name */}
                    <Row>
                        <Col lg="9">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Enter a description"
                                    isInvalid={methods.errors.name}
                                    ref={methods.register({ required: 'The name is required' })}
                                />
                                {methods.errors.name && <div className="invalid-feedback"> {methods.errors.name.message} </div>}

                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Description */}
                    <Row>
                        <Col lg="9">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    name="description"
                                    type="text"
                                    placeholder="Enter a description"
                                    as={'textarea'}
                                    rows={3}
                                    ref={methods.register}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="success"
                        id="btn-finish-question"
                        onClick={onClickUpdate}
                        disabled={loading}
                        className="btn-fixed fixed-right shadow">
                        {!loading && <i className="fas fa-save icon"></i>}
                        {loading && <MoonLoader color="#8e8e93" size={40} />}
                    </Button>

                </Form>
            </FormProvider>

        </LoadingOverlay>
    )
}

export default BestPracticeUpdateForm;