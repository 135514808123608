import React, { useCallback, useEffect, useState } from 'react';
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// Validations
import { useFormContext, Controller } from 'react-hook-form';
import { CHALLENGES_PRIORITY_LEVEL, CHALLENGES_PRIORITY_TESTABILITY } from '../../constants/challenges/challenges.constants';
import HtmlEditor from '../forms/HtmlEditor';
import StartupDatePicker from '../forms/DatePicker';
import Button from '../buttons/Button';
import Uploader from '../uploader/Uploader';
import { useApolloClient } from 'react-apollo';
import { REMOVE_CHALLENGE_DOCUMENT } from '../../queries/challenges/challenge.query';
import { toast } from 'react-toastify';
import { challengeCreate } from '../../redux/ducks/challenge-duck';
import { useDispatch } from 'react-redux';

interface CreateChallengeFormProps {
    template?: any,
    readOnly: boolean,
    modalAction: string
}

const CreateChallengeForm = (props: CreateChallengeFormProps) => {
    // Client
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // Template
    const { template, readOnly, modalAction } = props;
    // Custom
    const [blocking, setBlocking] = useState(false);
    const [critical, setCritical] = useState(false);
    const [descriptionContent, setDescriptionContent] = useState('');
    const [challengeOutcomeContent, setChallengeOutcomeContent] = useState('');
    const [dueDate, setDueDate] = useState<any>(null)
    // Files
    const [files, setFiles] = useState<any>([]);
    // Toggle Details
    const [toggleDetails, setToggleDetails] = useState(false)
    // Form
    const { register, errors, control, setValue } = useFormContext();
    // Draw Error
    const drawError = (errors, name) => {
        if (getError(errors, name)) {
            return <div className="invalid-feedback"> {errors['challenge'][name].message} </div>
        }
        return null;
    }
    // Get Error of Form
    const getError = (errors, name) => {
        if (errors['challenge']) {
            if (errors['challenge'][name]) {
                const keys = Object.keys(errors['challenge'][name]);
                if (keys.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    // Fetch the template
    const fetchTemplate = useCallback(() => {
        // Set States
        setBlocking(template.blocking)
        setCritical(template.critical)
        setDescriptionContent(template.description)
        setChallengeOutcomeContent(template.challenge_outcome)
        // console.log('template', template);
        if (template.due_date) {
            // console.log('template.due_date',template.due_date)
            setDueDate(template.due_date);
        }
        if (template.documents) {
            setFiles(template.documents);
        }
        if (modalAction === 'update' || modalAction === 'view') {
            setToggleDetails(true)
        } else {
            setToggleDetails(false)
        }
    }, [template, modalAction, setToggleDetails, setDueDate])

    // Change Template
    useEffect(() => {
        if (template && template.id) {
            fetchTemplate()
        }
    }, [fetchTemplate, template])

    // Files Action
    const onChangeUploader = async (files) => {
        setValue('challenge.files_input', files, { shouldValidate: true })
        setFiles(files)
    }
    const onDeletDocument = async (file) => {
        try {
            const fileModelInput = {
                ...file
            };
            delete fileModelInput.__typename;
            const responseDocument = await client.mutate({
                mutation: REMOVE_CHALLENGE_DOCUMENT,
                variables: { id: template.id, document: fileModelInput }
            })
            const challengeUpdated = responseDocument.data.removeChallengeDocument || {};
            console.log(challengeUpdated);
            if (!challengeUpdated.id) {
                throw new Error('Cannot deleted');
            }
            setFiles(files => files.filter((f) => f.id !== file.id))
            toast.success(`Document deleted succefully!`);
            // To Update List
            dispatch(challengeCreate(template))
        } catch (e) {
            console.log(e);
            toast.error(`Oops...An error occurred. Try again later`);
        }
    }
    return (
        <>
            {/* Title */}
            <Row >
                <Col lg="12">
                    <Form.Group >
                        <Form.Label>Challenge Title</Form.Label>
                        <Form.Control
                            disabled={readOnly}
                            name="challenge.title"
                            type="text"
                            placeholder="Enter a a title"
                            isInvalid={getError(errors, 'title')}
                            ref={register({
                                required: 'Title is required',
                            })}
                        />
                        {drawError(errors, 'title')}

                    </Form.Group>
                </Col>
            </Row>
            {/* Hide  Challenge Details */}
            { (modalAction === 'update' || modalAction === 'view') &&
                <Row>
                    <Col lg="12">
                        <Form.Group>
                            <Button className="btn-light-violet" onClick={() => setToggleDetails(!toggleDetails)}>
                                {toggleDetails ? 'Challenge Details' : 'Hide Challenge Details'}
                            </Button>
                        </Form.Group>
                    </Col>
                </Row>}
            {/* Challenge Details */}
            <div className={`challenge-details ${toggleDetails ? 'collapse' : ''}`}>
                {/* Description */}
                <Row >
                    <Col lg="12">
                        {/* Challengre Description */}
                        <Form.Group className={getError(errors, 'description') ? 'is-invalid' : ''}>
                            <Form.Label>Challenge Description</Form.Label>
                            <Controller
                                render={(props) => (
                                    <HtmlEditor
                                        disabled={readOnly}
                                        value={descriptionContent}
                                        onChange={(value) => {
                                            props.onChange(value)
                                        }}
                                    />
                                )}
                                name="challenge.description"
                                rules={{ required: 'Description is required' }}
                                control={control}

                            />
                            {/* Error */}
                            {drawError(errors, 'description')}

                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    {/* Priority Level */}
                    <Col lg="6">
                        <Form.Group >
                            <Form.Label >Priority Level</Form.Label>
                            <Form.Control
                                disabled={readOnly}
                                as="select"
                                name="challenge.priority_level_sku"
                                isInvalid={getError(errors, 'priority_level_sku')}
                                className="custom-select"
                                defaultValue=""
                                ref={register({
                                    required: 'Priority Level is required',
                                })}
                            >
                                <option disabled value="">Select...</option>
                                {CHALLENGES_PRIORITY_LEVEL.map((priority, index) => (
                                    <option value={priority.sku} key={index}>{priority.title}</option>
                                ))}

                            </Form.Control>
                            {/* Error */}
                            {drawError(errors, 'priority_level_sku')}
                        </Form.Group>
                    </Col>
                    {/* Testability */}
                    <Col lg="6">
                        <Form.Group >
                            <Form.Label >Testability</Form.Label>
                            <Form.Control
                                disabled={readOnly}
                                as="select"
                                name="challenge.testability_sku"
                                isInvalid={getError(errors, 'testability_sku')}
                                className="custom-select"
                                defaultValue=""
                                ref={register({
                                    required: 'Testability is required',
                                })}
                            >
                                <option disabled value="">Select...</option>
                                {CHALLENGES_PRIORITY_TESTABILITY.map((testability, index) => (
                                    <option value={testability.sku} key={index}>{testability.title}</option>
                                ))}

                            </Form.Control>
                            {/* Error */}
                            {drawError(errors, 'testability_sku')}
                        </Form.Group>
                    </Col>
                </Row>
                <Row >
                    {/* Blocking */}
                    <Col lg="3" className="d-flex align-items-center">
                        <Form.Group >
                            <Controller
                                name='challenge.blocking'
                                control={control}
                                render={(props) => (
                                    <Form.Check
                                        disabled={readOnly}
                                        custom
                                        type={'checkbox'}
                                        id={`create-modal-blocking`}
                                        label={`Blocking`}
                                        name="blocking"
                                        value={'blocking'}
                                        checked={blocking}
                                        onChange={(event: any) => {
                                            const { checked } = event.target;
                                            props.onChange(checked)
                                            setBlocking(checked)
                                        }}
                                    />
                                )}
                            />
                        </Form.Group>
                    </Col>
                    {/* Critical */}
                    <Col lg="3" className="d-flex align-items-center">
                        <Form.Group >
                            <Controller
                                name='challenge.critical'
                                control={control}
                                render={(props) => (
                                    <Form.Check
                                        custom
                                        disabled={readOnly}
                                        type={'checkbox'}
                                        id={`create-modal-critical`}
                                        label={`Critical for Next Round`}
                                        name="critical"
                                        value={'critical'}
                                        checked={critical}
                                        onChange={(event: any) => {
                                            const { checked } = event.target;
                                            props.onChange(checked)
                                            setCritical(checked)
                                        }}
                                    />)}
                            />
                        </Form.Group>
                    </Col>
                    {/* Test Due Date */}
                    <Col lg="6">
                        <Form.Group as={Row}>
                            <Form.Label column lg={4} className={'d-lg-flex align-items-lg-center mb-lg-0'}>Test Due Date</Form.Label>
                            <Col lg={8}>
                                <Controller
                                    // defaultValue={dueDate ? dueDate : null}
                                    render={(props) => (
                                        <StartupDatePicker
                                            disabled={readOnly}
                                            value={dueDate}
                                            isInvalid={getError(errors, 'due_date')}
                                            onChange={(date) => {
                                                // console.log('date', date)
                                                 setDueDate(date)
                                                 props.onChange(date)
                                            }}
                                            minDate={new Date()}
                                        />
                                    )}
                                    name="challenge.due_date"
                                    control={control}
                                    rules={{ required: 'Due Date is required' }}
                                />
                            </Col>

                        </Form.Group>
                    </Col>
                    {/* Due Date Error */}
                    <Col lg={6}></Col>
                    <Col lg={6}>
                        <Row>
                            <Col lg={4}></Col>
                            <Col lg={8} className={`${getError(errors, 'due_date') ? 'is-invalid' : ''}`}>
                                {/* Error */}
                                {drawError(errors, 'due_date')}
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
            { (modalAction === 'update' || modalAction === 'view') &&
                <>
                    {/* Challenge outcome */}
                    <Row>
                        <Col lg={12}>
                            {/* Challengre Description */}
                            <Form.Group className={getError(errors, 'challenge_outcome') ? 'is-invalid' : ''}>
                                <Form.Label>Challenge Outcome</Form.Label>
                                <Controller
                                    render={(props) => (
                                        <HtmlEditor
                                            disabled={readOnly}
                                            value={challengeOutcomeContent}
                                            onChange={(value) => {
                                                props.onChange(value)
                                            }}
                                        />
                                    )}
                                    name="challenge.challenge_outcome"
                                    control={control}

                                />
                                {/* Error */}
                                {drawError(errors, 'challenge_outcome')}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Files */}
                    <Row>
                        <Col lg="12">
                            <Form.Group>
                                <Controller
                                    name="challenge.files_input"
                                    control={control}
                                    rules={{}}
                                    default
                                    render={props => (
                                        <Uploader
                                            disabled={readOnly}
                                            onChange={onChangeUploader}
                                            files={files}
                                            multiple={true}
                                            // Enbale delete when have id
                                            enableRemoveItem={!readOnly}
                                            enableViewItem={true}
                                            onDeleteItem={onDeletDocument}
                                        />
                                    )} />
                            </Form.Group >
                        </Col>
                    </Row>
                </>
            }
        </>
    );
};
export default CreateChallengeForm;
