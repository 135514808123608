import gql from 'graphql-tag'
import { FILE_MODEL_FIELDS } from '../file-model/file-model.fragment'

export const CHALLENGE_FIELDS = gql`
${FILE_MODEL_FIELDS}
fragment ChallengeFields on Challenge {        
    id
    startup_id
    user_id
    type_sku
    status_sku
    # Resolvers
    # type: QuestionType;
    # startup: Startup;
    # user: User;
    # Challenge 
    title
    description
    challenge_outcome
    priority_level_sku
    testability_sku
    critical
    blocking
    # Test
    # test_title
    # test_description
    due_date
    metric_type_sku
    # progress
    progress
    # KPI
    kpi_metric
    kpi_metric_target
    kpi_metric_current
    # Completed
    complete
    # Template Id
    template_id
    documents{
        ...FileModelFields
    }
}`