
/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
// Core
import Button from "../../components/buttons/Button";
// Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// React form
import { useForm } from 'react-hook-form';
// Apollo
import { useApolloClient } from "react-apollo";
// Queries
import { LOGIN } from "../../queries/users/user.query";
// Utils
import { setToken, isAdmin } from "../../utils";
// Helpers
import TokenHelper from "../../helpers/security/token.helper";
// Redux
import { useDispatch } from 'react-redux';
import { userCreate, userDelete } from "../../redux/ducks/user-duck";
import { resetQuestionnaire, setQuestionnairePage, updateQuestionsPage } from "../../redux/ducks/questionnaire-duck";
import { resetDashboardData } from "../../redux/ducks/dashboard-duck";
import { answerFetchLastAnswer } from "../../redux/ducks/answer-duck";

export interface LoginUser {
    email: string;
    password: string;

}

const Login = (props) => {
    // Redux
    const dispatch = useDispatch();
    // Route
    const query = new URLSearchParams(props.location.search);
    const password_changed = query.get('password_changed')
    const confirm_invitation = query.get('confirm_invitation')
    // Form
    const { register, handleSubmit, errors } = useForm<LoginUser>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {}

    });
    // Reset First Login Data
    const resetFirstLoginData = useCallback(() => {
        dispatch(resetQuestionnaire())
        dispatch(resetDashboardData())
        dispatch(userDelete())
        dispatch(answerFetchLastAnswer(null))
    }, [dispatch])
    // Client
    const client = useApolloClient();
    // Alert
    const [alert, setAlert] = useState<any>({
        show: false,
        variant: "danger",
        message: ""
    });
    // Show Alert
    const showAlert = (show = false, variant = 'danger', message = '') => {
        setAlert({
            show: show,
            variant: variant,
            message: message
        });
    }
    // Loading
    const [loading, setLoading] = useState(false);
    // Fetch Continue Scoring
    const fetchContinueScoring = async (user) => {
        // Continue Scoring
        const continue_scoring = user.continue_scoring;
        // Set Page of continue scoring
        dispatch(setQuestionnairePage(continue_scoring.page))
        dispatch(updateQuestionsPage(continue_scoring.questionPage))

    }
    // Login submit
    const login = async (data) => {
        showAlert();
        setLoading(true);
        try {
            const variables = {
                ...data,
            }
            const resp = await client.mutate({
                mutation: LOGIN,
                variables: variables,
                fetchPolicy: 'no-cache'
            })

            // console.log("login success: Barer " + resp.data.login);
            const token = resp.data.login || null;
            const tokenHelper = new TokenHelper();
            const user = tokenHelper.decode(token);
            if (user) {
                // console.log('user', user)
                await setToken(token)
                delete user.password;
                // Set User
                dispatch(userCreate(user));
                if (isAdmin(user.type)) {
                    props.history.push(`/admin/startups`)
                } else {
                    if (user.first_login) {
                        if (user.startup_type === 'not_limited') {
                            props.history.push(`/questionnaire/intro/`)
                        } else if (user.startup_type === 'limited') {
                            props.history.push(`/questionnaire/intro-guest/`)
                        }
                        else if (user.startup_type === 'lead_generation') {
                            props.history.push(`/questionnaire/intro-lead/`)
                        }

                    } else {
                        if (user.continue_scoring) {
                            // Fetch Continue Scoring
                            await fetchContinueScoring(user);
                        }
                        if (user.startup_type === 'lead_generation') {
                            props.history.push(`/dashboard-lead/main`)
                        } else {
                            props.history.push(`/dashboard/main`)
                        }

                    }
                }
            } else {
                showAlert(true, 'danger', 'Wrong email or password')
            }

        } catch (e) {
            console.log(e)
            showAlert(true, 'danger', 'Wrong email or password')

        } finally {
            setLoading(false);
        }

    }
    // Usee effect
    useEffect(() => {
        if (password_changed) {
            showAlert(true, 'success', 'The password was updated successfully');
        }
    }, [password_changed])
    useEffect(() => {
        if (confirm_invitation) {
            showAlert(true, 'success', 'Invitation confirmed successfully');
        }
    }, [confirm_invitation])

    useEffect(() => {
        resetFirstLoginData()
    }, [])
    return (
        <div id="login-container" >
            <div className="form-box-login">
                <Row>
                    <Col md={12} >
                        <div className="form-box-header">
                            <img src={require('../../assets/img/logo-login.png').default} alt="" className="logo" />
                            <h4 className="text-capitalize text-center text-white">Log in to 34 Elements</h4>
                        </div>

                        {/* <p className="help-body text-center text-white">
                            Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                            Nullam id libero euismod, vestibulum eros eget
                        </p> */}
                    </Col>
                    {/* Alert */}
                    <Col xs={12} >
                        <Row className="justify-content-md-start justify-content-center">
                            <Col>
                                {alert.show && <Alert
                                    key='alert-login'
                                    className="my-2 my-md-2"
                                    variant={alert.variant}
                                    dismissible
                                    show={alert.show}
                                    onClose={() => showAlert()}
                                >
                                    {alert.message}
                                </Alert>
                                }
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Form */}
                <form noValidate className="p-0 form-login" onSubmit={handleSubmit(login)} >
                    <Row>
                        <Col md={12}>
                            <Form.Group controlId="formBasicEmail">
                                <div className="form-group-icon">
                                    <Form.Control
                                        name="email"
                                        type="email"
                                        placeholder="E-Mail Address"
                                        isInvalid={errors.email ? true : false}
                                        ref={register({
                                            required: 'The email is required',
                                            pattern: {
                                                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                                message: "Invalid email address"
                                            }
                                        })}
                                    />
                                    <span className="material-icons">
                                        person_outline
                                    </span>
                                </div>
                                {errors.email && <div className="invalid-feedback"> {errors.email.message} </div>}

                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            <Form.Group >
                                <div className="form-group-icon">
                                    <Form.Control
                                        name="password"
                                        type="password"
                                        placeholder="Password"
                                        isInvalid={errors.password ? true : false}
                                        ref={register({
                                            required: 'The password is required'
                                        })}
                                    />
                                    <span className="material-icons">
                                        lock_outline
                                    </span>
                                </div>
                                {errors.password && <div className="invalid-feedback"> {errors.password.message} </div>}
                            </Form.Group>
                        </Col>
                        <Col md={12}>
                            {/* Remember me  */}
                            <Row>
                                <Col md={12} lg={12}>
                                    <Form.Check
                                        className="success text-white"
                                        name="remember"
                                        custom
                                        type={'checkbox'}
                                        id={`custom-remember`}
                                        label={`Keep me logged in`}
                                    />
                                </Col>
                            </Row>
                            {/* Sign In  */}
                            <Row>
                                <Col xs={12} className="my-4 my-md-4">
                                    <Button disabled={loading} loading={loading} variant="success" type="submit" className="w-100">
                                        Log In
                                    </Button>
                                </Col>
                            </Row>
                            <Row>
                                {/* Register */}
                                {/* <Col md={12} className="">
                                    <a href="/auth/register"
                                        className="d-block mt-4 mt-lg-0 recover-password">You do not have an account? Sign up</a>
                                </Col> */}
                                {/* Forgot Password */}
                                <Col md={12} className="mt-4">
                                    <a href="/auth/recover-password"
                                        className="d-block mt-4 mt-lg-0 recover-password">Forgot password?</a>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                </form>
            </div>
        </div>
    )
}

export default Login;
