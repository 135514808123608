/*eslint-disable*/
import React from "react";
import RegisterForm from "../../components/register/RegisterForm";

// Register
const RegisterGuest = (props) => {
    return (<RegisterForm type="limited" title="Register Academy" />)
}

export default RegisterGuest;
