
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { DELETE_USER, FIND_ALL_USERS } from "../../../queries/users/user.query";
// Router
import { useHistory } from "react-router-dom";
// Table
import UserTable from "../../../components/users/UserTable";
// Sweet Alert
import Swal from '../../../components/sweet-altert/sweet-alert'
// Toast
import { toast } from "react-toastify";

// Advice Index View
const UserIndexView = (props) => {
    // Router
    const history = useHistory();
    // Users
    const [users, setUsers] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: FIND_ALL_USERS,
            fetchPolicy: 'no-cache',
            variables: { primary_account: true, active: true }
        })
        const users = response.data.findAllUsers || [];
        setUsers(users.filter(u => u.startup.type === 'limited'));
        setLoading(false);

    }, [client, setUsers]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickEditItem = (user) => {
        history.push(`/admin/users/edit/${user.id}`)
    }

    // Delete
    const onClickDeleteItem = (user) => {
        Swal.fire({
            title: `Are you sure to delete?`,
            html: `
                <span>You are trying deleted a User: <span class="font-weight-medium">${user.email}</span></span><br/>
                <span class="text-danger font-weight-medium">All related information will be deleted</span>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_USER,
                    variables: { id: user.id }
                }).then(resp => {
                    toast.success('Startup deleted succefully');
                    fetchData();
                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted Startup. Try later');
                });
            }
        })
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>Users</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Users</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <UserTable
                            users={users}
                            loading={loading}
                            onClickEditItem={onClickEditItem}
                            onClickDeleteItem={onClickDeleteItem}
                        />
                    </BoxStartup.Body>
                </BoxStartup>

            </Container>
        </>
    )
}

export default UserIndexView;
