import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";
// Helper
import DateHelper from "../../helpers/dates/date.helper";
// Props
interface ReportsTableProps {
    groups: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
    onClickDeleteItem?: Function,
    onClickCopyItem?: Function,

}
// Reports Table
const ExternalInvitationTable = (props: ReportsTableProps) => {
    // Props
    const { groups, loading, onClickViewItem, onClickDeleteItem, emptyString, onClickCopyItem } = props;
    // Helpers
    const dateHelper = new DateHelper()
    // On Click View
    const onClickView = (startup) => {
        if (onClickViewItem) {
            onClickViewItem(startup);
        }
    }
    const onClickDelete = (startup) => {
        if (onClickDeleteItem) {
            onClickDeleteItem(startup);
        }
    }
    const onClickCopy = (startup) => {
        if (onClickCopyItem) {
            onClickCopyItem(startup)
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-dashboard'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Copy Link
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-dashboard'}
                        className="btn btn-outline-dark btn-rounded-action action-table"
                        onClick={() => onClickCopy(row)}
                    >
                        <i className="fas far fa-copy"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            View
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-eye"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-trash'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-trash`}>
                            Delete
                        </Tooltip>
                    }>
                    <button type="button"
                        className="btn btn-outline-danger btn-rounded-action action-table"
                        onClick={() => onClickDelete(row)}>
                        <i className="far fa-trash-alt"></i>
                    </button>
                </OverlayTrigger>
         
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        
        {

            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {

            dataField: 'created_at',
            text: 'Created',
            sort: true,
            formatter: cell => dateHelper.format(cell),
            sortValue: cell => dateHelper.sortValue(cell)
        },
        {

            dataField: 'types_object',
            text: 'Sub Elements',
            sort: false,
            formatter: cell => cell.map(value => value.title).join(', '),
            headerStyle: { width: '400px' }
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
            // headerStyle: { minWidth: 'px' }
        }
    ];


    return (
        <>
            <DefaultTable
                data={groups}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'created_at',
                        order: 'desc'
                    }
                }
            />
        </>
    )
}

export default ExternalInvitationTable;