import gql from 'graphql-tag'

export const GUEST_INVITATION_FIELDS = gql`
fragment GuestInvitationFields on GuestInvitation {    
    id
    guest_id
    types   
    answered
    advance
    types
    anonymous
    question_types{
        id
        sku
        title
        order
    }
}`
