import React, { useEffect, useCallback, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
import { UPDATE_GUEST } from "../../queries/guests/guests.query";
//  Toast
import { toast } from "react-toastify";
// History
import { useHistory } from "react-router-dom";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    getQuestionnaireGuestQuestions,
    setQuestionnaireGuestPage
} from '../../redux/ducks/questionnaire-guest-duck';
// Pagination
import QuestionsPaginationFirstScoring from "./question-pagination-first-scoring/QuestionsPaginationFirstScoring";
import useGuestAnswer from "../../hooks/guest/useGuestAnswer";
import useGuestUpdate from "../../hooks/guest/useGuestUpdate";


const QuestionnaireGuestFirstQuestions = (props) => {
    // Next Route
    const nextRoute = `/questionnaire-guest/intro/second-questions`;
    // Client
    const client = useApolloClient();
    // History
    const history = useHistory();
    //  Dispatch
    const dispatch = useDispatch()
    // Answer Input
    const answerInput = useGuestAnswer();
    const guestUpdate = useGuestUpdate();
    // Question Store
    const questionsStore = useSelector(getQuestionnaireGuestQuestions)
    const [questions, setQuestions] = useState<any>([])
    // Loading
    const [loading, setLoading] = useState(false);
    // Fetch Data
    const fetchData = useCallback(() => {
        setQuestions(questionsStore);
    }, [setQuestions, questionsStore])

    useEffect(() => {
        if (questionsStore !== questions) {
            fetchData()
        }
    }, [fetchData, questionsStore, questionsStore])

 
    // On Click Next Section
    const onClickNextSection = async () => {
        try {
            setLoading(true);
            // Save current advance
            // Update Guest Advance
            const responseGuest = await client.mutate({
                mutation: UPDATE_GUEST,
                variables: { data: guestUpdate },
                fetchPolicy: 'no-cache'
            })
            const guestUpdated = responseGuest.data.updateGuest || {}
            if (!guestUpdated.id) {
                throw new Error('Cannot update guest')
            }
            // Create Answer
            console.log('answerInput', answerInput)
            const responseAnswer = await client.mutate({
                mutation: CREATE_ANSWER,
                variables: { data: answerInput }
            })
            const newAnswer = responseAnswer.data.createAnswer || {}
            if (!newAnswer.id) {
                throw new Error('Cannot save answer');
            }
            history.push(nextRoute);
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false);

        }

    }
    useEffect(() => {
        dispatch(setQuestionnaireGuestPage('first-questions'))
    }, [])
    return (
        <>
            <QuestionsPaginationFirstScoring
                questions={questions}
                onClickNextSection={onClickNextSection}
                loading={loading}
            />
        </>
    )
}

export default QuestionnaireGuestFirstQuestions;