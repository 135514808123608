
import customTotal from './CustomTotal';

export const PAGINATION_DEFAULT = {
    page: 1,
    sizePerPage: 10,
    pageStartIndex: 1,
    firstPageText: 'First',
    prePageText: 'Back',
    nextPageText: 'Next',
    lastPageText: 'Last',
    nextPageTitle: 'First Page',
    prePageTitle: 'Pre page',
    firstPageTitle: 'Next Page',
    lastPageTitle: 'Last Page',
    showTotal: true,
    paginationTotalRenderer: customTotal,
    disablePageTitle: true,
}