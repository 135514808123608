import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateHelper from "../../helpers/dates/date.helper";
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";

// Props
interface ChallengeTemplateTableProps {
    templates: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
    onClickDeleteItem?: Function
}
// Reports Table
const ChallengeTemplateTable = (props: ChallengeTemplateTableProps) => {
    const dateHelper = new DateHelper();
    // Props
    const { templates, loading, onClickViewItem, onClickDeleteItem, emptyString } = props;
    // On Click View
    const onClickView = (challengeTemplate) => {
        if (onClickViewItem) {
            onClickViewItem(challengeTemplate);
        }
    }
    const onClickDelete = (challengeTemplate) => {
        if (onClickDeleteItem) {
            onClickDeleteItem(challengeTemplate);
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Edit Challenge Template
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-edit"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-delete'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-delete`}>
                            Delete Challenge Template
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-danger btn-rounded-action action-table"
                        onClick={() => onClickDelete(row)}
                    >
                        <i className="far fa-trash-alt"></i>
                    </button>

                </OverlayTrigger>
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {

            dataField: 'metric_type_sku',
            text: 'Metric',
            sort: true,
        },
        {

            dataField: 'track.title',
            text: 'Track',
            formatter: (cell, row) => {
                if (!cell) {
                    return 'No data'
                }
                return cell;
            },
            sort: true,
        },
        {

            dataField: 'track.type.category.title',
            text: 'Main Element',
            formatter: (cell, row) => {
                if (!cell) {
                    return 'No data'
                }
                return cell;
            },
            sort: true,
        },
        {

            dataField: 'track.type.title',
            text: 'Sub Element',
            formatter: (cell, row) => {
                if (!cell) {
                    return 'No data'
                }
                return cell;
            },
            sort: true,
        },
        {

            dataField: 'title',
            text: 'Challenge title',
            sort: true,
        },
        {

            dataField: 'created_at',
            text: 'Created',
            sort: true,
            formatter: cell => dateHelper.format(cell),
            sortValue: cell => dateHelper.sortValue(cell)
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
            // headerStyle: { minWidth: 'px' }
        }
    ];


    return (
        <>
            <DefaultTable
                data={templates}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'created_at',
                        order: 'desc'
                    }
                }
            />
        </>
    )
}

export default ChallengeTemplateTable;