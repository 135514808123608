import gql from 'graphql-tag'
import { GUEST_INVITATION_FIELDS } from '../guest-invitation/guest-invitation.fragment'
import { GUEST_FIELDS } from '../guests/guest.fragment'
import { STARTUP_FIELDS } from '../startup/startup.fragment'
import { GUEST_GROUP_FIELDS } from "./guest-group.fragment"


export const LIST_GUEST_GROUP = gql`  
        ${GUEST_GROUP_FIELDS}              
        ${GUEST_FIELDS}
        query listGuestGroup($admin_created: Boolean){                     
            listGuestGroup(admin_created: $admin_created){
                ...GuestGroupFields
                guests{
                    ...GuestFields
                }
            }
}`

export const LIST_GUEST_GROUP_INVITATIONS = gql`  
        ${GUEST_GROUP_FIELDS}              
        ${GUEST_INVITATION_FIELDS}
        query listGuestGroup($admin_created: Boolean){                     
            listGuestGroup(admin_created: $admin_created){
                ...GuestGroupFields
                types_object{
                  title
                }
                invitations{
                    ...GuestInvitationFields
                }
            }
}`
export const LIST_GUEST_GROUP_ADMIN = gql`  
${GUEST_GROUP_FIELDS}          
${STARTUP_FIELDS}
query listGuestGroupAdmin($id: String!){                     
    listGuestGroupAdmin(id:$id){
        ...GuestGroupFields
        types_object{
            title
        }
    }
    getStartupById(id: $id){
        ...StartupFields
     }      
}`


export const CREATE_GUEST_GROUP = gql`  
        ${GUEST_GROUP_FIELDS}              
        mutation createGuestGroup($data:GuestGroupInput!){                     
            createGuestGroup(data:$data){
                ...GuestGroupFields
        }
}`
export const UPDATE_GUEST_GROUP = gql`  
${GUEST_GROUP_FIELDS}              
mutation updateGuestGroup($data:GuestGroupInput!){                     
    updateGuestGroup(data:$data){
        ...GuestGroupFields
}
}`

export const GET_GUEST_GROUP_BY_ID = gql`  
        ${GUEST_GROUP_FIELDS}              
        ${STARTUP_FIELDS}              
        query getGuestGroupById($id: String!){                     
            getGuestGroupById(id: $id){
                ...GuestGroupFields
                startup{
                    ...StartupFields
                }
            }
}`

export const DELETE_GUEST_GROUP = gql`  
${GUEST_GROUP_FIELDS}              
mutation deleteGuestGroup($id:String!){                     
    deleteGuestGroup(id:$id){
        ...GuestGroupFields
    }
}`
