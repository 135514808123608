import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getQuestionnaireGuestPage,
  getQuestionnaireGuestQuestionsPage,
  getQuestionnaireInvitation
} from "../../redux/ducks/questionnaire-guest-external-duck";

// Hook
const useGuestInvitationUpdate = () => {
  // Get Necesary Data
  const invitation = useSelector(getQuestionnaireInvitation);
  const page = useSelector(getQuestionnaireGuestPage);
  const questionPage = useSelector(
    getQuestionnaireGuestQuestionsPage
  );
  
  const [guestUpdate, setGuestUpdate] = useState<any>(null);

  const mapData = useCallback(() => {
    const guestInput = {
      id: invitation.id,
      advance: {
        page: page,
        questionPage:questionPage,
      },
      anonymous: invitation.anonymous,
      answered: false,
    };
    setGuestUpdate(guestInput);
  }, [setGuestUpdate, invitation, page, questionPage]);

  useEffect(() => {
    if (invitation) {
      mapData();
    }
  }, [invitation, mapData, page, questionPage]);

  return guestUpdate;
};

export default useGuestInvitationUpdate;
