


export const ADVICE_TO_SHOW = 'startup/challenges/ADVICE_TO_SHOW';


export function adviceToShow(payload) {
    return {
        type: ADVICE_TO_SHOW,
        payload
    }
}



const initialState = {
    adviceToShow: null,    
};

export default function reducer(state = initialState, action: any) {
    // console.log('state', state)
    // console.log('action', action)
    switch (action.type) {
        case ADVICE_TO_SHOW:
            return Object.assign(
                {},
                state,
                {
                    adviceToShow: action.payload
                }
            );
       
        default:
            return state;
    }
}
export const getAdviceToShow = (state) => state.advices.adviceToShow;

