export const SHOW_MODAL = 'startup/modalwrapper/SHOW_MODAL';
export const HIDE_MODAL = 'startup/modalwrapper/HIDE_MODAL';


export function showModal(payload) {
    return {
        type: SHOW_MODAL,
        payload
    }
}
export function hideModal() {
    return {
        type: HIDE_MODAL
    }
}

const initialState = {
    show: false
};

const modalWrapperReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SHOW_MODAL:
            return {
                show: true,
                ...payload
            };
        case HIDE_MODAL:
            return {};
        default:
            return state;
    }
};

// Selector
export const getCurrentModal = (state) => state.modalWrapper;
// Export
export default modalWrapperReducer;