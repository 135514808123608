
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { Controller, FormProvider, useForm } from "react-hook-form";
// Form
import ReactSelect from "../select/ReactSelect";
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { MoonLoader } from "react-spinners";
import Uploader from "../uploader/Uploader";

import { UPDATE_MASTER_SESSION } from "../../queries/master-session/master-session.query";
import { MASTER_SESSION_TOPICS } from "../../constants/master-session/master-session.constants";
import ObjectHelper from "../../helpers/objects/object.helper";
// Props
interface MasterSessionUpdateFormProps {
    masterSession: any,
    groups: any,
    onUpdated: Function

}
const MasterSessionUpdateForm = (props: MasterSessionUpdateFormProps) => {
    // Client
    const client = useApolloClient();
    // Helper
    const objectHelper = new ObjectHelper();
    // Form
    const methods = useForm({
        mode: 'onChange'
    });
    // Master Session
    const [files, setFiles] = useState<any>([]);
    const [topic, setTopic] = useState<any>(null);
    const [group, setGroup] = useState<any>(null);
    // Loading
    const [loading, setLoading] = useState(false);
    // Options
    const [optionsGroup, setOptionsGroup] = useState<any>(null);
    const [optionsTopic] = useState<any>(MASTER_SESSION_TOPICS);
    // Props
    const {
        masterSession,
        groups,
        onUpdated
    } = props;




    // On Change Topic
    const onChangeTopic = useCallback((option, event) => {
        setTopic(option);
        if (option) {
            methods.setValue('topic_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('topic_sku', null, { shouldValidate: true })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTopic, methods.setValue])
    // On Change Group
    const onChangeGroup = useCallback((option, event) => {
        setGroup(option);
        if (option) {
            methods.setValue('group_id', option.value, { shouldValidate: true })
        } else {
            methods.setValue('group_id', null, { shouldValidate: true })
        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTopic, methods.setValue])
    // Handle Files
    const onChangeUploader = async (files) => {
        methods.setValue('file_input', files, { shouldValidate: true })
        setFiles(files)
    }
    // Create
    const onClickUpdate = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.formState.errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateMasterSession()
        }
    }
    const updateMasterSession = async () => {
        try {
            setLoading(true)
            const values = methods.getValues()
            const toUpdate: any = {
                id: masterSession.id,
                ...values,
                file_input: null
            }

            if (values.file_input) {
                const fileInput = values.file_input[0].id ? null : values.file_input[0];
                toUpdate.file_input = fileInput
            }

            // Parse
            objectHelper.parseFieldToInt(toUpdate, ['number_of_slides', 'reading_time']);

            const update = await client.mutate({
                mutation: UPDATE_MASTER_SESSION,
                variables: { data: toUpdate }
            })
            const masterSessionUpdated = update.data.updateMasterSession || {}
            if (masterSessionUpdated.id) {
                toast.success(`Master Session update succefully!`);
                onUpdated(masterSessionUpdated)
            } else {
                throw new Error('Cannot create')
            }

        } catch (e: any) {
            console.log('e', e)
            const error = (e.graphQLErrors) ? e.graphQLErrors[0].message : undefined;
            if (error && error.includes('already exist')) {
                methods.setError('group_id', {
                    type: 'exist',
                    message: 'Group already exist ',
                    shouldFocus: true,
                });

                toast.error(`Already exist a master class for this group`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }

        } finally {
            setLoading(false)
        }
    }
    // Fetch Form After Fetch data
    const fetchForm = useCallback(() => {

        // Values
        methods.setValue('description', masterSession.description);
        methods.setValue('number_of_slides', masterSession.number_of_slides);
        methods.setValue('reading_time', masterSession.reading_time);
        // Group

        const _group = optionsGroup.find(o => o.value === masterSession.group_id)
        methods.setValue('group_id', _group.value);
        setGroup(_group);
        // Topic
        const _topic = optionsTopic.find(o => o.value === masterSession.topic_sku)
        methods.setValue('topic_sku', _topic.value);
        setTopic(_topic);
        // File Input
        if (masterSession.document) {
            setFiles([masterSession.document])
        }


        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        masterSession,
        optionsTopic,
        optionsGroup,
        methods.setValue,
    ])
    // Init
    useEffect(() => {
        if (optionsGroup) {
            fetchForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionsGroup])

    // First Fetch Data Async
    const fetchData = useCallback(() => {
        // Map options Categories
        const groupsMap = groups.map(group => {
            return {
                label: group.name,
                value: group.id
            }
        })
        console.log('optionsGroup', groupsMap)
        setOptionsGroup(groupsMap);
    }, [groups])
    useEffect(() => {
        if (masterSession && groups) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [masterSession, groups])


    return (
        <LoadingOverlay active={loading}
            text={'Updating Master Session...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods}>
                <Form noValidate name={'update-master-session'}>
                    {/* File */}
                    <Row>
                        <Col lg="6">
                            <Form.Group className={`${methods.errors.file_input ? 'is-invalid' : ''}`}>
                                <Controller
                                    name="file_input"
                                    control={methods.control}
                                    defaultValue={false}
                                    // rules={{ required: 'File is required' }}
                                    default
                                    render={props => (
                                        <Uploader
                                            onChange={onChangeUploader}
                                            files={files}
                                            validFileExtensions={['.pdf']}
                                            enableRemoveItem={false}
                                        />
                                    )} />
                                {methods.errors.file_input && <div className="invalid-feedback"> {methods.errors.file_input.message} </div>}
                            </Form.Group >
                        </Col>
                    </Row>
                    {/* Relation Group */}
                    <Row>
                        <Col lg="9">
                            <Row>
                                {/* Group */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.group_id ? 'is-invalid' : ''}`}>
                                        <Form.Label>Group</Form.Label>
                                        <Controller
                                            name="group_id"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Group is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a group"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.group_id}
                                                    options={optionsGroup}
                                                    onChange={onChangeGroup}
                                                    value={group}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {methods.errors.group_id && <div className="invalid-feedback"> {methods.errors.group_id.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Relation Topic*/}
                    <Row>
                        <Col lg="9">
                            <Row>
                                {/* Topic */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.topic_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Topic</Form.Label>
                                        <Controller
                                            name="topic_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Topic is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a topic"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.topic_sku}
                                                    options={optionsTopic}
                                                    onChange={onChangeTopic}
                                                    value={topic}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {methods.errors.topic_sku && <div className="invalid-feedback"> {methods.errors.topic_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>

                    {/* Description */}
                    <Row>
                        <Col lg="9">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    name="description"
                                    type="text"
                                    placeholder="Enter a description"
                                    as={'textarea'}
                                    rows={3}
                                    ref={methods.register({ required: 'The description is required' })}
                                    isInvalid={methods.errors.description}
                                />
                                {methods.errors.description && <div className="invalid-feedback"> {methods.errors.description.message} </div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Number of Slides */}
                    <Row>
                        <Col lg="4">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Number of Slides</Form.Label>
                                <Form.Control
                                    name="number_of_slides"
                                    type="number"
                                    placeholder="Enter a number"
                                    isInvalid={methods.errors.number_of_slides}
                                    ref={methods.register({ required: 'The number of slides is required' })}
                                />
                                {methods.errors.number_of_slides && <div className="invalid-feedback"> {methods.errors.number_of_slides.message} </div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Number of Slides */}
                    <Row>
                        <Col lg="4">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Reading Time</Form.Label>
                                <Form.Control
                                    name="reading_time"
                                    type="number"
                                    placeholder="Enter a number"
                                    isInvalid={methods.errors.reading_time}
                                    ref={methods.register({ required: 'The reading time is required' })}
                                />
                                {methods.errors.reading_time && <div className="invalid-feedback"> {methods.errors.reading_time.message} </div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="success"
                        id="btn-finish-question"
                        onClick={onClickUpdate}
                        disabled={loading}
                        className="btn-fixed fixed-right shadow">
                        {!loading && <i className="fas fa-save icon"></i>}
                        {loading && <MoonLoader color="#8e8e93" size={40} />}
                    </Button>

                </Form>
            </FormProvider>

        </LoadingOverlay>
    )
}

export default MasterSessionUpdateForm;