
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Button from "../buttons/Button";
// Redux
import { useDispatch } from 'react-redux';
import { updateAdvanceGuest, setQuestionnaireGuestPage } from '../../redux/ducks/questionnaire-guest-duck';

const QuestionnaireGuestIntro = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const nextRoute = `/questionnaire-guest/intro/example` ; 

    useEffect(() => {
        // Update Redux
        dispatch(setQuestionnaireGuestPage('intro'))
        // One page 1.4 of advance
        dispatch(updateAdvanceGuest(
            1.4
        ))
    }, [])
    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Welcome to 34 Elements! 👋</h4>
            {/* Description */}
            <p className="multiple-step-description">
                This questionnaire is a first pass into quantifying your fundability.<br />
                It's not a startup contest, it's an assessment to help you understand what to work on.
            </p>
            {/* List */}
            <ul className="questionnaire-instructions">
                <li>Be honest you won't receive money because you score well!</li>
                <li>Answer based upon where your startup is NOW, not what you dream it will become</li>
                <li>If you are not sure you can select the answer, it's probably because you should not.</li>
                <li>Do not be optimistic, be realistic, just facts, you do or you don't, even if it hurts.</li>
            </ul>
            <p className="multiple-step-description-black">
                Let's take an example.
            </p>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={() => { history.push(nextRoute) }}>
                    Go to Example
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </>
    )
}

export default QuestionnaireGuestIntro;