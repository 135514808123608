
import React from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import Container from "react-bootstrap/Container";
import MatrixFundingCalculation from "./MatrixFundingCalculation";
import BoxStartup from "../box/BoxStartup";

interface FundingCalculation {
    setting: any
}

const FundingCalculation = (props: FundingCalculation) => {
    const { setting } = props;
    return (

        <Tab.Container id="funding-calculation-tabs" defaultActiveKey="pre_seed">
            <Container>
                <Row>
                    <Col lg={12} >
                        <Nav variant="pills" className="justify-content-center">
                            <Nav.Item >
                                <Nav.Link eventKey={'pre_seed'}>Pre Seed</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey={'seed'}>Seed</Nav.Link>
                            </Nav.Item>
                            <Nav.Item >
                                <Nav.Link eventKey={'series_a'}>Series A</Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                </Row>
            </Container>
            <Row>
                <Col lg={12} >
                    <Tab.Content className="tab-content-primary tab-content">
                        <Tab.Pane eventKey={'pre_seed'}  >
                            <BoxStartup>
                                <BoxStartup.Body>
                                    <MatrixFundingCalculation
                                        setting={setting}
                                        stageSku={'pre_seed'}
                                    />
                                </BoxStartup.Body>
                            </BoxStartup>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'seed'}  >
                            <BoxStartup>
                                <BoxStartup.Body>
                                    <MatrixFundingCalculation
                                        setting={setting}
                                        stageSku={'seed'} />
                                </BoxStartup.Body>
                            </BoxStartup>
                        </Tab.Pane>
                        <Tab.Pane eventKey={'series_a'}  >
                            <BoxStartup>
                                <BoxStartup.Body>
                                    <MatrixFundingCalculation
                                        setting={setting}
                                        stageSku={'series_a'} />
                                </BoxStartup.Body>
                            </BoxStartup>
                        </Tab.Pane>
                    </Tab.Content>
                </Col>
            </Row>
        </Tab.Container>
    )
}

export default FundingCalculation