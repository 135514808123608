
import React, { useEffect, useCallback, useState } from "react";
// History
import { useHistory } from "react-router-dom";
// Svg
import { ReactSVG } from "react-svg";
// Button
import Button from "../../buttons/Button";
// Item

// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    getQuestionnaireGuestQuestionsPage,
    updateAdvanceGuest,
    updateQuestionsPage,
    setAnswersGuest
} from '../../../redux/ducks/questionnaire-guest-external-duck';
// Form
import { useForm, FormProvider } from "react-hook-form";
import QuestionPaginationItemGuestExternal from "./QuestionPaginationItemGuestExternal";


// Interface
interface QuestionsPaginationProps {
    questions: any,
    questionAdvanceFactor: number
}
const QuestionPaginationGuestExternal = (props: QuestionsPaginationProps) => {
    // Form
    const methods = useForm({
        mode: 'onChange'
    })
    // Redux
    const dispatch = useDispatch();
    const questionsPage = useSelector(getQuestionnaireGuestQuestionsPage)
    // History
    const history = useHistory();
    // Arrow
    const arrowRight = require("../../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../../assets/img/multiple-step/q-arrow-left.svg").default;
    const skipIcon = require("../../../assets/img/multiple-step/skip.svg").default;
    // Pagination
    const [questionsPagination, setQuestionsPagination] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<any>(1);
    // const nextRoute = `/questionnaire-guest/intro/second-questions`;
    const backRoute = `/questionnaire-guest/intro-external`;
    const nextRoute = `/questionnaire-guest/intro-external/done`;
    // Props
    const { questions, questionAdvanceFactor } = props;
    // Fetch Questions
    const fetchQuestions = useCallback(() => {
        setQuestionsPagination(questions)
    }, [setQuestionsPagination, questions])
    // Fetch Current Page
    const fetchCurrentPage = useCallback(() => {
        setCurrentPage(questionsPage)
    }, [setCurrentPage, questionsPage])
    // On Select Option
    const onSelectOption = () => {
        const data = methods.getValues();
        dispatch(setAnswersGuest(data))
    }
    // Next
    const onClickNext = () => {
        const next = currentPage + 1;
        const data = methods.getValues();
        dispatch(setAnswersGuest(data))
        if (next > questionsPagination.length) {
            history.push(nextRoute)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPage(next))
        }

    }
    const onClickSkip = () => {
        const next = currentPage + 1;
        const data = methods.getValues();
        dispatch(setAnswersGuest(data))
        if (next > questionsPagination.length) {
            history.push(nextRoute)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPage(next))
        }

    }
    // Back
    const onClickBack = () => {
        const next = currentPage - 1;
        const data = methods.getValues();
        dispatch(setAnswersGuest(data))
        if (next === 0) {
            history.push(backRoute)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPage(next))
        }

    }
    // Init Questions
    useEffect(() => {
        if (questions) {
            fetchQuestions()
        }
    }, [fetchQuestions, questions])
    // Change Advance
    // One page 1.4 of advance
    useEffect(() => {
        if (questionAdvanceFactor) {
            const advance = currentPage * questionAdvanceFactor + questionAdvanceFactor
            // Update Redux
            dispatch(updateAdvanceGuest(
                advance
            ))
        }
    }, [currentPage, dispatch, questionAdvanceFactor])
    // Fetch questions Page
    useEffect(() => {
        if (questionsPage !== currentPage) {
            fetchCurrentPage()
        }
    }, [questionsPage, currentPage, fetchCurrentPage])

    return (
        <>
            <FormProvider {...methods}>
                {
                    questionsPagination &&
                    questionsPagination[currentPage - 1] &&
                    <QuestionPaginationItemGuestExternal
                        key={questionsPagination[currentPage - 1].id}
                        question={questionsPagination[currentPage - 1]}
                        onSelectOption={onSelectOption}
                    />
                }
            </FormProvider>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-light-violet btn-skip"
                    onClick={onClickSkip}
                >
                    <ReactSVG src={skipIcon} wrapper="span" className="skip-icon icon-left" />
                    Skip
                </Button>
                <Button
                    className="btn-violet"
                    onClick={onClickNext}
                >
                    Next
                    <ReactSVG src={arrowRight} wrapper="span" className="arrow-left icon-right" />
                </Button>
            </div>


        </>
    )
}

export default QuestionPaginationGuestExternal;