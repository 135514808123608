import React from 'react';

interface StrengthItemProps {
    item: any,
    index: number
}

const StrengthItem = (props: StrengthItemProps) => {
    const { item, index } = props;
    return (
        <div className="strength-item" >
            <span className="item-title">{index}.{item.type_title}</span>
            <span className="item-description">{item.type_strength_description}</span>
        </div>
    );
};
export default StrengthItem;