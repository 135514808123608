import React, { useState } from 'react';
// Button
import Button from "../buttons/Button";
// Loading Overlay
import { MoonLoader } from "react-spinners";

interface ButtonFixedProps {
    loading?: boolean,
    icon?: string,
    onClick?: Function
}


const ButtonFixed = (props: ButtonFixedProps) => {

    // Max file size
    const defaultIcon = 'fa-save icon';
    const [icon] = useState(props.icon ? props.icon : defaultIcon);
    // Loading
    const { loading } = props;
    // On Click
    const onClick = () => {
        if (props.onClick) {
            props.onClick();
        }
    }
    return (
        <Button variant="success"
            id="btn-finish-question"
            onClick={onClick}
            disabled={loading}
            className="btn-fixed fixed-right shadow">
            {!loading && <i className={`fas ${icon} icon`}></i>}
            {loading && <MoonLoader color="#8e8e93" size={40} />}
        </Button>
    )
}

export default ButtonFixed;