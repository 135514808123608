/*eslint-disable*/
import React from "react";
import RegisterForm from "../../components/register/RegisterForm";

// Register
const RegisterNoLimitedView = (props) => {
    return (<RegisterForm type="not_limited" title="Register" />)
}

export default RegisterNoLimitedView;
