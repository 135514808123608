import gql from 'graphql-tag'

export const QUESTION_EXTERNAL_FIELDS = gql`
fragment QuestionExternalFields on QuestionExternal {
    id
    title
    type_sku
    type{
        sku
        title
    }
    order
    active
}`



