import React, { useCallback, useEffect, useRef, useState } from 'react';
// D3
import * as d3 from 'd3';
// Hook
import useWindowSize from '../../../hooks/useWindowResize';
// Constants
import { CHART_COLOR } from '../../../constants/charts/chart.constants';
import StartupHelper from '../../../helpers/startup/startup.helper';


interface ProgressBarChartProps {
    data: {
        percentage: number,
    },
    settings?: {
        height?: number,
        barBgColor?: string,
        barStrokeColor?: string

    }

}

const ProgressBarPercentageChart = (props: ProgressBarChartProps) => {
    // Helper
    const startupHelper = new StartupHelper();
    // Hook Window
    const windowSize = useWindowSize();
    // Props
    const { data: dataProps, settings: settingsProps } = props;
    // State
    const defaultSettings = {
        height: 16,
        barBgColor: CHART_COLOR.progressBarBg,
        barStrokeColor: CHART_COLOR.progressBarBorder,
    }
    const [data, setData] = useState<any>(null)
    const [settings, setSettings] = useState<any>(null)
    // Line Height
    const [lineHeight, setLineHeight] = useState<any>(14)
    // const [size, setSize] = useState<any>({ width: 'auto', height: 'auto' })

    // Chart
    const chartEl = useRef<HTMLDivElement>(null);
    const parentEl = useRef<HTMLDivElement>(null);
    // Fetch Data
    const fetchData = useCallback(() => {
        const settings = Object.assign(defaultSettings, {})
        if (settingsProps) {
            if (settingsProps.height) {
                settings.height = settingsProps.height;
            }
            if (settingsProps.barBgColor) {
                settings.barBgColor = settingsProps.barBgColor;
            }
            if (settingsProps.barStrokeColor) {
                settings.barStrokeColor = settingsProps.barStrokeColor;
            }
        }
        setSettings(settings);
        setData(dataProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps, settingsProps, setData, setSettings])

    const setup = (parentEl, chartEl) => {
        // Element width
        const elementSize = parentEl.current.clientWidth;
        // Svg Styles
        const margin = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
        let width = elementSize - margin.left - margin.right;
        let height = settings.height - margin.top - margin.bottom

        // Renove Svg
        d3.select(chartEl.current)
            .select("svg").remove()
        // Create
        const svg = d3
            .select(chartEl.current)
            .append("svg")
            .attr("width", (width + margin.left + margin.right))
            .attr("height", (height + margin.top + margin.bottom))
            .append("g").attr("class", "wrapper")
        // .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");

        return { svg, margin, width, height }
    }

    // Add logic to draw the chart here
    const drawChart = useCallback(() => {
        if (chartEl && chartEl.current && data && data.percentage >= 0) {
            // Percentage
            const isZero = parseInt(data.percentage) === 0;
            // Setup
            const { svg, width, height } = setup(parentEl, chartEl);
            setLineHeight(height)
            // Scale of progress bar
            const scale = d3.scaleLinear().domain([0, 100]).range([0, width - 4]);
            const status = startupHelper.resolvePercentageScoringStyle(data.percentage);
            // Rect Background
            svg.append('rect')
                .attr('class', 'bg-rect')
                .attr('rx', 4)
                .attr('ry', 4)
                .attr('fill', settings.barBgColor)
                .attr('stroke', settings.barStrokeColor)
                .attr('stroke-width', 1)
                .attr('height', settings.height)
                .attr('width', width)
                .attr('x', 0);
            // Height Bar Propgress
            const heightBarProgress = settings.height - 4;
            // Progress
            const progress =
                svg.append('rect')
                    .attr('class', 'progress-rect')
                    .attr('fill', isZero ? 'transparent' : status?.color)
                    .attr('height', heightBarProgress)
                    .attr('width', 0)
                    .attr('rx', 3)
                    .attr('ry', 3)
                    .attr('x', 2)
                    .attr('y', 2);

            progress
                .transition()
                .duration(1000)
                .attr('width', scale(isZero ? 100 : data.percentage) as any);
            // Text
            let textX = (scale(isZero ? 100 : data.percentage) as any / 2) as number;
            let fillText = '#fff';
            if (data.percentage < 6 && !isZero) {
                textX = scale(data.percentage) as number + 15;
                fillText = '#000'
            }
            svg
                .append("text")
                .attr('class', 'progress-text')
                .attr('y', heightBarProgress / 2 + 3)
                .attr('x', textX)
                .attr('dominant-baseline', 'middle')
                .attr('text-anchor', 'middle')
                .style('fill', isZero ? '#000' : fillText)
                .text(data.percentage + '%')
                .style('opacity', 0)
                .transition()
                .delay(1000)
                .duration(600)
                .style('opacity', 1);

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartEl, parentEl, settings, data])

    // On Change Props
    useEffect(() => {
        if (dataProps && dataProps !== data && settings !== settingsProps) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps, settingsProps, fetchData, data]);
    // Resize when call third time (first undefined, second with window value)
    useEffect(() => {
        if (windowSize.width && data && settings) {
            drawChart();
        }
    }, [windowSize, data, settings, drawChart]);


    return (
        <div ref={parentEl} className="startup-progress-percentage-chart">
            <div className="progress-bar-wrapper" style={{ lineHeight: lineHeight + 'px' }}>
                <div ref={chartEl} className="startup-progress-container"></div>
            </div>

        </div>
    )
}

export default ProgressBarPercentageChart;