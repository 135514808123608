
import React, { lazy, Suspense } from 'react';
// Modal
import Modal from 'react-bootstrap/Modal';
import Spinner from 'react-bootstrap/Spinner';
import Button from '../buttons/Button';
import ModalClose from './ModalClose';
// Hooks
import { useHideModal, useModal } from './ModalWrapperHooks';

export const ModalWrapper = () => {
    // Get modal params from redux store
    const { modal } = useModal();
    const { handleOnClose } = useHideModal();
    // Import dynamic component according to component path param
    const CustomComponent = lazy(() => import(`${modal.componentPath}`));
    return (
        <Modal show={modal.show}
            type={modal.type} size={modal.size}
            onHide={handleOnClose}
            enforceFocus={true}
            backdrop={'static'}
        >
            <Modal.Header >
                {modal.title && <Modal.Title>{modal.title}</Modal.Title>}
                {modal.close && <ModalClose onClick={handleOnClose} />}
            </Modal.Header>
            <Modal.Body>
                <Suspense fallback={<Spinner animation="grow" variant="primary" />}>
                    {modal.componentPath ? (
                        <CustomComponent {...modal.componentProps} />
                    ) : (
                            <h5 className="text-dark mb-4">{modal.body}</h5>
                        )}
                </Suspense>
            </Modal.Body>
            {modal.withButtons && (
                <Modal.Footer>
                    {modal.actionButton === 'alert' &&
                        <>
                            <Button                                
                                onClick={handleOnClose}
                                className="btn-violet">
                                Accept
                            </Button>
                        </>
                    }
                </Modal.Footer>
            )}
        </Modal>
    );
};