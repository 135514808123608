
// Questionnaire
import ErrorViewGuest from "./views/questionnaire-guest/ErrorGuestView";
import IntroViewGuest from "./views/questionnaire-guest/IntroGuestView";
import IntroViewGuestExternal from "./views/questionnaire-guest/IntroGuestExternalView";
import ProcessTokenView from "./views/questionnaire-guest/ProcessTokenView";
import ThanksGuest from "./views/questionnaire-guest/ThanksGuestView";
import ProcessTokenExternalView from "./views/questionnaire-guest/ProcessTokenExternalView";
import ProcessTotkenExternalGroupView from "./views/questionnaire-guest/ProcessTokenExternalGroupView";
import ThanksGuestAnonymousView from "./views/questionnaire-guest/ThanksGuestAnonymousView";




const routesQuestionnaire = [    
    {
        path: "/process-token/:token",
        name: "Process Invitation",
        icon: "ni ni-key-25",
        component: ProcessTokenView,
        layout: "/questionnaire-guest",
        key: 'process-token'
    },
    {
        path: "/process-token-external/:token",
        name: "Process Invitation",
        icon: "ni ni-key-25",
        component: ProcessTokenExternalView,
        layout: "/questionnaire-guest",
        key: 'process-token'
    },
    // When Admin Created
    {
        path: "/external-group/:token",
        name: "Process Invitation",
        icon: "ni ni-key-25",
        component: ProcessTotkenExternalGroupView,
        layout: "/questionnaire-guest",
        key: 'process-token'
    },
    {
        path: "/intro",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: IntroViewGuest,
        layout: "/questionnaire-guest",
        key: 'questionnaire'
    },
    {
        path: "/intro-external",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: IntroViewGuestExternal,
        layout: "/questionnaire-guest",
        key: 'questionnaire-external'
    },
    {
        path: "/error",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: ErrorViewGuest,
        layout: "/questionnaire-guest",
        key: 'error'
    },
    {
        path: "/thanks",
        name: "Thanks!",
        icon: "ni ni-key-25",
        component: ThanksGuest,
        layout: "/questionnaire-guest",
        key: 'thanks'
    },
    {
        path: "/thanks-anonymous",
        name: "Thanks!",
        icon: "ni ni-key-25",
        component: ThanksGuestAnonymousView,
        layout: "/questionnaire-guest",
        key: 'thanks'
    },
    
    

   
 

];
export default routesQuestionnaire;
