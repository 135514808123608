import React, { useState, useEffect, useCallback } from "react";
// Core
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// Toastify
import { toast } from "react-toastify";
import { useApolloClient } from "react-apollo";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getSubElement } from "../../redux/ducks/dashboard-duck";
import { getUser } from "../../redux/ducks/user-duck";
import {
  answerUpdateFirstScoring,
  getAnswerLastAnswer,
  getAnswerQuestion,
} from "../../redux/ducks/answer-duck";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
import BoxStartup from "../box/BoxStartup";
import Button from "../buttons/Button";

// Props
interface ModalUpdateFirstScoringProps {
  onClose?: Function;
  onSave?: Function;
}

const UpdateFirstScoring = (props: ModalUpdateFirstScoringProps) => {
  // Dispatch
  const dispatch = useDispatch();
  // Selector
  const user = useSelector(getUser);
  const question = useSelector(getAnswerQuestion);
  const lastAnswer = useSelector(getAnswerLastAnswer);
  const subElement = useSelector(getSubElement);
  // Client
  const client = useApolloClient();
  // Loading
  const [loading, setLoading] = useState(false);
  // Show
  // Chosen
  const [selected, setSelected] = useState(null);
  // Fetch Answer
  const fetchAnswer = useCallback(() => {
    // console.log('lastAnswer', lastAnswer);
    if (lastAnswer && subElement && lastAnswer.answer_items) {
      const item = lastAnswer.answer_items.find(
        (item) => item.question_type_sku === subElement
      );
      if (item) {
        setSelected(item.first_scoring_id);
      } else {
        setSelected(null)
      }
    } else {
      setSelected(null)
    }
  }, [subElement, lastAnswer, setSelected]);
 
  // On Click Radio
  const onClickRadio = (event) => {
    const { target } = event;
    const { value } = target;
    setSelected(value);
  };

  // Save if has last anser
  const saveHasLastAnswer = async () => {
    let answerCreate: any = null;
    setLoading(true);
    try {
      const mapData = lastAnswer.answer_items.map((answerItem) => ({
        question_id: answerItem.question_id,
        question_type_sku: answerItem.question_type_sku,
        first_scoring_id: answerItem.first_scoring_id,
        refined_scoring_ids: answerItem.refined_scoring_ids,
      }))
      const copyLastAnswer = {
        user_id: user.id,
        startup_id: lastAnswer.startup_id,
        items: [...mapData]
      };
      // Search
      const findIndex = copyLastAnswer.items.findIndex(a => a.question_type_sku === subElement);
      // If not exist
      if (findIndex === -1) {
        const newItem: any = {
          question_id: question.id,
          question_type_sku: question.type_sku,
          first_scoring_id: selected,
          refined_scoring_ids: null,
        };
        copyLastAnswer.items.push(newItem);
      } else {
        copyLastAnswer.items[findIndex].first_scoring_id = selected;
      }
      // Mutate
      const response = await client.mutate({
        mutation: CREATE_ANSWER,
        variables: { data: copyLastAnswer },
      });
      answerCreate = response.data.createAnswer || null;
      //console.log(copyLastAnswer);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    return answerCreate;
  }
  // Save if not have anser
  const saveNotLastAnswer = async () => {
    let answerCreate: any = null;
    setLoading(true);
    try {
      const newAnser = {
        user_id: user.id,
        startup_id: user.startup_id,
        items: [] as any,
      };

      const newItem: any = {
        question_id: question.id,
        question_type_sku: question.type_sku,
        first_scoring_id: selected,
        refined_scoring_ids: null,
      };
      newAnser.items.push(newItem);
      // Mutate
      const response = await client.mutate({
        mutation: CREATE_ANSWER,
        variables: { data: newAnser },
      });
      answerCreate = response.data.createAnswer || null;
      //console.log(copyLastAnswer);
    } catch (e) {
      console.log(e);
    } finally {
      setLoading(false);
    }

    return answerCreate;
  }
  // Save Fn
  const saveFn = async () => {
    let answerCreate: any = null;
    if (lastAnswer.answer_items) {
      answerCreate = await saveHasLastAnswer();
    } else {
      answerCreate = await saveNotLastAnswer();
    }
    return answerCreate;
  };

  // Save
  const handleSave = async () => {
    const answerCreate = await saveFn();
    console.log("answerCreate created", answerCreate);
    if (answerCreate && answerCreate.id) {
      if (props.onSave) {
        props.onSave(answerCreate);
      }
      // Message Success
      toast.success("First scoring saved succefully!");
      // Close Modal
      dispatch(answerUpdateFirstScoring(false));
    } else {
      toast.error("Opps...Error ocurred. Try later...");
    }
  };

  // Show
  useEffect(() => {
    fetchAnswer();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <BoxStartup >
      <BoxStartup.Header>
        <span className="title">
          {`First Scoring`}
        </span>
      </BoxStartup.Header>
      <BoxStartup.Body>
        <h5 className="text-dark mb-4">{question ? question.title : ""}</h5>
        {/* Items */}
        {question &&
          question.firstScoring.map((item, index) => (
            <Row key={`answer-first-scoring-${item.id}`}>
              <Col lg="12">
                <Form.Group>
                  <Form.Check
                    custom
                    name={`${question.type.sku}`}
                    type={"radio"}
                    id={`q-item-${item.id}`}
                    label={item.title}
                    value={item.id}
                    checked={selected === item.id}
                    onChange={onClickRadio}
                  />
                </Form.Group>
              </Col>
            </Row>
          ))}
        <Row>
          <Col lg="12">
            <Button
              disabled={loading}
              loading={loading}
              loadingText={"Saving"}
              onClick={handleSave}
              className="btn-violet"
            >
              Save
            </Button>
          </Col>
        </Row>
      </BoxStartup.Body>
    </BoxStartup>
  );
};

export default UpdateFirstScoring;
