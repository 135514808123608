
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Tab from 'react-bootstrap/Tab';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Nav from 'react-bootstrap/Nav';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Client
import { useApolloClient } from "react-apollo";
import FundingCalculation from "../../../components/settings/FundingCalculation";
import { GET_SETTING } from "../../../queries/settings/settings.query";

const SettingIndexView = (props) => {
    // Settings
    const [setting, setSetting] = useState('funding_calculation');
    // Set Key
    const [key, setKey] = useState('funding_calculation');
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        try {
            const resp = await client.query({
                query: GET_SETTING,
                fetchPolicy: 'no-cache'
            })
            const setting = resp.data.getSetting || {};
            setSetting(setting)
        } catch (e) {
            console.log(e)
        } finally {
            // Loading False
            setLoading(false);
        }

    }, [client, setSetting, setLoading]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [])
    // Render
    return (
        <>

            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>
                        Main Funding
                    </Breadcrumb.Item>
                </Breadcrumb>
            </Container>
            <Container fluid>

                <LoadingOverlay active={loading}
                    text={'Loading Settings...'}
                    spinner={
                        <div className="_loading_overlay_spinner">
                            <BounceLoader color={"#2662f0"} />
                        </div>}>

                    <Tab.Container id="tab-question" activeKey={key}
                        onSelect={(k: any) => setKey(k)}
                    >
                        <Row>
                            <Col lg="12">
                                <Container>
                                    <Nav variant="pills" className={'justify-content-center'} id="questions-pills">
                                        <Nav.Item>
                                            <Nav.Link eventKey="funding_calculation">Main Funding calculation</Nav.Link>
                                        </Nav.Item>

                                    </Nav>
                                </Container>
                            </Col>
                            <Col lg="12">
                                <Tab.Content>
                                    <Tab.Pane eventKey="funding_calculation" mountOnEnter={true}>
                                        <Container fluid>
                                            <FundingCalculation setting={setting}/>
                                        </Container>
                                    </Tab.Pane>
                                </Tab.Content>
                            </Col>
                        </Row>
                    </Tab.Container >
                </LoadingOverlay>
            </Container>
        </>
    )
}

export default SettingIndexView;
