
import React, { useCallback, useEffect, useState } from "react";
import Form from 'react-bootstrap/Form'
import { useForm } from "react-hook-form";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import {
    STARTUP_BUSINESS_CATEGORIES,
    STARTUP_BUSINESS_TYPES_1,
    STARTUP_BUSINESS_TYPES_2,
    STARTUP_SITUATION,
    STARTUP_STAGES
} from "../../constants/startup/startup.constants";
import Button from "../buttons/Button";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getQuestionnaireStartup, resetQuestionnaire, updateAdvance, updateStartup } from '../../redux/ducks/questionnaire-duck';
import { getUser, userCreate } from "../../redux/ducks/user-duck";
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_STARTUP } from "../../queries/startup/startups.query";
import { RESET_FIRST_LOGIN } from "../../queries/users/user.query";
// Toast
import { toast } from "react-toastify";
// Helper
import TokenHelper from "../../helpers/security/token.helper";
// Utils
import { setToken } from "../../utils";

const QuestionnaireStartupTypeGuest = (props) => {
    // Dispatch
    const dispatch = useDispatch();
    // Loading
    const [loading, setLoading] = useState(false);
    // Client
    const client = useApolloClient();
    // Startup
    const [startup, setStartup] = useState({
        business_category_sku: null,
        business_type_1_sku: null,
        business_type_2_sku: null,
        situation_sku: null,
        stage_sku: null

    })
    // Store
    const startupStore = useSelector(getQuestionnaireStartup);
    const user = useSelector(getUser);
    // History
    const history = useHistory();
    // Arrow
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Validation
    const { handleSubmit, formState, setValue, register } = useForm({
        mode: 'onChange',
    });
    // Save
    const saveStartup = async (startupVar) => {
        try {
            setLoading(false)
            // Update Startup
            const responseStartup = await client.mutate({
                mutation: UPDATE_STARTUP,
                variables: { data: startupVar }
            })
            const startup = responseStartup.data.updateStartup || {};
            if (!startup.id) {
                throw new Error('Cannot save startup');
            }
            // Update User
            const responseUser = await client.mutate({
                mutation: RESET_FIRST_LOGIN
            })
            const newToken = responseUser.data.resetFirstLogin || null;
            if (!newToken) {
                throw new Error('Cannot save user');
            }
            // Set the new token
            const tokenHelper = new TokenHelper();
            const user = tokenHelper.decode(newToken);
            if (user) {
                console.log('user', user)
                setToken(newToken)
                delete user.password;
                // Set User
                dispatch(userCreate(user));
                // Reset Questionnaire
                dispatch(resetQuestionnaire())
                // Redirect
                history.push(`/dashboard/main`)

            } else {
                throw new Error('Token error');

            }
            toast.success(`Startup successfully saved!`);

        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }
    }
    // Submit
    const onSubmit = (data) => {
        const startupVar = {
            ...data,
            id: user.startup_id,
            active: true,
        }
        saveStartup(startupVar);
    }
    const onClickBack = () => {
        dispatch(updateStartup(startup))
        history.push(`/questionnaire/intro-guest/`)
    }
    // On Change Radio
    const onChangeRadio = (event) => {
        // Target
        const { target } = event;
        // Values
        const { name, value } = target;
        // Set Data
        setStartup(prevState => ({ ...prevState, [name]: value }));
        setValue(name, value);
    }
    // Fetch Data
    const fetchData = useCallback(() => {
        setStartup({
            business_category_sku: startupStore.business_category_sku,
            business_type_1_sku: startupStore.business_type_1_sku,
            business_type_2_sku: startupStore.business_type_2_sku,
            situation_sku: startupStore.situation_sku,
            stage_sku: startupStore.stage_sku

        })
        const keys = Object.keys(startupStore);
        keys.forEach((key) => {
            setValue(key, startupStore[key], { shouldValidate: true })
        })
    }, [setStartup, startupStore, setValue])
    // Init
    useEffect(() => {
        if (startupStore) {
            fetchData();
        }
    }, [startupStore, fetchData])
    // Progress
    useEffect(() => {
        // Update Redux
        dispatch(updateAdvance(
            85
        ))
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <form onSubmit={handleSubmit(onSubmit)}>

            <p className="multiple-step-description mb-32">
                Before getting to our test, please describe your startup through the following questions to help us make a better assessment as it will trigger different options in the test!
            </p>
            {/* Bussiness Category */}
            <div className="radio-group-questions">
                <h4 className="radio-group-title">What is your business category?</h4>
                <div className="radio-group-questions-options in-row">
                    {STARTUP_BUSINESS_CATEGORIES.map((val, index) => (
                        <div className="radio-group-item" key={`custom-business-category-${val.sku}`} >
                            <Form.Check
                                custom
                                ref={register({
                                    required: 'Business category is required'
                                })}
                                name={`business_category_sku`}
                                type={'radio'}
                                id={`business-category-${val.sku}`}
                                label={val.title}
                                value={val.sku}
                                onChange={onChangeRadio}
                                checked={startup.business_category_sku === val.sku}
                            />
                        </div>
                    ))}

                </div>
            </div>
            {/* Bussiness Type */}
            <div className="radio-group-questions">
                <h4 className="radio-group-title">What type of business?</h4>
                <div className="radio-group-questions-options in-row">
                    {STARTUP_BUSINESS_TYPES_1.map((val, index) => (
                        <div className="radio-group-item" key={`custom-business-type-1-${val.sku}`} >
                            <Form.Check
                                custom
                                name={`business_type_1_sku`}
                                ref={register({
                                    required: 'Business Type is required'
                                })}
                                type={'radio'}
                                id={`business-type-1-${val.sku}`}
                                value={val.sku}
                                label={val.title}
                                onChange={onChangeRadio}
                                checked={startup.business_type_1_sku === val.sku}
                            />
                        </div>
                    ))}

                </div>
            </div>
            {/* Bussiness Type */}
            <div className="radio-group-questions">
                <h4 className="radio-group-title">What type of business?</h4>
                <div className="radio-group-questions-options in-row">
                    {STARTUP_BUSINESS_TYPES_2.map((val, index) => (
                        <div className="radio-group-item" key={`custom-business-2-type-${val.sku}`} >

                            <Form.Check
                                custom
                                name={`business_type_2_sku`}
                                ref={register({
                                    required: 'Business Type is required'
                                })}
                                type={'radio'}
                                id={`business-type-2-${val.sku}`}
                                value={val.sku}
                                label={val.title}
                                onChange={onChangeRadio}
                                checked={startup.business_type_2_sku === val.sku}
                            />

                        </div>
                    ))}

                </div>
            </div>
            {/* Stage */}
            <div className="radio-group-questions">
                <h4 className="radio-group-title">What stage?</h4>
                <div className="radio-group-questions-options in-row">
                    {STARTUP_SITUATION.map((val, index) => (
                        <div className="radio-group-item" key={`custom-situation-${val.value}`} >

                            <Form.Check
                                custom
                                name={`situation_sku`}
                                ref={register({
                                    required: 'Stage is required'
                                })}
                                type={'radio'}
                                id={`situation-${val.value}`}
                                value={val.value}
                                label={val.description}
                                onChange={onChangeRadio}
                                checked={startup.situation_sku === val.value}
                            />

                        </div>
                    ))}

                </div>
            </div>
            {/* Stage */}
            <div className="radio-group-questions">
                <h4 className="radio-group-title">What funding stage?</h4>
                <div className="radio-group-questions-options in-row">
                    {STARTUP_STAGES.map((val, index) => (
                        <div className="radio-group-item" key={`custom-stage-${val.value}`} >
                            <Form.Check
                                custom
                                name={`stage_sku`}
                                ref={register({
                                    required: 'Funding is required'
                                })}
                                type={'radio'}
                                id={`stage-${val.value}`}
                                label={val.description}
                                value={val.value}
                                onChange={onChangeRadio}
                                checked={startup.stage_sku === val.value}
                            />
                        </div>
                    ))}

                </div>
            </div>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                    disabled={loading}
                >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    type="submit"
                    disabled={!formState.isValid || loading}>
                    Finish
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </form>
    )
}

export default QuestionnaireStartupTypeGuest;