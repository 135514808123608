
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { useForm } from "react-hook-form";
//
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_CATEGORY } from '../../queries/categories/categories.query';
// Loader
import { MoonLoader } from "react-spinners";
// Constants
import { LEVEL_OF_CRITICALITY } from "../../constants/startup/startup.constants";
// Props
interface CategoryEditFormProps {
    category: any
}
const CategoryEditForm = (props: CategoryEditFormProps) => {
    // Keys numbers 
    const keysNumbers = [
        'order',
        'weight_excitement_pre_seed',
        'weight_confidence_pre_seed',
        'weight_excitement_seed',
        'weight_confidence_seed',
        'weight_excitement_series_a',
        'weight_confidence_series_a',

    ]
    // Client
    const client = useApolloClient();
    // Form
    const { errors, register, trigger, setValue, getValues } = useForm({
        mode: 'onChange'
    });
    // Loading
    const [loading, setLoading] = useState(false);
    // Props
    const {
        category
    } = props;


    // Fetch Data
    const fetchData = useCallback(() => {
        setValue('id', category.id);
        setValue('title', category.title);
        setValue('description', category.description);
        setValue('sku', category.sku);
        setValue('order', category.order);

        setValue('weight_excitement_pre_seed', category.weight_excitement_pre_seed);
        setValue('weight_confidence_pre_seed', category.weight_confidence_pre_seed);
        setValue('weight_excitement_seed', category.weight_excitement_seed);
        setValue('weight_confidence_seed', category.weight_confidence_seed);
        setValue('weight_excitement_series_a', category.weight_excitement_series_a);
        setValue('weight_confidence_series_a', category.weight_confidence_series_a);



        setValue('priority_level_pre_seed_sku', category.priority_level_pre_seed_sku);
        setValue('priority_level_seed_sku', category.priority_level_seed_sku);
        setValue('priority_level_series_a_sku', category.priority_level_series_a_sku);
    }, [
        category,
        setValue
    ])

    // Init
    useEffect(() => {
        if (category && category.id) {
            fetchData()
        }
    }, [category, fetchData])
    // Update Question
    const onClickUpdate = async () => {
        await trigger()
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateCategory()
        }
    }
    const updateCategory = async () => {
        try {
            setLoading(true)
            const category = getValues();

            // String to number question
            for (let key of keysNumbers) {
                category[key] = parseInt(category[key]);
            }
            console.log('formatted question', category)
            const resp = await client.mutate({
                mutation: UPDATE_CATEGORY,
                variables: { data: category }
            })
            const updated = resp.data.updateCategory || {};
            console.log(updated);
            if (updated.id) {
                toast.success(`Question successfully updated`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }


    }

    return (
        <LoadingOverlay active={loading}
            text={'Update category...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>

            <Form noValidate name={'edit-category'}>
                {/* Sku */}
                <Row >
                    <Col lg="4">
                        <Form.Group >
                            <Form.Label>SKU</Form.Label>
                            <Form.Control
                                readOnly={true}
                                name="sku"
                                type="text"
                                placeholder="Enter a Sku"
                                isInvalid={errors.title}
                                ref={register({
                                    required: 'Sku is required',
                                })}
                            />
                            {errors.sku &&
                                <div className="invalid-feedback"> {errors.sku.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                {/* Title */}
                <Row >
                    <Form.Control name="id" type="hidden" ref={register({
                        required: 'Question id required',
                    })} />
                    <Col lg="9">
                        <Form.Group >
                            <Form.Label>Title</Form.Label>
                            <Form.Control
                                name="title"
                                type="text"
                                placeholder="Enter a Title"
                                isInvalid={errors.title}
                                ref={register({
                                    required: 'Title is required',
                                })}
                            />
                            {errors.title &&
                                <div className="invalid-feedback"> {errors.title.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>
                {/* Description */}
                <Row >
                    <Col lg="9">
                        <Form.Group >
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                name="description"
                                type="text"
                                placeholder="Enter a Description"
                                isInvalid={errors.description}
                                as={'textarea'}
                                rows={3}
                                ref={register({
                                    required: 'Description is required',
                                })}
                            />
                            {errors.description &&
                                <div className="invalid-feedback"> {errors.description.message} </div>}
                        </Form.Group>
                    </Col>
                </Row>

                {/* Order  */}
                <Row >
                    <Col lg="4">
                        <Form.Label>Order</Form.Label>
                        <Form.Control
                            name="order"
                            type="number"
                            placeholder="Enter a number"
                            isInvalid={errors.order}
                            ref={register({
                                required: 'Weight is required',
                                min: {
                                    value: 0,
                                    message: 'The value must be greater than or equal to 0'
                                }
                            })}
                        />
                        {errors.order &&
                            <div className="invalid-feedback"> {errors.order.message} </div>}
                    </Col>
                </Row>
                {/*  Level of criticality */}
                <Row>
                    <Col lg="9">
                        <hr />
                        <h3> Level of criticality</h3>
                    </Col>
                </Row>
                <Row>
                    <Col lg="9">
                        <Row>
                            {/* Pre Seed */}
                            <Col lg="4">
                                <Form.Group >
                                    <Form.Label>Pre Seed</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="priority_level_pre_seed_sku"
                                        isInvalid={errors.priority_level_pre_seed_sku}
                                        className="custom-select"
                                        defaultValue=""
                                        ref={register({
                                            required: 'Priority Level is required',
                                        })}
                                    >
                                        <option disabled value="">Select...</option>
                                        {LEVEL_OF_CRITICALITY.map((priority, index) => (
                                            <option value={priority.sku} key={index}>{priority.title}</option>
                                        ))}
                                    </Form.Control>
                                    {/* Error */}
                                    {errors.priority_level_pre_seed_sku && <div className="invalid-feedback"> {errors.priority_level_pre_seed_sku.message} </div>}
                                </Form.Group>
                            </Col>
                            {/* Seed */}
                            <Col lg="4">
                                <Form.Group >
                                    <Form.Label>Seed</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="priority_level_seed_sku"
                                        isInvalid={errors.priority_level_seed_sku}
                                        className="custom-select"
                                        defaultValue=""
                                        ref={register({
                                            required: 'Priority Level is required',
                                        })}
                                    >
                                        <option disabled value="">Select...</option>
                                        {LEVEL_OF_CRITICALITY.map((priority, index) => (
                                            <option value={priority.sku} key={index}>{priority.title}</option>
                                        ))}
                                    </Form.Control>
                                    {/* Error */}
                                    {errors.priority_level_seed_sku && <div className="invalid-feedback"> {errors.priority_level_seed_sku.message} </div>}
                                </Form.Group>
                            </Col>
                            {/* Series A */}
                            <Col lg="4">
                                <Form.Group >
                                    <Form.Label>Seed</Form.Label>
                                    <Form.Control
                                        as="select"
                                        name="priority_level_series_a_sku"
                                        isInvalid={errors.priority_level_series_a_sku}
                                        className="custom-select"
                                        defaultValue=""
                                        ref={register({
                                            required: 'Priority Level is required',
                                        })}
                                    >
                                        <option disabled value="">Select...</option>
                                        {LEVEL_OF_CRITICALITY.map((priority, index) => (
                                            <option value={priority.sku} key={index}>{priority.title}</option>
                                        ))}
                                    </Form.Control>
                                    {/* Error */}
                                    {errors.priority_level_series_a_sku && <div className="invalid-feedback"> {errors.priority_level_series_a_sku.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                {/* Pre Seed */}
                <Row>
                    <Col lg="9">
                        <hr />
                        <h3>Weight Pre Seed</h3>
                    </Col>
                </Row>
                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Excitement</Form.Label>
                                    <Form.Control
                                        name="weight_excitement_pre_seed"
                                        type="number"
                                        placeholder="Enter a number"
                                        isInvalid={errors.weight_excitement_pre_seed}
                                        ref={register({
                                            required: 'Weight is required',
                                            min: {
                                                value: 0,
                                                message: 'The value must be greater than or equal to 0'
                                            }
                                        })}
                                    />
                                    {errors.weight_excitement_pre_seed &&
                                        <div className="invalid-feedback"> {errors.weight_excitement_pre_seed.message} </div>}
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Confidence</Form.Label>
                                    <Form.Control
                                        name="weight_confidence_pre_seed"
                                        type="number"
                                        placeholder="Enter a number"
                                        isInvalid={errors.weight_confidence_pre_seed}
                                        ref={register({
                                            required: 'Weight is required',
                                            min: {
                                                value: 0,
                                                message: 'The value must be greater than or equal to 0'
                                            }
                                        })}
                                    />
                                    {errors.weight_confidence_pre_seed &&
                                        <div className="invalid-feedback"> {errors.weight_confidence_pre_seed.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Seed */}
                <Row>
                    <Col lg="9">
                        <hr />
                        <h3>Weight Seed</h3>
                    </Col>
                </Row>
                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Excitement</Form.Label>
                                    <Form.Control
                                        name="weight_excitement_seed"
                                        type="number"
                                        placeholder="Enter a number"
                                        isInvalid={errors.weight_excitement_seed}
                                        ref={register({
                                            required: 'Weight is required',
                                            min: {
                                                value: 0,
                                                message: 'The value must be greater than or equal to 0'
                                            }
                                        })}
                                    />
                                    {errors.weight_excitement_seed &&
                                        <div className="invalid-feedback"> {errors.weight_excitement_seed.message} </div>}
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Confidence</Form.Label>
                                    <Form.Control
                                        name="weight_confidence_seed"
                                        type="number"
                                        placeholder="Enter a number"
                                        isInvalid={errors.weight_confidence_seed}
                                        ref={register({
                                            required: 'Weight is required',
                                            min: {
                                                value: 0,
                                                message: 'The value must be greater than or equal to 0'
                                            }
                                        })}
                                    />
                                    {errors.weight_confidence_seed &&
                                        <div className="invalid-feedback"> {errors.weight_confidence_seed.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Series A */}
                <Row>
                    <Col lg="9">
                        <hr />
                        <h3>Weight Series A</h3>
                    </Col>
                </Row>
                <Row>
                    <Col lg="9">
                        <Row>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Excitement</Form.Label>
                                    <Form.Control
                                        name="weight_excitement_series_a"
                                        type="number"
                                        placeholder="Enter a number"
                                        isInvalid={errors.weight_excitement_series_a}
                                        ref={register({
                                            required: 'Weight is required',
                                            min: {
                                                value: 0,
                                                message: 'The value must be greater than or equal to 0'
                                            }
                                        })}
                                    />
                                    {errors.weight_excitement_series_a &&
                                        <div className="invalid-feedback"> {errors.weight_excitement_series_a.message} </div>}
                                </Form.Group>
                            </Col>
                            <Col lg="6">
                                <Form.Group >
                                    <Form.Label>Confidence</Form.Label>
                                    <Form.Control
                                        name="weight_confidence_series_a"
                                        type="number"
                                        placeholder="Enter a number"
                                        isInvalid={errors.weight_confidence_series_a}
                                        ref={register({
                                            required: 'Weight is required',
                                            min: {
                                                value: 0,
                                                message: 'The value must be greater than or equal to 0'
                                            }
                                        })}
                                    />
                                    {errors.weight_confidence_series_a &&
                                        <div className="invalid-feedback"> {errors.weight_confidence_series_a.message} </div>}
                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                <Button variant="success"
                    id="btn-finish-question"
                    onClick={onClickUpdate}
                    disabled={loading}
                    className="btn-fixed fixed-right shadow">
                    {!loading && <i className="fas fa-save icon"></i>}
                    {loading && <MoonLoader color="#8e8e93" size={40} />}
                </Button>

            </Form>
        </LoadingOverlay>
    )
}

export default CategoryEditForm;