
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Group
import GroupUsersForm from '../../../components/groups/GroupUsersForm';
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import { LIST_QUESTION_TYPES } from "../../../queries/question-types/question-types.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
import BesPracticeAddForm from "../../../components/best-practice/BesPracticeAddForm";
// Last Location
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";
import GroupForm from "../../../components/groups/GroupForm";
import { GET_GROUP_BY_ID } from "../../../queries/group/group.query";
import { useHistory, useParams } from "react-router-dom";
import { toast } from "react-toastify";

const GroupManageUsersView = (props) => {
    // Group Id
    const { id } = useParams<any>();
    // Group
    const [group, setGroup] = useState<any>(null);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/groups");
    // History
    const history = useHistory();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);

        // Group 
        const groupResponse = await client.query({
            query: GET_GROUP_BY_ID,
            variables: { id: id },
            fetchPolicy: 'no-cache'
        })
        const group = groupResponse.data.getGroupById || {};
        if (!group.id) {
            toast.error(`Group doesn't exist`);
            history.push('/admin/groups')
        }
        setGroup(group);
        // Loading
        setLoading(false);
    }, [
        client,
        setGroup,
        setLoading,
    ]);

    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/groups${search}`}>Groups</Breadcrumb.Item>
                    <Breadcrumb.Item active>{`Manage Users of ${group ? group.name : ''}`}</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">{`Manage Users of ${group ? group.name : ''}`}</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body className={'overflow-visible'}>
                        {!loading && <GroupUsersForm group={group}/>}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default GroupManageUsersView;