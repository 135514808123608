
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Table
import ExternalInvitationTable from "../../../components/external-invitation/ExternalInvitationTable";
// Apollo
import { useApolloClient } from "react-apollo";
import {
    DELETE_GUEST_GROUP,
    LIST_GUEST_GROUP_ADMIN
} from '../../../queries/guest-group/guest-group.query'
// Router
import { useHistory, useParams } from "react-router-dom";
import { useLastLocation } from "react-router-last-location";
// Sweet alter
import Swal from '../../../components/sweet-altert/sweet-alert'
// Toastify
import { toast } from 'react-toastify';
// Startup
import StartupAccordion from "../../../components/startup/StartupAccordion";



const ListExternalInvitationView = (props) => {
    // Startup Id
    const { startup_id } = useParams<any>();
    // Router
    const history = useHistory();
    // Startup
    const [startup, setStartup] = useState<any>(null);
    // Groups
    const [groups, setGroups] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location
    const lastLocation = useLastLocation();
    // Last Location search
    const [search, setSearch] = useState('');
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_GUEST_GROUP_ADMIN,
            variables: { id: startup_id },
            fetchPolicy: 'no-cache'
        })
        const list = response.data.listGuestGroupAdmin || [];
        const startup = response.data.getStartupById || {};
        setStartup(startup);
        setGroups(list)
        setLoading(false);
    }, [client, setGroups, setStartup, startup_id]);
    // Call Fetch Data
    useEffect(() => {
        if (startup_id) {
            fetchData();
        }
    }, [startup_id])
    // View
    const onClickViewItem = (group) => {

        history.push(`/admin/startups/external-invitation/${group.id}`)
    }

    const copyToClipboard = (textToCopy) => {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res(true) : rej(false);
                textArea.remove();
            });
        }
    }

    // Copy Url
    const onClickCopyItem = async (group) => {

        copyToClipboard(group.link)
            .then(() => toast.info('Url copy to clipboard'))
            .catch(() => console.log('error'));
    }
    // Delete
    const onClickDeleteItem = (group) => {
        Swal.fire({
            title: `Are you sure to delete?`,
            html: `
                <span>You are trying deleted a external link: <span class="font-weight-medium">${group.name}</span></span><br/>
                <span class="text-danger font-weight-medium">All related information will be deleted</span>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_GUEST_GROUP,
                    variables: { id: group.id }
                }).then(resp => {
                    toast.success('External link deleted succefully');
                    fetchData();
                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted External link. Try later');
                });
            }
        })
    }

    // Last Location
    const fetchQueryParams = useCallback(() => {
        if (lastLocation?.pathname === "/admin/startups") {
            if (lastLocation.search) {
                setSearch(lastLocation.search);
            }
        }
    }, [lastLocation]);
    useEffect(() => {
        if (lastLocation) {
            fetchQueryParams();
        }
    }, [lastLocation])
    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/startups${search}`}>Startups</Breadcrumb.Item>
                    <Breadcrumb.Item active>List External Link</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">External Links of startup </span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {startup && <StartupAccordion  startup={startup}/>}
                        <br />
                        <br />
                        <ExternalInvitationTable
                            groups={groups}
                            loading={loading}
                            onClickViewItem={onClickViewItem}
                            onClickDeleteItem={onClickDeleteItem}
                            onClickCopyItem={onClickCopyItem}

                        />
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default ListExternalInvitationView;