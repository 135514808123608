import jwt from 'jwt-simple';

export default class TokenHelper {

    secret = 'startup2020!';
    

    encode(payload) {
        return jwt.encode(payload, this.secret);
    }

    decode(token) {
        return jwt.decode(token, this.secret);
    }

    getType(token) {
        const tokenContent = this.decode(token);
        const type = tokenContent.type[0];
        return type;
    }
}