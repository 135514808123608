import gql from 'graphql-tag'
import { ANSWER_ITEMS_FIELDS } from '../answer-items/answer-item.fragment'

export const ANSWER_FIELDS = gql`
${ANSWER_ITEMS_FIELDS}
fragment AnswerFields on Answer {
    id
    user_id   
    guest_id 
    startup_id
    has_answered_all
    answer_items{
        ...AnswerItemFields
    }    
}`



