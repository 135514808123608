

export const ANSWER_FETCH_QUESTION = 'startup/answer/ANSWER_FETCH_QUESTION';
export const ANSWER_FETCH_LAST_ANSWER = 'startup/answer/ANSWER_FETCH_LAST_ANSWER';
export const ANSWER_UPDATE_FIRST_SCORING = 'startup/answer/ANSWER_UPDATE_FIRST_SCORING';
export const ANSWER_UPDATE_REFINED_SCORING = 'startup/answer/ANSWER_UPDATE_REFINED_SCORING';

export function answerFetchQuestion(payload) {
    return {
        type: ANSWER_FETCH_QUESTION,
        payload
    }
}

export function answerFetchLastAnswer(payload) {
    return {
        type: ANSWER_FETCH_LAST_ANSWER,
        payload
    }
}

export function answerUpdateFirstScoring(payload) {
    return {
        type: ANSWER_UPDATE_FIRST_SCORING,
        payload
    }
}

export function answerUpdateRefinedScoring(payload) {
    return {
        type: ANSWER_UPDATE_REFINED_SCORING,
        payload
    }
}


const initialState = {
    currentQuestion: null,
    lastAnswer: null,
    firstScoring: false,
    refinedScoring: false,
};

export default function reducer(state = initialState, action: any) {
    // console.log('state', state)
    // console.log('action', action)
    switch (action.type) {
        case ANSWER_FETCH_QUESTION:
            return Object.assign(
                {},
                state,
                {
                    currentQuestion: action.payload
                }
            );
        case ANSWER_FETCH_LAST_ANSWER:
            return Object.assign(
                {},
                state,
                {
                    lastAnswer: action.payload
                }
            );
        case ANSWER_UPDATE_FIRST_SCORING:
            return Object.assign(
                {},
                state,
                {
                    firstScoring: action.payload
                }
            );
        case ANSWER_UPDATE_REFINED_SCORING:
            return Object.assign(
                {},
                state,
                {
                    refinedScoring: action.payload
                }
            );
        default:
            return state;
    }
}

export const getAnswerQuestion = (state) => state.answer.currentQuestion;
export const getAnswerLastAnswer = (state) => state.answer.lastAnswer;
export const getAnswerFirstScoring = (state) => state.answer.firstScoring;
export const getAnswerRefinedScoring = (state) => state.answer.refinedScoring;
