
import React from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import Page from "../components/page/Page";
import AdminFooter from "../components/footers/AdminFooter";
// Routes
import routes from "../routes";
import AdminNavbar from "../components/navbars/AdminNavbar";

const Admin = (props: any) => {
  document.body.classList.remove('with-sidebar');
  window.scrollTo(0, 0)
  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.layout === "/admin") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={props => (
              <Page {...props}
                component={prop.component}
                title={'34 Elements | ' + prop.name}
                name={prop.name}
                // onSetBrandText={onSetBrandText}
              />
            )}

          />
        );
      } else {
        return null;
      }
    });
  };


  return (
    <React.Fragment>
      <div className="main-content-admin">
        {/* Navbar */}
        <AdminNavbar />
        <div className="main-container-wrapper">
          {/* Main Container */}
          <div className="main-container">            
              <Switch>
                  {getRoutes(routes)}
                  <Redirect from="*" to="/admin/startups" />
              </Switch>            
              
          </div>
          {/* Footer */}
          <AdminFooter />
        </div>
      </div>
    </React.Fragment>
  );

}

export default Admin;
