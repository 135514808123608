
import React, { useState, useEffect, useCallback } from 'react'
// Bootstrap
import Modal from 'react-bootstrap/Modal'
// Modal
import ModalClose from '../modals/ModalClose';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getModalListGuestType, showModalListGuests } from '../../redux/ducks/guest-duck';
// Guests
import ListGuests from './ListGuests';
// Apollo
import { useApolloClient } from 'react-apollo';
import { DELETE_GUEST } from '../../queries/guests/guests.query'
import { DELETE_GUEST_INVITATION } from '../../queries/guest-invitation/guest-invitation.query'
// SweeAlert
import Swal from '../sweet-altert/sweet-alert';
// Toast
import { toast } from 'react-toastify';

// Props
interface ModalAddChallengeProps {
    onClose?: Function,
    onSave?: Function,
    guests: any,
    groupAdminInivtations?: any,
    loading: boolean,
    loadGuests?: Function
}

const ModalListGuests = (props: ModalAddChallengeProps) => {
    // Client
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // Props
    const { guests, loading, loadGuests, groupAdminInivtations } = props;
    // Selector
    const modalGuestType = useSelector(getModalListGuestType);
    // Show
    const [show, setShow] = useState(false);
    // Close
    const handleClose = () => {
        dispatch(showModalListGuests(null))
    }
    // Delete
    const onClickDelete = (guest) => {
        Swal.fire({
            title: `<span>Are you sure to delete?</span>`,
            html: `<span>You are trying to delete a invitation</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                const response = await client.mutate({
                    mutation: DELETE_GUEST,
                    variables: { id: guest.id }
                })
                const guestDeleted = response.data.deleteGuest || {};
                if (guestDeleted.id) {
                    toast.success('Invitation deleted succefully.');
                    if (loadGuests) {
                        loadGuests()
                    }
                } else {
                    toast.error('Opps...Error ocurred. Try later...');
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }

    const onClickDeleteInvitation = (invitation) => {
        Swal.fire({
            title: `<span>Are you sure to delete?</span>`,
            html: `<span>You are trying to delete a invitation</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                const response = await client.mutate({
                    mutation: DELETE_GUEST_INVITATION,
                    variables: { id: invitation.id }
                })
                const guestDeleted = response.data.deleteGuestInvitation || {};
                if (guestDeleted.id) {
                    toast.success('Invitation deleted succefully.');
                    if (loadGuests) {
                        loadGuests()
                    }
                } else {
                    toast.error('Opps...Error ocurred. Try later...');
                }
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }
    // Fetch Show
    const fetchShow = useCallback(async () => {
        if (modalGuestType) {
            setShow(true);
        } else {
            setShow(false)
        }
    }, [modalGuestType])
    // Show
    useEffect(() => {
        fetchShow()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [modalGuestType])

    return (
        <>
            <Modal
                id="modal-list-participants"
                show={show}
                onHide={handleClose}
                enforceFocus={true}
                backdrop={'static'}>
                <Modal.Header>
                    <Modal.Title>List of participants</Modal.Title>
                    <ModalClose onClick={handleClose} />
                </Modal.Header>
                <Modal.Body>
                    <ListGuests
                        guests={guests}
                        groupAdminInivtations={groupAdminInivtations}
                        loading={loading}
                        onClickDelete={onClickDelete}
                        onClickDeleteInvitation={onClickDeleteInvitation}
                    />
                </Modal.Body>
            </Modal>
        </>
    )
}

export default ModalListGuests;