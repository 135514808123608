
import React, { useEffect, useCallback, useState } from "react";
// History
import { useHistory } from "react-router-dom";
// Svg
import { ReactSVG } from "react-svg";
// Button
import Button from "../../buttons/Button";
// Item
import QuestionPaginationItemFirstScoring from "./QuestionPaginationItemFirstScoring";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    getQuestionnaireGuestQuestionsPageFirstScoring,
    updateAdvanceGuest,
    updateQuestionsPageFirstScoringGuest,
    setAnswersFirstScoringGuest
} from '../../../redux/ducks/questionnaire-guest-duck';
// Form
import { useForm, FormProvider } from "react-hook-form";


// Interface
interface QuestionsPaginationProps {
    questions: any,
    onClickNextSection: Function,
    loading: boolean
}
const QuestionsPaginationFirstScoring = (props: QuestionsPaginationProps) => {
    // Form
    const methods = useForm({
        mode: 'onChange'
    })
    // Redux
    const dispatch = useDispatch();
    const questionsPage = useSelector(getQuestionnaireGuestQuestionsPageFirstScoring)
    // History
    const history = useHistory();
    // Arrow
    const arrowRight = require("../../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Pagination
    const [questionsPagination, setQuestionsPagination] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<any>(1);
    // const nextRoute = `/questionnaire-guest/intro/second-questions`;
    const backRoute = `/questionnaire-guest/intro/example`;
    // Props
    const { questions, loading } = props;
    // Fetch Questions
    const fetchQuestions = useCallback(() => {
        setQuestionsPagination(questions)
    }, [setQuestionsPagination, questions])
    // Fetch Current Page
    const fetchCurrentPage = useCallback(() => {
        setCurrentPage(questionsPage)
    }, [setCurrentPage, questionsPage])
    // Next
    const onClickNext = () => {
        const next = currentPage + 1;
        const data = methods.getValues();
        console.log('data', data.firstScoring)
        dispatch(setAnswersFirstScoringGuest(data.firstScoring))
        if (next > questionsPagination.length) {
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPageFirstScoringGuest(next))
        }

    }
    // Back
    const onClickBack= () => {
        const next = currentPage - 1;
        const data = methods.getValues();
        console.log('data', data.firstScoring)
        dispatch(setAnswersFirstScoringGuest(data.firstScoring))
        if (next === 0) {
            history.push(backRoute)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPageFirstScoringGuest(next))
        }

    }
    // On click next section
    const onClickNextSection = ()=>{
        // Save current questions
        props.onClickNextSection()
    }
    // Init Questions
    useEffect(() => {
        if (questions) {
            fetchQuestions()
        }
    }, [fetchQuestions, questions])
    // Change Advance
    // One page 1.4 of advance
    useEffect(() => {
        const advance = currentPage * 1.4 + (1.4 * 2) 
        // Update Redux
        dispatch(updateAdvanceGuest(
            advance
        ))
    }, [currentPage])
    // Fetch questions Page
    useEffect(() => {
        if (questionsPage !== currentPage) {
            fetchCurrentPage()
        }
    }, [questionsPage, currentPage, fetchCurrentPage])

    return (
        <>
            <FormProvider {...methods}>
                {
                    questionsPagination &&
                    questionsPagination[currentPage - 1] &&
                    <QuestionPaginationItemFirstScoring
                        key={questionsPagination[currentPage - 1].id}
                        question={questionsPagination[currentPage - 1]}
                        onSelectOption={onClickNext}
                    />
                }
            </FormProvider>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                    disabled={loading}
                    >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                {currentPage  === questions.length &&
                <Button
                    className="btn-violet"
                    onClick={onClickNextSection}
                    disabled={loading || !methods.formState.isValid}
                    >
                    Next Section 
                    <ReactSVG src={arrowRight} wrapper="span" className="arrow-left icon-right" />
                </Button> }
            </div>


        </>
    )
}

export default QuestionsPaginationFirstScoring;