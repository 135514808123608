
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// External
import CategoryBoxExternalRating from '../../../components/external-rating/CategoryBoxExternalRating';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
// Startup
import StartupAccordion from "../../../components/startup/StartupAccordion";
// Router
import { useHistory, useParams } from "react-router-dom";
import { useQueryParams } from "../../../hooks/useQueryParams";
// Apollo
import { useApolloClient } from "@apollo/react-hooks";
import { UPDATE_GUEST_GROUP, GET_GUEST_GROUP_BY_ID } from "../../../queries/guest-group/guest-group.query";
import { LIST_CATEGORIES_AND_TYPES } from "../../../queries/categories/categories.query";
// Form
import { useForm } from "react-hook-form";
// Toast
import { toast } from "react-toastify";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";




interface FormGroupName {
    group: any;
}
const ExternalInvitationView = (props) => {
    // Startup Id
    const { group_id } = useParams<any>();
    const queryParams = useQueryParams();
    // Loading
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    // Startup
    const [group, setGroup] = useState<any>(null)
    const [startup, setStartup] = useState<any>(null)
    // Categories
    const [categories, setCategories] = useState<any>(null);
    // Selected
    const [typesSelected, setTypesSelected] = useState<Array<string>>([]);
    // History
    const history = useHistory();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/startups/external-invitation/list/");
    // Validation
    const {
        handleSubmit,
        register,
        errors,
        formState,
        setValue,
    } = useForm<FormGroupName>({
        mode: "onChange",
        defaultValues: {},
    });

    const { isDirty, isValid } = formState;
    // Client
    const client = useApolloClient();
    const fetchData = useCallback(async () => {
        // Startup
        try {
            const response = await client.query({
                query: GET_GUEST_GROUP_BY_ID,
                variables: { id: group_id },
                fetchPolicy: 'no-cache',
            });
            const group = response.data.getGuestGroupById || {}
            setGroup(group)
            setStartup(group.startup);
            // Categories
            const responseCategories = await client.query({
                query: LIST_CATEGORIES_AND_TYPES,
                fetchPolicy: 'no-cache',
            });
            const categories = responseCategories.data.listCategories || [];
            setCategories(categories);
            // Set Values
            setValue('group', group.name, { shouldValidate: true });
            // Types
            setTypesSelected(group.types)
            // Loading
            setLoading(false)
        } catch (e) {
            toast.error("The External invitation doesn't exist")
            if (queryParams.get('create')) {
                history.push('/admin/startups')
            } else {
                history.push(`/admin/startups/external-invitation/list/${startup ? startup.id : ''}`)
            }
        }
    }, [group_id])
    // Call Fetch Data
    useEffect(() => {
        if (group_id) {
            fetchData();
        }
    }, [group_id, fetchData])

    // Selected Types
    const onSelectItem = (type) => {
        const indexOf = typesSelected.indexOf(type)
        if (indexOf === -1) {
            typesSelected.push(type);
        } else {
            typesSelected.splice(indexOf, 1)
        }
        setTypesSelected([...typesSelected]);
    }
    // Render Group Info
    const onClickCopyToClipboard = () => {
        copyToClipboard(group.link)
            .then(() => toast.info('Url copy to clipboard'))
            .catch(() => console.log('error'));
    }
    const copyToClipboard = (textToCopy) => {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res(true) : rej(false);
                textArea.remove();
            });
        }
    }
    const renderGroupInfo = () => {
        return (
            <Row className="mb-4">
                <Col className="d-flex align-items-center col-lg-auto col-md-12 mb-2 mb-lg-0">
                    <p className="text-dark mb-0">{group.link}</p>
                </Col>
                <Col lg="4">
                    <Button
                        variant=""
                        className={"btn-violet"}
                        type="button"
                        onClick={onClickCopyToClipboard}
                    >
                        Copy Link
                    </Button>
                </Col>
            </Row>)
    }

    // Create Group
    const createGroup = async (data) => {
        setCreating(true);
        try {
            const guestGroupData = {
                id: group.id,
                startup_id: startup.id,
                name: data.group,
                types: typesSelected,
                admin_created: true
            }
            const responseCreate = await client.mutate({
                mutation: UPDATE_GUEST_GROUP,
                variables: { data: guestGroupData }
            })
            if (!responseCreate.data.id) {
                toast.success(`Link updated succefully!`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setCreating(false);
        }
    }

    
    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="1"></Col>
                    <Col sm="10">
                        {/* Box */}
                        <LoadingOverlay
                            active={loading || creating}
                            text={creating ? 'Updating...' : 'Loading...'}
                            spinner={
                                <div className="_loading_overlay_spinner">
                                    <BounceLoader color={"#2662f0"} />
                                </div>}>
                            <Breadcrumb>
                                {/* {queryParams.get('create') && <Breadcrumb.Item href="/admin/startups">Startups</Breadcrumb.Item>}
                                {!queryParams.get('create') && <Breadcrumb.Item href={`/admin/startups/external-invitation/list/${startup ? startup.id : ''}${search}`}>External Links</Breadcrumb.Item>} */}
                                <Breadcrumb.Item href={`/admin/startups/external-invitation/list/${startup ? startup.id : ''}${search}`}>External Links</Breadcrumb.Item>
                                <Breadcrumb.Item active>View External Invitation Link</Breadcrumb.Item>
                            </Breadcrumb>
                            {/* Box */}
                            <BoxStartup>
                                <BoxStartup.Header>
                                    <span className="title">External Invitation</span>
                                </BoxStartup.Header>
                                <BoxStartup.Body>
                                    {group && renderGroupInfo()}
                                    {startup && <StartupAccordion startup={startup} />}
                                </BoxStartup.Body>
                                {/* Categories*/}
                                <div className="category-list-external-rating">
                                    <Row className="m-0">
                                        {categories &&
                                            categories.length > 0 &&
                                            categories.map((category, index) => (
                                                <Col key={category.sku} className="p-0 category-box-external-wrapper">
                                                    <CategoryBoxExternalRating
                                                        category={category}
                                                        onSelectItem={onSelectItem}
                                                        typesSelected={typesSelected}
                                                        disabled={!startup}
                                                    />
                                                </Col>
                                            ))}
                                    </Row>
                                </div>
                                <BoxStartup.Body>

                                    <Form
                                        name="form-create-group"
                                        onSubmit={handleSubmit(createGroup)}
                                        noValidate
                                        className="mt-4"
                                    >
                                        <Form.Row>
                                            {/* <Col  className="d-flex align-items-center col-lg-auto col-md-12 mb-2 mb-lg-0">
                                                    <span className="title">Url Invitation</span>
                                                </Col> */}
                                            <Col lg="4" className="mb-4 mb-lg-0">
                                                <Form.Control
                                                    name="group"
                                                    type="text"
                                                    autoComplete="off"
                                                    isInvalid={errors.group ? true : false}
                                                    ref={register({
                                                        required: "Group name is required",
                                                    })}
                                                    placeholder="Group Name"
                                                />
                                                {errors.group && (
                                                    <div className="invalid-feedback">{errors.group.message}</div>
                                                )}
                                            </Col>
                                            <Col className="">
                                                <Button
                                                    variant=""
                                                    className={"btn-violet"}
                                                    type="submit"
                                                    disabled={creating || !isValid || typesSelected.length === 0}>
                                                    Update
                                                </Button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </BoxStartup.Body>
                            </BoxStartup>
                        </LoadingOverlay>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default ExternalInvitationView;
