import { compose, createStore, combineReducers } from 'redux';
import persistState from 'redux-sessionstorage'
import TokenHelper from '../../helpers/security/token.helper';
import { getToken, isAdmin, setToken } from '../../utils';
import reducers from './index'
import { userCreate } from './user-duck';
import client from '../../api/client';
import { GENERATE_TOKEN_USER } from '../../queries/users/user.query';

const createPersistentStore = compose(
  persistState(['questionnaire', 'users', 'questionnaireGuest', 'questionnaireGuestExternal','questionnaireLead'])
)(createStore)
// New Token

const generateNewToken = async (token) => {
  const tokenHelper = new TokenHelper();
   const user = tokenHelper.decode(token)
   // console.log('user====>' , user)
  if (user && !isAdmin(user.type)) {
    // Generate new token if change permissions
    const newTokenReponse = await client.mutate({
      mutation: GENERATE_TOKEN_USER,
      variables: { id: user.id }
    })
    const newToken = newTokenReponse.data.generateTokenUser || '';
    const newUser = tokenHelper.decode(newToken)
    setToken(newToken);
    // Dispatch
    store.dispatch(userCreate(newUser));
  }
}

// Store
const store = createPersistentStore(combineReducers(reducers), {})
// // Check User Logged In
 const token = getToken()
if (token) {
  try {
     generateNewToken(token);
  } catch (e) {
    console.log('invalid token', e);
  }
}


export default store;