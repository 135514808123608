
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import CategoryEditWeights from "../../../components/categories/CategoryEditWeights";


const CategoryWeightView = (props) => {
    // Categories
    const [categories, setCategories] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const responseCat = await client.query({
            query: LIST_CATEGORIES,
            fetchPolicy: 'no-cache'
        })
        const categories = responseCat.data.listCategories || [];
        setCategories(categories);
        setLoading(false);
    }, [client, setCategories]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])



    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>Main Funding Metrics</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Main Funding Metrics</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <Row>
                            <Col md="12">
                                <CategoryEditWeights
                                    categories={categories}
                                />
                            </Col>
                        </Row>
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default CategoryWeightView;
