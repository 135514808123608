
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_GROUPS } from "../../../queries/group/group.query";
import { GET_MASTER_SESSION_BY_ID } from "../../../queries/master-session/master-session.query";
import { DELETE_MASTER_SESSION_HISTORY } from "../../../queries/master-session-history/master-session.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Last Location
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";
// Update Form
import MasterSessionUpdateForm from "../../../components/master-session/MasterSessionUpdateForm";
// Router
import { useParams } from "react-router";
// History
import MasterSessionHistory from "../../../components/master-session/MasterSessionHistory";

// Swaler
import Swal from '../../../components/sweet-altert/sweet-alert';

// Toast
import { toast } from "react-toastify";

const MasterSessionEditView = (props) => {
    // Id
    const { id } = useParams<any>();
    // Master Session
    const [masterSession, setMasterSession] = useState([]);
    // History
    const [history, setHistory] = useState([]);
    // Groups
    const [groups, setGroups] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/master-sessions");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        // Set Master Session
        const responseMasterSession = await client.query({
            query: GET_MASTER_SESSION_BY_ID,
            variables: { id },
            fetchPolicy: 'no-cache'
        })
        const masterSession = responseMasterSession.data.getMasterSessionById || {};
        setMasterSession(masterSession);
        // Set History
        setHistory(masterSession.history || []);
        // Search Groups
        const responseGroup = await client.query({
            query: LIST_GROUPS,
            fetchPolicy: 'no-cache'
        })
        const groups = responseGroup.data.listGroups || [];
        setGroups(groups);
        // Loading
        setLoading(false);
    }, [
        id,
        client,
        setMasterSession,
        setGroups,
        setLoading,
    ]);
    // Delete History
    const onDeleteHistory = (history) => {
        Swal.fire({
            title: `<span>Are you sure to delete?</span>`,
            html: `<span>You are trying to delete a history of Master Session</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_MASTER_SESSION_HISTORY,
                    variables: { id: history.id }
                }).then(resp => {
                    toast.success('Master Session History deleted succefully');
                    fetchData();
                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted a Best Practice. Try later');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }
    // View History
    const onClickViewItem = (history) => {
        const newWindow = window.open(history.document.url, '_blank')
        if (newWindow) newWindow.opener = null
    }
    // Call Fetch Data
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])
    const onUpdated = (masterSession) => {
        setMasterSession(masterSession);
        setHistory(masterSession.history);
    }
    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/master-sessions${search}`}>Master Sessions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Master Session</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">Edit Master Session</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <MasterSessionUpdateForm masterSession={masterSession} groups={groups} onUpdated={onUpdated} />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
                <br />
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">History</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <MasterSessionHistory history={history} loading={loading} onClickDeleteItem={onDeleteHistory} onClickViewItem={onClickViewItem} />
                    </BoxStartup.Body>
                </BoxStartup>


            </Container>
        </>
    )
}

export default MasterSessionEditView;