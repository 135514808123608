import React, { useState } from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Svg
import { ReactSVG } from "react-svg";
// Locked
import LockedOverlay from '../locked/LockedOverlay';
// MyChallengeListItem
import MyChallengeListItem from '../challenges/MyChallengeListItem'
// Props
interface TrackItemProps {
    track: any,
    ids: any,
    challengeTemplates
}

const TrackItem = (props: TrackItemProps) => {
    // Props
    const { track, challengeTemplates, ids } = props;
    // States
    const [collapsed, setCollapsed] = useState(true)
    // Filter
    const challengeTemplatesFilter = challengeTemplates.filter(t => !ids.includes(t.id))
    // Icons
    const arrow = require("../../assets/img/challenges/arrow-right.svg").default;
    const iconStatusSrc = require(`../../assets/img/challenges/icon_proposed.svg`).default
    // Toggle
    const toggleCollapsed = (e) => {
        if (!track.locked) {
            e.preventDefault();
            setCollapsed(!collapsed)
        }

    }
    const renderCollapse = () => {
        return (
            <div className={`track-item-accordion ${collapsed ? 'collapse-hidden' : ''}`}>
                { challengeTemplatesFilter
                    .map((template) => (
                        <MyChallengeListItem key={template.id} challenge={template} template={true} />
                    ))
                }
            </div>
        )
    }
    return (
        <>
            {challengeTemplatesFilter && challengeTemplatesFilter.length > 0 &&
                <>
                    <div className={`track-item ${!collapsed ? 'collapse-show' : ''}`}>
                        <a href="" className="collapse-btn" onClick={toggleCollapsed}>
                            <ReactSVG src={arrow} wrapper="span" className="arrow-right" />
                        </a>
                        <div className="track-item-content">
                            <Row>
                                {/* Main Data */}
                                <Col md={7} className="d-flex align-items-center">
                                    <div className="track-item-information">
                                        <span className="track-order">
                                            TRACK #{track.order}
                                        </span>
                                        <span className="track-title">
                                            {track.title}
                                        </span>
                                    </div>
                                </Col>
                                <Col md={5} className="d-flex align-items-center justify-content-md-end mt-3 mt-md-0">
                                    <span className={`badge-challenge badge-challenge-status proposed`}>
                                        <ReactSVG src={iconStatusSrc} wrapper="span" className="icon-status " />Proposed
                            </span>
                                </Col>
                            </Row>
                        </div>
                        {track.locked && <LockedOverlay />}
                    </div>
                </>}
            {!track.locked && renderCollapse()}
        </>
    );
};
export default TrackItem;