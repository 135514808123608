
import gql from 'graphql-tag'
import { ANSWER_ITEMS_FIELDS } from '../answer-items/answer-item.fragment'
import { STARTUP_FIELDS } from '../startup/startup.fragment'
import { USER_FIELDS } from '../users/user.fragment'
import { ANSWER_FIELDS } from './answer.fragment'

export const CREATE_ANSWER = gql`
${ANSWER_FIELDS}
${ANSWER_ITEMS_FIELDS}
${USER_FIELDS}
${STARTUP_FIELDS}
mutation createAnswer($data: AnswerInput! ) {
    createAnswer(data: $data) {
      ...AnswerFields  
      user{
        ...UserFields
      }
      startup{
        ...StartupFields
      }
    }
}
`

export const GET_LAST_ANSWER_DASHBOARD = gql`
${ANSWER_FIELDS}
${ANSWER_ITEMS_FIELDS}
${USER_FIELDS}
${STARTUP_FIELDS}
query getLastAnswerDashboard{
    getLastAnswerDashboard{
      ...AnswerFields  
      user{
        ...UserFields
      }
      startup{
        ...StartupFields
      }
    }
}
`