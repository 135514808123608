
import React, { useState, useEffect, useCallback } from "react";
// Bootstrap
import Form from 'react-bootstrap/Form'
import { useFormContext } from "react-hook-form";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    getQuestionnaireAnswers, setAnswers
} from '../../../redux/ducks/questionnaire-duck';
interface QuestionPaginationItemRefinedProps {
    question: any
}
const QuestionPaginationItemRefined = (props: QuestionPaginationItemRefinedProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Answers
    const answersStore = useSelector(getQuestionnaireAnswers);
    // Form
    const { register, setValue, getValues } = useFormContext();
    // Props
    const { question } = props;
    // Item
    const [selected, setSelected] = useState<any>([])

    const onChangeRadio = (event) => {
        // Target
        const { target } = event;
        // Values
        const { name, value } = target;
        const copyArray = [...selected];
        if (copyArray.indexOf(value) === -1) {
            copyArray.push(value)
        }else{
            copyArray.splice(copyArray.indexOf(value),1)
        }
        console.log('copyArray',copyArray)
        // Set Data
        setSelected([...copyArray]);
        setValue(name, [...copyArray], { shouldValidate: true })
        // setTimeout(() => {
        //     onSelectOption()
        // }, 250)
         const data = getValues();
        console.log('data', data)
        dispatch(setAnswers({data}))

    }

    // Callback
    const fetchData = useCallback(() => {
        const item = answersStore[question.type.sku];
        setSelected(item);
        setValue(`${question.type.sku}`, item, { shouldValidate: true });
    }, [setSelected, question, answersStore])

    // Init
    useEffect(() => {
        if (question && answersStore[question.type.sku]) {
            fetchData()
        }
    }, [fetchData, question])


    return (
        <>
            <div className="radio-group-questions">
                <h4 className="radio-group-title">{question.title}</h4>

                <div className="radio-group-questions-options">
                    {question.refinedScoring &&
                        question.refinedScoring.length > 0 &&
                        question.refinedScoring.map((item, index) => (
                            <div className="radio-group-item" key={`custom-iten-${item.id}`} >
                                <Form.Check
                                    custom
                                    name={`${question.type.sku}`}
                                    ref={register({
                                        required: 'Must choice a option'
                                    })}
                                    type={'checkbox'}
                                    id={`q-item-${item.id}`}
                                    label={item.title}
                                    value={item.id}
                                    onChange={onChangeRadio}
                                    checked={selected.indexOf(item.id) >= 0}
                                />
                            </div>
                        ))}
                </div>
            </div>
        </>
    )
}

export default QuestionPaginationItemRefined;