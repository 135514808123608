import React  from 'react';


interface PriorizationItemProps {
    item: any,
    hideImprove?: boolean
}

const PriorizationItem = (props: PriorizationItemProps) => {
    // Props
    const { item,hideImprove } = props;
    const urlSubElement = '/dashboard/main/sub-element/'

    return (
      <div className="priorization-item-block">
          <div className="priorization-item-title-content">
              <span className="item-title">{item.type_title}</span>
              {!hideImprove && <a href={`${urlSubElement}${item.type_sku}`} 
                className="btn btn-sm btn-outline-violet font-weight-medium">
                  Improve
              </a>}
          </div>
          <div className="priorization-item-description-content">
              <p>{item.type_priorization_description}</p>
          </div>
      </div>
    );
};
export default PriorizationItem;