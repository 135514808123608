import gql from 'graphql-tag'
import { STARTUP_FIELDS } from '../startup/startup.fragment';
import { USER_FIELDS } from './user.fragment';

// Get user logged in
export const GET_USER_DATA = gql`
  ${USER_FIELDS}
  ${STARTUP_FIELDS}
    query userData{
        userData {
          ...UserFields
          startup{
            ...StartupFields
          }
        }
    }
`;
// Login
export const LOGIN = gql`
    mutation login($email: String!, $password: String!){
        login(email: $email, password: $password)
    }
`;
export const RESET_FIRST_LOGIN = gql`
    mutation resetFirstLogin{
      resetFirstLogin
    }
`;

// Recover Password
export const RECOVER_PASSWORD = gql`
    mutation recoverPassword($email: String!){
      recoverPassword(email: $email)
    }
`;
// Verify token recover
export const RESTORE_PASSWORD_TOKEN = gql`
  ${USER_FIELDS}
    query restorePasswordToken($token: String!){
      restorePasswordToken(token: $token){
        ...UserFields
      }
    }
`;
// Restore Password
export const RESTORE_PASSWORD = gql`
    ${USER_FIELDS}
    mutation restorePassword($data: RegisterInput!){
      restorePassword(data: $data){
        ...UserFields
      }
    }
`;
// Register
export const REGISTER = gql`
    ${USER_FIELDS}
   mutation register($data:RegisterInput!){
    register(data: $data) {
      ...UserFields
    }
  }
`;

export const CONFIRM_ACCOUNT = gql`
  ${USER_FIELDS}
    mutation confirmAccount($token: String!){
      confirmAccount(token: $token){
        ...UserFields
      }
    }
`;

export const CREATE_USER = gql`
    ${USER_FIELDS}
    mutation createUser($data: RegisterInput! ) {
      createUser (data: $data) {
          ...UserFields
        }
    }
`;
export const UPDATE_PROFILE = gql`
    ${USER_FIELDS}
    mutation updateProfile($data: RegisterInput! ) {
        updateProfile (data: $data) {
          ...UserFields
        }
    }
`;

export const UPDATE_PASSWORD = gql`
  mutation updatePasswordUser($data: ResetPasswordInput!){
    updatePasswordUser(data:$data) {
      id
    }
  }
`;

export const DELETE_USER = gql`
  mutation deleteUser($id: String!){
    deleteUser(id: $id) {
      id
    }
  }
`;
export const ACTIVATE_USER = gql`
  mutation activateUser($id: String!){
    activateUser(id: $id) {
      id
    }
  }
`;


export const FIND_ALL_USERS = gql`
${USER_FIELDS}
${STARTUP_FIELDS}
query findAllUsers(
  $type: String,
  $primary_account: Boolean
  $active: Boolean
  ){
  findAllUsers(
    type: $type,
    primary_account: $primary_account,
    active: $active
  ){
    ...UserFields
    startup{
      ...StartupFields
      group{
        name
      }
    }
  }
}
`;

export const FIND_USERS_BY_QUERY = gql`
${USER_FIELDS}
query findUserByQuery($query:String){
  findUserByQuery(
    query: $query,
    
  ){
    ...UserFields
    startup{
      id
      name
      type
      group_id
      group{
        name
      }
    }
  }
}
`;

export const FIND_USER_BY_ID = gql`
${USER_FIELDS}
${STARTUP_FIELDS}
query findUserById($id: String!){
  findUserById(id: $id){
    ...UserFields
    startup{
      ...StartupFields
    }
  }
}
`;

export const DELETE_PROFILE_PHOTO = gql`
mutation deleteProfilePhoto($id: String!){
  deleteProfilePhoto(id: $id)
}
`

export const CREATE_USER_AND_STARTUP = gql`
    ${USER_FIELDS}
    ${STARTUP_FIELDS}
    mutation createUserAndStartup($user: RegisterInput!,$startup: StartupInput! ) {
      createUserAndStartup(user: $user, startup: $startup) {
          ...UserFields
          startup{
            ...StartupFields
          }
        }
    }
`
export const UPDATE_USER_AND_STARTUP = gql`
    ${USER_FIELDS}
    ${STARTUP_FIELDS}
    mutation updateUserAndStartup($user: RegisterInput!,$startup: StartupInput! ) {
      updateUserAndStartup(user: $user, startup: $startup) {
          ...UserFields
          startup{
            ...StartupFields
          }
        }
    }
`
export const GENERATE_TOKEN_USER = gql`
    mutation generateTokenUser($id: String! ) {
      generateTokenUser(id: $id)        
    }
`

export const CONTACT_USER = gql`
    mutation contactUser($message: String! ) {
      contactUser(message: $message)        
    }
`
