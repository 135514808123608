
/*eslint-disable*/
import React from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// SVG
import { ReactSVG } from "react-svg";
// Box
import BoxStartup from "../../components/box/BoxStartup";
// Redux
import { useDispatch } from "react-redux";
import { showModalLetsTalk } from "../../redux/ducks/lest-talks-duck";


const BoxStuckBanner = (props) => {
    // Dispatch 
    const dispatch = useDispatch();
    // Icon
    const telIconSrc = require("../../assets/img/tel-icon-big.svg").default;
    const onClickShowModal = () => {
        dispatch(showModalLetsTalk())
    }
    // Render
    return (
        <BoxStartup className={'h-auto'} id={'box-stuck-banner'}>
            <BoxStartup.Body >
                <ReactSVG src={require("../../assets/img/tel-huge.svg").default} wrapper="span" className="tel-huge-bg" />
                <Row>
                    <Col lg={7} className="d-flex align-items-center">
                        <p>Stuck ? Need Coaching ?</p>
                    </Col>
                    <Col lg={5} className={'d-flex justify-content-lg-end mt-2 mt-lg-0'}>

                        <span className={'btn btn-light-violet'} onClick={onClickShowModal}>
                            <ReactSVG src={telIconSrc} wrapper="span" className="icon icon-left tel-icon" />
                            <span>Let’s Talk</span>
                        </span>
                    </Col>
                </Row>

            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxStuckBanner;
