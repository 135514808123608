import React, { useCallback, useEffect, useRef, useState } from 'react';
// D3
import * as d3 from 'd3';
import d3Tip from 'd3-tip';
// Hook
import useWindowSize from '../../../hooks/useWindowResize';
// UUID
import { v4 as uuid_v4 } from "uuid";
// Constants
import { CHART_COLOR } from '../../../constants/charts/chart.constants';
// Helper
import StartupHelper from '../../../helpers/startup/startup.helper';


interface NestedPieChartProps {
    data: any,
    settings?: {
        arcWidth: number
    }

}

const NestedPieChart = (props: NestedPieChartProps) => {
    // Helper
    const startupHelper = new StartupHelper();
    // Conf
    const tau = 2 * Math.PI; // http://tauday.com/tau-manifesto
    // Hook Window
    const windowSize = useWindowSize();
    // Props
    const { data: dataProps, settings: settingsProps } = props;
    // State
    const defaultSettings = {
        arcWidth: 25,
        border: 2
    }
    const [data, setData] = useState<any>(null)
    const [settings, setSettings] = useState<any>(null)
    // Size
    const [size, setSize] = useState<any>({ width: 'auto', height: 'auto' })
    // Tooltip
    const [tooltip, setToltip] = useState<any>(null)
    // Chart
    const chartEl = useRef<HTMLDivElement>(null);
    const parentEl = useRef<HTMLDivElement>(null);
    // Fetch Data
    const createTooltip = useCallback(() => {
        const tip = d3Tip()
            .attr('class', 'd3-tip-simple')
            .offset([-10, 0])
            // .direction('ne')
            .html((d) => {
                return "<span>" + d.label + "</span>";
            })
        console.log(typeof tip)
        setToltip({
            tip: tip
        } as any);
    }, [setToltip, d3Tip])
    // Fetch Data
    const fetchData = useCallback(() => {
        const settings = Object.assign(defaultSettings, {})
        if (settingsProps) {
            if (settingsProps.arcWidth) {
                settings.arcWidth = settingsProps.arcWidth;
            }
        }
        setSettings(settings);
        setData(dataProps);
    }, [dataProps, settingsProps, setData, setSettings])
    // Arc Tween Animation
    const arcTween = (newAngle, arc) => {
        return (d) => {
            const interpolate = d3.interpolate(0, newAngle);
            return (t) => {
                d.endAngle = interpolate(t);
                return arc(d);
            };
        };
    }
    const setup = (parentEl, chartEl) => {
        // Element width
        const elementSize = parentEl.current.clientWidth;
        // Svg Styles
        const margin = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
        let width = Math.min(elementSize, 500) - margin.left - margin.right;
        let height = Math.min(elementSize, 500) - margin.top - margin.bottom
        // const radius = Math.min(width, height) / 2;
        const totalWidth = data.length * ((settings.arcWidth + 2) * 2)
        let arcWidth = settings.arcWidth
        if (totalWidth < width) {
            width = totalWidth;
            height = totalWidth;
        } else if (totalWidth > width) {
            arcWidth = ((width / data.length) / 2  ) - 2          
        }


        // Renove Svg
        d3.select(chartEl.current).select("svg").remove()
        // Create
        const svg = d3
            .select(chartEl.current)
            .append("svg")
            .attr("width", (width + margin.left + margin.right))
            .attr("height", (height + margin.top + margin.bottom))
            .append("g").attr("class", "wrapper")
            .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");

        return { svg, margin, width, height,arcWidth }
    }
    // Build Donut
    const createDonut = (svg, item, i, id, color, arcWidth) => {
        const status = startupHelper.resolvePercentageScoringStyle(item.percentage);
        // Donut grou[]
        const donutGroup = svg
            .append("g")
            .attr("class", "ring");
        // Create Arc
        const innerRadius = 10 + arcWidth * i;
        const outerRadius = arcWidth * (i + 1);
        const currentArc: any = d3
            .arc()
            .innerRadius(innerRadius)
            .outerRadius(outerRadius)
            .cornerRadius(2)
            .startAngle(0);
        const arcBorderOuter: any = d3
            .arc()
            .innerRadius(outerRadius)
            .outerRadius(outerRadius + settings.border)
            .startAngle(0);
        const arcBorderInner: any = d3
            .arc()
            .innerRadius(innerRadius - settings.border )
            .outerRadius(innerRadius )
            .startAngle(0);
        // Bg
        donutGroup
            .append("path")
            .attr("class", "donut-arc-background")
            .datum({ endAngle: tau })
            .style("fill", CHART_COLOR.arcGrey)
            .style('stroke-width', 2)
            .style('stroke', 'transparent')
            .attr("d", currentArc)
            .on("mouseover", (event: any) => {
                const target = event.currentTarget;
                const parent = target.closest(".ring");
                d3.select(parent).classed("mouseover", true)
                tooltip.tip
                    .show(item, target);
            })
            .on("mouseout", (event: any) => {
                const target = event.currentTarget;
                const parent = target.closest(".ring");
                d3.select(parent).classed("mouseover", false)
                tooltip.tip.hide(item, target);

            });
        const fillDonutArc = status.color ? status.color : color(i.toString());
        const foreground = donutGroup.append('path')
            .datum({ endAngle: 0 })
            .attr("class", "donut-arc")
            .attr("id", `donut-arc-${id}-${i}`)
            .attr("index", `${i}`)
            .style('fill', fillDonutArc)
            .style('stroke-width', 2)
            .style('stroke', 'transparent')
            .attr('d', currentArc)
            .on("mouseover", (event: any) => {
                const target = event.currentTarget;
                const parent = target.closest(".ring");
                const arc = parent.querySelector('.donut-arc-background')
                d3.select(parent).classed("mouseover", true)
                tooltip.tip.show(item, arc);
            })
            .on("mouseout", (event: any) => {
                const target = event.currentTarget;
                const parent = target.closest(".ring");
                d3.select(parent).classed("mouseover", false)
                // const parent = target.closest(".ring");
                //const arc = parent.querySelector('.donut-arc-background')
                // d3.select(arc).style('stroke', 'transparent');
                tooltip.tip.hide()
            });
        donutGroup
            .append("path")
            .attr("class", "border inner-border")
            .datum({ endAngle: tau })
            .attr("fill", "transparent")
            .attr("d", arcBorderOuter);
        donutGroup
            .append("path")
            .attr("class", "border outer-border")
            .datum({ endAngle: tau })
            .attr("fill", "transparent")
            .attr("d", arcBorderInner);


        foreground.transition()
            .duration(750)
            .attrTween("d", arcTween((item.percentage / 100) * tau, currentArc));
    }

    // Add logic to draw the chart here
    const drawChart = useCallback(() => {
        if (chartEl && chartEl.current && data.length > 0) {
            const id = uuid_v4();
            // Setup
            const { svg, width, margin, height, arcWidth } = setup(parentEl, chartEl);
            setSize({ width: `${width + margin.left + margin.right}px`, height: `${height + margin.bottom + margin.top}px` })
            svg.call(tooltip.tip);
            // d3.v4
            const color = d3.scaleOrdinal(d3.schemeCategory10);
            // Each
            const xClone = Object.assign([], data);
            const reverseArray = xClone.reverse();
            for (let i = 0; i < reverseArray.length; i++) {
                const item = reverseArray[i];
                createDonut(svg, item, i, id, color, arcWidth);
            }
        }
    }, [chartEl, parentEl, settings, data, tooltip, setSize])
    // On Change Props
    useEffect(() => {
        createTooltip()
    }, []);
    // On Change Props
    useEffect(() => {
        if (dataProps && dataProps !== data && settings !== settingsProps) {
            fetchData()
        }
    }, [dataProps, settingsProps, fetchData, data]);
    // Resize when call third time (first undefined, second with window value)
    useEffect(() => {
        if (windowSize.width && data && settings && tooltip) {
            drawChart();
        }
    }, [windowSize, data, settings, tooltip, drawChart]);


    return (
        <div ref={parentEl} className="startup-nested-pie-chart">
            <div ref={chartEl} className="startup-nested-pie-chart-container" style={{ width: size.width, height: size.height }}></div>
        </div>
    )
}

export default NestedPieChart;