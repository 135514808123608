
import React, { useCallback, useEffect, useState } from 'react';
// Moment
import Moment from 'react-moment';
// Chart
import ProgressBarPercentageChart from '../charts/progress/ProgressBarPercentageChart';
// lodash
// eslint-disable-next-line @typescript-eslint/no-unused-vars
import * as _ from "lodash";

interface ChallengeItemProps {
    challenge: any,
    collapsed: boolean,
}

const ChallengeItemCollapse = (props: ChallengeItemProps) => {    
    
    // Challenge
    const [challenge, setChallenge] = useState<any>(null)
    // Collapsed
    const [collapsed, setCollapsed] = useState<any>(true)
    // Percentage
    const [percentage, setPercentage] = useState({ percentage: 0 })
 
    // Challenge
    const fetchChallenge = useCallback(() => {
        setChallenge(props.challenge)
        if (props.challenge.metric_type_sku === 'progress') {
            if(percentage.percentage !== props.challenge.progress ){
                setPercentage({ percentage: props.challenge.progress })
            }            
        }
    }, [props.challenge, setChallenge, setPercentage, percentage]);
    useEffect(() => {
        if (props.challenge) {
            fetchChallenge();
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.challenge])
    // Collapsed
    const fetchCollapsed = useCallback(() => {
        setCollapsed(props.collapsed)
    }, [props.collapsed, setCollapsed]);
    useEffect(() => {
        fetchCollapsed();
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.collapsed])

    const renderTestProgress = () => {
        return (
            <div className="test-progress-content">
                <div className="test-progress-content-title font-weight-medium">Progress</div>
                <div className="test-progress-content-metric">
                    <div className="test-progress-content-metric-wrapper">
                        {/* Pogress */}
                        {challenge.metric_type_sku === 'progress' && <ProgressBarPercentageChart data={percentage} />}
                        {/* KPI */}
                        {challenge.metric_type_sku === 'kpi' &&
                            <div className="kpi-metric-content">
                                <span className="kpi-metric-content-title">Metric:<span className={'font-weight-medium text-violet'}>{challenge.kpi_metric}</span></span>
                                <span className="kpi-metric-content-title">Target:<span className={'font-weight-medium text-violet'}>{challenge.kpi_metric_target}</span></span>
                                <span className="kpi-metric-content-title">Current:<span className={'font-weight-medium text-violet'}>{challenge.kpi_metric_current}</span></span>
                            </div>
                        }
                    </div>
                </div>
                { challenge && <div className="test-progress-content-due-date">
                    <span className="due-date">
                        Due Date:<span className="font-weight-bold text-violet"><Moment format="DD/MM/YYYY">{challenge.due_date}</Moment></span>
                    </span>
                   
                </div>}
            </div>
        )
    }
    return (
        <>
            <div className={`challenge-item-accordion ${collapsed ? 'collapse-hidden' : ''}`}>
                {challenge && <div className="table-cell">
                    {/* <p className="test-description">
                        <span className="font-weight-medium">Test:&nbsp;</span>{challenge?.test_title}
                    </p> */}

                    {renderTestProgress()}

                </div>}
            </div>

        </>

    );
};
export default ChallengeItemCollapse;