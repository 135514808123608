
/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Nav from 'react-bootstrap/Nav';
import Tab from 'react-bootstrap/Tab';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// React form
import { FormProvider, useForm } from "react-hook-form";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_AND_CATEGORIES } from '../../queries/questions/questions.query'
// Questions
import FirstScoring from "../../components/questions/FirstScoring";
import RefinedScoring from "../../components/questions/RefinedScoring";
import CreateStartupAndUser from "../../components/startup/CreateStartupAndUser";
import FinishQuestionnaireButton from "../../components/questions/FinishQuestionnaireButton";
// Toastify
import { toast } from 'react-toastify';
// Router
import { useParams, useHistory } from "react-router-dom";





const AnswerView = () => {
    // Routes
    const { user_id } = useParams<any>();
    const history = useHistory()
    // Questions
    const [questions, setQuestions] = useState<any>([]);
    // Categories
    const [categories, setCategories] = useState<any>([]);
    // User
    const [user, setUser] = useState<any>(null);
    // Last Answer
    const [answer, setAnswer] = useState<any>(null);
    // Tabs
    const [key, setKey] = useState('startup');
    // Global Totals
    const [totalTeam, setTotalTeam] = useState<any>();
    // Loading
    const [loading, setLoading] = useState(false);
    // Apollo Clinet
    const client = useApolloClient();
    // Form
    const defaultValueTotal = {
        preSeed: 0,
        seed: 0,
        seriesA: 0,
        excitement: {
            preSeed: 0,
            seed: 0,
            seriesA: 0,
        },
        confidence: {
            preSeed: 0,
            seed: 0,
            seriesA: 0,
        }
    }

    const methods = useForm({
        mode: 'onChange',
        defaultValues: {
            firstScoring: {
                team: 0,
                purpose: 0,
                market: 0,
                scale: 0,
                product: 0,
                analytics: 0,
                finance: 0,
            },
            refinedScoring: {
                team: 0,
                purpose: 0,
                market: 0,
                scale: 0,
                product: 0,
                analytics: 0,
                finance: 0,
            },
            user: {},
            totals: {
                team: defaultValueTotal,
                purpose: defaultValueTotal,
                market: defaultValueTotal,
                scale: defaultValueTotal,
                product: defaultValueTotal,
                analytics: defaultValueTotal,
                finance: defaultValueTotal
            }
        }
    });
    // Watch Categories

    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        setKey('first-scoring')
        const response = await client.query({
            query: LIST_QUESTIONS_AND_CATEGORIES,
            fetchPolicy: 'no-cache',
            variables: { startup_id: user.startup_id }
        });
        console.log('response', response);
        // Categories
        const categories = response.data.listCategories || [];
        setCategories(categories)
        // Questions
        const allQuestions = response.data.listQuestions || [];
        // All questions
        setQuestions(allQuestions);
        // Last Answer
        const lastAnswer = response.data.getLastAnswer || null;
        console.log('lasAnswer', lastAnswer);
        setAnswer(lastAnswer);
        // Loading
        setLoading(false);
        window.scrollTo(0, 0)
    }, [client,
        user,
        setLoading,
        setKey,
        setQuestions,
        setCategories,
        setAnswer
    ]);

    // On Mount
    useEffect(() => {
        if (user_id && user && user.id) {
            console.log('Start answers...')
            fetchData();
        }
    }, [user_id, user])

    // On Create User
    const onCreateUser = (user) => {
        history.push(`/questionnaire/answer/${user.id}`)
    }
    const onChangeUser = (user) => {
        if (user) {
            console.log('user', user)
            methods.register({ name: 'user' })
            methods.setValue('user', user)
            setUser(user);
        } else {
            history.push(`/questionnaire/answer/`)
            toast.error(`User doesn't exist`);
            setUser(user);
        }
    }

    // Render
    return (
        <LoadingOverlay active={loading}
            text={'Loading Questions...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods} >
                <Tab.Container id="tab-question"
                    activeKey={key}
                    onSelect={(k: any) => setKey(k)}
                >
                    <Row>
                        <Col lg="12">
                            <Container>
                                <Nav variant="pills" className={'justify-content-center'} id="questions-pills">
                                    <Nav.Item>
                                        <Nav.Link eventKey="startup">Startup</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="first-scoring" disabled={!user_id}>First Scoring</Nav.Link>
                                    </Nav.Item>
                                    <Nav.Item>
                                        <Nav.Link eventKey="refined-scoring" disabled={!user_id}>
                                            Refined Scoring
                                    </Nav.Link>
                                    </Nav.Item>
                                </Nav>
                            </Container>
                        </Col>
                        <Col lg="12">
                            <Tab.Content>
                                <Tab.Pane eventKey="startup">
                                    {/* Create Update Startup and User */}
                                    <CreateStartupAndUser
                                        onCreate={onCreateUser}
                                        onChangeUser={onChangeUser}
                                        userId={user_id}
                                    />
                                </Tab.Pane>

                                {/* First Scoring */}
                                <Tab.Pane eventKey="first-scoring">
                                    <Container fluid>
                                        {user_id && <FirstScoring
                                            categories={categories}
                                            questions={questions}
                                            lastAnswer={answer}
                                        />}
                                    </Container>
                                </Tab.Pane>
                                {/* Refined Scoring */}
                                <Tab.Pane eventKey="refined-scoring">
                                    <Container fluid>
                                        {user_id && user && <RefinedScoring
                                            categories={categories}
                                            questions={questions}
                                            lastAnswer={answer}
                                            startup={user.startup}
                                        />}
                                    </Container>
                                </Tab.Pane>
                            </Tab.Content>

                        </Col>
                    </Row>
                </Tab.Container >
                {/* Fixed Button */}
                {questions && questions.length > 0 && user_id  && key !== 'startup' && <FinishQuestionnaireButton categories={categories} />}
            </FormProvider>
        </LoadingOverlay >
    )
}

export default AnswerView;
