import gql from 'graphql-tag'
import { NOTE_FIELDS } from './note.fragment'

// List
export const GET_NOTE_BY_CATEGORY_SKU = gql`
${NOTE_FIELDS}
query getNoteByCategorySku($category_sku: String!){            
    getNoteByCategorySku(category_sku:$category_sku){      
        ...NoteFields    
        category{
            title
        }
    }
}`

// Update
export const UPDATE_NOTE = gql`
${NOTE_FIELDS}
mutation updateNote($data: NoteInput!){            
    updateNote(data: $data){
        ...NoteFields             
        category{
            title
        }
    }
}`
