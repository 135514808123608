import gql from 'graphql-tag'
import { STARTUP_FILE_FIELDS } from './startup-file.fragment'

// List
export const LIST_STARTUP_FILES = gql`
${STARTUP_FILE_FIELDS}
query listStartupFiles{            
    listStartupFiles{      
        ...StartupFileFields         
     }
}`
export const FIND_STARTUP_FILE_BY_CATEGORY_SKU = gql`
${STARTUP_FILE_FIELDS}
query findStartupFileByCategorySku($category_sku: String!){            
    findStartupFileByCategorySku(category_sku:$category_sku){      
        ...StartupFileFields           
    }
}`
// Get by ID
export const GET_STARTUP_FILE_BY_ID = gql`
${STARTUP_FILE_FIELDS}
query getStartupFileById($id: String!){            
    getStartupFileById(id: $id){      
        ...StartupFileFields    
    }
}`
// Create
export const CREATE_STARTUP_FILE = gql`
${STARTUP_FILE_FIELDS}
mutation createStartupFile($data: StartupFileInput!){            
    createStartupFile(data: $data){
        ...StartupFileFields             
    }
}`
// Update
export const UPDATE_STARTUP_FILE = gql`
${STARTUP_FILE_FIELDS}
mutation updateStartupFile($data: StartupFileInput!){            
    updateStartupFile(data: $data){
        ...StartupFileFields             
    }
}`

// Delete
export const DELETE_STARTUP_FILE = gql`
${STARTUP_FILE_FIELDS}
mutation deleteStartupFile($id: String!){            
    deleteStartupFile(id: $id){
        ...StartupFileFields             
    }
}`

// Delete File
export const DELETE_STARTUP_FILE_FILE = gql`
mutation deleteStartupFileFile($id: String!){            
    deleteStartupFileFile(id: $id)    
}`
