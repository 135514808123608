
import React, { useEffect, useCallback, useState } from "react";
// History
import { useHistory } from "react-router-dom";
// Svg
import { ReactSVG } from "react-svg";
// Button
import Button from "../../buttons/Button";
// Item
import QuestionPaginationItem from "./QuestionPaginationItem";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    getQuestionnaireQuestionsPageLead,
    updateAdvanceLead,
    updateQuestionsPageLead,
    setAnswersLead
} from '../../../redux/ducks/questionnaire-lead-duck';
// Form
import { useForm, FormProvider } from "react-hook-form";


// Interface
interface QuestionsPaginationProps {
    questions: any,
}
const QuestionsPagination = (props: QuestionsPaginationProps) => {
    // Form
    const methods = useForm({
        mode: 'onChange'
    })
    // Redux
    const dispatch = useDispatch();
    const questionsPage = useSelector(getQuestionnaireQuestionsPageLead)
    // History
    const history = useHistory();
    // Arrow
    // const arrowRight = require("../../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Pagination
    const [questionsPagination, setQuestionsPagination] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<any>(1);
    const nextRoute = `/questionnaire/intro-lead/done` ;
    const backRoute = `/questionnaire/intro-lead/startup`;
    // Props
    const { questions } = props;
    // Fetch Questions
    const fetchQuestions = useCallback(() => {
        setQuestionsPagination(questions)
    }, [setQuestionsPagination, questions])
    // Fetch Current Page
    const fetchCurrentPage = useCallback(() => {
        setCurrentPage(questionsPage)
    }, [setCurrentPage, questionsPage])
    // Next
    const onClickNext = () => {
        const next = currentPage + 1;
        const data = methods.getValues();
        console.log('data', data)
        dispatch(setAnswersLead({ currentPage, data }))
        if (next > questionsPagination.length) {
            history.push(nextRoute)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPageLead(next))
        }

    }
    // Back
    const onClickBack = () => {
        const next = currentPage - 1;
        const data = methods.getValues();
        console.log('data', data)
        dispatch(setAnswersLead({ currentPage, data }))
        if (next === 0) {
            history.push(backRoute)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPageLead(next))
        }

    }
    // Init Questions
    useEffect(() => {
        if (questions) {
            fetchQuestions()
        }
    }, [fetchQuestions, questions])
    // Change Advance
    useEffect(() => {
        const advance = currentPage * 2 + 29
        // Update Redux
        dispatch(updateAdvanceLead(
            advance
        ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentPage])
    // Fetch questions Page
    useEffect(() => {
        if (questionsPage !== currentPage) {
            fetchCurrentPage()
        }
    }, [questionsPage, currentPage, fetchCurrentPage])

    return (
        <>
            <FormProvider {...methods}>
                {
                    questionsPagination &&
                    questionsPagination[currentPage - 1] &&
                    <QuestionPaginationItem
                        key={questionsPagination[currentPage - 1].id}
                        question={questionsPagination[currentPage - 1]}
                        onSelectOption={onClickNext}
                    />
                }
            </FormProvider>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}>
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
            </div>

        </>
    )
}

export default QuestionsPagination;