
/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
// Questionnaire
import QuestionnaireGuestIntro from "../../components/questionnaire-guest/QuestionnaireGuestIntro";
import QuestionnaireGuestExample from "../../components/questionnaire-guest/QuestionnaireGuestExample";
import QuestionnaireGuestFirstQuestions from "../../components/questionnaire-guest/QuestionnaireGuestFirstQuestions";
import QuestionnaireGuestSecondQuestions from "../../components/questionnaire-guest/QuestionnaireGuestSecondQuestions";
import QuestionnaireGuestDone from "../../components/questionnaire-guest/QuestionnaireGuestDone";
import MultipleStep from "../../components/multiple-step/MultipleStep";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    getQuestionnaireGuestAdvance,
    getQuestionnaireGuestUser,
    setQuestionsGuest,
    resetQuestionnaireGuest,
    setLastAnswerGuest,
    setAnswersRefinedScoringGuest,
    setAnswersFirstScoringGuest,
    updateQuestionsPageFirstScoringGuest,
    updateQuestionsPageRefinedScoringGuest
} from '../../redux/ducks/questionnaire-guest-duck';
// Client
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_GUEST } from "../../queries/questions/questions.query";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
// Router
import { useHistory, useLocation } from "react-router-dom";
// SVG
import { ReactSVG } from "react-svg";
// Toast
import { toast } from "react-toastify";
// Button
import Button from "../../components/buttons/Button";
// Hooks
import useGuestAnswer from "../../hooks/guest/useGuestAnswer";
import useGuestUpdate from "../../hooks/guest/useGuestUpdate";
import { UPDATE_GUEST } from "../../queries/guests/guests.query";
import { showModal } from "../../redux/ducks/modal-wrapper-duck";


const IntroViewGuest = (props) => {
    // Route
    const location = useLocation();
    // Answer Input
    const answerInput = useGuestAnswer();
    const guestUpdate = useGuestUpdate();
    // Loading 
    const [loading, setLoading] = useState(false)
    // console.log(location.pathname);
    // Arrow
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Advance
    const advance = useSelector(getQuestionnaireGuestAdvance);
    // User
    const guest = useSelector(getQuestionnaireGuestUser);
    // Questions
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // History
    const history = useHistory();
    // Steps
    const steps = [
        {
            name: 'Intro',
            route: '/',
            component: QuestionnaireGuestIntro
        },
        {
            name: 'Example',
            route: '/example',
            component: QuestionnaireGuestExample
        },
        {
            name: 'First Questions',
            route: '/first-questions',
            component: QuestionnaireGuestFirstQuestions
        },
        {
            name: 'Second Questions',
            route: '/second-questions',
            component: QuestionnaireGuestSecondQuestions
        },
        {
            name: 'Done!',
            route: '/done',
            component: QuestionnaireGuestDone
        },
    ];
    // Save Current Answers
    const saveData = async () => {
        // Update Guest
        const responseGuest = await client.mutate({
            mutation: UPDATE_GUEST,
            variables: { data: guestUpdate },
            fetchPolicy: 'no-cache'
        })
        const guestUpdated = responseGuest.data.updateGuest || {}
        if (!guestUpdated.id) {
            throw new Error('Cannot update guest')
        }
        // Create Answer
        const responseAnswer = await client.mutate({
            mutation: CREATE_ANSWER,
            variables: { data: answerInput },
            fetchPolicy: 'no-cache'
        })
        const newAnswer = responseAnswer.data.createAnswer || {}
        if (!newAnswer.id) {
            throw new Error('Cannot save answer');
        }


    }
    // On Finish Later
    const onClickFinishLater = async () => {
        try {
            setLoading(true)
            await saveData();

            dispatch(
                showModal({
                    title: 'Questionnaire Information',
                    body: 'When you are ready to finish the questionnaire, please click again on the link sent to your email, and you will restart where you left off. Thank you!',
                    withButtons: true,
                    actionButton: 'alert'
                })
            );
            // dispatch(resetQuestionnaireGuest())
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);
        }
        finally {
            setLoading(false)
        }
    }
    // fetchLastAnswer
    const fetchLastAnswer = useCallback((lastAnswer)=>{
        const items = lastAnswer.answer_items;
        const answers_first_scoring = {};
        const answers_refined_scoring = {};
        for(let item of items){
            answers_first_scoring[`${item.question_type_sku}`] = item.first_scoring_id;
            if(item.refined_scoring_ids){
                answers_refined_scoring[`${item.question_type_sku}`] = item.refined_scoring_ids;
            }
        }
        console.log(answers_first_scoring)
        console.log(answers_refined_scoring)
         dispatch(setAnswersFirstScoringGuest(answers_first_scoring))
        dispatch(setAnswersRefinedScoringGuest(answers_refined_scoring))
    },[])
    const fetchGuestAdvance = useCallback(()=>{
        if(guest.advance){
            const advance = guest.advance;
            const page = advance.page;
            const firstPage = advance.firstPage;
            const refinedPage = advance.refinedPage;
            dispatch(updateQuestionsPageFirstScoringGuest(firstPage))
            dispatch(updateQuestionsPageRefinedScoringGuest(refinedPage))
            if(page){
                history.push(`/questionnaire-guest/intro/${page}`)
            }
        }
    },[guest, dispatch])
    // Fetch Data
    const fetchData = useCallback(async () => {
        let questions;
        let lastAnswer;
        try {
            const resp = await client.query({
                query: LIST_QUESTIONS_GUEST,
                variables:{guest_id: guest.id, startup_id: guest.startup_id},
                fetchPolicy: 'no-cache'
            })
            console.log(resp);
            questions = resp.data.listQuestions;
            lastAnswer = resp.data.getLastAnswerGuest || null;
           
        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setQuestionsGuest(questions));
            dispatch(setLastAnswerGuest(lastAnswer))
            if(lastAnswer){
                fetchLastAnswer(lastAnswer)
                fetchGuestAdvance()
            }
        }

    }, [
        client, 
        guest,
        fetchLastAnswer,
        fetchGuestAdvance,
        dispatch
    ])    
    // Check if user in memory
    const redirectToError = useCallback(() => {
        history.push(`/questionnaire-guest/error`)
    }, [])
    const redirectToThanks = useCallback(() => {
        history.push(`/questionnaire-guest/thanks`)
    }, [])
    useEffect(() => {
        if (!guest) {
            redirectToError()
        }else{
            if(!guest.answered){
                fetchData()
            }else{
                redirectToThanks()
            }
            
        }
    }, [guest])
    // Render
    return (
        <>

            <MultipleStep steps={steps}>
                {location && location.pathname && location.pathname.includes(`/questionnaire-guest/intro/second-questions`) &&
                    <Button
                        disabled={loading}
                        variant="" className="btn-violet btn-finish-later-questionnaire" onClick={onClickFinishLater}>
                        <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Finish Later
                </Button>}
                <div className="progress-multiple-step">
                    <div className="progress-multiple-step-bar" style={{ width: advance ? `${advance}%` : '0%' }}></div>
                </div>
            </MultipleStep>
        </>
    )
}

export default IntroViewGuest;
