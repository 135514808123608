/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Button
import Button from "../../components/buttons/Button";
// Boxs
import BoxAllChallenges from "../../components/dashboard/BoxAllChallenges";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { challengeCreate, getChallenge } from "../../redux/ducks/challenge-duck";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_CHALLENGES } from "../../queries/challenges/challenge.query";
// Go Premium
const ChallengesView = (props) => {
    // Challenges
    const [challenges, setChallenges] = useState<any>(null);
    // Disptach
    const dispatch = useDispatch();
    // Selectors
    const onUpdateCreateChallenge = useSelector(getChallenge);
    // Client
    const client = useApolloClient();
    // Fetch challenges
    const fetchChallenges = useCallback(async () => {
        // Challenge
        const responseChallenges = await client.query({
            query: LIST_CHALLENGES,
            fetchPolicy: "no-cache",
        });
        const myChallenges = responseChallenges.data.listChallenges || [];
        // My Challenges
        setChallenges(myChallenges);
        // setChallenges(CHALLENGES)
    }, [setChallenges]);
    // Fetch All data what dont need dashboard data
    useEffect(() => {
        // Challenges
        fetchChallenges();
    }, []);
    // On add or update Challenge
    useEffect(() => {
        if (onUpdateCreateChallenge && onUpdateCreateChallenge.id) {
            // Fetch Challenges
            fetchChallenges();
            // Set Null Challenge Selector, to not call change when change route
            dispatch(challengeCreate(null));
        }
    }, [onUpdateCreateChallenge]);
    // Render
    return (
        <>
            <Row>
                <Col md={12}>
                    <BoxAllChallenges challenges={challenges} type={'category'}/>
                </Col>
            </Row>
        </>
    );
};

export default ChallengesView;
