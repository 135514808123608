import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
// D3
import * as d3 from 'd3';
// Hook
import useWindowSize from '../../../hooks/useWindowResize';
// Constants
import { CHART_COLOR } from '../../../constants/charts/chart.constants';
import StartupHelper from '../../../helpers/startup/startup.helper';


interface ProgressBarChartProps {
    data: {
        label: string,
        percentage: number,
        hasData: boolean
    },
    settings?: {
        height: number,
        heightBar: number,
        labelWidth: number
    }

}

const ProgressBarChart = (props: ProgressBarChartProps) => {    
    // Hook Window
    const windowSize = useWindowSize();
    // Props
    const { data: dataProps, settings: settingsProps } = props;
    // State
    const defaultSettings = useMemo(() => {
        return {
            height: 14,
            heightBar: 12,
            labelWidth: 63 + 15
        }
    }, []);
    const [data, setData] = useState<any>()
    const [settings, setSettings] = useState<any>(null)
    // Line Height
    const [lineHeight, setLineHeight] = useState<any>(14)
    // Size
    // const [size, setSize] = useState<any>({ width: 'auto', height: 'auto' })

    // Chart
    const chartEl = useRef<HTMLDivElement>(null);
    const parentEl = useRef<HTMLDivElement>(null);
    // Fetch Data
    const fetchData = useCallback(() => {
        const settings = Object.assign(defaultSettings, {})
        if (settingsProps) {
            if (settingsProps.height) {
                settings.height = settingsProps.height;
            }
        }
        setSettings(settings);
        setData(dataProps);
    }, [defaultSettings, settingsProps, dataProps])

    const setup = useCallback(() => {
        let svg, width, height;
        // Svg Styles
        const margin = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
        if (parentEl && parentEl.current) {
            // Element width
            const elementSize = parentEl.current.clientWidth;
            width = Math.min(elementSize, 500) - margin.left - margin.right - settings.labelWidth;
            height = settings.height - margin.top - margin.bottom

            // Renove Svg
            d3.select(chartEl.current)
                .select("svg").remove()
            // Create
            svg = d3
                .select(chartEl.current)
                .append("svg")
                .attr("width", (width + margin.left + margin.right))
                .attr("height", (height + margin.top + margin.bottom))
                .append("g").attr("class", "wrapper")
            // .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");
        }
        return { svg, margin, width, height }
    }, [settings])

    // Add logic to draw the chart here
    const drawChart = useCallback(() => {
        if (chartEl && chartEl.current && data && data.percentage >= 0) {
            const startupHelper =  new StartupHelper();
            // Percentage
            const isZero = parseInt(data.percentage) === 0;
            // Setup
            const { svg, width, height } = setup();
            setLineHeight(height)
            // Scale of progress bar
            const scale = d3.scaleLinear().domain([0, 100]).range([0, width - 2]);
            const status = startupHelper.resolvePercentageScoringStyle(data.percentage);
            // Rect Background
            svg.append('rect')
                .attr('class', 'bg-rect')
                .attr('rx', 4)
                .attr('ry', 4)
                .attr('fill', CHART_COLOR.progressBarBg)
                .attr('stroke', CHART_COLOR.progressBarBorder)
                .attr('stroke-width', 1)
                .attr('height', settings.height)
                .attr('width', width)
                .attr('x', 0);
            // Progress
            const progress =
                svg.append('rect')
                    .attr('class', 'progress-rect')
                    .attr('fill', isZero ? '#6C727F' : status?.color)
                    .attr('height', settings.heightBar)
                    .attr('width', 0)
                    .attr('rx', 3)
                    .attr('ry', 3)
                    .attr('x', 1)
                    .attr('y', 1);

            progress
                .transition()
                .duration(1000)
                .attr('width', scale((isZero && !data.hasData) ? 50 : data.percentage) as any);
            // Text
            if (data.percentage > 18 || (isZero && !data.hasData)) {
                const textX = scale(isZero ? 50 : data.percentage) as any / 2;
                svg
                    .append("text")
                    .attr('class', 'progress-text')
                    .attr('y', settings.heightBar / 2 + 2)
                    .attr('x', textX)
                    .attr('dominant-baseline', 'middle')
                    .attr('text-anchor', 'middle')
                    .text(isZero ? 'NO DATA' : status.label)
                    .style('opacity', 0)
                    .transition()
                    .delay(1000)
                    .duration(600)
                    .style('opacity', 1);
            }

        } 
    }, [data, setup, settings])

    // On Change Props
    useEffect(() => {
        if (dataProps) {
            fetchData()
        }
    }, [dataProps, fetchData]);
    // Resize when call third time (first undefined, second with window value)
    useEffect(() => {
        if (windowSize.width && data && settings) {
            drawChart();
        }
    }, [windowSize, data, settings, drawChart]);


    return (
        <div ref={parentEl} className="startup-progress-chart">
            <div className="label">
                <span>{data && data.label ? data.label : ''}</span>
            </div>
            <div className="progress-bar-wrapper" style={{ lineHeight: lineHeight + 'px' }}>
                <div ref={chartEl} className="startup-progress-container"></div>
            </div>

        </div>
    )
}

export default ProgressBarChart;