/*eslint-disable*/
import React, { useState, useEffect } from "react";
// React bootstrap
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
// Router
import { withRouter } from "react-router";
import { NavLink, useHistory } from "react-router-dom";
// Apollo
import { useQuery } from "react-apollo";
import { GET_USER_DATA } from "../../queries/users/user.query";
// Utils
import { removeToken } from "../../utils";
import { ReactSVG } from "react-svg";
// Redux
import { useDispatch } from 'react-redux';
import { userDelete } from "../../redux/ducks/user-duck";

const DashboardLeadNavbar = (props: any) => {
  // History
  const history = useHistory();
  // Redux
  const dispatch = useDispatch();
  // Query
  const { loading, error, data } = useQuery(GET_USER_DATA, {
    fetchPolicy: 'no-cache'
  });
  // Props
  const [fullname, setFullname] = useState("")
  useEffect(() => {
    if (!loading) {
      if (data && data.userData) {
        setFullname(data.userData.fullname)
      } else {
        logout()
      }

    }
  }, [data, loading])

  const logout = () => {
    dispatch(userDelete())
    removeToken();
    history.push(`/auth/login`)
  }


  return (
    <Navbar id="navbar-dashboard" className={'fixed-top'} expand="lg">

      {/* Logo */}
      <Navbar.Brand href="/dashboard/main" className='text-white'>
        <Image src={require("../../assets/img/logo.svg").default} />
      </Navbar.Brand>
      {/* Toggle */}
      <Navbar.Toggle aria-controls="admin-nav order-1 " />
      <Navbar.Collapse id="admin-nav" className="mr-auto">
        {/* Main Nav */}
        <Nav id="main-navbar-nav">
          <Nav.Item>
            <NavLink className="nav-link" to="/dashboard-lead/main">Dashboard</NavLink>
          </Nav.Item>
        </Nav>
        {/* Logout Nav */}
        <Nav id="logout-navbar-nav" className={'ml-auto'}>
          <Nav.Item>
            <Nav.Link className="nav-link" onClick={logout}>
              <ReactSVG src={require(`../../assets/img/header/logout.svg`).default} wrapper="span" className="icon-link" />
                Logout
            </Nav.Link>
          </Nav.Item>
        </Nav >
      </Navbar.Collapse>

    </Navbar>
  )
}


export default DashboardLeadNavbar;
