import React, { useCallback, useEffect, useState } from "react";
// Slider
import Slider from 'rsuite/lib/Slider';
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// Helper
import StartupHelper from "../../helpers/startup/startup.helper";
interface RangePickerProps {
    value: number,
    onChange: Function,
    size?: string,
    disabled?:boolean
}

const RangePicker = (props: RangePickerProps) => {
    // Helper
    const statupHelper = new StartupHelper();
    // Props
    const { value: valueProps, onChange, size, disabled } = props;
    // State
    const [value, setValue] = useState(0)
    const [status, setStatus] = useState('poor')
    // Change
    const onChangeValue = (value) => {
        const status = statupHelper.resolvePercentageScoringStyle(value)
        setValue(value)
        onChange(value);
        setStatus(status.key);

    };
    // Fetch
    const fetchData = useCallback(() => {
        onChangeValue(valueProps)
    }, [setValue, valueProps])
    useEffect(() => {
        if (valueProps && valueProps !== value) {
            fetchData()
        }
    }, [valueProps, fetchData])
    return (
        <Form.Group className="form-group-slider">
            <Row>
                <Col lg="6">
                    <Form.Label>Starting percentage</Form.Label>
                </Col>
                <Col lg="6" className={'d-flex justify-content-lg-end mt-2 mt-lg-0'}>
                    <Form.Label className={`status-${status}`}>{value}%</Form.Label>
                </Col>
            </Row>

            <Slider
                disabled={disabled}
                className={`status-${status}`}
                progress
                value={value}
                onChange={(value) => onChangeValue(value)}
            />
        </Form.Group>
    )


}



export default RangePicker;