import React, { useEffect, useCallback, useState } from "react";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getQuestionnaireGuestQuestions,
  setQuestionnaireGuestPage,
} from "../../redux/ducks/questionnaire-guest-external-duck";
// Pagination
import QuestionPaginationGuestExternal from "./question-pagination/QuestionPaginationGuestExternal";
// Questions Exteneral
const QuestionnaireGuestExternalQuestions = (props) => {
  //  Dispatch
  const dispatch = useDispatch();
  // Question Store
  const questionsStore = useSelector(getQuestionnaireGuestQuestions);
  const [questions, setQuestions] = useState<any>([]);
  const [questionAdvanceFactor, setQuestionsAdvanceFactor] = useState<number>(0);

  // Fetch Data
  const fetchData = useCallback(() => {
    const factor = 100 / (questionsStore.length + 2);
    console.log(factor);
    setQuestionsAdvanceFactor(factor);
    setQuestions(questionsStore);
  }, [setQuestions, setQuestionsAdvanceFactor, questionsStore]);

  useEffect(() => {
    if (questionsStore !== questions) {
      fetchData();
    }
  }, [fetchData, questions, questionsStore]);

  useEffect(() => {
    dispatch(setQuestionnaireGuestPage("questions"));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  return (
    <>
      <QuestionPaginationGuestExternal
        questionAdvanceFactor={questionAdvanceFactor}
        questions={questions} />
    </>
  );
};

export default QuestionnaireGuestExternalQuestions;
