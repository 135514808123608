
import React from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
// Box
import BoxStartup from "../box/BoxStartup";
// Helper
import DateHelper from "../../helpers/dates/date.helper";
import NumberPretty from "../number/NumberPretty";


interface StartupInfoProps {
    startup: any,
    categories: any
}
const StartupTotals = (props: StartupInfoProps) => {
    // Props
    const { startup, categories } = props;
    const dateHelper = new DateHelper();
    let order = 0;
    return (
        <Container>
            { startup &&
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">
                            Startup Totals&nbsp;<b>({startup.stage_text})</b>
                        </span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <Row>
                            <Col md="1"></Col>
                            <Col md="10">
                                <div className="table-startup-wrapper table-responsive table-totals-wrapper">
                                    {categories.map((category, index) => {
                                        const typesTotal = startup.totals.types.filter(t => t.category_sku === category.sku);
                                        const categoryTotal = startup.totals.categories.find(c => c.category_sku === category.sku);
                                        return (
                                            <table className='table table-totals' key={category.id}>
                                                <thead>
                                                    <tr>
                                                        <th className={'thead-question'}></th>
                                                        <th></th>
                                                        <th colSpan={3}>First Scoring</th>
                                                        <th colSpan={3}>Refined Scoring</th>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={2}></th>
                                                        {/* FIRST SCORING */}
                                                        <th className={'thead-value'}>S</th>
                                                        <th className={'thead-value'}>E</th>
                                                        <th className={'thead-value'}>C</th>
                                                        {/* REFINED SCORING */}
                                                        <th className={'thead-value'}>S</th>
                                                        <th className={'thead-value'}>E</th>
                                                        <th className={'thead-value'}>C</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {typesTotal.map(type => {
                                                        order++;
                                                        return (
                                                            <tr key={type.type_sku}>
                                                                <td>{type.type_title}</td>
                                                                <td>{order}</td>
                                                                {/* FIRST SCORING */}
                                                                <td className={'font-weight-bold'}><NumberPretty hideZero={true}>{type.firstScoring}</NumberPretty> </td>
                                                                <td><NumberPretty hideZero={true}>{type.excitementFirstScoring}</NumberPretty> </td>
                                                                <td><NumberPretty hideZero={true}>{type.confidenceFirstScoring}</NumberPretty> </td>
                                                                {/* REFINED SCORING */}
                                                                <td className={'font-weight-bold'}><NumberPretty hideZero={true}>{type.refinedScoring}</NumberPretty></td>
                                                                <td><NumberPretty hideZero={true}>{type.excitementRefinedScoring}</NumberPretty></td>
                                                                <td><NumberPretty hideZero={true}>{type.confidenceRefinedScoring}</NumberPretty></td>
                                                            </tr>
                                                        )
                                                    })}
                                                    <tr className="totals">
                                                        <td colSpan={2} className={'bg-secondary text-right text-white font-weight-bold'}>
                                                            {category.title}
                                                        </td>
                                                        <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty>{categoryTotal.firstScoring}</NumberPretty></td>
                                                        <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty>{categoryTotal.excitementFirstScoring}</NumberPretty></td>
                                                        <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty>{categoryTotal.confidenceFirstScoring}</NumberPretty></td>

                                                        <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty>{categoryTotal.refinedScoring}</NumberPretty></td>
                                                        <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty>{categoryTotal.excitementRefinedScoring}</NumberPretty></td>
                                                        <td className={'bg-success  text-dark font-weight-bold'}><NumberPretty>{categoryTotal.confidenceRefinedScoring}</NumberPretty></td>
                                                    </tr>
                                                </tbody>
                                            </table>

                                        )
                                    }
                                    )}
                                </div>
                            </Col >
                        </Row >
                    </BoxStartup.Body>
                </BoxStartup >}
        </Container >
    )
}

export default StartupTotals;