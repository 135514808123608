/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Router
import { useParams } from "react-router-dom";
// Boxs
import BoxFundabilityMainElement from "../../components/dashboard/BoxFundabilityMainElement";
import BoxFiles from "../../components/dashboard/BoxFiles";
import BoxAllChallenges from "../../components/dashboard/BoxAllChallenges";
import BoxBrainStormingNotes from "../../components/dashboard/BoxBrainStormingNotes";
import BoxStrengths from "../../components/dashboard/BoxStrengths";
import BoxBestPractices from "../../components/dashboard/BoxBestPractices";
// Constants
import {
  DEFAULT_MAIN_ELEMENT_FUNDING,
  QUESTION_CATEGORIES,
} from "../../constants/startup/startup.constants";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_NOTE_BY_CATEGORY_SKU } from "../../queries/note/note.query";
import { LIST_CHALLENGES } from "../../queries/challenges/challenge.query";
import { LIST_BEST_PRACTICE } from "../../queries/best-practice/best-practice.query";
import { FIND_STARTUP_FILE_BY_CATEGORY_SKU } from "../../queries/startup-file/startup-file.query";
import { GET_STARTUP_DASHBOARD, GET_STARTUP_DASHBOARD_EXTERNAL, GET_STARTUP_DASHBOARD_INTERNAL } from "../../queries/startup/startups.query";
// Helper
import StartupHelper from "../../helpers/startup/startup.helper";
// Redux
import { useSelector, useDispatch } from "react-redux";
import {
  getDashboardData,
  getDashboardDataExternal,
  getDashboardDataInternal,
  setDashboardData,
  setDashboardDataExternal,
  setDashboardDataInternal,
  setMainElement as setMainElementRedux,
} from "../../redux/ducks/dashboard-duck";
import { challengeCreate, getChallenge } from "../../redux/ducks/challenge-duck";
import { getFileChange } from "../../redux/ducks/files-duck";
// Window Size
import useWindowSize from "../../hooks/useWindowResize";
// lodash
import * as _ from "lodash";
import { toast } from "react-toastify";
// Main Element View (Category)
const MainElementView = (props) => {
  // Dispatch
  const dispatch = useDispatch();
  // All Data without Internal and external
  const dashboardData = useSelector(getDashboardData);
  // Internal
  const dashboardDataInternal = useSelector(getDashboardDataInternal);
  // External
  const dashboardDataExternal = useSelector(getDashboardDataExternal);
  // On Update Create Challenge
  const onUpdateCreateChallenge = useSelector(getChallenge);
  // File Chnage
  const fileChange = useSelector(getFileChange)
  // Client
  const client = useApolloClient();
  // Helper
  const startupHelper = new StartupHelper();
  // Params
  const { main_element } = useParams<any>();
  // Main elemt
  const [mainElement, setMainElement] = useState<any>("");
  const [mainElementDescription, setMainElementDescription] = useState<any>(``);
  // Fundability
  const [fundability, setFundability] = useState<any>(
    DEFAULT_MAIN_ELEMENT_FUNDING
  );
  const [fundabilityInternal, setFundabilityInternal] = useState<any>(
    DEFAULT_MAIN_ELEMENT_FUNDING
  );
  const [fundabilityExternal, setFundabilityExternal] = useState<any>(
    DEFAULT_MAIN_ELEMENT_FUNDING
  );
  const [fundabilitySubElements, setFundabilitySubElements] = useState<any>([]);
  // Priority Level
  const [priorityLevel, setPriorityLevel] = useState<any>({
    preSeed: {
      sku: "empty",
      title: "EMPTY",
    },
    seed: {
      sku: "empty",
      title: "EMPTY",
    },
    seriesA: {
      sku: "empty",
      title: "EMPTY",
    },
  });
  // Challenges
  const [challenges, setChallenges] = useState<any>(null);
  // Files
  const [files, setFiles] = useState<any>([]);
  // Best Practices
  const [bestPractices, setBestPractices] = useState<any>(null);
  // Notes
  const [note, setNote] = useState<any>(null);
  // Strength
  const [strengths, setStrengths] = useState<any>(null);
  // Fetch Dashboard Data
  const fetchDashboardData = useCallback(async () => {
    const response = await client.query({
      query: GET_STARTUP_DASHBOARD,
      fetchPolicy: "no-cache",
    });
    // Startup
    const startup = response.data.getStartupDashboard || {};
    dispatch(setDashboardData(startup));
    // Internal
    const responseInternal = await client.query({
      query: GET_STARTUP_DASHBOARD_INTERNAL,
      fetchPolicy: "no-cache",
    });
    const startupInternal = responseInternal.data.getStartupDashboard || {};
    dispatch(setDashboardDataInternal(startupInternal));
    // External
    const responseExternal = await client.query({
      query: GET_STARTUP_DASHBOARD_EXTERNAL,
      fetchPolicy: "no-cache",
    });
    const startupExternal = responseExternal.data.getStartupDashboard || {};
    dispatch(setDashboardDataExternal(startupExternal));
  }, [client, dispatch]);
  // Fetch Fundability
  const fetchFundability = useCallback(
    (
      currentCategory,
  
    ) => {
      // Fundability
      let scoring = 0;
      if (currentCategory.hasRefinedChosen) {
        scoring = currentCategory.refinedScoring;
      } else {
        scoring = currentCategory.firstScoring;
      }
      const cloned = startupHelper.resolveFundabilityStatusElement(
        scoring,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      setFundability([...cloned]); 
    },
    [
      setFundability,
      setFundabilityExternal,
      setFundabilityInternal,
      DEFAULT_MAIN_ELEMENT_FUNDING,
    ]
  );
  const fetchFundabilityInternal = useCallback(
    (
      currentCategoryInternal,
      hasFoundersInternal,
    ) => {
     
      // Set Fundability Internal
      let scoringInternal = 0;
      if (hasFoundersInternal) {
        scoringInternal = currentCategoryInternal.scoring;
      }
      const clonedInternal = startupHelper.resolveFundabilityStatusElement(
        scoringInternal,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      setFundabilityInternal([...clonedInternal]);
   
    },
    [
      setFundability,
      setFundabilityExternal,
      setFundabilityInternal,
      DEFAULT_MAIN_ELEMENT_FUNDING,
    ]
  );
  const fetchFundabilityExternal = useCallback(
    (
      currentCategoryExternal,
      hasFoundersExternal
    ) => {
     
      // Set Fundability External
      let scoringExternal = 0;
      if (hasFoundersExternal) {
        scoringExternal = currentCategoryExternal.scoring;
      }
      const clonedExternal = startupHelper.resolveFundabilityStatusElement(
        scoringExternal,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      setFundabilityExternal([...clonedExternal]);
    },
    [
      setFundabilityExternal,
      DEFAULT_MAIN_ELEMENT_FUNDING,
    ]
  );
  // Fetch Sub Elements
  const fetchFundabilitySubElements = useCallback(
    (currentCategory, types) => {
      const subElements = types.filter(
        (items) => items.category_sku === currentCategory.category_sku
      );
      const fundabilitySubElements: any = [];
      for (let subElement of subElements) {
        let scoring = 0;
        if (subElement.hasRefinedChosen) {
          scoring = subElement.refinedScoring;
        } else {
          scoring = subElement.firstScoring;
        }
        const cloned = startupHelper.resolveFundabilityStatusElement(scoring, [
          ...DEFAULT_MAIN_ELEMENT_FUNDING,
        ]);
        fundabilitySubElements.push({
          label: subElement.type_title,
          fundability: cloned,
        });
      }
      setFundabilitySubElements(fundabilitySubElements);
    },
    [setFundabilitySubElements, DEFAULT_MAIN_ELEMENT_FUNDING]
  );
  // Fetch Strengths
  const fetchStrengths = useCallback(
    (currentCategory, types) => {
      const subElements = types.filter(
        (items) => items.category_sku === currentCategory.category_sku
      );
      const strengthsElements: any = [];
      for (let subElement of subElements) {
        let scoring = 0;
        if (subElement.hasRefinedChosen) {
          scoring = subElement.refinedScoring;
        } else {
          scoring = subElement.firstScoring;
        }
        // console.log("subElement", subElement);
        strengthsElements.push({
          ...subElement,
          scoring: scoring,
        });
      }

      let strenghtsFilter = strengthsElements.filter((i) => i.scoring >= 7);
      strenghtsFilter = _.orderBy(
        strenghtsFilter,
        ["scoring", "type_order"],
        ["desc", "asc"]
      );

      setStrengths(strenghtsFilter);
    },
    [setStrengths]
  );
  // Fetch Priority Level
  const fetchPriorityLevel = useCallback(
    (currentCategory) => {
      setPriorityLevel({
        preSeed: currentCategory.priority_level_pre_seed,
        seed: currentCategory.priority_level_seed,
        seriesA: currentCategory.priority_level_series_a,
      });
    },
    [setPriorityLevel]
  );
  // Fetch challenges
  const fetchChallenges = useCallback(async () => {
    // Challenge
    const responseChallenges = await client.query({
      query: LIST_CHALLENGES,
      fetchPolicy: "no-cache",
    });
    const myChallenges = responseChallenges.data.listChallenges || [];
    // My Challenges
    setChallenges(
      myChallenges.filter((c) => c.type.category_sku === main_element)
    );
    // setChallenges(CHALLENGES)
  }, [setChallenges, main_element]);
  // Fetch Best Practices Documents
  const fetchBestPracticeDocuments = useCallback(async () => {
    // Suggestions
    const bestPracticeResponse = await client.query({
      query: LIST_BEST_PRACTICE,
      fetchPolicy: "no-cache",
    });
    const bestPractices = bestPracticeResponse.data.listBestPractice || [];
    const bestPracticesFilter = bestPractices.filter(
      (file) => file.type.category.sku === main_element
    );
    // Files
    setBestPractices(startupHelper.mapFiles(bestPracticesFilter));
  }, [main_element, setBestPractices]);
  // Fetch Notes
  const fetchNotes = useCallback(async () => {
    setNote(null);
    // Suggestions
    const noteResponse = await client.query({
      query: GET_NOTE_BY_CATEGORY_SKU,
      variables: { category_sku: main_element },
      fetchPolicy: "no-cache",
    });
    const note = noteResponse.data.getNoteByCategorySku;
    // Files
    setNote(note);
  }, [main_element, setNote]);
  const fetchFiles = useCallback(async () => {
    const filesResp = await client.query({
      query: FIND_STARTUP_FILE_BY_CATEGORY_SKU,
      variables: { category_sku: main_element },
      fetchPolicy: 'no-cache'
    })
    const startupFiles = filesResp.data.findStartupFileByCategorySku
    setFiles(startupHelper.mapFiles(startupFiles));
  }, [setFiles, main_element]);
  // Fetch Data
  const fetchData = useCallback(async () => {
    dispatch(setMainElementRedux(main_element));
    // Startup
    const startup = dashboardData;
    // Totals
    const totals = startup.totals;
    // Categories
    const categories = totals.categories;
    const currentCategory = categories.find(
      (c) => c.category_sku === main_element
    );
    const mainElement = QUESTION_CATEGORIES.find(
      (c) => c.value === main_element
    ); 
    // Types
    const types = totals.types;
    // Priority Level
    fetchPriorityLevel(currentCategory);
    // Main Fundability
    fetchFundability(
      currentCategory
    );
    // Main Element Label
    setMainElement(mainElement?.label);
    // Main Element Label
    setMainElementDescription(currentCategory.category_description);
    // Strengths
    fetchStrengths(currentCategory, types);
    // Sub Elements
    fetchFundabilitySubElements(currentCategory, types);
  }, [
    main_element,
    dashboardData,
    fetchPriorityLevel,
    fetchFundability,
    setMainElement,
    setMainElementDescription,
    fetchStrengths,
    fetchFundabilitySubElements,
  ]);
  // Fetch Dashboard data
  useEffect(() => {
    if (main_element && dashboardData && dashboardData.id) {
      fetchData();
    }
  }, [main_element, dashboardData, fetchData]);

  // Fetch Data Internal 
  const fetchDataInternal = useCallback(() => {
    try {
      // Startup
      const startup = dashboardDataInternal;
      // Internal Total
      const totals_internal = startup.totals_internal;
      // Internal Categories
      const categoriesInternal = totals_internal.total_categories;
      const currentCategoryInternal = categoriesInternal.find(
        (c) => c.sku === main_element
      );
      // If has Founders Internal
      const hasFoundersInternal = totals_internal.has_founders;
      fetchFundabilityInternal(currentCategoryInternal, hasFoundersInternal);
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      // setLoading(false);
    }
  }, [dashboardDataInternal, fetchFundabilityInternal])
  useEffect(() => {
    if (dashboardDataInternal && dashboardDataInternal.id) {
      fetchDataInternal();
    }
  }, [dashboardDataInternal]);
  // Fetch Data External
  const fetchDataExternal = useCallback(() => {
    try {
      // Startup
      const startup = dashboardDataExternal;
      // External Total
      const totals_external = startup.totals_external;
      const categoriesExternal = totals_external.total_categories;
      const currentCategoryExternal = categoriesExternal.find(
        (c) => c.sku === main_element
      );
      // If Has founders External
      const hasFoundersExternal = totals_external.has_founders;

      fetchFundabilityExternal(currentCategoryExternal, hasFoundersExternal);
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      // setLoading(false);
    }
  }, [dashboardDataExternal, fetchFundabilityExternal])
  useEffect(() => {
    if (dashboardDataExternal && dashboardDataExternal.id) {
      fetchDataExternal();
    }
  }, [dashboardDataExternal]);
  // Fetch All data what dont need dashboard data
  useEffect(() => {
    if (main_element) {
      // Fetch Challenges
      fetchChallenges();
      // Best Practices
      fetchBestPracticeDocuments();
      // Files
      fetchFiles()
      // Notes
      fetchNotes();
    }
  }, [main_element]);
  // On add or update Challenge
  useEffect(() => {
    if (onUpdateCreateChallenge && onUpdateCreateChallenge.id) {
      // Fetch Dashboard Data
      fetchDashboardData();
      // Fetch Challenges
      fetchChallenges();
      // Set Null Challenge Selector, to not call change when change route
      dispatch(challengeCreate(null));
    }
  }, [onUpdateCreateChallenge]);
  // On File Change
  useEffect(() => {
    if (fileChange && fileChange.id) {
      fetchFiles();
    }
  }, [fileChange]);

  //#region Sizes
  // Sizes
  const windowSize = useWindowSize();
  const [columnChallengeHeight, setColumnChallengeHeight] = useState("auto");
  // Body Ref
  const colFundabilityRef = useRef<HTMLDivElement>(null);
  const changeSizes = useCallback(() => {
    setTimeout(() => {
      if (colFundabilityRef && colFundabilityRef.current) {
        const box = colFundabilityRef.current.querySelector(".box-startup");
        if (box) {
          if (windowSize.width >= 1024) {
            const boxsHeight = 184 + 16 * 2 + 174;
            const height = box.clientHeight - boxsHeight;
            setColumnChallengeHeight(`${height}px`);
          } else {
            setColumnChallengeHeight(`auto`);
          }
        }
      }
    }, 200);
  }, [windowSize, colFundabilityRef, setColumnChallengeHeight]);

  // Change
  useEffect(() => {
    if (
      windowSize.width &&
      colFundabilityRef.current &&
      fundabilitySubElements.length > 0
    ) {
      changeSizes();
    }
  }, [windowSize, colFundabilityRef, fundabilitySubElements]);
  //#endregion Sizes

  // Render
  return (
    <>
      {/* First Row */}
      <Row>
        <Col
          md={12}
          className={"col-lg-45 col-xl-43 mb-16"}
          ref={colFundabilityRef}
        >
          <BoxFundabilityMainElement
            mainElement={mainElement}
            mainElementDescription={mainElementDescription}
            priorityLevel={priorityLevel}
            fundability={fundability}
            fundabilityInternal={fundabilityInternal}
            fundabilityExternal={fundabilityExternal}
            fundabilitySubElements={fundabilitySubElements}
          />
        </Col>
        <Col md={12} className={"col-lg-55 col-xl-57 mb-16"}>
          <Row>
            <Col lg={12} className={"mb-16"}>
              <BoxStrengths
                title={mainElement}
                strengths={strengths}
                id="box-strengths-main-element"
              />
            </Col>
          </Row>
          <Row>
            <Col
              lg={6}
              className={"mb-16"}
              style={{ height: columnChallengeHeight, overflow: "hiddenn" }}
            >
              <BoxBestPractices
                files={bestPractices}
                title={"Best Practice Documents"}
                id="box-files-main-element"
              />
            </Col>
            <Col
              lg={6}
              className={"mb-16"}
              style={{ height: columnChallengeHeight, overflow: "hiddenn" }}
            >
              <BoxFiles files={files} id="box-files-main-element" />
            </Col>
          </Row>
          <Row>
            <Col md={12} className={"mb-16"}>
              <BoxBrainStormingNotes note={note} />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Second Row */}
      <Row>
        <Col md={12}>
          <BoxAllChallenges
            title={`${mainElement} Challenges`}
            challenges={challenges}
            type={"type"}
            id={"box-all-challenges-main-element"}
          />
        </Col>
      </Row>
    </>
  );
};

export default MainElementView;
