import React from 'react';
// Boostrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Button
import Button from '../buttons/Button';



interface GuestExternalItemProps {
    guest: any,
    index: number,
    onRemove: Function
}

const GuestExternalItem = (props: GuestExternalItemProps) => {
    // Props
    const { guest, onRemove, index } = props;
    const onClickRemove = () =>{
        onRemove(index);
    }
    return (
        <div className="guest-external-item">
            <Row>
                <Col md="7">
                    <span className="guest-item-name text-truncate">
                        {guest.first_name}
                    </span>
                    <span className="guest-item-email text-truncate">
                        {guest.email}
                    </span>
                </Col>
                <Col md="5" className="justify-content-end d-flex align-items-center">
                    <Button
                        variant=""
                        size="sm"
                        className={'btn-outline-danger'}
                        onClick={onClickRemove}
                        >
                        Remove
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
export default GuestExternalItem;