
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import ProgressBarChart from "../charts/progress/ProgressBarChart";

// Props
interface BoxElementScoringProps {
    elementScoring: any
}


const BoxElementScoring = (props: BoxElementScoringProps) => {
    // Props
    const { elementScoring } = props;
    // States
    const [elements, setElements] = useState<any>(null)
    const fetchElements = useCallback(() => {
        // console.log('elementScoring', elementScoring)
        setElements(elementScoring);
    }, [setElements, elementScoring])
    useEffect(() => {
        if (elementScoring && elementScoring.length > 0) {
            fetchElements();
        }
    }, [elementScoring, fetchElements])
    // Render
    return (
        <BoxStartup className={'box-element-scoring'}>
            <BoxStartup.Header>
                <span className="title">Element scoring</span>
            </BoxStartup.Header>
            <BoxStartup.Body >
                <Row>
                    <Col md={12}>
                        <div className="startup-progress-chart-list">
                            {elements && elements.map((pie, index) => {
                                return <ProgressBarChart
                                            key={index}
                                            data={pie}
                                />
                            })}
                        </div>
                    </Col>
                    {/* <Col md={6}>
                        <div className="mt-4 mt-lg-0">
                            <NestedPieChart data={elementScoring} />
                        </div>
                    </Col> */}
                </Row>
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxElementScoring;
