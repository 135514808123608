const primary = '#2662f0';
const white = '#ffffff';
const danger = '#dc3545';
/*

const bsBorderGrey = '#cccccc';
const bsBoxShadowBlue = 'inset 0 1px 1px rgba(0,0,0,.075), 0 0 8px rgba(102,175,233,.6);';
const bsBoxShadowGrey = 'inset 0 1px 1px rgba(0,0,0,.075);';
const bsBackgroundGrey = '#eeeeee';
const bsBackgroundOption = '#f8f9fa';
const bsBackgroundOptionSelected = '#eeeeee';
const bsControlTextColor = '#000';
const bsControlPlaceholderColor = '#999999';*/

const styles = {
    menu: (provided, state) => ({
        // ...provided,
        position: 'absolute',
        width: '100%',
        margin: '0.125rem 0 0',
        fontSize: '1rem',
        color: '#221f20',
        textAlign: 'left',
        border: '0',
        borderRadius: '0',
        boxShadow: '0'
    }),
    option: (provided, state) => ({
        ...provided,
        padding: 0,
    }),
    valueContainer: (provided //, state
    ) => ({
        ...provided,
        padding: '0px',
    }),
    multiValue: (provided) => ({
        ...provided,
        backgroundColor: primary,
        // margin: 0
    }),
    multiValueLabel: (provided) => ({
        ...provided,
        backgroundColor: primary,
        color: white,
        fontSize: 15,

    }),
    multiValueRemove: (provided) => ({
        ...provided,
        backgroundColor: primary,
        color: white,
        fontSize: 15,
        ':hover': {
            backgroundColor: danger ,
            color: white,
            cursor: 'pointer'
        },

    })
    
}
export default styles;