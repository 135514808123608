import React, { useEffect, useCallback, useState, useImperativeHandle } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// React hook Form
import { useFormContext } from "react-hook-form";
// Helpers
// import StartupHelper from "../../../helpers/startup/startup.helper";

interface QuestionBlockRefinedScoringProps {
    question: any,
    onChangeQuestion?: Function,
    refinedScoring: any,
    answerItem: any,
    startup: any
}
const QuestionBlockRefinedScoring = React.forwardRef((props: QuestionBlockRefinedScoringProps, ref) => {
    // State
    const [namePrefix, setNamePrefix] = useState<any>(null);
    const [chosenRefinedScoring, setChosenRefinedScoring] = useState<any>([]);
    // const [totalRefinedScoring, setTotalRefinedScoring] = useState(0);
    // Props
    const { question, onChangeQuestion, refinedScoring, answerItem,startup } = props;
    // Helpers
    // const startupHelper = new StartupHelper();
    // Form
    const { register, setValue, getValues } = useFormContext();

    // Validation and set values
    const validation = useCallback(() => {
        //  const maxFirstScoring = startupHelper.getMaxWeight(firstScoring)
        // Name Prefix
        const namePrefixFormated = `refinedScoring.${question.type.category_sku}.${question.type_sku}`;
        // console.log('validation', namePrefixFormated)
        setNamePrefix(namePrefixFormated)
        // Option Validation        
        register({ name: `${namePrefixFormated}.refinedScoringChosen` });
        // register({ name: `${namePrefixFormated}.totalRefinedScoring` });
        //  Totals
        // setValue(`${namePrefixFormated}.totalRefinedScoring`, 0)
        // // First Scoring data necesary
        // // Option Validation
        // register({ name: `${namePrefixFormated}.maxWeightFirstScoring` });
        // register({ name: `${namePrefixFormated}.weightType` });
        // register({ name: `${namePrefixFormated}.total_refined_pre_seed` });
        // register({ name: `${namePrefixFormated}.total_refined_seed` });
        // register({ name: `${namePrefixFormated}.total_refined_series_a` });
        // // Set Values
        // setValue(`${namePrefixFormated}.maxWeightFirstScoring`, maxFirstScoring.weight)
        // // Set Weight Type 
        // setValue(`${namePrefixFormated}.weightType`, question.weight_type_sku)
        // // Totals Seed
        // setValue(`${namePrefixFormated}.total_refined_pre_seed`, question.total_refined_pre_seed);
        // setValue(`${namePrefixFormated}.total_refined_seed`, question.total_refined_seed);
        // setValue(`${namePrefixFormated}.total_refined_series_a`, question.total_refined_series_a);


        // First Change
        if (onChangeQuestion) {
            onChangeQuestion();
        }

    }, [register, question, setNamePrefix, setValue, onChangeQuestion])

    const handleChangeRefinedScoring = (questionItem) => {
        // Get Value
        const refinedScoringOld = getValues(`${namePrefix}.refinedScoringChosen`);
        let refinedScoringNew: any = [];
        // Set
        if (refinedScoringOld) {
            refinedScoringNew = [...refinedScoringOld];
        }
        // Checked or not checked
        if (refinedScoringNew.filter(i => i.id === questionItem.id).length === 0) {
            refinedScoringNew.push(questionItem)
        } else if (refinedScoringNew.length > 0) {
            refinedScoringNew = refinedScoringNew.filter(q => q.id !== questionItem.id);
        }
        setValue(`${namePrefix}.refinedScoringChosen`, [...refinedScoringNew]);
        setChosenRefinedScoring([...refinedScoringNew]);

    }
    // const handleTotalChange = useCallback(() => {

    //     // Trigger Totals
    //     const firtsScoringChosen = getValues(`firstScoring.${question.type.category_sku}.${question.type_sku}.firstScoringChosen`);
    //     const maxFirstScoring = getValues(`firstScoring.${question.type.category_sku}.${question.type_sku}.maxWeightFirstScoring`);
    //     // console.log('total change refined', firtsScoringChosen)
    //     if (firtsScoringChosen && firtsScoringChosen.weight) {

    //         // Calculate total Refined Scoring
    //         const totalRefinedScoring = startupHelper.calculateTotalRefinedScoring(
    //             firtsScoringChosen.weight,
    //             maxFirstScoring,
    //             chosenRefinedScoring,
    //             refinedScoring.length)
    //         setValue(`${namePrefix}.totalRefinedScoring`, totalRefinedScoring);
    //         setTotalRefinedScoring(totalRefinedScoring)
    //         // Change
    //         onChangeQuestion();
    //     }
    // }, [setValue, setTotalRefinedScoring, getValues, chosenRefinedScoring, namePrefix])
    // Fetch Data
    const fetchData = useCallback(() => {
        if (answerItem.refined_scoring_ids && answerItem.refined_scoring_ids.length > 0) {
            const refinedChosen = refinedScoring.filter(function (item) {
                return !(answerItem.refined_scoring_ids.indexOf(item.id) === -1);
            });
            // Set Checked
            setChosenRefinedScoring(refinedChosen);
            setValue(`${namePrefix}.refinedScoringChosen`, refinedChosen);
        }
    }, [answerItem, refinedScoring, setChosenRefinedScoring])

    // First Generate Form
    useEffect(() => {
        if (question.id) {
            validation();
        }
    }, [question])
    // Fetch data if have
    useEffect(() => {
        if (answerItem) {
            fetchData();
        }
    }, [answerItem])


    return (
        <>
            { question && refinedScoring.length > 0 &&
                < Row className="mb-3">
                    <Col md="12">
                        <h2 className='mb-3 text-uppercase bg-primary p-2 text-white'>{question.type.title}</h2>
                    </Col>
                    {/* <Col md="12">
                        <span className='mb-4 text-uppercase font-weight-bold d-block'>{question.weight_type_name}</span>
                    </Col> */}
                    <Col md="12">
                        <h3 className='mb-3'>{question.title}</h3>
                    </Col>
                    {/* RefinedScoring */}
                    <Col md={12}>
                        <div className="pl-0">
                            {refinedScoring.map((q, index) => {
                                const includes = q.situations_sku.includes(startup.situation_sku);
                                return(    
                                <div key={`custom-${q.id}`} className="mb-3">
                                    <Form.Check
                                        custom
                                        disabled={!includes}
                                        name={`refined-${question.type.category_sku}.${question.type_sku}`}
                                        type={'checkbox'}
                                        id={`custom-${q.id}`}
                                        label={q.title}
                                        checked={chosenRefinedScoring.filter(i => i.id === q.id).length > 0}
                                        onChange={() => handleChangeRefinedScoring(q)}
                                    />
                                </div>)
                            })}
                        </div>
                    </Col>
                    <Col md={12}>
                        <hr />
                    </Col>
                </Row>}
        </>
    )
})

export default QuestionBlockRefinedScoring;