
/*eslint-disable*/
import React from "react";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import BestPracticeList from "../best-practice/BestPracticeList";
// Props
interface BoxFilesProps {
    title?: any,
    id?: any,
    className?: any,
    bestPractices: Array<any>
}
const BoxAllBestPractices = (props: BoxFilesProps) => {
    const { title, bestPractices, id, className } = props;
    return (
        <BoxStartup id={id ? id : 'box-files'} className={className ? className : ''}>
            <BoxStartup.Header>
                <span className="title">
                    {title ? title : 'Best Practice Documents Feed'}
                </span>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0 box-body-100'}>
                <BestPracticeList files={bestPractices} />
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxAllBestPractices;
