import gql from 'graphql-tag'

export const CHALLENGE_TEMPLATE_FIELDS = gql`
fragment ChallengeTemplateFields on ChallengeTemplate {        
    id    
    track_id
    title
    description
    priority_level_sku
    testability_sku
    critical
    blocking
    # Test
    # test_title
    # test_description
    metric_type_sku
    # progress
    progress
    # KPI
    kpi_metric
    kpi_metric_target
    kpi_metric_current    
    order
    locked

    created_at
    updated_at
}`