import React  from 'react';
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Button
import Button from '../buttons/Button';

interface FinishChallengeFormProps {
    onClickYes: Function,
    onClickNo: Function,
    loading: boolean
}

const FinishChallengeForm = (props: FinishChallengeFormProps) => {

    // Props
    const { onClickYes, onClickNo, loading } = props;

    return (
        <div className="challenge-finish-form">
            <Row>
                <Col lg="12">
                    <h4 className="title">Was the Challenge successfully completed?</h4>
                </Col>
                <Col lg="12" className={'btn-container'}>
                    <Button
                        disabled={loading}
                        className="btn-violet"
                        onClick={onClickYes}>
                        Yes
                    </Button>
                    <span>or</span>
                    <Button
                        disabled={loading}
                        className="btn-violet"
                        onClick={onClickNo}>
                        No
                    </Button>
                </Col>
            </Row>
        </div>
    );
};
export default FinishChallengeForm;