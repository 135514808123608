import React from 'react';
// Route
import { Route, Redirect } from 'react-router-dom';
// Token
import TokenHelper from '../../helpers/security/token.helper';
// Utils
import { getToken, isAdmin, isLogin, removeToken, removeTokenGuest } from '../../utils';
/**
 * Public route resolve
 */
const PublicRoute = ({ component: Component, restricted, ...rest }) => {
    // Token helper
    const tokenHelper = new TokenHelper();
    // Check if logged in
    let _isLogin = isLogin();
    // Default url if logged in
    let urlLoggedIn = '/dashboard/main';
    const pathname = rest.location.pathname;
    console.log('path', pathname)
    // Check if user logged in is admin to redirect admin dashboard
    if (_isLogin) {
        if (pathname.includes('questionnaire-guest')) {
            removeToken()
            removeTokenGuest();
            _isLogin = false;
        } else {
            const token = getToken();
            const user = tokenHelper.decode(token);
            if (isAdmin(user.type)) {
                urlLoggedIn = '/admin/startups';
            } else if (user.first_login) {
                console.log(user.type)
                if (user.startup_type === 'not_limited') {
                    urlLoggedIn = "/questionnaire/intro";
                } else if (user.startup_type === 'limited') {
                    urlLoggedIn = '/questionnaire/intro-guest';
                } else if (user.startup_type === 'lead_generation') {
                    urlLoggedIn = `/questionnaire/intro-lead`;
                }
            } else {
                if (user.startup_type === 'lead_generation') {
                    urlLoggedIn = '/dashboard-lead/main';
                }
            }

        }

    }
    return (
        // restricted = false meaning public route
        // restricted = true meaning restricted route
        <Route {...rest} render={props => (_isLogin && restricted ? <Redirect to={urlLoggedIn} /> : <Component {...props} />)} />
    );
};

export default PublicRoute;