
import React, { useState, useEffect, useCallback } from 'react'
import DatePicker from 'react-datepicker'


interface DatePickerProps {
    value: any,
    onChange: Function,
    isInvalid: boolean,
    minDate?: Date,
    size?: any
    disabled?: boolean
}

const StartupDatePicker = (props: DatePickerProps) => {
    // Props
    const { value, onChange, size, isInvalid, minDate, disabled } = props;
    // State
    const [date, setDate] = useState(null)
    // Change
    const onChangeDate = useCallback((argDate) => {
        setDate(argDate)
        onChange(argDate);
    }, [setDate, onChange]);
    // Fetch
    const fetchDate = useCallback(() => {
        if (value && value !== date) {
            onChangeDate(value)
        }

    }, [value, date, onChangeDate])
    useEffect(() => {
        if (value) {
            // console.log('value',value)
            fetchDate()
        }
    }, [fetchDate, value])
    return (
        <div className={`${isInvalid ? 'is-invalid' : ''}`}>
            <DatePicker
                disabled={disabled}
                selected={date}
                onChange={onChangeDate}
                dateFormat="dd/MM/yyyy"
                placeholderText="--/--/----"
                className={`${size ? size : ''}`}
                minDate={minDate ? minDate : null}
            />

        </div>
    )
}

export default StartupDatePicker;