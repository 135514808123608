export const CHART_COLOR = {
    arcLabelColor: '#a0aec0',
    darkViolet: '#1C0D3D',
    arcGrey: '#edf2f7',
    good: '#42936D',
    fair: '#EAA13B',
    poor: '#CB3A32',
    drifting: '#A0AEC0',
    bad: '#1E0601',
    inProgress: '#6C727F',
    progressBarBg: '#F7FAFC',
    progressBarBorder: '#EDF2F7',
    dark: '#1E0601',
}
