
import React from 'react';
// Boostrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StartupHelper from '../../helpers/startup/startup.helper';
import { ReactSVG } from 'react-svg';
import Button from '../buttons/Button';
import ChallengeHelper from '../../helpers/challenge/challenge.helper';
// Redux
import { useDispatch } from 'react-redux';
import { challengeCreateFromTemplate, challengeToDelete, challengeToUpdate, challengeToView } from '../../redux/ducks/challenge-duck';
// Overlay
import LockedOverlay from '../locked/LockedOverlay';
// Show More 
import ShowMoreText from 'react-show-more-text';


interface MyChallengeListItemProps {
    challenge: any,
    template: boolean
}
const MyChallengeListItem = (props: MyChallengeListItemProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Props
    const { challenge, template } = props;
    // Helper
    const startupHelper = new StartupHelper();
    const challengeHelper = new ChallengeHelper();
    //  Status
    const status = !template ? startupHelper.resolveChallengeStatus(challenge.status_sku) : { sku: 'proposed', title: 'Proposed' }
    // Icon Status
    const iconStatusSrc = require(`../../assets/img/challenges/icon_${status.sku}.svg`).default
    // On View
    const onClickViewChallenge = (challenge) => {
        dispatch(challengeToView(challenge));
    }
    // On Update
    const onClickUpdateChallenge = (challenge) => {
        dispatch(challengeToUpdate(challenge));
    }
    // On Delete
    const onClickDeleteChallenge = (challenge) => {
        dispatch(challengeToDelete(challenge));
    }
    // Cretate Form template
    const onClickViewTemplate = (challenge) => {
        if (!challenge.locked) {
            dispatch(challengeCreateFromTemplate(challenge))
        }

    }
    return (
        <div className="my-challenge-list-item position-relative">
            { template && challenge.locked && <LockedOverlay />}
            <Row>
                <Col lg={8}>
                    <div className="my-challenge-content">
                        <span className="challenge-title">{challenge.title}</span>
                        <span className="challenge-description">
                            <ShowMoreText
                                /* Default options */
                                lines={1}
                                more='Show more'
                                less='Show less'
                                className='content-css'
                                anchorClass='challlenge-description-show-more'
                                // onClick={executeOnClick}
                                expanded={false}
                                width={0}>
                                <div dangerouslySetInnerHTML={{ __html: challenge.description }} />
                            </ShowMoreText>

                        </span>
                    </div>
                </Col>
                <Col lg={4} className={'d-lg-flex mt-4 mt-lg-0 justify-content-lg-end align-items-center'}>
                    <div className="my-challenge-status">
                        {status && !template &&
                            <span className={`badge-challenge badge-challenge-status  ${status?.sku}`}>
                                <ReactSVG src={iconStatusSrc} wrapper="span" className="icon-status " />
                                {status.title}
                            </span>}
                        {/* Not template Complete*/}
                        {!template && challengeHelper.isCompleted(challenge) &&
                            <>
                                <Button
                                    variant=""
                                    size="sm"
                                    onClick={() => onClickViewChallenge(challenge)}
                                    className={'btn-outline-violet'}>
                                    View
                            </Button>
                                <Button
                                    variant=""
                                    size="sm" onClick={() => onClickDeleteChallenge(challenge)}
                                    className={'btn-outline-danger'}>
                                    Delete
                            </Button>
                            </>}
                        {/* Not template */}
                        {!template && !challengeHelper.isCompleted(challenge) &&
                            <>
                                <Button
                                    variant=""
                                    size="sm"
                                    onClick={() => onClickUpdateChallenge(challenge)}
                                    className={'btn-outline-violet'}>
                                    Update
                            </Button>
                                <Button
                                    variant=""
                                    size="sm" onClick={() => onClickDeleteChallenge(challenge)}
                                    className={'btn-outline-danger'}>
                                    Delete
                            </Button>
                            </>}
                        {/* Not template */}
                        {template &&
                            <>
                                <Button
                                    variant=""
                                    size="sm"
                                    onClick={() => onClickViewTemplate(challenge)}
                                    className={'btn-outline-violet'}>
                                    View
                            </Button>
                            </>}

                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default MyChallengeListItem;