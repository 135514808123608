
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
import ProgressBarPercentageChart2 from "../charts/progress/ProgressBarPercentageChart2";
// Props
interface SummaryItemFoundersProps {
    item: any
}


const SummaryItemFounders = (props: SummaryItemFoundersProps) => {
    const [item, setItem] = useState<any>(null);
    const [data, setData] = useState<any>(null);
    const [settings, setSettings] = useState<any>(null);
    const fetchData = useCallback(() => {
        setItem(props.item)
        setData({ percentage: props.item.percentage })
        setSettings({ barBgColor: props.item.color, })
    }, [setItem, setData, setSettings, props.item])
    useEffect(() => {
        if (props.item) {
            fetchData()
        }
    }, [props.item])
    // Render
    return (
        <>
            { item && 
            <div className="summary-item-founders">
                <span className="summary-item-founders-title">{item.title}</span>
                <div className="summary-item-founders-percent">

                    <ProgressBarPercentageChart2
                        data={data}
                        settings={settings} />
                </div>
            </div>}
        </>

    )
}

export default SummaryItemFounders;
