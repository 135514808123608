
import React from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
// Box
import BoxStartup from "../box/BoxStartup";
// Helper
import DateHelper from "../../helpers/dates/date.helper";


interface StartupInfoProps {
    startup: any
}
const StartupInfo = (props: StartupInfoProps) => {
    // Props
    const { startup } = props;
    const dateHelper = new DateHelper();
    return (
        <Container>
            { startup && <BoxStartup>
                <BoxStartup.Header>
                    <span className="title">Startup Information</span>
                </BoxStartup.Header>
                <BoxStartup.Body>

                    <Row>
                        <Col md="6">
                            <ul className="list-group list-group-flush">
                                <li className="list-group-item">
                                    Name<br />
                                    <span className="font-weight-semi-bold">{startup.name}</span>
                                </li>
                                <li className="list-group-item">
                                    Stage<br />
                                    <span className="font-weight-semi-bold">{startup.stage_text}</span>
                                </li>
                                <li className="list-group-item">
                                    Situation<br />
                                    <span className="font-weight-semi-bold">{startup.situation_text}</span>
                                </li>
                                {startup.business_category_text !=='' &&
                                <li className="list-group-item">
                                    Business Category<br />
                                    <span className="font-weight-semi-bold">{startup.business_category_text}</span>
                                </li>}
                                {startup.business_type_1_text !=='' &&
                                <li className="list-group-item">
                                    Business Type 1<br />
                                    <span className="font-weight-semi-bold">{startup.business_type_1_text}</span>
                                </li>}
                                {startup.business_type_2_text !=='' &&
                                <li className="list-group-item">
                                    Business Type 2<br />
                                    <span className="font-weight-semi-bold">{startup.business_type_2_text}</span>
                                </li>}
                                <li className="list-group-item">
                                    Primary Account<br />
                                    <span className="font-weight-semi-bold">{`${startup.primary_account.first_name} ${startup.primary_account.last_name}, ${startup.primary_account.email}`}</span>
                                </li>
                                <li className="list-group-item">
                                    Created<br />
                                    <span className="font-weight-semi-bold">{dateHelper.format(startup.created)}</span>
                                </li>

                            </ul>
                        </Col>
                    </Row>
                </BoxStartup.Body>
            </BoxStartup >}
        </Container >
    )
}

export default StartupInfo;