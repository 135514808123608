import React, { useCallback, useEffect, useRef, useState } from 'react';
import * as d3 from 'd3';
import useWindowSize from '../../../hooks/useWindowResize';
import MiddleGaugeNeedle from './MiddleGaugeNeedle';
import { v4 as uuid_v4 } from "uuid";
import { CHART_COLOR } from '../../../constants/charts/chart.constants';

export interface SettingsMiddleGaugeChart {
  arcWidth?: number,
  displayInnerArc?: boolean,
  displayLabels?: boolean,

}
interface GaugeChartProps {
  data: any,
  external?: any,
  internal?: any,
  settings?: SettingsMiddleGaugeChart,
  onEndDraw?: Function
}

const MiddleGaugeChart = (props: GaugeChartProps) => {
  // Hook Window
  const windowSize = useWindowSize();
  // Props
  const {
    data: dataProps,
    settings: settingsProps,
    internal,
    external,
    onEndDraw
  } = props;
  // State
  const defaultSettings = {
    arcWidth: 32,
    displayInnerArc: true,
    displayLabels: true,
    internalNeedle: null,
    externalNeedle: null,
  }
  const [data, setData] = useState<any>(null)
  const [settings, setSettings] = useState<any>(null)
  // Size
  const [size, setSize] = useState<any>({ width: 'auto', height: 'auto' })
  // Chart
  const chartEl = useRef<HTMLDivElement>(null);
  const parentEl = useRef<HTMLDivElement>(null);

  const isBoolean = val => Boolean(val) === val;

  // Fetch Data
  const fetchData = useCallback(() => {
    const settings = Object.assign(defaultSettings, {})
    if (settingsProps) {
      if (settingsProps.arcWidth) {
        settings.arcWidth = settingsProps.arcWidth;
      }
      if (typeof settingsProps.displayInnerArc !== 'undefined' && isBoolean(settingsProps.displayInnerArc)) {
        settings.displayInnerArc = settingsProps.displayInnerArc;
      }
      if (typeof settingsProps.displayLabels !== 'undefined' && isBoolean(settingsProps.displayLabels)) {
        settings.displayLabels = settingsProps.displayLabels;
      }

    }
    setSettings(settings);
    setData(dataProps);
  }, [dataProps, settingsProps, setData])

  // Setup Pie
  const setup = (parentEl, chartEl) => {
    // Element width
    const elementSize = parentEl.current.clientWidth;
    // Because is semi arc + 30 (90deg + 30deg)
    const angleEnd = 30;
    // Svg Styles
    const margin = { left: 20, top: 20, right: 20, bottom: 20 };
    const width = Math.min(elementSize, 500) - margin.left - margin.right;
    const height = Math.min((elementSize / 2) + angleEnd, (500 / 2) + angleEnd)
    const radius = width / 2;

    // Renove Svg
    d3.select(chartEl.current).select("svg").remove()
    // Create
    const svg = d3
      .select(chartEl.current)
      .append("svg")
      .attr("width", (width + margin.left + margin.right))
      .attr("height", (height + margin.top + margin.bottom))
      .append("g").attr("class", "wrapper")
      .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (width / 2 + margin.top) + ")")

    return { svg, margin, width, height, radius }
  }
  // Create Donut Chart
  const createDonutChart = (svg, donutData, arc, pie, colorScale, id) => {
    svg.selectAll(".donutArcs")
      .data(pie(donutData))
      .enter().append("path")
      .attr("class", "donutArcs")
      .attr("d", arc)
      // .style('stroke','#000')
      // .style('stroke-width',2.5)
      .style("fill", (d: any, i) => {
        if (i === 7) return "#CCCCCC"; //Other
        if (d.data.color) {
          return d.data.color;
        }
        else return colorScale(i);
      })
      .each((d, i, n) => {
        //Search pattern for everything between the start and the first capital L
        const firstArcSection: any = /(^.+?)L/;

        //Grab everything up to the first Line statement
        var newArc = firstArcSection.exec(d3.select(n[i]).attr("d"))[1];
        //Replace all the comma's so that IE can handle it
        newArc = newArc.replace(/,/g, " ");

        //If the end angle lies beyond a quarter of a circle (90 degrees or pi/2) 
        //flip the end and start position
        if (d.endAngle > 110 * Math.PI / 180) {
          const startLoc: any = /M(.*?)A/,		//Everything between the first capital M and first capital A
            middleLoc: any = /A(.*?)0 0 1/,	//Everything between the first capital A and 0 0 1
            endLoc: any = /0 0 1 (.*?)$/;	//Everything between the first 0 0 1 and the end of the string (denoted by $)
          //Flip the direction of the arc by switching the start en end point (and sweep flag)
          //of those elements that are below the horizontal line
          const newStart = endLoc.exec(newArc)[1];
          const newEnd = startLoc.exec(newArc)[1];
          const middleSec = middleLoc.exec(newArc)[1];

          //Build up the new arc notation, set the sweep-flag to 0
          newArc = "M" + newStart + "A" + middleSec + "0 0 0 " + newEnd;
        }

        //Create a new invisible arc that the text can flow along
        svg.append("path")
          .attr("class", "hiddenDonutArcs")
          .attr("id", `arc-hidden-${id}` + i)
          .attr("d", newArc)
          .style("fill", "none");
      });
  }
  // Create Labels
  const createInnerArcLabels = (svg, pie, donutData, innerRadius, id) => {
    //Append the label names on the outside
    svg
      .selectAll(".donutText")
      .data(pie(donutData))
      .enter().append("text")
      .attr("class", "donutText")
      //Move the labels below the arcs for those slices with an end angle greater than 90 degrees
      .attr("dy", function (d, i) { return (d.endAngle > 110 * Math.PI / 180 ? 18 : -11); })
      .append("textPath")
      .attr("startOffset", "50%")
      .style("text-anchor", "middle")
      .style("fill", (d, i) => {
        if (d.data.textColor) {
          return d.data.textColor;
        }
        return CHART_COLOR.arcLabelColor
      })
      .attr("xlink:href", function (d, i) { return `#arc-hidden-${id}` + i; })
      .text((d: any) => { return d.data.label; });


  }
  // Create inner arc grey
  const createInnerArcGrey = (svg, radius) => {
    // Gutter
    const gutter = settings.arcWidth + 5;
    // Radius
    const outerRadius = (radius * 0.75 + settings.arcWidth) - gutter;
    const innerRadius = (radius * 0.75) - gutter;
    // Create an arc function   
    const arc: any = d3.arc()
      .outerRadius(outerRadius)
      .innerRadius(innerRadius)

    const pie = d3.pie()
      .startAngle(-110 * Math.PI / 180)
      .endAngle(110 * Math.PI / 180)
      .value((d: any) => { return d.value; })
      // .padAngle(.01)
      .sort(null);
    const donutData: any = [{ value: 100 }];
    svg.selectAll(".donutArcsGrey")
      .data(pie(donutData))
      .enter().append("path")
      .attr("class", "donutArcsGrey")
      .attr("d", arc)
      // .style('stroke','#000')
      // .style('stroke-width',2.5)
      .style("fill", (d: any, i) => {
        return CHART_COLOR.arcGrey
      })
  }
  // Create Needle
  const createNeedle = (svg, width, donutData, type) => {
    // Needle from -0.05 to 0.55
    const needle = new MiddleGaugeNeedle(svg, width)
    needle.render(type)
    const factor = (0.6 / donutData.length);
    let moveTo = 0;
    const findSelected = donutData.findIndex(d => d.selected);
    if (findSelected >= 0) {
      // Item
      const item = donutData[findSelected];
      // Location of item
      let itemLocation = findSelected * factor
      // If Has part value the factor divide by 4
      // console.log('item.partValue', item.partValue)
      if (typeof item.partValue !== 'undefined') {
        const factorDivision = item.factorDivision || 4
        const offest = 0;
        moveTo = itemLocation - 0.05 + ((factor / factorDivision) * (item.partValue - offest))
      } else {
        moveTo = itemLocation;
      }
    }
    needle.moveTo(moveTo);
  }
  // Add logic to draw the chart here
  const drawChart = useCallback(() => {
    if (chartEl && chartEl.current && data.length > 0) {
      const id = uuid_v4();
      // Setup
      const { svg, width, height, radius, margin } = setup(parentEl, chartEl);
      setSize({ width: `${width + margin.left + margin.right}px`, height: `${height + margin.bottom + margin.top}px` })
      // Prepare Data
      const donutData: any = [...data]
      // Create a color scale
      const colorScale = d3.scaleLinear()
        .domain([1, 3.5, 6])
        .range(["#2c7bb6", "#ffffbf", "#d7191c"] as any)
      // .interpolate(d3.interpolateHcl());
      // Radius
      const outerRadius = radius * 0.75 + settings.arcWidth;
      const innerRadius = radius * 0.75;
      // Create an arc function   
      const arc: any = d3.arc()
        .outerRadius(outerRadius)
        .innerRadius(innerRadius)

      const pie = d3.pie()
        .startAngle(-110 * Math.PI / 180)
        .endAngle(110 * Math.PI / 180)
        .value((d: any) => { return d.value; })
        // .padAngle(.01)
        .sort(null);

      // Create Donut Char
      createDonutChart(svg, donutData, arc, pie, colorScale, id);
      // Create Donut Char
      if (settings.displayInnerArc) {
        createInnerArcGrey(svg, radius);
      }
      // Create Labels
      if (settings.displayLabels) {
        createInnerArcLabels(svg, pie, donutData, innerRadius, id);
      }
      // Default needle
      createNeedle(svg, width, donutData, 'default')
      // Internal
      if (internal) {
        createNeedle(svg, width, internal, 'internal')
      }
      // External
      if (external) {
        createNeedle(svg, width, external, 'external')
      }
      if (onEndDraw) {
        onEndDraw()
      }

    }
  }, [chartEl, parentEl, settings, data, setSize, internal, external])

  // On Change Props
  useEffect(() => {
    if (dataProps && dataProps !== data && settings !== settingsProps) {
      fetchData()
    }
  }, [dataProps, settingsProps, fetchData, data]);
  // Resize when call third time (first undefined, second with window value)
  useEffect(() => {
    if (windowSize.width && data && settings) {
      drawChart();
    }
  }, [windowSize,
    data,
    settings,
    internal,
    external,
    drawChart]);


  return (
    <div ref={parentEl} className="startup-middle-gauge-chart">
      <div ref={chartEl} className="startup-middle-gauge-chart-container" style={{ width: size.width, height: size.height }}></div>
    </div>
  )
}

export default MiddleGaugeChart;