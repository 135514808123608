import React, { useState, useEffect, useImperativeHandle, useCallback } from 'react'
// Core
import Button from '../buttons/Button';
// Bootstrap
import Modal from 'react-bootstrap/Modal'
// Form
import { useForm, FormProvider } from "react-hook-form";
// Toastify
import { toast } from 'react-toastify';
import CreateChallengeForm from './CreateChallengeForm';
import CreateTestForm from './CreateTestForm';
import { useApolloClient } from 'react-apollo';
import { CREATE_CHALLENGE, UPDATE_CHALLENGE } from '../../queries/challenges/challenge.query';
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getSubElement } from "../../redux/ducks/dashboard-duck";
import { challengeCreate, challengeCreateFromTemplate, challengeToUpdate, challengeToView } from "../../redux/ducks/challenge-duck";
import ObjectHelper from '../../helpers/objects/object.helper';
import { CHALLENGE_NUMBER_FIELDS } from '../../constants/challenges/challenges.constants';
import FinishChallengeForm from './FinishChallengeForm';

interface ModalAddChallengeProps {
    onClose?: Function,
    onSave?: Function,
    location: string
}

const ModalAddChallenge = (props: ModalAddChallengeProps, ref) => {
    // Modal Action
    const [modalAction, setModalAction] = useState('create');
    // Helper
    const objectHelper = new ObjectHelper();
    // Props
    const { location } = props;
    // Dispatch
    const dispatch = useDispatch();
    // Type
    const type = useSelector(getSubElement)
    // Client
    const client = useApolloClient();
    // Loading 
    const [loading, setLoading] = useState(false);
    // Show
    const [show, setShow] = useState(false);
    // Template
    const [template, setTemplate] = useState<any>(null);
    const [templateBeforeFinish, setTemplateBeforeFinish] = useState<any>(null);
    // Form
    const methods = useForm({
        mode: 'onChange'
    });
    // Close
    const handleClose = () => {
        setShow(false);
        // Reset 
        dispatch(challengeCreateFromTemplate(null))
        dispatch(challengeToUpdate(null))
        dispatch(challengeToView(null));
        setTemplate(null)
        setTemplateBeforeFinish(null)
        setModalAction('create')
    }
    // Save Fn
    const saveFn = async () => {
        let challenge: any = null;
        setLoading(true);
        try {
            const values = methods.getValues();
            const data = {
                ...values.challenge,
                ...values.test,
                type_sku: type
            }
            if (location !== 'sub-element') {
                data.type_sku = template.type_sku
            }
            // Template
            if (modalAction === 'template') {
                data.template_id = template.id
            }
            // Update
            if (modalAction === 'update') {
                data.id = template.id
                if (data.files_input && data.files_input.length > 0) {
                    data.files_input = data.files_input.filter((f) => !f.id)
                }
            }
            // Parse Int Fields
            objectHelper.parseFieldToInt(data, CHALLENGE_NUMBER_FIELDS)
            // Mutate
            console.log('modalAction', modalAction);
            console.log('data', data);
            const response = await client.mutate({
                mutation: modalAction === 'update' ? UPDATE_CHALLENGE : CREATE_CHALLENGE,
                variables: { data: data }
            })
            console.log('response', response)
            challenge = modalAction === 'update' ? response.data.updateChallenge || null : response.data.createChallenge || null

        } catch (e) {
            console.log(e)
        } finally {
            setLoading(false);
        }

        return challenge

    }
    // Save
    const handleSave = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.formState.errors);
        if (keys.length === 0) {
            const challenge = await saveFn()
            console.log('challenge created', challenge)
            if (challenge && challenge.id) {
                if (props.onSave) {
                    props.onSave(challenge)
                }
                // Dispatch
                dispatch(challengeCreate(challenge))
                // Message Success
                if (modalAction === 'update') {
                    toast.success('Challenge updated succefully')
                } else {
                    toast.success('Challenge created succefully')
                }

                // Close Modal
                handleClose();

            } else {
                toast.error('Opps...Error ocurred. Try later...')
            }
        } else {
            toast.error('Check the required fields.')
        }
    }
    const resetForm = useCallback(() => {
        methods.reset();
        methods.setValue('test.metric_type_sku', 'kpi')
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [methods.reset, methods.setValue])

    // Show
    useEffect(() => {
        if (!show) {
            resetForm()
        }
    }, [show, resetForm])
    // Create Action
    const createAction = () => {
        setShow(true)
        setModalAction('create');
    }
    // Update Action
    const updateAction = (template) => {
        setShow(true);
        setModalAction('update');
        setTimeout(() => {
            setTemplateValues(template)
        }, 100)
    }
    // View Action
    const viewAction = (template) => {
        setShow(true);
        setModalAction('view');
        setTimeout(() => {
            setTemplateValues(template)
        }, 100)
    }
    // Create From Template
    const createFromTemplateAction = (template) => {
        setShow(true);
        setModalAction('template');
        setTimeout(() => {
            setTemplateValues(template)
        }, 100)
    }
    // Set Template Value (when edit use template)
    const setTemplateValues = (template) => {
        // Challenge
        methods.setValue('challenge.title', template.title);
        methods.setValue('challenge.description', template.description);
        methods.setValue('challenge.priority_level_sku', template.priority_level_sku)
        methods.setValue('challenge.testability_sku', template.testability_sku)
        methods.setValue('challenge.blocking', template.blocking)
        methods.setValue('challenge.critical', template.critical)
        // Set Kpi Data
        if (template.metric_type_sku === 'kpi') {
            methods.setValue('test.kpi_metric', template.kpi_metric);
            methods.setValue('test.kpi_metric_target', template.kpi_metric_target);
            methods.setValue('test.kpi_metric_current', template.kpi_metric_current);
        }
        // Set Values custom forms
        setTemplate({ ...template })
    }
    // Export
    useImperativeHandle(ref, () => {
        return {
            create: () => createAction(),
            view: (template) => viewAction(template),
            update: (template) => updateAction(template),
            createFromTemplate: (template) => createFromTemplateAction(template),
        }
    });
    // Complete
    // Handle Complete Challenge
    const handleCompleteChallenge = () => {
        const values = methods.getValues();
        const templateBeforeFinish = {
            ...template,
            ...values.challenge,
            ...values.test,
        }
        delete templateBeforeFinish.__typename;
        setTemplateBeforeFinish(templateBeforeFinish);
        // console.log('templateBeforeFinish', templateBeforeFinish)
        setModalAction('finish')
    }
    // Finish
    const onClickNoFinish = () => {
        // console.log('values.challenge', templateBeforeFinish)
        updateAction({
            ...templateBeforeFinish
        })
    }
    // Handle Finish Challenge
    const handleFinishChalenge = async () => {
        let challengeUpdated: any = null;
        setLoading(true);
        try {
            // Set Data To Updated
            const toUpdate: any = {
                ...templateBeforeFinish,
                id: template.id,
                complete: true

            }
            delete toUpdate.type;
            delete toUpdate.status_sku;
            delete toUpdate.documents;
            // console.log('toUpdate', toUpdate)

            objectHelper.parseFieldToInt(toUpdate, CHALLENGE_NUMBER_FIELDS)
            if (template.metric_type_sku === 'progress') {
                toUpdate.progress = 100;
            } else {
                toUpdate.kpi_metric_current = template.kpi_metric_target;
            }
            // Mutate
            const response = await client.mutate({
                mutation: UPDATE_CHALLENGE,
                variables: { data: toUpdate }
            })
            // Get Response
            challengeUpdated = response.data.updateChallenge

        } catch (e) {
            console.log('Error modal when update', e)
        } finally {
            setLoading(false);
        }
        // Return
        return challengeUpdated

    }

    const onClicYesFinish = async () => {
        const challengeUpdated: any = await handleFinishChalenge();
        if (challengeUpdated && challengeUpdated.id) {
            // Message
            toast.success('Challenge was succefully completed!')
            // Dispatch
            dispatch(challengeCreate(challengeUpdated))
            // Close
            handleClose();
        } else {
            toast.error('Opps...Error ocurred. Try later...')
        }

    }
    return (
        <>
            <FormProvider {...methods}>
                <Modal
                    show={show}
                    onHide={handleClose}
                    enforceFocus={false}
                    restoreFocus={false}
                    backdrop={'static'}
                    id={'modal-add-challenge'}
                >
                    <Modal.Header>
                        <Modal.Title>
                            {modalAction === 'create' && <span> Add Challenge</span>}
                            {modalAction === 'template' && <span> Add Challenge Proposed</span>}
                            {modalAction === 'update' && <span>Update Challenge</span>}
                            {modalAction === 'finish' && <span>Complete Challenge</span>}
                            {modalAction === 'view' && <span>View Challenge</span>}

                        </Modal.Title>
                    </Modal.Header>
                    <Modal.Body >
                        {modalAction !== 'finish' &&
                            <>
                                <CreateChallengeForm
                                    template={template}
                                    modalAction={modalAction}
                                    readOnly={modalAction === 'view'}
                                />
                                <CreateTestForm
                                    template={template}
                                    readOnly={modalAction === 'view'}

                                />
                            </>
                        }
                        {modalAction === 'finish' &&
                            <FinishChallengeForm
                                onClickNo={onClickNoFinish}
                                onClickYes={onClicYesFinish}
                                loading={loading}
                            />
                        }
                    </Modal.Body>
                    {modalAction !== 'finish' && <Modal.Footer>
                        {(modalAction === 'update') &&
                            <div className="d-flex w-100">
                                <Button
                                    className="btn-violet mr-auto"
                                    onClick={handleCompleteChallenge}>
                                    Complete Challenge
                                </Button>
                                <Button
                                    disabled={loading}
                                    variant="light"
                                    onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={loading}
                                    loading={loading}
                                    loadingText={'Saving'}
                                    onClick={handleSave}
                                    className="btn-violet">
                                    Update
                                </Button>
                            </div>
                        }
                        {(modalAction === 'create' || modalAction === 'template') &&
                            <div >
                                <Button
                                    disabled={loading}
                                    variant="light"
                                    onClick={handleClose}>
                                    Cancel
                                </Button>
                                <Button
                                    disabled={loading}
                                    loading={loading}
                                    loadingText={'Saving'}
                                    onClick={handleSave}
                                    className="btn-violet">
                                    Create
                                </Button>
                            </div>
                        }
                        {modalAction === 'view' &&
                            <div >
                                <Button
                                    disabled={loading}
                                    variant="light"
                                    onClick={handleClose}>
                                    Close
                                </Button>
                            </div>
                        }
                    </Modal.Footer>}
                </Modal>
            </FormProvider>
        </>
    )
}

export default React.forwardRef(ModalAddChallenge);