import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";
// Helper
import DateHelper from "../../helpers/dates/date.helper";
import StartupHelper from "../../helpers/startup/startup.helper";
// Props
interface ReportsTableProps {
    startups: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
    onClickDeleteItem?: Function,
    onClickDashboardItem?: Function,
    onClickExternalItem?:Function,
    onClickViewExternalItem?:Function
}
// Reports Table
const StartupTable = (props: ReportsTableProps) => {
    // Props
    const { startups, loading, onClickViewItem, 
            onClickDeleteItem, emptyString, onClickDashboardItem, 
            onClickExternalItem, onClickViewExternalItem } = props;
    // Helpers
    const dateHelper = new DateHelper()
    const startupHelper = new StartupHelper();
    // On Click View
    const onClickView = (startup) => {
        if (onClickViewItem) {
            onClickViewItem(startup);
        }
    }
    const onClickDelete = (startup) => {
        if (onClickDeleteItem) {
            onClickDeleteItem(startup);
        }
    }
    const onClickDashboard = (startup) => {
        if (onClickDashboardItem) {
            onClickDashboardItem(startup)
        }
    }
    const onClickExternal = (startup) => {
        if (onClickExternalItem) {
            onClickExternalItem(startup)
        }
    }
    const onClickViewExternal = (startup) =>{
        if (onClickViewExternalItem) {
            onClickViewExternalItem(startup)
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-dashboard'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Copy url Dashboard
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-dashboard'}
                        className="btn btn-outline-dark btn-rounded-action action-table"
                        onClick={() => onClickDashboard(row)}
                    >
                        <i className="fas fa-tachometer-alt"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            View Details
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-eye"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-trash'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-trash`}>
                            Delete Startup
                        </Tooltip>
                    }>
                    <button type="button"
                        className="btn btn-outline-danger btn-rounded-action action-table"
                        onClick={() => onClickDelete(row)}>
                        <i className="far fa-trash-alt"></i>
                    </button>
                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-invitation'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-invitation`}>
                            Create External Link Invitation
                        </Tooltip>
                    }>
                    <button type="button"
                        className="btn btn-outline-secondary btn-rounded-action action-table"
                        onClick={() => onClickExternal(row)}>
                        <i className="fa fa-link"></i>
                    </button>
                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-view-invitation'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-invitation`}>
                            View External Link
                        </Tooltip>
                    }>
                    <button type="button"
                        className="btn btn-outline-info btn-rounded-action action-table"
                        onClick={() => onClickViewExternal(row)}>
                        <i className="far fa-envelope"></i>
                    </button>
                </OverlayTrigger>
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {

            dataField: 'stage_text',
            text: 'Stage',
            sort: true,
        },
        {

            dataField: 'situation_text',
            text: 'Situation',
            sort: true,
        },
        {

            dataField: 'name',
            text: 'Name',
            sort: true,
        },
        {

            dataField: 'created_at',
            text: 'Created',
            sort: true,
            formatter: cell => dateHelper.format(cell),
            sortValue: cell => dateHelper.sortValue(cell)
        },
        {

            dataField: 'type',
            text: 'Academy',
            sort: true,
            formatter: cell => startupHelper.formatType(cell),
            sortValue: cell => startupHelper.formatType(cell)
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
            // headerStyle: { minWidth: 'px' }
        }
    ];


    return (
        <>
            <DefaultTable
                data={startups}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'created_at',
                        order: 'desc'
                    }
                }
            />
        </>
    )
}

export default StartupTable;