import React from 'react';
import BestPracticeItem from './BestPracticeItem';

interface FileListProps {
    files: Array<any>,
    emptyString?: string,
}

const BestPracticeList = (props: FileListProps) => {
    // Props
    const { files, emptyString } = props;

    return (
        <div className="best-practice-list" >
            {/* Loading */}
            {!files &&
                <div className="best-practice-item-empty">
                    <span>Loading...</span>
                </div>
            }
            {/* Has Files */}
            {files && files.length > 0 && files.map((file, index) => (
                <BestPracticeItem file={file} key={index} />
            ))}
            {/* Empty */}
            {files && files.length === 0 &&
                <div className="best-practice-item-empty">
                    <span>{emptyString ? emptyString : 'No Files'}</span>
                </div>
            }
        </div>
    );
};
export default BestPracticeList;