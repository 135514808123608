
import React, { useEffect, useCallback, useState } from "react";
// History
import { useHistory } from "react-router-dom";
// Svg
import { ReactSVG } from "react-svg";
// Button
import Button from "../../buttons/Button";
// Item
import QuestionPaginationItemRefined from "./QuestionPaginationItemRefined";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    getQuestionnaireGuestQuestionsPageRefinedScoring,
    updateAdvanceGuest,
    updateQuestionsPageRefinedScoringGuest,
    setAnswersRefinedScoringGuest
} from '../../../redux/ducks/questionnaire-guest-duck';
// Form
import { useForm, FormProvider } from "react-hook-form";


// Interface
interface QuestionsPaginationRefinedProps {
    questions: any,
}
const QuestionsPaginationRefined = (props: QuestionsPaginationRefinedProps) => {
    // Form
    const methods = useForm({
        mode: 'onChange'
    })
    // Redux
    const dispatch = useDispatch();
    const questionsPage = useSelector(getQuestionnaireGuestQuestionsPageRefinedScoring)
    // History
    const history = useHistory();
    // Arrow
    const arrowRight = require("../../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Pagination
    const [questionsPagination, setQuestionsPagination] = useState<any>([]);
    const [currentPage, setCurrentPage] = useState<any>(1);

    // Props
    const { questions } = props;
    // Fetch Questions
    const fetchQuestions = useCallback(() => {
        setQuestionsPagination(questions)
    }, [setQuestionsPagination, questions])
    // Fetch Current Page
    const fetchCurrentPage = useCallback(() => {
        setCurrentPage(questionsPage)
    }, [setCurrentPage, questionsPage])
    // Next
    const onClickNext = () => {
        const next = currentPage + 1;
        const data = methods.getValues();
        console.log('onClickNext', data)
        dispatch(setAnswersRefinedScoringGuest(data.refinedScoring))
        if (next > questionsPagination.length) {
            history.push(`/questionnaire-guest/intro/done`)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPageRefinedScoringGuest(next))
        }

    }
    // Back
    const onClickBack = () => {
        const next = currentPage - 1;
        const data = methods.getValues();
        console.log('onClickBack', data)
        dispatch(setAnswersRefinedScoringGuest(data.refinedScoring))
        if (next === 0) {
            history.push(`/questionnaire-guest/intro/first-questions/`)
        } else {
            window.scrollTo(0, 0)
            setCurrentPage(next);
            dispatch(updateQuestionsPageRefinedScoringGuest(next))
        }

    }
    // Init Questions
    useEffect(() => {
        if (questions) {
            fetchQuestions()
        }
    }, [fetchQuestions, questions])
    // Change Advance
    useEffect(() => {
        const advance = (currentPage * 1.4) + ( 36 * 1.4)
        // Update Redux
        dispatch(updateAdvanceGuest(
            advance
        ))
    }, [currentPage])
    // Fetch questions Page
    useEffect(() => {
        if (questionsPage !== currentPage) {
            fetchCurrentPage()
        }
    }, [questionsPage, currentPage, fetchCurrentPage])

    return (
        <>
            <FormProvider {...methods}>
                {
                    questionsPagination &&
                    questionsPagination[currentPage - 1] &&
                    <QuestionPaginationItemRefined
                        key={questionsPagination[currentPage - 1].id}
                        question={questionsPagination[currentPage - 1]}
                    />
                }
            </FormProvider>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}>
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    onClick={onClickNext}>
                    Next
                    <ReactSVG src={arrowRight} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </>
    )
}

export default QuestionsPaginationRefined;