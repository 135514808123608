import React from 'react';
import TrackItem from '../tracks/TrackItem';
import MyChallengeListItem from './MyChallengeListItem';


interface MyChallengeListProps {
    challenges: any,
    tracks: any,
    hideTemplates: boolean
}

const MyChallengeList = (props: MyChallengeListProps) => {
    // Props
    // States
    const { challenges, tracks, hideTemplates} = props

    const ids = challenges ? challenges.map(c => c.template_id) : [];
    return (
        // style={{ height: heightList }}
        <div className="my-challenge-list" >

            {!hideTemplates && tracks && tracks 
            .map((track) => {
                
                return (
                    <TrackItem
                        track={track}
                        key={track.id}
                        ids={ids}
                        challengeTemplates={track.challenge_templates}
                    />
                )
            })}

            {challenges && challenges.length > 0 &&
                challenges.map((challenge, index) => {

                    return (
                        <MyChallengeListItem
                            challenge={challenge}
                            key={index}
                            template={false}
                        />
                    )
                })}
            {challenges && challenges.length === 0 && ((tracks && tracks.length === 0) || hideTemplates) &&
                <div className={`my-challenge-list-item-empty`}>
                    No Challenges.
                    </div>
            }
            {!challenges &&
                <div className={`my-challenge-list-item-empty`}>
                    Loading Challenges...
                    </div>
            }

        </div>
    );
};
export default MyChallengeList;