
import { useLastLocation } from "react-router-last-location";

export const useLastLocationQueryParams = (route?: string) => {
    let queryParams = '';

    const lastLocation = useLastLocation();

    if (lastLocation) {
        if (route) {
            if (lastLocation?.pathname.includes(route)) {
                if (lastLocation.search) {
                    queryParams = lastLocation.search;
                }
            }
        } else {
            if (lastLocation.search) {
                queryParams = lastLocation.search;
            }
        }
    }

    return queryParams;
}