
/*eslint-disable*/
import React, { useRef, useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import Button from "../buttons/Button";
// Modals
import ModalAddChallenge from "../challenges/ModalAddChallenge";
import MyChallengeList from "../challenges/MyChallengeList";
// Redux
import { useSelector } from 'react-redux';
import {
    getChallengeToUpdate,
    getChallengeTemplate,
    getChallengeToView
} from "../../redux/ducks/challenge-duck";
import ModalDeleteChallenge from "../challenges/ModalDeleteChallenge";

// Props
interface BoxMyChallengesProps {
    title?: string,
    challenges: Array<any>,
    tracks: Array<any>,
    subElement?: any
}


const BoxMyChallenges = (props: BoxMyChallengesProps) => {
    // Challenge to view
    const challengeToView = useSelector(getChallengeToView);
    // Challenge to update
    const challengeToUpdate = useSelector(getChallengeToUpdate);
    // Challenge Template 
    const challengeTemplate = useSelector(getChallengeTemplate)
    // States
    const [hideProposed, setHideProposed] = useState(false);
    // Title
    const { title, tracks, challenges, subElement } = props;
    // Modal Ref
    const modalAddChallenge: any = useRef(null);
    // On Click Add
    const onClickAdd = () => {
        modalAddChallenge.current.create()
    }
    // On  Click Update
    const onClickUpdateChallenge = useCallback(() => {
        // console.log('challengeToUpdate', challengeToUpdate)
        const toUpdate = {
            ...challengeToUpdate,
            due_date: challengeToUpdate.due_date ? new Date(challengeToUpdate.due_date) : null
        }
        modalAddChallenge.current.update(toUpdate)

    }, [modalAddChallenge, challengeToUpdate])
    // On  Click View
    const onClickViewChallenge = useCallback(() => {
        // console.log('challengeToUpdate', challengeToUpdate)
        const toView = {
            ...challengeToView,
            due_date: challengeToView.due_date ? new Date(challengeToView.due_date) : null
        }
        modalAddChallenge.current.view(toView)

    }, [modalAddChallenge, challengeToView])
    // On Click create from template
    const onClickCreateFromTemplate = useCallback(() => {
        modalAddChallenge.current.createFromTemplate(challengeTemplate);

    }, [modalAddChallenge, challengeTemplate])
    // View
    useEffect(() => {
        if (challengeToView && challengeToView.id) {
            onClickViewChallenge()
        }
    }, [challengeToView, onClickViewChallenge])
    // Update
    useEffect(() => {
        if (challengeToUpdate && challengeToUpdate.id) {
            onClickUpdateChallenge()
        }
    }, [challengeToUpdate, onClickUpdateChallenge])
    // Create From Template
    useEffect(() => {
        if (challengeTemplate && challengeTemplate.id) {
            onClickCreateFromTemplate()
        }
    }, [challengeTemplate])
    // When Chaneg Sub Element Reset
    const resetData = useCallback(() => {
        setHideProposed(false)
    }, [setHideProposed])
    useEffect(() => {
        resetData();
    }, [subElement])


    // Render
    return (
        <>
            {/* Add */}
            <ModalAddChallenge
                location={'sub-element'}
                ref={modalAddChallenge}
            />        
            {/* Delete */}
            <ModalDeleteChallenge />
            <BoxStartup className={'h-auto'} id="box-my-challenges">
                <BoxStartup.Header>
                    <Row>
                        <Col lg="6">
                            <span className="title">
                                {title ? title : 'My Challenges'}
                            </span>
                        </Col>
                        <Col lg="6" className={'d-flex justify-content-lg-end mt-2 mt-lg-0'}>
                            <Form.Check
                                custom
                                type={'checkbox'}
                                className={'custom-checkbox-sm  text-color-dark'}
                                id={`header-my-challenges-hide-proposed`}
                                label={`Hide Proposed challenges`}
                                name="hide-proposed"
                                value={'hide-proposed'}
                                checked={hideProposed}
                                onChange={(event: any) => {
                                    const { checked } = event.target;
                                    setHideProposed(checked)
                                }}
                            />
                            <Button size="sm" className="btn-violet" onClick={onClickAdd}>Create challenge</Button>
                        </Col>
                    </Row>

                </BoxStartup.Header>
                <BoxStartup.Body className="p-0">
                    <MyChallengeList
                        tracks={tracks}
                        challenges={challenges}
                        hideTemplates={hideProposed}
                    />
                </BoxStartup.Body>
            </BoxStartup >
        </>

    )
}

export default BoxMyChallenges;
