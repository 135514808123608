import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { ReactSVG } from 'react-svg';
import InvitationItem from './InvitationItem';
// Props
interface GuestItemProps {
    group: any,
    onClickDeleteInvitation: Function
}
// Guest Item
const GroupInvitationsAdmin = (props: GuestItemProps) => {
    const { group,  onClickDeleteInvitation: onClickDeleteInvitationProps } = props;

    const arrow = require("../../assets/img/challenges/arrow-right.svg").default;
    // Collapse
    const [collapsed, setCollapsed] = useState(true)
    
    const onClickDeleteInvitation = (invitation) => {
        onClickDeleteInvitationProps(invitation)
    }
    const toggleCollapsed = (e) => {
        e.preventDefault();
        setCollapsed(!collapsed)
    }

    const drawGroup = () => {
        return (
            <>
                {/* Guest */}
                <div className={`guest-item-wrapper ${collapsed ? '' : 'collapse-show'}`}>
                    <Row>
                        <Col xs="1">
                            <span className="collapse-btn" onClick={toggleCollapsed}>
                                <ReactSVG src={arrow} wrapper="span" className="arrow-right" />
                            </span>
                        </Col>
                        <Col xs="11">
                            <span className="guest-item-name">{group.name}</span>
                        </Col>
                    </Row>
                </div>
                {/* Accordion */}
                <div className={`guest-item-accordion ${collapsed ? 'collapse-hidden' : ''}`}>
                    {group.invitations.map((invitation) => (
                        <InvitationItem invitation={invitation} key={invitation.id} 
                            onClickDelete={onClickDeleteInvitation} />
                    ))}
                </div>
            </>
        )
    }
    return (
        <div className={`guest-item group-admin-item `}>
            {drawGroup()}
        </div>
    )
}

export default GroupInvitationsAdmin;