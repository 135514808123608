
import React, { useState } from "react";
import { Redirect, Route, Switch } from "react-router-dom";
// core components
import Page from "../components/page/Page";
import QuestionNavbar from "../components/navbars/QuestionsNavbar";
import QuestionFooter from "../components/footers/QuestionFooter";
// Routes
import routes from "../routes";


const Question = (props: any) => {
  document.body.classList.remove('with-sidebar');
  window.scrollTo(0, 0)

  const [brandName, setBrandName]: any = useState(null);

  const onSetBrandText = (title: string) => {
    setBrandName(title)
    console.log(brandName);
  };

  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.layout === "/questionnaire") {
        if (prop.hasSubmenu) {
          const submenu = prop.submenus.map((submenu: any, submenuKey: any) => {
            return (<Route
              path={submenu.layout + submenu.path}
              key={submenuKey}
              render={props => (
                <Page {...submenu}
                  component={submenu.component}
                  title={'34 Elements | ' + submenu.name}
                  name={submenu.name}
                  onSetBrandText={onSetBrandText}
                />
              )}
            />)
          });
          // console.log(submenu);
          return submenu;
        } else {
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              render={props => (
                <Page {...props} component={prop.component} title={'Startup | ' + prop.name} name={prop.name} onSetBrandText={onSetBrandText} />
              )}

            />
          );
        }
      } else {
        return null;
      }
    });
  };


  return (
    <React.Fragment>
      <div className="main-content-questions">
        {/* Navbar */}
        <QuestionNavbar />
        {/* Main Container */}
        <div className="main-container">
          <Switch>
            {getRoutes(routes)}
            {/* Default */}
            <Redirect from="*" to="/questionnaire-public/answer" />
          </Switch>
        </div>
        {/* Footer */}
        <QuestionFooter />
      </div>
    </React.Fragment>
  );

}
export default Question;