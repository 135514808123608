// Auth
import Login from "./views/auth/Login";
import RecoverPassword from "./views/auth/RecoverPassword";
import RestorePassword from "./views/auth/RestorePassword"
import ConfirmAccount from "./views/auth/ConfirmAccount";
import Register from "./views/auth/Register";
import RegisterGuest from "./views/auth/RegisterGuest"
import RegisterNoLimitedView from "./views/auth/RegisterNoLimitedView";

const routesAuth = [
  // Auth

  {
    path: "/diagnostic",
    name: "Register",
    icon: "ni ni-key-25",
    component: Register,
    layout: "/auth",
    key: 'diagnostic'
  },
  {
    path: "/academy",
    name: "Register Academy",
    icon: "ni ni-key-25",
    component: RegisterGuest,
    layout: "/auth",
    key: 'academy'
  },
  {
    path: "/register",
    name: "Register",
    icon: "ni ni-key-25",
    component: RegisterNoLimitedView,
    layout: "/auth",
    key: 'register'
  },
  {
    path: "/login",
    name: "Login",
    icon: "ni ni-key-25",
    component: Login,
    layout: "/auth",
    key: 'login'
  },
  {
    path: "/recover-password",
    name: "Recover Password",
    icon: "ni ni-key-25",
    component: RecoverPassword,
    layout: "/auth",
    key: 'recover-password'
  },
  {
    path: "/restore-password/:token",
    name: "Restore Password",
    icon: "ni ni-key-25",
    component: RestorePassword,
    layout: "/auth",
    key: 'restore-password'
  },
  {
    path: "/confirm-account/:token",
    name: "Cofirmar Cuenta",
    icon: "ni ni-key-25",
    component: ConfirmAccount,
    layout: "/auth",
    key: 'confirm-account'
  },





];
export default routesAuth;
