import React, { useState, useEffect, useCallback } from 'react'
// Core
import Button from '../buttons/Button';
// Bootstrap
import Modal from 'react-bootstrap/Modal'
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { adviceToShow, getAdviceToShow } from '../../redux/ducks/advice-duck';

// Modal Advice
const ModalAdvice = (props) => {
    // Dispatch
    const dispatch = useDispatch();
    // Selector
    const advice = useSelector(getAdviceToShow);    
    // Loading 
    const [loading] = useState(false);
    // Show
    const [show, setShow] = useState(false);
    // Fetch Show
    const fetchShow = useCallback(() => {
        if (advice && advice.id) {
            setShow(true);
        } else {
            setShow(false);
        }

    }, [setShow, advice])
    // Close
    const handleClose = () => {
        dispatch(adviceToShow(null))
    }


    // Show
    useEffect(() => {
        fetchShow()
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [advice])

    return (
        <>

            <Modal
                show={show}
                onHide={handleClose}
                enforceFocus={true}
                backdrop={'static'}>
                <Modal.Header>
                    <Modal.Title>{advice ? advice.title : ''}</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div className="modal-body-text" dangerouslySetInnerHTML={{__html: advice ? advice.description : ''}}></div>                  
                </Modal.Body>
                <Modal.Footer>
                    <Button
                        disabled={loading}
                        variant="light"
                        onClick={handleClose}>
                        Close
                        </Button>             
                </Modal.Footer>
            </Modal>
        </>
    )
}

export default ModalAdvice;