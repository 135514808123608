import React from 'react';

interface ChallengeItemEmptyProps {
    title: string
}

const ChallengeItemEmpty = (props: ChallengeItemEmptyProps) => {

    return (
        <div className="challenge-item">
            <span className="title-empty">{props.title}</span>
        </div>
    );
};
export default ChallengeItemEmpty;