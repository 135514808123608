import React, { useEffect, useCallback, useState } from "react";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionnaireGuestQuestions, setQuestionnaireGuestPage } from '../../redux/ducks/questionnaire-guest-duck';
import QuestionsPaginationRefined from "./question-pagination-refined-scoring/QuestionsPaginationRefined";



const QuestionnaireGuestSecondQuestions = (props) => {
    //  Dispatch
    const dispatch = useDispatch()
    // Questions Store
    const questionsStore = useSelector(getQuestionnaireGuestQuestions)
    const [questions, setQuestions] = useState<any>([])
    const fetchData = useCallback(() => {
        setQuestions(questionsStore);
    }, [setQuestions, questionsStore])

    useEffect(() => {
        if (questionsStore !== questions) {
            fetchData()
        }
    }, [fetchData, questionsStore, questionsStore])

    useEffect(()=>{
        dispatch(setQuestionnaireGuestPage('second-questions'))
    },[])
    return (
        <>
            <QuestionsPaginationRefined questions={questions} />
        </>
    )
}

export default QuestionnaireGuestSecondQuestions;