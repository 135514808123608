

export default class FileHelper {
    getBase64(file: File) {
        console.log(file);
        return new Promise((resolve, reject) => {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
                resolve(reader.result);
            };
            reader.onerror = (error) => {
                console.log('Error: ', error);
                reject(error);
            };
        });
    }
    resolveIcon(file) {
        let icon = 'link';
        if (file.mime.includes('pdf')) {
            icon = 'pdf';
        }
        else if (file.mime.includes('mp4')) {
            icon = 'mp4';
        }
        else if (file.mime.includes('txt')) {
            icon = 'txt';
        }
        else if (file.mime.includes('key')) {
            icon = 'key';
        }
        else if (file.mime.includes('xls')) {
            icon = 'xls';
        }
        else if (file.mime.includes('image')) {
            icon = 'img';
        }

        return icon
    }

}
