
import React, { useEffect, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
// Button
import Button from "../buttons/Button";
// History
import { useHistory } from "react-router-dom";
// SVG
import { ReactSVG } from "react-svg";
// Redux
import { useDispatch } from 'react-redux';
import { resetQuestionnaireGuest, setQuestionnaireGuestPage, updateAdvanceGuest } from "../../redux/ducks/questionnaire-guest-duck";
// Toast
import { toast } from "react-toastify";
// Hooks
import useGuestAnswer from "../../hooks/guest/useGuestAnswer";
import useGuestUpdate from "../../hooks/guest/useGuestUpdate";
import { GUEST_FINISH_ANSWERED } from "../../queries/guests/guests.query";


const QuestionnaireGuestDone = (props) => {
    // Loading
    const [loading, setLoading] = useState(false);
    // Client
    const client = useApolloClient();
    // dispatch
    const dispatch = useDispatch();
    // Startup
    const answerInput = useGuestAnswer();
    const guestUpdate = useGuestUpdate();
    // History
    const history = useHistory();
    // Arrow
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Back
    const onClickBack = () => {
        history.push(`/questionnaire-guest/intro/second-questions`)
    }
    // Finish
    const onClickFinish = async () => {
        setLoading(true)
        
        try {
            // Update Guest
            const guestInput = {
                ...guestUpdate,
                answered: true
            }
            const responseGuest = await client.mutate({
                mutation: GUEST_FINISH_ANSWERED,
                variables: { data: guestInput },
                fetchPolicy: 'no-cache'
            })
            const guestUpdated = responseGuest.data.guestFinishAnswered || {}
            if (!guestUpdated.id) {
                throw new Error('Cannot update guest')
            }
            // Create Answer
            const responseAnswer = await client.mutate({
                mutation: CREATE_ANSWER,
                variables: { data: answerInput }
            })            
            const newAnswer = responseAnswer.data.createAnswer || {}
            if (!newAnswer.id) {
                throw new Error('Cannot save answer');
            }
           
            toast.success(`Questionnaire successfully finished!`);
            history.push('/questionnaire-guest/thanks');
            dispatch(resetQuestionnaireGuest())
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }


    }
    // Progress
    useEffect(() => {

        dispatch(setQuestionnaireGuestPage('done'))
        // Update Redux
        dispatch(updateAdvanceGuest(
            100
        ))
    }, [])

    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Awesome your done the questionnaire!</h4>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                    disabled={loading}
                >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    type="submit"
                    onClick={onClickFinish}
                    disabled={loading}
                >
                    Finish
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>
        </>
    )
}

export default QuestionnaireGuestDone;