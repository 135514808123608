import React, { useState, useEffect, useCallback } from "react";
// Core
import Button from "../buttons/Button";
// Bootstrap
import Modal from "react-bootstrap/Modal";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
// Toastify
import { toast } from "react-toastify";
import { useApolloClient } from "react-apollo";
import { CREATE_STARTUP_FILE, UPDATE_STARTUP_FILE } from "../../queries/startup-file/startup-file.query";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { fileChange, getCurrentModalFiles, hideModalFiles } from "../../redux/ducks/files-duck";
import { getMainElement } from "../../redux/ducks/dashboard-duck";
// Validations
import { Controller, FormProvider, useForm } from "react-hook-form";
// Custom Inputs
import Uploader from "../uploader/Uploader";
import UrlInput from "../forms/UrlInput";
// Props
interface ModalAddChallengeProps {
    onClose?: Function;
    onSave?: Function;
}

const ModalAddFile = (props: ModalAddChallengeProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Validation
    const methods = useForm({
        mode: 'onChange'
    })
    // const { register, setValue, errors, control, trigger } = methods;
    // Selector
    const modalData = useSelector(getCurrentModalFiles);
    // Main Element
    const categorySku = useSelector(getMainElement);
    // Current Startup File
    const [startupFile, setStartupFile] = useState<any>(null)
    // Client
    const client = useApolloClient();
    // Loading
    const [loading, setLoading] = useState(false);
    // Show
    const [show, setShow] = useState(false);
    // Modal Action
    const [action, setAction] = useState('create');
    // File Attrs
    const [type, setType] = useState('link')
    const [link, setLink] = useState('')
    const [files, setFiles] = useState<any>([]);
    // On Change Upload
    const onChangeUploader = async (files) => {
        methods.setValue('file_input', files, { shouldValidate: true })
        setFiles(files)
    }
    // On Change Type 
    const onChangeType = (type) => {
        setType(type)
    }
    // Reset Form Data
    const resetFormData = () => {
        // States
        setStartupFile(null)
        setLink('')
        setFiles([])
        setType('link')
        // Validation
        methods.setValue('link', '')
        methods.setValue('file_input', null)
        methods.setValue('name', '')
        methods.setValue('description', '')
    }
    // Close
    const handleClose = () => {
        dispatch(hideModalFiles());
        resetFormData()
    }
    // Fetch Form
    const fetchForm = useCallback(() => {
        const startupFile = modalData.data.data;
        console.log('startupFile', startupFile)

        // Type
        setType(startupFile.type_file_sku);
        // Values
        methods.setValue('name', startupFile.name, { shouldValidate: false });
        methods.setValue('description', startupFile.description, { shouldValidate: false });

        if (startupFile.type_file_sku === 'link') {
            setFiles([])
            methods.setValue('file_input', null)
            if (startupFile.document) {
                setLink(startupFile.document.url)
            }
        } else {
            setLink('')
            methods.setValue('link', '')
            // File Input
            if (startupFile.document) {
                setFiles([startupFile.document])
            }
        }
        setStartupFile(startupFile)
    }, [modalData, methods, setStartupFile])
    //   Fetch Modal data
    const fetchModalData = useCallback(() => {
        if (modalData.show) {
            
            const modalAction = modalData.data.action;
            const fileData = modalData.data.data;            
            
            if (fileData) {
                setTimeout(() => {
                    fetchForm();
                },0)

            }
            setAction(modalAction)
            setShow(true)

        } else {
            setShow(false)
        }
    }, [modalData, fetchForm])
    // Change Modal Data
    useEffect(() => {
        fetchModalData()
    }, [modalData])
    // Create Action
    const createFile = async () => {
        try {
            // Load
            setLoading(true)
            // Get Values
            const values = methods.getValues()
            const toCreate: any = {
                ...values,
                type_file_sku: type,
                file_input: type === 'file' ? values.file_input[0] : null,
                category_sku: categorySku
            }
            console.log('toCreate', toCreate)
            const createStartupFileResp = await client.mutate({
                mutation: CREATE_STARTUP_FILE,
                variables: { data: toCreate }
            })
            console.log('resp', createStartupFileResp)
            const startupFile = createStartupFileResp.data.createStartupFile || {}
            if (startupFile.id) {
                toast.success(`File created succefully!`);
                handleClose();
                dispatch(fileChange(startupFile))
            } else {
                throw new Error('Cannot create')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }
    // Update File
    const updateFile = async () => {
        try {
            setLoading(true)
            const values = methods.getValues()
            const toUpdate: any = {
                id: startupFile.id,
                category_sku: categorySku,
                ...values,
                type_file_sku: type,
                file_input: null
            }
            if (type === 'file') {
                if(values.file_input){
                const fileInput = values.file_input[0].id ? null : values.file_input[0];
                toUpdate.file_input = fileInput
                }
            }
            const updateStartupFileResp = await client.mutate({
                mutation: UPDATE_STARTUP_FILE,
                variables: { data: toUpdate }
            })
            const startupFileUpdated = updateStartupFileResp.data.updateStartupFile || {}
            if (startupFileUpdated.id) {
                toast.success(`File update succefully!`);
                handleClose();
                dispatch(fileChange(startupFileUpdated))
            } else {
                throw new Error('Cannot create')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }

    // Save
    const onClickSave = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.formState.errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);
        } else {
            if (action.includes('create')) {
                createFile();
            }
            if (action.includes('update')) {
                updateFile();
            }
        }
    }
    return (
        <>
            <Modal
                id="modal-add-file"
                show={show}
                onHide={handleClose}
                enforceFocus={true}
                backdrop={"static"}
            >
                <Modal.Header>
                    <Modal.Title>
                    {action === 'update' ? 'Edit File' : 'Add File'}
                    </Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <FormProvider {...methods}>
                        <Form noValidate name={'create-file'}>
                            <Row>
                                <Col lg="6">
                                    <Form.Group >
                                        <Form.Label className="d-block">File Type</Form.Label>
                                        <ButtonGroup aria-label="Basic example">
                                            <Button variant="primary" onClick={() => onChangeType('link')} className={type === 'link' ? 'btn-violet' : 'btn-light'} >Link</Button>
                                            <Button variant="primary" onClick={() => onChangeType('file')} className={type === 'file' ? 'btn-violet' : 'btn-light'} >File</Button>
                                        </ButtonGroup>
                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* File */}
                            {type === 'file' &&
                                <Row>
                                    <Col lg="9">
                                        <Form.Group className={`${methods.errors.file_input ? 'is-invalid' : ''}`}>
                                            <Controller
                                                name="file_input"
                                                control={methods.control}
                                                rules={action === 'update' ? {} : { required: 'File is required' }}
                                                default
                                                render={props => (
                                                    <Uploader
                                                        onChange={onChangeUploader}
                                                        files={files}
                                                    />
                                                )} />
                                            {methods.errors.file_input && <div className="invalid-feedback"> {methods.errors.file_input.message} </div>}
                                        </Form.Group >
                                    </Col>
                                </Row>}
                            {/* Or Link */}
                            {type == 'link' &&
                                <Row>
                                    <Col lg="9">
                                        <UrlInput
                                            name="link"
                                            value={link}
                                            onChange={(value) => setLink(value)}
                                        />

                                    </Col>
                                </Row>}
                            {/* Name */}
                            <Row>
                                <Col lg="9">
                                    <Form.Group >
                                        <Form.Label>Name</Form.Label>
                                        <Form.Control
                                            name="name"
                                            type="text"
                                            placeholder="Enter a name"
                                            isInvalid={methods.errors.name}
                                            ref={methods.register({ required: 'The name is required' })}
                                        />
                                        {methods.errors.name && <div className="invalid-feedback"> {methods.errors.name.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                            {/* Description */}
                            <Row>
                                <Col lg="9">
                                    <Form.Group controlId="questionTitle">
                                        <Form.Label>Description</Form.Label>
                                        <Form.Control
                                            name="description"
                                            type="text"
                                            placeholder="Enter a description"
                                            as={'textarea'}
                                            rows={3}
                                            ref={methods.register}
                                        />
                                    </Form.Group>
                                </Col>
                            </Row>


                        </Form>
                    </FormProvider>
                </Modal.Body>
                <Modal.Footer>
                    <Button disabled={loading} variant="light" onClick={handleClose}>
                        Cancel
                    </Button>
                    <Button
                        disabled={loading}
                        loading={loading}
                        loadingText={"Saving"}
                        onClick={onClickSave}
                        className="btn-violet">
                        {action === 'update' ? 'Update' : 'Create'}
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalAddFile;
