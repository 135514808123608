
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Form from 'react-bootstrap/Form';
// Charts
import MiddleGaugeChart, { SettingsMiddleGaugeChart } from '../../components/charts/gauge-chart/MiddleGaugeChart';
import BoxStartup from "../../components/box/BoxStartup";
import ProgressBarInlineChart from "../../components/charts/progress/ProgressBarInlineChart";
import { ReactSVG } from "react-svg";
import useWindowSize from "../../hooks/useWindowResize";
import MiddleGaugeChartMin from "../charts/gauge-chart/MiddleGaugeChartMin";

// Props
interface BoxFundabilityMainElementProps {
    mainElement: string,
    mainElementDescription: string,
    priorityLevel: any,
    fundability: any,
    fundabilityInternal: any,
    fundabilityExternal: any,
    fundabilitySubElements: any,
}


const BoxFundabilityMainElement = (props: BoxFundabilityMainElementProps) => {
    // Hook Window
    const windowSize = useWindowSize();
    // Filter
    const [filter, setFilter] = useState({
        internal: false,
        external: false
    })

    // Internal
    const [internal, setInternal] = useState<any>(null)
    // external
    const [external, setExternal] = useState<any>(null)
    // Props
    const {
        fundability,
        fundabilityInternal,
        fundabilityExternal,
        mainElement,
        mainElementDescription,
        fundabilitySubElements,
        priorityLevel
    } = props;
    // Needle
    const needle = require("../../assets/img/needle/needle-min.svg").default;

    // On Change Filter
    const onChangeCheckbox = (event) => {
        console.log(event);
        const { target } = event;
        const { name, checked } = target;

        setFilter(prevState => ({ ...prevState, [name]: checked }));
    }
    const onChangeFilter = useCallback(() => {
        if (filter.external) {
            setExternal(fundabilityExternal)
        } else {
            setExternal(null)
        }
        if (filter.internal) {
            setInternal(fundabilityInternal)
        } else {
            setInternal(null)
        }
    }, [filter, setExternal, setInternal])
    useEffect(() => {
        onChangeFilter()
    }, [filter, onChangeFilter])
    // Reset Data
    const resetData = useCallback(() => {
        setFilter({
            internal: false,
            external: false
        })
    }, [setFilter])
    useEffect(() => {
        resetData()
    }, [mainElement, resetData])
    // Render
    return (
        <BoxStartup className={'h-auto'}  id="box-fundability-main-element">
            <BoxStartup.Header>
                <span className="title">{mainElement}</span>
            </BoxStartup.Header>
            <BoxStartup.Body >
                <div className="box-main-element-header">
                    <div className="box-main-element-header-wrapper">
                        <div className="box-main-element-header-content">
                            <p>{mainElementDescription}</p>
                        </div>

                        <div className="box-main-element-header-level-criticaly">
                            <span className="level-criticaly-title">
                                Level of criticality
                                </span>
                            <div className="level-criticaly-table-container">
                                <table className="level-criticaly-table"  >
                                    <thead>
                                        <tr>
                                            <th>Pre seed</th>
                                            <th>Seed</th>
                                            <th>Series A</th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td className={`${priorityLevel.preSeed.sku}`}>{priorityLevel.preSeed.title}</td>
                                            <td className={`${priorityLevel.seed.sku}`}>{priorityLevel.seed.title}</td>
                                            <td className={`${priorityLevel.seriesA.sku}`}>{priorityLevel.seriesA.title}</td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mb-4" id="main-chart-container">
                    <MiddleGaugeChart
                        data={fundability}
                        external={external}
                        internal={internal}

                    />
                    <span className="title-chart-sub-element">{mainElement}</span>
                </div>
                <div className={`main-element-footer-charts ${fundabilitySubElements.length >= 6 ? 'two-columns' : ''}`}>
                    {fundabilitySubElements.map((item, index) => (
                        <div key={index} className='main-element-footer-item'>
                            <MiddleGaugeChartMin
                                data={item.fundability}
                                settings={{
                                    arcWidth: 8,
                                    maxWidth: 77
                                }}
                            />
                            <span className="main-element-footer-item-title ">{item.label}</span>
                        </div>
                    ))}

                </div>
                {/* Footer */}
                <div className="box-main-element-footer">
                    <div className="footer-title">
                        <span>Rating View:</span>
                    </div>


                    <div className="checkbox-with-icon">
                        <Form.Check
                            className={'custom-checkbox-sm'}
                            custom
                            name={'internal'}
                            type={'checkbox'}
                            id={`custom-checkbox-seed`}
                            label={`Internal`}
                            checked={filter.internal}
                            onChange={onChangeCheckbox}
                        />
                        <ReactSVG src={needle} wrapper="span" className="icon-checkbox comparision-icon yellow" />
                    </div>
                    <div className="checkbox-with-icon">
                        <Form.Check
                            className={'custom-checkbox-sm'}
                            custom
                            name={'external'}
                            type={'checkbox'}
                            id={`custom-checkbox-series-a`}
                            label={`External`}
                            checked={filter.external}
                            onChange={onChangeCheckbox}
                        />
                        <ReactSVG src={needle} wrapper="span" className="comparision-icon" />
                    </div>



                </div>

            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxFundabilityMainElement;
