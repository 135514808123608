import React from "react";

// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";

// React
import { ReactSVG } from "react-svg";

// Props
interface MasterSessionItemProps {
    item: any;
}

const MasterSessionItem = (props: MasterSessionItemProps) => {
    const { item } = props;
    const icon = require(`../../assets/img/files/icon_pdf.svg`).default;
    const onClickFile = () => {
        const newWindow = window.open(item.document.url, '_blank')
        if (newWindow) newWindow.opener = null
    }
    return (
        <div className="master-session-item">
            <Row >
                <Col lg="3">
                    <p className="text-text-color-dark font-weight-bold">{item.topic}</p>
                </Col>
                <Col lg="6">
                    <p>{item.description}</p>
                </Col>
                <Col lg="3">
                    <div className="master-session-file" onClick={onClickFile}>
                        <ReactSVG src={icon} wrapper="span" className="icon-file" />
                        <span className="file-name">{item.document.name}</span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};

export default MasterSessionItem;
