import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";
// Helper
import DateHelper from "../../helpers/dates/date.helper";
// Props
interface QuestionAdminTableProps {
    questionTypes: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
}
// Reports Table
const QuestionTypesTable = (props: QuestionAdminTableProps) => {
    // Props
    const { questionTypes, loading, onClickViewItem, emptyString } = props;
    // Helpers
    const dateHelper = new DateHelper()
    // On Click View
    const onClickView = (questionType) => {
        if (onClickViewItem) {
            onClickViewItem(questionType);
        }
    } 
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Edit Sub Element
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-edit"></i>
                    </button>

                </OverlayTrigger>                
            </div>
        )
    };

const columns: Array<ColumnType> = [
    {
        dataField: 'id',
        text: 'ID',
        hidden: true
    },
    {

        dataField: 'sku',
        text: 'SKU',
        sort: true,
    },
    {

        dataField: 'title',
        text: 'Sub Element',
        sort: true,
    },
    {

        dataField: 'order',
        text: 'Order',
        sort: true,
    },  
    {
        dataField: 'id',
        text: 'Actions',
        formatter: actionsFormatter,
        // headerStyle: { minWidth: 'px' }
    }
];


return (
    <>
        <DefaultTable
            data={questionTypes}
            loading={loading}
            columns={columns}
            emptyString={emptyString}
            defaultSorted={
                {
                    dataField: 'order',
                    order: 'asc'
                }
            }
        />
    </>
)
}

export default QuestionTypesTable;