// questionnaire-duck.js
// Actions
const RESET = 'startup/questionnaire/RESET';
const RESET_ANSWERS = 'startup/questionnaire/RESET_ANSWERS';
const UPDATE_STARTUP = 'startup/questionnaire/UPDATE_STARTUP';
const UPDATE_ADVANCE = 'startup/questionnaire/UPDATE_ADVANCE';
const UPDATE_QUESTIONS_PAGE = 'startup/questionnaire/UPDATE_QUESTIONS_PAGE';
const SET_QUESTIONS = 'startup/questionnaire/SET_QUESTIONS';
const SET_ANSWERS = 'startup/questionnaire/SET_ANSWERS';
const SET_LAST_ANSWER = 'startup/questionnaire/SET_LAST_ANSWER';
const SET_QUESTIONNAIRE_PAGE = 'startup/questionnaire/SET_QUESTIONNAIRE_PAGE';
// Action Creators
export function resetQuestionnaire() {
  return {
    type: RESET
  };
}
export function resetAnswers() {
  return {
    type: RESET_ANSWERS
  };
}
export function updateStartup(payload: any) {
  return {
    type: UPDATE_STARTUP,
    payload: payload
  };
}
export function updateAdvance(payload: any) {
  return {
    type: UPDATE_ADVANCE,
    payload: payload
  };
}

export function updateQuestionsPage(payload: any) {
  return {
    type: UPDATE_QUESTIONS_PAGE,
    payload: payload
  };
}

export function setQuestions(state: []) {
  return {
    type: SET_QUESTIONS,
    payload: state
  };
}
export function setAnswers(state) {
  return {
    type: SET_ANSWERS,
    payload: state
  };
}
export function setLastAnswer(state) {
  return {
    type: SET_LAST_ANSWER,
    payload: state
  };
}
export function setQuestionnairePage(state) {
  return {
    type: SET_QUESTIONNAIRE_PAGE,
    payload: state
  };
}
// Interface
interface QuestionnaireState {
  questionnaire_page: any,
  startup_questionnaire: any,
  questions: any,
  questions_page: number
  answers: any,
  user: any,
  advance: number,
  lastAnswer: any,
}
// Percentage total 11 pages
// 1 = 9 | 4 pages 36%
// 7 = 64% | questions
// Initial State
const initialState: QuestionnaireState = {
  questionnaire_page: null,
  startup_questionnaire: null,
  questions: [],
  questions_page: 1,
  answers: {},
  user: null,
  advance: 0,
  lastAnswer: null
};
// Reducer
export default function reducer(state: QuestionnaireState = initialState, action: any = {}) {
  // console.log('Questionnaire', action)
  switch (action.type) {
    // Update Startup Data
    case RESET: {
      return Object.assign(
        {},
        state,
        {
          ...initialState
        }
      );
    }
    case RESET_ANSWERS: {
      return Object.assign(
        {},
        state,
        {
          answers: {}
        }
      );
    }

    // Update Startup Data
    case UPDATE_STARTUP: {
      return Object.assign(
        {},
        state,
        {
          startup_questionnaire: action.payload
        }
      );
    }
    // Update Advance
    case UPDATE_ADVANCE: {
      return Object.assign(
        {},
        state,
        {
          advance: action.payload
        }
      );
    }
    // Update Questions Page
    case UPDATE_QUESTIONS_PAGE: {
      return Object.assign(
        {},
        state,
        {
          questions_page: action.payload
        }
      );
    }
    // Set Questions
    case SET_QUESTIONS:
      return Object.assign(
        {},
        state,
        {
          questions: action.payload || []
        }
      );
    // Set Answers
    case SET_ANSWERS:
      const answers = { ...state.answers, ...action.payload.data };
      return Object.assign(
        {},
        state,
        {
          answers: answers
        }
      );
    // Set Last Answers
    case SET_LAST_ANSWER:
      return Object.assign(
        {},
        state,
        {
          lastAnswer: action.payload
        }
      );
    case SET_QUESTIONNAIRE_PAGE:
      return Object.assign(
        {},
        state,
        {
          questionnaire_page: action.payload
        }
      );

    default:
      return state;
  }
}



// SELECTORS
export const getQuestionnaireStartup = (state) => state.questionnaire.startup_questionnaire;
export const getQuestionnaireAdvance = (state) => state.questionnaire.advance;
export const getQuestionnaireQuestions = (state) => state.questionnaire.questions;
export const getQuestionnaireQuestionsPage = (state) => state.questionnaire.questions_page;
export const getQuestionnaireAnswers = (state) => state.questionnaire.answers;
export const getQuestionnaireLastAnswer = (state) => state.questionnaire.lastAnswer;
export const getQuestionnairePage = (state) => state.questionnaire.questionnaire_page;