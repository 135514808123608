
/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { setQuestionnaireInvitation, resetQuestionnaireGuest, getQuestionnaireInvitation, getQuestionnaireGuestPage } from '../../redux/ducks/questionnaire-guest-external-duck';
// Client
import { useApolloClient } from "react-apollo";
import { CREATE_GUEST_INVITATION } from "../../queries/guest-invitation/guest-invitation.query";
// Router
import { useHistory, useParams } from "react-router-dom";
import { GET_GUEST_GROUP_BY_ID } from "../../queries/guest-group/guest-group.query";
import { toast } from "react-toastify";



const ProcessTokenExternalGroupView = (props) => {
    // Dispatch
    const dispatch = useDispatch();
    // States
    const [error, setError] = useState(false)
    const [processToken, setProcessToken] = useState(true)
    // Params
    const { token } = useParams<any>();
    // Invitation
    const invitationSelector = useSelector(getQuestionnaireInvitation);
    const questionnairePage = useSelector(getQuestionnaireGuestPage);
    // History
    const history = useHistory();
    // Client
    const client = useApolloClient();
    // Fetch Data
    const processTokenFn = useCallback(async () => {
        try {
            const response = await client.query({
                query: GET_GUEST_GROUP_BY_ID,
                variables: { id: token }
            })
            const guestGroup = response.data.getGuestGroupById || {}
            console.log('guestGroup', guestGroup)
            if (guestGroup.admin_created && guestGroup.types && guestGroup.types.length > 0) {
                // Need Create Invitation In Air 
                const invitationInAir = {
                    anonymous: true,
                    answered: false,
                    guest_group_id: guestGroup.id,
                    types: guestGroup.types
                }
                const response = await client.mutate({
                    mutation: CREATE_GUEST_INVITATION,
                    variables: { data: invitationInAir }
                })
                const invitation = response.data.createGuestInvitation || {};
                console.log(invitation)
                if (!invitation.id) {
                    toast.error('Oops...An error occurred. Try again later ')
                }
                dispatch(resetQuestionnaireGuest())
                dispatch(setQuestionnaireInvitation(invitation))
                history.push(`/questionnaire-guest/intro-external`)
            } else {
                setError(true);
            }

        } catch (e) {
            console.log('error', e)
            setError(true)
        } finally {
            setProcessToken(false)
        }
    }, [client, token, setProcessToken, setError])

    const proccessCurrentInvitation = useCallback(async () => {
        // alert('asd');

        if (!invitationSelector.id) {
            processTokenFn();
        } else {
            if (questionnairePage) {
                switch (questionnairePage) {
                    case 'intro': {
                        history.push(`/questionnaire-guest/intro-external`)
                        break;
                    }
                    case 'questions': {
                        history.push(`/questionnaire-guest/intro-external/questions`)
                        break;
                    }
                    case 'done': {
                        history.push(`/questionnaire-guest/intro-external/done`)
                        break;
                    }
                    default: {
                        history.push(`/questionnaire-guest/intro-external`)
                        break;
                    }
                }
            }
        }

    }, [invitationSelector, questionnairePage])
    // Init
    useEffect(() => {
        if (token && !invitationSelector) {
            processTokenFn()
        } else if (token && invitationSelector) {
            proccessCurrentInvitation();
        }
    }, [token, invitationSelector])
    // Render
    return (
        <>
            <h4 className="text-center">
                {processToken && <span>Process Invitation...</span>}
                {!processToken && error && <span>Token Invalid</span>}
                {!processToken && !error && <span>Token Valid!</span>}
            </h4>
        </>
    )
}

export default ProcessTokenExternalGroupView;
