/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { FIND_USER_BY_ID } from "../../../queries/users/user.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Form
import UserGroupForm from "../../../components/users/UserGroupForm";
// Router
import { useHistory, useParams } from "react-router-dom";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";
// Toast
import { toast } from "react-toastify";
import { LIST_GROUPS } from "../../../queries/group/group.query";



// UserElementsAvailablesView
const UserEditView = (props) => {
    // User Id
    const { id } = useParams<any>();
    // Users
    const [user, setUser] = useState<any>();
    // Users
    const [groups, setGroups] = useState<any>([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query params
    const search = useLastLocationQueryParams("/admin/users");
    // History
    const history = useHistory();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: FIND_USER_BY_ID,
            variables: { id: id },
            fetchPolicy: 'no-cache'
        })
        const user = response.data.findUserById;

        if (!user.id) {
            toast.error(`User doesn't exist`);
            history.push('/admin/users')
        }
        setUser(user);
        // Search Groups
        const responseGroup = await client.query({
            query: LIST_GROUPS,
            fetchPolicy: 'no-cache'
        })
        const groups = responseGroup.data.listGroups || [];

        setGroups(groups)
        // Loading
        setLoading(false);
    }, [
        client,
        setUser,
        setGroups,
        setLoading,
    ]);
    // Call Fetch Data
    useEffect(() => {
        if (id) {
            fetchData();
        }
    }, [id])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/users${search}`}>Users</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit User</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">Edit User {user ? user.email : ''}</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body className="overflow-visible">
                        {!loading &&
                            <UserGroupForm
                                user={user}
                                groups={groups}
                            />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default UserEditView;