
/*eslint-disable*/
import React from "react";

const ThanksGuestAnonymousView = (props) => {

    // Render
    return (
        <>
            <h4 className="text-center">
                Thank you for answering the questionnaire. <br /><br />
            </h4>

        </>
    )
}

export default ThanksGuestAnonymousView;
