export const SHOW_MODAL = 'startup/letstalk/SHOW_MODAL';
export const HIDE_MODAL = 'startup/letstalk/HIDE_MODAL';


export function showModalLetsTalk() {
    return {
        type: SHOW_MODAL
    }
}
export function hideModalLetsTalk() {
    return {
        type: HIDE_MODAL
    }
}

const initialState = {
    show: false
};

const letsTalkReducer = (state = initialState, action) => {
    const { type } = action;

    switch (type) {
        case SHOW_MODAL:
            return {
                show: true
            };
        case HIDE_MODAL:
            return {
                show: false
            };
        default:
            return state;
    }
};

// Selector
export const getShowModalLetsTalk = (state) => state.letstalk.show;
// Export
export default letsTalkReducer;