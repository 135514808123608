
import React, { useEffect, useCallback, useState } from "react";
// Redux
import { useSelector } from 'react-redux';
import { getQuestionnaireQuestions } from '../../redux/ducks/questionnaire-duck';
import QuestionsPagination from "./question-pagination/QuestionsPagination";


const QuestionnaireQuestions = (props) => {
    const questionsStore = useSelector(getQuestionnaireQuestions)
    const [questions, setQuestions] = useState<any>([])
    const fetchData = useCallback(() => {
        setQuestions(questionsStore);
    }, [setQuestions, questionsStore])

    useEffect(() => {
        if (questionsStore !== questions) {
            fetchData()
        }
    }, [fetchData, questionsStore, questionsStore])
    return (
        <>
            <QuestionsPagination questions={questions}/>
        </>
    )
}

export default QuestionnaireQuestions;