
/*eslint-disable*/
import React, { useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Button from 'react-bootstrap/Button';
// Charts
import MiddleGaugeChart from '../../components/charts/gauge-chart/MiddleGaugeChart';
import { CHART_COLOR } from "../../constants/charts/chart.constants";
import BoxStartup from "../../components/box/BoxStartup";
import NestedPieChart from "../../components/charts/pie/NestedPieChart";
import ProgressBarChart from "../../components/charts/progress/ProgressBarChart";
import ProgressBarInlineChart from "../../components/charts/progress/ProgressBarInlineChart";

const ChartView = (props) => {

    const [gaugeData, setGaugeData] = useState<any>([
        { label: "NOT FUNDABLE", value: 20, color: '#ff6442' },
        { label: "WORK TO DO", value: 20, color: '#b64ea3', selected: true, partValue: 4 },
        { label: "LOOKING GOOD", value: 20, color: '#546cb9'},
        { label: "GO GET 'EM", value: 20, color: '#5cd2c7', textColor: CHART_COLOR.good },
    ]);
    const [nestedPie, setNestedPie] =
        useState<any>([
            { label: 'Team', percentage: 90,  },
            { label: 'Purpose', percentage: 40, },
            { label: 'Market', percentage: 90,  },
            { label: 'Scale', percentage: 40, },
            { label: 'Product', percentage: 90,  },
            { label: 'Analytics', percentage: 70,  },
            { label: 'Traction', percentage: 60,  },
        ]);

    const onClickReloadGauge = () => {
        setGaugeData([
            { label: "NOT FUNDABLE", value: 20, color: '#ff6442' },
            { label: "WORK TO DO", value: 20, color: '#b64ea3' },
            { label: "LOOKING GOOD", value: 20, color: '#546cb9', selected: true, partValue: 2  },
            { label: "GO GET 'EM", value: 20, color: '#5cd2c7' },
        ])
    }
    const onClickReloadNestedPie = () => {
        setNestedPie([
            { label: 'Team', percentage: 90, },
            { label: 'Purpose', percentage: 40, },
            { label: 'Market', percentage: 90, },
            { label: 'Scale', percentage: 40, },
            { label: 'Product', percentage: 90, },
            { label: 'Analytics', percentage: 70, },
            { label: 'Traction', percentage: 60, },
        ])
    }
    // Render
    return (

        <Container fluid>
            <Row>
                <Col md={5}>
                    <BoxStartup>
                        <BoxStartup.Header>
                            <Row>
                                <Col sm={4}>
                                    <span className="title">Fundability</span>
                                </Col>
                                <Col sm={8}>
                                    <ProgressBarInlineChart 
                                        data={{
                                            label:'Questions answered',
                                            percentage: 60,
                                            color: '#433D8F'
                                        }}
                                        settings={{
                                            labelColor: '#514E6B'
                                        }}
                                    />
                                </Col>
                            </Row>
                            
                        </BoxStartup.Header>
                        <BoxStartup.Body>
                            <div className="mb-4">
                                <MiddleGaugeChart data={gaugeData} />
                            </div>
                            <Button onClick={onClickReloadGauge}>Reload Gauge</Button>
                        </BoxStartup.Body>
                    </BoxStartup>
                </Col>
                <Col md={7}>
                    <BoxStartup>
                        <BoxStartup.Header>
                            <span className="title">Element scoring</span>
                        </BoxStartup.Header>
                        <BoxStartup.Body>
                            <Row>
                                <Col md={6}>
                                    <div className="startup-progress-chart-list">
                                        {nestedPie.map((pie, index) => {

                                            return <ProgressBarChart
                                                key={index}
                                                data={pie}
                                            />
                                        })}
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <div className="mb-4">
                                        <NestedPieChart data={nestedPie} />
                                    </div>
                                </Col>
                                <Col md={6}>
                                    <Button onClick={onClickReloadNestedPie}>Reload Nested Pie</Button>
                                </Col>
                            </Row>
                        </BoxStartup.Body>

                    </BoxStartup>
                </Col>
            </Row>
        </Container >
    )
}

export default ChartView;
