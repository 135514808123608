import gql from 'graphql-tag'
import { MASTER_SESSION_HISTORY_FIELDS } from './master-session-history.fragment'

// // Queries
// export const GET_MASTER_SESSION = gql`
// ${MASTER_SESSION_FIELDS}
// query getMasterSession($sku: String!){            
//     getMasterSession(sku: $sku){      
//         ...MasterSessionFields    
//     }
// }`

// // Mutation
export const DELETE_MASTER_SESSION_HISTORY = gql`
${MASTER_SESSION_HISTORY_FIELDS}
mutation deleteMasterSessionHistory($id: String!){            
    deleteMasterSessionHistory(id: $id){      
        ...MasterSessionHistoryFields    
    }
}`
