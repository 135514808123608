
import React, { useState } from "react";
// Bootstrap
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";


import { useFormContext } from "react-hook-form";
// Box
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from '../../queries/answer/answers.query'
// Loader
import MoonLoader from 'react-spinners/MoonLoader'

interface FinishQuestionProps {
    categories: any
}
const FinishQuestionnaireButton = (props: FinishQuestionProps) => {
    // Categories
    const { categories } = props;
    // Form Context
    const { getValues, trigger, errors } = useFormContext();
    // Client
    const client = useApolloClient()
    // Loading
    const [loading, setLoading] = useState(false);

    // console.log(totals);
    const onClickFinish = async () => {
        await trigger();
        const keys = Object.keys(errors);
        if(keys.length > 0){
            toast.error(`You must complete required fields`);
        }else{
            onSave();
        }
    }
    const onSave = async () => {
        setLoading(true);
        try {
            const formData = getValues();
            console.log(formData)
            // Questions
            const dataFirstScoring = formData.firstScoring;
            const dataRefinedScoring = formData.refinedScoring;
            // User
            const user = formData.user;
            // Startup
            const startup = user.startup;
            // Create Model To Save
            const answer = {
                user_id: user.id,
                startup_id: startup.id,
                items: [] as any
            }
            for (let cat of categories) {
                const categoryDataFirstScoring = dataFirstScoring[cat.sku];
                const categoryDataRefinedScoring = dataRefinedScoring[cat.sku];
                // Types to each
                const types = Object.keys(categoryDataFirstScoring);
                for (let type of types) {
                    const firstScoring = categoryDataFirstScoring[type];
                    const refinedScoring = categoryDataRefinedScoring[type];
                    const item: any = {
                        question_id: firstScoring.questionId,
                        question_type_sku: type,
                        first_scoring_id: null,
                        refined_scoring_ids: null
                    }
                    if (firstScoring && firstScoring.firstScoringChosen) {
                        item.first_scoring_id = firstScoring.firstScoringChosen.id
                    }
                    if (refinedScoring && refinedScoring.refinedScoringChosen && refinedScoring.refinedScoringChosen.length > 0) {
                        const mapIds = refinedScoring.refinedScoringChosen.map(refinedVal => refinedVal.id);
                        item.refined_scoring_ids = mapIds;
                    }
                    if (item.first_scoring_id || item.refined_scoring_ids.length > 0) {
                        answer.items.push(item)
                    }
                }
            }
            console.log('Answer mapped', answer)
            const response = await client.mutate({
                mutation: CREATE_ANSWER,
                variables: { data: answer }
            })
            console.log(response);
            toast.success(`Questionnaire sending successfully!`);
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false);
        }

    }
    return (
        <>   
         <OverlayTrigger
            key={'ovelary-btn-finish'}
            placement={'top'}
            overlay={
                <Tooltip id="tooltip-btn-finish">
                    Submit the <strong>questionnaire!</strong>
                </Tooltip>
            }
            >
            <Button variant="success"
                id="btn-finish-question"
                onClick={onClickFinish}
                disabled={loading}
                className="btn-fixed fixed-right shadow">
                {!loading && <i className="fas fa-save icon"></i>}
                {loading && <MoonLoader  color="#8e8e93"   size={40}/>}
            </Button>
            </OverlayTrigger>
        </>
    )
}

export default FinishQuestionnaireButton;