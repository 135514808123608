import { CHART_COLOR } from "../charts/chart.constants";

export const GUEST_STATUS = [
    {
        sku: 'answered',
        title: 'Answered',
        color: CHART_COLOR.good
    },
    {
        sku: 'sent',
        title: 'Sent',
        color: CHART_COLOR.poor
    },    
]