
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { DELETE_TRACK, LIST_TRACKS } from "../../../queries/track/track.query";
// Router
import { useHistory } from "react-router-dom";
// Swal
import Swal from '../../../components/sweet-altert/sweet-alert';
// Button
import Button from "../../../components/buttons/Button";
// Toast
import { toast } from "react-toastify";
// Table
import TrackTable from "../../../components/tracks/TrackTable";
// Advice Index View
const TrackIndexView = (props) => {
    // Router
    const history = useHistory();
    // Startups
    const [tracks, setTracks] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_TRACKS,
            fetchPolicy: 'no-cache'
        })
        const tracks = response.data.listTracks || [];
        setTracks(tracks)
        setLoading(false);

    }, [client, setTracks]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickViewItem = (track) => {
        history.push(`/admin/tracks/edit/${track.id}`)
    }
    const onClickDeleteItem = (track) => {
        Swal.fire({
            title: `<span>Are you sure to delete?</span>`,
            html: `<span>You are trying to delete a track</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_TRACK,
                    variables: { id: track.id }
                }).then(resp => {
                    toast.success('Track deleted succefully');
                    fetchData();
                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted a Track. Try later');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }
    const onClickAdd = () => {
        history.push(`/admin/tracks/add`)
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>Tracks</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <Row >
                            <Col md="6" className="d-flex">
                                <span className="title"> Tracks</span>
                            </Col>
                            <Col md="6" className="d-flex justify-content-lg-end">
                                <Button
                                    variant={'primary'}
                                    size="sm"
                                    className={'ml-auto'} onClick={onClickAdd}>
                                    <i className="fas fa-add mr-2"></i>
                                    Add
                            </Button>
                            </Col>
                        </Row>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <TrackTable 
                            tracks={tracks} 
                            loading={loading} 
                            onClickDeleteItem={onClickDeleteItem}
                            onClickViewItem={onClickViewItem}
                            />
                    </BoxStartup.Body>
                </BoxStartup>

            </Container>
        </>
    )
}

export default TrackIndexView;
