
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Button from "../buttons/Button";
// Redux
import { useDispatch } from 'react-redux';
import { setQuestionnairePage, updateAdvance } from '../../redux/ducks/questionnaire-duck';
const QuestionnaireRefinedIntro = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;

    useEffect(() => {
        // Set Page
        dispatch(setQuestionnairePage('intro'))
        // Update Redux
        dispatch(updateAdvance(
            9
        ))
    }, [])
    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">👋</h4>
            {/* Description */}
            <p className="multiple-step-description">
                Welcome to 170 additional questions to refine your score <br />
                    and provide a more accurate assessment.
            </p>
            {/* List */}
            <ul className="questionnaire-instructions">
                <li>You'll have 5 options to choose from. If the answer is "Yes" select it, if it is "No" do not select it. </li>
                <li>You can select 0, 1, 2, 3, 4, or 5 answers by section.</li>
                <li>Remember stay honest!</li>
            </ul>            
            <div className="multiple-step-btn-container">
                <Button className="btn-violet" onClick={() => { history.push(`/questionnaire/refined-scoring/questions`) }}>
                    Start
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </>
    )
}

export default QuestionnaireRefinedIntro;