
/*eslint-disable*/
import React from "react";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import FileList from "../files/FileList";
// Props
interface BoxFilesProps {
    title?: any,
    id?: any,
    className?: any,
    files: Array<any>
}
const BoxBestPractices = (props: BoxFilesProps) => {
    const { title, files, id, className } = props;
    return (
        <BoxStartup id={id ? id : 'box-files'} className={className ? className : ''}>
            <BoxStartup.Header>
                <span className="title">
                    {title ? title : 'Files'}
                </span>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0'}>
                <FileList files={files} type="best-practice" />
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxBestPractices;
