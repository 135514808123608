import gql from 'graphql-tag'

export const FILE_MODEL_FIELDS = gql`
fragment FileModelFields on FileModel {        
    id
    url
    name
    type
    size
    description
}`