import React from 'react';
import ChallengeItem from './ChallengeItem';
import ChallengeItemEmpty from './ChallengeItemEmpty';

interface ChallengeListProps {
    challenges: any,
    type: string
}

const ChallengeList = (props: ChallengeListProps) => {
    // Props
    const { challenges, type } = props;

    return (
        <div className="challenge-list" >
            {!challenges &&  <ChallengeItemEmpty title='Loading Challenges...'/> }
            {challenges && challenges.length > 0 && challenges.map((challenge, index) => (
                <ChallengeItem challenge={challenge} key={challenge.id} type={type} />
            ))}
            {challenges && challenges.length === 0 && <ChallengeItemEmpty title='No Challenges'/>  }

        </div>
    );
};
export default ChallengeList;