
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Table
import StartupsTable from "../../../components/startup/StartupsTable";
// Apollo
import { useApolloClient } from "react-apollo";
import {
    LIST_STARTUPS,
    DELETE_STARTUP,
    GET_STARTUP_HASH_URL
} from "../../../queries/startup/startups.query";
// Router
import { useHistory } from "react-router-dom";
// Sweet alter
import Swal from '../../../components/sweet-altert/sweet-alert'
// Toastify
import { toast } from 'react-toastify';


const StartupIndexView = (props) => {
    // Router
    const history = useHistory();
    // Startups
    const [startups, setStartups] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_STARTUPS,
            fetchPolicy: 'no-cache'
        })
        const list = response.data.listStartups || [];
        setStartups(list)
        setLoading(false);
    }, [client, setStartups]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickViewItem = (startup) => {
        history.push(`/admin/startups/view/${startup.id}`)
    }
    // External Create
    const onClickExternalItem = (startup) =>{
        history.push(`/admin/startups/external-invitation/create/${startup.id}`)
    }
    // External List
    const onClickViewExternalItem = (startup) =>{
        history.push(`/admin/startups/external-invitation/list/${startup.id}`)
    }
    const copyToClipboard = (textToCopy) => {
        // navigator clipboard api needs a secure context (https)
        if (navigator.clipboard && window.isSecureContext) {
            // navigator clipboard api method'
            return navigator.clipboard.writeText(textToCopy);
        } else {
            // text area method
            let textArea = document.createElement("textarea");
            textArea.value = textToCopy;
            // make the textarea out of viewport
            textArea.style.position = "fixed";
            textArea.style.left = "-999999px";
            textArea.style.top = "-999999px";
            document.body.appendChild(textArea);
            textArea.focus();
            textArea.select();
            return new Promise((res, rej) => {
                // here the magic happens
                document.execCommand('copy') ? res(true) : rej(false);
                textArea.remove();
            });
        }
    }

    // Copy Url
    const onClickDashboardItem = async (startup) => {
        const response = await client.query({
            query: GET_STARTUP_HASH_URL,
            variables: { id: startup.id }
        })
        const hash = response.data.getStartupHashUrl || ''
        const host = window.location.host + '/auto-login/' + hash;
        // navigator.clipboard.writeText(host)
        copyToClipboard(host)
            .then(() => toast.info('Url copy to clipboard'))
            .catch(() => console.log('error'));
    }
    // Delete
    const onClickDeleteItem = (startup) => {
        Swal.fire({
            title: `Are you sure to delete?`,
            html: `
                <span>You are trying deleted a Startup: <span class="font-weight-medium">${startup.name}</span></span><br/>
                <span class="text-danger font-weight-medium">All related information will be deleted</span>
            `,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, cancel!',
        }).then((result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_STARTUP,
                    variables: { id: startup.id }
                }).then(resp => {
                    toast.success('Startup deleted succefully');
                    fetchData();
                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted Startup. Try later');
                });
            }
        })
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active> Startups </Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Startups</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <StartupsTable
                            startups={startups}
                            loading={loading}
                            onClickViewItem={onClickViewItem}
                            onClickDeleteItem={onClickDeleteItem}
                            onClickDashboardItem={onClickDashboardItem}
                            onClickExternalItem={onClickExternalItem}
                            onClickViewExternalItem={onClickViewExternalItem}
                        />
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default StartupIndexView;
