import { CHALLENGES_STATUS } from "../../constants/challenges/challenges.constants";
import { STARTUP_STATUS } from "../../constants/startup/startup.constants";
// Lodash
import * as _ from "lodash";
import { GUEST_STATUS } from "../../constants/guests/guest.constants";


export default class StartupHelper {
    // Get Max Weight
    getMaxWeight(questions) {
        const max = questions.reduce((prev, current) => (prev.weight > current.weight) ? prev : current)
        return max;
    }

    /**
     * Calculate Total First Scoring
     * The answer selected (weight) / (max first scoring value / 10)
     * @param firstScoring 
     * @param maxFirstScoring 
     */
    calculateTotalFirstScoring(firstScoring, maxFirstScoring) {
        let total = 0
        if (firstScoring) {
            total = firstScoring.weight / (maxFirstScoring / 10)
        }
        return total;
    }
    /**
     * Calculate Total Refined Scoring
     * @param totalFirstScoring 
     * @param maxFirstScoring 
     * @param choosenRefinedScoring 
     * @param refinedScoringLength 
     */
    calculateTotalRefinedScoring(firstScoringWeight, maxFirstScoring, choosenRefinedScoring, refinedScoringLength) {
        let total = 0;
        let totalRefinedScoring = 0;
        if (choosenRefinedScoring.length > 0) {
            totalRefinedScoring = choosenRefinedScoring.reduce((sum, current) => sum + current.weight, 0);
        }
        // Count refined scoring + max first scoring
        const countRefinedPlusMax = refinedScoringLength + maxFirstScoring;
        total = ((firstScoringWeight + totalRefinedScoring) * 10) / countRefinedPlusMax;

        return total;
    }

    resolvePercentageScoringStyle(value: number): any {
        // Poor	Fair	Good
        //  0-4	 5-7	8-10
        let style = STARTUP_STATUS.find(s => s.key === 'good');
        if (value >= 70.01) {
            style = STARTUP_STATUS.find(s => s.key === 'good')
        } else if (value >= 40.01) {
            style = STARTUP_STATUS.find(s => s.key === 'fair')
        } else {
            style = STARTUP_STATUS.find(s => s.key === 'poor')
        }
        return style

    }
    resolveFundabilityStatusElement(value, fundabilityDefault) {
        const fundabilityStatus = this.resolveScoringPartValue(value);
        // console.log('fundabilityStatus', fundabilityStatus)
        const cloned = _.cloneDeep(fundabilityDefault);
        if (fundabilityStatus.sku !== '') {
            const wanted = cloned.find(f => f.sku === fundabilityStatus.sku);
            wanted.selected = true;
            wanted.partValue = fundabilityStatus.partValue;
        } else {
            cloned[0].selected = true;
            cloned[0].partValue = 0;
            // console.log('cloned', cloned)
        }
        return cloned;

    }
    resolveScoringPartValue(value: number): any {
        // console.log('value => ',value)
        const valueInt = parseFloat(value.toFixed(2));
        // console.log('valueInt => ',valueInt)
        // Poor	Fair	Good
        //  0-4	 5-7	8-10
        let partValue = 1
        let sku = ''
        if (value >= 7.01) {
            sku = 'good';
            partValue = valueInt - 7;
        } else if (value >= 4.01) {
            sku = 'fair';
            partValue = valueInt - 4;
        } else if (value >= 1) {
            sku = 'poor';
            partValue = valueInt;
        }
        return { partValue, sku }

    }
    // Assumptions Progress Status
    resolveChallengeStatus(sku): any {
        const progress = CHALLENGES_STATUS.find(s => s.sku === sku);
        return progress
    }
    // Assumptions Progress Status
    resolveGuestStatus(sku): any {
        const status = GUEST_STATUS.find(s => s.sku === sku);
        return status
    }
    // Resolve 
    resolveFileName(file) {
        let fileName = '';
        let extension = ''
        const document = file.document;
        const typesLink = ['youtube', 'link'];
        if (typesLink.some(v => document.type.includes(v))) {
            fileName = `${file.name}`;
        } else {
            extension = file.document.name.split('.').pop()
            fileName = `${file.name}.${extension}`;
        }
        return { fileName, extension };
    }
    // Map Files
    mapFiles(files) {
        const mapFiles = files
            .filter(file => file.document !== null)
            .map((file: any) => {
                const { extension } = this.resolveFileName(file);
                const document = file.document;
                return {
                    // To Edit when is startup file
                    _object: file,
                    type: file.type_file_sku,
                    name: file.name,
                    extension: extension,
                    created_at: file.created_at,
                    size: document.size,
                    url: document.url.replace('http://localhost:4000', ''),
                    mime: document.type,
                }
            })
        return mapFiles;
    }
    mapRefinedScoringIds(answerItems) {
        const answers_refined_scoring = {};
        for (let item of answerItems) {
            if (item.refined_scoring_ids) {
                answers_refined_scoring[`${item.question_type_sku}`] = item.refined_scoring_ids;
            }
        }
        return answers_refined_scoring;
    }

    formatType(type) {
        let typeStartup = 'Diagnostic'
        switch (type) {
            case 'limited': {
                typeStartup = 'Academy'
                break;
            }
            case 'not_limited': {
                typeStartup = 'Regular'
                break;
            }
            case 'lead_generation': {
                typeStartup = 'Diagnostic'
                break;
            }
            default: {
                typeStartup = 'Diagnostic'
                break;
            }
        }
        return typeStartup;
    }

    getSubElementTitleToLimited(title: string, sku: string) {
        let newTitle = title;
        if (sku === 'business_model') {

            newTitle = 'Monetization';
        }
        return newTitle;
    }
}