
/*eslint-disable*/
import React from "react";
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import Button from "../buttons/Button";
import FileList from "../files/FileList";
// Redux
import { useDispatch } from "react-redux";
import { showModalFiles } from "../../redux/ducks/files-duck";
// Props
interface BoxFilesProps {
    title?: any,
    id?: any,
    className?: any,
    files: Array<any>
}
const BoxFiles = (props: BoxFilesProps) => {
    // Dispatch
    const dispatch = useDispatch();
    // Props
    const { title, files, id, className } = props;
    // On Click Add
    const onClickAdd = () => {
        dispatch(showModalFiles({ action: 'create', data: null }))
    }
    return (
        <BoxStartup id={id ? id : 'box-files'} className={className ? className : ''}>
            <BoxStartup.Header>
                <Row>
                    <Col lg="6">
                        <span className="title">
                            {title ? title : 'Files'}
                        </span>
                    </Col>
                    <Col lg="6" className={'d-flex justify-content-lg-end mt-2 mt-lg-0'}>
                        <Button size="sm" className="btn-violet" onClick={onClickAdd}>Add File</Button>
                    </Col>
                </Row>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0'}>
                <FileList files={files} type="startup-file" />
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxFiles;
