import React, { useState, useCallback, useEffect, useRef } from 'react';
import { ReactSVG } from 'react-svg'
import { NavLink, useLocation, useRouteMatch } from "react-router-dom";
import Image from 'react-bootstrap/Image';
import c from "classnames";
import useOutsideClick from '../../hooks/useOutsideClick'

interface MenuItemProps {
    item: any,
    hasSubmenu: boolean,
    menuType?: 'normal' | 'limited';
}

const MenuItem = (props: MenuItemProps) => {
    // Ref
    const ref = useRef(null);
    // Item
    const { item, hasSubmenu, menuType } = props;
    // Location
    const location = useLocation();
    // Match
    const match = useRouteMatch(item.link);
    // Active
    const [active, setActive] = useState(false);
    // Toggle
    const [toggle, setToggle] = useState(false);
    // SubMenu
    const prefixSubmenu = `/dashboard/main/sub-element/`;
    // SubMenu Limited
    const prefixSubmenuLimited = `/dashboard/main/sub-element-limited/`;
    // Icons
    const lockIconSrc = require("../../assets/img/lock.svg").default;
    // Icon
    let iconSrc = null;
    if (item.icon) {
        iconSrc = require(`../../assets/img/sidebar/${item.icon}`).default;
    }
    useOutsideClick(ref, (element) => {
        if (element.closest('.link-main') || element.closest('.sidebar-sub-menu')) {
            setToggle(false);
        }
    });
    const caret = require("../../assets/img/sidebar/caret.svg").default;
    // Toggle
    const onClickToggle = (event) => {
        event.preventDefault();
        // event.stopPropagation();
        setToggle(!toggle)
    }
    // Is Active
    const fnIsActive = useCallback(() => {
        let active = match ? true : false;
        if (hasSubmenu) {
            for (let submenu of item.submenu) {
                const matchSubmenu = location.pathname.includes(`${menuType === 'normal' ? prefixSubmenu : prefixSubmenuLimited}${submenu.sku}`);
                if (matchSubmenu) {
                    active = true;
                    break;
                }
            }
        }
        setActive(active)
        setToggle(false)
    }, [match, setActive, location, hasSubmenu, item, prefixSubmenu, prefixSubmenuLimited, menuType])
    useEffect(() => {
        fnIsActive();
    }, [match, location, fnIsActive])

    // Render Nav Link
    const renderNavLink = (iconSrc, hasSubmenu) => {
        if (item.link !== 'no_link') {
            return (
                <NavLink className="nav-link" to={item.link} onClick={() => { }}>
                    {iconSrc && <ReactSVG src={iconSrc} wrapper="span" className="icon-link" />}
                    <span>{item.label}</span>
                    {hasSubmenu && <span className="btn-caret" onClick={onClickToggle}>
                        <Image src={caret} />
                    </span>}
                </NavLink>)
        } else {
            return (
                <a href="#!" className="nav-link" onClick={(e) => hasSubmenu ? onClickToggle(e) : {}}>
                    {iconSrc && <ReactSVG src={iconSrc} wrapper="span" className="icon-link" />}
                    <span>{item.label}</span>
                    {hasSubmenu &&
                        <span className="btn-caret" onClick={onClickToggle}>
                            <Image src={caret} />
                        </span>
                    }
                </a>
            )
        }
    }

    return (
        <li ref={ref} className={c("sidebar-menu-item", 'link-main', { 'active': active }, { 'toggle': toggle }, { 'without-submenu': !hasSubmenu })}>
            {/* Render Nav Link */}
            {renderNavLink(iconSrc, hasSubmenu)}
            {/* Has Submenu ? */}
            {hasSubmenu &&
                <ul className={`sidebar-sub-menu ${!iconSrc ? 'not-icon' : ''}`}>
                    {item.submenu.map((submenu, index) => (
                        <li key={index}>
                            {/* Available */}
                            {submenu.available &&
                                <NavLink className="nav-link" to={`${menuType === 'normal' ? prefixSubmenu : prefixSubmenuLimited}${submenu.sku}`} onClick={() => { }}>
                                    {submenu.title}
                                </NavLink>}
                            {!submenu.available &&
                                <span className="nav-link nav-link-disabled">
                                    <ReactSVG src={lockIconSrc} wrapper="span" className="icon lock-icon" />
                                    <span>{submenu.title}</span>
                                </span>}
                        </li>
                    ))}
                </ul>}
        </li>
    );
}

export default MenuItem;