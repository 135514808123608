import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import TokenHelper from '../../helpers/security/token.helper';
import { getToken, isAdmin, isLogin } from '../../utils';

/**
 * Admin Route resolver, only admin user
 */
const AdminRoute = ({ component: Component, ...rest }) => {
    // Token
    const tokenHelper = new TokenHelper();
    // Check if logged in
    let _isLogin = isLogin();
    // Var
    let loggedIn = false;
    // If logged in
    if(_isLogin){
        // Get Token
        const token = getToken();
        // Decode
        const user = tokenHelper.decode(token);
        // If user admin allow
        if(isAdmin(user.type)){
            loggedIn = true;
        }
    }
    return (

        // Show the component only when the user is logged in
        // Otherwise, redirect the user to /signin page
        <Route {...rest} render={props => (
            loggedIn ? <Component {...props} />: <Redirect to="/auth/login" />
        )} />
    );
};

export default AdminRoute;