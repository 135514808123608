const TOKEN_KEY = 'startup_token';
const TOKEN_KEY_GUEST = 'startup_token_guest';
const TYPE_ADMIN = 'admin';

export const getToken = () => {
    return localStorage.getItem(TOKEN_KEY)
}

export const setToken = (token) => {
    localStorage.setItem(TOKEN_KEY, token);
}
export const removeToken = () => {
    localStorage.removeItem(TOKEN_KEY);
}
export const getTokenGuest = () => {
    return localStorage.getItem(TOKEN_KEY_GUEST)
}

export const setTokenGuest = (token) => {
    localStorage.setItem(TOKEN_KEY_GUEST, token);
}
export const removeTokenGuest = () => {
    localStorage.removeItem(TOKEN_KEY_GUEST);
}
// Default
export const isLogin = () => {    
    if (localStorage.getItem(TOKEN_KEY)) {
        return true;
    }
    return false;
}
export const isAdmin = (type): boolean => {
    return TYPE_ADMIN.includes(type)
}