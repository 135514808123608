/*eslint-disable*/
import React, { useEffect, useCallback } from "react";
// Questionnaire
import QuestionnaireIntroLead from "../../components/questionnaire-lead/QuestionnaireIntroLead";
import QuestionnaireExampleLead from "../../components/questionnaire-lead/QuestionnaireExampleLead";
import QuestionnaireStartupTypeLead from "../../components/questionnaire-lead/QuestionnaireStartupTypeLead";
import QuestionnaireQuestionsLead from "../../components/questionnaire-lead/QuestionnaireQuestionsLead";
import QuestionnaireDoneLead from "../../components/questionnaire-lead/QuestionnaireDoneLead";
import MultipleStep from "../../components/multiple-step/MultipleStep";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { setQuestionsLead, getQuestionnaireAdvanceLead, resetQuestionnaireLead } from "../../redux/ducks/questionnaire-lead-duck";
// Client
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_FIRST_SCORING, LIST_QUESTIONS_LEAD } from "../../queries/questions/questions.query";

import { getUser } from "../../redux/ducks/user-duck";
import { useHistory } from "react-router-dom";
import { removeToken } from "../../utils";



const IntroLeadView = (props) => {
    // History
    const history = useHistory();
    // User
    const user = useSelector(getUser);
    // Advance
    const advance = useSelector(getQuestionnaireAdvanceLead);
    // Questions
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // Steps
    const steps = [
        {
            name: 'Intro',
            route: '/',
            component: QuestionnaireIntroLead
        },
        {
            name: 'Example',
            route: '/example',
            component: QuestionnaireExampleLead
        },
        {
            name: 'Startup Type',
            route: '/startup',
            component: QuestionnaireStartupTypeLead
        },
        {
            name: 'Questions',
            route: '/questions',
            component: QuestionnaireQuestionsLead
        },
        {
            name: 'Done!',
            route: '/done',
            component: QuestionnaireDoneLead
        },
    ];



    // Fetch Data
    const fetchData = useCallback(async () => {
        let questions;
        if (!user || user.startup_type !== 'lead_generation') {
            dispatch(resetQuestionnaireLead());
            removeToken();
            history.push('/auth/login')
        }
        try {
            const resp = await client.query({
                query: LIST_QUESTIONS_FIRST_SCORING,
                fetchPolicy: 'no-cache'
            })
            console.log(resp);
            questions = resp.data.listQuestions;

        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setQuestionsLead(questions));
        }
    }, [client])
    // Init
    useEffect(() => {
        fetchData()
    }, [fetchData])
    // Render
    return (
        <>

            <MultipleStep steps={steps}>
                <div className="progress-multiple-step">
                    <div className="progress-multiple-step-bar" style={{ width: advance ? `${advance}%` : '0%' }}></div>
                </div>
            </MultipleStep>
        </>
    )
}

export default IntroLeadView;
