import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
import { AuthStates } from "../constants/startup/startup.constants";
import { getUser } from "../redux/ducks/user-duck";

// Hook
export const useUserAvailableSubElement = (sku: string): string => {
    // Get Necesary Data
    const authUser = useSelector(getUser);
    // Available
    const [available, setAvailable] = useState<string>(AuthStates.PROCESS);
    // Check
    const checkIfAvailable = useCallback(() => {
        console.log('process');
        let available = AuthStates.NOT_AVAILABLE;
        if (authUser.startup_type === 'limited') {
            const elementsConfiguration = authUser.elements_configuration || null;
            if (elementsConfiguration) {
                const elConf = elementsConfiguration.find(el => el.sku === sku)
                if (elConf) {
                    available = elConf.available ? AuthStates.AVAILABLE : AuthStates.NOT_AVAILABLE;
                } else {
                    available = AuthStates.NOT_AVAILABLE;
                }
            } else {
                available = AuthStates.NOT_AVAILABLE;
            }
        } else {
            available = AuthStates.AVAILABLE;
        }
        setAvailable(available)
    }, [authUser, sku, setAvailable])

    useEffect(() => {
        if (authUser) {
            checkIfAvailable()
        }
    }, [authUser, checkIfAvailable, sku]);

    return available;
}
