
/*eslint-disable*/
import React, { useEffect, useRef, useState, useCallback } from "react";
// Box
import BoxStartup from "../box/BoxStartup";
// Assumptions
import ChallengesFilter from "../challenges/ChallengesFilter";
import ChallengeList from "../challenges/ChallengeList";
// Modals
import ModalAddChallenge from "../challenges/ModalAddChallenge";
// Redux
import { useSelector } from 'react-redux';
import {
    getChallengeToUpdate,
    getChallengeToView
} from "../../redux/ducks/challenge-duck";

// Props
interface BoxAllAssumptionsProps {
    id?: string,
    title?: string,
    type: string,
    challenges: any,
}


const BoxAllChallenges = (props: BoxAllAssumptionsProps) => {
    // Challenge to view
    const challengeToView = useSelector(getChallengeToView);
    // Challenge to update
    const challengeToUpdate = useSelector(getChallengeToUpdate);
    // Modal Ref
    const modalAddChallenge: any = useRef(null);
    // Challenges
    const [challenges, setChallenges] = useState<any>(null)
    const [filterChallenges, setFilterChallenges] = useState<any>(null)
    // Props
    const { title, type, id } = props;
    // Filter Ref
    const filterRef = useRef<HTMLDivElement>(null);
    // On change filter
    const onChangeFilter = (filter) => {
        console.log('filter', filter)
        //     priority: null,
        //     testability: null,
        //     blocking: null,
        //     critical: null
        //     status: null
        let filtered = [...challenges];
        if (filter.priority) {
            filtered = filtered.filter(c => c.priority_level_sku === filter.priority);
        }
        if (filter.testability) {
            filtered = filtered.filter(c => c.testability_sku === filter.testability);
        }
        if (filter.blocking) {
            filtered = filtered.filter(c => c.blocking === filter.blocking);
        }
        if (filter.conviction_level) {
            filtered = filtered.filter(c => c.conviction_level_sku === filter.conviction_level);
        }
        if (filter.critical) {
            filtered = filtered.filter(c => c.critical === filter.critical);
        }
        if (filter.status) {
            filtered = filtered.filter(c => c.status_sku === filter.status);
        }

        setFilterChallenges([...filtered])

    }
    // Actions
    // On  Click Update
    const onClickUpdateChallenge = useCallback(() => {
        // console.log('challengeToUpdate', challengeToUpdate)
        const toUpdate = {
            ...challengeToUpdate,
            due_date: challengeToUpdate.due_date ? new Date(challengeToUpdate.due_date) : null
        }
        modalAddChallenge.current.update(toUpdate)

    }, [modalAddChallenge, challengeToUpdate])
    // On  Click View
    const onClickViewChallenge = useCallback(() => {
        // console.log('challengeToUpdate', challengeToUpdate)
        const toView = {
            ...challengeToView,
            due_date: challengeToView.due_date ? new Date(challengeToView.due_date) : null
        }
        modalAddChallenge.current.view(toView)

    }, [modalAddChallenge, challengeToView])

    // View
    useEffect(() => {
        if (challengeToView && challengeToView.id) {
            onClickViewChallenge()
        }
    }, [challengeToView, onClickViewChallenge])
    // Update
    useEffect(() => {
        if (challengeToUpdate && challengeToUpdate.id) {
            onClickUpdateChallenge()
        }
    }, [challengeToUpdate, onClickUpdateChallenge])
    // Fetch Challenges
    const fetchChallenges = useCallback(() => {
        setChallenges(props.challenges);
        setFilterChallenges(props.challenges)
    }, [setChallenges, props.challenges])
    useEffect(() => {
        if (props.challenges) {
            fetchChallenges();
        }
    }, [props.challenges])
    // Render
    return (
        <>
            {/* Add */}
            <ModalAddChallenge
                location={'main-element'}
                ref={modalAddChallenge}
            />
            {/* Delete */}
            <BoxStartup id={id ? id : 'box-all-challenges'} >
                <BoxStartup.Header>
                    <span className="title">
                        {title ? title : 'All Challenges'}
                    </span>
                </BoxStartup.Header>
                <BoxStartup.Body className="p-0 box-body-100">
                    <ChallengesFilter onChange={onChangeFilter} ref={filterRef} />
                    <ChallengeList
                        challenges={filterChallenges}
                        type={type}
                    />
                </BoxStartup.Body>
            </BoxStartup>
        </>

    )
}

export default BoxAllChallenges;
