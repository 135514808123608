
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_MASTER_SESSIONS, DELETE_MASTER_SESSION } from "../../../queries/master-session/master-session.query";


// Navigation
import { useHistory } from "react-router";

// Swaler
import Swal from '../../../components/sweet-altert/sweet-alert';
// Toast
import { toast } from "react-toastify";
//  Table
import MasterSessionTable from "../../../components/master-session/MasterSessionTable";
// Button
import Button from "../../../components/buttons/Button";

const MasterSessionIndexView = (props) => {
    // Navigation
    const history = useHistory();
    // Master Session
    const [masterSessions, setMasterSessions] = useState<any>([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();

    // On click Add
    const onClickAdd = () => {
        history.push(`/admin/master-sessions/add`)
    }
    // On View / Edit Item
    const onEditItem = (item) => {
        history.push(`/admin/master-sessions/edit/${item.id}`)
    }
    // On Delete Item
    const onDeleteItem = (masterSession) => {
        Swal.fire({
            title: `<span>Are you sure to delete?</span>`,
            html: `<span>You are trying to delete a Master Session</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, remove it!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                client.mutate({
                    mutation: DELETE_MASTER_SESSION,
                    variables: { id: masterSession.id }
                }).then(resp => {
                    const deleted = resp.data.deleteMasterSession || {};
                    if (deleted.id) {
                        toast.success('Master Session  deleted succefully');
                        fetchData()
                    } else {
                        toast.error('Opps...An error ocurred when deleted a Master Session. Try later');
                    }

                }).catch(error => {
                    console.log(error);
                    toast.error('Opps...An error ocurred when deleted a Master Session. Try later');
                });
            } else if (result.dismiss === Swal.DismissReason.cancel) {

            }
        })
    }
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        // If not existe exception
        try {
            const response = await client.query({
                query: LIST_MASTER_SESSIONS,
                fetchPolicy: 'no-cache'
            })
            const masterSessions = response.data.listMasterSessions || [];

            setMasterSessions(masterSessions);
        } catch (e) {
            console.log(e);
        } finally {
            setLoading(false);
        }


    }, [
        client,
        setMasterSessions,
        setLoading]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // Events

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>Master Sessions</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <Row >
                            <Col md="6" className="d-flex">
                                <span className="title"> Master Sessions </span>
                            </Col>
                            <Col md="6" className="d-flex justify-content-lg-end">
                                <Button
                                    variant={'primary'}
                                    size="sm"
                                    className={'ml-auto'} onClick={onClickAdd}>
                                    <i className="fas fa-add mr-2"></i>
                                    Add
                                </Button>
                            </Col>
                        </Row>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                    
                            <MasterSessionTable
                                loading={loading}
                                masterSessions={masterSessions}
                                onClickDeleteItem={onDeleteItem}
                                onClickViewItem={onEditItem}
                            />
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default MasterSessionIndexView;