import { useCallback, useEffect, useState } from "react";
import { useSelector } from 'react-redux';
// import { setAnswers } from "../../redux/ducks/questionnaire-duck";
import {
    getQuestionnaireAnswersLead,
    getQuestionnaireQuestionsLead,
} from "../../redux/ducks/questionnaire-lead-duck";

// Hook
const useLeadAnswer = () => {
    // Get Necesary Data
    const answers = useSelector(getQuestionnaireAnswersLead);
    const questions = useSelector(getQuestionnaireQuestionsLead)
    const [answer, setAnswer] = useState<any>(null);

    const mapData = useCallback(() => {
        const answer = {
            items: [] as any
        }
        // First Scoring
        const answerKeys = Object.keys(answers);
        for (let aKey of answerKeys) {
            const answerItem = answers[aKey];
            const question = questions.find(q => q.type_sku === aKey);

            const item: any = {
                question_id: question.id,
                question_type_sku: question.type_sku,
                first_scoring_id: answerItem,
                refined_scoring_ids: null
            }

            answer.items.push(item)
        }
        
        setAnswer(answer);
    }, [setAnswer, answers, questions])

    useEffect(() => {
        if (answers && questions) {
            mapData()
        }
    }, [answers,  questions, mapData]);

    return answer;
}

export default useLeadAnswer;