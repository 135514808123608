import React from "react";
// Core
import Button from "../buttons/Button";
// Bootstrap
import Modal from "react-bootstrap/Modal";
import MasterSessionItem from "./MasterSessionItem";

// Props
interface ModalMasterSessionHistoryProps {
    onClose: Function;
    show: boolean;
    history: Array<any>
}

const ModalMasterSessionHistory = (props: ModalMasterSessionHistoryProps) => {
    const { show, onClose, history } = props;
    // Close
    const handleClose = () => {
        onClose();
    };
    return (
        <>
            <Modal
                id="modal-master-session-history"
                show={show}
                onHide={handleClose}
                enforceFocus={true}
                backdrop={"static"}
            >
                <Modal.Header>
                    <Modal.Title>History</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    {/* History Item */}
                    {history.map((item) => <MasterSessionItem item={item} key={item.id} />)}
                </Modal.Body>
                <Modal.Footer>
                    <Button variant="light" onClick={handleClose}>
                        Close
                    </Button>
                </Modal.Footer>
            </Modal>
        </>
    );
};

export default ModalMasterSessionHistory;
