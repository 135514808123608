import React, { useState } from "react";

import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';


const SearchTable = (props) => {
    // Props
    const [filter, setFilter] = useState('');
    const reset = () => {
        setFilter('');
        props.onSearch('');
    };
    
    const handleOnChangeFilter = (event) => {
        const {  value } = event.target;
        setFilter(value);
        props.onSearch(value);
    }
    return (
        <div className="table-search">
            <Row>
                <Col lg={7}>
                    <Form.Control
                        name="search"
                        type="text"
                        placeholder={ props.placeholder ? props.placeholder : 'Search...'}
                        className="input-search"
                        onChange={handleOnChangeFilter}
                        value={filter}
                    />
                </Col>
                <Col md={5} className="d-flex">
                    <button className="btn btn-primary" onClick={reset}>
                        Clear
                    </button>
                </Col>
            </Row>
        </div>
    );
};

export default SearchTable;