
/*eslint-disable*/
import React from 'react';
// Vendors
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css';
import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-image-lightbox/style.css';
import "react-datepicker/dist/react-datepicker.css";
import 'rsuite/dist/styles/rsuite-default.css'
import 'suneditor/dist/css/suneditor.min.css'; // Import Sun Editor's CSS File
import 'react-bootstrap-typeahead/css/Typeahead.css';
// Main style
import "./assets/scss/main.scss";
// Apollo
import client from './api/client';
import { ApolloProvider } from '@apollo/react-hooks';
import { ApolloProvider as ApolloProviderHooks } from '@apollo/react-hooks';
// Router
import { BrowserRouter, Switch, Redirect, Route, useHistory } from "react-router-dom";
import PrivateRoute from './components/auth/PrivateRoute';
import PublicRoute from './components/auth/PublicRoute';
import GuestRoute from './components/auth/GuestRoute';
import { LastLocationProvider } from 'react-router-last-location';

// Layouts
import AdminLayout from "./layouts/Admin";
import DashboardLayout from "./layouts/Dashboard";
import AuthLayout from "./layouts/Auth";
import QuestionnaireLayout from "./layouts/Questionnaire";
import QuestionLayout from "./layouts/Question";
import QuestionnaireGuest from './layouts/QuestionnaireGuest';
import DashboardLead from './layouts/DashboardLead';
// Toast
import { ToastContainer } from 'react-toastify';
import AdminRoute from './components/auth/AdminRoute';
// redux
import { Provider } from 'react-redux';
import store from './redux/ducks/duck';
import AutoLoginView from './views/auto-login/AutoLoginView';
// Modal
import { ModalWrapper } from './components/modals/ModalWrapper';

import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = '/pdf.worker.js';

const App: React.FC = () => {

  return (
    <ApolloProviderHooks client={client}>
      <ApolloProvider client={client}>
        <BrowserRouter >
          <LastLocationProvider>
            <Provider store={store}>
              <Switch>
                {/* Auto Login */}
                <Route path="/auto-login/:token" component={AutoLoginView} />
                {/* Dashboard */}
                <PrivateRoute path="/dashboard-lead" component={DashboardLead} />
                {/* Dashboard */}
                <PrivateRoute path="/dashboard" component={DashboardLayout} />
                {/* Questionnaire */}
                <PrivateRoute path="/questionnaire" component={QuestionnaireLayout} />
                {/* Admin */}
                <AdminRoute path="/admin" component={AdminLayout} />
                {/* Questionnaire Guest */}
                <GuestRoute path="/questionnaire-guest" restricted={true} component={QuestionnaireGuest} />
                {/* Auth */}
                <PublicRoute path="/auth" restricted={true} component={AuthLayout} />
                {/* Event Questions */}
                <PublicRoute path="/questionnaire-public" restricted={true} component={QuestionLayout} />
                {/* Default */}
                <Redirect from="/" to="/auth/login" />
              </Switch>
              <ToastContainer
                position="top-right"
                autoClose={5000}
                hideProgressBar={false}
                newestOnTop={false}
                closeOnClick
                rtl={false}
                pauseOnFocusLoss
                draggable
                pauseOnHover
              />
              <ModalWrapper />
            </Provider>
          </LastLocationProvider>
        </BrowserRouter>
      </ApolloProvider>
    </ApolloProviderHooks>
  );
}

export default App;