import React, { useState } from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StartupHelper from '../../helpers/startup/startup.helper';
import { ReactSVG } from 'react-svg';
import Button from '../buttons/Button';
import GuestItemInvitation from './GuestItemInvitation';
// Props
interface GuestItemProps {
    guest: any,
    onClickDelete: Function
    onClickDeleteInvitation: Function
}
// Guest Item
const GuestItem = (props: GuestItemProps) => {
    const { guest, onClickDelete: onClickDeleteProps, onClickDeleteInvitation: onClickDeleteInvitationProps } = props;
    const startupHelper = new StartupHelper();
    const status = startupHelper.resolveGuestStatus(guest.answered ? 'answered' : 'sent')
    // Icons
    const iconStatussrc = require(`../../assets/img/guests/icon_${status.sku}.svg`).default
    const arrow = require("../../assets/img/challenges/arrow-right.svg").default;
    // Collapse
    const [collapsed, setCollapsed] = useState(true)
    // Delete
    const onClickDelete = () => {
        onClickDeleteProps(guest);
    }
    const onClickDeleteInvitation = (invitation) => {
        onClickDeleteInvitationProps(invitation)
    }
    const toggleCollapsed = (e) => {
        e.preventDefault();
        setCollapsed(!collapsed)
    }
    const drawGuestInternal = () => {
        return (<Row>
            <Col lg="3">
                <span className="guest-item-name">{guest.first_name}</span>
            </Col>
            <Col lg="4" className='mt-4 mt-lg-0'>
                <span className="guest-item-email">{guest.email}</span>
            </Col>
            <Col lg="5" className="d-lg-flex mt-4 mt-lg-0 justify-content-lg-end align-items-center">
                {guest.type === 'guest_external' &&
                    <span className="mr-auto guest-item-group">
                        {guest.guest_group.name}
                    </span>
                }
                <div className="guest-item-status">
                    <span className={`badge-challenge badge-challenge-status  ${status?.sku}`}>
                        <ReactSVG src={iconStatussrc} wrapper="span" className="icon-status " />
                        {status.title}
                    </span>
                    <Button variant="" className="btn-flat btn-flat-danger" size="sm" onClick={onClickDelete}>Delete</Button>
                </div>
            </Col>
        </Row>)
    }
    const drawGuestExternal = () => {
        return (
            <>
                {/* Guest */}
                <div className={`guest-item-wrapper ${collapsed ? '' : 'collapse-show'}`}>
                    <Row>
                        <Col lg="1">
                            <span className="collapse-btn" onClick={toggleCollapsed}>
                                <ReactSVG src={arrow} wrapper="span" className="arrow-right" />
                            </span>
                        </Col>
                        <Col lg="3">
                            <span className="guest-item-name">{guest.first_name}</span>
                        </Col>
                        <Col lg="4" className='mt-4 mt-lg-0'>
                            <span className="guest-item-email">{guest.email}</span>
                        </Col>
                        <Col lg="4" className="d-lg-flex mt-4 mt-lg-0 justify-content-lg-end align-items-center">
                            <span className="mr-auto guest-item-group">
                                {guest.guest_group.name}
                            </span>
                            <div className="guest-item-status">
                                <Button variant="" className="btn-flat btn-flat-danger" size="sm" onClick={onClickDelete}>Delete</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* Accordion */}
                <div className={`guest-item-accordion ${collapsed ? 'collapse-hidden' : ''}`}>
                    {guest.invitations.map((invitation) => (
                        <GuestItemInvitation invitation={invitation} key={invitation.id} onClickDelete={onClickDeleteInvitation} />
                    ))}
                </div>
            </>
        )
    }
    return (
        <div className={`guest-item ${guest.type}`}>
            {guest.type === 'guest_internal' && drawGuestInternal()}
            {guest.type === 'guest_external' && drawGuestExternal()}
        </div>
    )
}

export default GuestItem;