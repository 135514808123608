import gql from 'graphql-tag'
import { GROUP_FIELDS } from './group.fragment'


export const GET_GROUP_BY_ID = gql`  
        ${GROUP_FIELDS}               
        query getGroupById($id: String!){                     
            getGroupById(id: $id){
                ...GroupFields
                users{
                    id
                    first_name
                    last_name
                    fullname
                    email
                    startup{
                        id 
                        type
                        name
                        stage_sku
                    }
                }
            }
}`
export const LIST_GROUPS = gql`  
${GROUP_FIELDS}              
query listGroups{                     
    listGroups{
        ...GroupFields
    }
}`

export const CREATE_GROUP = gql`  
        ${GROUP_FIELDS}              
        mutation createGroup($data:GroupInput!){                     
            createGroup(data:$data){
                ...GroupFields
        }
}`
export const UPDATE_GROUP = gql`  
${GROUP_FIELDS}              
mutation updateGroup($data:GroupInput!){                     
    updateGroup(data:$data){
        ...GroupFields
}
}`

export const DELETE_GROUP = gql`  
${GROUP_FIELDS}              
mutation deleteGroup($id:String!){                     
    deleteGroup(id:$id){
        ...GroupFields
    }
}`
