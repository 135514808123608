export const SET_MAIN_ELEMENT = "startup/dashboard/SET_MAIN_ELEMENT";
export const SET_SUB_ELEMENT = "startup/dashboard/SET_SUB_ELEMENT";
// Dashboard Data
export const SET_DASHBOARD_DATA = "startup/dashboard/SET_DASHBOARD_DATA";
// Dashboard Data Internal
export const SET_DASHBOARD_DATA_INTERNAL = "startup/dashboard/SET_DASHBOARD_DATA_INTERNAL";
// Dashboard Data External
export const SET_DASHBOARD_DATA_EXTERNAL = "startup/dashboard/SET_DASHBOARD_DATA_EXTERNAL";

export const RESET_DASHBOARD_DATA = "startup/dashboard/RESET_DASHBOARD_DATA";
export const SET_MAIN_LOADING = "startup/dashboard/SET_MAIN_LOADING";
export const SET_CATEGORIES =
  "startup/dashboard/SET_CATEGORIES";

export function resetDashboardData() {
  return {
    type: RESET_DASHBOARD_DATA,
  };
}
export function setDashboardData(payload) {
  return {
    type: SET_DASHBOARD_DATA,
    payload,
  };
}
export function setDashboardDataInternal(payload) {
  return {
    type: SET_DASHBOARD_DATA_INTERNAL,
    payload,
  };
}
export function setDashboardDataExternal(payload) {
  return {
    type: SET_DASHBOARD_DATA_EXTERNAL,
    payload,
  };
}
export function setCategories(payload) {
  return {
    type: SET_CATEGORIES,
    payload,
  };
}
export function setMainLoading(payload) {
  return {
    type: SET_MAIN_LOADING,
    payload,
  };
}

export function setMainElement(payload) {
  return {
    type: SET_MAIN_ELEMENT,
    payload,
  };
}

export function setSubElement(payload) {
  return {
    type: SET_SUB_ELEMENT,
    payload,
  };
}

const initialState = {
  mainElement: null,
  subElement: null,
  startup: null,
  startup_internal: null,
  startup_external: null,
  loading: false,
  categories: null,
};

export default function reducer(state = initialState, action: any) {
  //  console.log('state', state)
  //  console.log('action', action)
  switch (action.type) {
    case RESET_DASHBOARD_DATA:
      return Object.assign({}, state, {
        ...initialState,
      });
    case SET_DASHBOARD_DATA:
      return Object.assign({}, state, {
        startup: action.payload,
      });
    case SET_DASHBOARD_DATA_INTERNAL:
      return Object.assign({}, state, {
        startup_internal: action.payload,
      });
    case SET_DASHBOARD_DATA_EXTERNAL:
      return Object.assign({}, state, {
        startup_external: action.payload,
      });
    case SET_CATEGORIES:
      return Object.assign({}, state, {
        categories: action.payload,
      });
    case SET_MAIN_LOADING:
      return Object.assign({}, state, {
        loading: action.payload,
      });
    case SET_MAIN_ELEMENT:
      return Object.assign({}, state, {
        mainElement: action.payload,
      });
    case SET_SUB_ELEMENT:
      return Object.assign({}, state, {
        subElement: action.payload,
      });
    default:
      return state;
  }
}

export const getMainElement = (state) => state.dashboard.mainElement;
export const getSubElement = (state) => state.dashboard.subElement;
export const getMainLoading = (state) => state.dashboard.loading;
export const getDashboardData = (state) => state.dashboard.startup;
export const getDashboardDataInternal = (state) => state.dashboard.startup_internal;
export const getDashboardDataExternal = (state) => state.dashboard.startup_external;
export const getCategories = (state) => state.dashboard.categories;
