
/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
// Redux
import { useDispatch } from 'react-redux';
import { setQuestionnaireInvitation, resetQuestionnaireGuest} from '../../redux/ducks/questionnaire-guest-external-duck';
// Client
import { useApolloClient } from "react-apollo";
import { RESOLVE_TOKEN_GUEST_INVITATION } from "../../queries/guest-invitation/guest-invitation.query";
// Router
import { useHistory, useParams } from "react-router-dom";



const ProcessTokenExternalView = (props) => {
    // Dispatch
    const dispatch = useDispatch();
    // States
    const [error, setError] = useState(false)
    const [processToken, setProcessToken] = useState(true)
    // Params
    const { token } = useParams<any>();
    // History
    const history = useHistory();
    // Client
    const client = useApolloClient();
    // Fetch Data
    const processTokenFn = useCallback(async () => {
        try {
            const response = await client.query({
                query: RESOLVE_TOKEN_GUEST_INVITATION,
                variables: { token }
            })
            const invitation = response.data.resolveTokenGuestInvitation || {}
            if (!invitation.id || !invitation.guest) {
                throw new Error('Token invalid')
            }

            dispatch(resetQuestionnaireGuest())
            dispatch(setQuestionnaireInvitation(invitation))
            history.push(`/questionnaire-guest/intro-external`)
        } catch (e) {
            console.log(e)
            setError(true)
        } finally {
            setProcessToken(false)
        }
    }, [client, token, setProcessToken, setError])
    // Init
    useEffect(() => {
        if (token) {
            processTokenFn()
        }
    }, [token])
    // Render
    return (
        <>
            <h4 className="text-center">
                {processToken && <span>Process Invitation...</span>}
                {!processToken && error && <span>Token Invalid</span>}
                {!processToken && !error && <span>Token Valid!</span>}
            </h4>
        </>
    )
}

export default ProcessTokenExternalView;
