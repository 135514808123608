import React from 'react'
// Svg
import { ReactSVG } from "react-svg";
// Props
interface ModalCloseProps {
    onClick: Function
}
// Modal close
const ModalClose = (props: ModalCloseProps) => {
    // Close icon
    const close = require("../../assets/img/modal-close.svg").default;
    // On Click
    const onClick = () => {
        props.onClick();
    }
    return (
        <span onClick={onClick} className="custom-modal-close">
            <ReactSVG src={close} wrapper="span" className="close-icon" />
        </span>
    )
}

export default ModalClose;