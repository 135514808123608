import gql from 'graphql-tag'

export const GROUP_FIELDS = gql`
fragment GroupFields on Group {
    id
    name
    description
    elements_configuration{
        sku
        title
        available
    }
    created_at
    updated_at
}`