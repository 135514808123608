import React from "react";
// Core
import SearchTable from "./SearchTable";
// Boostrap Table
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory from 'react-bootstrap-table2-paginator';
import ToolkitProvider from 'react-bootstrap-table2-toolkit';
// Boostrap
import Spinner from 'react-bootstrap/Spinner';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Dropdown from 'react-bootstrap/Dropdown';
// Constants
import { PAGINATION_DEFAULT } from "./pagination.default";
import { useHistory } from "react-router-dom";
import { useQueryParams } from "../../hooks/useQueryParams";
import { useEffect } from "react";
import { useCallback } from "react";
import { useState } from "react";

const sizePerPageRenderer = ({
    options,
    currSizePerPage,
    onSizePerPageChange
}) => {
    const currentOption = options.find(opt => opt.page === parseInt(currSizePerPage));
    return (
        < Dropdown >
            <Dropdown.Toggle variant="success" id="dropdown-basic">
                {currentOption ? currentOption.text : ''}
            </Dropdown.Toggle>
            <Dropdown.Menu>
                {options.map((option, index) => (
                    <Dropdown.Item
                        key={index}
                        onClick={() => onSizePerPageChange(option.page)}>
                        {option.text}
                    </Dropdown.Item>
                ))}
            </Dropdown.Menu>
        </Dropdown >

    )
};



// Column Type
export interface ColumnType {
    dataField: string;
    text: string;
    hidden?: boolean;
    sort?: boolean;
    formatter?: Function;
    sortValue?: Function;
    headerStyle?: any,
    onSort?: any

}
// Default Sorted
export interface DefaultSortedType {
    dataField: string,
    order: string
}
// FieldOrder
export interface FieldOrder {
    field: string | null,
    order: string | null
}
// Props
interface DefaultTableProps {
    data: Array<any>,
    loading: boolean,
    columns: Array<ColumnType>,
    emptyString?: string,
    keyField?: string
    defaultSorted?: DefaultSortedType
}
// Reports Table
const DefaultTable = (props: DefaultTableProps) => {
    // Props
    const {
        data,
        loading,
        columns,
        emptyString: emptyStringProps,
        keyField: keyFieldProps,
        defaultSorted: defaultSortedProps
    } = props;
    // Empty data Description
    let emptyString = 'Whithout Data';
    let keyField = 'Whithout Data';
    if (typeof emptyStringProps !== 'undefined') emptyString = emptyStringProps;
    if (typeof keyFieldProps !== 'undefined') keyField = keyFieldProps;
    // Router
    const history = useHistory();
    const queryParams = useQueryParams();
    // Pagination 
    const [page, setPage] = useState(1);
    const [sizePerPage, setSizePerPage] = useState(10);
    // Default Sort
    const defaultSortedValue: DefaultSortedType = {
        dataField: 'update_at',
        order: 'desc'
    };
    const [defaultSorted] = useState(typeof defaultSortedProps !== 'undefined' ? defaultSortedProps : defaultSortedValue);

    const [fieldOrder, setFieldOrder] = useState<FieldOrder>({
        field: null,
        order: null
    })
    // Map to listen on sort
    const [mapColumns, setMapColumns] = useState<Array<ColumnType>>(new Array<ColumnType>());

    // Handle Sort Chnange
    const handleSort = (field, order) => {
        // Params
        const params = new URLSearchParams()
        setFieldOrder({
            field: field,
            order: order
        })

        params.append("field", field)
        params.append("order", order)

        if (sizePerPage) {
            params.append("sizePerPage", sizePerPage.toString())
        }
        if (page) {
            params.append("page", page.toString())
        }
        history.push({
            search: params.toString()
        })
    }
    // Set Pagination Parameters
    const fetchPaginationQuery = useCallback(() => {
        const params = new URLSearchParams()
        if (fieldOrder.field && fieldOrder.order) {
            params.append("field", fieldOrder.field)
            params.append("order", fieldOrder.order)
        }
        if (sizePerPage) {
            params.append("sizePerPage", sizePerPage.toString())
        }
        if (page) {
            params.append("page", page.toString())
        }
        history.push({
            search: params.toString()
        })
    }, [fieldOrder.field, fieldOrder.order, sizePerPage, page, history])

    const onPaginationChange = (sizePerPage, page) => {
        if (sizePerPage) {
            setSizePerPage(sizePerPage);
        }
        if (page) {
            setPage(page);
        }
        
    }
    // Pagination
    const [paginationOptions, setPaginationOptions] = useState({
        ...PAGINATION_DEFAULT,
        sizePerPageRenderer: sizePerPageRenderer,
        onSizePerPageChange: (sizePerPage, page) => {
            onPaginationChange(sizePerPage, page);

        },
        onPageChange: (page, sizePerPage) => {
            onPaginationChange(sizePerPage, page);
        }

    })
    // Initial Configuration from query params
    const fetchInitalConfigurationFromQuery = useCallback(() => {
        // Pagination
        const qPage = queryParams.get('page') || '1';
        const qSizePerPage = queryParams.get('sizePerPage') || '10';
        const intPage = parseInt(qPage);
        const intSizePerPage = parseInt(qSizePerPage);

        setPage(intPage);
        setSizePerPage(intSizePerPage);

        const copyPaginationOptions = { ...paginationOptions }

        if (paginationOptions.sizePerPage !== intSizePerPage ||
            paginationOptions.page !== intPage) {
            copyPaginationOptions.page = intPage;
            copyPaginationOptions.sizePerPage = intSizePerPage;
        }
        setPaginationOptions(copyPaginationOptions)
        // Field Sort
        const qField = queryParams.get('field');
        const qOrder = queryParams.get('order');
        console.log('qField', qField)
        console.log('qOrder', qOrder)
        if (qField && qOrder) {
            setFieldOrder({
                field: qField,
                order: qOrder
            })
        } else {
            setFieldOrder({
                field: defaultSorted.dataField,
                order: defaultSorted.order
            })
        }

    }, [queryParams, paginationOptions, setFieldOrder, defaultSorted])
    // Inital Data
    const fetchInitalData = useCallback(() => {
        setFieldOrder({
            field: defaultSorted.dataField,
            order: defaultSorted.order
        })
    }, [defaultSorted])
    // Call One Time Query Params :p
    useEffect(() => {
        let init = false;
        if (queryParams) {
            console.log('query params change')
            // Pagination
            const qPage = queryParams.get('page');
            const qSizePerPage = queryParams.get('sizePerPage');

            if (qPage && qSizePerPage) {
                const intPage = parseInt(qPage);
                const intSizePerPage = parseInt(qSizePerPage);
                if (intPage !== page || intSizePerPage !== sizePerPage) {
                    init = true;
                    fetchInitalConfigurationFromQuery();
                }
            }
            // Sort
            const qField = queryParams.get('field');
            const qOrder = queryParams.get('order');

            if (qField && qOrder && !init) {
                init = true;
                fetchInitalConfigurationFromQuery();

            }
            // If not init
            if (!init) {
                fetchInitalData();
            }


        }

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Pagination Change
    useEffect(() => {
        fetchPaginationQuery();
    }, [page, sizePerPage, fetchPaginationQuery])

    //#region Columns
    const onFetchColumns = useCallback(() => {
        const mapColumns = columns.map((col) => (
            {
                ...col,
                onSort: handleSort
            }
        ))

        setMapColumns(mapColumns);

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [columns])
    useEffect(() => {
        if (columns && columns.length > 0) {
            onFetchColumns();
        }
    }, [columns, onFetchColumns])
    //#endregion Columns

    // Table
    const table = () => {
        return (
            <>
                <ToolkitProvider
                    data={data}
                    columns={mapColumns}
                    keyField='id'
                    bootstrap4
                    search={{ searchFormatted: true }}
                >
                    {
                        props => (
                            <>
                                <SearchTable {...props.searchProps} />
                                <BootstrapTable
                                    wrapperClasses="table-responsive table-startup-wrapper"
                                    keyField={keyField}
                                    bootstrap4
                                    bordered={false}
                                    {...props.baseProps}
                                    pagination={paginationFactory({
                                        ...paginationOptions,
                                        sizePerPageList: [{
                                            text: '10', value: 10
                                        }, {
                                            text: '15', value: 15
                                        },
                                        {
                                            text: '20', value: 20
                                        }, {
                                            text: '30', value: 30
                                        },
                                        {
                                            text: 'All', value: data.length
                                        }],
                                    })}
                                    noDataIndication={empty()}
                                    // defaultSorted={defaultSorted}
                                    sort={{
                                        dataField: fieldOrder.field,
                                        order: fieldOrder.order
                                    }}

                                />
                            </>
                        )}
                </ToolkitProvider>
            </>
        )
    }
    // Empty
    const empty = () => {
        return `${emptyString}`
    }
    const listItemsEmpty = () => {
        return (
            <>
                <Row>
                    <Col className="text-center">
                        <p className="text-gray-400 mb-3 table-without-results-label">
                            {empty()}
                        </p>
                    </Col>
                </Row>
            </>
        )
    }
    return (
        <>
            {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
            {(!loading && data.length === 0) && listItemsEmpty()}
            {(!loading && data.length > 0) && table()}
        </>
    )
}

export default DefaultTable;