import React from 'react'
import GuestItem from './GuestItem';
import GroupInvitationsAdmin from './GroupInvitationsAdmin';

// Props
interface ListGuestsProps {
    guests: any,
    groupAdminInivtations?: any
    loading: boolean,
    onClickDelete: Function,
    onClickDeleteInvitation: Function
}
// Modal close
const ListGuests = (props: ListGuestsProps) => {
    const { guests, loading, onClickDelete, onClickDeleteInvitation, groupAdminInivtations } = props;

    return (
        <div className="list-guests">
            <div className="list-guest-wrapper">
                {loading && <div className="guest-item-empty">Loading...</div>}
                {!loading && guests.length === 0 && (!groupAdminInivtations || groupAdminInivtations.length === 0) && <div className="guest-item-empty">No participants</div>}
                {!loading && guests.length > 0 &&
                    guests.map((guest, index) => (
                        <GuestItem
                            guest={guest}
                            key={index}
                            onClickDelete={onClickDelete}
                            onClickDeleteInvitation={onClickDeleteInvitation}
                        />
                    ))
                }
                {!loading && groupAdminInivtations && groupAdminInivtations.length > 0 &&
                    groupAdminInivtations.map((group, index) => (
                        <GroupInvitationsAdmin
                            group={group}
                            key={index}
                            onClickDeleteInvitation={onClickDeleteInvitation}

                        />
                    ))
                }
            </div>
        </div>
    )
}

export default ListGuests;