
/*eslint-disable*/
import React from "react";
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import PriorizationTab from "../priorization/PriorizationTab";
// Props
interface BoxPriorizationStringProps {
    title?: string,
    now: any,
    next: any,
    future: any,
    priorizationHelp?: string
    hideImprove?: boolean
}


const BoxPriorization = (props: BoxPriorizationStringProps) => {
    const { priorizationHelp, title, hideImprove } = props;
    // Render
    return (
        <BoxStartup className={'box-priorization h-auto'}>
            <BoxStartup.Header>
                <span className="title">{title ? title : 'What to Improve'}</span>
            </BoxStartup.Header>
            <BoxStartup.Body className="p-0">
                {priorizationHelp && <div className="priorization-help">
                    <p>{priorizationHelp} </p>
                </div>}
                <PriorizationTab {...props} />
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxPriorization;
