// user.js

export const USER_UPDATE = 'startup/users/USER_UPDATE';
export const USER_CREATE = 'startup/users/USER_CREATE';
export const USER_DELETE = 'startup/users/USER_DELETE';



export function userDelete() {
    return {
        type: USER_DELETE,
    }
}

export function userCreate(payload) {
    return {
        type: USER_CREATE,
        payload
    };
}

export function userUpdate(payload) {
    return {
        type: USER_UPDATE,
        payload
    }
}

const initialState = {
    user: null
};

export default function reducer(state = initialState, action: any) {
    // console.log('state', state)
    // console.log('action', action)
    switch (action.type) {
        case USER_CREATE:
            return Object.assign(
                {},
                state,
                {
                    user: action.payload
                }
            );
        case USER_UPDATE:
            return Object.assign(
                {},
                state,
                {
                    user: action.payload
                }
            );
        case USER_DELETE:
            return Object.assign(
                {},
                state,
                {
                    user: null
                }
            );
        default:
            return state;
    }
}

export const getUser = (state) => state.users.user;
