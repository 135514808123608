import React, { useCallback, useEffect, useState } from "react";
import { Route, Switch, useHistory } from "react-router-dom";
// Bootstrap
import Container from "react-bootstrap/Container";
// core components
import Page from "../components/page/Page";
import DashboardLeadNavbar  from '../components/navbars/DashboardLeadNavbar'

// Routes
import routes from "../routes";
// Redux
import { useSelector, useDispatch } from "react-redux";
import { getUser } from "../redux/ducks/user-duck";
import { setDashboardData, setCategories } from "../redux/ducks/dashboard-duck";
import { answerFetchLastAnswer } from "../redux/ducks/answer-duck";
// Client
import { useApolloClient } from "react-apollo";
import { GET_STARTUP_DASHBOARD_LEAD } from "../queries/startup/startups.query";
import { GET_LAST_ANSWER_DASHBOARD } from "../queries/answer/answers.query";
// Utils
import { removeToken } from "../utils";


const DashboardLead = (props: any) => {
    // Client
    const client = useApolloClient();
    // Current User
    const user = useSelector(getUser);
    // Dispatch
    const dispatch = useDispatch();
    // History
    const history = useHistory();
    // Page Id
    const [pageId, setPageId] = useState('')
    // document.body.classList.remove('with-sidebar');
    window.scrollTo(0, 0);
    // On Set Page Id
    const onSetPageId = (pageId) => {
        console.log("pageId", pageId);
        setPageId(pageId);
    };
    const getRoutes = (routes: any) => {
        return routes.map((prop: any, key: any) => {
            if (prop.layout === "/dashboard-lead") {
                return (
                    <Route
                        path={prop.layout + prop.path}
                        key={key}
                        render={(props) => (
                            <Page
                                {...props}
                                component={prop.component}
                                title={"34 Elements | " + prop.name}
                                name={prop.name}
                                onSetPageId={onSetPageId}
                                pageId={prop.key}
                            />
                        )}
                    />
                );
            } else {
                return null;
            }
        });
    };
    const fetchData = useCallback(async () => {
        console.log('fetch data');
        // Get Last Answer
        const lastAnswerResponse = await client.query({
            query: GET_LAST_ANSWER_DASHBOARD,
            fetchPolicy: "no-cache",
        });
        const lastAnswer = lastAnswerResponse.data.getLastAnswerDashboard || {};
        dispatch(answerFetchLastAnswer(lastAnswer));

        const response = await client.query({
            query: GET_STARTUP_DASHBOARD_LEAD,
            fetchPolicy: "no-cache",
        });
        // Startup
        const startup = response.data.getStartupDashboard || {};
        dispatch(setDashboardData(startup));
        // Questions External
        const categories = response.data.listCategories || [];
        dispatch(setCategories(categories));
    }, [client, dispatch]);
    useEffect(() => {
        fetchData();
    }, [fetchData]);
    useEffect(() => {
        if (user) {
            if (user.first_login) {
                if (user.startup_type === 'lead_generation') {
                    history.push('/questionnaire/intro-lead');
                } else {
                    removeToken()
                    props.history.push(`/auth/login`)
                }
            }
            if (!user.startup_type) {
                removeToken()
                props.history.push(`/auth/login`)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [user]);

    return (
        <React.Fragment>
            <DashboardLeadNavbar />
            <div className="main-content-dashboard-lead" id={pageId ? pageId : ''}>
                <div className="main-container-wrapper">
                    {/* Main Container */}
                    <div className="main-container">
                        <Container fluid>
                            <Switch>{getRoutes(routes)}</Switch>
                        </Container>
                    </div>
                </div>
            </div>
        </React.Fragment>
    );
};

export default DashboardLead;
