
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Table
import QuestionTable from "../../../components/questions/admin/QuestionTable";
// Apollo
import { useApolloClient } from "react-apollo";
// Router
import { useHistory } from "react-router-dom";
// Toastify
import { LIST_CATEGORIES } from "../../../queries/categories/categories.query";
import Button from "../../../components/buttons/Button";
import CategoryTable from "../../../components/categories/CategoryTable";



const CategoryIndexView = (props) => {
    // Router
    const history = useHistory();
    // Startups
    const [categories, setCategories] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_CATEGORIES,
            fetchPolicy: 'no-cache'
        })
        const categories = response.data.listCategories || [];
        setCategories(categories)
        setLoading(false);
    }, [client, setCategories]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickViewItem = (category) => {
        history.push(`/admin/main-elements/edit/${category.id}`)
    }
    const onClickWeights = (question) => {
        history.push(`/admin/main-elements/weights`)
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active>  Main Elements </Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <Row >
                            <Col md="6" className="d-flex">
                                <span className="title"> Main Elements</span>
                            </Col>
                            <Col md="6" className="d-flex justify-content-lg-end">
                                <Button 
                                    variant={'primary'} 
                                    size="sm"
                                    className={'ml-auto'} onClick={onClickWeights}>
                                    <i className="fas fa-sort-amount-down-alt mr-2"></i>
                                Weights
                            </Button>
                            </Col>
                        </Row>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <CategoryTable
                            categories={categories}
                            loading={loading}
                            onClickViewItem={onClickViewItem}

                        />
                    </BoxStartup.Body>
                </BoxStartup>

            </Container>
        </>
    )
}

export default CategoryIndexView;
