
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
import ButtonGroup from "react-bootstrap/ButtonGroup";
// Validation
import { Controller, FormProvider, useForm } from "react-hook-form";
// Form
import ReactSelect from "../select/ReactSelect";
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { MoonLoader } from "react-spinners";
import Uploader from "../uploader/Uploader";
import { CREATE_BEST_PRACTICE } from "../../queries/best-practice/best-practice.query";
import UrlInput from "../forms/UrlInput";

// Props
interface BesPracticeAddFormProps {
    questionTypes: any,
    categories: any
}
const BesPracticeAddForm = (props: BesPracticeAddFormProps) => {

    // Client
    const client = useApolloClient();
    // Form
    const methods = useForm({
        mode: 'onChange'
    });
    // Best Practice
    const [link, setLink] = useState('');
    const [type, setType] = useState('link');
    const [categoryQuestion, setCategoryQuestion] = useState(null);
    const [typeQuestion, setTypeQuestion] = useState(null);
    const [files, setFiles] = useState([]);
    // Loading
    const [loading, setLoading] = useState(false);
    // Options
    const [optionsCategories, setOptionCategories] = useState<any>([]);
    const [optionsType, setOptionsType] = useState<any>([]);
    const [optionsTypeFilter, setOptionsTypeFilter] = useState<any>([]);
    // Props
    const {
        questionTypes,
        categories
    } = props;
    // Reset Form
    const resetForm = () => {
        // States
        setLink('')
        setFiles([])
        setTypeQuestion(null)
        setCategoryQuestion(null)
        // Validation
        methods.setValue('link', '')
        methods.setValue('type_sku', null)
        methods.setValue('category_sku', null)
        methods.setValue('file_input', null)
        methods.setValue('name', '')
        methods.setValue('description', '')

    }
    // Type Change
    const onChangeTypeQuestion = useCallback((option) => {
        setTypeQuestion(option);
        if (option) {
            methods.setValue('type_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('type_sku', null, { shouldValidate: true })
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setTypeQuestion, methods.setValue])
    // Category Change, change filter question types
    const onChangeCategoryQuestion = useCallback((option, event) => {
        setCategoryQuestion(option);
        if (option) {
            methods.setValue('category_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('category_sku', null, { shouldValidate: true })
        }
        if (event.action === 'select-option') {
            let filter = [];
            if (option) {
                filter = optionsType.filter(filter => filter.category_sku === option.value);
            }
            setOptionsTypeFilter(filter);
            onChangeTypeQuestion(null);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setCategoryQuestion, methods.setValue, optionsType, setOptionsTypeFilter, onChangeTypeQuestion])
    // Fetch Data
    const fetchData = useCallback(() => {
        // Map options Categories
        const categoriesMap = categories.map(cat => {
            return {
                label: cat.title,
                value: cat.sku
            }
        })
        setOptionCategories(categoriesMap);
        // Map options Question Types
        const typesMap = questionTypes.map(type => {
            return {
                category_sku: type.category_sku,
                label: type.title,
                value: type.sku
            }
        })
        setOptionsType(typesMap);

    }, [
        // Options type
        setOptionsType,
        questionTypes,
        // Categories
        setOptionCategories,
        categories,
    ])

    // Init
    useEffect(() => {
        if (categories && questionTypes) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [categories, questionTypes])
    // On Change Type
    const onChangeType = (type) => {
        setType(type)
    }
    // Handle Files
    const onChangeUploader = async (files) => {
        methods.setValue('file_input', files, { shouldValidate: true })
        setFiles(files)
    }
    // Create
    const onClickCreate = async () => {
        await methods.trigger();
        const keys = Object.keys(methods.formState.errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            createBestPractice()
        }
    }
    const createBestPractice = async () => {
        try {
            setLoading(true)
            const values = methods.getValues()
            const toCreate: any = {
                ...values,
                type_file_sku: type,
                file_input: type === 'file'? values.file_input[0] : null
            }
            delete toCreate.category_sku;
            

            const createBestPracticeResp = await client.mutate({
                mutation: CREATE_BEST_PRACTICE,
                variables: { data: toCreate }
            })
            console.log('resp', createBestPracticeResp)
            const bestPractice = createBestPracticeResp.data.createBestPractice || {}
            if (bestPractice.id) {
                toast.success(`Best Practice created succefully!`);
                resetForm()
            } else {
                throw new Error('Cannot create')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }
    return (
        <LoadingOverlay active={loading}
            text={'Creating Best Practice...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods}>


                <Form noValidate name={'create-best-practice'}>
                    <Row>
                        <Col lg="6">
                            <Form.Group >
                                <Form.Label className="d-block">Best Practice Document</Form.Label>
                                <ButtonGroup aria-label="Basic example">
                                    <Button variant="primary" onClick={() => onChangeType('link')} className={type === 'link' ? 'btn-violet' : 'btn-light'} >Link</Button>
                                    <Button variant="primary" onClick={() => onChangeType('file')} className={type === 'file' ? 'btn-violet' : 'btn-light'} >File</Button>
                                </ButtonGroup>
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* File */}
                    { type === 'file' && 
                    <Row>
                        <Col lg="6">
                            <Form.Group className={`${methods.errors.file_input ? 'is-invalid' : ''}`}>                                
                                <Controller
                                    name="file_input"
                                    control={methods.control}
                                    defaultValue={false}
                                    rules={{ required: 'File is required' }}
                                    default
                                    render={props => (
                                        <Uploader
                                            onChange={onChangeUploader}
                                            files={files}
                                        />
                                    )} />
                                {methods.errors.file_input && <div className="invalid-feedback"> {methods.errors.file_input.message} </div>}
                            </Form.Group >
                        </Col>
                    </Row>}                    
                    {/* Or Link */}
                    { type === 'link' && 
                    <Row>
                        <Col lg="9">
                            <UrlInput
                                name="link"
                                value={link}
                                onChange={(value) => setLink(value)}
                            />

                        </Col>
                    </Row>}
                    {/* Relation */}
                    <Row>
                        <Col lg="9">
                            <Row>
                                {/* Main element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.category_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Main element</Form.Label>
                                        <Controller
                                            name="category_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Main element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a main-element"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.category_sku}
                                                    options={optionsCategories}
                                                    onChange={onChangeCategoryQuestion}
                                                    value={categoryQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {methods.errors.category_sku && <div className="invalid-feedback"> {methods.errors.category_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                                {/* Sub element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${methods.errors.type_sku ? 'is-invalid' : ''}`}>
                                        <Form.Label>Sub element</Form.Label>
                                        <Controller
                                            name="type_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Sub element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a sub-element"
                                                    isClearable={true}
                                                    isInvalid={methods.errors.type_sku}
                                                    options={optionsTypeFilter}
                                                    onChange={onChangeTypeQuestion}
                                                    value={typeQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />
                                        {methods.errors.type_sku && <div className="invalid-feedback"> {methods.errors.type_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Name */}
                    <Row>
                        <Col lg="9">
                            <Form.Group >
                                <Form.Label>Name</Form.Label>
                                <Form.Control
                                    name="name"
                                    type="text"
                                    placeholder="Enter a description"
                                    isInvalid={methods.errors.name}
                                    ref={methods.register({ required: 'The name is required' })}
                                />
                                {methods.errors.name && <div className="invalid-feedback"> {methods.errors.name.message} </div>}

                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Description */}
                    <Row>
                        <Col lg="9">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Description</Form.Label>
                                <Form.Control
                                    name="description"
                                    type="text"
                                    placeholder="Enter a description"
                                    as={'textarea'}
                                    rows={3}
                                    ref={methods.register}
                                />
                            </Form.Group>
                        </Col>
                    </Row>
                    <Button variant="success"
                        id="btn-finish-question"
                        onClick={onClickCreate}
                        disabled={loading}
                        className="btn-fixed fixed-right shadow">
                        {!loading && <i className="fas fa-save icon"></i>}
                        {loading && <MoonLoader color="#8e8e93" size={40} />}
                    </Button>

                </Form>
            </FormProvider>
        </LoadingOverlay>
    )
}

export default BesPracticeAddForm;