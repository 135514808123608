import gql from 'graphql-tag'

export const TRACK_FIELDS = gql`
fragment TrackFields on Track {        
    id    
    type_sku    
    title
    description
    active    
    order
    locked

    created_at
    updated_at
}`