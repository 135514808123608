import React, { useCallback, useEffect, useRef, useState } from 'react';
// D3
import * as d3 from 'd3';
// Hook
import useWindowSize from '../../../hooks/useWindowResize';
import StartupHelper from '../../../helpers/startup/startup.helper';



interface ProgressBarChartProps {
    data: {
        label: string,
        percentage: number,
        color: string
    },
    settings?: {
        height?: number,
        heightBar?: number,
        bgColor?: string,
        color?: string,
        labelColor?: string,
    }

}

const ProgressBarInlineChart = (props: ProgressBarChartProps) => {    
    // Helper
    const startupHelper = new StartupHelper();
    // Hook Window
    const windowSize = useWindowSize();
    // Props
    const { data: dataProps, settings: settingsProps } = props;    

    // Line Height
    const [lineHeight, setLineHeight] = useState<any>(6)
    // State
    const defaultSettings = {
        height: 6,
        heightBar: 4,
        bgColor: '#fff',
        labelColor: '#514E6B'
    }
    const [data, setData] = useState<any>(null)
    const [settings, setSettings] = useState<any>(null)
    // Color
     const [color, setColor] = useState<any>('#433D8F')
    // Size
    // const [size, setSize] = useState<any>({ width: 'auto', height: 'auto' })

    // Chart
    const chartEl = useRef<HTMLDivElement>(null);
    const parentEl = useRef<HTMLDivElement>(null);
    // Fetch Data
    const fetchData = useCallback(() => {
        const settings = Object.assign(defaultSettings, {})
        if (settingsProps) {
            if (settingsProps.height) {
                settings.height = settingsProps.height;
            }
            if (settingsProps.heightBar ) {
                settings.heightBar = settingsProps.heightBar;
            }
            if (settingsProps.labelColor ) {
                settings.labelColor = settingsProps.labelColor;
            }
        }
        setSettings(settings);
        setData(dataProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps, settingsProps, setData, setSettings])

    const setup = (parentEl, chartEl) => {
        // Element width
        const elementSize = parentEl.current.clientWidth;
        // Svg Styles
        const margin = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
        let width = Math.min(elementSize, 500) - margin.left - margin.right;
        let height = settings.height - margin.top - margin.bottom

        // Renove Svg
        d3.select(chartEl.current)
            .select("svg").remove()
        // Create
        const svg = d3
            .select(chartEl.current)
            .append("svg")
            .attr("width", (width + margin.left + margin.right))
            .attr("height", (height + margin.top + margin.bottom))
            .append("g").attr("class", "wrapper")
         // .attr("transform", "translate(0,0)");

        return { svg, margin, width, height }
    }

    // Add logic to draw the chart here
    const drawChart = useCallback(() => {
        if (chartEl && chartEl.current && data && data.percentage) {
            // Setup
            const { svg, width, height } = setup(parentEl, chartEl);
            // Set Styles
            setLineHeight(height)
            const status = startupHelper.resolvePercentageScoringStyle(data.percentage);
            setColor(status.color)
            // Scale of progress bar
            const scale = d3.scaleLinear().domain([0, 100]).range([0, width - 2]);
            // Rect Background
            svg.append('rect')
                .attr('class', 'bg-rect')
                .attr('rx', 2)
                .attr('ry', 2)
                .attr('fill', settings.bgColor)
                .attr('height', settings.height)
                .attr('width', width)
                .attr('x', 0)
                .attr('y', 0);
            // Progress
            const progress =
                svg.append('rect')
                    .attr('class', 'progress-rect')
                    .attr('fill', status.color)
                    .attr('height', settings.heightBar)
                    .attr('width', 0)
                    .attr('rx', 1)
                    .attr('ry', 1)
                    .attr('x', 1)
                    .attr('y', 1);

            progress
                .transition()
                .duration(1000)
                .attr('width', scale(data.percentage) as any);
 

        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartEl, parentEl, settings, data])

    // On Change Props
    useEffect(() => {
        if (dataProps && dataProps !== data && settings !== settingsProps) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps, settingsProps, fetchData, data]);
    // Resize when call third time (first undefined, second with window value)
    useEffect(() => {
        if (windowSize.width && data && settings) {
            drawChart();
        }
    }, [windowSize, data, settings, drawChart]);


    return (
        <div ref={parentEl} className="startup-progress-chart-inline" style={{color: color}}>
            <div className="startup-progress-chart-inline-header">
                <span className="label" style={{color: settings && settings.labelColor ? settings.labelColor : 'inherit'}}>{data && data.label ? data.label : ''}</span>
                <span className="percentage" >{data && data.percentage ? data.percentage : '0'}%</span>
            </div>
            <div className="progress-bar-wrapper" style={{lineHeight: lineHeight + 'px'}}>
                <div ref={chartEl} className="startup-progress-container"></div>
            </div>

        </div>
    )
}

export default ProgressBarInlineChart;