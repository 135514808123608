import React from 'react';
import NoteItem from './NoteItem';


interface NoteListProps {
    note: Array<any>
}

const NoteList = (props: NoteListProps) => {
    // Props
    const { note } = props;

    return (
        <div className="note-list" >
            {!note && 
                <div className="note-item-empty" >
                    Loading...
                </div>
            }
            {note &&  <NoteItem note={note} />}
        </div>
    );
};
export default NoteList;