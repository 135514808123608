import React from 'react';
import MenuItem from './MenuItem';
// import { NavLink } from "react-router-dom";
// Redux
import { useSelector } from 'react-redux';
import { getUser } from '../../redux/ducks/user-duck';
interface SidebarMenuProps {
    home: any,
    menu: any,
    categoriesMenu?: any,
    extraMenu?: any
    menuType?: 'normal' | 'limited';
}

const SidebarMenu = (props: SidebarMenuProps) => {
    const user = useSelector(getUser);

    const { menu, home, extraMenu, categoriesMenu, menuType } = props;

    return (

        <ul className="sidebar-menu">
            {/* Home */}
            <MenuItem item={home} hasSubmenu={false} />
            {/* Divider */}
            <li className="divider"></li>
            {/* Items */}
            {menu && menu.map((item, index) => (<MenuItem item={item} key={index} hasSubmenu={true}  menuType={menuType} />))}
            {user && user.primary_account &&
                <>
                    {categoriesMenu && <>
                        <li className="divider"></li>
                        {categoriesMenu && categoriesMenu.map((item, index) => (<MenuItem item={item} key={index} hasSubmenu={false} />))}
                    </>}
                    {/* Divider */}
                    <li className="divider"></li>
                    {/* Extra Menu */}
                    {extraMenu && extraMenu.map((item, index) => (<MenuItem item={item} key={index} hasSubmenu={false} />))}
                    {/* Divider */}
                    {/* <li className="divider"></li>
                    <li className={"sidebar-menu-item"}>
                        <NavLink className="nav-link nav-violet" 
                            id="nav-link-go-premium"
                            to={'/dashboard/go-premium'} >
                            <span>Go Premium</span>
                        </NavLink>
                    </li> */}
                </>
            }
        </ul>
    );
}

export default SidebarMenu;