import React, { useState, useEffect, useCallback } from 'react';
// Bootstrap
import Form from 'react-bootstrap/Form';
// Constants
import {
    CHALLENGES_PRIORITY_LEVEL,
    CHALLENGES_PRIORITY_TESTABILITY,
    CHALLENGES_STATUS
} from '../../constants/challenges/challenges.constants';


interface ChallengesFilterProps {
    onChange?: Function
}

const ChallengesFilter = React.forwardRef((props: ChallengesFilterProps, ref: any) => {
    // Props
    const { onChange } = props;
    // Filter
    const [firstTime, setFirstTime] = useState(true);
    const [filter, setFilter] = useState({
        priority: null,
        testability: null,
        blocking: null,
        conviction_level: null,
        critical: null,
        status: null
    })
    const onChangeSelect = (event) => {
        const { target } = event;
        const { name, value } = target;
        setFilter(prevState => ({ ...prevState, [name]: value !== '' ? value : null }));
    }
    const onChangeCheckbox = (event) => {
        const { target } = event;
        const { name, checked } = target;
        setFilter(prevState => ({ ...prevState, [name]: checked }));
    }
    const onChangeFilter = useCallback(() => {
        if (firstTime) {
            setFirstTime(false)
        } else {
            if (onChange) {
                onChange(filter)
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter, firstTime, setFirstTime])
    useEffect(() => {
        onChangeFilter()
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [filter])

    return (
        <div className="challenge-filter" ref={ref}>
            <Form className="form-inline">
                {/* Priority */}
                <Form.Group className={'sm'} id="form-group-priority">
                    <Form.Label className={'text-dark'}>Priority Level:</Form.Label>
                    <Form.Control
                        as="select"
                        name="priority"
                        type="text"
                        placeholder="Select..."
                        className="custom-select custom-select-sm"
                        onChange={onChangeSelect}
                        defaultValue=""
                    >
                        <option value="">All</option>
                        {CHALLENGES_PRIORITY_LEVEL.map((priority, index) => (
                            <option value={priority.sku} key={index}>{priority.title}</option>
                        ))}

                    </Form.Control>
                </Form.Group>
                {/* Testability */}
                <Form.Group className={'sm'} id="form-group-testability">
                    <Form.Label className={'text-dark'}>Testability:</Form.Label>
                    <Form.Control
                        as="select"
                        name="testability"
                        type="text"
                        placeholder="Select..."
                        className="custom-select custom-select-sm"
                        onChange={onChangeSelect}
                        defaultValue=""
                    >
                        <option value="">All</option>
                        {CHALLENGES_PRIORITY_TESTABILITY.map((testability, index) => (
                            <option value={testability.sku} key={index}>{testability.title}</option>
                        ))}

                    </Form.Control>
                </Form.Group>
                {/* Blocking */}
                <Form.Group className={'sm'} id="form-group-blocking">
                    <Form.Check
                        className={'custom-checkbox-sm invested text-dark'}
                        custom
                        type={'checkbox'}
                        id={`assumption-filter-blocking`}
                        label={`Blocking:`}
                        name="blocking"
                        value={'blocking'}
                        onChange={onChangeCheckbox}
                    />
                </Form.Group>
                {/* Critical */}
                <Form.Group className={'sm'} id="form-group-critical">
                    <Form.Check
                        className={'custom-checkbox-sm invested text-dark'}
                        custom
                        type={'checkbox'}
                        id={`assumption-filter-next-round`}
                        label={`Critical`}
                        value={'critical'}
                        name={'critical'}
                        onChange={onChangeCheckbox}
                    />
                </Form.Group>
                {/* Status */}
                <Form.Group className={'sm'} id="form-group-conviction">
                    <Form.Label className={'text-dark'}>Status:</Form.Label>
                    <Form.Control
                        as={'select'}
                        className="custom-select custom-select-sm"
                        name="status"
                        onChange={onChangeSelect}
                        defaultValue=""
                    >
                        <option value="">All</option>
                        {CHALLENGES_STATUS.map((status, index) => (
                            <option value={status.sku} key={index}>{status.title}</option>
                        ))}
                    </Form.Control>
                </Form.Group>
            </Form>
        </div>
    );
})
export default ChallengesFilter;