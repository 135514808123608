/*eslint-disable*/
import React from "react";
import RegisterForm from "../../components/register/RegisterForm";

// Register
const Register = (props) => {
    return (<RegisterForm type="lead_generation" title="Register Diagnostic" />)
}

export default Register;
