/*eslint-disable*/
import React from "react";
import Image from "react-bootstrap/Image";


const QuestionnaireNavBar = (props: any) => {
  return (
    <header id="main-header-questionnaire">
      <div className="logo-container">
        <Image src={require("../../assets/img/logo-questionnaire.svg").default} />
      </div>
    </header>
  )
}

export default QuestionnaireNavBar;
