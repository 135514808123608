
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_QUESTION_BY_ID, } from "../../../queries/questions/questions.query";
// Router
import { useParams } from "react-router-dom";
// Form
import QuestionEditForm from "../../../components/questions/admin/QuestionEditForm";
import Spinner from "react-bootstrap/Spinner";
// Hooks
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";

const QuestionEditView = (props) => {
    // Question Id
    const { question_id } = useParams<any>();
    // Question
    const [question, setQuestion] = useState<any>(null);
    const [questionTypes, setQuestionTypes] = useState([]);
    const [categories, setCategories] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/questions");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: GET_QUESTION_BY_ID,
            variables: { id: question_id },
            fetchPolicy: 'no-cache'
        })
        console.log(response)
        // Question
        const question = response.data.getQuestionById || {};
        setQuestion(question)
        // Question Types
        const questionTypes = response.data.listQuestionTypes || [];
        setQuestionTypes(questionTypes)
        // Categories
        const categories = response.data.listCategories || [];
        setCategories(categories)
        // Loading
        setLoading(false);
    }, [
        client,
        setQuestion,
        setQuestionTypes,
        setCategories,
        setLoading,
        question_id]);
    // Call Fetch Data
    useEffect(() => {
        if (question_id) {
            fetchData();
        }
    }, [question_id, fetchData])
    
    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/questions${search}`}>Questions</Breadcrumb.Item>
                    <Breadcrumb.Item active>Edit Question</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">Question  {question && <b>Order {question.order}</b>}</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <QuestionEditForm question={question} questionTypes={questionTypes} categories={categories} />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default QuestionEditView;