import MainView from "./views/dashboard-lead/MainView";



const routesDashboard = [

  {
    path: "/main",
    name: "Main Dashboard",
    icon: "ni ni-key-25",
    component: MainView,
    layout: "/dashboard-lead",
    key: "main-dashboard",
  },
];
export default routesDashboard;
