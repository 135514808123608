import { group } from "d3";
import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateHelper from "../../helpers/dates/date.helper";
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";

// Props
interface UserTableProps {
    users: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickEditItem?: Function,
    onClickDeleteItem?: Function
}
// Reports Table
const UserTable = (props: UserTableProps) => {
    const dateHelper = new DateHelper();
    // Props
    const { users, loading, emptyString, onClickDeleteItem } = props;
    // On Click View
    const onClickEditUser = (user) => {
        if (props.onClickEditItem) {
            props.onClickEditItem(user);
        }
    }
    // On Click Delete Item
    const onClickDelete = (user) => {
        if (onClickDeleteItem) {
            onClickDeleteItem(user);
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            Edit User
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickEditUser(row)}
                    >
                        <i className="far fa-edit"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-trash'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-trash`}>
                            Delete User
                        </Tooltip>
                    }>
                    <button type="button"
                        className="btn btn-outline-danger btn-rounded-action action-table"
                        onClick={() => onClickDelete(row)}>
                        <i className="far fa-trash-alt"></i>
                    </button>
                </OverlayTrigger>
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },

        {

            dataField: 'email',
            text: 'Email',
            sort: true,
        },
        {

            dataField: 'first_name',
            text: 'First Name',
            sort: true,
        },
        {

            dataField: 'last_name',
            text: 'Last Name',
            sort: true,
        },
        {

            dataField: 'startup.name',
            text: 'Startup',
            sort: true,
        },
        {

            dataField: 'startup.group',
            text: 'Group',
            sort: true,
            formatter: cell => !cell ? 'Without Group' : cell.name,
        },
        {

            dataField: 'created_at',
            text: 'Created',
            sort: true,
            formatter: cell => dateHelper.format(cell),
            sortValue: cell => dateHelper.sortValue(cell)
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
            // headerStyle: { minWidth: 'px' }
        }
    ];


    return (
        <>
            <DefaultTable
                data={users}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'created_at',
                        order: 'desc'
                    }
                }
            />
        </>
    )
}

export default UserTable;