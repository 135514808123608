

import questionnaire from './questionnaire-duck'
import users from './user-duck'
import dashboard from './dashboard-duck'
import challenges from './challenge-duck'
import answer from './answer-duck'
import advices from './advice-duck'
import guests from './guest-duck'
import questionnaireGuest from './questionnaire-guest-duck'
import questionnaireGuestExternal from './questionnaire-guest-external-duck'
import modalWrapper from './modal-wrapper-duck'
import files from './files-duck'
import letstalk from './lest-talks-duck'
import questionnaireLead from './questionnaire-lead-duck'

const reducers = {
    questionnaire,
    users,
    dashboard,
    challenges,
    answer,
    advices,
    guests,
    questionnaireGuest,
    questionnaireGuestExternal,
    modalWrapper,
    files,
    letstalk,
    questionnaireLead
}
export default (reducers);
