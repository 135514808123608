
import gql from 'graphql-tag'

export const GUEST_GROUP_FIELDS = gql`
fragment GuestGroupFields on GuestGroup {    
    id
    startup_id
    name   
    types 
    link
    created_at
    updated_at
    admin_created
}`
