
/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// Charts
import MiddleGaugeChart from '../charts/gauge-chart/MiddleGaugeChart';
import BoxStartup from "../box/BoxStartup";
import { ReactSVG } from "react-svg";
// Window
import useWindowSize from "../../hooks/useWindowResize";
// Button
import Button from "../buttons/Button";
// Redux
import { useDispatch } from 'react-redux';
import { answerUpdateFirstScoring, answerUpdateRefinedScoring } from "../../redux/ducks/answer-duck";
// Props
interface BoxFundabilitySubElementProp {
    subElement: string,
    subElementDescription: string,
    fundability: any,
    fundabilityInternal: any,
    fundabilityExternal: any,
    hasFirstChosen: boolean,
    hasRefinedChosen: boolean,
    onChangeBodyHeight?: Function,
}


const BoxFundabilitySubElement = (props: BoxFundabilitySubElementProp) => {
    // Dispatch
    const dispatch = useDispatch();
    // Hook Window
    const windowSize = useWindowSize();
    // Icon
    const openIconSrc = require("../../assets/img/open-outline-violet.svg").default;
    const openIconWhiteSrc = require("../../assets/img/open-outline.svg").default;
    // Filter
    const [filter, setFilter] = useState({
        internal: false,
        external: false
    })
    // Props
    const { fundability, fundabilityInternal, fundabilityExternal, onChangeBodyHeight, subElement, subElementDescription, hasRefinedChosen, hasFirstChosen } = props;
    // Internal
    const [internal, setInternal] = useState<any>(null)
    // External
    const [external, setExternal] = useState<any>(null)
    // Body Ref
    const boxBodyRef = useRef<HTMLDivElement>(null);
    // Needle
    const needle = require("../../assets/img/needle/needle-min.svg").default;
    // Click First Scoring
    const onClickFirstScoring = () => {
        dispatch(answerUpdateFirstScoring(true))
    }
    // Click Refined Scoring
    const onClickRefinedScoring = () => {
        dispatch(answerUpdateRefinedScoring(true))
    }
    // On Change
    const changeSizes = useCallback(() => {

        setTimeout(() => {
            if (boxBodyRef && boxBodyRef.current) {
                if (onChangeBodyHeight) {
                    onChangeBodyHeight(boxBodyRef.current.clientHeight, windowSize);
                }
            }
        }, 200)

    }, [windowSize, boxBodyRef])
    // Change
    useEffect(() => {
        if (windowSize.width && boxBodyRef.current) {
            changeSizes()
        }
    }, [windowSize, boxBodyRef, changeSizes]);
    // On Change Filter
    const onChangeCheckbox = (event) => {
        const { target } = event;
        const { name, checked } = target;
        setFilter(prevState => ({ ...prevState, [name]: checked }));
    }
    // Filter
    const onChangeFilter = useCallback(() => {

        if (filter.internal) {
            if (fundabilityInternal) {
                setInternal([...fundabilityInternal])
            }
        } else {
            setInternal(null);
        }
        if (filter.external) {
            if (fundabilityExternal) {
                setExternal([...fundabilityExternal])
            }
        } else {
            setExternal(null);
        }
    }, [filter, setInternal, setExternal])
    useEffect(() => {
        onChangeFilter()
    }, [filter, onChangeFilter])
    const resetData = useCallback(() => {
        setFilter({
            internal: false,
            external: false
        })
    }, [setFilter])
    useEffect(() => {
        resetData()
    }, [subElement, resetData])
    // Render
    return (
        <BoxStartup className={'h-auto'} id="box-fundability-sub-element">
            <BoxStartup.Header>
                <Row>
                    <Col lg={5}>
                        <span className="title">{subElement}</span>
                    </Col>
                    <Col lg={7} className={'d-flex justify-content-lg-end mt-2 mt-lg-0'}>
                        <Button size="sm"
                            className={`${hasFirstChosen ? 'btn-light-violet' : 'btn-violet'} font-weight-medium`}
                            onClick={onClickFirstScoring}>
                            <ReactSVG src={hasFirstChosen ? openIconSrc : openIconWhiteSrc} wrapper="span" className="icon open-icon" />
                            <span>First Scoring</span>
                        </Button>
                        <Button size="sm"
                            className={`${hasRefinedChosen ? 'btn-light-violet' : 'btn-violet'} font-weight-medium`}
                            disabled={!hasFirstChosen}
                            onClick={onClickRefinedScoring}>
                            <ReactSVG src={hasRefinedChosen ? openIconSrc : openIconWhiteSrc} wrapper="span" className="icon open-icon" />
                            <span>Refine Score</span>
                        </Button>
                    </Col>
                </Row>

            </BoxStartup.Header>
            <BoxStartup.Body ref={boxBodyRef}>
                {/* Header */}
                <div className="box-sub-element-header">
                    <p>{subElementDescription}</p>
                </div>
                {/* Chart */}
                <div className="mb-4" id="main-chart-container">
                    <MiddleGaugeChart data={fundability} internal={internal} external={external} />
                    <span className="title-chart-sub-element">{subElement}</span>
                </div>
                {/* Footer */}
                <div className="box-sub-element-footer">
                    {/* Title */}
                    <div className="footer-title">
                        <span>Rating View:</span>
                    </div>

                    {/* Internal */}
                    <div className="checkbox-with-icon">
                        <Form.Check
                            className={'custom-checkbox-sm'}
                            custom
                            name={'internal'}
                            type={'checkbox'}
                            id={`custom-checkbox-seed`}
                            label={`Internal`}
                            checked={filter.internal}
                            onChange={onChangeCheckbox}
                        />
                        <ReactSVG src={needle} wrapper="span" className="icon-checkbox comparision-icon yellow" />
                    </div>
                    {/* External */}
                    <div className="checkbox-with-icon">
                        <Form.Check
                            className={'custom-checkbox-sm'}
                            custom
                            name={'external'}
                            type={'checkbox'}
                            id={`custom-checkbox-series-a`}
                            label={`External`}
                            checked={filter.external}
                            onChange={onChangeCheckbox}
                        />
                        <ReactSVG src={needle} wrapper="span" className="comparision-icon" />
                    </div>

                </div>

            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxFundabilitySubElement;
