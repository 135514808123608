import gql from 'graphql-tag'
import { CHALLENGE_TEMPLATE_FIELDS } from './challenge-template.fragment'

// List
export const LIST_CHALLENGES_TEMPLATES = gql`
${CHALLENGE_TEMPLATE_FIELDS}
query listChallengeTemplates{            
    listChallengeTemplates{      
        ...ChallengeTemplateFields    
        track{
            title
            type{
                title
                category{
                    title
                }
            }
            
        }
    }
}`



// Get by ID
export const GET_CHALLENGE_TEMPLATE_BY_ID = gql`
${CHALLENGE_TEMPLATE_FIELDS}
query getChallengeTemplateById($id: String!){            
    getChallengeTemplateById(id: $id){      
        ...ChallengeTemplateFields    
        track{
            title
            type{
                title
                category{
                    title
                }
            }
            
        }
    }
}`
// Create
export const CREATE_CHALLENGE_TEMPLATE = gql`
${CHALLENGE_TEMPLATE_FIELDS}
mutation createChallengeTemplate($data: ChallengeTemplateInput!){            
    createChallengeTemplate(data: $data){
        ...ChallengeTemplateFields             
    }
}`
// Update
export const UPDATE_CHALLENGE_TEMPLATE = gql`
${CHALLENGE_TEMPLATE_FIELDS}
mutation updateChallengeTemplate($data: ChallengeTemplateInput!){            
    updateChallengeTemplate(data: $data){
        ...ChallengeTemplateFields             
    }
}`
// Delete
export const DELETE_CHALLENGE_TEMPLATE = gql`
${CHALLENGE_TEMPLATE_FIELDS}
mutation deleteChallengeTemplate($id: String!){            
    deleteChallengeTemplate(id: $id){
        ...ChallengeTemplateFields             
    }
}`
