/*eslint-disable*/
import React from "react";
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';

const QuestionFooter = (props) => {
    return (

        <footer className="admin-footer">
            <Container>
                <Row>
                    <Col lg="6" className="d-flex justify-content-lg-start justify-content-center">
                        <a className="navbar-brand" href="/questions/answer">
                            Startup
                        </a>
                    </Col>
                    <Col lg="6" className="d-flex align-items-center">
                        <p >
                            All rights reserved <span>v1.0</span>
                        </p>
                    </Col>
                </Row>
            </Container>

        </footer>
    )
}
export default QuestionFooter;