import React, { useState } from 'react';
// Svg
import { ReactSVG } from "react-svg";
// Moment
import Moment from 'react-moment';
import PrettyFileSize from '../files/PrettyFileSize';
// Loader
import Loader from 'rsuite/lib/Loader';
// Helper
import FileHelper from '../../helpers/files/file.helper';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Props
interface BestPracticeItemProps {
    file: any
}

const BestPracticeItem = (props: BestPracticeItemProps) => {
    // Helper
    const fileHelper = new FileHelper()
    // Props
    const { file } = props;
    // Fetch
    const [isFetch, setIsFecth] = useState(false)
    // Icons
    const icon = require(`../../assets/img/files/icon_${fileHelper.resolveIcon(file)}.svg`).default;
    const download = require("../../assets/img/files/download.svg").default;
    const calendar = require("../../assets/img/challenges/calendar.svg").default;
    // Fetch file
    const fetchFile = async () => {
        try {
            setIsFecth(true);
            // const options = {
            //     method: 'GET',
            //     headers: { 'Content-Type': file.type }
            // };
            const responseFile = await fetch(file.url)
            console.log('responseFile', responseFile)
            const blob = await responseFile.blob()

            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            console.log('file.name', file.name)
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                file.name + '.' + file.extension,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link && link.parentNode) {
                link.parentNode.removeChild(link);
            }

        } catch (e) {
            console.log(e)
        } finally {
            setIsFecth(false);
        }
    }
    const onClickDownload = async () => {
        if (!isFetch) {
            if (file.type === 'file') {
                fetchFile()
            } else {
                window.open(file.url, '_blank');
            }
        }
    }
    return (
        <div className={`best-practice-item ${isFetch ? 'is-fetch' : ''}`}>
            <Row>
                {/* File */}
                <Col xs={10} md={6}>
                    <div className="file-content">
                        <ReactSVG src={icon} wrapper="span" className="icon-file" />
                        <div className="file-item-content">
                            <span className="file-name">{file.name}</span>
                            <span className="file-date-and-size">
                                {file.size > 0 ? <span><PrettyFileSize>{file.size}</PrettyFileSize></span> : ''}
                            </span>
                        </div>
                    </div>
                </Col>
                {/* Download */}
                <Col xs={2} md={1} className={'d-flex align-items-center justify-content-center'}>
                    <div className={`file-download-wrapper ${isFetch ? 'is-fetch' : ''}`} >
                        <span className="file-download" onClick={onClickDownload}>
                            <ReactSVG src={download} wrapper="span" className="icon-download" />
                            <Loader speed="slow" className="rs-loader-primmary" />
                        </span>
                    </div>
                </Col>
                <Col md={2} className={'d-flex align-items-center justify-content-start justify-content-md-center mt-2 mt-md-0'}>
                    <span className={`badge-challenge category`}>
                        {file._object.type.category.title}
                    </span>
                </Col>
                <Col md={3} className={'d-flex align-items-center justify-content-start justify-content-md-end my-3 my-md-0'}>
                    <div className="file-created">
                        <ReactSVG src={calendar} wrapper="span" className="calendar-icon svg-baseline" />
                        <span className="created-date">Added on <Moment format="MMMM D[,] YYYY">{file.created_at}</Moment>
                        </span>
                    </div>
                </Col>
            </Row>
        </div>
    );
};
export default BestPracticeItem;