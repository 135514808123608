// questionnaire-duck.js
// Actions
const RESET = 'startup/questionnaire-lead/RESET';
const RESET_ANSWERS = 'startup/questionnaire-lead/RESET_ANSWERS';
const UPDATE_STARTUP = 'startup/questionnaire-lead/UPDATE_STARTUP';
const UPDATE_ADVANCE = 'startup/questionnaire-lead/UPDATE_ADVANCE';
const UPDATE_QUESTIONS_PAGE = 'startup/questionnaire-lead/UPDATE_QUESTIONS_PAGE';
const SET_QUESTIONS = 'startup/questionnaire-lead/SET_QUESTIONS';
const SET_ANSWERS = 'startup/questionnaire-lead/SET_ANSWERS';
const SET_LAST_ANSWER = 'startup/questionnaire-lead/SET_LAST_ANSWER';
const SET_QUESTIONNAIRE_PAGE = 'startup/questionnaire-lead/SET_QUESTIONNAIRE_PAGE';
// Action Creators
export function resetQuestionnaireLead() {
  return {
    type: RESET
  };
}
export function resetAnswersLead() {
  return {
    type: RESET_ANSWERS
  };
}
export function updateStartupLead(payload: any) {
  return {
    type: UPDATE_STARTUP,
    payload: payload
  };
}
export function updateAdvanceLead(payload: any) {
  return {
    type: UPDATE_ADVANCE,
    payload: payload
  };
}

export function updateQuestionsPageLead(payload: any) {
  return {
    type: UPDATE_QUESTIONS_PAGE,
    payload: payload
  };
}

export function setQuestionsLead(state: []) {
  return {
    type: SET_QUESTIONS,
    payload: state
  };
}
export function setAnswersLead(state) {
  return {
    type: SET_ANSWERS,
    payload: state
  };
}
export function setLastAnswerLead(state) {
  return {
    type: SET_LAST_ANSWER,
    payload: state
  };
}
export function setQuestionnairePageLead(state) {
  return {
    type: SET_QUESTIONNAIRE_PAGE,
    payload: state
  };
}
// Interface
interface QuestionnaireState {
  questionnaire_page: any,
  startup_questionnaire: any,
  questions: any,
  questions_page: number
  answers: any,
  user: any,
  advance: number,
  lastAnswer: any,
}
// Percentage total 11 pages
// 1 = 9 | 4 pages 36%
// 7 = 64% | questions
// Initial State
const initialState: QuestionnaireState = {
  questionnaire_page: null,
  startup_questionnaire: null,
  questions: [],
  questions_page: 1,
  answers: {},
  user: null,
  advance: 0,
  lastAnswer: null
};
// Reducer
export default function reducer(state: QuestionnaireState = initialState, action: any = {}) {
  // console.log('Questionnaire', action)
  switch (action.type) {
    // Update Startup Data
    case RESET: {
      return Object.assign(
        {},
        state,
        {
          ...initialState
        }
      );
    }
    case RESET_ANSWERS: {
      return Object.assign(
        {},
        state,
        {
          answers: {}
        }
      );
    }

    // Update Startup Data
    case UPDATE_STARTUP: {
      return Object.assign(
        {},
        state,
        {
          startup_questionnaire: action.payload
        }
      );
    }
    // Update Advance
    case UPDATE_ADVANCE: {
      return Object.assign(
        {},
        state,
        {
          advance: action.payload
        }
      );
    }
    // Update Questions Page
    case UPDATE_QUESTIONS_PAGE: {
      return Object.assign(
        {},
        state,
        {
          questions_page: action.payload
        }
      );
    }
    // Set Questions
    case SET_QUESTIONS:
      return Object.assign(
        {},
        state,
        {
          questions: action.payload || []
        }
      );
    // Set Answers
    case SET_ANSWERS:
      const answers = { ...state.answers, ...action.payload.data };
      return Object.assign(
        {},
        state,
        {
          answers: answers
        }
      );
    // Set Last Answers
    case SET_LAST_ANSWER:
      return Object.assign(
        {},
        state,
        {
          lastAnswer: action.payload
        }
      );
    case SET_QUESTIONNAIRE_PAGE:
      return Object.assign(
        {},
        state,
        {
          questionnaire_page: action.payload
        }
      );

    default:
      return state;
  }
}



// SELECTORS
export const getQuestionnaireStartupLead = (state) => state.questionnaireLead.startup_questionnaire;
export const getQuestionnaireAdvanceLead = (state) => state.questionnaireLead.advance;
export const getQuestionnaireQuestionsLead = (state) => state.questionnaireLead.questions;
export const getQuestionnaireQuestionsPageLead = (state) => state.questionnaireLead.questions_page;
export const getQuestionnaireAnswersLead = (state) => state.questionnaireLead.answers;
export const getQuestionnaireLastAnswerLead = (state) => state.questionnaireLead.lastAnswer;
export const getQuestionnairePageLead = (state) => state.questionnaireLead.questionnaire_page;