import gql from "graphql-tag";
import { CHALLENGE_TEMPLATE_FIELDS } from "../challenge-template/challenge-template.fragment";
import { TRACK_FIELDS } from "./track.fragment";

// List
export const LIST_TRACKS = gql`
  ${TRACK_FIELDS}
  query listTracks {
    listTracks {
      ...TrackFields
      type {
        title
        category_sku
        category {
          title
        }
      }
    }
  }
`;
export const LIST_TRACKS_BY_TYPE_SKU = gql`
  ${TRACK_FIELDS}
  ${CHALLENGE_TEMPLATE_FIELDS}
  query listTracksByTypeSku($type_sku: String!) {
    listTracksByTypeSku(type_sku: $type_sku) {
      ...TrackFields
      type {
        title
        category_sku
        category {
          title
        }
      }
      challenge_templates{
        ...ChallengeTemplateFields
      }
    }
  }
`;

// Get by ID
export const GET_TRACK_BY_ID = gql`
  ${TRACK_FIELDS}
  query getTrackById($id: String!) {
    getTrackById(id: $id) {
      ...TrackFields
      type {
        sku
        title
        category_sku
        category {
          sku
          title
        }
      }
    }
  }
`;
// Create
export const CREATE_TRACK = gql`
  ${TRACK_FIELDS}
  mutation createTrack($data: TrackInput!) {
    createTrack(data: $data) {
      ...TrackFields
    }
  }
`;
// Update
export const UPDATE_TRACK = gql`
  ${TRACK_FIELDS}
  mutation updateTrack($data: TrackInput!) {
    updateTrack(data: $data) {
      ...TrackFields
    }
  }
`;
// Delete
export const DELETE_TRACK = gql`
  ${TRACK_FIELDS}
  mutation deleteTrack($id: String!) {
    deleteTrack(id: $id) {
      ...TrackFields
    }
  }
`;
