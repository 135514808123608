import React, { useState } from 'react';
// Svg
import { ReactSVG } from "react-svg";
// Moment
import Moment from 'react-moment';
import PrettyFileSize from './PrettyFileSize';
// Loader
import Loader from 'rsuite/lib/Loader';
// Bootstrap
import Dropdown from 'react-bootstrap/Dropdown';
// Portal
import { Portal } from 'react-portal';
// Redux
import { useDispatch } from 'react-redux';
import { fileChange, showModalFiles } from '../../redux/ducks/files-duck';
import { useApolloClient } from 'react-apollo';
// SweeAlert
import Swal from '../sweet-altert/sweet-alert';
import { DELETE_STARTUP_FILE } from '../../queries/startup-file/startup-file.query';
import { toast } from 'react-toastify';
import FileHelper from '../../helpers/files/file.helper';
// Props
interface FileItemProps {
    file: any,
    type: 'best-practice' | 'startup-file' | 'master-session'
}

const FileItem = (props: FileItemProps) => {
    // Helper
    const fileHelper = new FileHelper();
    // Dispatch
    const dispatch = useDispatch();
    // Props
    const { file, type } = props;
    // Fetch
    const [isFetch, setIsFecth] = useState(false)
    // Icons
    const icon = require(`../../assets/img/files/icon_${fileHelper.resolveIcon(file)}.svg`).default;
    const menu = require(`../../assets/img/ellipsis-vertical.svg`).default;
    const download = require("../../assets/img/files/download.svg").default;
    // Client
    const client = useApolloClient();
    // Fetch file
    const fetchFile = async () => {
        try {
            setIsFecth(true);
            // const options = {
            //     method: 'GET',
            //     headers: { 'Content-Type': file.type }
            // };
            const responseFile = await fetch(file.url)
            console.log('responseFile', responseFile)
            const blob = await responseFile.blob()

            // Create blob link to download
            const url = window.URL.createObjectURL(
                new Blob([blob]),
            );
            const link = document.createElement('a');
            link.href = url;
            link.setAttribute(
                'download',
                file.name + '.' + file.extension,
            );

            // Append to html link element page
            document.body.appendChild(link);

            // Start download
            link.click();

            // Clean up and remove the link
            if (link && link.parentNode) {
                link.parentNode.removeChild(link);
            }

        } catch (e) {
            console.log(e)
        } finally {
            setIsFecth(false);
        }
    }
    const onClickDownload = async () => {
        if (!isFetch) {
            if (file.type === 'file') {
                fetchFile()
            } else {
                window.open(file.url, '_blank');
            }
        }

    }

    const onClickEdit = () => {
        dispatch(showModalFiles({ action: 'update', data: file._object }))
    }
    const onClickView = () => {
        onClickDownload();
    }
    const deleteFileFn = async () => {
        const responseDelete = await client.mutate({
            mutation: DELETE_STARTUP_FILE,
            variables: { id: file._object.id }
        })
        const deleted = responseDelete.data.deleteStartupFile;
        if (deleted.id) {
            toast.success(`The file has been deleted successfully!`);
            dispatch(fileChange(deleted))
        } else {
            toast.error(`Oops...An error occurred. Try again later`);
        }
    }
    const onClickDelete = () => {
        Swal.fire({
            title: `<span>Are you sure to delete the file?</span>`,
            html: `<span>You are trying to delete a file, this action can't undone</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                deleteFileFn();
            }
            // else if (result.dismiss === Swal.DismissReason.cancel) {

            // }
        })
    }
    const onClickWrapper = () => {
        if (type === 'master-session') {
            const newWindow = window.open(file.url, '_blank')
            if (newWindow) newWindow.opener = null
        }
    }
    return (
        <div className={`file-item  ${type} ${isFetch ? 'is-fetch' : ''}`} onClick={onClickWrapper}>
            <ReactSVG src={icon} wrapper="span" className="icon-file" />
            <div className="file-item-content">
                <span className="file-name">{file.name}</span>
                <span className="file-date-and-size">
                    <Moment format="MMMM D[,] YYYY">{file.created_at}</Moment> {file.size > 0 ? <span>- <PrettyFileSize>{file.size}</PrettyFileSize></span> : ''}
                </span>
            </div>
            {type.includes('best-practice') &&
                <span className="file-download" onClick={onClickDownload}>
                    <ReactSVG src={download} wrapper="span" className="icon-download" />
                    <Loader speed="slow" className="rs-loader-primmary" />
                </span>}
            {type.includes('startup-file') &&
                <Dropdown className="file-menu-action">
                    <Dropdown.Toggle variant="" id={file._object.id + '-dropdown-edit'} className="">
                        <ReactSVG src={menu} wrapper="span" className="icon-menu" />
                    </Dropdown.Toggle>
                    <Portal>
                        <Dropdown.Menu>
                            <Dropdown.Item onClick={onClickEdit}>Edit</Dropdown.Item>
                            <Dropdown.Item onClick={onClickView}>
                                {file.type === 'file' ? 'Download' : 'View'}
                            </Dropdown.Item>
                            <Dropdown.Item onClick={onClickDelete} className="text-danger">Delete</Dropdown.Item>
                        </Dropdown.Menu>
                    </Portal>
                </Dropdown>
            }
        </div>
    );
};
export default FileItem;