
/*eslint-disable*/
import React, { useState, useEffect } from "react";
// Core
import Button from "../../components/buttons/Button";
// Bootstrap
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Form from 'react-bootstrap/Form';
import Alert from 'react-bootstrap/Alert';
// React form
import { useForm } from 'react-hook-form';
// Apollo
import { useApolloClient } from "react-apollo";
// Queries
import { CONFIRM_ACCOUNT } from "../../queries/users/user.query";


const RestorePassword = (props) => {
    // Token
    const token = props.match.params.token;
    // Client
    const client = useApolloClient();
    // Alert
    const [alert, setAlert] = useState<any>({
        show: false,
        variant: 'danger',
        message: ''
    });
    // Props
    const [user, setUser] = useState<any>(null);
    const [processToken, setProcessToken] = useState<any>(true);
    const [tokenError, setTokenError] = useState(false);
    // Show alert
    const showAlert = (show = false, variant = 'danger', message = '') => {
        setAlert({
            show: show,
            variant: variant,
            message: message
        });
    }
    // Loading
    const [loading, setLoading] = useState(false);


    // Process
    const renderProcesss = () => {
        return (
            <Row>
                <Col lg={12} >
                    <h4 className="text-capitalize text-center text-white ">Process Token...</h4>
                </Col>
            </Row>)
    }
    // Token invalid
    const renderTokenInvalid = () => {
        return (
            <Row>
                <Col lg={12} >
                    <h4 className="text-capitalize text-center text-white">Token invalid...</h4>
                </Col>
                <Col xs={12} className="text-center mt-4">
                    <a href="/auth/login"
                        className="d-block mt-4 mt-lg-0 recover-password">Back to Sign In</a>
                </Col>
            </Row>
        )
    }
    // Token valid
    const renderActivate = () => {
        return (
            <Row>
                <Col lg={12} >
                    <h4 className="text-capitalize text-center text-white">User succsefully activate</h4>
                </Col>
                <Col xs={12} className="text-center mt-4">
                    <a href="/auth/login"
                        className="d-block mt-4 mt-lg-0 recover-password">Back to Sign In</a>
                </Col>
            </Row>
        )
    }

    useEffect(() => {
        client.mutate({
            mutation: CONFIRM_ACCOUNT,
            variables: { token }
        }).then((response) => {
            // console.log(response);
            const user = response.data.confirmAccount || {}
            if (user.id) {
                setProcessToken(false);
                setTokenError(false);
                setUser(user);
            } else {
                setProcessToken(false);
                setTokenError(true);
            }

        }).catch((error) => {
            console.log('errror', error)
            setProcessToken(false);
            setTokenError(true);
        });
    }, [token])

    return (
        <>
            <div id="login-container" >
                <div className="form-box-login">
                    <Row>
                        <Col md={12} className="help p-0">
                            <h3 className="text-capitalize text-center text-white">Confirm Account</h3>
                            {/* <p className="help-body text-center text-white">
                                Lorem ipsum dolor sit amet, consectetur adipiscing elit.
                                Nullam id libero euismod, vestibulum eros eget
                        </p> */}
                        </Col>
                        {/* Alert */}
                        <Col xs={12} >
                            <Row className="justify-content-md-start justify-content-center">
                                <Col>
                                    {alert.show && <Alert
                                        key='alert-login'
                                        className="my-2 my-md-2"
                                        variant={alert.variant}
                                        dismissible
                                        show={alert.show}
                                        onClose={() => showAlert()}
                                    >
                                        <p className="p-0" style={{ whiteSpace: 'pre-line' }}> {alert.message}</p>
                                    </Alert>
                                    }
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Process token */}
                    {processToken && renderProcesss()}
                    {/* Process token */}
                    {tokenError && renderTokenInvalid()}
                    {/* Valid */}
                    {(!processToken && !tokenError) && renderActivate()}
                </div>
            </div>

        </>
    )
}

export default RestorePassword;
