
import gql from 'graphql-tag'

export const GUEST_FIELDS = gql`
fragment GuestFields on Guest {    
    id
    type
    email
    first_name
    last_name
    startup_id
    answered
    advance
    guest_group_id
    guest_group{
        id
        name
        startup_id
    }
}`
