import React, { useState, useEffect, useCallback } from "react";
// Bootstrap
import Form from "react-bootstrap/Form";
import { useFormContext } from "react-hook-form";
// Redux
import { useSelector } from "react-redux";
import { QUESTIONS_EXTERNAL_OPTIONS } from "../../../constants/questions-external/questions-external.constants";
import { getQuestionnaireGuestAnswers } from "../../../redux/ducks/questionnaire-guest-external-duck";
interface QuestionPaginationItemGuestExternalProps {
  question: any;
  onSelectOption: Function;
}
const QuestionPaginationItemGuestExternal = (
  props: QuestionPaginationItemGuestExternalProps
) => {
  // Answers
  const answersStore = useSelector(getQuestionnaireGuestAnswers);
  // Form
  const { register, setValue } = useFormContext();
  // Props
  const { question, onSelectOption } = props;
  // Item
  const [selected, setSelected] = useState(null);

  const onChangeRadio = (event) => {
    // Target
    const { target } = event;
    // Values
    const { name, value } = target;
    // Set Data
    setSelected(value);
    setValue(name, value, { shouldValidate: true });

    onSelectOption();
  };

  // Callback
  const fetchData = useCallback(() => {
    const item = answersStore[question.sku];
    setSelected(item);
    setValue(`${question.sku}`, item, { shouldValidate: true });
  }, [answersStore, question.sku, setValue]);

  // Init
  useEffect(() => {
    if (question && answersStore[question.sku]) {
      fetchData();
    }
  }, [fetchData, question, answersStore]);

  return (
    <>
      <div className="radio-group-questions-number row">
        <div className="col-lg-12">
          <h4 className="radio-group-title">{question.title}</h4>
        </div>
        <div className="col-12 radio-group-questions-options-numbers">
          <div className="row">
            {QUESTIONS_EXTERNAL_OPTIONS.map((item, index) => (
              <div
                className="radio-group-item col"
                id={`radio-group-item-${item.value}`}
                key={`custom-iten-${item.value}`}
              >
                <Form.Check
                  custom
                  name={`${question.sku}`}
                  ref={register}
                  type={"radio"}
                  id={`q-item-extenral-${item.value}`}
                  label={item.title}
                  value={item.value}
                  onChange={onChangeRadio}
                  checked={selected === item.value.toString()}
                />
                <span className="radio-group-after-label">
                  {item.text}
                </span>
              </div>
            ))}
          </div>
        </div>
      </div>
    </>
  );
};

export default QuestionPaginationItemGuestExternal;
