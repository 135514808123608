
/*eslint-disable*/
import React from "react";
// Charts
import MiddleGaugeChart from '../charts/gauge-chart/MiddleGaugeChart';
import BoxStartup from "../box/BoxStartup";
// Props
interface BoxFundabilityFoundersProps {
    title: string,
    description: string,
    chartDescription: string,
    fundability: any,
}


const BoxFundabilityFounders = (props: BoxFundabilityFoundersProps) => {

    // Props
    const { fundability, title, description, chartDescription } = props;

   
    return (
        <BoxStartup  id="box-fundability-founders">
            <BoxStartup.Header>
                <span className="title">{title}</span>
            </BoxStartup.Header>
            <BoxStartup.Body >
                {/* Header */}
                <div className="box-sub-element-header">
                    <p>{description}</p>
                </div>
                {/* Chart */}
                <div className="" id="main-chart-container">
                    <MiddleGaugeChart data={fundability}  />
                    <span className="title-chart-sub-element">{chartDescription}</span>
                </div>
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxFundabilityFounders;
