
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { Controller, useForm } from "react-hook-form";
// Button
import Button from "../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
import { MoonLoader } from "react-spinners";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_GROUP, UPDATE_GROUP } from "../../queries/group/group.query";
import StartupHelper from "../../helpers/startup/startup.helper";

// Props
interface GroupFormProps {
    group?: any
    questionTypes: any,
    categories: any
}
const GroupForm = (props: GroupFormProps) => {
    // Client
    const client = useApolloClient();
    // Form
    const { register, errors, trigger, getValues, setValue, control, reset } = useForm({
        mode: 'onChange'
    });
    // Loading
    const [loading, setLoading] = useState(false);
    // Helper 
    const startupHelper = new StartupHelper();
    // Props
    const {
        questionTypes,
        categories,
        group
    } = props;

    // Create
    const onClickCreate = async () => {
        await trigger();
        console.log(getValues().elements_configuration[1]);
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            if (!group) {
                createGroup()
            } else {
                updateGroup()
            }

        }
    }
    const createGroup = async () => {
        try {
            setLoading(true)
            const values = getValues()
            const toCreate: any = {
                ...values,
            }

            const createGroupResp = await client.mutate({
                mutation: CREATE_GROUP,
                variables: { data: toCreate }
            })
            const groupCreated = createGroupResp.data.createGroup || {}
            if (groupCreated.id) {
                toast.success(`Group created succefully!`);
                reset()
            } else {
                throw new Error('Cannot create')
            }
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }

    const updateGroup = async () => {
        try {
            setLoading(true)
            const values = getValues()
            const toUpdate: any = {
                id: group.id,
                ...values,
            }

            const updateGroupResp = await client.mutate({
                mutation: UPDATE_GROUP,
                variables: { data: toUpdate }
            })
            const groupUpdate = updateGroupResp.data.updateGroup || {}
            if (groupUpdate.id) {
                toast.success(`Group updated succefully!`);
                // resetForm()
            } else {
                throw new Error('Cannot update')
            }
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }

    // Render Types Inputs
    const renderTypesInputs = (sku) => {
        if (!questionTypes && questionTypes.length === 0) {
            return null;
        }
        // Filter
        const filtered = questionTypes.filter(type => type.category_sku === sku);
        // Items
        const items = filtered.map((type, index) => {
            const namePrefixFormated = `elements_configuration[${type.order - 1}]`
            return (
                <Row key={type.id}>
                    <Col>
                        <Form.Group >
                            <Form.Control
                                type="hidden"
                                name={`${namePrefixFormated}.sku`}
                                ref={register}
                                value={type.sku}
                            />
                            <Controller
                                name={`${namePrefixFormated}.available`}
                                control={control}
                                defaultValue={false}
                                render={(props) =>
                                    <Form.Switch
                                        id={`edit-el-availables-${type.sku}`}
                                        label={startupHelper.getSubElementTitleToLimited(type.title, type.sku)}
                                        checked={props.value}
                                        onChange={(evt) => {
                                            const { target } = evt;
                                            const { checked } = target;
                                            props.onChange(checked);
                                        }}
                                    />
                                }
                            />

                        </Form.Group>
                    </Col>
                </Row>
            )
        })
        return items;
    }
    // Init
    const fetchForm = useCallback(() => {
        setValue(`name`, group.name);
        setValue(`description`, group.description ? group.description : '');
        let index = 0;
        for (let elConf of group.elements_configuration) {
            setValue(`elements_configuration[${index}].available`, elConf.available);
            index++;
        }
    }, [setValue, group])
    useEffect(() => {
        if (group && group.id) {
            fetchForm()
        }
    }, [group, fetchForm])
    return (
        <LoadingOverlay active={loading}
            text={!group ? 'Creating Group...' : 'Updating Group...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <Form noValidate name={'form-group'}>

                {/* Name */}
                <Row>
                    <Col lg="9">
                        <Form.Group >
                            <Form.Label>Name</Form.Label>
                            <Form.Control
                                name="name"
                                type="text"
                                placeholder="Enter a description"
                                isInvalid={errors.name}
                                ref={register({ required: 'The name is required' })}
                            />
                            {errors.name && <div className="invalid-feedback"> {errors.name.message} </div>}

                        </Form.Group>
                    </Col>
                </Row>
                {/* Description */}
                <Row>
                    <Col lg="9">
                        <Form.Group controlId="questionTitle">
                            <Form.Label>Description</Form.Label>
                            <Form.Control
                                name="description"
                                type="text"
                                placeholder="Enter a description"
                                as={'textarea'}
                                rows={3}
                                ref={register}
                            />
                        </Form.Group>
                    </Col>
                </Row>
                <h5 className="my-4 font-weight-bold">Elements Availables </h5>
                <Row>
                    {categories && categories.length > 0 &&
                        categories.map(category => (
                            <Col lg="4" key={category.id} className="mb-3">
                                <h5 className="mb-3 font-weight-bold">{category.title}</h5>
                                {renderTypesInputs(category.sku)}
                            </Col>
                        ))
                    }
                </Row>

                {/* Button */}
                <Button variant="success"
                    id="btn-finish-question"
                    onClick={onClickCreate}
                    disabled={loading}
                    className="btn-fixed fixed-right shadow">
                    {!loading && <i className="fas fa-save icon"></i>}
                    {loading && <MoonLoader color="#8e8e93" size={40} />}
                </Button>

            </Form>
        </LoadingOverlay>
    )
}

export default GroupForm;