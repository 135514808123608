/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// React bootstrap
import Container from 'react-bootstrap/Container';
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
// Router
import { NavLink, useRouteMatch, withRouter } from "react-router-dom";


const QuestionNavbar = () => {
  const match = useRouteMatch("/questionnaire/answer/:user_id");
  const [userId, setUserId] = useState<any>(null);
  const fetchData = useCallback((params) => {
    if (params.user_id) {
      setUserId(params.user_id);
    }
  }, [setUserId])
  useEffect(() => {
    if (match && match.params) {
      fetchData(match.params)
    }
  }, [match,fetchData])
  return (
    <Navbar bg="primary" expand="lg" id="navbar-admin" className={'fixed-top'}>
      <Container>
        {/* Logo */}
        <Navbar.Brand href={`/questionnaire/answer/${userId ? userId : ''}`} className='text-white'>
          Startup
        </Navbar.Brand>
        {/* Toggle */}
        <Navbar.Toggle aria-controls="admin-nav order-1 " />

        <Navbar.Collapse id="admin-nav" className="justify-content-center order-3 order-lg-2">
          <Nav >
            <NavLink
              exact={false}
              className="nav-link" to={`/questionnaire/answer/${userId ? userId : ''}`}>Questions</NavLink>
          </Nav>
        </Navbar.Collapse>

      </Container>
    </Navbar>
  )
}


export default withRouter(QuestionNavbar);
