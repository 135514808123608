import React from 'react';
import Col from 'react-bootstrap/Col';
import Row from 'react-bootstrap/Row';
import Tab from 'react-bootstrap/Tab';
import Nav from 'react-bootstrap/Nav';
import PriorizationItem from './PriorizationItem';




interface PriorizationTabProps {
    now: Array<any>,
    next: Array<any>,
    future: Array<any>,
    hideImprove?:boolean
}

const PriorizationTab = (props: PriorizationTabProps) => {
    // Props
    const { now, next, future, hideImprove} = props;
    return (
        <div id="tab-priorization-container">
            <Tab.Container id="tabs-priorization" defaultActiveKey="now" >
                <Row>
                    <Col lg={12}>
                        <Nav variant="tabs" className={'nav-fill nav-tabs-2'}>
                            <Nav.Item>
                                <Nav.Link eventKey="now">
                                    <span>NOW</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="next">
                                    <span>NEXT</span>
                                </Nav.Link>
                            </Nav.Item>
                            <Nav.Item>
                                <Nav.Link eventKey="future">
                                    <span>FUTURE</span>
                                </Nav.Link>
                            </Nav.Item>
                        </Nav>
                    </Col>
                    <Col sm={12}>
                        <Tab.Content>
                            <Tab.Pane eventKey="now">
                                {!now &&
                                    <div className="priorization-item-block-empty">
                                        Loading...
                                    </div>
                                }
                                {now && now.length > 0 && now.map((item, index) => (
                                    <PriorizationItem item={item} key={index} hideImprove={hideImprove} />
                                ))}
                                {now && now.length === 0 &&
                                    <div className="priorization-item-block-empty">
                                        No Data
                                    </div>
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="next">
                                {!next &&
                                    <div className="priorization-item-block-empty">
                                        Loading...
                                    </div>
                                }
                                {next && next.length > 0 && next.map((item, index) => (
                                    <PriorizationItem item={item} key={index} hideImprove={hideImprove} />
                                ))}
                                {next && next.length === 0 &&
                                    <div className="priorization-item-block-empty">
                                        No Data
                                </div>
                                }
                            </Tab.Pane>
                            <Tab.Pane eventKey="future">
                                {!future &&
                                    <div className="priorization-item-block-empty">
                                        Loading...
                                    </div>
                                }
                                {future && future.length > 0 && future.map((item, index) => (
                                    <PriorizationItem item={item} key={index} hideImprove={hideImprove}/>
                                ))}
                                {future && future.length === 0 &&
                                    <div className="priorization-item-block-empty">
                                        No Data
                                    </div>
                                }
                            </Tab.Pane>
                        </Tab.Content>
                    </Col>
                </Row>
            </Tab.Container>
        </div>
    );
};
export default PriorizationTab;