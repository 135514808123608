import React, { useEffect, useCallback, useState, useImperativeHandle } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";




interface QuestionBlockRefinedScoringProps {
    question: any,
    refinedScoring: any,
    answerItem: any
}
const QuestionBlockRefinedScoringAdmin = React.forwardRef((props: QuestionBlockRefinedScoringProps, ref) => {
    // State
    const [chosenRefinedScoring, setChosenRefinedScoring] = useState<any>([]);
    // Props
    const { question, refinedScoring, answerItem } = props;
    // Fetch Data
    const fetchData = useCallback(() => {
        if (answerItem.refined_scoring_ids && answerItem.refined_scoring_ids.length > 0) {
            const refinedChosen = refinedScoring.filter(function (item) {
                return !(answerItem.refined_scoring_ids.indexOf(item.id) === -1);
            });
            // Set Checked
            setChosenRefinedScoring(refinedChosen);
        }
    }, [answerItem, refinedScoring, setChosenRefinedScoring])

    // Init
    useEffect(() => {
        if (answerItem) {
            fetchData();
        }
    }, [answerItem, fetchData])


    return (
        <>
            { question && refinedScoring.length > 0 &&
                < Row className="mb-3">
                    <Col md="12">
                        <h2 className='mb-3 text-uppercase bg-primary p-2 text-white'>{question.type.title}</h2>
                    </Col>
                    <Col md="12">
                        <span className='mb-4 text-uppercase font-weight-bold d-block'>{question.weight_type_name}</span>
                    </Col>
                    <Col md="12">
                        <h3 className='mb-3'>{question.title}</h3>
                    </Col>
                    {/* RefinedScoring */}
                    <Col md={12}>
                        <div className="pl-0">
                            {refinedScoring.map((q, index) => (
                                <div key={`custom-${q.id}`} className="mb-3">
                                    <Form.Check
                                        custom
                                        readOnly={true}
                                        name={`refined-${question.category_sku}.${question.type_sku}`}
                                        type={'checkbox'}
                                        id={`custom-${q.id}`}
                                        label={q.title}
                                        checked={chosenRefinedScoring.filter(i => i.id === q.id).length > 0}
                                        onChange={() => {}}
                                    />
                                </div>
                            ))}
                        </div>
                    </Col>
                    <Col md={12}>
                        <hr />
                    </Col>
                </Row>}
        </>
    )
})

export default QuestionBlockRefinedScoringAdmin;