
/*eslint-disable*/
import React, { useEffect, useCallback, useState } from "react";
// Questionnaire
import QuestionnaireGuestExternalIntro from "../../components/questionnaire-guest-external/QuestionnaireGuestExternalIntro";
import QuestionnaireGuestExternalQuestions from "../../components/questionnaire-guest-external/QuestionnaireGuestExternalQuestions";
import QuestionnaireGuestExternalDone from "../../components/questionnaire-guest-external/QuestionnaireGuestExternalDone";
import MultipleStep from "../../components/multiple-step/MultipleStep";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import {
    getQuestionnaireGuestAdvance,
    getQuestionnaireInvitation,
    setQuestionsGuest,
} from '../../redux/ducks/questionnaire-guest-external-duck';
// Client
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_EXTERNAL } from "../../queries/questions-external/questions-external.query";
// Router
import { useHistory } from "react-router-dom";

const IntroViewGuestExternal = (props) => {
    // Advance
    const advance = useSelector(getQuestionnaireGuestAdvance);
    // User
    const invitation = useSelector(getQuestionnaireInvitation);
    // Questions
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // History
    const history = useHistory();
    // Steps
    const steps = [
        {
            name: 'Intro',
            route: '/',
            component: QuestionnaireGuestExternalIntro
        },
        {
            name: 'Questions',
            route: '/questions',
            component: QuestionnaireGuestExternalQuestions
        },
        {
            name: 'Done!',
            route: '/done',
            component: QuestionnaireGuestExternalDone
        },
    ];
    // Fetch Data
    const fetchData = useCallback(async () => {
        // Question Guest Exteranl all types configuration
        dispatch(setQuestionsGuest(invitation.question_types));
    }, [
        client,
        invitation,
        // fetchGuestAdvance,
        dispatch
    ])
    // Check if user in memory
    const redirectToError = useCallback(() => {
        history.push(`/questionnaire-guest/error`)
    }, [])
    const redirectToThanks = useCallback(() => {
        history.push(`/questionnaire-guest/thanks`)
    }, [])
    useEffect(() => {
        if (!invitation) {
            redirectToError()
        } else {
            if (!invitation.answered 
                && (invitation.guest || invitation.anonymous) 
                && invitation.question_types) {
                fetchData()
            } else {
                redirectToThanks()
            }

        }
    }, [invitation])
    // Render
    return (
        <>

            <MultipleStep steps={steps}>
                <div className="progress-multiple-step">
                    <div className="progress-multiple-step-bar" style={{ width: advance ? `${advance}%` : '0%' }}></div>
                </div>
            </MultipleStep>
        </>
    )
}

export default IntroViewGuestExternal;
