    import React, { useEffect, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
// Button
import Button from "../buttons/Button";
// History
import { useHistory } from "react-router-dom";
// SVG
import { ReactSVG } from "react-svg";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { resetQuestionnaire, setQuestionnairePage, updateAdvance } from '../../redux/ducks/questionnaire-duck';
import { getUser } from "../../redux/ducks/user-duck";
import { toast } from "react-toastify";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
// Hooks
import useAnswerRefinedScoring from "../../hooks/continue-scoring/useAnswerRefinedScoring";



const QuestionnaireRefinedResults = (props) => {
    const user = useSelector(getUser);
    // Loading
    const [loading, setLoading] = useState(false);
    // Client
    const client = useApolloClient();
    // dispatch
    const dispatch = useDispatch();
    // Answer Refined
    const answerRefinedInput = useAnswerRefinedScoring()
    // History
    const history = useHistory();
    // Arrow
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Back
    const onClickBack = () => {
        history.push(`/questionnaire/refined-scoring/questions`)
    }
    // Finish
    const onClickFinish = async () => {
        setLoading(true)
        // Create        
        try {
            // Create Answer
            const responseAnswer = await client.mutate({
                mutation: CREATE_ANSWER,
                variables: { data: answerRefinedInput }
            })
            const newAnswer = responseAnswer.data.createAnswer || {}
            if (!newAnswer.id) {
                throw new Error('Cannot save answer');
            }
            // Reset Questionnaire
            dispatch(resetQuestionnaire())
            // Redirec
            if(user.startup_type === 'lead_generation'){
                history.push(`/dashboard-lead/main`)
            }else{
                history.push(`/dashboard/main`)
            }
            
            toast.success(`Questionnaire successfully finished!`);
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }


    }
    // Progress
    useEffect(() => {
        dispatch(setQuestionnairePage('results'))
        // Update Redux
        dispatch(updateAdvance(
            100
        ))
    }, [])

    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Results</h4>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                    disabled={loading}
                >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    type="submit"
                    onClick={onClickFinish}
                    disabled={loading}
                >
                    Finish
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>
        </>
    )
}

export default QuestionnaireRefinedResults;