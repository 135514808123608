
import React, { useCallback, useEffect } from "react";
import { Redirect, Route, Switch, useHistory, useLocation } from "react-router-dom";
// core components
import Page from "../components/page/Page";
import QuestionnaireNavBar from "../components/navbars/QuestionnaireNavBar";
// Routes
import routes from "../routes";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { resetDashboardData } from "../redux/ducks/dashboard-duck";
import { getUser } from "../redux/ducks/user-duck";


const Questionnaire = (props: any) => {
  // Route
  const location = useLocation();
  // Redux
  const dispatch = useDispatch();
  // Current User
  const user = useSelector(getUser);
  // User Selector
  const history = useHistory();
  // const user = tokenHelper.decode(token);
  // const dafaultUrl = user.primary_account ? '/questionnaire/intro/' : '/questionnaire/intro-user/'
  // document.body.classList.remove('with-sidebar');
  const resetData = useCallback(() => {
    console.log('reset data dashboard')
    dispatch(resetDashboardData())
  }, [dispatch])
  useEffect(() => {
    resetData()
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])
  window.scrollTo(0, 0)
  const getRoutes = (routes: any) => {
    return routes.map((prop: any, key: any) => {
      if (prop.layout === "/questionnaire") {
        return (
          <Route
            path={prop.layout + prop.path}
            key={key}
            render={props => (
              <Page {...props}
                component={prop.component}
                title={'34 Elements | ' + prop.name}
                name={prop.name}
              // onSetBrandText={onSetBrandText}
              />
            )}

          />
        );
      } else {
        return null;
      }
    });
  };

  useEffect(() => {
    console.log('user', user)
    if (!user.first_login) {
      // if(user.primary_account){
      if(location.pathname.includes('/questionnaire/intro/')){
        history.push('/dashboard/main');
      }      
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user])

  return (
    <React.Fragment>
      <div className="main-content-questionnaire">
        {/* Header */}
        <QuestionnaireNavBar />
        <div className="main-container-wrapper">
          {/* Main Container */}
          <div className="main-container">
            <Switch>
              {getRoutes(routes)}
              <Redirect from="*" to={'/questionnaire/intro/'} />
            </Switch>
          </div>
        </div>
      </div>

    </React.Fragment>
  );

}

export default Questionnaire;
