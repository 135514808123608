

export const CHALLENGE_CREATE = 'startup/challenges/CHALLENGE_CREATE';
export const CHALLENGE_TO_VIEW = 'startup/challenges/CHALLENGE_TO_VIEW';
export const CHALLENGE_TO_UPDATE = 'startup/challenges/CHALLENGE_TO_UPDATE';
export const CHALLENGE_TO_DELETE = 'startup/challenges/CHALLENGE_TO_DELETE';
export const CHALLENGE_LOAD = 'startup/challenges/CHALLENGE_LOAD';
export const CHALLENGE_CREATE_FROM_TEMPLATE = 'startup/challenges/CHALLENGE_CREATE_FROM_TEMPLATE';

export function challengeCreateFromTemplate(payload) {
    return {
        type: CHALLENGE_CREATE_FROM_TEMPLATE,
        payload
    }
}

export function challengeLoad(payload) {
    return {
        type: CHALLENGE_CREATE,
        payload
    }
}

export function challengeCreate(payload) {
    return {
        type: CHALLENGE_CREATE,
        payload
    }
}
export function challengeToView(payload) {
    return {
        type: CHALLENGE_TO_VIEW,
        payload
    };
}
export function challengeToUpdate(payload) {
    return {
        type: CHALLENGE_TO_UPDATE,
        payload
    };
}
export function challengeToDelete(payload) {
    return {
        type: CHALLENGE_TO_DELETE,
        payload
    };
}

const initialState = {
    challenges: null,
    challenge: null,
    challengeToView: null,
    challengeToUpdate: null,
    challengeToDelete: null,
    template: null

};

export default function reducer(state = initialState, action: any) {
    //  console.log('state', state)
    //  console.log('action', action)
    switch (action.type) {
        case CHALLENGE_CREATE:
            return Object.assign(
                {},
                state,
                {
                    challenge: action.payload
                }
            );
        case CHALLENGE_TO_UPDATE:
            return Object.assign(
                {},
                state,
                {
                    challengeToUpdate: action.payload
                }
            );
        case CHALLENGE_TO_VIEW:
            return Object.assign(
                {},
                state,
                {
                    challengeToView: action.payload
                }
            );
        case CHALLENGE_TO_DELETE:
            return Object.assign(
                {},
                state,
                {
                    challengeToDelete: action.payload
                }
            );
        case CHALLENGE_LOAD:
            return Object.assign(
                {},
                state,
                {
                    challenges: action.payload
                }
            );
        case CHALLENGE_CREATE_FROM_TEMPLATE:
            return Object.assign(
                {},
                state,
                {
                    template: action.payload
                }
            );
        default:
            return state;
    }
}
export const getChallenges = (state) => state.challenges.challenges;
export const getChallenge = (state) => state.challenges.challenge;
export const getChallengeToUpdate = (state) => state.challenges.challengeToUpdate;
export const getChallengeToView = (state) => state.challenges.challengeToView;
export const getChallengeToDelete = (state) => state.challenges.challengeToDelete;
export const getChallengeTemplate = (state) => state.challenges.template;

