import React from "react";
// Typehead
import { Highlighter } from 'react-bootstrap-typeahead'



// People
export const renderMenuItemChildrenUser = (option, props, index) => {
    return [
        <Highlighter key="fullname" search={props.text}>
            {option.fullname}
        </Highlighter>,
        <div key="email">
            <small>
                <Highlighter key="email" search={props.text}>
                    {option.email}
                </Highlighter>
            </small>
        </div>
    ];

}