import React from 'react';

  

const BoxStartupFooter = (props) => {
    return (
        <div className="box-startup-footer">
            {props.children}
        </div>
    );
};

export default BoxStartupFooter;