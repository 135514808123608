import { useCallback, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import {
  getQuestionnaireGuestPage,
  getQuestionnaireGuestQuestionsPageFirstScoring,
  getQuestionnaireGuestQuestionsPageRefinedScoring,
  getQuestionnaireGuestUser,
} from "../../redux/ducks/questionnaire-guest-duck";

// Hook
const useGuestUpdate = () => {
  // Get Necesary Data
  const guest = useSelector(getQuestionnaireGuestUser);
  const page = useSelector(getQuestionnaireGuestPage);
  const pageFirstScoring = useSelector(
    getQuestionnaireGuestQuestionsPageFirstScoring
  );
  const pageRefinedScoring = useSelector(
    getQuestionnaireGuestQuestionsPageRefinedScoring
  );

  // Initialize state with undefined width/height so server and client renders match
  // Learn more here: https://joshwcomeau.com/react/the-perils-of-rehydration/
  const [guestUpdate, setGuestUpdate] = useState<any>(null);

  const mapData = useCallback(() => {
    const guestInput = {
      id: guest.id,
      advance: {
        page: page,
        firstPage: pageFirstScoring,
        refinedPage: pageRefinedScoring,
      },
      answered: false,
    };
    setGuestUpdate(guestInput);
  }, [setGuestUpdate, guest, page, pageFirstScoring, pageRefinedScoring]);

  useEffect(() => {
    if (guest) {
      mapData();
    }
  }, [guest, mapData, page, pageFirstScoring, pageRefinedScoring]);

  return guestUpdate;
};

export default useGuestUpdate;
