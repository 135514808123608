
import React from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Container from "react-bootstrap/Container";
// Box
import BoxStartup from "../box/BoxStartup";
// Helper
import NumberPretty from "../number/NumberPretty";


interface StartupControlBoardProps {
    startup: any,
    categories: any
}
const StartupControlBoard = (props: StartupControlBoardProps) => {
    // Props
    const { startup, categories } = props;
    let order = 0;
    return (
        <Container>
            { startup &&
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title">
                            Priorization&nbsp;<b>({startup.stage_text})</b>
                        </span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <Row>
                            <Col md="1"></Col>
                            <Col md="10">
                                <div className="table-startup-wrapper table-responsive table-totals-wrapper">
                                    {categories.map((category, index) => {
                                        const priorizartionTotal = startup.totals.priorization.filter(t => t.category_sku === category.sku);
                                        return (
                                            <table className='table table-totals' key={category.id}>
                                                <thead>
                                                    <tr>
                                                        <th colSpan={7}>{category.title}</th>
                                                    </tr>
                                                </thead>
                                                <thead>
                                                    <tr>
                                                        <th className={'thead-question'}></th>
                                                        <th></th>
                                                        <th>First Scoring</th>
                                                        <th>Refined Scoring</th>
                                                        <th>Now</th>
                                                        <th>Next</th>
                                                        <th>Future</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    {priorizartionTotal.map(priorization => {
                                                        order++;
                                                        return (
                                                            <tr key={priorization.type_sku}>
                                                                <td>{priorization.type_title}</td>
                                                                <td>{order}</td>
                                                                <td><NumberPretty hideZero={true}>{priorization.firstScoring}</NumberPretty></td>
                                                                <td><NumberPretty hideZero={true}>{priorization.refinedScoring}</NumberPretty></td>
                                                                <td>{priorization.now && <span className="material-icons text-danger">close</span>}</td>
                                                                <td>{priorization.next && <span className="material-icons text-danger">close</span>}</td>
                                                                <td>{priorization.future && <span className="material-icons text-danger">close</span>}</td>
                                                            </tr>
                                                        )
                                                    })}
                                                </tbody>
                                            </table>

                                        )
                                    }
                                    )}
                                </div>
                            </Col >
                        </Row >
                    </BoxStartup.Body>
                </BoxStartup >}
        </Container >
    )
}

export default StartupControlBoard;