/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
import { useHistory, useParams } from "react-router-dom";
import { useUserAvailableSubElement } from "../../hooks/useUserAvailableSubElement";
import { getUser } from "../../redux/ducks/user-duck";
// Toast
import { toast } from "react-toastify";
import { AuthStates } from "../../constants/startup/startup.constants";
import { getDashboardData, setDashboardData, setDashboardDataExternal, setDashboardDataInternal, setSubElement as setSubElementRedux } from "../../redux/ducks/dashboard-duck";
// Redux
import { useDispatch, useSelector } from "react-redux";
// Helpers
import StartupHelper from "../../helpers/startup/startup.helper";
import { Col, Row, Spinner } from "react-bootstrap";
import { useApolloClient } from "react-apollo";
import { GET_STARTUP_DASHBOARD, GET_STARTUP_DASHBOARD_EXTERNAL, GET_STARTUP_DASHBOARD_INTERNAL } from "../../queries/startup/startups.query";
import { GET_QUESTION_BY_TYPE_SKU } from "../../queries/questions/questions.query";
import { answerFetchLastAnswer, answerFetchQuestion } from "../../redux/ducks/answer-duck";
import { GET_LAST_ANSWER_DASHBOARD } from "../../queries/answer/answers.query";
import UpdateFirstScoring from "../../components/answer/UpdateFirstScoring";
import UpdateRefinedScoring from "../../components/answer/UpdateRefinedScoring";
import MainSubElementView from "./MainSubElementView";


const MainSubElementLimitedView = (props) => {
  // History
  const history = useHistory();
  // Client
  const client = useApolloClient();
  // Auth User
  const user = useSelector(getUser);
  // Dashboard Data without external and internal
  const dashboardData = useSelector(getDashboardData);
  // Helper
  const startupHelper = new StartupHelper();
  // Params
  const { sub_element } = useParams<any>();
  // Sub element neccessary
  const [subElement, setSubElement] = useState<any>("");
  const [subElementObject, setSubElementObject] = useState<any>(null);
  const [subElementDescription, setSubElementDescription] = useState<any>(``);
  const [hasFirstChosen, setHasFirstChosen] = useState<any>(false);
  const [hasRefinedChosen, setHasRefinedChosen] = useState<any>(false);
  // Available
  const isAvailable = useUserAvailableSubElement(sub_element);
  // Dispatch
  const dispatch = useDispatch();
  // Loading
  const [loading, setLoading] = useState(false);
  // Fetch Dashboard Data
  const fetchDashboardData = useCallback(async () => {
    const response = await client.query({
      query: GET_STARTUP_DASHBOARD,
      fetchPolicy: "no-cache",
    });
    // Startup
    const startup = response.data.getStartupDashboard || {};
    dispatch(setDashboardData(startup));
    // Internal
    const responseInternal = await client.query({
      query: GET_STARTUP_DASHBOARD_INTERNAL,
      fetchPolicy: "no-cache",
    });
    const startupInternal = responseInternal.data.getStartupDashboard || {};
    dispatch(setDashboardDataInternal(startupInternal));
    // External
    const responseExternal = await client.query({
      query: GET_STARTUP_DASHBOARD_EXTERNAL,
      fetchPolicy: "no-cache",
    });
    const startupExternal = responseExternal.data.getStartupDashboard || {};
    dispatch(setDashboardDataExternal(startupExternal));
  }, [client, dispatch]);
  // Fetch Question Data
  const fetchQuestionData = useCallback(async () => {
    const questionResponse = await client.query({
      query: GET_QUESTION_BY_TYPE_SKU,
      variables: { type_sku: sub_element },
      fetchPolicy: "no-cache",
    });
    const question = questionResponse.data.getQuestionByTypeSku || {};
    dispatch(answerFetchQuestion(question));
  }, [sub_element, dispatch]);
  // Fetch Answer Data
  const fetchLastAnswerData = useCallback(async () => {
    // Get Last Answer
    const lastAnswerResponse = await client.query({
      query: GET_LAST_ANSWER_DASHBOARD,
      fetchPolicy: "no-cache",
    });
    const lastAnswer = lastAnswerResponse.data.getLastAnswerDashboard || {};
    dispatch(answerFetchLastAnswer(lastAnswer));
  }, [sub_element, dispatch]);
  // Fetch All data what dont need dashboard data
  useEffect(() => {
    if (subElement && isAvailable.includes(AuthStates.AVAILABLE)) {
      fetchQuestionData();
    }
  }, [subElement, isAvailable]);
  // Fundability Data
  const fetchFundability = useCallback(
    (
    ) => {

      setHasFirstChosen(subElementObject.hasFirstChosen)
      if (subElementObject.hasRefinedChosen) {
        setHasRefinedChosen(true);
      } else {
        setHasRefinedChosen(false);
      }
    },
    [
      subElementObject,
      setHasFirstChosen,
      setHasRefinedChosen
    ]
  );
  // Fetch Fundability when change Sub Element
  useEffect(() => {
    // Fundabilty
    if (subElementObject) {
      // console.log('fetchFundability', subElementObject)
      fetchFundability();
    }

  }, [subElementObject])
  // Fetch Data
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // Set Current Sub Element
      dispatch(setSubElementRedux(sub_element));
      // Startup
      const startup = dashboardData;
      // Totals
      const totals = startup.totals;
      // Types
      const types = totals.types;
      // Sub Element
      const subElement = types.find((c) => c.type_sku === sub_element);
      if (user.startup_type === 'limited') {
        let newTitle = startupHelper.getSubElementTitleToLimited(subElement.type_title, sub_element);
        subElement.type_title = newTitle;
      }
      setSubElementObject(subElement);
      setSubElement(subElement?.type_title);
      setSubElementDescription(subElement?.type_description);

    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      setLoading(false);
    }
  }, [
    sub_element,
    user,
    dashboardData,
    setSubElement,
    setSubElementDescription
  ]);
  // Fetch Dashboard data
  useEffect(() => {
    if (sub_element && dashboardData && dashboardData.id && isAvailable.includes(AuthStates.AVAILABLE)) {
      fetchData();
    }
  }, [sub_element, dashboardData, isAvailable]);
  // Check If Sub element is available
  useEffect(() => {
    if (isAvailable.includes(AuthStates.NOT_AVAILABLE)) {
      toast.error(`You don't have permissions for this section`);
      history.push('/dashboard/main');
    }
  }, [isAvailable])


  // On Change Answer Fetch Dashboard Data
  const onSaveAnswer = (answer) => {
    fetchDashboardData();
    // Question Data
    fetchQuestionData();
    // Last Answer Data
    fetchLastAnswerData();
  };
  // Loading
  if (loading) {
    return <div className="text-center"><Spinner animation="grow" variant="primary" /></div>
  }
  // If response all 
  if(hasFirstChosen && hasRefinedChosen){
    return <MainSubElementView />
  }
  // Render
  return (
    <Row>
      <Col
        lg={7}
      >
        {!hasFirstChosen && <UpdateFirstScoring onSave={onSaveAnswer} />}
        {!hasRefinedChosen && hasFirstChosen && <UpdateRefinedScoring onSave={onSaveAnswer} />}
      </Col>
    </Row>
  );
};

export default MainSubElementLimitedView;
