import React from 'react';
import StrengthItem from './StrengthItem';

interface StrengthListProps {
    strengths: any,
}

const StrengthList = (props: StrengthListProps) => {
    const { strengths } = props;
    return (
        <div className="strength-list" >
            {!strengths && <div className="strength-item-empty">Loading...</div>}
            {strengths && strengths.length > 0 && strengths.map((item, index) => (
                <StrengthItem item={item} index={index + 1} key={index} />
            ))}
            {strengths && strengths.length === 0 && 
                <div className="strength-item-work-to-do">
                    You still have some work to do to 😎
                </div>}
        </div>
    );
};
export default StrengthList;