/*eslint-disable*/
import React from "react";
import Form from 'react-bootstrap/Form';
// Props
interface CategoryItemFoundersProps {
  category: any;
  typesSelected: Array<string>,
  onSelectItem: Function,
  disabled: boolean
}

const CategoryBoxExternalRating = (props: CategoryItemFoundersProps) => {
  const { category, onSelectItem, typesSelected , disabled } = props;
  // On Change Checkbox
  const onChangeItem = (event) => {
    const { name } = event.target;
    onSelectItem(name);
  }
  // Render
  return (
    <div className={`category-box-external-rating`}>
      <div className="category-title">
        <span>{category.title}</span>
      </div>
      {/* List Question */}
      <div className="list-types">
        {category.question_types &&
          category.question_types.length > 0 &&
          category.question_types.map((type) => (
            <div className="list-type-item" key={type.id}>
              <Form.Check
                custom
                type={'checkbox'}
                id={`type-external-rating-${type.id}`}
                label={type.title}
                name={type.sku}
                value={type.sku}
                onChange={onChangeItem}
                checked={typesSelected.indexOf(type.sku) >= 0}
                disabled={disabled}
              />
            </div>
          ))}
      </div>
    </div>
  );
};

export default CategoryBoxExternalRating;
