

import AnswerView from "./views/questions/AnswerView";
import ChartView from "./views/charts/ChartView";

// Routes
import routesAuth from "./routes-auth";
import routesAdmin from "./routes-admin";
import routesDashboard from "./routes-dashboard";
import routesQuestionnaire from "./routes-questionnaire";
import routesQuestionnaireGuest from "./routes-questionnaire-guest";
import routesDashboardLead from "./routes-dashboard-lead";


const routes = [
  // Dashboard Lead
  ...routesDashboardLead,
  // Admin
  ...routesAdmin,
  // Questionnaire
  ...routesQuestionnaire,
  // Questionnaire Guest
  ...routesQuestionnaireGuest,
  // Dashboard
  ...routesDashboard,
  // Auth
  ...routesAuth,
  // Answer
  {
    path: "/answer/:user_id?",
    name: "Answer",
    icon: "ni ni-key-25",
    component: AnswerView,
    layout: "/questionnaire",
    key: 'answer'
  },
  {
    path: "/charts",
    name: "Charts",
    icon: "ni ni-key-25",
    component: ChartView,
    layout: "/questionnaire",
    key: 'chart'
  },


];
export default routes;
