export const MASTER_SESSION_TOPICS = [
    {
        key: 'purpose_founders',
        value: 'purpose_founders',
        label: 'Purpose & Founders',
    },
    {
        key: 'team_strengths',
        value: 'team_strengths',
        label: 'Team Strengths',
    },
    {
        key: 'value_proposition',
        value: 'value_proposition',
        label: 'Value Proposition',
    },
    {
        key: 'positioning',
        value: 'positioning',
        label: 'Positioning',
    },
    {
        key: 'market_segmentation',
        value: 'market_segmentation',
        label: 'Market Segmentation',
    },
    {
        key: 'go_to_market_timing',
        value: 'go_to_market_timing',
        label: 'Go-to-market and Timing',
    },
    {
        key: 'product_experience',
        value: 'product_experience',
        label: 'Product Experience',
    },
    {
        key: 'business_model',
        value: 'business_model',
        label: 'Business Model',
    },
    {
        key: 'analytics_customer_experience',
        value: 'analytics_customer_experience',
        label: 'Analytics and customer experience',
    },
    {
        key: 'momentum_to_scale',
        value: 'momentum_to_scale',
        label: 'Momentum to Scale',
    },


]