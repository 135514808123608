
import React, { useEffect, useCallback, useState } from "react";
// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionnaireQuestions, setQuestionnairePage } from '../../redux/ducks/questionnaire-duck';
import QuestionsPaginationRefined from "./question-pagination/QuestionsPaginationRefined";


const QuestionnaireRefinedQuestions = (props) => {
    // Dispatch
    const dispatch = useDispatch();
    const questionsStore = useSelector(getQuestionnaireQuestions)
    const [questions, setQuestions] = useState<any>([])
    const fetchData = useCallback(() => {
        setQuestions(questionsStore);
    }, [setQuestions, questionsStore])

    useEffect(() => {
        if (questionsStore !== questions) {
            fetchData()
        }
    }, [fetchData, questionsStore, questionsStore])

    useEffect(() => {
        // Set Page
        dispatch(setQuestionnairePage('questions'))        
    }, [])
    return (
        <>
            <QuestionsPaginationRefined 
                    questions={questions}/>
        </>
    )
}

export default QuestionnaireRefinedQuestions;