import React  from "react";
import QuestionBlockRefinedScoringAdmin from "./QuestionBlockRefinedScoringAdmin";

interface CategoryBlockProps {
    questions: any,
    category: any,
    name: string,
    lastAnswer: any
}
const CategoryBlockRefinedScoringAdmin = (props: CategoryBlockProps) => {
    // Props
    const { questions, category, name, lastAnswer } = props;
    return (
        <>
            {questions.map((question, index) => {
                const refinedScoring = question.items.filter(i => i.type_sku === 'refined-scoring');
                let answerItem = null;
                if(lastAnswer && lastAnswer.answer_items.length > 0){
                    answerItem = lastAnswer.answer_items.find(_answerItem =>  _answerItem.question_type_sku === question.type_sku);
                }
                return(<QuestionBlockRefinedScoringAdmin
                    key={index}
                    question={question}
                    refinedScoring={refinedScoring}
                    answerItem={answerItem}
                    />)
            })}
          
        </>
    )
}

export default CategoryBlockRefinedScoringAdmin;