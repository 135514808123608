
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Button from "react-bootstrap/Button";
import Form from "react-bootstrap/Form";
// External
import CategoryBoxExternalRating from '../../../components/external-rating/CategoryBoxExternalRating';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Startup
import StartupAccordion from "../../../components/startup/StartupAccordion";
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
// Router
import { useHistory, useParams } from "react-router-dom";
// Apollo
import { useApolloClient } from "@apollo/react-hooks";
import { GET_STARTUP_BY_ID } from '../../../queries/startup/startups.query';
import { LIST_CATEGORIES_AND_TYPES } from "../../../queries/categories/categories.query";
import { CREATE_GUEST_GROUP } from "../../../queries/guest-group/guest-group.query";
// Toast
import { toast } from "react-toastify";
// Forms
import { useForm } from "react-hook-form";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";

interface FormGroupName {
    group: any;
}

const CreateExternalInvitationView = (props) => {
    
    // Startup Id
    const { startup_id } = useParams<any>();
    // Router
    const history = useHistory();
    // Loading
    const [loading, setLoading] = useState(true);
    const [creating, setCreating] = useState(false);
    // Startup
    const [startup, setStartup] = useState<any>(null)
    // Categories
    const [categories, setCategories] = useState<any>(null);
    // Selected
    const [typesSelected, setTypesSelected] = useState<Array<string>>([]);
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/startups");
    // Validation
    const {
        handleSubmit,
        register,
        errors,
        formState,
    } = useForm<FormGroupName>({
        mode: "onChange",
        defaultValues: {},
    });

    const {  isValid } = formState;
    // Client
    const client = useApolloClient();
    const fetchData = useCallback(async () => {
        // Startup
        const response = await client.query({
            query: GET_STARTUP_BY_ID,
            variables: { id: startup_id },
            fetchPolicy: 'no-cache',
        });
        const startup = response.data.getStartupById || {}
        setStartup(startup);
        // Categories
        const responseCategories = await client.query({
            query: LIST_CATEGORIES_AND_TYPES,
            fetchPolicy: 'no-cache',
        });
        const categories = responseCategories.data.listCategories || [];
        setCategories(categories);
        // Loadinf
        setLoading(false)
    }, [startup_id])
    // Call Fetch Data
    useEffect(() => {
        if (startup_id) {
            fetchData();
        }
    }, [startup_id, fetchData])

    // Selected Types
    const onSelectItem = (type) => {
        const indexOf = typesSelected.indexOf(type)
        if (indexOf === -1) {
            typesSelected.push(type);
        } else {
            typesSelected.splice(indexOf, 1)
        }
        setTypesSelected([...typesSelected]);
    }
  
    // Create Group
    const createGroup = async (data) => {
        setCreating(true);
        try {
            const guestGroupData = {
                startup_id: startup.id,
                name: data.group,
                types: typesSelected,
                admin_created: true
            }
            const responseCreate = await client.mutate({
                mutation: CREATE_GUEST_GROUP,
                variables: { data: guestGroupData }
            })
            const groupCreated = responseCreate.data.createGuestGroup || {}
            if (groupCreated.id) {
                toast.success(`Link created succefully!`);
                history.push(`/admin/startups/external-invitation/${groupCreated.id}?create=true`)
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
            
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setCreating(false);
        }


    }

    return (
        <>
            <Container fluid>
                <Row>
                    <Col sm="1"></Col>
                    <Col sm="10">
                        {/* Box */}
                        <LoadingOverlay
                            active={loading || creating}
                            text={creating ? 'Creating Link...' : 'Loading...'}
                            spinner={
                                <div className="_loading_overlay_spinner">
                                    <BounceLoader color={"#2662f0"} />
                                </div>}>
                            <Breadcrumb>
                                <Breadcrumb.Item href={`/admin/startups${search}`}>Startups</Breadcrumb.Item>
                                <Breadcrumb.Item active>Create External Invitation Link</Breadcrumb.Item>
                            </Breadcrumb>
                            {/* Box */}
                            <BoxStartup>
                                <BoxStartup.Header>
                                    <span className="title">External Invitation</span>
                                </BoxStartup.Header>
                                <BoxStartup.Body>
                                    {startup && <StartupAccordion  startup={startup}/>}
                                </BoxStartup.Body>
                                {/* Categories*/}
                                <div className="category-list-external-rating">
                                    <Row className="m-0">
                                        {categories &&
                                            categories.length > 0 &&
                                            categories.map((category, index) => (
                                                <Col key={category.sku} className="p-0 category-box-external-wrapper">
                                                    <CategoryBoxExternalRating
                                                        category={category}
                                                        onSelectItem={onSelectItem}
                                                        typesSelected={typesSelected}
                                                        disabled={!startup}
                                                    />
                                                </Col>
                                            ))}
                                    </Row>
                                </div>
                                <BoxStartup.Body>

                                    <Form
                                        name="form-create-group"
                                        onSubmit={handleSubmit(createGroup)}
                                        noValidate
                                        className="mt-4"
                                    >
                                        <Form.Row>
                                            {/* <Col  className="d-flex align-items-center col-lg-auto col-md-12 mb-2 mb-lg-0">
                                                    <span className="title">Url Invitation</span>
                                                </Col> */}
                                            <Col lg="4" className="mb-4 mb-lg-0">
                                                <Form.Control
                                                    name="group"
                                                    type="text"
                                                    autoComplete="off"
                                                    isInvalid={errors.group ? true : false}
                                                    ref={register({
                                                        required: "Name is required",
                                                    })}
                                                    placeholder="Name"
                                                />
                                                {errors.group && (
                                                    <div className="invalid-feedback">{errors.group.message}</div>
                                                )}
                                            </Col>
                                            <Col className="">
                                                <Button
                                                    variant=""
                                                    className={"btn-violet"}
                                                    type="submit"
                                                    disabled={creating || !isValid || typesSelected.length === 0}>
                                                    Create Link
                                                </Button>
                                            </Col>
                                        </Form.Row>
                                    </Form>
                                </BoxStartup.Body>
                            </BoxStartup>
                        </LoadingOverlay>
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default CreateExternalInvitationView;
