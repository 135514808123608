
/*eslint-disable*/
import React from "react";
// Questionnaire
import QuestionnaireIntroGuest from "../../components/questionnaire/QuestionnaireIntroGuest";
import QuestionnaireStartupTypeGuest from "../../components/questionnaire/QuestionnaireStartupTypeGuest";
import MultipleStep from "../../components/multiple-step/MultipleStep";
// Redux
import { useSelector } from 'react-redux';
import { getQuestionnaireAdvance } from '../../redux/ducks/questionnaire-duck';
// Client



const IntroViewGuest = (props) => {
    // Advance
    const advance = useSelector(getQuestionnaireAdvance);
    // Steps
    const steps = [
        {
            name: 'Intro',
            route: '/',
            component: QuestionnaireIntroGuest
        },
        {
            name: 'Startup Type',
            route: '/startup',
            component: QuestionnaireStartupTypeGuest
        }
    ];

    // Render
    return (
        <>

            <MultipleStep steps={steps}>
                <div className="progress-multiple-step">
                    <div className="progress-multiple-step-bar" style={{ width: advance ? `${advance}%` : '0%' }}></div>
                </div>
            </MultipleStep>
        </>
    )
}

export default IntroViewGuest;
