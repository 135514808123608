
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_TRACKS } from "../../../queries/track/track.query";
// Spinner
import Spinner from "react-bootstrap/Spinner";
// Form
import ChallengeTemplateAddForm from "../../../components/challenges-template/ChallengeTemplateAddForm";
import { useLastLocationQueryParams } from "../../../hooks/useLastLocationQueryParams";

const ChallengeTemplateAddView = (props) => {
    // Tracks
    const [tracks, setTracks] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Last Location query parmas
    const search = useLastLocationQueryParams("/admin/challenge-templates");
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        // Get Tracks
        const responseTracks = await client.query({
            query: LIST_TRACKS,
            fetchPolicy: 'no-cache'
        })
        const tracks = responseTracks.data.listTracks || [];
        // Set Tracks
        setTracks(tracks)
        // Loading
        setLoading(false);
    }, [
        client,
        setTracks,
        setLoading,
    ]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [])

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item href={`/admin/challenge-templates${search}`}>Challenge Templates</Breadcrumb.Item>
                    <Breadcrumb.Item active>Add</Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup >
                    <BoxStartup.Header>
                        <span className="title">Add Challenge Template</span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        {!loading && <ChallengeTemplateAddForm tracks={tracks}  />}
                        {loading && <div className="text-center"><Spinner animation="grow" variant="primary" /></div>}
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default ChallengeTemplateAddView;