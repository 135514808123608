import React, { useCallback, useEffect, useState } from "react";

// Bootstrap
import Form from 'react-bootstrap/Form';
import { useFormContext, Controller } from "react-hook-form";


interface UrlInputProps {
    value: string,
    name: string,
    label?: string,
    placeholder?: string,
    validFileExtensions?: Array<string>,
    onChange?: Function,
}
const UrlInput = (props: UrlInputProps) => {
    // Form
    const { errors, control, setValue } = useFormContext();    
    // Props
    const { value: valueProps, onChange, name, label: labelProps, placeholder: placeholderProps } = props;
    // // Extensions
    // const validExtensionsDefault = [
    //     '.pdf',
    //     '.mp4',
    //     '.numbers',
    //     '.pptx',
    //     '.ppt',
    //     '.key',
    //     '.xls',
    //     '.xlsx',
    //     '.txt'
    // ];
    // const [validFileExtensions] = useState<Array<string>>(props.validFileExtensions ? props.validFileExtensions : validExtensionsDefault);
    // // Pattern Map Extension
    // var extensionMaps = validFileExtensions.map(function (extension) {
    //     return `\.${extension.replace('.', '')}`;
    // }).join("|");
    // // Patterns
    // // const patternFile = /(?:[^\/][\d\w\.]+)$(?<=(?:.jpg)|(?:.pdf)|(?:.gif)|(?:.jpeg)|(more_extension))/i
    // const patternFile = new RegExp(`.{0,}(${extensionMaps})$`, 'gm')
    const patternUrl = /^(http[s]?:\/\/(www\.)?|ftp:\/\/(www\.)?|www\.){1}([0-9A-Za-z-\.@:%_\+~#=]+)+((\.[a-zA-Z]{2,3})+)(\/(.)*)?(\?(.)*)?/g
    // Form
    const [label] = useState(labelProps ? labelProps : 'Paste link of file');
    const [placeholder] = useState(placeholderProps ? placeholderProps : 'Copy link url example: http://34elements/my-file.mp4');  
    // Value
    const [valueLink, setValueLink] = useState('');
    // Change
    const onChangeValue = (event) => {
        const { target } = event;
        setValue(name, target.value, { shouldValidate: true })
        setValueLink(target.value)
        if (onChange) {
            onChange(target.value);
        }        
    };
    const fetchData = useCallback(() => {
        setValue(name, valueProps, { shouldValidate: false })
        setValueLink(valueProps)
    }, [name,valueProps, setValueLink])

    useEffect(() => {
        if (valueProps !== valueLink) {
            fetchData()
        }
    }, [valueProps])

    return (
        <Form.Group >
            <Form.Label>{label}</Form.Label>
            <Controller
                name={name}
                control={control}
                defaultValue={valueProps}
                rules={{
                    required: 'The link is required',
                    validate: (value) => {
                        const isUrl = patternUrl.test(value);
                        if (!isUrl) {
                            return "The Link is invalid"
                        }                        
                        return true;
                    }
                }}
                render={props => (
                    <Form.Control
                        type="text"
                        placeholder={placeholder}
                        isInvalid={errors[name]}
                        // defaultValue={valueProps}
                        autoComplete="off"
                        onChange={onChangeValue}
                        value={valueLink}
                    />
                )} />

            {errors[name] && <div className="invalid-feedback"> {errors[name].message} </div>}            

        </Form.Group>
    )


}



export default UrlInput;

