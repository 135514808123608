import gql from 'graphql-tag';

import { CATEGORY_FIELDS } from './category.fragment';
// Query
export const LIST_CATEGORIES = gql`  
        ${CATEGORY_FIELDS}              
        query listCategories{                     
            listCategories{
                ...CategoryFields
            }
        }
      
`
export const LIST_CATEGORIES_AND_TYPES = gql`  
        ${CATEGORY_FIELDS}              
        query listCategories{                     
            listCategories{
                ...CategoryFields
                question_types{
                    id
                    sku
                    title
                    description
                }
            }
        }
      
`


export const GET_CATEGORY_BY_ID = gql`  
        ${CATEGORY_FIELDS}              
        query getCategoryById($id: String!){                     
            getCategoryById(id:$id){
                ...CategoryFields
            }
        }
      
`

// Mutation
export const UPDATE_CATEGORY = gql`  
        ${CATEGORY_FIELDS}              
        mutation updateCategory($data: CategoryInput!){                     
            updateCategory(data:$data){
                ...CategoryFields
            }
        }
      
`
