import * as d3 from 'd3';
// https://gist.github.com/ameyms/9184728
// Gauge Needle
class MiddleGaugeNeedleMin {
    svg: d3.Selection<SVGElement, any, any, any>;
    width: number;
    radius: number;
    radiusCenter: number;
    len: number;
    perc: number = 0;
    constructor(svg, width) {
        this.svg = svg;
        this.width = width;
        this.len = (this.width * 0.35) ;
        this.radius = this.len * 0.07;
        this.radiusCenter = this.radius * 0.5;

    }
    render() {
        this.svg.append('circle').attr('class', 'needle-center').attr('cx', 0).attr('cy', 0).attr('r', this.radius);
        
        this.svg.append('path').attr('class', 'needle').attr('id', 'client-needle').attr('d', this.recalcPointerPos(0) as any);
        this.svg.append('circle').attr('class', 'needle-center-white').attr('cx', 0).attr('cy', 0).attr('r', this.radiusCenter);
        
    }
    percToDeg(perc) {
        return perc * 360;
    }

    percToRad(perc) {
        return this.degToRad(this.percToDeg(perc));
    }

    degToRad(deg) {
        return deg * Math.PI / 180;
    }

    recalcPointerPos(perc) {

        var centerX, centerY, leftX, leftY, rightX, rightY, thetaRad, topX, topY;
        thetaRad = this.percToRad(perc );
        centerX = 0;
        centerY = 0;
        // First Line
        leftX = centerX - this.radius * Math.cos(thetaRad - Math.PI / 2);
        leftY = centerY - this.radius * Math.sin(thetaRad - Math.PI / 2);
        // Corner
        topX = centerX - this.len * Math.cos(thetaRad);
        topY = centerY - this.len * Math.sin(thetaRad);
        // End
        rightX = centerX - this.radius * Math.cos(thetaRad + Math.PI / 2);
        rightY = centerY - this.radius * Math.sin(thetaRad + Math.PI / 2);

        return "M " + leftX + " " + leftY + 
               " L " + topX + " " + topY  +                
               " L " + rightX + " " + rightY;
   
    }

    moveTo(perc) {
        var self,
            oldValue = this.perc || 0;

        this.perc = perc;
        self = this;

        // Reset pointer position
        this.svg.transition()
            .delay(100)
            .ease(d3.easeQuad)
            .duration(200)
            .select('.needle')
            .tween('reset-progress', function () {
                return function (percentOfPercent) {
                    var progress = (1 - percentOfPercent) * oldValue;
                    return d3.select(this).attr('d', self.recalcPointerPos.call(self, progress));
                };
            });

        this.svg.transition()
            .delay(300)
            .ease(d3.easeBounce)
            .duration(1500)
            .select('.needle')
            .tween('progress', function () {
                return function (percentOfPercent) {
                    var progress = percentOfPercent * perc;
                    return d3.select(this).attr('d', self.recalcPointerPos.call(self, progress));
                };
            });

    };





}

export default MiddleGaugeNeedleMin;
