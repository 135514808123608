
import React, { useEffect } from "react";
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Button from "../buttons/Button";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import {
    updateAdvanceGuest,
    setQuestionnaireGuestPage,
    getQuestionnaireGuestQuestions
} from '../../redux/ducks/questionnaire-guest-external-duck';


const QuestionnaireGuestExternalIntro = (props) => {
    const history = useHistory();
    const dispatch = useDispatch();
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const nextRoute = `/questionnaire-guest/intro-external/questions`;
    const questions = useSelector(getQuestionnaireGuestQuestions);
    const questionsLength = questions ? questions.length - 1  : 0;

    useEffect(() => {
        // Update Redux
        dispatch(setQuestionnaireGuestPage('intro'))
        // One page 1.4 of advance
        dispatch(updateAdvanceGuest(
            100 / (questions.length + 2)
        ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [questions])
    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Welcome to 34 Elements! 👋</h4>
            {/* Description */}
            <p className="multiple-step-description">
               {`This questionnaire is short and will capture your assessment of the startup on ${questions ? questions.length : ''} key points:`}<br />
                {questions && questions.map((type, index) =>(
                    <span key={index}>{type.title}{questionsLength > index ? ` - `: `` }</span>
                ))}
            </p>
            {/* List */}
            <ul className="questionnaire-instructions">
                <li>If you cannot answer a given question, you can skip it.</li>
                <li>Be honest, only honest answers can help!</li>
                <li>Once finished your contact will be informed.</li>
            </ul>
            <p className="multiple-step-description-black">
                Thank you!
            </p>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={() => { history.push(nextRoute) }}>
                    Go to Questionnaire
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </>
    )
}

export default QuestionnaireGuestExternalIntro;