import React from 'react'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import StartupHelper from '../../helpers/startup/startup.helper';
import { ReactSVG } from 'react-svg';
import Button from '../buttons/Button';
import Moment from 'react-moment';
// Props
interface GuestItemInvitationProps {
    invitation: any,
    onClickDelete: Function
}
// Modal close
const GuestItemInvitation = (props: GuestItemInvitationProps) => {
    const { invitation, onClickDelete: onClickDeleteProps } = props;
    const startupHelper = new StartupHelper();
    const status = startupHelper.resolveGuestStatus(invitation.answered ? 'answered' : 'sent')
    // Icons
    const iconStatussrc = require(`../../assets/img/guests/icon_${status.sku}.svg`).default
    // Types
    const types = invitation.question_types.map((type) => (type.title)).join(', ');
    // Delete
    const onClickDelete = () => {
        onClickDeleteProps(invitation);
    }
    return (
        <div className="guest-item-invitation">
            <Row >
                <Col lg="1">

                </Col>
                <Col lg="3">
                    <span className="guest-item-invitation-date">
                        <span className="date"><Moment format="MMMM D[,] YYYY">{invitation.created_at}</Moment></span>
                    </span>

                </Col>
                <Col lg="4" className='mt-4 mt-lg-0'>
                    <span className="guest-item-invitation-types">
                        {types}
                    </span>
                </Col>
                <Col lg="4" className="d-lg-flex mt-4 mt-lg-0 justify-content-lg-end align-items-center">
                    <div className="guest-item-status">
                        <span className={`badge-challenge badge-challenge-status  ${status?.sku}`}>
                            <ReactSVG src={iconStatussrc} wrapper="span" className="icon-status " />
                            {status.title}
                        </span>
                        <Button variant="" className="btn-flat btn-flat-danger" size="sm" onClick={onClickDelete}>Delete</Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

export default GuestItemInvitation;