
import React, { useEffect, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_ANSWER } from "../../queries/answer/answers.query";
// Button
import Button from "../buttons/Button";
// History
import { useHistory } from "react-router-dom";
// SVG
import { ReactSVG } from "react-svg";
// Redux
import { useDispatch,useSelector } from 'react-redux';
import { getQuestionnaireStartupLead, resetQuestionnaireLead } from "../../redux/ducks/questionnaire-lead-duck";
import { setQuestionnaireGuestPage, updateAdvanceGuest } from "../../redux/ducks/questionnaire-guest-duck";
// Toast
import { toast } from "react-toastify";
// Hooks
import { getUser, userCreate } from "../../redux/ducks/user-duck";
import TokenHelper from "../../helpers/security/token.helper";
import { setToken } from "../../utils";
import { RESET_FIRST_LOGIN } from "../../queries/users/user.query";
import { UPDATE_STARTUP } from "../../queries/startup/startups.query";
import useLeadAnswer from "../../hooks/lead/useLeadAnswer";
import { setAnswers, setLastAnswer } from "../../redux/ducks/questionnaire-duck";
import StartupHelper from "../../helpers/startup/startup.helper";



const QuestionnaireDoneLead = (props) => {
    const startupHelper = new StartupHelper();
    // Loading
    const [loading, setLoading] = useState(false);
    // Client
    const client = useApolloClient();
    // dispatch
    const dispatch = useDispatch();
    // Selector
    const user = useSelector(getUser);
    const startup = useSelector(getQuestionnaireStartupLead);
    const answerInput = useLeadAnswer();
    // History
    const history = useHistory();
    // Arrow
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Back
    const onClickBack = () => {
        history.push(`/questionnaire/intro-lead/questions`)
    }
    // Go To Continue Scoring
    const goToContinueScoring = (lastAnswer) =>{
        // const lastAnswerResponse = await client.mutate({
        //     mutation: GET_LAST_ANSWER_DASHBOARD,
        //     fetchPolicy: 'no-cache'
        // })
        // Map Answer Items
        const answerItems = lastAnswer.answer_items;
        const idsRefined = startupHelper.mapRefinedScoringIds(answerItems);
        // Set Answers of continue scoring
        dispatch(setAnswers({
            currentPage: 1,
            data: idsRefined
        }));
        dispatch(setLastAnswer(lastAnswer))
        // Redirect
        history.push(`/questionnaire/refined-scoring/`)
    }
    // Finish
    const onClickFinish = async () => {
        setLoading(true)
        setLoading(true)
        
        const startupVar = {
            ...startup,
            active: true,
            id: user.startup_id,
        }
        const answer = {
            user_id: user.id,
            startup_id: user.startup_id,
            items: answerInput.items
        }
       
        try {

            // Update Startup
            const responseStartup = await client.mutate({
                mutation: UPDATE_STARTUP,
                variables: { data: startupVar }
            })
            const startup = responseStartup.data.updateStartup || {};
            if (!startup.id) {
                throw new Error('Cannot save startup');
            }
            // Update User
            const responseUser = await client.mutate({
                mutation: RESET_FIRST_LOGIN
            })
            const newToken = responseUser.data.resetFirstLogin || null;
            if (!newToken) {
                throw new Error('Cannot save user');
            }
            // Create Answer
            const responseAnswer = await client.mutate({
                mutation: CREATE_ANSWER,
                variables: { data: answer }
            })
            // console.log('responseAnswer', responseAnswer)
            const newAnswer = responseAnswer.data.createAnswer || {}
            if (!newAnswer.id) {
                throw new Error('Cannot save answer');
            }
            // Set the new token
            const tokenHelper = new TokenHelper();
            const user = tokenHelper.decode(newToken);
            if (user) {
                console.log('user', user)
                setToken(newToken)
                delete user.password;
                // Set User
                dispatch(userCreate(user));
                // Reset Questionnaire
                dispatch(resetQuestionnaireLead())
                
                // Continue scoring
                goToContinueScoring(newAnswer);

            }else{
                throw new Error('Token error');

            }
            toast.success(`Questionnaire successfully finished!`);
        } catch (e) {
            console.log(e)
            toast.error(`Oops...An error occurred. Try again later`);
        } finally {
            setLoading(false)
        }



    }
    // Progress
    useEffect(() => {

        dispatch(setQuestionnaireGuestPage('done'))
        // Update Redux
        dispatch(updateAdvanceGuest(
            100
        ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])

    return (
        <>
            {/* Title */}
            <h4 className="multiple-step-title">Awesome, you just finished the first part of the questionnaire!</h4>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}
                    disabled={loading}
                >
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    type="submit"
                    onClick={onClickFinish}
                    disabled={loading}
                >
                    Finish
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>
        </>
    )
}

export default QuestionnaireDoneLead;