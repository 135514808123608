// Moment
import moment from 'moment-timezone'

export default class DateHelper {


    format(date, format?: string) {
        if(!format){
            format ='MM/DD/YYYY HH:MM A'
        }
        return moment(date).format(format);

    }
    sortValue(date) {
        return moment(date).toDate().getTime()
    }

}