import React, { useState } from 'react';
import SunEditor from 'suneditor-react';

interface HtmlEditorProps {
    value: string,
    placeholder?: string,
    onChange?: Function,
    disabled?: boolean

}

const HtmlEditor = (props: HtmlEditorProps) => {
    // Props
    const { value: valueProps, placeholder: placeholderProps, onChange, disabled } = props;
    // Placeholser
    const [placeholder] = useState(placeholderProps ? placeholderProps : 'Enter a description');
    // Button List
    const defaultButtonList = [
        ["undo", "redo"],
        ["image"]
    ]
    // const [buttonList] = useState(!disabled ? defaultButtonList : [])
    // Content
    // const [content, setContent] = useState('')
    // Editor
    const onChangeEditor = (newContent) => {
        // console.log(newContent)
        // setContent(newContent)
        if (onChange) {
            onChange(newContent)
        }
    }



    // // Fetch Content
    // const fetchContent = useCallback(() => {
    //     // setContent(valueProps)
    // }, [setContent, valueProps])
    // // Fetch Data
    // useEffect(() => {
    //     if (valueProps && valueProps !== content) {
    //         fetchContent()
    //     }
    // }, [valueProps])
    return (
        <>

            <SunEditor
                // ref={editor}
                disable={disabled}
                enableToolbar={!disabled}
                setDefaultStyle="font-family: 'Source Sans Pro', sans-serif; font-size: 1rem;"
                placeholder={placeholder}
                setContents={valueProps}
                setOptions={{
                    attributesWhitelist: {
                        'all': "style|data-+.",
                        'img': 'style'
                    },
                    buttonList: defaultButtonList,
                }}
                // onImageUpload={onImageUpload}
                // onImageUploadBefore={onImageUploadBefore}
                height="100%"
                onChange={onChangeEditor}

            />
        </>
    )

}



export default HtmlEditor;