
import React from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Nav from "react-bootstrap/Nav";
import Tab from "react-bootstrap/Tab";
import CategoryBlockFirstScoring from "./first-scoring/CategoryBlockFirstScoring";
// Validation
import { useFormContext } from "react-hook-form";


interface FirstScoringProps {
    questions: any,
    categories: any,
    lastAnswer: any
}
const FirstScoring = (props: FirstScoringProps) => {
    // Props
    const {
        categories,
        questions,
        lastAnswer
    } = props;
    // Validation
    const { errors } = useFormContext()
    // Get Error
    const getError = (errors, category) => {
        if (errors['firstScoring']) {
            if (errors['firstScoring'][category.sku]) {
                const keys = Object.keys(errors['firstScoring'][category.sku]);
                if (keys.length > 0) {
                    return true;
                }
            }
        }
        return false;
    }
    return (
        <>
            {/* Tabs */}
            { categories && questions && <div className="tabs-answers-container">
                <Tab.Container id="left-tabs-example" defaultActiveKey="team">
                    <Row>
                        <Col lg={3} className="d-flex">
                            <Nav variant="pills" className="flex-row flex-lg-column w-100 nav-pills-primary nav-pills-with-error">
                                {categories.map(category => (
                                    <Nav.Item key={category.id}>
                                        <Nav.Link eventKey={category.sku} className={`${getError(errors, category) ? 'has-error' : ''}`}>
                                            <span>{category.title}</span>
                                            <i className="fas fa-exclamation-circle icon-error"></i>
                                        </Nav.Link>
                                    </Nav.Item>
                                ))}
                            </Nav>
                        </Col>
                        <Col lg={9} >
                            <Tab.Content className="tab-content-primary tab-content">
                                {categories.map(category => {
                                    const categoryQuestions = questions.filter(q => q.type.category_sku.includes(category.sku))
                                    return (
                                        <Tab.Pane eventKey={category.sku} key={category.id}>
                                            <CategoryBlockFirstScoring
                                                key={'tab-cat-' + category.id}
                                                name={category.sku}
                                                questions={categoryQuestions}
                                                category={category}
                                                lastAnswer={lastAnswer}
                                            />
                                        </Tab.Pane>
                                    )
                                })}
                            </Tab.Content>
                        </Col>
                    </Row>
                </Tab.Container>
            </div>}
        </>
    )
}

export default FirstScoring;