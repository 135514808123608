import gql from 'graphql-tag'

import { QUESTION_TYPE_FIELDS } from "./question-type.fragment";

// List
export const LIST_QUESTION_TYPES = gql`
${QUESTION_TYPE_FIELDS}
query listQuestionTypes{            
    listQuestionTypes{      
        ...QuestionTypeFields    
    }
}`
// Get by ID
export const GET_QUESTION_TYPE_BY_ID = gql`
${QUESTION_TYPE_FIELDS}
query getQuestionTypeById($id: String!){            
    getQuestionTypeById(id: $id){      
        ...QuestionTypeFields    
    }
}`
// Update
export const UPDATE_QUESTION_TYPE = gql`
${QUESTION_TYPE_FIELDS}
mutation updateQuestionType($data: QuestionTypeInput!){            
    updateQuestionType(data: $data){
        ...QuestionTypeFields             
    }
}`
