export const SHOW_MODAL = 'startup/files/SHOW_MODAL';
export const HIDE_MODAL = 'startup/files/HIDE_MODAL';
export const FILE_CHANGE = 'startup/files/FILE_CHANGE';


export function showModalFiles(payload) {
    return {
        type: SHOW_MODAL,
        payload
    }
}
export function hideModalFiles() {
    return {
        type: HIDE_MODAL
    }
}
export function fileChange(payload) {
    return {
        type: FILE_CHANGE,
        payload: payload
    }
}

const initialState = {
    modal: {
        show: false,
        modalData: null
    },
    file_change: null
};

const filesReducer = (state = initialState, action) => {
    const { type, payload } = action;

    switch (type) {
        case SHOW_MODAL:
            return Object.assign(
                {},
                state,
                {
                    modal: {
                        show: true,
                        data: payload
                    }
                }
            );
        case HIDE_MODAL:
            return Object.assign(
                {},
                state,
                {
                    modal: {
                        show: false,
                        data: null
                    }
                }
            );
        case FILE_CHANGE:
            return Object.assign(
                {},
                state,
                {
                    file_change: payload
                }
            );
        default:
            return state;
    }
};

// Selector
export const getCurrentModalFiles = (state) => state.files.modal;
export const getFileChange = (state) => state.files.file_change;
// Export
export default filesReducer;