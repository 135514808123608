
/*eslint-disable*/
import React from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// SVG
import { ReactSVG } from "react-svg";
// Box
import BoxStartup from "../../components/box/BoxStartup";
import AdviceList from "../advices/AdviceList";
import ModalAdvice from "../advices/ModalAdvice";
// Props
interface BoxAdvicesProps {
    advices:Array<any>,
    title?: string,
    id?: string,
}
const BoxAdvices = (props:BoxAdvicesProps) => {
    // Props
    const { advices, title, id } = props;
    
    // Render
    return (
        <>
        <ModalAdvice />
        <BoxStartup  id={id ? id : ''}>
            <BoxStartup.Header>
                <span className="title">
                    { title ? title : 'Advice'}
                </span>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0'}>            
                    <AdviceList advices={advices}/>
            </BoxStartup.Body>
        </BoxStartup >
        </>
    )
}

export default BoxAdvices;
 