
/*eslint-disable*/
import React from "react";
import ProgressBarPercentageChart2 from "../charts/progress/ProgressBarPercentageChart2";
// Props
interface MostOptimistItemProps {
    item: any
}


const MostOptimistItem = (props: MostOptimistItemProps) => {
    const { item } = props
    // Render
    return (
        <div className="most-optimist-item">
            <span className="most-optimist-item-title">{item.name}</span>
            <div className="most-optimist-item-items">
                { item && item.items.length > 0 && item.items.map((type, index) => (
                    <span className="type-item" key={index}>{type.title}</span>
                ))}
            </div>
        </div>

    )
}

export default MostOptimistItem;
