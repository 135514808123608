// import { CHALLENGE_STATUS_COMPLETED } from "../../constants/challenges/challenges.constants";
// import { STARTUP_STATUS } from "../../constants/startup/startup.constants";

export default class ChallengeHelper {

    isCompleted(challenge) {
        // return challenge.complete || CHALLENGE_STATUS_COMPLETED.includes(challenge.status_sku);
        return challenge.complete;
    }

    resolvePercentage(challenge): any {
        let value = 0;
        if (challenge.metric_type_sku === 'progress') {
            value = challenge.progress;
        } else {
            value = (challenge.kpi_metric_current * 100) / challenge.kpi_metric_target
        }
       return value;

    }


}