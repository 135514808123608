import React from 'react';
import GuestExternalItem from './GuestExternalItem';

interface ListGuestExternalProps {
    guests: Array<any>
    onRemoveItem: Function
}

const ListGuestExternal = (props: ListGuestExternalProps) => {
    // Props
    const { guests, onRemoveItem } = props;
    const onRemove = (index)=>{
        onRemoveItem(index);
    }
    return (
        <div className="list-guest-external" >
            {guests && guests.length > 0 && guests.map((guest, index) => (
                <GuestExternalItem 
                    guest={guest} 
                    key={index} 
                    index={index}
                    onRemove={onRemove}/>
            
            ))} 
        </div>
    );
};
export default ListGuestExternal;