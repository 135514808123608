// Auth
import MainView from "./views/dashboard/MainView";
import MainElementView from "./views/dashboard/MainElementView";
import MainSubElementView from "./views/dashboard/MainSubElementView";
import FoundersAlignment from "./views/dashboard/FoundersAlignment";
import ExternalRatingView from "./views/dashboard/ExternalRatingView";
import ChallengesView from "./views/dashboard/ChallengesView";
import MainSubElementLimitedView from "./views/dashboard/MainSubElementLimitedView";
// import GoPremiumView from "./views/dashboard/GoPremiumView";

const routesDashboard = [
  // Dashboard
  //   {
  //     path: "/go-premium",
  //     name: "External Rating",
  //     icon: "ni ni-key-25",
  //     component: GoPremiumView,
  //     layout: "/dashboard",
  //     key: "go-premium",
  //   },
  {
    path: "/challenges",
    name: "Challenges",
    icon: "ni ni-key-25",
    component: ChallengesView,
    layout: "/dashboard",
    key: "challenges",
  },
  {
    path: "/external-rating",
    name: "External Rating",
    icon: "ni ni-key-25",
    component: ExternalRatingView,
    layout: "/dashboard",
    key: "external-rating",
  },
  {
    path: "/founders-alignment",
    name: "Founders' Alignment",
    icon: "ni ni-key-25",
    component: FoundersAlignment,
    layout: "/dashboard",
    key: "founders-alignment",
  },
  // Normal User
  {
    path: "/main/sub-element/:sub_element",
    name: "Sub Element",
    icon: "ni ni-key-25",
    component: MainSubElementView,
    layout: "/dashboard",
    key: "main-subelement",
  },
  // Limited User
  {
    path: "/main/sub-element-limited/:sub_element",
    name: "Sub Element",
    icon: "ni ni-key-25",
    component: MainSubElementLimitedView,
    layout: "/dashboard",
    key: "main-subelement",
  },
  {
    path: "/main/:main_element",
    name: "Main Element",
    icon: "ni ni-key-25",
    component: MainElementView,
    layout: "/dashboard",
    key: "main-element",
  },
  {
    path: "/main",
    name: "Main Dashboard",
    icon: "ni ni-key-25",
    component: MainView,
    layout: "/dashboard",
    key: "main-dashboard",
  },
];
export default routesDashboard;
