
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Apollo
import { useApolloClient } from "react-apollo";
import { RESOLVE_STARTUP_HASH_URL } from '../../queries/startup/startups.query';
// Bootstrap
import Container from 'react-bootstrap/Container';
import { useParams } from "react-router-dom";
import { removeToken, setToken } from "../../utils";
// Redux
import { useDispatch } from 'react-redux'
import { userDelete, userCreate } from "../../redux/ducks/user-duck";
// Helpers
import TokenHelper from "../../helpers/security/token.helper";

const AutoLoginView = (props) => {
    document.title = 'Auto Login'
    // Helper 
    const tokenHelper = new TokenHelper();
    // Param
    const { token } = useParams<any>();
    // Loading
    const [loading, setLoading] = useState(true)
    const [error, setError] = useState(false)
    // Client
    const client = useApolloClient();
    // Redux
    const dispatch = useDispatch();
    // Auto Login
    const autoLogin = useCallback(async () => {
        setLoading(true)
        try {
            // Remove actual token
            removeToken();
            // Remove User Redux
            dispatch(userDelete())
            // Resolve Token
            const response = await client.query({
                query: RESOLVE_STARTUP_HASH_URL,
                variables: { token: token }
            })
            const tokenUser = response.data.resolverStartupHashUrl || '';
            if (tokenUser === '') {
                throw new Error('Not token')
            }
            const user = tokenHelper.decode(tokenUser);
            if (user.id) {
                setToken(tokenUser)
                delete user.password;
                // Set User
                dispatch(userCreate(user))

                props.history.push(`/auth/login`)

            } else {
                throw new Error('Token Invalid')
            }
        } catch (e) {
            console.log(e);
            setError(true)
        } finally {
            setLoading(false)
        }
    }, [token, setLoading, setError])

    useEffect(() => {
        if (token) {
            autoLogin()
        }
    }, [token, autoLogin])
    // Render
    return (

        <Container>
            <div className="py-5 d-flex justify-content-center">
                {loading && <h2>Loading...</h2>}
                {error && <h2>Token Invalid</h2>}
                {!loading && <h2>Redirecting...</h2>}
            </div>
        </Container >
    )
}

export default AutoLoginView;
