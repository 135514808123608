import React, { useEffect } from "react";
import { useState } from "react";
// Loading
import LoadingOverlay from 'react-loading-overlay';
import BounceLoader from 'react-spinners/BounceLoader';
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Validation
import { Controller, useForm } from "react-hook-form";
// Form
import ReactSelect from "../select/ReactSelect";
// Button Fixed
import ButtonFixed from "../buttons/ButtonFixed";
import { useCallback } from "react";
import { toast } from "react-toastify";
import { UPDATE_STARTUP_GROUP } from "../../queries/startup/startups.query";
import { useApolloClient } from "react-apollo";
// Props
interface UserGroupFormProps {
    user: any,
    groups: any
}

const UserGroupForm = (props: UserGroupFormProps) => {
    // Props
    const { user, groups } = props;
    // Loading
    const [loading, setLoading] = useState(false);
    // Validation
    const { errors, control, setValue, getValues, trigger } = useForm({
        mode: 'onChange'
    });
    // Group
    const [groupSelected, setGroupSelected] = useState(null);
    const [optionGroups, setOptionGroups] = useState<any>([]);
    // Client
    const client = useApolloClient();
    // Change Group Select
    const onChangeGroup = (option) => {
        setGroupSelected(option);
        if (option) {
            setValue('group_id', option.value, { shouldValidate: true })
        } else {
            setValue('group_id', null, { shouldValidate: true })
        }

    }
    // Map Group Options
    const fetchData = useCallback(() => {
        const groupsMap = groups.map(group => {
            return {
                label: group.name,
                value: group.id
            }
        });
        setOptionGroups(groupsMap)
    }, [setOptionGroups, groups])
    useEffect(() => {
        if (groups) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [groups])
    // Fetch Form
    const fetchForm = useCallback(() => {
        const group = optionGroups.find(o => o.value === user.startup.group_id)
        if (group) {
            setGroupSelected(group);
            setValue('group_id', group.value);
        }
    }, [optionGroups, setValue, user])
    useEffect(() => {
        if (optionGroups && optionGroups.length > 0) {
            fetchForm()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [optionGroups])
    const onClickUpdate = async () => {
        await trigger();
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateStartupGroup()
        }
    }
    const updateStartupGroup = async () => {
        try {
            setLoading(true)
            const values = getValues()
            const toUpdate: any = {
                id: user.startup.id,
                ...values,
            }
            // console.log(toUpdate);
            // delete toCreate.category_sku;
            // // Parse

            const updateStartupGroupResponse = await client.mutate({
                mutation: UPDATE_STARTUP_GROUP,
                variables: { data: toUpdate }
            })
            const startup = updateStartupGroupResponse.data.updateStartupGroup || {}
            if (startup.id) {
                toast.success(`User updated succefully!`);
            } else {
                throw new Error('Cannot update')
            }

        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }
    }
    return (
        <LoadingOverlay active={loading}
            text={'Updating Elements...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>


            <Form noValidate name={'update-group-user'}>
                {/* Relation */}
                <Row>
                    {/* Challenge*/}
                    <Col lg="9">
                        <Row>
                            {/* Main element */}
                            <Col lg="6">
                                <Form.Group controlId="formGroupKeyProperty" className={`${errors.group_id ? 'is-invalid' : ''}`}>
                                    <Form.Label>Group</Form.Label>
                                    <Controller
                                        name="group_id"
                                        control={control}
                                        defaultValue={false}
                                        rules={{ required: 'Group is required' }}
                                        render={props =>
                                            <ReactSelect
                                                placeholder="Select a group"
                                                isClearable={true}
                                                isInvalid={errors.group_id}
                                                options={optionGroups}
                                                onChange={onChangeGroup}
                                                value={groupSelected}
                                            />
                                        } // props contains: onChange, onBlur and value
                                    />

                                    {errors.group_id && <div className="invalid-feedback"> {errors.group_id.message} </div>}

                                </Form.Group>
                            </Col>
                        </Row>
                    </Col>
                </Row>

                {/* Button */}
                <ButtonFixed onClick={onClickUpdate} loading={loading} />

            </Form>

        </LoadingOverlay>
    )
}

export default UserGroupForm;
