
/*eslint-disable*/
import React, { useEffect, useCallback } from "react";
// Questionnaire
import QuestionnaireIntro from "../../components/questionnaire/QuestionnaireIntro";
import QuestionnaireExample from "../../components/questionnaire/QuestionnaireExample";
import QuestionnaireStartupType from "../../components/questionnaire/QuestionnaireStartupType";
import QuestionnaireQuestions from "../../components/questionnaire/QuestionnaireQuestions";
import QuestionnaireResults from "../../components/questionnaire/QuestionnaireResults";
import MultipleStep from "../../components/multiple-step/MultipleStep";

// Redux
import { useSelector, useDispatch } from 'react-redux';
import { getQuestionnaireAdvance, resetQuestionnaire, setQuestions, updateQuestionsPage } from '../../redux/ducks/questionnaire-duck';
// Client
import { useApolloClient } from "react-apollo";
import { LIST_QUESTIONS_FIRST_SCORING } from "../../queries/questions/questions.query";
import { getUser } from "../../redux/ducks/user-duck";
import { useHistory } from "react-router-dom";
import { removeToken } from "../../utils";

const IntroView = (props) => {
    // History
    const history = useHistory();
    // User
    const user = useSelector(getUser);
    // Advance
    const advance = useSelector(getQuestionnaireAdvance);
    // Questions
    const client = useApolloClient();
    // Dispatch
    const dispatch = useDispatch();
    // Steps
    const steps = [
        {
            name: 'Intro',
            route: '/',
            component: QuestionnaireIntro
        },
        {
            name: 'Example',
            route: '/example',
            component: QuestionnaireExample
        },
        {
            name: 'Startup Type',
            route: '/startup',
            component: QuestionnaireStartupType
        },
        {
            name: 'Questions',
            route: '/questions',
            component: QuestionnaireQuestions
        },
        {
            name: 'Results',
            route: '/results',
            component: QuestionnaireResults
        },
    ];

    // Fetch Data
    const fetchData = useCallback(async () => {
        let questions;
        if (!user || user.startup_type !== 'not_limited') {
            dispatch(resetQuestionnaire());
            removeToken();
            history.push('/auth/login')
        }
        try {
            const resp = await client.query({
                query: LIST_QUESTIONS_FIRST_SCORING,
                fetchPolicy: 'no-cache'
            })
            questions = resp.data.listQuestions;

        } catch (e) {
            console.log(e);
        } finally {
            dispatch(setQuestions(questions));
        }
        dispatch(updateQuestionsPage(1))
    }, [client, user])
    // Init
    useEffect(() => {
        fetchData()
    }, [fetchData])
    // Render
    return (
        <>

            <MultipleStep steps={steps}>
                <div className="progress-multiple-step">
                    <div className="progress-multiple-step-bar" style={{ width: advance ? `${advance}%` : '0%' }}></div>
                </div>
            </MultipleStep>
        </>
    )
}

export default IntroView;
