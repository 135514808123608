

import React, { useState, useEffect } from "react";
import Form from 'react-bootstrap/Form'
import { useHistory } from "react-router-dom";
import { ReactSVG } from "react-svg";
import Button from "../buttons/Button";
// Redux
import { useDispatch } from 'react-redux';
import { updateAdvanceLead } from "../../redux/ducks/questionnaire-lead-duck";

const QuestionnaireExampleLead = (props) => {
    // History
    const history = useHistory();
    // Arrow
    const arrow = require("../../assets/img/multiple-step/q-arrow-right.svg").default;
    const arrowLeft = require("../../assets/img/multiple-step/q-arrow-left.svg").default;
    // Questions

    const nextRoute =  `/questionnaire/intro-lead/startup`; 
    const backRoute = `/questionnaire/intro-lead/`; 

    // Example
    const [checked,] = useState(2)
    // Dispatch
    const dispatch = useDispatch();
    const onClickBack = () =>{
        history.push(backRoute)
    }
    useEffect(() => {
        // Update Redux
        dispatch(updateAdvanceLead(
            9 * 2
        ))
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [])
    // Example
    const example = [
        {
            id: 1,
            title: 'Prospects/Customers agree that problem creates suffering and that they would pay to solve it.',
        },
        {
            id: 2,
            title: 'Prospects/Customers agree that problem exists and that a solution would be welcome',
        },
        {
            id: 3,
            title: 'Prospects/Customers have a solution to the problem, but not optimal',
        },
    ]
    return (
        <>
            {/* Example */}
            <div className="radio-group-questions">
                <h4 className="radio-group-title">How real is the problem?</h4>
                <div className="radio-group-questions-options">
                    {example.map((val, index) => (
                        <div className="radio-group-item" key={`custom-example-${val.id}`} >
                            <Form.Check
                                custom
                                name={`example-1`}
                                type={'radio'}
                                id={`example-intro-${val.id}`}
                                label={val.title}
                                checked={checked === val.id}
                                onChange={() => { }}
                            />
                        </div>
                    ))}

                </div>
            </div>
            <p className="multiple-step-description">
                Let's say you are manufacturing specifics helmets for cyclists. You could say that without it people will get hurt and will suffer. Thus to avoid pain and suffering they will pay for your helmet. However until cyclists agree that they feel unsafe without a helmet, you are not solving anything. What you believe is not important, it's what the market is telling you that matters!
            </p>
            <div className="multiple-step-btn-container">
                <Button
                    className="btn-violet"
                    onClick={onClickBack}>
                    <ReactSVG src={arrowLeft} wrapper="span" className="arrow-right icon-left" />
                    Back
                </Button>
                <Button
                    className="btn-violet"
                    onClick={() => { history.push(nextRoute) }}>
                    Next
                    <ReactSVG src={arrow} wrapper="span" className="arrow-right icon-right" />
                </Button>
            </div>

        </>
    )
}

export default QuestionnaireExampleLead;