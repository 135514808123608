import { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { hideModal, showModal, getCurrentModal } from '../../redux/ducks/modal-wrapper-duck';

export const useModal = () => {
   // Get modal params from store using reselect
    return {
       modal: useSelector(getCurrentModal, shallowEqual)
    };
};

export const useShowModal = props => {
    const dispatch = useDispatch();
   return useCallback(
        (params = {}) => {
           dispatch(showModal({ ...props, ...params }));
        },
        [dispatch]
    );
};


export const useConfirmationModal = () => {
    return {
       // Create your reusable confirmation modal logic
   };
};

export const useInfoModal = () => {
   return {
       // Create your reusable info modal logic
    };
};

export const useHideModal = () => {
    const dispatch = useDispatch();
    const handleOnClose = useCallback(() => {
       dispatch(hideModal());
    }, [dispatch]);
    return {
        handleOnClose 
    }
};