
/*eslint-disable*/
import React from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
// Box
import BoxStartup from "../../components/box/BoxStartup";
// List
import StrengthList from "../strengths/StrengthList";

// Props
interface BoxStrengthsProps {
    title: string,
    id?: string,
    strengths: any
}
const BoxStrengths = (props: BoxStrengthsProps) => {
    // Props
    const { title, id, strengths } = props;

    // Render
    return (

        <BoxStartup id={id ? id : 'box-strengths'}>
            <BoxStartup.Header>
                <Row>
                    <Col lg={4}>
                        <span className="title ">
                            {title ? `${title} Strengths` : ''}
                        </span>
                    </Col>
                    <Col lg={8} className={`d-lg-flex align-items-lg-center justify-content-lg-end mt-2 mt-lg-0`}>
                        <span className="title-description">
                            {title ? `When pitching your ${title.toLowerCase()}, leverage your strengths` : ''}
                        </span>
                    </Col>
                </Row>

            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0'}>
                <StrengthList strengths={strengths} />
            </BoxStartup.Body>
        </BoxStartup >
    )
}

export default BoxStrengths;
