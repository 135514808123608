import { CHART_COLOR } from "../charts/chart.constants";

export const STARTUP_STAGES = [
    {
        title: "Pre Seed",
        label: "Pre Seed",
        description: 'Pre-seed (raising less than $1M)',
        sku: "pre_seed",
        value: "pre_seed",

    },
    {
        title: "Seed",
        label: "Seed",
        description: 'Seed (Raising between $2 and $5M)',
        sku: "seed",
        value: "seed",
    },
    {
        title: "Series A",
        label: "Series A",
        description: 'Series A (Raising between $5 and $15M)',
        sku: "series_a",
        value: "series_a",
    }
];

export const LEVEL_OF_CRITICALITY = [
    {
        sku: 'low',
        title: 'Low',
    },
    {
        sku: 'medium',
        title: 'Medium',
    },
    {
        sku: 'high',
        title: 'High',
    },
]


export const DEFAULT_FUNDING_GLOBAL = [
    {
        label: "WEAK",
        sku: "not_fundable",
        value: 25,
        color: CHART_COLOR.inProgress,
        selected: false,
        partValue: null,
        factorDivision: 5
    },
    {
        label: "WORK TO DO",
        sku: "work_to_do",
        value: 25,
        color: CHART_COLOR.poor,
        selected: false,
        partValue: null
    },
    {
        label: "LOOKING GOOD",
        sku: 'looking_good',
        value: 25,
        color: CHART_COLOR.fair,
        selected: false,
        partValue: null
    },
    {
        label: "SOLID",
        sku: 'go_get_them',
        value: 25,
        color: CHART_COLOR.good,
        textColor: CHART_COLOR.good,
        selected: false,
        partValue: null
    },
]

export const DEFAULT_ELEMENT_SCORING = [
    {
        label: 'Team',
        percentage: 0,
        sku: 'team',
        hasData: false,
    },
    {
        label: 'Purpose',
        percentage: 0,
        sku: 'purpose',
        hasData: false,
    },
    {
        label: 'Market',
        percentage: 0,
        sku: 'market',
        hasData: false,
    },
    {
        label: 'Scale',
        percentage: 0,
        sku: 'scale',
        hasData: false,
    },
    {
        label: 'Product',
        percentage: 0,
        sku: 'product',
        hasData: false,
    },
    {
        label: 'Analytics',
        percentage: 0,
        sku: 'analytics',
        hasData: false,
    },
    {
        label: 'Traction',
        percentage: 0,
        sku: 'traction',
        hasData: false,
    },
];

export const DEFAULT_MAIN_ELEMENT_FUNDING =
    [
        {
            label: "POOR",
            sku: "poor",
            value: 33,
            color: CHART_COLOR.poor,
            selected: false,
            partValue: 0,
            factorDivision: 5
        },
        {
            label: "FAIR",
            sku: 'fair',
            value: 33,
            color: CHART_COLOR.fair,
            selected: false,
            partValue: 0,
            factorDivision: 3
        },
        {
            label: "GOOD",
            sku: 'good',
            value: 33,
            color: CHART_COLOR.good,
            textColor: CHART_COLOR.good,
            selected: false,
            partValue: 0,
            factorDivision: 3
        },
    ]
export const STARTUP_SITUATION = [
    {
        label: "Pre-Product",
        description: 'Pre-Product',
        value: "pre_product"
    },
    {
        label: "Product out / Pre-Revenue",
        description: 'Product out and Pre-Revenue',
        value: "product_out_pre_revenue"
    },
    {
        label: "Product out / Post-Revenue",
        description: 'Post-Revenue',
        value: "product_out_post_revenue"
    }
];



export const STARTUP_FUNDING_OPTIONS = [
    { label: "Empty", value: "empty", color: '#fff' },
    { label: "Weak", value: "not_fundable", color: '#dddddd' },
    { label: "Work to do", value: "work_to_do", color: '#ffb1a6' },
    { label: "Looking good", value: "looking_good", color: '#ffec9a' },
    { label: "Solid", value: "go_get_them", color: '#b0ec9a' },
];


export const QUESTION_CATEGORIES = [
    // Team
    { label: "Team", value: "team" },
    // Purpose
    { label: "Purpose", value: "purpose" },
    // Market
    { label: "Market", value: "market" },
    // Scale
    { label: "Scale", value: "scale" },
    // Product
    { label: "Product", value: "product" },
    // Analytics
    { label: "Analytics", value: "analytics" },
    // Traction
    { label: "Traction", value: "traction" },
]


export const QUESTION_TYPES = [
    // Team
    { category_sku: 'team', order: 1, title: "Skills", sku: "skills" },
    { category_sku: 'team', order: 2, title: "Roles", sku: "roles" },
    { category_sku: 'team', order: 3, title: "Experience", sku: "experience" },
    { category_sku: 'team', order: 4, title: "Chemistry", sku: "chemistry" },
    { category_sku: 'team', order: 5, title: "Culture", sku: "culture" },
    // Purpose
    { category_sku: 'purpose', order: 6, title: "Founders' Fit", sku: "founders_fit" },
    { category_sku: 'purpose', order: 7, title: "Problem", sku: "problem" },
    { category_sku: 'purpose', order: 8, title: "Ambition", sku: "ambition" },
    { category_sku: 'purpose', order: 9, title: "Clarity of Vision", sku: "clarity_of_vision" },
    // Market
    { category_sku: 'market', order: 10, title: "Size", sku: "size" },
    { category_sku: 'market', order: 11, title: "Customers", sku: "customers" },
    { category_sku: 'market', order: 12, title: "Partners", sku: "partners" },
    { category_sku: 'market', order: 13, title: "Competition", sku: "competition" },
    { category_sku: 'market', order: 14, title: "Timing", sku: "timing" },
    // Scale
    { category_sku: 'scale', order: 15, title: "Distribution", sku: "distribution" },
    { category_sku: 'scale', order: 16, title: "Go-to-market", sku: "go_to_market" },
    { category_sku: 'scale', order: 17, title: "Customer Experience", sku: "customer_experience" },
    { category_sku: 'scale', order: 18, title: "Business Model", sku: "business_model" },
    { category_sku: 'scale', order: 19, title: "Barrier to entry", sku: "barrier_to_entry" },
    { category_sku: 'scale', order: 20, title: "Hiring", sku: "hiring" },
    // Product
    { category_sku: 'product', order: 21, title: "Roadmap", sku: "roadmap" },
    { category_sku: 'product', order: 22, title: "Adoption", sku: "adoption" },
    { category_sku: 'product', order: 23, title: "Time to Value", sku: "time_to_value" },
    { category_sku: 'product', order: 24, title: "Retention", sku: "retention" },
    { category_sku: 'product', order: 25, title: "Engagement", sku: "engagement" },
    { category_sku: 'product', order: 26, title: "Branding", sku: "branding" },
    // Analytics
    { category_sku: 'analytics', order: 27, title: "Assumptions", sku: "assumptions" },
    { category_sku: 'analytics', order: 28, title: "Metrics", sku: "metrics" },
    { category_sku: 'analytics', order: 29, title: "Feedback Loop", sku: "feedback_loop" },
    // Traction
    { category_sku: 'traction', order: 30, title: "Runway", sku: "runway" },
    { category_sku: 'traction', order: 31, title: "Sales", sku: "sales" },
    { category_sku: 'traction', order: 32, title: "Marketing", sku: "marketing" },
    { category_sku: 'traction', order: 33, title: "Engineering", sku: "product" },
    { category_sku: 'traction', order: 34, title: "Revenue", sku: "revenue" },

]
export const QUESTION_TYPES_MENU_LIMITED = [
    // Purpose Founder
    { category_sku: 'purpose_founders', order: 9, title: "Vision", sku: "clarity_of_vision" },
    { category_sku: 'purpose_founders', order: 6, title: "Founders' Fit", sku: "founders_fit" },
    // Team Strengths => team_strengths
    { category_sku: 'team_strengths', order: 1, title: "Skills", sku: "skills" },
    { category_sku: 'team_strengths', order: 2, title: "Roles", sku: "roles" },
    { category_sku: 'team_strengths', order: 3, title: "Experience", sku: "experience" },
    { category_sku: 'team_strengths', order: 4, title: "Chemistry", sku: "chemistry" },
    { category_sku: 'team_strengths', order: 5, title: "Culture", sku: "culture" },
    // Value Proposition => value_proposition
    { category_sku: 'value_proposition', order: 7, title: "Problem", sku: "problem" },
    { category_sku: 'value_proposition', order: 8, title: "Ambition", sku: "ambition" },
    // Positioning => positioning
    { category_sku: 'positioning', order: 13, title: "Competition", sku: "competition" },
    { category_sku: 'positioning', order: 32, title: "Marketing", sku: "marketing" },
    // Market Segmentation => market_segmentation
    { category_sku: 'market_segmentation', order: 10, title: "Sizing and Segmentation", sku: "size" },
    { category_sku: 'market_segmentation', order: 11, title: "Customers", sku: "customers" },
    // Go-to-market and Timing => go_to_market_timing
    { category_sku: 'go_to_market_timing', order: 16, title: "Go-to-market", sku: "go_to_market" },
    { category_sku: 'go_to_market_timing', order: 21, title: "Roadmap", sku: "roadmap" },
    { category_sku: 'go_to_market_timing', order: 14, title: "Timing", sku: "timing" },
    { category_sku: 'go_to_market_timing', order: 19, title: "Barrier to entry", sku: "barrier_to_entry" },
    // Product Experience =>  product_experience 
    { category_sku: 'product_experience', order: 22, title: "Adoption", sku: "adoption" },
    { category_sku: 'product_experience', order: 23, title: "Time to Value", sku: "time_to_value" },
    { category_sku: 'product_experience', order: 24, title: "Retention", sku: "retention" },
    { category_sku: 'product_experience', order: 25, title: "Engagement", sku: "engagement" },
    { category_sku: 'product_experience', order: 26, title: "Branding", sku: "branding" },
    // Business Model => business_model
    { category_sku: 'business_model', order: 15, title: "Distribution", sku: "distribution" },
    { category_sku: 'business_model', order: 18, title: "Monetization", sku: "business_model" },
    // Analytics and customer experience => analytics_customer_experience
    { category_sku: 'analytics_customer_experience', order: 27, title: "Assumptions", sku: "assumptions" },
    { category_sku: 'analytics_customer_experience', order: 28, title: "Metrics", sku: "metrics" },
    { category_sku: 'analytics_customer_experience', order: 17, title: "Customer Experience", sku: "customer_experience" },
    { category_sku: 'analytics_customer_experience', order: 29, title: "Feedback Loop", sku: "feedback_loop" },
    // Momentum to Scale => momentum_to_scale
    { category_sku: 'momentum_to_scale', order: 34, title: "Revenue", sku: "revenue" },
    { category_sku: 'momentum_to_scale', order: 30, title: "Runway", sku: "runway" },
    { category_sku: 'momentum_to_scale', order: 31, title: "Sales", sku: "sales" },
    { category_sku: 'momentum_to_scale', order: 33, title: "Engineering", sku: "product" },
    { category_sku: 'momentum_to_scale', order: 12, title: "Partners", sku: "partners" },
    { category_sku: 'momentum_to_scale', order: 20, title: "Hiring", sku: "hiring" },
]


export const STARTUP_STATUS = [
    {
        key: 'good',
        label: 'Good',
        color: CHART_COLOR.good,

    },
    {
        key: 'fair',
        label: 'Fair',
        color: CHART_COLOR.fair,
    },
    {
        key: 'poor',
        label: 'Poor',
        color: CHART_COLOR.poor,
    },
]

export const STARTUP_BUSINESS_CATEGORIES = [
    {
        sku: 'b2c',
        title: 'B2C',
    },
    {
        sku: 'b2b',
        title: 'B2B',
    },
    {
        sku: 'b2b2c',
        title: 'B2B2C',
    },
    {
        sku: 'other',
        title: 'Other',
    },
]
export const STARTUP_BUSINESS_TYPES_1 = [
    {
        sku: 'e-commerce',
        title: 'E-commerce',
    },
    {
        sku: 'sass',
        title: 'Saas',
    },
    {
        sku: 'hardware',
        title: 'Hardware',
    },
    {
        sku: 'software',
        title: 'Software',
    },
    {
        sku: 'marketplace',
        title: 'Marketplace',
    },
    {
        sku: 'deep_tech',
        title: 'Deep Tech',
    },
    {
        sku: 'other',
        title: 'Other',
    },
]
export const STARTUP_BUSINESS_TYPES_2 = [
    {
        sku: 'advertising',
        title: 'Advertising',
    },
    {
        sku: 'agr_tech',
        title: 'Agr Tech',
    },
    {
        sku: 'al',
        title: 'Al',
    },
    {
        sku: 'ar_vr',
        title: 'AR/VR',
    },
    {
        sku: 'biotech',
        title: 'Biotech',
    },
    {
        sku: 'blockchain',
        title: 'Blockchain',
    },
    {
        sku: 'autonomous_vehicules',
        title: 'Autonomous vehicules',
    },
    {
        sku: 'clean_tech',
        title: 'Clean Tech',
    },
    {
        sku: 'cloud_infrastructure',
        title: 'Cloud infrastructure Tech',
    },
    {
        sku: 'health_fitness',
        title: 'Health & Fitness',
    },
    {
        sku: 'consumer_products',
        title: 'Consumer products',
    },
    {
        sku: 'beauty_tech',
        title: 'Beauty tech',
    },
    {
        sku: 'crypto',
        title: 'Crypto',
    },
    {
        sku: 'data_services',
        title: 'Data services',
    },
    {
        sku: 'developer_tools',
        title: 'Developer tools',
    },
    {
        sku: 'ecommerce',
        title: 'E-commerce',
    },
    {
        sku: 'ed_tech',
        title: 'Ed-tech',
    },
    {
        sku: 'energy_tech',
        title: 'Energy Tech',
    },
    {
        sku: 'enterprise',
        title: 'Enterprise',
    },
    {
        sku: 'entertainment_sports',
        title: 'Entertainment & Sports',
    },
    {
        sku: 'fashion',
        title: 'Fashion',
    },
    {
        sku: 'fintech',
        title: 'Fintech',
    },
    {
        sku: 'food',
        title: 'Food',
    },
    {
        sku: 'games_esport',
        title: 'Games/esport',
    },
    {
        sku: 'hardware',
        title: 'Hardware',
    },
    {
        sku: 'impact',
        title: 'Impact',
    },
    {
        sku: 'iot',
        title: 'IoT',
    },
    {
        sku: 'logistics',
        title: 'Logistics',
    },
    {
        sku: 'marketing_automation',
        title: 'Marketing automation',
    },
    {
        sku: 'marketplaces',
        title: 'Marketplaces',
    },
    {
        sku: 'media_content',
        title: 'Media/content',
    },
    {
        sku: 'messaging',
        title: 'Messaging',
    },
    {
        sku: 'payments',
        title: 'Payments',
    },
    {
        sku: 'real_estate',
        title: 'Real estate',
    },
    {
        sku: 'retail',
        title: 'Retail',
    },
    {
        sku: 'robotics',
        title: 'Robotics',
    },
    {
        sku: 'saas',
        title: 'SAAS',
    },
    {
        sku: 'sales_crm',
        title: 'Sales & CRM',
    },
    {
        sku: 'security',
        title: 'Security',
    },
    {
        sku: 'travel',
        title: 'Travel',
    },
    {
        sku: 'space',
        title: 'Space',
    },

]

export const MOST_OPTIMIST = [
    {
        name: 'Paul',
        items: [
            {
                title: 'Retention'
            },
            {
                title: 'Time to Value'
            },
            {
                title: 'Sales'
            },
            {
                title: 'Marketing'
            },
            {
                title: 'Business Model'
            },
            {
                title: 'Clarity'
            }

        ]
    },
    {
        name: 'John',
        items: [
            {
                title: 'Skills'
            },
            {
                title: 'Roles'
            },
            {
                title: 'Size'
            },
            {
                title: 'Culture'
            },
            {
                title: 'Partners'
            },
            {
                title: 'Timing'
            }
        ]
    },
    {
        name: 'Ringo',
        items: [
            {
                title: 'Competition'
            },
            {
                title: 'Ambition'
            },
            {
                title: 'Problem'
            },
        ]
    },
]

export const CO_FOUNDERS_CATEGORIES = () => {

    const categories: any = [];
    for (let cat of QUESTION_CATEGORIES) {
        const types = QUESTION_TYPES.filter(t => t.category_sku === cat.value)
        categories.push({
            title: cat.label,
            types: types.map((val) => {
                return {
                    type_title: val.title,
                    spread_status: 'empty'
                }
            })

        })
    }
    return categories;
}

export const CO_FOUNDERS_SUMMARY = [
    {
        title: 'Perfect',
        percentage: 41,
        color: CHART_COLOR.good
    },
    {
        title: 'Fair',
        percentage: 61,
        color: CHART_COLOR.fair
    },
    {
        title: 'Poor',
        percentage: 21,
        color: CHART_COLOR.poor
    },
    {
        title: 'Drifting',
        percentage: 24,
        color: CHART_COLOR.drifting
    },
    {
        title: 'Bad',
        percentage: 2,
        color: CHART_COLOR.bad
    }
]

export const CO_FOUNDERS_SUMMARY_EMPTY = [
    {
        sku: 'good',
        title: 'Good',
        percentage: null,
        color: CHART_COLOR.good
    },
    {
        sku: 'fair',
        title: 'Fair',
        percentage: null,
        color: CHART_COLOR.fair
    },
    {
        sku: 'poor',
        title: 'Poor',
        percentage: null,
        color: CHART_COLOR.poor
    },
    {
        sku: 'drifting',
        title: 'Drifting',
        percentage: null,
        color: CHART_COLOR.drifting
    },
    {
        sku: 'bad',
        title: 'Bad',
        percentage: null,
        color: CHART_COLOR.bad
    }
]

export enum AuthStates{
    AVAILABLE = 'AVAILABLE',
    NOT_AVAILABLE = 'NOT_AVAILABLE',
    PROCESS = 'PROCESS',
}

export const RADAR_CATEGORIES = {
    labels: ['Team', 'Purpose', 'Market', 'Scale', 'Product', 'Analytics', 'Traction'],
    datasets: [
        {
            label: 'First diagnostic',
            backgroundColor: 'rgba(204,62,50,0.1)',
            borderColor: 'rgba(204,62,50,1)',
            pointBackgroundColor: 'rgba(204,62,50,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(204,62,50,1)',
            borderWidth: 1,
            data: []
        },
        {
            label: 'Improves',
            backgroundColor: 'rgba(230,147,58,0.1)',
            borderColor: 'rgba(230,147,58,1)',
            pointBackgroundColor: 'rgba(230,147,58,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(230,147,58,1)',
            borderWidth: 1,
            data: []
        }
    ]
};