import React from 'react';
import LockedOverlay from '../locked/LockedOverlay';
import AdviceItem from './AdviceItem';

interface AdviceListProps {
    advices: Array<any>,
    emptyString?: any,
}

const AdviceList = (props: AdviceListProps) => {
    // Props
    const { advices,emptyString } = props;

    return (
        <div className="advice-list" >
            {/* Loading */}
            {!advices &&
                <div className="advice-item-empty">
                    <span>Loading...</span>
                </div>
            }
            {/* Has Files */}
            {advices && advices.length > 0 && advices.map((advice, index) => (
                <AdviceItem advice={advice} key={index} >
                        { advice.locked && <LockedOverlay />}
                </AdviceItem>
            ))}
            {/* Empty */}
            {advices && advices.length === 0 &&
                <div className="advice-item-empty">
                    <span>{emptyString ? emptyString : 'No Advices'}</span>
                </div>
            }      
        </div>
    );
};
export default AdviceList;