/*eslint-disable*/
import React, { useCallback, useEffect, useRef, useState } from "react";
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// Charts
import MiddleGaugeChart from '../../components/charts/gauge-chart/MiddleGaugeChart';
import BoxStartup from "../../components/box/BoxStartup";
import { ReactSVG } from "react-svg";
import needle from "../../assets/img/needle/needle-min.svg";

// Props
interface BoxFundabilityProps {
    fundability: any,
    fundabilityInternal: any,
    fundabilityExternal: any,
    displayFooter: boolean
}


const BoxFundability = (props: BoxFundabilityProps) => {
    // Filter
    const [filter, setFilter] = useState({
        me: true,
        internal: false,
        external: false
    })
    // Internal
    const [internal, setInternal] = useState<any>(null)
    // external
    const [external, setExternal] = useState<any>(null)
    // Props
    const { fundability, fundabilityExternal, fundabilityInternal, displayFooter} = props;
    // On Change Filter
    const onChangeCheckbox = (event) => {
        // console.log(event);
        const { target } = event;
        const { name, checked } = target;

        setFilter(prevState => ({ ...prevState, [name]: checked }));
    }
    const onChangeFilter = useCallback(() => {
        if (filter.external) {
            setExternal(fundabilityExternal)
        } else {
            setExternal(null)
        }
        if (filter.internal) {
            setInternal(fundabilityInternal)
        } else {
            setInternal(null)
        }
    }, [filter, setExternal, setInternal])
    useEffect(() => {
        onChangeFilter()
    }, [filter, onChangeFilter])
    // Render
    return (
        <BoxStartup className={''} id="box-fundability">
            <BoxStartup.Header>
                <span className="title">Strategy</span>
            </BoxStartup.Header>
            <BoxStartup.Body className={'p-0'}>
                <div id="main-chart-container">
                    <MiddleGaugeChart
                        data={fundability}
                        external={external}
                        internal={internal}
                    />
                </div>


            </BoxStartup.Body>
            { displayFooter && <BoxStartup.Footer>
                {/* Footer */}
                <div className="box-main-element-footer">
                    <div className="footer-title">
                        <span>Rating View:</span>
                    </div>
                    <div className="checkbox-with-icon">
                        <Form.Check
                            className={'custom-checkbox-sm'}
                            custom
                            name={'internal'}
                            type={'checkbox'}
                            id={`custom-checkbox-seed`}
                            label={`Internal`}
                            checked={filter.internal}
                            onChange={onChangeCheckbox}
                        />
                        <ReactSVG src={needle} wrapper="span" className="icon-checkbox comparision-icon yellow" />
                    </div>
                    <div className="checkbox-with-icon">
                        <Form.Check
                            className={'custom-checkbox-sm'}
                            custom
                            name={'external'}
                            type={'checkbox'}
                            id={`custom-checkbox-series-a`}
                            label={`External`}
                            checked={filter.external}
                            onChange={onChangeCheckbox}
                        />
                        <ReactSVG src={needle} wrapper="span" className="comparision-icon" />
                    </div>



                </div>
            </BoxStartup.Footer>}
        </BoxStartup >

    )
}

export default BoxFundability;
