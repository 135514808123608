import React from 'react';
import BoxStartupBody from './BoxStartupBody';
import BoxStartupHeader from './BoxStartupHeader';
import BoxStartupFooter from './BoxStartupFooter';




const BoxStartup = (props: any) => {
    const { className, id } = props;
    return (
        <div className={`box-startup ${className ? className : ''}`} id={`${id ? id : ''}`}>

            {props.children}
        </div>
    );
};

BoxStartup.Header = BoxStartupHeader;
BoxStartup.Body = BoxStartupBody;
BoxStartup.Footer = BoxStartupFooter;

export default BoxStartup;