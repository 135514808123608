
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Container from 'react-bootstrap/Container';
import Breadcrumb from 'react-bootstrap/Breadcrumb';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
// Box
import BoxStartup from "../../../components/box/BoxStartup";
// Table
import QuestionTable from "../../../components/questions/admin/QuestionTable";
// Apollo
import { useApolloClient } from "react-apollo";
// Router
import { useHistory } from "react-router-dom";
// Toastify
import { LIST_QUESTIONS_ADMIN } from "../../../queries/questions/questions.query";
import Button from "../../../components/buttons/Button";
import { LIST_QUESTION_TYPES } from "../../../queries/question-types/question-types.query";
import QuestionTypesTable from "../../../components/question-types/QuestionTypesTable";



const QuestionTypeIndexView = (props) => {
    // Router
    const history = useHistory();
    // Startups
    const [questionTypes, setQuestionTypes] = useState([]);
    // Loading
    const [loading, setLoading] = useState(true);
    // Client
    const client = useApolloClient();
    // Fetch Data
    const fetchData = useCallback(async () => {
        setLoading(true);
        const response = await client.query({
            query: LIST_QUESTION_TYPES,
            fetchPolicy: 'no-cache'
        })
        const types = response.data.listQuestionTypes || [];
        setQuestionTypes(types)
        setLoading(false);
    }, [client, setQuestionTypes]);
    // Call Fetch Data
    useEffect(() => {
        fetchData();
    }, [fetchData])
    // View
    const onClickViewItem = (questionType) => {
        history.push(`/admin/sub-elements/edit/${questionType.id}`)
    }

    // Render
    return (
        <>
            <Container>
                <Breadcrumb>
                    <Breadcrumb.Item active> Sub Elements </Breadcrumb.Item>
                </Breadcrumb>
                {/* Box */}
                <BoxStartup>
                    <BoxStartup.Header>
                        <span className="title"> Sub Elements </span>
                    </BoxStartup.Header>
                    <BoxStartup.Body>
                        <QuestionTypesTable
                            questionTypes={questionTypes}
                            loading={loading}
                            onClickViewItem={onClickViewItem}

                        />
                    </BoxStartup.Body>
                </BoxStartup>
            </Container>
        </>
    )
}

export default QuestionTypeIndexView;
