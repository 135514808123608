/*eslint-disable*/
import React, { useEffect, useState, useCallback, useRef } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Boxs
import BoxFundability from "../../components/dashboard/BoxFundability";
import BoxPriorization from "../../components/dashboard/BoxPriorization";
import BoxElementScoring from "../../components/dashboard/BoxElementScoring";
import BoxCompletionLead from "../../components/dashboard/BoxCompletionLead";
// Constants
import {
  DEFAULT_ELEMENT_SCORING,
  DEFAULT_FUNDING_GLOBAL,
  RADAR_CATEGORIES,
} from "../../constants/startup/startup.constants";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_BEST_PRACTICE } from "../../queries/best-practice/best-practice.query";
// Toast
import { toast } from "react-toastify";
// Lodash
import * as _ from "lodash";
// Redux
import { useSelector } from "react-redux";
import {
  getDashboardData
} from "../../redux/ducks/dashboard-duck";
import { getAnswerLastAnswer } from "../../redux/ducks/answer-duck";
import { getUser } from "../../redux/ducks/user-duck";
// Hook
import useWindowSize from "../../hooks/useWindowResize";
// Helper
import StartupHelper from "../../helpers/startup/startup.helper";




const MainView = (props) => {
  // Helper
  const startupHelper = new StartupHelper();
  // Selectors
  const lastAnswer = useSelector(getAnswerLastAnswer);
  const dashboardData = useSelector(getDashboardData);
  const user = useSelector(getUser);
  // Client
  const client = useApolloClient();
  // Fundability
  const [fundability, setFundability] = useState<any>(DEFAULT_FUNDING_GLOBAL);
  const [fundabilityInternal, setFundabilityInternal] = useState<any>(
    DEFAULT_FUNDING_GLOBAL
  );
  const [fundabilityExternal, setFundabilityExternal] = useState<any>(
    DEFAULT_FUNDING_GLOBAL
  );
  // Answer Percentage
  const [answerPercentage, setAnswerPercentage] = useState(0);
  // Priorization
  const [priorization, setPriorization] = useState({
    now: null,
    next: null,
    future: null,
  });
  // Element Scoring
  const [elementScoring, setElementScoring] = useState<any>([]);
  // Best Practices
  const [bestPractices, setBestPractices] = useState<any>(null)
  // Categories
  const [radarCategories, setRadarCategories] = useState<any>(null)
  // Fetch Fundability
  const fetchFundability = useCallback(
    (fundingGlobal) => {
      const cloned = _.cloneDeep(DEFAULT_FUNDING_GLOBAL);
      if (lastAnswer && lastAnswer.has_answered_all) {
        if (
          fundingGlobal.hasRefined &&
          fundingGlobal.refinedScoring.funding !== "empty"
        ) {
          const wanted = cloned.find(
            (f) => f.sku === fundingGlobal.refinedScoring.funding
          );
          wanted.selected = true;
          wanted.partValue = fundingGlobal.refinedScoring.value;
          setFundability([...cloned]);
        } else if (fundingGlobal.firstScoring.funding !== "empty") {
          const wanted = cloned.find(
            (f) => f.sku === fundingGlobal.firstScoring.funding
          );
          wanted.selected = true;
          wanted.partValue = fundingGlobal.firstScoring.value;
          setFundability([...cloned]);
        } else {
          cloned[0].selected = true;
          cloned[0].partValue = 0;
          setFundability([...cloned]);
        }
      } else {
        cloned[0].selected = true;
        cloned[0].partValue = 0;
        setFundability([...cloned]);
      }
    },
    [setFundability, DEFAULT_FUNDING_GLOBAL, lastAnswer]
  );

  // Fetch Priorization
  const fetchPriorization = useCallback(
    (priorization) => {
      if (lastAnswer && lastAnswer.has_answered_all) {
        const now = priorization.filter((p) => p.now);
        const next = priorization.filter((p) => p.next);
        const future = priorization.filter((p) => p.future);
        setPriorization({ now: now, next: next, future: future });
      } else {
        setPriorization({ now: [] as any, next: [] as any, future: [] as any });
      }
    },
    [setPriorization, lastAnswer]
  );
  // Fetch Element Scoring
  const fetchElementScoring = useCallback(
    (categories) => {
      const cloned = _.cloneDeep(DEFAULT_ELEMENT_SCORING);
      for (let category of categories) {
        const wanted = cloned.find((c) => c.sku === category.category_sku);
        wanted.hasData = category.hasData;
        if (category.hasRefinedChosen) {
          wanted.percentage = category.refinedScoring * 10;
        } else {
          wanted.percentage = category.firstScoring * 10;
        }
      }
      setElementScoring([...cloned]);
    },
    [setElementScoring, DEFAULT_ELEMENT_SCORING]
  );
  // Fetch Radar Categories
  const fetchRadarCategories = useCallback(
    (categories, first_total) => {
      const cloned = _.cloneDeep(RADAR_CATEGORIES);
      if (first_total) {
        // First categories
        const firstCategories = first_total.categories;
        // First categories Without refined scoring
        const firstCategoriesWithRefined = firstCategories.filter((cat) => cat.hasFirstRefined);
        // If Has with refined
        if (firstCategoriesWithRefined.length > 0) {
          // Clone Radar <\>
          const cloned = _.cloneDeep(RADAR_CATEGORIES);
          const dataRedRadar: any = [];
          const dataOrangeRadar: any = [];
          // Each Categories
          firstCategories.forEach((firstCategoryItem) => {
            if (firstCategoryItem.hasFirstRefined) {
              // Set Red
              if (firstCategoryItem.hasRefinedChosen) {
                dataRedRadar.push(firstCategoryItem.refinedScoring);
              } else {
                dataRedRadar.push(firstCategoryItem.firstScoring);
              }
              // Set Orange
              const lastCategoryItem = categories.find(cat => cat.category_sku === firstCategoryItem.category_sku);
              if (lastCategoryItem.hasRefinedChosen) {
                dataOrangeRadar.push(lastCategoryItem.refinedScoring);
              } else {
                dataOrangeRadar.push(lastCategoryItem.firstScoring);
              }
            } else {
              dataOrangeRadar.push(undefined)
              // Set Red
              const lastCategoryItem = categories.find(cat => cat.category_sku === firstCategoryItem.category_sku);
              if (lastCategoryItem.hasRefinedChosen) {
                dataRedRadar.push(lastCategoryItem.refinedScoring);
              } else {
                dataRedRadar.push(lastCategoryItem.firstScoring);
              }
            }
          });


          cloned.datasets[0].data = dataRedRadar;
          cloned.datasets[1].data = dataOrangeRadar;
          setRadarCategories({ ...cloned });
        } else {
          // Set Last Answer Red
          const cloned = _.cloneDeep(RADAR_CATEGORIES);
          const mapCatLasAnswer = categories.map((category) => {
            if (category.hasRefinedChosen) {
              return category.refinedScoring;
            } else {
              return category.firstScoring;
            }
          })
          cloned.datasets[0].data = mapCatLasAnswer;
          setRadarCategories({ ...cloned });
        }
      } else if (lastAnswer.id) {
        const cloned = _.cloneDeep(RADAR_CATEGORIES);
        const mapCatLasAnswer = categories.map((category) => {
          if (category.hasRefinedChosen) {
            return category.refinedScoring;
          } else {
            return category.firstScoring;
          }
        })
        cloned.datasets[0].data = mapCatLasAnswer;
        setRadarCategories({ ...cloned });
      } else {
        setRadarCategories({ ...cloned });
      }
    },
    [setRadarCategories, lastAnswer]
  );

  // Fetch Best Practices
  const fetchBestPractices = useCallback(async () => {
    // Suggestions
    const bestPracticeResponse = await client.query({
      query: LIST_BEST_PRACTICE,
      fetchPolicy: "no-cache",
    });
    const bestPractices =
      bestPracticeResponse.data.listBestPractice || [];
    // const ordered = _.orderBy(bestPractices, ['type.order', 'created_at'], ['asc', 'asc']);
    // Files
    setBestPractices(startupHelper.mapFiles(bestPractices));
  }, [setBestPractices])
  // Fetch Data
  const fetchData = useCallback(async () => {
    try {
      // Startup
      const startup = dashboardData;
      // Stage
      // setStageSku(startup.stage_sku);
      // Totals
      const totals = startup.totals;
      const first_total = totals.first_total;
      console.log(totals)
      console.log(first_total)
      // Funding Flobal
      const fundingGlobal = totals.funding_global;
      // Answer Percentage
      setAnswerPercentage(totals.answer_percentage);
      // Fetch Fundability
      fetchFundability(fundingGlobal);
      // Priorization
      const priorization = totals.priorization;
      fetchPriorization(priorization);
      // Element Scoring
      const categories = totals.categories;
      fetchElementScoring(categories);
      fetchRadarCategories(categories, first_total)
      // // Internal Total
      // const totals_internal = startup.totals_internal;
      // const fundingGlobalInternal = totals_internal.funding_global;
      // fetchFundabilityInternal(fundingGlobalInternal);
      // // External Total
      // const totals_external = startup.totals_external;
      // const fundingGlobalExternal = totals_external.funding_global;
      // fetchFundabilityExternal(fundingGlobalExternal);
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      // setLoading(false);
    }
  }, [
    client,
    dashboardData,
    fetchFundability,
    setAnswerPercentage,
    fetchPriorization,
    setAnswerPercentage,
    // fetchFundabilityInternal,
    // fetchFundabilityExternal,
    fetchBestPractices
  ]);
  // Init load one time totals
  useEffect(() => {
    if (dashboardData && dashboardData.id) {
      fetchData();
    }
  }, [dashboardData]);
  // Fetch All data what dont need dashboard data
  useEffect(() => {
    // Best Practices
    fetchBestPractices();
  }, []);
  //#region Sizes
  // Sizes
  const windowSize = useWindowSize();
  const [columnChallengeHeight, setColumnChallengeHeight] = useState("auto");
  // Body Ref
  const colPriorizationRef = useRef<HTMLDivElement>(null);
  const changeSizes = useCallback(() => {
    if (colPriorizationRef && colPriorizationRef.current) {
      const box = colPriorizationRef.current.querySelector(".box-startup");
      if (box) {
        if (windowSize.width >= 1024) {
          const height = box.clientHeight;
          setColumnChallengeHeight(`${height}px`);
        } else {
          setColumnChallengeHeight(`auto`);
        }
      }
    }
  }, [windowSize, colPriorizationRef, setColumnChallengeHeight]);

  // Change
  useEffect(() => {
    if (windowSize.width && colPriorizationRef.current) {
      changeSizes();
    }
  }, [windowSize, colPriorizationRef, priorization]);
  //#endregion Sizes


  // Render
  return (
    <>
      {/* First Row */}
      <Row>
        <Col md={12} lg={6} className="mb-16">
          <BoxCompletionLead
            answerPercentage={answerPercentage}
            loading={!lastAnswer ? true : false}
            radarCategories={radarCategories}
          />
        </Col>
        <Col md={12} lg={6} className="mb-16">
          <BoxElementScoring elementScoring={elementScoring} />
        </Col>
      </Row>
      {/* Second Row */}
      <Row>
        <Col 
          style={{ height: columnChallengeHeight, overflow: "hidden" }}
          md={12} lg={6} className="mb-16">
          <BoxFundability
            fundability={fundability}
            fundabilityExternal={fundabilityExternal}
            fundabilityInternal={fundabilityInternal}
            displayFooter={false}
          />
        </Col>
        <Col
          md={12}
          lg={6}
          className="mb-16"
          ref={colPriorizationRef}
        >
          <BoxPriorization
            priorizationHelp={`Weak points to improve based upon startup stage\nImprove it or construct a credible story to temper the perception`}
            now={priorization.now}
            next={priorization.next}
            future={priorization.future}
            hideImprove={true}
          />
        </Col>
      </Row>
    </>
  );
};

export default MainView;
