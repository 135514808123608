import React from 'react';
import FileItem from './FileItem';

interface FileListProps {
    files: Array<any>,
    emptyString?: string,
    type: 'best-practice'|'startup-file'

}

const FileList = (props: FileListProps) => {
    // Props
    const { files, emptyString,type } = props;

    return (
        <div className="file-list" >
            {/* Loading */}
            {!files &&
                <div className="file-item-empty">
                    <span>Loading...</span>
                </div>
            }
            {/* Has Files */}
            {files && files.length > 0 && files.map((file, index) => (
                <FileItem file={file} key={index} type={type} />
            ))}
            {/* Empty */}
            {files && files.length === 0 &&
                <div className="file-item-empty">
                    <span>{emptyString ? emptyString : 'No Files'}</span>
                </div>
            }
        </div>
    );
};
export default FileList;