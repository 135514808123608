import React from "react";
// Route
import { Route } from "react-router-dom";
// Utils
import { getTokenGuest, removeTokenGuest } from "../../utils";
/**
 * Public route resolve
 */
const GuestRoute = ({ component: Component, restricted, ...rest }) => {
  // Check if logged in
  const tokenGuest = getTokenGuest();
  // Check if user logged in is admin to redirect admin dashboard
  if (tokenGuest) {
    removeTokenGuest();
  }
  return (
    // restricted = false meaning public route
    // restricted = true meaning restricted route
    <Route {...rest} render={(props) => <Component {...props} />} />
  );
};

export default GuestRoute;
