import React from "react";
// Boostrap
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import DateHelper from "../../helpers/dates/date.helper";
// Table
import DefaultTable, { ColumnType } from "../table/DefaultTable";

// Props
interface MasterSessionHistoryProps {
    history: Array<any>,
    loading: boolean,
    emptyString?: string,
    onClickViewItem?: Function,
    onClickDeleteItem?: Function
}
// Reports Table
const MasterSessionHistory = (props: MasterSessionHistoryProps) => {
    const dateHelper = new DateHelper();
    // Props
    const { history, loading, onClickViewItem, onClickDeleteItem, emptyString } = props;
    // On Click View
    const onClickView = (history) => {
        if (onClickViewItem) {
            onClickViewItem(history);
        }
    }
    const onClickDelete = (history) => {
        if (onClickDeleteItem) {
            onClickDeleteItem(history);
        }
    }
    // Action Formatter
    const actionsFormatter = (cell, row) => {
        return (
            <div className="actions-table-wrapper" key={cell + '-table-actions'}>
                <OverlayTrigger
                    key={cell + '-view'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-edit`}>
                            View Document
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-view'}
                        className="btn btn-outline-primary btn-rounded-action action-table"
                        onClick={() => onClickView(row)}
                    >
                        <i className="far fa-eye"></i>
                    </button>

                </OverlayTrigger>
                <OverlayTrigger
                    key={cell + '-delete'}
                    placement="top"
                    overlay={
                        <Tooltip id={`tooltip-${cell}-delete`}>
                            Delete Master Session
                        </Tooltip>
                    }>
                    <button
                        type="button"
                        id={cell + '-dropdown-delete'}
                        className="btn btn-outline-danger btn-rounded-action action-table"
                        onClick={() => onClickDelete(row)}
                    >
                        <i className="far fa-trash-alt"></i>
                    </button>

                </OverlayTrigger>
            </div>
        )
    };

    const columns: Array<ColumnType> = [
        {
            dataField: 'id',
            text: 'ID',
            hidden: true
        },
        {

            dataField: 'topic',
            text: 'Topic',
            sort: true,
        },
        {

            dataField: 'description',
            text: 'Description',
            sort: true,
        },
        {

            dataField: 'document.name',
            text: 'Document',
            sort: true,
        },
        {

            dataField: 'number_of_slides',
            text: 'Slides #',
            sort: true,
        },
        {

            dataField: 'reading_time',
            text: 'Reading Time',
            sort: true,
        },
        {

            dataField: 'created_at',
            text: 'Created',
            sort: true,
            formatter: cell => dateHelper.format(cell),
            sortValue: cell => dateHelper.sortValue(cell)
        },
        {
            dataField: 'id',
            text: 'Actions',
            formatter: actionsFormatter,
        }
    ];


    return (
        <>
            <DefaultTable
                data={history}
                loading={loading}
                columns={columns}
                emptyString={emptyString}
                defaultSorted={
                    {
                        dataField: 'created_at',
                        order: 'desc'
                    }
                }
            />
        </>
    )
}

export default MasterSessionHistory;