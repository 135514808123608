import gql from 'graphql-tag'
import { BEST_PRACTICE_FIELDS } from './best-practice.fragment'

// List
export const LIST_BEST_PRACTICE = gql`
${BEST_PRACTICE_FIELDS}
query listBestPractice{            
    listBestPractice{      
        ...BestPracticeFields    
        type{
            title
            category_sku
            category{
                title
            }
        }
    }
}`
export const FIND_BEST_BEST_PRACTICE_BY_TYPE_SKU = gql`
${BEST_PRACTICE_FIELDS}
query findBestPracticeByTypeSku($type_sku: String!){            
    findBestPracticeByTypeSku(type_sku:$type_sku){      
        ...BestPracticeFields    
        type{
            title
            category_sku
            category{
                title
            }
        }
    }
}`
// Get by ID
export const GET_BEST_PRACTICE_BY_ID = gql`
${BEST_PRACTICE_FIELDS}
query getBestPracticeById($id: String!){            
    getBestPracticeById(id: $id){      
        ...BestPracticeFields    
    }
}`
// Create
export const CREATE_BEST_PRACTICE = gql`
${BEST_PRACTICE_FIELDS}
mutation createBestPractice($data: BestPracticeInput!){            
    createBestPractice(data: $data){
        ...BestPracticeFields             
    }
}`
// Update
export const UPDATE_BEST_PRACTICE = gql`
${BEST_PRACTICE_FIELDS}
mutation updateBestPractice($data: BestPracticeInput!){            
    updateBestPractice(data: $data){
        ...BestPracticeFields             
    }
}`

// Delete
export const DELETE_BEST_PRACTICE = gql`
${BEST_PRACTICE_FIELDS}
mutation deleteBestPractice($id: String!){            
    deleteBestPractice(id: $id){
        ...BestPracticeFields             
    }
}`

// Delete File
export const DELETE_BEST_PRACTICE_FILE = gql`
mutation deleteBestPracticeFile($id: String!){            
    deleteBestPracticeFile(id: $id)    
}`
