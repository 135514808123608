/*eslint-disable*/
import React, { useState, useEffect, useCallback, useRef } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Router
import { useHistory, useParams } from "react-router-dom";
// Boxs
import BoxFundabilitySubElement from "../../components/dashboard/BoxFundabilitySubElement";
import BoxStuckBanner from "../../components/dashboard/BoxStuckBanner";
import BoxAdvices from "../../components/dashboard/BoxAdvices";
import BoxMyChallenges from "../../components/dashboard/BoxMyChallenges";
// Modal
import ModalUpdateFirstScoring from "../../components/answer/ModalUpdateFirstScoring";
import ModalUpdateRefinedScoring from "../../components/answer/ModalUpdateRefinedScoring";
// Constants
import { AuthStates, DEFAULT_MAIN_ELEMENT_FUNDING } from "../../constants/startup/startup.constants";
// Apollo
import { useApolloClient } from "react-apollo";
import { GET_STARTUP_DASHBOARD, GET_STARTUP_DASHBOARD_EXTERNAL, GET_STARTUP_DASHBOARD_INTERNAL } from "../../queries/startup/startups.query";
import { FIND_BEST_BEST_PRACTICE_BY_TYPE_SKU } from "../../queries/best-practice/best-practice.query";
import { GET_QUESTION_BY_TYPE_SKU } from "../../queries/questions/questions.query";
import { GET_LAST_ANSWER_DASHBOARD } from "../../queries/answer/answers.query";
import { LIST_CHALLENGES } from "../../queries/challenges/challenge.query";
import { LIST_ADVICES_BY_TYPE_SKU } from "../../queries/advices/advice.query";
// Toast
import { toast } from "react-toastify";
// Helpers
import StartupHelper from "../../helpers/startup/startup.helper";
// Redux
import { useDispatch, useSelector } from "react-redux";
import {
  getDashboardDataExternal,
  getDashboardDataInternal,
  setDashboardData,
  setDashboardDataExternal,
  setDashboardDataInternal,
  setSubElement as setSubElementRedux,
} from "../../redux/ducks/dashboard-duck";
import {
  getChallenge,
  challengeCreate,
} from "../../redux/ducks/challenge-duck";
import { answerFetchLastAnswer, answerFetchQuestion } from "../../redux/ducks/answer-duck";
import { getDashboardData } from "../../redux/ducks/dashboard-duck";
// Hooks
import useWindowSize from "../../hooks/useWindowResize";
import { LIST_TRACKS_BY_TYPE_SKU } from "../../queries/track/track.query";
import BoxBestPractices from "../../components/dashboard/BoxBestPractices";
// Hooks
import { useUserAvailableSubElement } from "../../hooks/useUserAvailableSubElement";
import { getUser } from "../../redux/ducks/user-duck";
const MainSubElementView = (props) => {
  // History
  const history = useHistory();
  // Auth User
  const user = useSelector(getUser);
  // Dashboard Data without external and internal
  const dashboardData = useSelector(getDashboardData);
  // Internal
  const dashboardDataInternal = useSelector(getDashboardDataInternal);
  // External
  const dashboardDataExternal = useSelector(getDashboardDataExternal);
  // Selector
  const onUpdateCreateChallenge = useSelector(getChallenge);
  // Dispatch
  const dispatch = useDispatch();
  // Client
  const client = useApolloClient();
  // Helper
  const startupHelper = new StartupHelper();
  // Params
  const { sub_element } = useParams<any>();
  // Available
  const isAvailable = useUserAvailableSubElement(sub_element);
  // Main elemt
  const [subElement, setSubElement] = useState<any>("");
  const [subElementObject, setSubElementObject] = useState<any>(null);
  const [subElementDescription, setSubElementDescription] = useState<any>(``);
  const [hasFirstChosen, setHasFirstChosen] = useState<any>(false);
  const [hasRefinedChosen, setHasRefinedChosen] = useState<any>(false);
  // Sub Element External
  const [subElementExternal, setSubElementExternal] = useState<any>({
    value: null,
    hasFoundersExternal: false
  });
  // Sub Element Internal
  const [subElementInternal, setSubElementInternal] = useState<any>({
    value: null,
    hasFoundersInternal: false
  });
  // Fundability
  const [fundability, setFundability] = useState<any>();
  const [fundabilityInternal, setFundabilityInternal] = useState<any>();
  const [fundabilityExternal, setFundabilityExternal] = useState<any>();
  // Files
  const [bestPractices, setBestPractices] = useState<any>(null);
  // Files
  const [advices, setAdvices] = useState<any>(null);
  // Tracks
  const [tracks, setTracks] = useState<any>(null);
  // My Challenges
  const [myChallenges, setMyChallenges] = useState<any>(null);
  // Loading
  const [loading, setLoading] = useState(false);
  // Fetch Dashboard Data
  const fetchDashboardData = useCallback(async () => {
    const response = await client.query({
      query: GET_STARTUP_DASHBOARD,
      fetchPolicy: "no-cache",
    });
    // Startup
    const startup = response.data.getStartupDashboard || {};
    dispatch(setDashboardData(startup));
    // Internal
    const responseInternal = await client.query({
      query: GET_STARTUP_DASHBOARD_INTERNAL,
      fetchPolicy: "no-cache",
    });
    const startupInternal = responseInternal.data.getStartupDashboard || {};
    dispatch(setDashboardDataInternal(startupInternal));
    // External
    const responseExternal = await client.query({
      query: GET_STARTUP_DASHBOARD_EXTERNAL,
      fetchPolicy: "no-cache",
    });
    const startupExternal = responseExternal.data.getStartupDashboard || {};
    dispatch(setDashboardDataExternal(startupExternal));
  }, [client, dispatch]);
  // Fetch Question Data
  const fetchQuestionData = useCallback(async () => {
    const questionResponse = await client.query({
      query: GET_QUESTION_BY_TYPE_SKU,
      variables: { type_sku: sub_element },
      fetchPolicy: "no-cache",
    });
    const question = questionResponse.data.getQuestionByTypeSku || {};
    dispatch(answerFetchQuestion(question));
  }, [sub_element, dispatch]);
  // Fetch Answer Data
  const fetchLastAnswerData = useCallback(async () => {
    // Get Last Answer
    const lastAnswerResponse = await client.query({
      query: GET_LAST_ANSWER_DASHBOARD,
      fetchPolicy: "no-cache",
    });
    const lastAnswer = lastAnswerResponse.data.getLastAnswerDashboard || {};
    dispatch(answerFetchLastAnswer(lastAnswer));
  }, [sub_element, dispatch]);
  // Fectch Fundability
  const fetchFundability = useCallback(
    (
    ) => {
      // console.log('subElement', subElement)
      let scoring = 0;
      setHasFirstChosen(subElementObject.hasFirstChosen)
      if (subElementObject.hasRefinedChosen) {
        scoring = subElementObject.refinedScoring;
        setHasRefinedChosen(true);
      } else {
        scoring = subElementObject.firstScoring;
        setHasRefinedChosen(false);
      }
      const cloned = startupHelper.resolveFundabilityStatusElement(
        scoring,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      setFundability([...cloned]);

    },
    [
      subElementObject,
      setFundability,
      setHasFirstChosen,
      setHasRefinedChosen,
      DEFAULT_MAIN_ELEMENT_FUNDING,
    ]
  );
  // Fectch Fundability
  const fetchFundabilityInternal = useCallback(
    (
    ) => {

      // Set Fundability Internal
      let scoringInternal = 0;
      if (subElementInternal.hasFoundersInternal) {
        scoringInternal = subElementInternal.value.scoring;
      }
      const clonedInternal = startupHelper.resolveFundabilityStatusElement(
        scoringInternal,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      setFundabilityInternal([...clonedInternal]);
    },
    [
      setFundabilityInternal,
      subElementInternal,
      DEFAULT_MAIN_ELEMENT_FUNDING,
    ]
  );

  // Fectch Fundability External
  const fetchFundabilityExternal = useCallback(
    (

    ) => {

      // Set Fundability External
      let scoringExternal = 0;
      if (subElementExternal.hasFoundersExternal) {
        scoringExternal = subElementExternal.value.scoring;
      }
      const clonedExternal = startupHelper.resolveFundabilityStatusElement(
        scoringExternal,
        DEFAULT_MAIN_ELEMENT_FUNDING
      );
      console.log('fetchFundabilityExternal')
      console.log('clonedExternal', clonedExternal)
      setFundabilityExternal([...clonedExternal]);
    },
    [
      subElementExternal,
      setFundabilityExternal,
      DEFAULT_MAIN_ELEMENT_FUNDING,
    ]
  );
  // Fetch Challenges
  const fetchChallenges = useCallback(async () => {
    // Challenge
    const responseChallenges = await client.query({
      query: LIST_CHALLENGES,
      fetchPolicy: "no-cache",
    });
    const myChallenges = responseChallenges.data.listChallenges || [];
    // My Challenges
    setMyChallenges(myChallenges.filter((c) => c.type_sku === sub_element));
  }, [setMyChallenges, sub_element]);
  // Fetch Suggestions
  const fetchChallengesSuggestions = useCallback(async () => {
    // Challenge
    const responseChallenges = await client.query({
      query: LIST_TRACKS_BY_TYPE_SKU,
      variables: { type_sku: sub_element },
      fetchPolicy: "no-cache",
    });
    const tracks = responseChallenges.data.listTracksByTypeSku || [];
    const tracksFilter = tracks.filter(t => t.challenge_templates.length > 0)
    // Suggestions
    setTracks(tracksFilter);
  }, [sub_element, setTracks]);
  // Fetch Best Practices Documents
  const fetchBestPracticeDocuments = useCallback(async () => {
    // Suggestions
    const bestPracticeResponse = await client.query({
      query: FIND_BEST_BEST_PRACTICE_BY_TYPE_SKU,
      variables: { type_sku: sub_element },
      fetchPolicy: "no-cache",
    });
    const bestPractices =
      bestPracticeResponse.data.findBestPracticeByTypeSku || [];
    // Files
    setBestPractices(startupHelper.mapFiles(bestPractices));
  }, [sub_element, setBestPractices]);
  // Fetch Advice
  const fetchAdvices = useCallback(async () => {
    // Suggestions
    const advicesResponse = await client.query({
      query: LIST_ADVICES_BY_TYPE_SKU,
      variables: { type_sku: sub_element },
      fetchPolicy: "no-cache",
    });
    const advices = advicesResponse.data.listAdvicesByTypeSku || [];
    // Files
    setAdvices(advices);
  }, [sub_element, setAdvices]);
  // Reset data Challenges
  const resetDataChallenges = useCallback(() => {
    setTracks(null);
    setMyChallenges(null);
  }, [setTracks, setMyChallenges]);
  // Fetch Fundability when change Sub Element
  useEffect(() => {
    // Fundabilty
    if (subElementObject) {
      // console.log('fetchFundability', subElementObject)
      fetchFundability();
    }

  }, [subElementObject])
  // Fetch Data
  const fetchData = useCallback(async () => {
    try {
      // Set Current Sub Element
      dispatch(setSubElementRedux(sub_element));
      // Startup
      const startup = dashboardData;
      // Totals
      const totals = startup.totals;
      // Types
      const types = totals.types;
      // Sub Element
      const subElement = types.find((c) => c.type_sku === sub_element);
      if (user.startup_type === 'limited') {
        let newTitle = startupHelper.getSubElementTitleToLimited(subElement.type_title, sub_element);
        subElement.type_title = newTitle;
      }
      setSubElementObject(subElement);
      setSubElement(subElement?.type_title);
      setSubElementDescription(subElement?.type_description);

    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      setLoading(false);
    }
  }, [
    sub_element,
    user,
    dashboardData,
    setSubElement,
    setSubElementDescription,
    fetchFundability,
  ]);
  // Fetch Dashboard data
  useEffect(() => {
    if (sub_element && dashboardData && dashboardData.id && isAvailable.includes(AuthStates.AVAILABLE)) {
      fetchData();
    }
  }, [sub_element, dashboardData, isAvailable]);
  // Fetch Fundability Internal
  useEffect(() => {
    if (subElementInternal.value) {
      fetchFundabilityInternal();
    }
  }, [subElementInternal])
  // Fetch Data Internal 
  const fetchDataInternal = useCallback(() => {
    try {
      // Startup
      const startup = dashboardDataInternal;
      // Internal Total
      const totals_internal = startup.totals_internal;
      const hasFoundersInternal = totals_internal.has_founders;
      const typesInternal = totals_internal.total_types;
      const subElementInternal = typesInternal.find(
        (c) => c.type_sku === sub_element
      );
      setSubElementInternal({ value: subElementInternal, hasFoundersInternal })
      // fetchFundabilityInternal(subElementInternal, hasFoundersInternal);
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      // setLoading(false);
    }
  }, [dashboardDataInternal, fetchFundabilityInternal, setSubElementInternal])
  useEffect(() => {
    if (dashboardDataInternal && dashboardDataInternal.id) {
      fetchDataInternal();
    }
  }, [dashboardDataInternal, subElement]);
  // Fetch Fundability External
  useEffect(() => {
    if (subElementExternal.value) {
      fetchFundabilityExternal();
    }
  }, [subElementExternal])
  // Fetch Data External
  const fetchDataExternal = useCallback(() => {
    try {
      // Startup
      const startup = dashboardDataExternal;
      // External Total

      const totals_external = startup.totals_external;
      const hasFoundersExternal = totals_external.has_founders;
      const typesExternal = totals_external.total_types;
      const subElementExternal = typesExternal.find(
        (c) => c.type_sku === sub_element
      );
      setSubElementExternal({...{ value: subElementExternal, hasFoundersExternal }})
      // fetchFundabilityExternal(subElementExternal, hasFoundersExternal);
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      // setLoading(false);
    }
  }, [dashboardDataExternal, fetchFundabilityExternal])
  useEffect(() => {
    if (dashboardDataExternal && dashboardDataExternal.id) {
      fetchDataExternal();
    }
  }, [dashboardDataExternal, subElement]);
  // Fetch All data what dont need dashboard data
  useEffect(() => {
    if (subElement && isAvailable.includes(AuthStates.AVAILABLE)) {
      resetDataChallenges();
      // My Challenges
      fetchChallenges();
      // Question Data
      fetchQuestionData();
      // Advices
      fetchAdvices();
      // Challenges Suggestions
      fetchChallengesSuggestions();
      // Best Practices
      fetchBestPracticeDocuments();
    }
  }, [subElement, isAvailable]);
  // On add or Update Challenge, refetch dashboard data and fetch new challenges
  useEffect(() => {
    if (onUpdateCreateChallenge && onUpdateCreateChallenge.id) {
      // Fetch Dashboard Data
      fetchDashboardData();
      // Fetch Challenges
      fetchChallenges();
      // Set Null Challenge Selector, to not call change when change route
      dispatch(challengeCreate(null));
    }
  }, [onUpdateCreateChallenge]);
  useEffect(() => {
    if (isAvailable.includes(AuthStates.NOT_AVAILABLE)) {
      toast.error(`You don't have permissions for this section`);
      history.push('/dashboard/main');
    }
  }, [isAvailable])

  // On Change Answer Fetch Dashboard Data
  const onSaveAnswer = (answer) => {
    fetchDashboardData();
    // Question Data
    fetchQuestionData();
    // Last Answer Data
    fetchLastAnswerData();
  };
  //#region Sizes
  // Sizes
  const windowSize = useWindowSize();
  const [columnHeight, setColumnHeight] = useState("auto");
  // Body Ref
  const colFundabilityRef = useRef<HTMLDivElement>(null);
  const changeSizes = useCallback(() => {
    setTimeout(() => {
      if (colFundabilityRef && colFundabilityRef.current) {
        const box = colFundabilityRef.current.querySelector(".box-startup");
        if (box) {
          if (windowSize.width >= 1024) {
            const height = box.clientHeight - (138 + 16);
            setColumnHeight(`${height}px`);
          } else {
            setColumnHeight(`auto`);
          }
        }
      }
    }, 200);
  }, [windowSize, colFundabilityRef, setColumnHeight]);

  // Change
  useEffect(() => {
    if (windowSize.width && colFundabilityRef.current && fundability) {
      changeSizes();
    }
  }, [windowSize, colFundabilityRef, fundability]);
  //#endregion Sizes

  // Render
  return (
    <>
      {/* Update First Scoring */}
      <ModalUpdateFirstScoring onSave={onSaveAnswer} />
      {/* Update Refined Scoring */}
      <ModalUpdateRefinedScoring onSave={onSaveAnswer} />
      {/* First Row */}
      <Row>
        <Col
          md={12}
          className={"col-lg-45 col-xl-43 mb-16"}
          ref={colFundabilityRef}
        >
          <BoxFundabilitySubElement
            subElementDescription={subElementDescription}
            fundability={fundability}
            fundabilityInternal={fundabilityInternal}
            fundabilityExternal={fundabilityExternal}
            subElement={subElement}
            hasFirstChosen={hasFirstChosen}
            hasRefinedChosen={hasRefinedChosen}
          />
        </Col>
        <Col md={12} className={"col-lg-55 col-xl-57"}>
          <Row>
            <Col
              lg={6}
              className={"mb-16"}
              style={{ height: columnHeight, overflow: "hiddenÎ" }}
            >
              <BoxBestPractices
                id="box-files-sub-element"
                files={bestPractices}
                title={"Best Practice Documents"}
              />
            </Col>
            <Col
              lg={6}
              className={"mb-16"}
              style={{ height: columnHeight, overflow: "hiddenÎ" }}
            >
              <BoxAdvices id="box-advices-sub-element" advices={advices} />
            </Col>
            <Col md={12} className={"mb-16"}>
              <BoxStuckBanner />
            </Col>
          </Row>
        </Col>
      </Row>
      {/* Second Row */}
      <Row>
        <Col lg="12">
          <BoxMyChallenges
            challenges={myChallenges}
            tracks={tracks}
            subElement={sub_element}
          />
        </Col>
      </Row>
    </>
  );
};

export default MainSubElementView;
