
import React, { useEffect, useCallback, useState } from "react";
// Bootstrap
import Col from "react-bootstrap/Col";
import Row from "react-bootstrap/Row";
import Form from "react-bootstrap/Form";
// Item
import QuestionItemForm from './item/QuestionItemForm'
// Validation
import { Controller, FormProvider, useForm } from "react-hook-form";
// Form
import ReactSelect from "../../select/ReactSelect";
// Button
import Button from "../../buttons/Button";
// Toastify
import { toast } from 'react-toastify';
// Loading Overlay
import LoadingOverlay from 'react-loading-overlay'
import BounceLoader from 'react-spinners/BounceLoader'
// Apollo
import { useApolloClient } from "react-apollo";
import { UPDATE_QUESTION } from '../../../queries/questions/questions.query';
import { MoonLoader } from "react-spinners";
// Props
interface QuestionEditFormProps {
    question: any,
    questionTypes: any,
    categories: any

}
const QuestionEditForm = (props: QuestionEditFormProps) => {
    const methods = useForm({
        mode: 'onChange'
    });
    // Keys numbers 
    const keysNumbers = [
        'total_refined_pre_seed',
        'total_refined_seed',
        'total_refined_series_a',
        'priorization_pre_seed',
        'priorization_seed',
        'priorization_series_a',

    ]
    // Client
    const client = useApolloClient();
    // Form
    const { errors, register } = methods;
    // Question
    const [categoryQuestion, setCategoryQuestion] = useState(null);
    const [typeQuestion, setTypeQuestion] = useState(null);
    // Loading
    const [loading, setLoading] = useState(false);
    // Options
    const [optionsCategories, setOptionCategories] = useState<any>([]);
    const [optionsType, setOptionsType] = useState<any>([]);
    const [optionsTypeFilter, setOptionsTypeFilter] = useState<any>([]);
    // Props
    const {
        question,
        questionTypes,
        categories
    } = props;
    // Type Change
    const onChangeTypeQuestion = useCallback((option) => {
        setTypeQuestion(option);
        if (option) {
            methods.setValue('type_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('type_sku', null, { shouldValidate: true })
        }
    }, [setTypeQuestion, methods.setValue])
    // Category Change, change filter question types
    const onChangeCategoryQuestion = useCallback((option, event) => {
        setCategoryQuestion(option);
        if (option) {
            methods.setValue('category_sku', option.value, { shouldValidate: true })
        } else {
            methods.setValue('category_sku', null, { shouldValidate: true })
        }
        if (event.action === 'select-option') {
            let filter = [];
            if (option) {
                filter = optionsType.filter(filter => filter.category_sku === option.value);
            }
            setOptionsTypeFilter(filter);
            onChangeTypeQuestion(null);
        }
    }, [setCategoryQuestion, methods.setValue, optionsType, setOptionsTypeFilter, onChangeTypeQuestion])

    // Fetch Data Cat and Type
    const fecthDataCategoryAndType = useCallback(() => {
        // Set Category and Type
        const questionTypeAux = question.type;
        const filterQuestionType = optionsType.filter(t => t.category_sku === questionTypeAux.category_sku);
        setOptionsTypeFilter(filterQuestionType);
        // Category
        const categoryChosen = optionsCategories.find(cat => cat.value === questionTypeAux.category_sku);
        // Question Type
        const questionTypeChosen = filterQuestionType.find(t => t.value === questionTypeAux.sku);
        // Trigger
        onChangeTypeQuestion(questionTypeChosen)
        onChangeCategoryQuestion(categoryChosen, { action: 'first-time' })
    }, [
        question,
        optionsCategories,
        optionsType,
        setOptionsTypeFilter,
        // Change
        onChangeTypeQuestion,
        onChangeCategoryQuestion
    ])
    // Fetch Data
    const fetchData = useCallback(() => {
        // Map options Categories
        const categoriesMap = categories.map(cat => {
            return {
                label: cat.title,
                value: cat.sku
            }
        })
        setOptionCategories(categoriesMap);
        // Map options Question Types
        const typesMap = questionTypes.map(type => {
            return {
                category_sku: type.category_sku,
                label: type.title,
                value: type.sku
            }
        })
        setOptionsType(typesMap);
        // Question
        methods.setValue('id', question.id);
        methods.setValue('title', question.title);
        methods.setValue('weight_type_sku', question.weight_type_sku);
        // Total
        methods.setValue('total_refined_pre_seed', question.total_refined_pre_seed);
        methods.setValue('total_refined_seed', question.total_refined_seed);
        methods.setValue('total_refined_series_a', question.total_refined_series_a);
        // priorization
        methods.setValue('priorization_pre_seed', question.priorization_pre_seed);
        methods.setValue('priorization_seed', question.priorization_seed);
        methods.setValue('priorization_series_a', question.priorization_series_a);
    }, [
        question,
        // Options type
        setOptionsType,
        questionTypes,
        // Categories
        setOptionCategories,
        categories,
        // Set Value
        methods.setValue
    ])
    // Init Category and Type
    useEffect(() => {
        if (optionsCategories && optionsType && optionsType.length > 0 && optionsCategories.length > 0) {
            console.log('fetch data cat');
            fecthDataCategoryAndType()
        }
    }, [optionsCategories, optionsType, fecthDataCategoryAndType])
    // Init
    useEffect(() => {
        if (question && question.id) {
            console.log('fetch data');
            fetchData()
        }
    }, [question, fetchData])
    // Update Question
    const onClickUpdate = () => {
        const keys = Object.keys(errors);
        if (keys.length > 0) {
            toast.error(`You must complete required fields`);

        } else {
            updateQuestion()
        }
    }
    const updateQuestion = async () => {
        try {
            setLoading(true)
            const question = methods.getValues();
            // Remove unecessary
            delete question.category_sku;
            // String to Int
            for (let item of question.itemsFirstScoring) {
                item.order = parseInt(item.order);
                item.weight = parseInt(item.weight);
            }
            for (let item of question.itemsRefinedScoring) {
                item.order = parseInt(item.order);
                item.weight = parseInt(item.weight);
            }
            // String to number question
            for (let key of keysNumbers) {
                question[key] = parseInt(question[key]);
            }
            console.log('formatted question', question)
            const resp = await client.mutate({
                mutation: UPDATE_QUESTION,
                variables: { data: question }
            })
            const updated = resp.data.updateQuestion || {};
            console.log(updated);
            if (updated.id) {
                toast.success(`Question successfully updated`);
            } else {
                toast.error(`Oops...An error occurred. Try again later`);
            }
        } catch (e) {
            console.log('e', e)
            toast.error(`Oops...An error occurred. Try again later`);

        } finally {
            setLoading(false)
        }


    }

    return (
        <LoadingOverlay active={loading}
            text={'Update question...'}
            spinner={
                <div className="_loading_overlay_spinner">
                    <BounceLoader color={"#2662f0"} />
                </div>}>
            <FormProvider {...methods}>
                <Form noValidate name={'edit-question'}>
                    <Row >
                        <Form.Control name="id" type="hidden" ref={register({
                            required: 'Question id required',
                        })} />
                        <Col lg="9">
                            <Form.Group controlId="questionTitle">
                                <Form.Label>Question</Form.Label>
                                <Form.Control
                                    name="title"
                                    type="text"
                                    placeholder="Enter a question"
                                    isInvalid={errors.title}
                                    as={'textarea'}
                                    rows={3}
                                    ref={register({
                                        required: 'Question is required',
                                    })}
                                />
                                {errors.title &&
                                    <div className="invalid-feedback"> {errors.title.message} </div>}
                            </Form.Group>
                        </Col>
                    </Row>
                    {/* Excitement confident */}
                    <Row>
                        <Col lg="9">
                            <Form.Group controlId="excitementConfidence">
                                <Form.Check
                                    custom
                                    inline
                                    value={'excitement'}
                                    name="weight_type_sku"
                                    label="Excitement"
                                    type={'radio'}
                                    id={`inline-excitement`}
                                    ref={register}
                                />
                                <Form.Check
                                    custom
                                    inline
                                    value={'confidence'}
                                    name="weight_type_sku"
                                    label="Confidence"
                                    type={'radio'}
                                    id={`inline-confidence`}
                                    ref={register}
                                />

                            </Form.Group >
                        </Col>
                    </Row>
                    {/* Divider */}
                    <Row>
                        <Col lg="9">
                            <hr />
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="9">
                            <Row>
                                {/* Main element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${errors.init_date ? 'is-invalid' : ''}`}>
                                        <Form.Label>Main element</Form.Label>
                                        <Controller
                                            name="category_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Main element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a main-element"
                                                    isClearable={true}
                                                    isInvalid={errors.category_sku}
                                                    options={optionsCategories}
                                                    onChange={onChangeCategoryQuestion}
                                                    value={categoryQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {errors.category_sku && <div className="invalid-feedback"> {errors.category_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                                {/* Sub element */}
                                <Col lg="6">
                                    <Form.Group controlId="formGroupKeyProperty" className={`${errors.init_date ? 'is-invalid' : ''}`}>
                                        <Form.Label>Sub element</Form.Label>
                                        <Controller
                                            name="type_sku"
                                            control={methods.control}
                                            defaultValue={false}
                                            rules={{ required: 'Sub element is required' }}
                                            default
                                            render={props =>
                                                <ReactSelect
                                                    placeholder="Select a sub-element"
                                                    isClearable={true}
                                                    isInvalid={errors.type_sku}
                                                    options={optionsTypeFilter}
                                                    onChange={onChangeTypeQuestion}
                                                    value={typeQuestion}
                                                />
                                            } // props contains: onChange, onBlur and value
                                        />

                                        {errors.type_sku && <div className="invalid-feedback"> {errors.type_sku.message} </div>}

                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Totals */}
                    <Row>
                        <Col lg="9">
                            <hr />
                            <h3>Total</h3>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="9">
                            <Row>
                                <Col lg="4">
                                    <Form.Group controlId="totalPreSeed">
                                        <Form.Label>TOTAL Refined Pre Seed </Form.Label>
                                        <Form.Control
                                            name="total_refined_pre_seed"
                                            type="number"
                                            placeholder="Enter a number"
                                            isInvalid={errors.total_refined_pre_seed}
                                            ref={register({
                                                required: 'Total refined pre seed is required',
                                                min: {
                                                    value: 0,
                                                    message: 'The value must be greater than or equal to 0'
                                                }
                                            })}
                                        />
                                        {errors.total_refined_pre_seed &&
                                            <div className="invalid-feedback"> {errors.total_refined_pre_seed.message} </div>}
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group controlId="totalPreSeed">
                                        <Form.Label>TOTAL Refined Seed </Form.Label>
                                        <Form.Control
                                            name="total_refined_seed"
                                            type="number"
                                            placeholder="Enter a number"
                                            isInvalid={errors.total_refined_seed}
                                            ref={register({
                                                required: 'Total refined seed is required',
                                                min: {
                                                    value: 0,
                                                    message: 'The value must be greater than or equal to 0'
                                                }
                                            })}
                                        />
                                        {errors.total_refined_seed &&
                                            <div className="invalid-feedback"> {errors.total_refined_seed.message} </div>}
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group controlId="totalPreSeed">
                                        <Form.Label>TOTAL Refined Series A </Form.Label>
                                        <Form.Control
                                            name="total_refined_series_a"
                                            type="number"
                                            placeholder="Enter a number"
                                            isInvalid={errors.total_refined_series_a}
                                            ref={register({
                                                required: 'Total refined series a is required',
                                                min: {
                                                    value: 0,
                                                    message: 'The value must be greater than or equal to 0'
                                                }
                                            })}
                                        />
                                        {errors.total_refined_series_a &&
                                            <div className="invalid-feedback"> {errors.total_refined_series_a.message} </div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* Priorization */}
                    <Row>
                        <Col lg="9">
                            <hr />
                            <h3>Priorization</h3>
                            <div className="alert alert-primary" role="alert">
                                Zero value is a item without priorization
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col lg="9">
                            <Row>
                                <Col lg="4">
                                    <Form.Group controlId="totalPreSeed">
                                        <Form.Label>Pre Seed </Form.Label>
                                        <Form.Control
                                            name="priorization_pre_seed"
                                            type="number"
                                            placeholder="Enter a number"
                                            isInvalid={errors.priorization_pre_seed}
                                            ref={register({
                                                required: 'The priorization pre seed is required',
                                                min: {
                                                    value: 0,
                                                    message: 'The value must be greater than or equal to 0'
                                                }
                                            })}
                                        />
                                        {errors.priorization_pre_seed &&
                                            <div className="invalid-feedback"> {errors.priorization_pre_seed.message} </div>}
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group controlId="totalPreSeed">
                                        <Form.Label>Seed </Form.Label>
                                        <Form.Control
                                            name="priorization_seed"
                                            type="number"
                                            placeholder="Enter a number"
                                            isInvalid={errors.priorization_seed}
                                            ref={register({
                                                required: 'The priorization seed is required',
                                                min: {
                                                    value: 0,
                                                    message: 'The value must be greater than or equal to 0'
                                                }
                                            })}
                                        />
                                        {errors.priorization_seed &&
                                            <div className="invalid-feedback"> {errors.priorization_seed.message} </div>}
                                    </Form.Group>
                                </Col>
                                <Col lg="4">
                                    <Form.Group controlId="totalPreSeed">
                                        <Form.Label>Series A</Form.Label>
                                        <Form.Control
                                            name="priorization_series_a"
                                            type="number"
                                            placeholder="Enter a number"
                                            isInvalid={errors.priorization_series_a}
                                            ref={register({
                                                required: 'The priorization series a is required',
                                                min: {
                                                    value: 0,
                                                    message: 'The value must be greater than or equal to 0'
                                                }
                                            })}
                                        />
                                        {errors.priorization_series_a &&
                                            <div className="invalid-feedback"> {errors.priorization_series_a.message} </div>}
                                    </Form.Group>
                                </Col>
                            </Row>
                        </Col>
                    </Row>
                    {/* First Scoring */}
                    <Row>
                        <Col lg="9">
                            <hr />
                            <h3>First Scoring</h3>
                        </Col>
                    </Row>
                    <div className="question-items-list">
                        {question.firstScoring.map((item, index) => {
                            return (
                                <Row key={`first-scoring-${index}`}>
                                    <Col lg="9">
                                        <QuestionItemForm
                                            item={item}
                                            index={index}
                                            prefix={'itemsFirstScoring'}
                                        />
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                    {/* First Scoring */}
                    <Row>
                        <Col lg="9">
                            <hr />
                            <h3>Refined Scoring</h3>
                        </Col>
                    </Row>
                    <div className="question-items-list">
                        {question.refinedScoring.map((item, index) => {
                            return (
                                <Row key={`first-scoring-${index}`}>
                                    <Col lg="9">
                                        <QuestionItemForm
                                            item={item}
                                            index={index}
                                            prefix={'itemsRefinedScoring'}
                                        />
                                    </Col>
                                </Row>
                            )
                        })}
                    </div>
                    <Button variant="success"
                        id="btn-finish-question"
                        onClick={onClickUpdate}
                        disabled={loading}
                        className="btn-fixed fixed-right shadow">
                        {!loading && <i className="fas fa-save icon"></i>}
                        {loading && <MoonLoader color="#8e8e93" size={40} />}
                    </Button>

                </Form>
            </FormProvider>
        </LoadingOverlay>
    )
}

export default QuestionEditForm;