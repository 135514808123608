
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
import SummaryItemFounders from "./SummaryItemFounders";
// Props
interface CategoryItemFoundersProps {
    summary: any
}


const SummaryFounders = (props: CategoryItemFoundersProps) => {
    const [summary, setSummary] = useState<any>(null);
    const fetchData = useCallback(() => {
        setSummary(props.summary)
    }, [setSummary, props.summary])
    useEffect(()=>{
        if(props.summary){
            fetchData()
        }
    },[props.summary])
    // Render
    return (
        <div className="category-item-founders with-line">
            <div className="category-title">
                <span>Summary</span>
            </div>
            <div className={`list-summary-founders `}>
                {summary && summary.map((item, index) => (
                    <SummaryItemFounders item={item} key={index} />
                ))}
            </div>
        </div>

    )
}

export default SummaryFounders;
