import React from 'react';

  

const BoxStartupBody =React.forwardRef((props:any, ref:any)  => {
    const { className } = props;
    return (
        <div className={ `box-startup-body ${className ? className: '' }`} ref={ref}>
            {props.children}
        </div>
    );
})

export default BoxStartupBody;