import React, { useEffect, useCallback, useState } from "react";
// Boostrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Questions
import QuestionBlockTotalsRefinedScoring from "../QuestionBlockTotalsRefinedScoring";
import QuestionBlockRefinedScoring from "./QuestionBlockRefinedScoring";


interface CategoryBlockProps {
    questions: any,
    category: any,
    name: string,
    lastAnswer: any,
    startup: any
}
const CategoryBlockRefinedScoring = (props: CategoryBlockProps) => {
    
    // Props
    const { questions, category, name, lastAnswer, startup } = props;
    
    return (
        <>
            {questions.map((question, index) => {
                const refinedScoring = question.items.filter(i => i.type_sku === 'refined-scoring');
                // const firstScoring = question.items.filter(i => i.type_sku === 'first-scoring');            
                let answerItem = null;
                if(lastAnswer && lastAnswer.answer_items.length > 0){
                    answerItem = lastAnswer.answer_items.find(_answerItem => _answerItem.question_type_sku === question.type_sku);
                }
                return(<QuestionBlockRefinedScoring
                    key={index}
                    question={question}
                    refinedScoring={refinedScoring}
                    answerItem={answerItem}
                    startup={startup}
                    />)
            })}            
        </>
    )
}

export default CategoryBlockRefinedScoring;