
/*eslint-disable*/
import React, { useState } from "react";
// Bootstrap
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import Form from 'react-bootstrap/Form';
// Charts
import BoxStartup from "../../components/box/BoxStartup";
import Button from "../buttons/Button";
import CategoryItemFounders from "../founders/CategoryItemFounders";
// Forms
import { useForm } from "react-hook-form";
// Apollo
import { useApolloClient } from "react-apollo";
import { CREATE_GUEST, DELETE_ALL_GUEST_PER_TYPE } from "../../queries/guests/guests.query";
// Toast
import { toast } from "react-toastify";
// Redux
import { useDispatch } from 'react-redux';
// Summary
import SummaryFounders from "../founders/SummaryFounders";
import { showModalListGuests } from "../../redux/ducks/guest-duck";
// SweeAlert
import Swal from '../sweet-altert/sweet-alert';
// Props
interface BoxFoundersProps {
    categories: any,
    summary: any,
    loadGuests: Function
    canInvite: boolean;
}
interface FormGuest {
    email: string;
    first_name: string;
}

const BoxFounders = (props: BoxFoundersProps) => {
    // Client
    const client = useApolloClient()
    // Dispatch
    const dispatch = useDispatch();
    // Props
    const { categories, summary, loadGuests, canInvite } = props;
    // Loading
    const [loading, setLoading] = useState(false)
    const [loadingResetRatings, setLoadingResetRatings] = useState(false)
    // Form
    const { register, handleSubmit, errors, setError, reset } = useForm<FormGuest>({
        mode: 'onSubmit',
        reValidateMode: 'onChange',
        defaultValues: {}
    });

    const inviteFn = async (data) => {
        setLoading(true);
        try {
            const guestInput = {
                ...data,
                type: 'guest_internal'
            }

            const userResponse = await client.mutate({
                mutation: CREATE_GUEST,
                variables: { data: guestInput },
                fetchPolicy: 'no-cache'
            })
            const guest = userResponse.data.createGuest || {}
            if (guest.id) {
                toast.success('Invitation sent succefully')
                reset();
            } else {
                toast.error('Oops...An error occurred. Try again later')
            }

        } catch (e: any) {
            const error = (e.graphQLErrors) ? e.graphQLErrors[0].message : undefined;
            if (error && error.includes('already exist')) {
                setError('email', {
                    type: 'exist',
                    message: 'This email has already been invited ',
                    shouldFocus: true,
                });

                toast.error('This email has already been invited')
            } else {
                toast.error('Oops...An error occurred. Try again later')
            }
        } finally {
            setLoading(false);
            loadGuests()
        }

    }
    // List Participants
    const onClickListParticipants = () => {
        dispatch(showModalListGuests('guest-internal'))
    }

    // Reset Ratings
    const onClickResetRatings = async () => {
        Swal.fire({
            title: `<span>Are you sure to reset ratings?</span>`,
            html: `<span>All invitations will be deleted</span>`,
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, reset!',
            cancelButtonText: 'No, keep it',
        }).then(async (result) => {
            if (result.value) {
                resetRatingsFn();
            }
            // else if (result.dismiss === Swal.DismissReason.cancel) {

            // }
        })
    }
    const resetRatingsFn = async () => {
        setLoadingResetRatings(true);
        try {

            const response = await client.mutate({
                mutation: DELETE_ALL_GUEST_PER_TYPE,
                variables: { type: 'guest_internal' },
                fetchPolicy: 'no-cache'
            })
            const deleted = response.data.deleteAllGuestPerType || {}
            if (deleted) {
                toast.success('Reset ratings succefully')
                reset();
            } else {
                toast.error('Oops...An error occurred. Try again later')
            }

        } catch (e) {
            console.log('error', e);
            toast.error('Oops...An error occurred. Try again later')
        } finally {
            setLoadingResetRatings(false);
            loadGuests()
        }
    }
    // Render
    return (
        <BoxStartup className={''} id="box-founders">
            <BoxStartup.Header>
                {/* Invite */}
                <div className="box-founders-invite-header">
                    <Form onSubmit={handleSubmit(inviteFn)} noValidate>
                        <Form.Row>
                            <Col className="mb-4 mb-lg-0 column-title align-items-lg-center d-lg-flex">
                                <span className="title">Co-Founders</span>
                            </Col>
                            <Col className="column-input mb-4 mb-lg-0">
                                <Form.Control
                                    name="first_name"
                                    placeholder="Enter name..."
                                    isInvalid={errors.first_name ? true : false}
                                    maxLength={150}
                                    disabled={!canInvite}
                                    ref={register({
                                        required: 'First Name is required'
                                    })}
                                />
                                {errors.first_name &&
                                    <div className="invalid-feedback"> {errors.first_name.message} </div>}
                            </Col>
                            <Col className="column-input mb-4 mb-lg-0">
                                <Form.Control
                                    name="email"
                                    type="email"
                                    disabled={!canInvite}
                                    isInvalid={errors.email ? true : false}
                                    ref={register({
                                        required: 'E-mail is required',
                                        pattern: {
                                            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                                            message: "Invalid email address"
                                        }
                                    })}
                                    placeholder="Enter email..." />
                                {errors.email &&
                                    <div className="invalid-feedback"> {errors.email.message} </div>}

                            </Col>
                            <Col className="column-btn mb-4 mb-lg-0">
                                <Button variant="" className={'btn-violet'} type="submit" disabled={loading || !canInvite}>Invite</Button>
                            </Col>
                        </Form.Row>
                    </Form>
                </div>
            </BoxStartup.Header>
            <BoxStartup.Body>

                {/* Actions */}
                <div className="box-founders-actions">
                    <Row>
                        <Col className="column-description">
                            <p className="text-description"> Invite co-founders, key employees  to analyze alignment.<br />Visualize results below or by checking “Internal” underneath each speedometer.</p>
                        </Col>
                        <Col className="column-actions d-flex justify-content-lg-end">
                            <div>
                                <Button disabled={!canInvite} variant="" className={'btn-light-violet'} onClick={onClickListParticipants}>List of participants</Button>
                                <Button  variant="" className={'btn-poor'} onClick={onClickResetRatings} disabled={loadingResetRatings || !canInvite}>Reset Ratings</Button>
                            </div>
                        </Col>
                    </Row>
                </div>
                {/* Categories Average */}
                <div className="list-category-founders">
                    <Row>
                        {/* Categories */}

                        {categories && categories.map((category, index) => (
                            <Col lg={3} key={index} className="mb-16">
                                <CategoryItemFounders category={category} />
                            </Col>
                        ))}

                        {/* Summary */}
                        <Col lg={3} className="mb-16">
                            <SummaryFounders summary={summary} />
                        </Col>
                    </Row>
                </div>
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxFounders;
