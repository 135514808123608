
/*eslint-disable*/
import React from "react";
import BoxStartup from "../box/BoxStartup";
import MostOptimistItem from "../most-optimist/MostOptimistItem";
// Props
interface BoxMostOptimistProps {
    mostOptimist: any,
}
const BoxMostOptimist = (props: BoxMostOptimistProps) => {
    const { mostOptimist } = props;
    // Render
    return (
        <BoxStartup className={''} id="box-most-optimist">
            <BoxStartup.Header>
                <span className="title">Most Optimist Founder per Element</span>
            </BoxStartup.Header>
            <BoxStartup.Body>
                {!mostOptimist && <div className="most-optimist-item-empty">Loading...</div>}
                {mostOptimist && mostOptimist.length === 0 && <div className="most-optimist-item-empty">No Data</div>}
                {mostOptimist && mostOptimist.length > 0 &&
                    mostOptimist.map((item, index) => (
                        <MostOptimistItem item={item} key={index} />
                    ))
                }
            </BoxStartup.Body>
        </BoxStartup >

    )
}

export default BoxMostOptimist;

