/*eslint-disable*/
import React, { useState, useEffect, useCallback } from "react";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
// Router
// Boxs
import BoxFoundersExternal from "../../components/dashboard/BoxFoundersExternal";
// Apollo
import { useApolloClient } from "react-apollo";
import { LIST_GUEST_BY_TYPE } from "../../queries/guests/guests.query";
import { LIST_GUEST_GROUP, LIST_GUEST_GROUP_INVITATIONS } from "../../queries/guest-group/guest-group.query";
// Toast
import { toast } from "react-toastify";
// Modal
import ModalListGuests from "../../components/guests/ModalListGuests";
// Redux
import { useSelector } from "react-redux";
import { getCategories } from "../../redux/ducks/dashboard-duck";


// Founders Alignment
const ExternalRatingView = (props) => {
  // Selectors
  const categoriesData = useSelector(getCategories);
  // Client
  const client = useApolloClient();
  // Categories
  const [categories, setCategories] = useState(null);
  // Guests
  const [guests, setGuests] = useState([]);
  const [groupAdminInivtations, setGroupAdminInivtations] = useState([]);
  const [guestGroups, setGuestGroups] = useState([]);
  // Loading
  const [loading, setLoading] = useState(false);
  // Fetch Guests External
  const fetchGuests = useCallback(async () => {
    // Guest external
    const guestResponse = await client.query({
      query: LIST_GUEST_BY_TYPE,
      variables: { type: "guest_external" },
      fetchPolicy: "no-cache",
    });
    // Guest List
    const guestsList = guestResponse.data.listGuestByType || [];
    // Anonymous Invitations
    const guestGroupResponse = await client.query({
      query: LIST_GUEST_GROUP_INVITATIONS,
      variables: { admin_created: true },
      fetchPolicy: "no-cache",
    });
    const groupsAdmin = guestGroupResponse.data.listGuestGroup || [];
    // Only with invitation answered
    const groupsAdminMap = groupsAdmin.map(g => {
      return {
        ...g,
        invitations: g.invitations.filter(inv => inv.answered)
      }
    }).filter(group => group.invitations.filter(inv => inv.answered).length > 0)
    // const invitationsAnonymous: any = []
    // for (let group of groupsAdmin) {
    //   if (group.invitations && group.invitations.length > 0) {
    //     invitationsAnonymous.push(...group.invitations.filter(inv => inv.answered));
    //   }
    // }
    // Guests
    setGuests(guestsList);
    // Groups
    setGroupAdminInivtations(groupsAdminMap);
  }, [client, setGuests, setGroupAdminInivtations]);
  // Fetch Guests External
  const fetchGuestGroup = useCallback(async () => {
    // Suggestions
    const guestGroupResponse = await client.query({
      query: LIST_GUEST_GROUP,
      variables: { admin_created: false },
      fetchPolicy: "no-cache",
    });
    const guestGroups = guestGroupResponse.data.listGuestGroup || [];
    console.log('guestGroups', guestGroups)
    // Files
    setGuestGroups(guestGroups);
  }, [client, setGuests]);
  // Fecth Data
  const fetchData = useCallback(async () => {
    try {
      setLoading(true);
      // Categories
      setCategories(categoriesData);
      // Guests
      await fetchGuests();
      await fetchGuestGroup();
    } catch (e) {
      console.log("e", e);
      toast.error("Opps...Error ocurred. Try later...");
    } finally {
      setLoading(false);
    }
  }, [client, fetchGuests, setCategories, categoriesData]);
  // Init load one time totals
  useEffect(() => {
    if (categoriesData) {
      fetchData();
    }
  }, [categoriesData]);

  // Render
  return (
    <>
      {/* Guests */}
      <ModalListGuests
        guests={guests}
        groupAdminInivtations={groupAdminInivtations}
        loading={loading}
        loadGuests={fetchData}
      />
      {/* First Row */}
      <Row>
        <Col className={"mb-16"} md={12}>
          <BoxFoundersExternal
            categories={categories}
            guestGroups={guestGroups}
            loadGuests={fetchData}
          />
        </Col>
      </Row>
    </>
  );
};

export default ExternalRatingView;
