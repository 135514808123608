import React, { useState, useEffect, useCallback } from "react";
// Form
import { useFormContext } from "react-hook-form";
// Bootstrap
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
// Constants
import { STARTUP_SITUATION } from "../../../../constants/startup/startup.constants";


interface QuestionItemFormProps {
    item: any,
    prefix: string,
    index: number,
}
const QuestionItemForm = (props: QuestionItemFormProps) => {
    // Startup Situations
    const [startupSituation, setStartupSituation] = useState(STARTUP_SITUATION);
    // Props
    const { item, prefix, index } = props;
    // Form
    const { register, errors, setValue } = useFormContext();
    // Error
    const getError = (key) => {
        if (errors) {
            if (errors[prefix] && errors[prefix][index]) {
                return errors[prefix][index][key]
            }
        }
        return null;
    }
    // Fetch data
    const fetchData = useCallback(() => {
        console.log('item', item);
        setValue(`${prefix}[${index}].id`, item.id);
        setValue(`${prefix}[${index}].order`, item.order);
        setValue(`${prefix}[${index}].type_sku`, item.type_sku);
        if (prefix.includes('itemsRefinedScoring')) {
            setValue(`${prefix}[${index}].situations_sku`, item.situations_sku);
        }
    }, [setValue, item, prefix, index])
    // Ini
    useEffect(() => {
        if (item) {
            fetchData()
        }
    }, [item, fetchData])
    // Props
    return (<>
        <div className="question-item" >
            <Row>
                <Col lg="12">
                    <Form.Control name={`${prefix}[${index}].id`} type="hidden" ref={register({
                        required: 'Item id is required',
                    })} />
                    <Form.Control name={`${prefix}[${index}].order`}  type="hidden" ref={register({
                        required: 'Item order is required',
                    })} />
                    <Form.Control name={`${prefix}[${index}].type_sku`}  type="hidden" ref={register({
                        required: 'Item type sku is required',
                    })} />
                    <Form.Group >
                        <Form.Label>Option Text</Form.Label>
                        <Form.Control
                            name={`${prefix}[${index}].title`}
                            type="text"
                            placeholder="Enter a text"
                            isInvalid={getError('title')}
                            as={'textarea'}
                            rows={3}
                            ref={register({
                                required: 'Option Text is required',
                            })}
                            defaultValue={item.title}
                        />
                        {getError('title') &&
                            <div className="invalid-feedback"> {getError('title').message} </div>}
                    </Form.Group>
                </Col>

            </Row>
            <Row >
                <Col lg="4">
                    <Form.Group >
                        <Form.Label>Option Weight </Form.Label>
                        <Form.Control
                            name={`${prefix}[${index}].weight`}
                            type="number"
                            placeholder="Enter a weight"
                            isInvalid={getError('weight')}
                            ref={register({
                                required: 'Option weight is required',
                                min: {
                                    value: 0,
                                    message: 'The value must be greater than or equal to 0'
                                }
                            })}
                            defaultValue={item.weight}
                        />
                        {getError('weight') &&
                            <div className="invalid-feedback"> {getError('weight').message} </div>}
                    </Form.Group>
                </Col>
            </Row>
            {prefix === 'itemsRefinedScoring' &&
                <Row>
                    <Col lg="12">
                        <Form.Group >
                            {startupSituation.map((situation) => {
                                return (
                                    <Form.Check
                                        key={`inline-${index}-${situation.value}`}
                                        custom
                                        inline
                                        name={`${prefix}[${index}].situations_sku`}
                                        label={situation.label}
                                        value={situation.value}
                                        type={'checkbox'}
                                        id={`inline-${index}-${situation.value}`}
                                        ref={register}
                                    />)
                            })}
                        </Form.Group>
                    </Col>
                </Row>
            }
        </div>

    </>)
}

export default QuestionItemForm;