
/*eslint-disable*/
import React from "react";

const ErrorViewGuest = (props) => {

    // Render
    return (
        <>
            <h4 className="text-center">
                Token invalid or session expired. Re-enter with the link that was sent to your email. <br/><br/>
            </h4>
        </>
    )
}

export default ErrorViewGuest;
