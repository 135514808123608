import React, { useState } from 'react';
import Card from "react-bootstrap/Card";
import Accordion from "react-bootstrap/Accordion";
import Button from "react-bootstrap/Button";
import DateHelper from '../../helpers/dates/date.helper';
// Render Startup Info
const StartupAccordion = ({ startup }) => {
    const dateHelper = new DateHelper();
    const [currentActiveKey, setCurrentActiveKey] = useState('');
    const toggleActiveKey = (key) => {
        setCurrentActiveKey(currentActiveKey === key ? null : key);
    };
    return (
        <Accordion activeKey={currentActiveKey}>
            <Card>
                <Accordion.Toggle
                    as={Card.Header}
                    className={currentActiveKey === '0' ? '' : 'collapsed'}
                    eventKey="0"
                    onClick={() => {
                        toggleActiveKey("0");
                    }}
                >
                    <Button
                        variant=""
                        className="accordion-button">
                        Startup Information
                    </Button>
                </Accordion.Toggle>
                <Accordion.Collapse eventKey="0" >
                    <div className="collapse-card">
                        <ul className="list-group list-group-flush">
                            <li className="list-group-item">
                                Name<br />
                                <span className="font-weight-semi-bold">{startup.name}</span>
                            </li>
                            <li className="list-group-item">
                                Stage<br />
                                <span className="font-weight-semi-bold">{startup.stage_text}</span>
                            </li>
                            <li className="list-group-item">
                                Situation<br />
                                <span className="font-weight-semi-bold">{startup.situation_text}</span>
                            </li>
                            {startup.business_category_text !== '' &&
                                <li className="list-group-item">
                                    Business Category<br />
                                    <span className="font-weight-semi-bold">{startup.business_category_text}</span>
                                </li>}
                            {startup.business_type_1_text !== '' &&
                                <li className="list-group-item">
                                    Business Type 1<br />
                                    <span className="font-weight-semi-bold">{startup.business_type_1_text}</span>
                                </li>}
                            {startup.business_type_2_text !== '' &&
                                <li className="list-group-item">
                                    Business Type 2<br />
                                    <span className="font-weight-semi-bold">{startup.business_type_2_text}</span>
                                </li>}
                            <li className="list-group-item">
                                Primary Account<br />
                                <span className="font-weight-semi-bold">{`${startup.primary_account.first_name} ${startup.primary_account.last_name}, ${startup.primary_account.email}`}</span>
                            </li>
                            <li className="list-group-item">
                                Created<br />
                                <span className="font-weight-semi-bold">{dateHelper.format(startup.created)}</span>
                            </li>

                        </ul>
                    </div>
                </Accordion.Collapse>
            </Card>

        </Accordion>
    )
}

export default StartupAccordion;