import React, { useCallback, useEffect, useRef, useState } from 'react';
// D3
import * as d3 from 'd3';
// Hook
import useWindowSize from '../../../hooks/useWindowResize';
// Constants
import { CHART_COLOR } from '../../../constants/charts/chart.constants';


interface ProgressBarChartProps {
    data: {
        percentage: number,
    },
    settings?: {
        height?: number,
        barBgColor?: string,
        barStrokeColor?: string

    }

}

const ProgressBarPercentageChart2 = (props: ProgressBarChartProps) => {    
    // Hook Window
    const windowSize = useWindowSize();
    // Props
    const { data: dataProps, settings: settingsProps } = props;
    // State
    const defaultSettings = {
        height: 16,
        barBgColor: CHART_COLOR.dark,
        barStrokeColor: CHART_COLOR.progressBarBorder,
    }
    const [data, setData] = useState<any>(null)
    const [settings, setSettings] = useState<any>(null)
    // Line Height
    const [lineHeight, setLineHeight] = useState<any>(14)
    // const [size, setSize] = useState<any>({ width: 'auto', height: 'auto' })

    // Chart
    const chartEl = useRef<HTMLDivElement>(null);
    const parentEl = useRef<HTMLDivElement>(null);
    // Fetch Data
    const fetchData = useCallback(() => {
        const settings = Object.assign(defaultSettings, {})
        if (settingsProps) {
            if (settingsProps.height) {
                settings.height = settingsProps.height;
            }
            if (settingsProps.barBgColor) {
                settings.barBgColor = settingsProps.barBgColor;
            }
            if (settingsProps.barStrokeColor) {
                settings.barStrokeColor = settingsProps.barStrokeColor;
            }
        }
        setSettings(settings);
        setData(dataProps);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps, settingsProps, setData, setSettings])

    const setup = (parentEl, chartEl) => {
        // Element width
        const elementSize = parentEl.current.clientWidth;
        // Svg Styles
        const margin = {
            left: 0,
            top: 0,
            right: 0,
            bottom: 0
        };
        let width = elementSize - margin.left - margin.right;
        let height = settings.height - margin.top - margin.bottom

        // Renove Svg
        d3.select(chartEl.current)
            .select("svg").remove()
        // Create
        const svg = d3
            .select(chartEl.current)
            .append("svg")
            .attr("width", (width + margin.left + margin.right))
            .attr("height", (height + margin.top + margin.bottom))
            .append("g").attr("class", "wrapper")
        // .attr("transform", "translate(" + (width / 2 + margin.left) + "," + (height / 2 + margin.top) + ")");

        return { svg, margin, width, height }
    }

    // Add logic to draw the chart here
    const drawChart = useCallback(() => {
        if (chartEl && chartEl.current && data) {
            if (data.percentage) {
                // Setup
                const { svg, width, height } = setup(parentEl, chartEl);
                setLineHeight(height)
                // Scale of progress bar
                const scale: any = d3.scaleLinear().domain([0, 100]).range([width, 0]);

                // Height Bar Propgress
                const heightBarProgress = settings.height;
                // Progress
                const progress =
                    svg.append('rect')
                        .attr('class', 'progress-rect')
                        .attr('fill', settings.barBgColor)
                        .attr('height', heightBarProgress)
                        .attr('width', scale())
                        .attr('rx', 4)
                        .attr('ry', 4)
                        .attr("x", (d) => { return scale(0) })
                progress
                    .transition()
                    .duration(1000)
                    .attr("x", (d) => { return scale(data.percentage) })
                    .attr("width", (d) => { return scale(0) - scale(data.percentage); })

                // If Has decimals
                let offsetText = 0
                const valueParts = (data.percentage + "").split(".")
                if (valueParts.length === 2) {
                    offsetText = 8;
                }
                // Text
                let textX = scale(0) - (12 + offsetText);
                let fillText = '#fff';
                if (data.percentage < 40) {
                    if (valueParts.length === 2) {
                        offsetText = 4;
                    }
                    textX = scale(data.percentage) - (16 + offsetText);
                    fillText = settings.barBgColor
                }
                svg
                    .append("text")
                    .attr('class', 'progress-text')
                    .attr('y', heightBarProgress / 2)
                    .attr('x', textX)
                    .attr('dominant-baseline', 'middle')
                    .attr('text-anchor', 'middle')
                    .style('fill', fillText)
                    .text(data.percentage + '%')
                    .style('opacity', 0)
                    .transition()
                    .delay(1000)
                    .duration(600)
                    .style('opacity', 1);
            } else { // Empty
                // Setup
                const { svg, width, height } = setup(parentEl, chartEl);
                setLineHeight(height)
                // Scale of progress bar
                const scale: any = d3.scaleLinear().domain([0, 100]).range([width, 0]);

                // Height Bar Propgress
                const heightBarProgress = settings.height;
                // Progress
                const progress =
                    svg.append('rect')
                        .attr('class', 'progress-rect')
                        .attr('fill', settings.barBgColor)
                        .attr('height', heightBarProgress)
                        .attr('width', scale())
                        .attr('rx', 4)
                        .attr('ry', 4)
                        .attr("x", (d) => { return scale(0) })
                progress
                    .transition()
                    .duration(1000)
                    .attr("x", (d) => { return scale(40) })
                    .attr("width", (d) => { return scale(0) - scale(40); })
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chartEl, parentEl, settings, data])

    // On Change Props
    useEffect(() => {
        if (dataProps && dataProps !== data && settings !== settingsProps) {
            fetchData()
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataProps, settingsProps, fetchData, data]);
    // Resize when call third time (first undefined, second with window value)
    useEffect(() => {
        if (windowSize.width && data && settings) {

            drawChart();
        }
    }, [windowSize, data, settings, drawChart]);


    return (
        <div ref={parentEl} className="startup-progress-percentage-chart">
            <div className="progress-bar-wrapper" style={{ lineHeight: lineHeight + 'px' }}>
                <div ref={chartEl} className="startup-progress-container"></div>
            </div>

        </div>
    )
}

export default ProgressBarPercentageChart2;