
// Questionnaire
import IntroLeadView from "./views/questionnaire/IntroLeadView";
import IntroView from "./views/questionnaire/IntroView";
import IntroViewGuest from "./views/questionnaire/IntroViewGuest";
import RefinedView from "./views/questionnaire/RefinedView";



const routesQuestionnaire = [    
    {
        path: "/refined-scoring",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: RefinedView,
        layout: "/questionnaire",
        key: 'refined-scoring'
    },
    {
        path: "/intro",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: IntroView,
        layout: "/questionnaire",
        key: 'intro-view'
    },
    {
        path: "/intro-lead",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: IntroLeadView,
        layout: "/questionnaire",
        key: 'lead-view'
    },
    {
        path: "/intro-guest",
        name: "Questionnaire",
        icon: "ni ni-key-25",
        component: IntroViewGuest,
        layout: "/questionnaire",
        key: 'intro-guest-view'
    },

 

];
export default routesQuestionnaire;
