
/*eslint-disable*/
import React, { useCallback, useEffect, useState } from "react";
// Bootstrap
import Navbar from 'react-bootstrap/Navbar';
import Nav from 'react-bootstrap/Nav';
import Image from 'react-bootstrap/Image';
// SVG
import { ReactSVG } from "react-svg";
// Menu
import SidebarMenu from "./SidebarMenu";
// Class
import c from "classnames";
// Constants
import { QUESTION_TYPES, QUESTION_TYPES_MENU_LIMITED } from "../../constants/startup/startup.constants";
// Utils
import { removeToken } from "../../utils";
// Redux
import { useDispatch, useSelector } from 'react-redux';
import { getUser, userDelete } from "../../redux/ducks/user-duck";
// History
import { useHistory } from "react-router-dom";

interface ItemMenu {
    key: string;
    link: string;
    label: string;
    icon?: string;
    submenu: ItemSubmenu[]
}
interface ItemSubmenu {
    category_sku: string;
    order: number;
    title: string;
    sku: string;
}

const SidebarMainDash = (props) => {
    // Redux
    const dispatch = useDispatch();
    // User
    const user = useSelector(getUser);
    // console.log('SidebarMainDash', user);
    // History
    const history = useHistory();
    // Menu
    const [menuSidebar, setMenuSidebar] = useState<Array<ItemMenu>>(new Array<ItemMenu>());
    const [menuSidebarType, setMenuSidebarType] = useState<'normal'|'limited'>('normal');
    // 
    const isAvailableSubmenuItem = (elementsConfiguration, questionType) => {
        let available = false;
        if (elementsConfiguration && elementsConfiguration.length > 0) {
            const elConf = elementsConfiguration.find(elConf => elConf.sku === questionType.sku);
            if (elConf) {
                available = elConf.available
            }
        }
        return available;
    }
    // Build Menu
    const buildMenu = (category: string, user) => {
        const questionTypes =
            QUESTION_TYPES.filter(t => t.category_sku === category).map((q) => (
                {
                    ...q,
                    available: user.startup_type === 'not_limited' ? true : isAvailableSubmenuItem(user.elements_configuration, q)
                }
            ));

        return questionTypes;
    }
    // Build Menu Limited
    const buildMenuLimited = (category: string, user) => {
        const questionTypes =
            QUESTION_TYPES_MENU_LIMITED.filter(t => t.category_sku === category).map((q) => (
                {
                    ...q,
                    available: user.startup_type === 'not_limited' ? true : isAvailableSubmenuItem(user.elements_configuration, q)
                }
            ));
        return questionTypes;
    }
    // Home
    const home = {
        key: 'dashboard',
        link: "/dashboard/main",
        label: 'Dashboard',
        icon: 'home.svg',
    }
    // Menu
    const menu: Array<ItemMenu> = [
        {
            key: 'team',
            link: "/dashboard/main/team",
            label: 'Team',
            icon: 'team.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'purpose',
            link: "/dashboard/main/purpose",
            label: 'Purpose',
            icon: 'purpose.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'market',
            link: "/dashboard/main/market",
            label: 'Market',
            icon: 'market.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'scale',
            link: "/dashboard/main/scale",
            label: 'Scale',
            icon: 'scale.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'product',
            link: "/dashboard/main/product",
            label: 'Product',
            icon: 'product.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'traction',
            link: "/dashboard/main/traction",
            label: 'Traction',
            icon: 'traction.svg',
            submenu: []

        },
        {
            key: 'analytics',
            link: "/dashboard/main/analytics",
            label: 'Analytics',
            icon: 'analytics.svg',
            submenu: []
        },

    ]
    // Menu Limited (Academy)
    const menu_limited: Array<ItemMenu> = [
        {
            key: 'purpose_founders',
            link: "no_link",
            label: 'Purpose & Founders',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'team_strengths',
            link: "no_link",
            label: 'Team Strengths',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'value_proposition',
            link: "no_link",
            label: 'Value Proposition',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'positioning',
            link: "no_link",
            label: 'Positioning',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'market_segmentation',
            link: "no_link",
            label: 'Market Segmentation',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'go_to_market_timing',
            link: "no_link",
            label: 'Go-to-market and\nTiming',
            submenu: []
        },
        {
            key: 'product_experience',
            link: "no_link",
            label: 'Product Experience',
            submenu: []
        },
        {
            key: 'business_model',
            link: "no_link",
            label: 'Business Model',
            submenu: []
        },
        {
            key: 'analytics_customer_experience',
            link: "no_link",
            label: 'Analytics and\ncustomer experience',
            submenu: []
        },
        {
            key: 'momentum_to_scale',
            link: "no_link",
            label: 'Momentum to Scale',
            submenu: []
        },

    ]
    // Menu
    const menu_categories: Array<ItemMenu> = [
        {
            key: 'team',
            link: "/dashboard/main/team",
            label: 'Team',
            icon: 'team.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'purpose',
            link: "/dashboard/main/purpose",
            label: 'Purpose',
            icon: 'purpose.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'market',
            link: "/dashboard/main/market",
            label: 'Market',
            icon: 'market.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'scale',
            link: "/dashboard/main/scale",
            label: 'Scale',
            icon: 'scale.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'product',
            link: "/dashboard/main/product",
            label: 'Product',
            icon: 'product.svg',
            submenu: new Array<ItemSubmenu>()
        },
        {
            key: 'traction',
            link: "/dashboard/main/traction",
            label: 'Traction',
            icon: 'traction.svg',
            submenu: []

        },
        {
            key: 'analytics',
            link: "/dashboard/main/analytics",
            label: 'Analytics',
            icon: 'analytics.svg',
            submenu: []
        },

    ]
    // Extra Menu
    const extraMenu = [
        {
            key: 'challenges',
            link: "/dashboard/challenges",
            label: `Challenges`,
        },
        {
            key: 'founders-alignment',
            link: "/dashboard/founders-alignment",
            label: `Founders' Alignment`,
        },
        {
            key: 'external-rating',
            link: "/dashboard/external-rating",
            label: `External Rating`,
        },

    ]
    // Extra Menu Limited
    const extraMenuLimited = [
        {
            key: 'challenges',
            link: "/dashboard/challenges",
            label: `Tasks`,
        },
        {
            key: 'founders-alignment',
            link: "/dashboard/founders-alignment",
            label: `Founders' Alignment`,
        },

    ]
    const [toggle, setToggle] = useState(false);
    const toggleSidebar = () => {
        setToggle(!toggle)
    }

    // Logout
    const logout = () => {
        dispatch(userDelete())
        removeToken();
        history.push(`/auth/login`)
    }
    // Build Menu
    const fetchMenuSidebar = useCallback(() => {
        // If User is guest
        if (user.startup_type === 'limited') {
            if (!user.elements_configuration) {
                dispatch(userDelete);
            }
        }
        // console.log('user.startup_type', user.startup_type)
        if (user.startup_type === 'limited') {
            // Fetch
            for (let itemMenu of menu_limited) {
                itemMenu.submenu = buildMenuLimited(itemMenu.key, user);
            }
            setMenuSidebarType('limited');
            setMenuSidebar(menu_limited)
        } else {
            // Fetch
            for (let itemMenu of menu) {
                itemMenu.submenu = buildMenu(itemMenu.key, user);
            }
            setMenuSidebar(menu)
        }

    }, [user, menu, menu_limited, setMenuSidebar])
    // Buil Menu According User Type
    useEffect(() => {
        if (user) {
            fetchMenuSidebar()
        }
    }, [user])
    // Render
    return (
        <div className={c("sidebar-main-dash", { 'show-sidebar': toggle })}>
            <a className="btn-toggle-sidebar d-block d-lg-none" onClick={toggleSidebar}>
                <ReactSVG src={require(`../../assets/img/sidebar/chevron-forward-outline.svg`).default} wrapper="span" className="icon-link" />
            </a>
            {/* Logo */}
            <Navbar.Brand href="/dashboard/main" className='text-white'>
                <Image src={require("../../assets/img/logo.svg").default} />
            </Navbar.Brand>
            <div className="sidebar-main-dash-wrapper">
                <div className="sidebar-main-dash-container">
                    { user && <SidebarMenu
                        home={home}
                        menu={menuSidebar}
                        menuType={menuSidebarType}
                        categoriesMenu={ user.startup_type === 'limited' ?  menu_categories : null}
                        extraMenu={user.startup_type === 'limited' ? extraMenuLimited : extraMenu}
                    />}
                </div>
            </div>
            <div className="sidebar-logout">
                <Nav.Item>
                    <Nav.Link className="nav-link" onClick={logout}>
                        <ReactSVG src={require(`../../assets/img/header/logout.svg`).default} wrapper="span" className="icon-link" />
                        Logout
                    </Nav.Link>
                </Nav.Item>
            </div>
        </div>
    )
}

export default SidebarMainDash;
